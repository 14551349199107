import { getField, updateField } from 'vuex-map-fields'
import { get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  income: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  income: ({ income }) => income,
  incomeId: ({ income }) => _get(income, 'id'),

  getIncomeSourceById: (state) => (sourceId) =>
    state.income.additional_income.find((source) => source.id == sourceId),
}

const actions = {
  fetchIncome({ commit }, incomeId) {
    if (!incomeId) return commit('setLoading', false)

    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/income/${incomeId}`)
      .then(({ data }) => {
        commit('setIncome', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  updateIncome({ commit, getters }, data) {
    const incomeId = getters['income'].id
    const { income } = getters
    const payload = { ...income, ...data }

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/income/${incomeId}`, payload)
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  updateIncomeByKey({ commit, getters }, { field, value }) {
    const incomeId = getters['income'].id
    const { income } = getters
    const payload = { ...income, [field]: value }

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/income/${incomeId}`, payload)
      .then(({ data }) => {
        commit('setIncome', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  createIncome({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post('applications/income/')
      .then(({ data }) => {
        commit('setIncome', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  createAdditionalIncomeSource({ commit, getters }, income) {
    const incomeId = getters.income.id
    const payload = { ...income, income: incomeId }
    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .post('applications/additional-income/', payload)
      .then((result) => {
        commit('addIncomeSource', result.data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  updateAdditionalIncomeSource({ commit, getters }, income) {
    const incomeId = getters.income.id
    const payload = { ...income, income: incomeId }

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/additional-income/${income.id}`, payload)
      .then((result) => {
        commit('updateAdditionalIncomeSource', result.data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  deleteIncomeSourceById(/* incomeSourceId */) {},
}

const mutations = {
  updateField,
  ...mutationHelpers,

  setIncome: (state, income) => (state.income = income),

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  addIncomeSource: (state, source) => {
    state.income.additional_income.push(source)
  },

  updateAdditionalIncomeSource: (state, source) => {
    const sourceIndex = state.income.additional_income.findIndex((item) => item.id == source.id)

    state.income.additional_income[sourceIndex] = source
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
