import { trim } from 'lodash'
import emailValidator from 'email-validator'
import { differenceInYears } from 'date-fns'

const required = (value) => (!trim(value) ? 'Field is required' : true)

const emailField = (value) => {
  if (!value) return true

  return emailValidator.validate(value) || 'Invalid Email'
}

const minValue = (min) => (value) => value && value < min ? `Minimum value of ${min}` : true

const maxValue = (max) => (value) => value && value > max ? `Maximum value of ${max}` : true

const maxLength = (max) => (value) =>
  value && value.length > max ? `Maximum number of characters is ${max}` : true

const minLength = (min) => (value) =>
  value && value.length < min ? `Minimum number of characters is ${min}` : true

const exactLength = (length) => (value) =>
  value && value.length < length ? `Must be ${length} characters` : true

const onlyLetters = (value) => (value && /[^A-Za-z]/g.test(value) ? 'Only letters allowed' : true)

const onlyNumbers = (value) => (value && /[^0-9]/g.test(value) ? 'Only Numbers allowed' : true)

const minAge =
  (minAgeNumber = 18, customErrorMessage) =>
  (value) => {
    const userAge = differenceInYears(new Date(), new Date(value))
    const userIsYoungerThanMinAge = userAge < minAgeNumber
    return userIsYoungerThanMinAge
      ? customErrorMessage || `Must be at least ${minAgeNumber} years of age.`
      : false
  }

export {
  required,
  emailField,
  minValue,
  maxValue,
  maxLength,
  minLength,
  onlyLetters,
  onlyNumbers,
  exactLength,
  minAge,
}
