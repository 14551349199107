import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const US_COUNTRY_CODE = 'US'

const getDefaultState = () => ({
  ...stateHelpers,
  cosigner: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  applicationFeeIsWaived: ({ cosigner }) =>
    _.get(cosigner, 'source.source_application.waive_application_fee'),

  cosigner: ({ cosigner }) => cosigner,
  cosignerId: ({ cosigner }) => _.get(cosigner, 'id'),
  cosignerIncomeId: ({ cosigner }) => _.get(cosigner, 'income'),
  cosignerBackgroundCheck: ({ cosigner }) => _.get(cosigner, 'background_check'),
  cosignerChargeStatus: ({ cosigner }) => _.get(cosigner, 'charge.status'),
  cosignerApplicationStatus: ({ cosigner }) => _.get(cosigner, 'status'),
  cosignerMarketCountryCode: ({ cosigner }) =>
    _.get(cosigner, 'source.source_application.market.country_code'),
  cosignerFee: ({ cosigner }) => _.get(cosigner, 'source.source_application.application_fee', 0),
  cosignerSourceApplication: ({ cosigner }) => _.get(cosigner, 'source.source_application'),
  cosignerSourceApplicantProperty: ({ cosigner }) =>
    _.get(cosigner, 'source.source_application.property', {}),
  cosignerSourceApplicantPropertyAddress: ({ cosigner }) =>
    _.get(cosigner, 'source.source_application.property.address', {}),
  cosignerSourceApplicantInfo: ({ cosigner }) =>
    _.get(cosigner, 'source.source_application.applicant_info'),
  hasUSAddress: ({ cosigner }) => {
    const cosignerAddressCountryCode = _.get(cosigner, 'address.country_code')
    return cosignerAddressCountryCode === US_COUNTRY_CODE
  },
  displayState: ({ cosigner }) => _.get(cosigner, 'display_state'),
  sectionStarted:
    ({ cosigner }) =>
    (section) =>
      _.get(cosigner, `display_state.sectionStatus.${section}`) === 'started',
}

const actions = {
  fetchCosignerBySource({ commit }, source__slug) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`cosigner/cosigner/?source__slug=${source__slug}`)
      .then(({ data }) => _.get(data, 'results.0'))
      .then((cosigner) => {
        commit('setCosigner', cosigner)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  fetchCosignerById({ commit }, cosignerId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`cosigner/cosigner/${cosignerId}`)
      .then(({ data }) => data)
      .then((cosigner) => {
        commit('setCosigner', cosigner)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  createCosignerBySource({ commit }, source) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(`cosigner/cosigner/`, { source })
      .then(({ data }) => data)
      .then((cosigner) => {
        commit('setCosigner', cosigner)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  updateCosigner({ commit, getters }) {
    const cosigner = getters['cosigner']
    const cosignerId = getters['cosignerId']

    commit('setUpdating', true)

    return this._vm.$http
      .put(`cosigner/cosigner/${cosignerId}`, cosigner)
      .then(({ data }) => {
        commit('setCosigner', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  updateCosignerByKey({ commit, getters }, { field, value }) {
    const cosigner = getters['cosigner']
    const cosignerId = getters['cosignerId']

    const payload = { ...cosigner, [field]: value }

    commit('setUpdating', true)

    return this._vm.$http
      .put(`cosigner/cosigner/${cosignerId}`, payload)
      .then(({ data }) => {
        commit('setCosigner', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setCosigner(state, cosigner) {
    state.cosigner = cosigner
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
