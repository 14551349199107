<template>
  <div class="mg-participants">
    <div class="mg-participants-list">
      <v-layout :class="['header-box', { tight: pageView }]">
        <blw-button v-if="pageView" flat icon color="charcoal" @click="goBack">
          <v-icon color="charcoal">arrow_back</v-icon>
        </blw-button>
        <span :class="(pageView || !property) && 'lead'">{{ headerBoxText }}</span>
      </v-layout>
      <v-layout v-ripple class="participant view-preferences" @click="viewPreferences">
        <blw-avatar :avatars="participants" size="36" />
        <v-layout column>
          <b class="name"> Living preferences </b>
          <span class="status"><b> See how compatible you are </b></span>
        </v-layout>
        <v-icon color="charcoal">arrow_forward</v-icon>
      </v-layout>
      <v-layout
        v-ripple
        class="participant"
        v-for="participant in participants"
        :key="participant.id"
        align-center
        justify-space-between
        @click="viewProfile(participant.id)"
      >
        <blw-avatar :avatar="participant" size="40" />
        <v-layout column>
          <b class="name"> {{ participant.first_name }} {{ participant.last_name }} </b>
          <span v-if="participant.is_applicant" class="status">
            Applying for <b>{{ room.name }}</b>
          </span>
        </v-layout>
        <v-icon v-if="participant.id" color="charcoal">arrow_forward</v-icon>
      </v-layout>
    </div>
    <div v-if="showResidentWarning" class="mg-participants-warning">
      <blw-svg name="warning" />
      <span>
        Some roommates may be slow to respond because they have an older version of our app.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetGreetParticipants',
  props: {
    property: {
      type: Object,
    },
    primary_applicant: {
      type: Object,
    },
    secondary_applicant: {
      type: Object,
    },
    room: {
      type: Object,
    },
    residents: {
      type: Array,
    },
    pageView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applicants() {
      const applicants = [{ ...this.primary_applicant, is_applicant: true }]
      if (this.secondary_applicant)
        applicants.push({ ...this.secondary_applicant, is_applicant: true })

      return applicants
    },
    participants() {
      return [...this.applicants, ...this.residents]
    },
    propertyAddress() {
      const { street_address, unit, city, postal_code } = this.property.address
      return `${street_address} ${unit ? `– ${unit}` : ''} ${city}, ${postal_code}`
    },
    headerBoxText() {
      if (this.property) return this.propertyAddress
      return 'Your Bungalow'
    },
    showResidentWarning() {
      // TODO: In future will be based on app version, for now based on missing user:
      const participantsMissingUserId = this.participants.filter((participant) => !participant.id)
      return !!participantsMissingUserId.length
    },
  },
  methods: {
    viewPreferences() {
      this.$trackEvent({
        action: 'View Preferences',
        properties: {
          location: 'Meet & Greet - Participants',
          CTA: 'Living preferences',
          text: 'See how compatible you are',
          category: 'Meet & Greet',
          type: 'List',
        },
      })

      this.$routerPush({ name: 'meetGreetPreferences' })
    },
    viewProfile(userId) {
      if (!userId) return

      this.$trackEvent({
        action: 'View Profile',
        properties: {
          location: 'Meet & Greet - Participants',
          CTA: 'Profile Card',
          category: 'Meet & Greet',
          type: 'List',
        },
      })

      this.$routerPush({
        name: 'meetGreetProfileView',
        params: { userId },
      })
    },
    goBack() {
      this.$trackEvent({
        action: 'Go Back',
        properties: {
          location: 'Meet & Greet - Participants',
          CTA: 'Back arrow',
          category: 'Meet & Greet',
          type: 'Icon',
        },
      })

      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.mg-participants {
  .mg-participants-list {
    background: white;

    @include mobile {
      margin-bottom: 5rem;
    }

    .view-preferences {
      display: none;

      @include mobile {
        display: flex;
      }
    }

    .header-box {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      padding: 0 3.5rem;
      height: 100px;
      font-size: 13px;
      border-bottom: 1px solid $charcoal-40;

      &.tight {
        padding: 0 1rem;
        height: 4rem;

        .lead {
          margin-left: -1rem;
          padding-top: 2px;
          flex-grow: 1;
        }
      }
    }

    .participant {
      cursor: pointer;
      position: relative;
      height: 5rem;
      padding: 1.25rem;

      &:hover {
        background: $charcoal-20;
      }

      &::after {
        content: '';
        position: absolute;
        height: 1px;
        left: 1.25rem;
        right: 1.25rem;
        bottom: 0;
        background: $charcoal-40;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .blw-avatar {
        margin-right: 0.75rem;
      }

      .name {
        font-weight: bold;
      }

      .status {
        font-size: 11px;
        color: $text-lighter;
      }
    }
  }

  .mg-participants-warning {
    display: flex;
    align-items: center;
    background: white;
    margin-top: 2rem;
    padding: 1.5rem;

    @include mobile {
      position: fixed;
      bottom: 0;
    }

    span {
      font-size: 12px;
      margin-left: 1.5rem;
    }
  }
}
</style>
