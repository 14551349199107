<template>
  <div id="confirm-address-modal">
    <v-layout column align-center class="text-xs-center">
      <blw-icon icon="checkmark" size="lg" />
      <h2 class="header-1 my-4">Let's make sure<br />this is right.</h2>
      <p v-max-width="250" class="mb-5">
        You get <span class="font-bold">{{ annualIncome }}</span> from your job at
        {{ applicantEmployer }}?
      </p>
    </v-layout>
    <v-layout class="">
      <v-flex xs6 class="px-2">
        <blw-button color="charcoal" @click="$root.$emit('closeModal')" label="Go back" />
      </v-flex>
      <v-flex xs6 class="px-2">
        <blw-button color="salmon" @click="onContinue" label="Confirm" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { getAnnualIncome } from '@/utils/helpers'

export default {
  name: 'ConfirmIncomeModal',
  props: {
    applicantIncome: {
      type: [String, Number],
      required: true,
    },
    applicantEmployer: {
      type: String,
      required: true,
    },
    nextPath: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$trackEvent({
      action: 'Open Modal: Confirm Your Annual Income',
      properties: {
        location: 'Confirm your annual income',
        CTA: 'Continue',
        event: 'Open Modal',
      },
    })
  },
  computed: {
    annualIncome() {
      return this.getAnnualIncome(this.applicantIncome)
    },
  },
  methods: {
    getAnnualIncome,
    onContinue() {
      this.$trackEvent({
        action: 'CTA Clicked: Income Confirmed',
        properties: {
          location: 'Confirmed Annual Income',
          CTA: 'Continue',
          event: 'CTA Clicked',
        },
      })
      this.$routerPush({ name: this.nextPath })
      this.$root.$emit('closeModal')
    },
  },
}
</script>
