import ApplicationsContainer from './ApplicationsContainer'
import ApplicationsHome from './views/ApplicationsHome'
import NoApplicationSource from './views/NoApplicationSource'
import BeforeYouBegin from './views/BeforeYouBegin'

const routes = {
  path: 'applications',
  component: ApplicationsContainer,
  meta: {
    basicNav: true,
    requiresAuth: true,
    disableBack: true,
  },
  children: [
    {
      path: '',
      name: 'applications',
      component: ApplicationsHome,
    },
    {
      path: 'no-application-source',
      name: 'applicationSourceNotFound',
      component: NoApplicationSource,
    },
    {
      path: 'before-you-begin',
      name: 'applicationBeforeYouBegin',
      component: BeforeYouBegin,
    },
  ],
}

export default routes
