<template>
  <div class="mg-modal">
    <v-layout column align-center justify-space-around>
      <div>
        <blw-illustration class="mx-auto mb-4" type="spot" name="meet-roommates" size="80" />
        <h2 class="header-md text-xs-center mb-4">The chat has ended.</h2>
        <p class="mb-4">We’ve also sent you an email with information about what’s next.</p>
      </div>
      <blw-button color="charcoal" @click="$root.$emit('closeModal')"> Continue </blw-button>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'MeetGreetChatEnded',
  mounted() {
    this.$trackEvent({
      action: 'Modal Opened',
      properties: {
        location: 'Meet & Greet - Chat Ended',
        text: 'The chat has ended',
        CTA: 'Continue',
        color: 'Charcoal',
        category: 'Meet & Greet',
        type: 'Modal',
      },
    })
  },
}
</script>
