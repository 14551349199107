<template>
  <div class="mg-review">
    <blw-illustration name="cat-clock" size="185" />
    <h1 class="header-1 my-4">We’re reviewing the roommates’ decision</h1>
    <p class="mb-4">
      This process usually takes a few hours. We’ll notify you via email once we’ve reviewed their
      decision.
    </p>
    <!-- <a @click="showNotSureModal">Changed your mind?</a> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MeetGreetReview',
  computed: {
    ...mapGetters('meetGreet', ['meeting', 'error']),
  },
  methods: {
    showNotSureModal() {
      this.$root.$emit('openModal', {
        modalComponent: 'MeetGreetNotSure',
      })
    },
  },
  mounted() {
    if (this.meeting && !this.error) this.$routerPush({ name: 'meetGreet' })
  },
}
</script>

<style lang="scss">
.mg-review {
  @include fullHeight();

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2.75rem;
  max-width: 420px;
  margin: auto;

  a {
    text-decoration: underline;

    &:hover {
      color: $blue-60;
    }
  }
}
</style>
