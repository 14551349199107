<template>
  <v-layout wrap>
    <v-flex row xs12>
      <blw-text-field
        label="First name"
        v-model="firstName"
        :rules="roommateFlag.expanded ? [required, maxLength(50)] : []"
        :box="box"
      />
    </v-flex>

    <v-flex xs12>
      <blw-text-field
        label="Last name"
        v-model="lastName"
        :rules="roommateFlag.expanded ? [required, maxLength(50)] : []"
        :box="box"
      />
    </v-flex>

    <v-flex xs12>
      <blw-text-field
        label="Email"
        v-model="email"
        :rules="roommateFlag.expanded ? [required, emailField, maxLength(100)] : []"
        :box="box"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

import { required, emailField, maxLength } from '@/utils/formValidations'

export default {
  name: 'PropertyRoomShare',
  props: {
    box: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields('applicationFlags', [
      'roommateFlag.flag_data.firstName',
      'roommateFlag.flag_data.lastName',
      'roommateFlag.flag_data.email',
    ]),
    ...mapGetters('applicationFlags', ['roommateFlag']),
  },
  methods: {
    emailField,
    required,
    maxLength,
  },
}
</script>
