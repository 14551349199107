<template>
  <v-card class="br-8 pa-3" id="find-homes-card">
    <v-img
      class="br-8 d-flex justify-center align-center font-weight-bold"
      height="156px"
      :src="marketImage"
    >
      {{ applicationMarketName }}
    </v-img>
    <v-card-text class="text-xs-center">
      <div
        class="header-sm font-weight-bold pt-3"
        v-text="'There are more homes nearby at a similar price'"
      />
      <div class="font-large pt-3" v-text="'Add more homes and keep chatting!'" />
    </v-card-text>
    <v-card-actions>
      <blw-button color="charcoal" @click="viewBungalowListings" block label="Find more homes" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FindHomesCard',
  computed: {
    ...mapGetters('application', ['applicationMarketSlug', 'applicationMarketName']),
    marketImage() {
      return `${process.env.VUE_APP_AWS_ASSETS_URL}markets/maps/${this.applicationMarketSlug}.png`
    },
  },
  methods: {
    viewBungalowListings() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          Category: 'Home Selection',
          CTA: 'Add More Homes',
          Location: 'Search Card',
          Text: 'Visit Bungalow.com',
        },
      })
      window.open(
        `${process.env.VUE_APP_BUNGALOW_WEBSITE_BASE_URL}listings/${this.applicationMarketSlug}`
      )
    },
  },
}
</script>

<style lang="scss">
#find-homes-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  .v-image__image--cover {
    background-size: auto;
  }
}
</style>
