<template>
  <loading-spinner v-if="loading" fullPage />
  <div v-else class="debug">
    <v-container>
      <transition name="fade">
        <div v-show="isAdmin">
          <v-layout align-center mt-3>
            <blw-illustration name="lease" size="40" />
            <h1 class="header-md ml-3">Debug Tool</h1>
          </v-layout>
          <v-layout justify-space-between>
            <p class="debug-instructions font-xsmall font-grey mt-2 mb-3">
              Load an <router-link to="/applications">application</router-link> to see it's data.
            </p>
            <blw-button
              :disabled="!application"
              class="mr-2"
              size="xs"
              color="salmon"
              @click="goToFieldstone"
            >
              Fieldstone <v-icon size="16" class="ml-1">open_in_new</v-icon>
            </blw-button>
            <blw-button class="mr-2" size="xs" color="blue" @click="logState">
              Log State
            </blw-button>
            <blw-button size="xs" color="charcoal" @click="clearStorage">
              Clear Storage
            </blw-button>
          </v-layout>
          <v-card class="store-dump pa-3">
            <pre v-if="application" class="pb-2">Source Slug: {{ application.source }} </pre>
            <pre>{{ applicationData }}</pre>
          </v-card>
        </div>
      </transition>
      <v-layout v-if="!isAdmin" class="riddle pb-5 full-height" column align-center justify-center>
        <blw-illustration name="five-plants" size="200" />
        <p v-max-width="450" class="mt-4 header-md text-xs-center mx-auto">
          If you have to ask, you’ll never know. <br />
          If you know, you need only ask...
        </p>
      </v-layout>
    </v-container>
    <v-snackbar multi-line :value="stateLogged || storageCleared">
      <v-layout align-center justify-center>
        <template v-if="stateLogged">
          <v-icon color="white">input</v-icon>
          <p class="ml-2 text-xs-center"><b>State Logged</b> – check developer console</p>
        </template>
        <template v-if="storageCleared">
          <v-icon color="white">input</v-icon>
          <p class="ml-2 text-xs-center">
            <b>Local Storage Cleared</b>
          </p>
        </template>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'Debug',
  components: { LoadingSpinner },
  data() {
    return {
      stateLogged: false,
      storageCleared: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'loading']),
    ...mapState('application', ['application']),
    applicationData() {
      return { application: this.application }
    },
  },
  methods: {
    ...mapActions('user', ['fetchProfile']),
    goToFieldstone() {
      if (!this.application) return

      const baseUrl = process.env.VUE_APP_BACKEND_ADMIN_URL
      const adminUrl = `${baseUrl}applications/application/${this.application.id}/change/`

      window.open(adminUrl)
    },
    logState() {
      /* eslint-disable-next-line */
      console.log('✍🏼 Apply.Bungalow State 📦')
      window.$getState && window.$getState()
      this.stateLogged = true
      setTimeout(() => (this.stateLogged = false), 1500)
    },
    clearStorage() {
      /* eslint-disable-next-line */
      console.log('📁 Local Storage Cleared 🗑')
      localStorage.clear()
      this.storageCleared = true
      setTimeout(() => (this.storageCleared = false), 1500)
    },
  },
  beforeMount() {
    this.fetchProfile()
  },
}
</script>

<style lang="scss">
.debug {
  a {
    color: $blue-60;
    text-decoration: underline;
  }

  .debug-instructions {
    flex: 1;
  }

  .riddle p {
    line-height: 2.5rem;
  }

  .container {
    max-width: 1000px;
  }

  .store-dump {
    overflow-x: auto;
  }
}
</style>
