<template>
  <v-btn
    id="blw-button"
    :class="['blw-control blw-button', size, { 'dark-text': darkText }]"
    :style="disabledStyles"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <slot>{{ label }}</slot>
  </v-btn>
</template>

<script>
// Button Types
//
// 1. Primary - black background, white text
// color = "primary"
//
// 2. Secondary - salmon background, white text
// color = "secondary" (default value)
//
// 3. Primary Outline - Charcoal outline, transparent background, charcoal text
// color = "primary"
// outline = "true"
//
// 4. Flat - No outline, transparent background, charcoal text
// flat = "true"
// darkText = "true" OR color="primary" (otherwise hover will take styles from secondary)

export default {
  name: 'BlwButton',
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
    label: {
      type: String,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (size) => ['xs', 'sm', 'lg'].includes(size),
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    darkText: {
      type: Boolean,
      default: false,
    },
    disabledBackground: {
      type: String,
      default: '#C8C7C7',
    },
  },
  computed: {
    disabledStyles() {
      const disabledStyle = `background-color: ${this.disabledBackground} !important;`
      return this.$attrs.disabled ? disabledStyle : ''
    },
  },
}
</script>
<style lang="scss">
$dark-text-button-hover-background: $charcoal-20;
$btn-max-width: 324px;
$btn-xs-height: 40px;
$btn-sm-height: 50px;
$btn-lg-height: 75px;

.blw-button {
  margin: 0 auto;
  width: 100%;

  &.v-btn {
    &:not(.v-btn--round) {
      border-radius: 4px;
    }
  }

  &:not(.v-btn--block) {
    max-width: $btn-max-width;
  }

  &.v-btn--flat {
    .v-btn__content {
      color: $charcoal-60;
    }
  }

  @include mobile {
    max-width: 100%;
  }

  &.xs {
    height: $btn-xs-height;
    font-size: 14px;
    max-width: 130px;
  }

  &.sm {
    height: $btn-sm-height;
  }

  &.lg {
    height: $btn-lg-height;

    @include mobile {
      height: $btn-sm-height;
    }
  }

  .v-btn__content {
    color: white;
  }

  &.dark-text {
    .v-btn__content {
      color: $charcoal-60;
    }

    &:hover {
      &::before {
        background-color: $dark-text-button-hover-background;
      }
    }
  }
  .v-progress-circular {
    color: white;
  }
  &.pink-gradient {
    background: linear-gradient(225.24deg, #ee0979 0.42%, #ff6a00 100%) !important;
    color: white !important;
  }
  &.outlined {
    border: 1px solid;
    background-color: white !important;
    .v-btn__content {
      color: black !important;
    }
  }
}
</style>
