<template>
  <div class="login-more-options grid-enabled small-modal">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="login-buttons mt-3">
        <button
          class="login-buttons__button"
          v-for="provider in providers"
          :key="provider.key"
          :class="[provider.key]"
          @click="$props.authenticate(provider.key)"
        >
          <component :is="provider.component" />
          <div class="login-buttons__button-text">Continue with {{ provider.name }}</div>
        </button>

        <button class="login-buttons__button text-only close" @click="$root.$emit('closeModal')">
          <div class="login-buttons__button-text">Close</div>
        </button>

        <div class="mt-3 mx-auto" v-max-width="312">
          <p class="login-page__disclaimer">
            By clicking Continue with Apple, Continue with Facebook or Continue with LinkedIn, you
            agree to Bungalow’s
            <a href="https://bungalow.com/terms-of-use" target="_blank">Terms of Use</a>&nbsp;and
            <a href="https://bungalow.com/privacy-policy" target="_blank">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginMoreOptionsModal',
  props: {
    providers: {
      type: Array,
      required: true,
    },
    authenticate: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.login-more-options {
  .login-buttons__button {
    &:focus,
    &:hover {
      border-color: $charcoal-20;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
</style>
