<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'HomeShoppingContainer',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('application', ['applicationLeasePreferenceId', 'application']),
    ...mapGetters('user', ['userProfile']),
  },
  methods: {
    ...mapActions('leasePreference', ['fetchLeasePreferenceById']),
    ...mapActions('meetings', ['fetchOpenMeetings', 'fetchOpenMeetingsMock']),
    ...mapActions('meetGreet', ['fetchChatUser']),
    ...mapActions('constants', ['fetchConstants']),
    ...mapActions('interests', ['fetchInterests']),
  },
  async beforeMount() {
    this.loading = true
    await this.fetchInterests()
    await this.fetchOpenMeetings()
    await this.fetchChatUser()
    await this.fetchConstants({ category: 'Lease Length Premiums' })
    // @TODO fetch open meetings from fieldstone
    if (this.applicationLeasePreferenceId) {
      await this.fetchLeasePreferenceById(this.applicationLeasePreferenceId)
    } else {
      this.$trackEvent({
        action: 'Home Selection - No Lease Preference',
        properties: {
          applicationId: this.application.id,
          userEmail: this.userProfile.email,
        },
      })
    }
    this.loading = false
  },
}
</script>
