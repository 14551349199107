<template>
  <router-view />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SubmitContainer',
  props: {
    validationNamespace: {
      type: String,
      required: true,
    },
    fallbackRoute: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isValid(state, getters) {
        return getters[`${this.validationNamespace}/isValid`]
      },
    }),
  },
  beforeMount() {
    if (!this.isValid) this.$routerReplace({ name: this.fallbackRoute })
  },
}
</script>
