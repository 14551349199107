import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const US_COUNTRY_CODE = 'US'

const getDefaultState = () => ({
  ...stateHelpers,
  applicant: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  applicant: ({ applicant }) => applicant,
  backgroundCheck: ({ applicant }) => _.get(applicant, 'background_check'),
  emergencyContactId: ({ applicant }) => _.get(applicant, 'emergency_contact'),
  incomeId: ({ applicant }) => _.get(applicant, 'income'),
  photo_id: ({ applicant }) => _.get(applicant, 'photo_id'),

  residences: ({ applicant }) => _.get(applicant, 'residences'),
  currentResidence: ({ applicant }) =>
    _.find(_.get(applicant, 'residences', []), ({ current }) => !!current),
  hasUSAddress: ({ applicant }) => {
    const applicantsSavedAddresses = _.get(applicant, 'residences', [])
    return !!applicantsSavedAddresses.find(
      ({ address: { country_code } }) => country_code === US_COUNTRY_CODE
    )
  },
  residenceById:
    (state, { residences }) =>
    (residenceId) =>
      _.find(residences, { id: residenceId }),
  areResidencesEmpty: (state, { residences }) => _.isEmpty(residences),
}

const actions = {
  async fetchApplicant({ commit, rootGetters }) {
    const applicant_id = rootGetters['application/applicantInfoId']

    commit('setLoading', true)
    commit('setError', false)

    return await this._vm.$http
      .get(`applications/applicant-info/${applicant_id}`)
      .then(({ data }) => {
        commit('setApplicant', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  updateApplicantByKey({ commit, getters }, { field, value }) {
    const { applicant } = getters
    const payload = { ...applicant, [field]: value }

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/applicant-info/${applicant.id}`, payload)
      .then((result) => {
        commit('setApplicant', result.data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  async updateApplicant({ commit, getters, rootGetters }) {
    const applicant_id = rootGetters['application/applicantInfoId']
    const { applicant } = getters

    commit('setUpdating', true)
    commit('setError', false)

    return await this._vm.$http
      .put(`applications/applicant-info/${applicant_id}`, applicant)
      .then(({ data }) => {
        commit('setApplicant', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  pushResidence({ commit }, residence) {
    commit('pushResidence', residence)
  },

  findAndUpdateResidence({ commit }, residence) {
    commit('findAndUpdateResidence', residence)
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setApplicant(state, applicant) {
    state.applicant = applicant
  },

  pushResidence(state, residence) {
    if (!_.find(state.applicant.residences, { id: residence.id })) {
      state.applicant.residences.push(residence)
    }
  },

  findAndUpdateResidence(state, residence) {
    const matchedResidenceIndex = _.findIndex(state.applicant.residences, {
      id: residence.id,
    })

    state.applicant.residences[matchedResidenceIndex] = {
      ...state.applicant.residences[matchedResidenceIndex],
      ...residence,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
