<template>
  <div>
    <v-container v-if="showNewHeader" class="mt-5 fluid" v-max-width="1400">
      <v-layout class="relative">
        <v-btn flat class="mt-4" absolute left top @click="backToHomeShoppingClickHandler">
          <v-btn fab color="white" small>
            <v-icon color="charcoal" fab>arrow_back</v-icon>
          </v-btn>
          <span class="ml-3">Back to all chats</span>
        </v-btn>
        <v-flex class="text-xs-center">
          <div class="header-md mb-3">
            You're currently chatting with<br />
            {{ formattedPropertyAddress }}
          </div>
          <div>
            <span class="font-weight-bold">Remember: </span>you can chat with as many homes as you
            like!
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <div :class="['meet-and-greet', { 'pt-5': showNewHeader }]">
      <template v-if="!updating">
        <participants v-bind="participants" />
        <transition name="fade">
          <messaging v-bind="messaging" />
        </transition>
        <preferences v-bind="preferences" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Messaging from '../components/MeetGreetMessaging'
import Participants from '../components/MeetGreetParticipants'
import Preferences from '../components/MeetGreetPreferences'

export default {
  name: 'MeetGreetChat',
  components: {
    Participants,
    Messaging,
    Preferences,
  },
  computed: {
    ...mapGetters('application', ['applicationStatus', 'getAvailablePropertyAddressById']),
    ...mapGetters('user', ['salmonAvatar']),
    ...mapGetters('meetGreet', ['meeting', 'timeLeft', 'chatUser', 'updating']),
    // Component Props
    messaging() {
      return {
        ...this.meeting,
        chatUser: this.chatUser,
        avatarFallback: this.salmonAvatar,
      }
    },
    participants() {
      return {
        ...this.meeting,
      }
    },
    preferences() {
      return {
        roommates: this.meeting.residents,
        overlaps: this.meeting.lp_overlaps,
      }
    },
    formattedPropertyAddress() {
      return this.getAvailablePropertyAddressById(this.meeting.property.id)
    },
    showNewHeader() {
      return (
        !this.updating &&
        this.$vuetify.breakpoint.mdAndUp &&
        this.applicationStatus === 'meet_and_greet'
      )
    },
  },
  mounted() {
    if (window.Intercom) window.Intercom('shutdown')
  },
  methods: {
    backToHomeShoppingClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Go back',
          text: 'back to all chats',
          category: 'meet_greet',
          property_id: this.meeting.property.id,
          bedroom_id: this.meeting.room.id,
        },
      })
      this.$routerPush({ name: 'homeShopping' })
    },
  },
}
</script>

<style lang="scss">
.meet-and-greet {
  display: grid;
  width: 100%;
  max-width: 1400px;
  grid-column-gap: 2.5rem;
  padding: 5.75rem 2.5rem;
  margin: auto;
  grid-template-rows: auto;
  grid-template-columns: 284px 1fr 284px;
  grid-template-areas: 'participants messaging preferences';

  .mg-participants {
    grid-area: participants;
    height: max-content;
  }

  .mg-messaging {
    grid-area: messaging;
    max-height: 612px;
    min-width: 400px;

    @include mobile {
      min-width: initial;
    }
  }

  .mg-preferences {
    grid-area: preferences;
    height: max-content;
  }

  @include mobile {
    grid-template-areas: 'messaging';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0;

    .mg-messaging {
      max-height: 100vh;
    }

    .mg-participants,
    .mg-preferences {
      display: none;
    }
  }
}
</style>
