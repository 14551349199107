import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  applications: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,

  applications: ({ applications }) => applications,

  statusText: () => ({
    open: 'Get started',
    submitted: 'In review',
    preapproved: 'Create a profile',
    profile_completed: 'Select your home',
    room_selected: 'Meet your roommates',
    meet_and_greet: 'Select your home',
    approved: 'Approved',
  }),
}

const actions = {
  async fetchApplications({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return await this._vm.$http
      .get('applications/applications/')
      .then(({ data }) => data.results)
      .then((applicationsResult) => {
        commit('setApplications', applicationsResult)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setApplications(state, applications) {
    state.applications = applications
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
