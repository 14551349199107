<template>
  <main class="Oauth">
    <loading-spinner full-page />
  </main>
</template>

<script>
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'Oauth',
  components: {
    LoadingSpinner,
  },
}
</script>
