import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep as _cloneDeep, get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const newResidence = {
  current: false,
  move_in_date: '',
  monthly_rent: '',
  address: {
    street_address: '',
    city: '',
    region: '',
    region_code: '',
    postal_code: '',
    county: '',
    country: 'United States',
    country_code: 'US',
  },
}

const getDefaultState = () => ({
  ...stateHelpers,
  residence: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,
  residence: ({ residence }) => residence,
  address: ({ residence }) => _get(residence, 'address'),
  residenceCountryCode: ({ residence }) => _get(residence, 'address.country_code'),
  residenceRegionCode: ({ residence }) => _get(residence, 'address.region_code'),
}

const actions = {
  createResidence({ commit, dispatch, rootGetters, state: { residence } }) {
    const applicantInfoId = rootGetters['application/applicantInfoId']
    const areResidencesEmpty = rootGetters['applicant/areResidencesEmpty']

    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post('applications/residences/', {
        ...residence,
        applicant_info: applicantInfoId,
        current: areResidencesEmpty,
      })
      .then(async ({ data }) => {
        // dispatch global fetchApplicant action to ensure the residences we have in state is up to date
        await dispatch('applicant/fetchApplicant', null, { root: true })
        commit('setResidence', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  fetchResidence({ commit }, residenceId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/residences/${residenceId}`)
      .then(({ data }) => {
        commit('setResidence', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  resetResidence({ commit }) {
    commit('resetResidence')
  },

  updateResidence({ commit, dispatch, state: { residence } }) {
    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/residences/${residence.id}`, residence)
      .then(async ({ data }) => {
        // dispatch global fetchApplicant action to ensure the residences we have in state is up to date
        await dispatch('applicant/fetchApplicant', null, { root: true })
        commit('setResidence', data)
        commit('setError', false)
      })
      .catch((error) => {
        commit('setError', error)
      })
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setResidence(state, residence) {
    state.residence = residence
  },

  resetResidence(state) {
    state.residence = _cloneDeep(newResidence)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
