<template>
  <div class="d-flex align-center">
    {{ label }}
    <v-tooltip top>
      <!-- Trigger -->
      <template v-slot:activator="{ on }">
        <v-layout class="ml-1 clickable" style="min-width: 1rem" v-on="on"><InfoIcon /></v-layout>
      </template>
      <!-- Content -->
      <div v-max-width="maxWidth" class="pa-1">
        <p v-if="title" class="charcoal-40--txt mb-1">{{ title }}</p>
        <b>{{ tooltip }}</b>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import InfoIcon from '@/shared/components/icons/InfoIcon.vue'

export default {
  name: 'GroupTooltip',
  components: { InfoIcon },
  props: {
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    maxWidth: {
      type: Number,
      default: 230,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
