import Vue from 'vue'

Vue.directive('max-width', {
  bind(el, binding) {
    el.style.maxWidth = `${binding.value}px`
  },
})

Vue.directive('min-width', {
  bind(el, binding) {
    el.style.minWidth = `${binding.value}px`
  },
})
