<template>
  <div :class="['blw-icon', size]">
    <component :is="customIconModule" />
  </div>
</template>

<script>
export default {
  name: 'BlwIcon',
  data() {
    return {
      customIconModule: null,
      sizesInPx: {
        sm: '40px',
        lg: '60px',
        xl: '120px',
      },
    }
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (size) =>
        [
          'sm', // 40px
          'lg', // 60px
          'xl', // 120px
        ].includes(size),
    },
    color: {
      type: String,
      default: 'charcoal',
    },
  },
  watch: {
    color() {
      this.setIconCSS()
    },
    size: {
      handler(newSize, oldSize) {
        if (newSize !== oldSize) this.importIcon()
        this.$nextTick(() => this.setIconCSS())
      },
      immediate: true,
    },
    icon: {
      handler(newIcon, oldIcon) {
        if (newIcon !== oldIcon) this.importIcon()
      },
      immediate: true,
    },
  },
  created() {
    this.ensureIcon()
  },
  mounted() {
    this.setIconCSS()
  },
  methods: {
    importIcon() {
      this.customIconModule = () => import(`@/assets/icons/${this.icon}_${this.size}.svg`)
    },
    setIconCSS() {
      const currentTheme = this.$vuetify.theme
      const colorHex = currentTheme[this.color] ? currentTheme[this.color].base : this.color
      const sizeInPx = this.sizesInPx[this.size]

      this.$el.style.setProperty('--size', sizeInPx)
      this.$el.style.setProperty('--color', colorHex)
    },
    async ensureIcon() {
      try {
        require(`@/assets/icons/${this.icon}_${this.size}.svg`)
      } catch (iconFileNotFound) {
        throw new Error('Icon Not Found:', this.icon)
      }
    },
  },
}
</script>

<style lang="scss">
.blw-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  * {
    stroke: var(--color, $charcoal-60);
  }
}
</style>
