<template>
  <v-form :ref="formName" v-model="isValid" class="blw-form grid" @submit.prevent="attemptSubmit">
    <blw-hero
      v-if="hasHeroContent"
      :header="header"
      :lead="lead"
      :text="text"
      :icon="icon"
      :iconComponent="iconComponent"
    />
    <slot v-if="customSlotContainer" />
    <v-container v-else>
      <slot></slot>
    </v-container>

    <footer :class="{ 'sticky-bottom-footer': stickyFooter }">
      <v-snackbar :value="error" :timeout="errorDisplayDuration">
        {{ error }}
      </v-snackbar>
      <v-layout>
        <v-flex d-flex column align-center v-if="onSubmit">
          <p v-if="updating && updatingText" class="helper-text font-xsmall font-grey">
            {{ updatingText }}
          </p>
          <p v-else :class="['helper-text', helperTextClasses, { invisible: isValid }]">
            All fields are required
          </p>
          <blw-button color="primary" :loading="updating" :label="buttonLabel" type="submit" />
          <blw-button
            darkText
            v-if="secondarySubmitHandler && secondarySubmitLabel"
            class="my-3"
            flat
            :label="secondarySubmitLabel"
            @click="secondarySubmitHandler"
          />
        </v-flex>
      </v-layout>
    </footer>
  </v-form>
</template>

<script>
export default {
  name: 'BlwForm',
  data() {
    return {
      isValid: false,
      invalidSubmitAttempt: false,
    }
  },
  props: {
    secondarySubmitHandler: {
      type: Function,
    },
    secondarySubmitLabel: {
      type: String,
    },
    formName: {
      type: String,
      default: 'blwForm',
    },
    buttonLabel: {
      type: String,
      default: 'Save and continue',
    },
    updating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
    },
    error: {
      type: [String, Boolean, Error],
    },
    errorDisplayDuration: {
      type: Number,
      default: 6000,
    },
    header: {
      type: String,
    },
    lead: {
      type: String,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconComponent: {
      type: Object,
    },
    customSlotContainer: {
      type: Boolean,
      default: false,
    },
    stickyFooter: {
      type: Boolean,
      default: false,
    },
    updatingText: {
      type: String,
    },
  },
  computed: {
    helperTextClasses() {
      return this.invalidSubmitAttempt ? 'font-red font-weight-bold' : 'font-grey'
    },
    hasHeroContent() {
      return this.header || this.lead || this.text || this.icon || this.iconComponent
    },
  },
  methods: {
    showHelperText() {
      this.invalidSubmitAttempt = true
      setTimeout(() => (this.invalidSubmitAttempt = false), 2000)
    },
    attemptSubmit() {
      const isValid = this.$refs[this.formName].validate()

      isValid && !this.updating ? this.onSubmit() : this.showHelperText()
    },
  },
}
</script>

<style lang="scss">
.blw-form {
  footer {
    padding-top: 0;
  }

  .helper-text {
    text-align: center;
    padding-bottom: 0.5rem;
  }

  @include mobile {
    grid-template-rows: auto 1fr auto;
  }

  @include desktop {
    grid-template-rows: auto auto 1fr;
  }

  &.v-form > .container {
    @include mobile {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }

  > .container {
    > .layout > .flex {
      @include desktop {
        padding: $spacer-sm $spacer-md;
      }

      @include mobile {
        padding-top: $spacer-sm;
        padding-bottom: $spacer-sm;
      }
    }

    > .layout:only-child {
      margin: auto (-$spacer-sm);
    }

    > .layout:not(:only-child) {
      margin: auto (-$spacer-sm);
    }
  }
}
</style>
