<template>
  <loading-spinner full-page />
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'PreMeetAndGreet',
  components: {
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('application', ['applicationMarketSlug']),
  },
  beforeMount() {
    // as soon as this page mounts
    // direct them back to the website with the magic showChats
    // query param to auto open their list of chats
    this.backToWebsite()
  },
  methods: {
    backToWebsite() {
      window.location.replace(
        `${process.env.VUE_APP_BUNGALOW_WEBSITE_BASE_URL}rooms-for-rent/${this.applicationMarketSlug}?showChats=true`
      )
    },
  },
}
</script>
