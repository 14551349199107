import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  emergencyContact: {},
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  emergencyContact: ({ emergencyContact }) => emergencyContact,
}

const actions = {
  async fetchEmergencyContact({ commit, rootGetters }) {
    const emergencyContactId = rootGetters['applicant/emergencyContactId']

    commit('setLoading', true)
    commit('setError', false)

    return await this._vm.$http
      .get(`applications/emergency-contact/${emergencyContactId}`)
      .then(({ data }) => data)
      .then((emergencyContactData) => {
        commit('setEmergencyContact', emergencyContactData)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async updateEmergencyContact({ commit, getters }) {
    const { emergencyContact } = getters

    commit('setUpdating', true)
    commit('setError', false)

    return await this._vm.$http
      .put(`applications/emergency-contact/${emergencyContact.id}`, emergencyContact)
      .then(({ data }) => data)
      .then((emergencyContactData) => {
        commit('setEmergencyContact', emergencyContactData)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setEmergencyContact(state, emergencyContact) {
    state.emergencyContact = emergencyContact
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
