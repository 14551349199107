<template>
  <v-layout v-if="$vuetify.breakpoint.smAndDown" row wrap>
    <v-flex md6>
      <blw-text-field
        :error-messages="apiFieldError('company_name')"
        label="Employer"
        v-model="company_name"
        :rules="[required]"
        @blur="savedFieldLog('Employer')"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-text-field
        :error-messages="apiFieldError('job_title')"
        label="Job title"
        v-model="job_title"
        :rules="[required]"
        @blur="savedFieldLog('Job title')"
        box
      />
    </v-flex>

    <v-flex md6 class="monthly-salary-input">
      <blw-text-field
        :error-messages="apiFieldError('monthly_salary')"
        :hint="annualIncome"
        :rules="[required]"
        label="Total monthly income (before taxes)"
        autocomplete="off"
        mask="$######"
        type="tel"
        v-model="monthly_salary"
        @blur="savedFieldLog('Total monthly income')"
        box
      />
    </v-flex>
    <v-flex xs12>
      <p class="font-bold font-large">Proof of income</p>
      <div class="subheader-sm mb-3">
        Please upload a pay stub, bank statement or tax document to verify your income
      </div>
      <blw-upload-v2
        :documentUrl="documentUrl"
        :fileOnChangeHandler="uploadAndSaveDocument"
        :deleteFileHandler="documentDeleteSuccessHandler"
        inputId="income-document"
      />
    </v-flex>
  </v-layout>
  <v-layout v-else row wrap>
    <v-flex xs6 style="margin-left: -0.5rem">
      <v-flex>
        <blw-text-field
          :error-messages="apiFieldError('company_name')"
          label="Employer"
          v-model="company_name"
          :rules="[required]"
          @blur="savedFieldLog('Employer')"
          box
        />
      </v-flex>

      <v-flex>
        <blw-text-field
          :error-messages="apiFieldError('job_title')"
          label="Job title"
          v-model="job_title"
          :rules="[required]"
          @blur="savedFieldLog('Job title')"
          box
        />
      </v-flex>

      <v-flex class="monthly-salary-input">
        <blw-text-field
          :error-messages="apiFieldError('monthly_salary')"
          :hint="annualIncome"
          :rules="[required]"
          label="Total monthly income (before taxes)"
          autocomplete="off"
          @blur="savedFieldLog('Total monthly income')"
          mask="$######"
          type="tel"
          v-model="monthly_salary"
          box
        />
      </v-flex>
    </v-flex>
    <v-flex xs6 style="margin-right: -0.5rem">
      <p class="font-bold font-large">Proof of Income</p>
      <div class="font-base charcoal-40--txt mb-3">
        Please upload a pay stub, bank statement or tax document to verify your income
      </div>
      <blw-upload-v2
        :documentUrl="documentUrl"
        :fileOnChangeHandler="uploadAndSaveDocument"
        :deleteFileHandler="documentDeleteSuccessHandler"
        inputId="income-document"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { addMonths, format } from 'date-fns'
import _ from 'lodash'

import { emailField, maxLength, required } from '@/utils/formValidations'
import { getAnnualIncome } from '@/utils/helpers'

export default {
  name: 'IncomeInformation',
  async beforeMount() {
    this.contact_first_name = 'N/A'
    this.contact_last_name = 'N/A'
    this.contact_phonenumber = '+15195800887'
    this.contact_email = 'na@bungalow.com'

    this.start_date = '1971-01-01'
    this.currency = 'USD'

    const incomeDocumentId = this.income.document

    this.resetDocument()
    incomeDocumentId && this.fetchDocument(incomeDocumentId)
  },
  computed: {
    ...mapFields('income', [
      'income.company_name',
      'income.job_title',
      'income.monthly_salary',
      'income.currency',
      'income.start_date',
      'income.contact_first_name',
      'income.contact_last_name',
      'income.contact_phonenumber',
      'income.contact_email',
    ]),
    ...mapGetters('income', ['updating', 'error', 'apiFieldError']),
    ...mapGetters({
      uploadError: 'documents/error',
      incomeUpdateError: 'income/error',

      loadingIncome: 'income/loading',
      loadingFileUpload: 'documents/loading',
      updatingIncome: 'income/updating',

      income: 'income/income',
      incomeId: 'income/incomeId',
      document: 'documents/document',
    }),
    ...mapGetters('documents', ['documentById']),
    incomeDocument() {
      return this.documentById(this.income.document)
    },
    documentUrl() {
      return this.incomeDocument ? this.incomeDocument.url : null
    },
    annualIncome() {
      return this.getAnnualIncome(this.monthly_salary)
    },
    maxStartDate() {
      return format(addMonths(new Date(), 3), 'YYYY-MM-DD')
    },
    requiredFields() {
      return [this.company_name, this.job_title, this.monthly_salary, this.incomeDocument]
    },
  },
  watch: {
    requiredFields(val, oldVal) {
      if (val.every((fieldValue) => !!fieldValue) && !oldVal.every((fieldValue) => !!fieldValue)) {
        this.$trackEvent({
          action: 'Apply section complete',
          properties: {
            section: 'Income information',
          },
        })
      }
    },
  },
  methods: {
    emailField,
    maxLength,
    required,
    getAnnualIncome,
    ...mapActions('income', ['updateIncomeByKey', 'updateIncome', 'fetchIncome']),
    ...mapActions('documents', [
      'fetchDocument',
      'createDocument',
      'resetDocument',
      'deleteDocument',
    ]),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    async uploadAndSaveDocument(file) {
      this.showLoader()
      this.resetDocument()
      await this.createDocument({
        purpose: 'applicant_income',
        file,
      })
      if (!this.uploadError && this.document) {
        this.$trackEvent({
          action: 'Field Saved',
          properties: {
            type: 'Application Page',
            text: 'Income document',
          },
        })
        await this.updateIncomeByKey({ field: 'document', value: this.document.id })
      }
      this.hideLoader()
    },
    async documentDeleteSuccessHandler() {
      this.resetDocument()
      this.income.document && (await this.deleteDocument(this.income.document))
      this.fetchIncome(this.incomeId)
    },
    debouncedUpdateIncome: _.debounce(function () {
      this.updateIncome()
    }, 500),
    savedFieldLog(field) {
      if (!this.monthly_salary) return
      this.$trackEvent({
        action: 'Field Saved',
        properties: {
          type: 'Application Page',
          text: field,
        },
      })
      this.debouncedUpdateIncome()
    },
  },
}
</script>
<style></style>
