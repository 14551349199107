<template>
  <main class="grid-expanding-center">
    <header>
      <blw-hero header="Income information" text="Please include all sources of income." />
    </header>
    <section>
      <v-flex class="white">
        <blw-list :items="incomeListItems" />
      </v-flex>
    </section>
    <footer>
      <v-layout justify-center>
        <blw-button label="Save and continue" @click="onContinue" />
      </v-layout>
    </footer>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { omit as _omit, every as _every } from 'lodash'

import BlwList from '@/shared/List'
import { getAnnualIncome } from '@/utils/helpers'

export default {
  name: 'IncomeOverview',
  props: {
    employmentDetailsRouteName: {
      type: String,
      required: true,
    },
    additionalIncomeRouteName: {
      type: String,
      required: true,
    },
    cosignersEnabled: {
      type: Boolean,
      required: true,
    },
    nextRoute: {
      type: String,
      required: true,
    },
  },
  components: { BlwList },
  computed: {
    ...mapGetters('income', ['income']),
    ...mapGetters('application', ['cosignerSources']),
    incomeListItems() {
      const listItems = []
      const { company_name, monthly_salary, additional_income } = this.income

      const jobHasDetails = company_name && monthly_salary

      const mainIncomeDetails = `${company_name} - ${getAnnualIncome(monthly_salary)}`

      // Employment
      listItems.push({
        header: `${jobHasDetails ? 'Primary income' : 'Add primary income'}`,
        subHeader: jobHasDetails && mainIncomeDetails,
        linkName: this.employmentDetailsRouteName,
        additional: !jobHasDetails,
        status: this.employmentDetailsComplete,
      })

      // Additional Income(s)
      additional_income.forEach(({ id, source_name, monthly_salary }) => {
        listItems.push({
          header: 'Additional income',
          subHeader: `${source_name} - ${getAnnualIncome(monthly_salary)}`,
          linkObj: {
            name: this.additionalIncomeRouteName,
            params: { sourceId: id },
          },
          status: 'complete',
        })
      })

      this.cosignersEnabled &&
        this.cosignerSources.map((cosigner) =>
          listItems.push({
            header: 'Cosigner',
            subHeader: `${cosigner.first_name} ${cosigner.last_name}`,
            linkObj: {
              name: 'cosigner',
              params: {
                cosignerId: cosigner.id,
              },
            },
            status: 'complete',
          })
        )

      // Add Income Source
      listItems.push({
        header: 'Add supplementary income',
        linkName: this.additionalIncomeRouteName,
        subHeader: 'Optional',
        additional: true,
      })

      // Add Income Source
      this.cosignersEnabled &&
        listItems.push({
          header: this.addCosignerLabel,
          subHeader: this.addCosignerSubHeader,
          linkName: 'newCosigner',
          additional: true,
        })

      return listItems
    },
    existingCosignerSource() {
      return !!this.cosignerSources.length
    },
    addCosignerLabel() {
      return `Nominate${this.existingCosignerSource ? ' additional' : ''} co-signer`
    },
    addCosignerSubHeader() {
      return this.existingCosignerSource ? 'Optional' : 'Recommended if your credit score is < 600'
    },
    employmentDetailsComplete() {
      const allIncomeFields = _omit(this.income, 'additional_income')
      // loop through all income object fields to check if all have been entered
      const allIncomeFieldsCompleted = _every(allIncomeFields)

      return allIncomeFieldsCompleted ? 'complete' : null
    },
  },
  methods: {
    ...mapActions('income', ['fetchIncome']),
    onContinue() {
      this.$routerPush({ name: this.nextRoute })
    },
  },
}
</script>
