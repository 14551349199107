// we need to run this at the top of the file before any page transitions occur
const queryParams = new URLSearchParams(location.search)

const state = {
  proxyUserEmail: queryParams.get('proxyUserEmail'),
}

const getters = {
  proxyUserEmail: ({ proxyUserEmail }) => proxyUserEmail,
}

export default {
  namespaced: true,
  state,
  getters,
}
