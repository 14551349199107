<template>
  <loading-spinner full-page v-if="loading" />
  <no-cosign-source v-else-if="!cosigner" />
  <router-view v-else />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import LoadingSpinner from '@/shared/LoadingSpinner'
import NoCosignSource from './components/NoCosignSource'

export default {
  name: 'CosignContainer',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    LoadingSpinner,
    NoCosignSource,
  },
  async beforeMount() {
    this.loading = true
    const { cosignerSource } = this.$route.params
    await this.fetchCosignerBySource(cosignerSource)
    this.cosigner && (await this.fetchCosignerValidationById(this.cosignerId))
    this.checkIfApplicationSubmitted()
    this.loading = false
  },
  computed: {
    ...mapGetters('cosigner', ['cosigner', 'cosignerId', 'cosignerApplicationStatus']),
  },
  methods: {
    ...mapActions('cosignerValidation', ['fetchCosignerValidationById']),
    ...mapActions('cosigner', ['fetchCosignerBySource']),
    checkIfApplicationSubmitted() {
      if (this.cosignerApplicationStatus === 'submitted') {
        this.$routerReplace({ name: 'cosigner-submitSuccess' })
      }
    },
  },
  watch: {
    $route() {
      this.checkIfApplicationSubmitted()
    },
  },
}
</script>
<style lang="sass"></style>
