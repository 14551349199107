<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'Home',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('applications', ['applications']),
    ...mapGetters('application', ['application']),
    ...mapGetters('applicationSource', ['applicationSource', 'applicationSourceHasBeenUsed']),
  },
  methods: {
    ...mapActions('application', ['fetchApplicationBySource']),
    ...mapActions('applications', ['fetchApplications']),
    ...mapActions('applicationSource', ['fetchApplicationSource']),
    goToApplication(applicationSource) {
      this.$routerPush({
        name: 'apply',
        params: { applicationSource },
      })
    },
  },
  watch: {
    application(app) {
      app && this.goToApplication(app.source)
    },
    applications(apps) {
      const onlyOneApplicationAndNoSource = apps.length === 1 && !this.$route.query.source

      if (onlyOneApplicationAndNoSource) {
        const applicationSource = _.get(apps, '0.source')
        this.goToApplication(applicationSource)
      }
    },
  },
  async beforeMount() {
    this.loading = true

    // NOTE: It's important not to assign `query` here:
    const { source, force } = this.$route.query

    await this.fetchApplications()

    if (source) {
      // to prevent a current bug where leads are creating multiple applications
      // check for non approved or rejected applications before accepting a new source
      // if one exists, load that as the application
      const foundOpenApplication = this.applications.find((application) => {
        return !['approved', 'rejected'].includes(application.status)
      })
      if (!force && foundOpenApplication) {
        return this.goToApplication(foundOpenApplication.source)
      }
      // Fetch source details
      await this.fetchApplicationSource(source)
      // Not a valid source (doesn't exist / no access)
      if (!this.applicationSource) {
        this.$router
          .push({ name: 'applicationSourceNotFound', query: this.$route.query })
          .catch(() => {})
      } else {
        // Source already converted, fetch associated application
        if (this.applicationSourceHasBeenUsed) {
          await this.fetchApplicationBySource(source)
        } else {
          // Fresh source, continue to BYB
          this.$router
            .push({ name: 'applicationBeforeYouBegin', query: this.$route.query })
            .catch(() => {})
        }
      }
    }
    // Fetch list of applications (if any)

    this.loading = false
  },
}
</script>
