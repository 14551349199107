<template>
  <v-layout row wrap id="current-address">
    <v-flex md6>
      <blw-text-field
        id="street-address-input"
        :label="street_address ? 'Street address' : null"
        :placeholder="street_address ? null : 'Street address'"
        v-model="street_address"
        @blur="savedFieldLog('Street address')"
        :rules="[required]"
        box
      />
    </v-flex>
    <v-flex md6>
      <blw-text-field
        label="City"
        v-model="city"
        :rules="[required]"
        box
        @blur="savedFieldLog('City')"
      />
    </v-flex>
    <v-flex md6>
      <blw-select
        name="country"
        v-model="country_code"
        label="Country"
        :rules="[required]"
        :items="CountryCodes"
        @blur="savedFieldLog('Country')"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-select
        name="state"
        :rules="[required]"
        v-model="region_code"
        :label="country_code === 'US' ? 'State' : 'Province'"
        :items="selectedCountryStates"
        @blur="savedFieldLog('State')"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-text-field
        :label="country_code === 'US' ? 'Zip code' : 'Postal code'"
        v-model="postal_code"
        :rules="[required]"
        @blur="savedFieldLog('Zip code')"
        box
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import { mapFields } from 'vuex-map-fields'
import { emailField, required } from '@/utils/formValidations'
import { CountryCodes, getAvailableStatesForCountry } from '@/utils/regionData'
import { GoogleAddressAutoCompleteMixin } from '@/mixins'

export default {
  name: 'CurrentAddress',
  mixins: [GoogleAddressAutoCompleteMixin],
  data() {
    return {
      CountryCodes,
    }
  },
  mounted() {
    this.enableGoogleAutoComplete('#street-address-input input')
  },
  watch: {
    residenceCountryCode(value) {
      if (!value) return
      const selectedCountry = _.find(this.CountryCodes, { value })
      this.country = selectedCountry.text
      if (this.region || this.region_code) {
        this.region_code = ''
        this.region = ''
      }
    },
    residenceRegionCode(value) {
      if (!value) return
      const selectedRegion =
        _.find(
          this.selectedCountryStates,
          ({ value: stateCode }) => stateCode.toLowerCase() === value.toLowerCase()
        ) ||
        _.find(
          this.selectedCountryStates,
          ({ text: stateLabel }) => stateLabel.toLowerCase() === value.toLowerCase()
        )
      if (selectedRegion) {
        this.region = selectedRegion.text
        this.region_code = selectedRegion.value
      } else {
        this.region = ''
        this.region_code = ''
      }
    },
    requiredFields(val, oldVal) {
      if (val.every((fieldValue) => !!fieldValue) && !oldVal.every((fieldValue) => !!fieldValue)) {
        this.$trackEvent({
          action: 'Apply section complete',
          properties: {
            section: 'Current address',
          },
        })
      }
    },
  },
  computed: {
    ...mapGetters('residence', [
      'updating',
      'error',
      'residence',
      'address',
      'residenceCountryCode',
      'residenceRegionCode',
    ]),
    ...mapGetters('applicant', ['areResidencesEmpty']),
    ...mapFields('residence', [
      'residence.address.street_address',
      'residence.address.city',
      'residence.address.region',
      'residence.address.region_code',
      'residence.address.county',
      'residence.address.country',
      'residence.address.country_code',
      'residence.address.postal_code',
      'residence.id',
    ]),
    selectedCountryStates() {
      return this.country_code ? getAvailableStatesForCountry(this.country_code) : []
    },
    requiredFields() {
      return [this.street_address, this.city, this.country_code, this.region_code, this.postal_code]
    },
  },
  methods: {
    emailField,
    required,
    ...mapActions('residence', ['updateResidence']),
    debouncedUpdateResidence: _.debounce(function () {
      this.updateResidence()
    }, 500),
    savedFieldLog(field) {
      this.$trackEvent({
        action: 'Field Saved',
        properties: {
          type: 'Application Page',
          text: field,
        },
      })
      this.debouncedUpdateResidence()
    },
  },
}
</script>
<style lang="scss">
/* Google Places Autocomplete (PAC) */
#current-address {
  .v-text-field--placeholder {
    .pac-target-input {
      margin-top: 13px;
    }
  }
  .pac-logo::after {
    margin: 0.35rem 0.5rem;
  }
  .pac-icon {
    display: none;
  }

  .pac-item-query {
    padding-right: 0.35rem;
  }

  .pac-item {
    transition: all 0.15s ease;
    display: flex;
    align-items: center;
    padding: 1rem;
    height: 3rem;
    font-family: $base-font;
    border-top: none;

    &:hover {
      cursor: pointer;
      color: white;
      background: $blue-60;

      .pac-item-query,
      .pac-matched {
        color: white;
      }
    }
  }
}
/* END – Google Places Autocomplete (PAC) */
</style>
