<template>
  <div id="hide-property-modal" class="grid-enabled px-2 sm-modal">
    <span class="modal-grid-header header-md text-xs-center">
      {{ propertyIsHidden ? 'Un-hide' : 'Hide' }} this home?
    </span>
    <div class="modal-grid-content my-3 py-2 mx-0 px-0">
      <p class="text-xs-center px-5" v-text="description"></p>
    </div>

    <div class="modal-grid-footer">
      <v-layout justify-center row>
        <v-flex xs12 sm12>
          <blw-button
            block
            color="charcoal"
            :loading="loading"
            @click="onConfirmClickHandler"
            label="Confirm"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'HidePropertyModal',
  props: {
    propertySlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('application', ['displayState', 'hiddenProperties']),
    propertyIsHidden() {
      return this.hiddenProperties.includes(this.propertySlug)
    },
    description() {
      return this.propertyIsHidden
        ? 'This will re-add this home to your application.'
        : 'This will hide this home from your application, you can re-add it at any time.'
    },
  },
  methods: {
    ...mapActions('application', ['updateApplicationByKey']),
    async onConfirmClickHandler() {
      this.loading = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          text: this.propertyIsHidden ? 'Un-hide property' : 'Hide property',
          category: 'Home Selection',
          type: 'Button',
        },
      })
      await this.updateApplicationByKey({
        field: 'display_state',
        value: {
          ...this.displayState,
          hiddenProperties: this.propertyIsHidden
            ? [...this.hiddenProperties].filter((slug) => slug !== this.propertySlug)
            : _.uniq([this.propertySlug, ...this.hiddenProperties]),
        },
      })
      // no hidden properties, toggle back to main properties list
      if (!this.hiddenProperties.length) {
        this.$root.$emit('toggleHiddenProperties', false)
      }
      this.$root.$emit('closeModal')
      this.loading = false
    },
  },
}
</script>
<style lang="scss"></style>
