<template>
  <v-layout :class="['blw-hero', { enableBreakTags }]" align-center justify-center text-xs-center>
    <v-container>
      <blw-icon v-if="icon" :icon="icon" size="lg" class="mx-auto" />
      <component class="mb-3" v-if="iconComponent" :is="iconComponent" />
      <img class="mb-4" v-if="headerImageSrc" :src="headerImageSrc" />
      <blw-illustration v-if="headerIllustration" class="mx-auto" :name="headerIllustration" />
      <p v-if="lead" class="lead mt-4" v-html="lead" />

      <h1 v-if="header" :class="['header-md', { 'mt-4': addMarginIfIcon }]" v-html="header" />

      <blw-illustration
        v-if="subHeaderIllustration"
        class="mx-auto"
        :name="subHeaderIllustration"
      />

      <blw-icon v-if="subHeaderIcon" :icon="subHeaderIcon" size="sm" class="mt-3 mb-2" />

      <p v-if="text" :class="['font-large', { 'mt-3': header }]" v-html="text" />
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: 'BlwHero',
  props: {
    headerImageSrc: {
      type: String,
    },
    headerIllustration: {
      type: String,
    },
    header: {
      type: String,
    },
    lead: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconComponent: {
      type: Object,
    },
    subHeaderIcon: {
      type: String,
    },
    subHeaderIllustration: {
      type: String,
    },
    text: {
      type: String,
    },
    enableBreakTags: {
      type: Boolean,
    },
  },
  computed: {
    addMarginIfIcon() {
      return this.$slots.icon || this.icon
    },
  },
}
</script>

<style lang="scss">
.blw-hero {
  margin: 1rem auto;
  padding: 3.5rem 0;

  @include mobile {
    padding: 2rem 0 1rem 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  &.enableBreakTags {
    br {
      display: block !important;
    }
  }

  h1 {
    padding: 0 2.75rem;
  }

  p {
    max-width: 30rem;
    margin: auto;
  }

  a {
    text-decoration: underline;
  }
}
</style>
