<template>
  <v-card class="br-8 pa-3" id="find-roommates-card">
    <v-img
      class="br-8 d-flex justify-center align-center font-weight-bold"
      height="156px"
      src="https://assets.bungalow.com/images/roommates_small.png"
    />
    <v-card-text class="text-xs-center">
      <div class="header-sm font-weight-bold pt-2">Roommate finder</div>
      <div class="font-large pt-3">
        We'll find you homes in
        {{ applicationMarketName }}
        with roommates that share the same interests as you and are within your price range
      </div>
    </v-card-text>
    <v-card-actions>
      <blw-button color="charcoal" @click="viewMatches" block label="Show matches" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FindRoommatesCard',
  computed: {
    ...mapGetters('application', [
      'applicationMarketSlug',
      'applicationMarketName',
      'applicationPreApprovedForAll',
      'applicationPreApprovedMaxRent',
    ]),

    ...mapGetters('interests', {
      selectedInterests: 'selected',
    }),
    marketImage() {
      return `${process.env.VUE_APP_AWS_ASSETS_URL}markets/maps/${this.applicationMarketSlug}.png`
    },
  },
  methods: {
    viewMatches() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          Category: 'Home Selection',
          CTA: 'Show matches',
          Location: 'Search Card',
          Text: 'Show matches',
        },
      })
      let query = `?roommateInterests=${this.selectedInterests.map(({ label }) => label).join(',')}`
      if (!this.applicationPreApprovedForAll) {
        query += `&priceMax=${this.applicationPreApprovedMaxRent}`
      }
      window.open(
        `${process.env.VUE_APP_BUNGALOW_WEBSITE_BASE_URL}listings/${this.applicationMarketSlug}${query}`
      )
    },
  },
}
</script>

<style lang="scss">
#find-roommates-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  .v-image__image--cover {
    background-size: cover;
  }
}
</style>
