<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReviewContainer',
  beforeMount() {
    // Groups have a separate review path:
    if (this.isGroupApplication) return this.navigateToGroupSection(this.application)

    // Co-living review paths:
    this.navigateUser(this.application)
  },
  computed: {
    ...mapGetters('groupApplicationSource', ['groupApplicationSource', 'groupStageByStatus']),
    ...mapGetters('application', ['application', 'displayState', 'isGroupApplication']),
  },
  methods: {
    replaceRoute(name) {
      if (this.$route.name !== name) this.$routerReplace({ name })
    },
    navigateToGroupSection({ status, application_requests }) {
      // If we have an open application request, see if it needs action
      if (application_requests) {
        // We should be able to see if there's a pending cosigner request:
        const { request_type, status } = application_requests
        if (request_type === 'add_cosigner' && status === 'pending') {
          // The user needs to nominate a co-signer, redirect them to that page:
          return this.replaceRoute(this.groupStageByStatus.submitted_request_pending)
        }
      }

      // For groups, show the correct page based on application status...
      const groupRouteName = this.groupStageByStatus[status] || this.groupStageByStatus.review

      this.replaceRoute(groupRouteName)
    },
    navigateUser({ status, application_requests, is_paying_double_deposit }) {
      // Application has been approved
      if (['approved', 'lease_sent'].includes(status))
        return this.replaceRoute('reviewApplicationApproved')

      if (status === 'rejected') return this.replaceRoute('applicationInReview')

      // No requests and the status of the application is still submitted
      if (!application_requests && status === 'submitted')
        return this.replaceRoute('applicationInReview')

      // If a pending application request exist redirect based off the request type
      if (application_requests) {
        const REQUEST_TO_ADD_COSIGNER = application_requests.request_type === 'add_cosigner'

        const REQUEST_TO_ADD_COSIGNER_OR_PAY_DOUBLE_DEPOSIT =
          application_requests.request_type === 'add_cosigner_or_double_deposit'

        const REQUEST_IS_COMPLETE = application_requests.status === 'completed'

        // If the request is to add a cosigner
        if (REQUEST_TO_ADD_COSIGNER) {
          const routeName = REQUEST_IS_COMPLETE ? 'reviewCoSignerInReview' : 'reviewAddCoSigner'

          return this.replaceRoute(routeName)
        }

        if (REQUEST_TO_ADD_COSIGNER_OR_PAY_DOUBLE_DEPOSIT) {
          if (REQUEST_IS_COMPLETE) {
            const routeName = is_paying_double_deposit
              ? 'applicationInReview'
              : 'reviewCoSignerInReview'

            return this.replaceRoute(routeName)
          }

          return this.replaceRoute('reviewCosignOrDeposit')
        }

        const routeByRequestType =
          application_requests.request_type === 'add_cosigner'
            ? 'reviewAddCoSigner'
            : 'reviewCosignOrDeposit'

        return this.replaceRoute(routeByRequestType)
      }
    },
  },
}
</script>
