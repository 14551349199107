<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 class="header-md mb-5">Great choice!</h1>
        <blw-illustration name="thumbs-up" size="175" class="mb-4" />
        <p v-max-width="262" class="text-xs-center">
          We’ve confirmed your home, next up it’s time to meet the roommates.
        </p>
      </div>
      <blw-button class="mt-5" size="lg" label="Continue" @click="goToMeetAndGreet" />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'HomeSelectionComplete',
  methods: {
    goToMeetAndGreet() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Home Selection Complete',
          color: 'Salmon',
          text: 'Great choice!',
          CTA: 'Continue',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.$routerPush({ name: 'meetGreet' })
    },
  },
}
</script>
