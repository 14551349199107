import Vue from 'vue'
import VueRouter from 'vue-router'

import ApplicationsRoutes from '@/routes/applications'
import ApplicationRoutes from '@/routes/application'
import CosignRouteHandlers from '@/routes/cosign'

// NOTE: temporary pattern during A/B testing:
import GetStarted from '@/routes/start/GetStarted'

import { Auth, Login, Oauth, Debug, NotFound, RedirectRoutes } from '@/routes'

const BAD_EMAIL_SOURCE_STRING = '?utm_medium=email'

const generateExternalLoginPath = () => {
  return `${process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE}?title=Apply Preview Login&next=${window.location.href}`
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/applications',
      alias: '/apply',
      component: Auth,
      children: [ApplicationsRoutes, ApplicationRoutes, ...CosignRouteHandlers],
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: { basicNav: true, disableBack: true },
      beforeEnter: (to, from, next) => {
        // If splash screen is enabled, redirect there first:
        const { query } = to
        if (query.promo === 'showSplash') {
          delete query.promo
          // Ensure source is perserved
          return router.replace({ name: 'start', query }).catch(() => {})
        }

        next()
      },
    },
    {
      name: 'start',
      path: '/start',
      component: GetStarted,
      meta: { basicNav: true, disableBack: true, applicationClasses: 'bg-white' },
    },
    {
      name: 'oauth',
      path: '/oauth',
      component: Oauth,
      meta: { hideNav: true },
    },
    {
      name: 'debug',
      path: '/debug',
      component: Debug,
      meta: {
        basicNav: true,
        disableBack: true,
      },
    },
    {
      name: 'notFound',
      path: '/404',
      component: NotFound,
      meta: {
        basicNav: true,
        disableBack: true,
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
    // Legacy redirects
    ...RedirectRoutes,
  ],
})

// Authentication Middleware
router.beforeEach(async (goingTo, comingFrom, next) => {
  // @TOOD 2021-01-28 - Emails are sending a bad source links that join the source and utm_source
  // when detected, identify and replace bad string with the proper source
  // while retaining the utm source of email for analytics
  const { source = '' } = goingTo.query
  if (source.includes(BAD_EMAIL_SOURCE_STRING)) {
    return next({
      path: goingTo.path,
      query: {
        ...goingTo.query,
        source: source.replace(BAD_EMAIL_SOURCE_STRING, ''),
        utm_medium: 'email',
      },
    })
  }
  const $auth = router.app.$auth
  const onLoginPage = goingTo.name === 'login'
  // Guard authentication protected route(s)
  const routeRequiresAuth = goingTo.matched.some((route) => route.meta.requiresAuth)
  // Fetch fresh access token if possible, go to login on failure
  if (onLoginPage && !$auth.isAuthenticated() && $auth.getRefreshToken()) {
    try {
      await $auth.refreshAccessToken()
    } catch (errorRefreshingToken) {
      $auth.logout()
      if (process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE)
        return window.location.replace(generateExternalLoginPath())
      return next({ path: '/login', query: goingTo.query })
    }
  }
  // Bounce to login screen if not logged in...
  if (routeRequiresAuth && !$auth.isAuthenticated()) {
    if (process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE)
      return window.location.replace(generateExternalLoginPath())
    localStorage.setItem('redirectRoute', goingTo.fullPath)
    return next({ path: '/login', query: goingTo.query })
  }
  // Proceed if already logged in...
  if (onLoginPage && $auth.isAuthenticated()) {
    const redirectTo = localStorage.getItem('redirectRoute')
    return next({ path: redirectTo || '/applications', query: goingTo.query })
  }
  next()
})

export default router
