<template>
  <Wordmark class="wordmark" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import Wordmark from '@/assets/svg/logo_wordmark.svg'

export default {
  components: { Wordmark },
}
</script>

<style lang="scss">
.wordmark {
  height: 1rem;

  @include mobile {
    height: 0.9rem;
  }
}
</style>
