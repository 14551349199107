<template>
  <loading-spinner full-page v-if="!initialized" />
  <div class="mg-messaging" v-else>
    <v-layout class="status-bar">
      <v-layout class="time-left">
        <v-icon
          v-if="backToHomeShoppingEnabled"
          class="chevron mr-2"
          size="28"
          @click="backToHomeShoppingClickHandler"
        >
          chevron_left
        </v-icon>
        <meet-greet-time-left />
      </v-layout>
      <blw-avatar asList :avatars="residents" :clickHandler="viewParticipants" size="28" />
    </v-layout>
    <div ref="messageHistory" class="message-history">
      <template>
        <!-- Greeting Message -->
        <div class="message-wrapper">
          <blw-avatar :avatars="residents" size="40" />
          <div class="message group">
            {{ groupMessage }}
          </div>
        </div>
        <!-- Message History... -->
        <div
          :key="message.messageId"
          v-for="message in contextualMessages"
          :class="['message-wrapper', message.messageType]"
        >
          <!-- Avatar -->
          <blw-avatar
            v-if="!message.sentBySelf"
            :avatar="message.profileUrl"
            size="40"
            :clickHandler="() => !message.isAdmin && viewProfile(message)"
          />
          <!-- Message Body -->
          <div :class="['message', message.messageType, { delivered: message.sentBySelf }]">
            {{ message.message }}
          </div>
        </div>
      </template>
    </div>
    <!-- Pop-over Hint -->
    <div class="mg-profile-hint" v-if="profileHint && isMobile">
      <v-icon v-ripple color="charcoal" @click="hideProfileHint"> close </v-icon>
      <blw-icon class="mr-3" icon="volunteering" />
      <span class="font-small">
        You and the roommates <b>have a lot in common.</b>
        <a class="blue--text" @click="viewPreferences">
          <b> Check out their profiles</b>
        </a>
      </span>
    </div>
    <!-- Input Box -->
    <v-layout class="message-input" align-center>
      <blw-text-field
        embedded
        @keyup.enter="sendMessage"
        placeholder="Type your message..."
        v-model="newMessage"
      />
      <blw-button
        icon
        flat
        color="primary"
        class="ml-3"
        disabledBackground="white"
        @click="sendMessage"
        :disabled="sending || !newMessage"
      >
        <v-icon color="grey">arrow_forward</v-icon>
      </blw-button>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import SendBirdSDK from '@/utils/sendBird'
import LoadingSpinner from '@/shared/LoadingSpinner'
import MeetGreetTimeLeft from './MeetGreetTimeLeft'

const SendBird = new SendBirdSDK(process.env.VUE_APP_SENDBIRD_APP_ID)

export default {
  name: 'MeetGreetMessaging',
  components: { LoadingSpinner, MeetGreetTimeLeft },
  props: {
    chat_channel: {
      type: Object,
    },
    chatUser: {
      type: Object,
    },
    residents: {
      type: Array,
    },
    avatarFallback: {
      type: String,
    },
    property: {
      type: Object,
    },
    room: {
      type: Object,
    },
  },
  data() {
    return {
      // Flags
      initialized: false,
      sending: false,
      session: null,
      profileHint: false, // TODO
      groupChannel: {},
      newMessage: '',
      messages: [],
    }
  },
  computed: {
    ...mapGetters('application', ['applicationStatus']),
    backToHomeShoppingEnabled() {
      return this.applicationStatus === 'meet_and_greet' && this.isMobile
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    contextualMessages() {
      return this.messages.map(this.addMessageContext)
    },
    channelUrl() {
      return this.chat_channel.provider_id
    },
    groupMessage() {
      const isGroup = this.residents.length > 1
      const weAreOrIam = isGroup ? 'We’re' : 'I am'
      const ourOrMy = isGroup ? 'our' : 'my'
      const usOrMe = isGroup ? 'us' : 'me'

      return `Hi there! ${weAreOrIam} excited you’ve chosen ${ourOrMy} home and looking forward to
        meeting you. Tell ${usOrMe} a little about yourself and why you chose this home!`
    },
  },
  methods: {
    backToHomeShoppingClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Go back',
          text: 'back to all chats',
          category: 'meet_greet',
          property_id: this.property.id,
          bedroom_id: this.room.id,
        },
      })
      this.$routerPush({ name: 'homeShopping' })
    },
    trackToolTipShown() {
      this.$trackEvent({
        action: 'View Tooltip',
        properties: {
          location: 'Meet & Greet - Chat',
          CTA: 'info_icon',
          text: 'How it works',
          category: 'Meet & Greet',
          type: 'Icon',
        },
      })
    },
    viewProfile({ userId }) {
      this.$trackEvent({
        action: 'View Profile',
        properties: {
          location: 'Meet & Greet - Chat',
          CTA: 'Profile Card (participant list)',
          category: 'Meet & Greet',
          type: 'List',
        },
      })

      this.$routerPush({ name: 'meetGreetProfileView', params: { userId } })
    },
    showNotSureModal() {
      this.$root.$emit('openModal', {
        modalComponent: 'MeetGreetNotSure',
      })
    },
    showWelcomeModal() {
      if (
        !localStorage.getItem('mg_start_modal_shown') &&
        this.applicationStatus !== 'meet_and_greet'
      ) {
        this.$root.$emit('openModal', {
          modalComponent: 'MeetGreetStartChatting',
          modalSize: 'small',
        })
        localStorage.setItem('mg_start_modal_shown', true)
      }
    },
    showProfileHint() {
      this.profileHint = !localStorage.getItem('mg_profile_hint_shown')
    },
    hideProfileHint() {
      this.profileHint = false
      localStorage.setItem('mg_profile_hint_shown', true)
    },
    viewParticipants() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'View profiles',
          category: 'meet_greet',
          property_id: this.property.id,
          bedroom_id: this.room.id,
        },
      })

      this.$routerPush({ name: 'meetGreetParticipants' })
    },
    viewPreferences() {
      this.$routerPush({ name: 'meetGreetPreferences' })
    },
    ensureProfileImage(message) {
      if (message && message._sender) return message._sender.profileUrl
    },
    addMessageContext(message) {
      const type = message.messageType

      // Add admin avatar (if applicable)
      if (type === 'admin') {
        message.isAdmin = true
        message.profileUrl = this.avatarFallback
        return message
      }

      // Not a user message, return as is
      if (!['user', 'users'].includes(type)) return message

      // Set the message type to 'self' if sent by current user
      message.sentBySelf = this.chatUser.user_id === message.userId
      message.messageType = message.sentBySelf ? 'self' : message.messageType
      if (!message.profileUrl) message.profileUrl = this.ensureProfileImage(message)

      // Send back updated message
      return message
    },
    showLastMessage() {
      this.$nextTick(function () {
        if (!this.$refs.messageHistory) return
        // Scroll to most recent message
        this.$refs.messageHistory.scrollTop = this.$refs.messageHistory.scrollHeight
      })
    },
    async sendMessage() {
      this.sending = true

      const sentMessage = await SendBird.sendUserMessage(this.groupChannel, this.newMessage)

      if (sentMessage) {
        this.messages.push(sentMessage)
        this.showLastMessage(true)
      }

      this.newMessage = ''
      this.sending = false

      this.$trackEvent({
        action: 'Message Sent',
        properties: {
          location: 'Meet & Greet - Chat',
          category: 'Meet & Greet',
          type: 'Event',
        },
      })
    },
    onSendBirdError(error) {
      console.error(error)
    },
    onMessageReceived(channel, message) {
      this.messages.push(message)
      this.showLastMessage()

      this.$trackEvent({
        action: 'Message Received',
        properties: {
          location: 'Meet & Greet - Chat',
          category: 'Meet & Greet',
          type: 'Event',
        },
      })
    },
    async launchMeetAndGreet() {
      try {
        // 1.) Connect to SendBird
        await SendBird.connect(this.chatUser.user_id, this.chatUser.provider_token)
        // 2.) Fetch channel
        this.groupChannel = await SendBird.getGroupChannel(this.channelUrl)
        // 3.) Fetch messages
        this.messages = await SendBird.getMessages(this.groupChannel)
        // 4.) Setup listener
        await SendBird.addChannelHandler(this.channelUrl, this.onMessageReceived)

        this.initialized = true
        this.showLastMessage()

        this.$trackEvent({
          action: 'M&G Chat Loaded',
          properties: {
            location: 'Meet & Greet - Chat',
            category: 'Meet & Greet',
            type: 'Event',
          },
        })
      } catch (error) {
        this.$trackEvent({
          action: 'M&G Chat Error',
          properties: {
            location: 'Meet & Greet - Chat',
            category: 'Meet & Greet',
            type: 'Event',
            chatUserId: _.get(this.chatUser, 'user_id'),
            chatUserProviderTokenExists: !!_.get(this.chatUser, 'provider_token'),
            channelUrl: this.channelUrl,
            errorMessage: error.message,
          },
        })
        this.$sentry.captureException(error)
      }
    },
  },
  mounted() {
    this.showWelcomeModal()
    this.launchMeetAndGreet()
    // this.showProfileHint(); // TODO
  },
  destroyed() {
    SendBird.disconnect(this.groupChannel)
  },
}
</script>

<style lang="scss">
.mg-messaging {
  display: flex;
  position: relative;
  flex-direction: column;
  background: white;
  @include boxShadow();

  .mg-profile-hint {
    position: absolute;
    display: flex;
    align-items: center;
    background: white;
    z-index: 999;
    bottom: 64px;
    height: 100px;
    border-bottom: 1px solid $charcoal-40;
    padding: 2.25rem 1.75rem 1.75rem 1.75rem;

    .v-icon {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  .message-history {
    overflow: hidden;
    overflow-y: scroll;
    border-bottom: 1px solid $charcoal-40;
    padding-top: 2.5rem;
    height: 100vh;

    @include mobile {
      background: $cream;
    }

    .blw-avatar {
      margin-right: 1.25rem;
    }
  }

  .message-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 0 3rem;

    .blw-avatar {
      border-radius: 100%;
    }

    @include mobile {
      padding: 0 1.5rem;
    }

    &.self {
      justify-content: flex-end;
    }
  }

  .message {
    width: auto;
    position: relative;
    background: $cream;
    padding: 1.25rem;
    border-bottom: 2px solid $charcoal-60;
    max-width: 280px;
    margin-bottom: 2.25rem;

    @include mobile {
      background: white;
    }

    &.self {
      color: white;
      background: $charcoal-60;
      padding-right: 2.5rem;

      &::after {
        content: 'Delivered';
        display: block;
        position: absolute;
        opacity: 0.5;
        bottom: -1.65rem;
        right: 0;
        color: $charcoal-60;
        font-size: 11px;
      }
    }
  }

  .message-input {
    min-height: 100px;
    padding: 0 3rem;

    @include mobile {
      min-height: 64px;
    }
  }

  .time-left {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      justify-content: flex-start;
    }

    .time-left-tooltip {
      small,
      a {
        color: $text-lighter;
        font-size: 13px;
      }

      a {
        text-decoration: underline;

        &:hover {
          color: $charcoal-60;
        }
      }
    }
  }

  .status-bar {
    display: flex;
    align-items: center;
    min-height: 100px;
    border-bottom: 1px solid $charcoal-40;
    justify-content: center;
    padding: 1rem 2.5rem;

    @include mobile {
      padding: 1rem 1.5rem;
      min-height: 60px;
      justify-content: space-between;
      border-color: transparent;
    }

    .blw-avatar {
      cursor: pointer;
      display: none;

      @include mobile {
        display: block;
      }
    }
  }
}
</style>
