import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  groupApplicationSource: null,
  groupPropertySelection: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  groupApplicationSource: ({ groupApplicationSource }) => groupApplicationSource,
  groupPropertySelection: ({ groupPropertySelection }) => groupPropertySelection,
  groupStageByStatus: () => ({
    // Status Page
    submitted: 'groupStatus',
    // Review
    review: 'groupApplicationInReview',
    // Needs Co-signer
    submitted_request_pending: 'groupNeedsCosigner',
    // Lease Sent
    lease_sent: 'groupLeaseSent',
  }),
  incompleteGroupStatuses: () => [null, 'open', undefined],
  groupMemberSubmissionsPending: ({ groupApplicationSource }, { incompleteGroupStatuses }) => {
    if (!groupApplicationSource) return false

    // Any application status other than "open" means they've already submitted:
    return groupApplicationSource.members.some((groupMember) =>
      incompleteGroupStatuses.includes(groupMember.application_status)
    )
  },
}

const actions = {
  fetchGroupApplicationSource({ commit }, source) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/groups/sources/${source}/`)
      .then(({ data }) => data)
      .then((applicationSource) => {
        commit('setGroupApplicationSource', applicationSource)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  async fetchGroupPropertySelection({ commit, getters }) {
    try {
      commit('setLoading', true)

      const groupSlug = getters.groupApplicationSource.slug
      const groupPropertySelection = await this._vm.$http
        .get(`applications/groups/property-selections/`)
        .then(({ data }) => {
          // There may be multiple property selections, find the one that matches this group:
          return data.results.find(({ group_source_slug }) => group_source_slug === groupSlug)
        })

      return this._vm.$http
        .get(`applications/groups/property-selections/${groupPropertySelection.id}`)
        .then(({ data }) => commit('setGroupPropertySelection', data))
        .catch((error) => commit('setError', error))
        .finally(() => commit('setLoading', false))
    } catch (propertySelectionError) {
      commit('setLoading', false)
      commit('setError', false)

      this._vm.$sentry.captureMessage('Group Property Selection Fetch Failed!', {
        extra: { error: propertySelectionError },
      })
    }
  },
  addGroupMemberToGroupSource({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(`applications/groups/members/`, payload)
      .then(({ data }) => data)
      .catch((error) => {
        // NOTE: This should never fail, log to Sentry!
        this._vm.$sentry.captureMessage('Add Group Member to Source Failed', {
          extra: { error },
        })
        return commit('setError', error)
      })
      .finally(() => commit('setLoading', false))
  },
  addApplicationToGroupSource({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)
    return this._vm.$http
      .post('applications/groups/sources-add-application/', payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  sendGroupMemberReminder({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)
    return this._vm.$http
      .post('/applications/groups/reminders/', payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setGroupApplicationSource(state, applicationSource) {
    state.groupApplicationSource = applicationSource
  },
  setGroupPropertySelection(state, propertySelection) {
    state.groupPropertySelection = propertySelection
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
