<template>
  <v-checkbox
    class="blw-control blw-checkbox"
    @change="$emit('input', $event)"
    v-bind="{ ...$props, ...$attrs }"
  />
</template>

<script>
export default {
  name: 'BlwCheckBox',
  props: {
    'hide-details': {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'blue',
    },
    'on-icon': {
      type: String,
      default: 'check_circle',
    },
    'off-icon': {
      type: String,
      default: 'radio_button_unchecked',
    },
    'true-value': {
      type: Boolean,
      default: true,
    },
    'false-value': {
      type: Boolean,
      default: false,
    },
    'input-value': {
      type: Boolean,
      default: false,
    },
    'indeterminate-icon': {
      type: String,
      default: 'info',
    },
    inputID: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
// FIXME: Remove this imporant tag:
.v-input--selection-controls {
  padding: 0px !important;
  margin: 0px !important;
}
</style>
