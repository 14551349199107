import { getField, updateField } from 'vuex-map-fields'
import { get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  cosignerSource: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  cosignerSource: ({ cosignerSource }) => cosignerSource,
  cosignerSourceId: ({ cosignerSource }) => _get(cosignerSource, 'id'),
  cosignerSourceSlug: ({ cosignerSource }) => _get(cosignerSource, 'slug'),
  cosignerSourceApplication: ({ cosignerSource }) => _get(cosignerSource, 'source_application'),
  cosignerSourceFeeIsWaived: ({ cosignerSource }) =>
    _get(cosignerSource, 'source_application.waive_application_fee'),
}

const actions = {
  resetCosignerSource({ commit }) {
    commit('setCosignerSource', {})
  },
  setCosignerSource({ commit }, cosigner) {
    commit('setCosignerSource', cosigner)
  },
  getCosignerSourceBySlug({ commit }, source) {
    commit('setUpdating', true)
    return this._vm.$http
      .get(`cosigner/source/${source}/`)
      .then(({ data }) => {
        commit('setCosignerSource', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  createCosignerSource({ commit, getters }, source_application) {
    const { cosignerSource } = getters

    const payload = {
      ...cosignerSource,
      source_application,
    }

    commit('setUpdating', true)

    return this._vm.$http
      .post('cosigner/source/', payload)
      .then(({ data }) => {
        commit('setCosignerSource', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  updateCosignerSource({ commit, getters }) {
    const { cosignerSource } = getters
    const { cosignerSourceSlug } = getters

    commit('setUpdating', true)

    return this._vm.$http
      .put(`cosigner/source/${cosignerSourceSlug}`, cosignerSource)
      .then(({ data }) => {
        commit('setCosignerSource', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  updateCosignerSourceByKey({ commit, getters }, { field, value }) {
    const { cosignerSource } = getters
    const { cosignerSourceSlug } = getters

    const payload = { ...cosignerSource, [field]: value }

    commit('setUpdating', true)

    return this._vm.$http
      .put(`cosigner/source/${cosignerSourceSlug}`, payload)
      .then(({ data }) => {
        commit('setCosignerSource', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  deleteCosignerSourceBySlug({ commit }, slug) {
    commit('setUpdating', true)

    return this._vm.$http
      .delete(`cosigner/source/${slug}`)
      .then(() => {
        commit('setCosignerSource', {})
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setCosignerSource(state, cosignerSource) {
    state.cosignerSource = cosignerSource
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
