<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { format, addMonths } from 'date-fns'
import { mapFields } from 'vuex-map-fields'

import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'ApplyContainer',
  data() {
    return {
      loading: true,
    }
  },
  components: { LoadingSpinner },
  async beforeMount() {
    this.loading = true
    // Base resource fetching
    await this.setDefaultMoveInMoveOutDates()
    await this.fetchApplicationValidation(this.applicationId)
    await this.fetchApplicant()
    await this.fetchApplicationFlags()

    // Data initialization
    await this.initializeEmergencyContactInfo()
    await this.initializeIncome()
    await this.initializeResidence()

    // Group Applications Only
    if (this.groupApplicationSource) {
      await this.ensureGroupInvitesWereSent()
      await this.maybeNavigateToGroupStatusPage()
    }

    this.loading = false
  },
  computed: {
    ...mapFields('application', ['application.move_in_date', 'application.lease_end_date']),
    ...mapGetters('groupApplicationSource', ['groupApplicationSource']),
    ...mapFields('residence', {
      landlordFirstName: 'residence.contact_first_name',
      landlordLastName: 'residence.contact_last_name',
      landlordPhonenumber: 'residence.contact_phonenumber',
      landlordEmail: 'residence.contact_email',
      landlordMoveOutReason: 'residence.reason_for_moving',
      residenceMonthlyRent: 'residence.monthly_rent',
      residenceMoveInDate: 'residence.move_in_date',
    }),
    ...mapFields('emergencyContact', {
      emergencyContactFirstName: 'emergencyContact.first_name',
      emergencyContactLastName: 'emergencyContact.last_name',
      emergencyContactPhonenumber: 'emergencyContact.phonenumber',
      emergencyContactEmail: 'emergencyContact.email',
      emergencyContactRelationship: 'emergencyContact.relationship',
    }),
    ...mapGetters('application', [
      'applicationId',
      'applicationStatus',
      'sectionStarted',
      'displayState',
      'application',
    ]),
    ...mapGetters('applicationSource', ['applicationSource']),
    ...mapGetters('groupApplicationSource', ['groupMemberSubmissionsPending']),
    ...mapGetters('applicant', ['applicant', 'incomeId', 'residences']),
    ...mapGetters('income', ['income', 'error']),
  },
  methods: {
    ...mapActions('applicationValidation', ['fetchApplicationValidation']),
    ...mapActions('application', [
      'updateApplication',
      'updateApplicationByKey',
      'fetchApplicationBySource',
    ]),
    ...mapActions('groupApplicationSource', ['addApplicationToGroupSource']),
    ...mapActions('applicant', ['fetchApplicant', 'updateApplicantByKey']),
    ...mapActions('emergencyContact', ['fetchEmergencyContact', 'updateEmergencyContact']),
    ...mapActions('income', ['fetchIncome', 'createIncome']),
    ...mapActions('residence', [
      'fetchResidence',
      'createResidence',
      'updateResidence',
      'resetResidence',
    ]),
    ...mapActions('applicationFlags', ['fetchApplicationFlags']),
    goToApplication() {
      this.$routerReplace({
        name: 'apply',
        params: { applicationSource: this.applicationSource.slug },
      })
    },
    async maybeNavigateToGroupStatusPage() {
      const isSubmitted = this.application.status !== 'open'
      if (isSubmitted && this.groupMemberSubmissionsPending)
        this.$routerReplace({ name: 'groupStatus' })
    },
    async ensureGroupInvitesWereSent() {
      const { members, number_of_potential_roommates } = this.groupApplicationSource
      const invitesSent = number_of_potential_roommates === members.length

      // TODO: Re-evaluate how we handle this (if at all)
      // If we're ever missing the number of roommates, head directly to application (no invite step)
      if (!number_of_potential_roommates) return this.goToApplication()

      // Ensure group invites have been sent already
      if (!invitesSent) return this.$router.replace({ name: 'groupsInvite' }).catch(() => {})

      // If trying to access the invite page but they've already been sent – go to apply instead:
      if (this.$route.name === 'groupsInvite') this.goToApplication()
    },
    /**
     * set default move in and move out dates,
     * these dates are updated in the home selection phase
     */
    async setDefaultMoveInMoveOutDates() {
      this.move_in_date = this.move_in_date || format(new Date(), 'YYYY-MM-DD')
      this.lease_end_date =
        this.lease_end_date || format(addMonths(this.move_in_date, 12), 'YYYY-MM-DD')
      await this.updateApplication()
    },
    /**
     * Emergency contact info will be retrieved later in the process with this flow
     * set initial temporary data which can be updated at any time
     */
    async initializeEmergencyContactInfo() {
      await this.fetchEmergencyContact()
      // default all landlord info since it's no longer required
      this.emergencyContactFirstName = 'N/A'
      this.emergencyContactLastName = 'N/A'
      this.emergencyContactPhonenumber = '+15195800998'
      this.emergencyContactEmail = 'na@bungalow.com'
      this.emergencyContactRelationship = 'N/A'
      // save hardcoded data
      await this.updateEmergencyContact()
    },
    /**
     * if we have an income id load it
     * if not create the income to be edited
     */
    async initializeIncome() {
      if (this.incomeId) {
        await this.fetchIncome(this.incomeId)
      } else {
        await this.createIncome()
        await this.updateApplicantByKey({ field: 'income', value: this.income.id })
      }
    },
    /**
     * if we have a saved residence, load it otherwise setup
     * the initial blank resident state
     */
    async initializeResidence() {
      const savedCurrentResidence = _.find(this.residences, { current: true })
      if (savedCurrentResidence) {
        await this.fetchResidence(savedCurrentResidence.id)
      } else {
        this.resetResidence()
        // default all landlord info since it's no longer required
        this.landlordFirstName = 'N/A'
        this.landlordLastName = 'N/A'
        this.landlordPhonenumber = '+15195800998'
        this.landlordEmail = 'na@bungalow.com'
        this.landlordMoveOutReason = 'N/A'
        this.residenceMonthlyRent = 0
        this.residenceMoveInDate = '1971-01-01'
        await this.createResidence()
      }
    },
  },
  watch: {
    $route: {
      handler({ name }) {
        if (this.applicationStatus === 'rejected') {
          name !== 'review' && this.$routerPush({ name: 'review' }).catch(() => {})
        }
      },
      immediate: true,
    },
  },
}
</script>
