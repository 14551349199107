<template>
  <div class="overlap-preferences pt-4 pb-4">
    <swiper :options="swiperOptions">
      <swiper-slide :key="index" v-for="(overlap, index) in overlapsWithContext">
        <v-card class="preference-card shadow">
          <div class="profile-images">
            <v-layout class="w-100" align-center justify-space-around>
              <blw-avatar :avatar="userProfileImage" size="64" />
              <blw-avatar :avatar="theirProfileImage" size="64" />
            </v-layout>
          </div>

          <div :class="['overlap-status', `font-${overlap.color}`]">
            <blw-svg :name="overlap.icon" />
            <p class="lead">{{ overlap.lead }}</p>
          </div>

          <h3 class="header-sm font-large header">{{ overlap.title }}</h3>
          <p class="details">{{ overlap.subtitle }}</p>
        </v-card>
      </swiper-slide>
      <div v-ripple slot="button-prev" class="swiper-button swiper-button-round swiper-button-prev">
        <v-icon color="charcoal">chevron_left</v-icon>
      </div>
      <div v-ripple slot="button-next" class="swiper-button swiper-button-round swiper-button-next">
        <v-icon color="charcoal">chevron_right</v-icon>
      </div>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OverlapPreferences',
  props: {
    preferences: {
      type: Array,
    },
    theirProfileImage: {
      type: String,
    },
  },
  data() {
    return {
      swiperOptions: {
        updateOnWindowResize: true,
        breakpoints: {
          320: {
            slidesPerView: 1.085,
          },
          375: {
            slidesPerView: 1.225,
          },
          450: {
            slidesPerView: 1.3,
          },
        },
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      statusIcons: {
        home_run: 'overlap_shared',
        in_between: 'overlap_between',
        strike_out: 'overlap_different',
      },
      statusColors: {
        home_run: 'green',
        in_between: 'yellow',
        strike_out: 'red',
      },
      statusLead: {
        home_run: 'shared',
        in_between: 'in between',
        strike_out: 'different',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userProfileImage']),
    overlapsWithContext() {
      return this.preferences.map((overlap) => {
        // Inject flags and context into each card...
        overlap.icon = this.statusIcons[overlap.status]
        overlap.color = this.statusColors[overlap.status]
        overlap.lead = this.statusLead[overlap.status]
        return overlap
      })
    },
  },
}
</script>

<style lang="scss">
.overlap-preferences {
  min-height: 392px;
  .profile-images {
    margin-bottom: 2.75rem;
  }

  .preference-card {
    user-select: none;
    text-align: center;
    padding: 2.5rem 3rem;
    padding-bottom: 1.5rem;
    width: 284px;
    min-height: 392px;
    margin: 0 auto;
    margin-bottom: 1.5rem;

    .overlap-status {
      margin-top: 1.25rem;
    }

    .header {
      margin: 0.75rem 0;
    }

    .details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      font-size: 13px;
      color: $text-lighter;
    }

    .actions {
      display: flex;
    }
  }
}
</style>
