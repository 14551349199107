<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { delay } from '@/utils/helpers'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'Auth',
  components: { LoadingSpinner },
  data() {
    return { loading: false }
  },
  async beforeMount() {
    this.checkForWebPushNotificationsEligibility()

    this.loading = true

    this.$trackEvent({ action: 'Loading Auth Container' })

    if (this.$auth.isAuthenticated()) {
      this.$trackEvent({ action: 'Fetching User' })
      await this.fetchProfile()
    }

    if (this.error) {
      this.$trackEvent({
        action: 'User Fetch Error - Redirecting To Login',
        properties: { error: this.error },
      })

      // Logout user and reset data:
      return this.resetApplicationAndRedirectToLogin({ vm: this })
    }

    this.$trackEvent({
      action: 'User Profile Successfuly Loaded',
      properties: { error: this.error },
    })

    this.initiateIntercomSnippet()

    this.loading = false
  },
  beforeDestroy() {
    if (window.Intercom) {
      // Reset the Intercom widget (clears all conversations and personal info)
      window.Intercom('shutdown')
      window.Intercom('boot')
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'error']),
  },
  methods: {
    ...mapActions('user', ['fetchProfile', 'resetApplicationAndRedirectToLogin']),
    checkForWebPushNotificationsEligibility() {
      const canShowNotificationPrompt =
        'Notification' in window && 'PushManager' in window && Notification.permission !== 'denied'
      this.$trackEvent({
        action: 'Checking Web Notification Eligibility',
        properties: {
          eligable: canShowNotificationPrompt,
          currentNotificationPermissionSettings: window.Notification
            ? window.Notification.permission
            : null,
        },
      })
    },
    // Presets user data for the intercom widget ( user name, email etc. )
    async initiateIntercomSnippet(attempts = 1) {
      // Intercom has not been intjected yet
      if (!window.Intercom) {
        const maxAttempts = 5
        if (attempts === maxAttempts) return
        // Check periodically...
        attempts += 1
        await delay(2000)

        return this.initiateIntercomSnippet(attempts)
      }

      const {
        email,
        first_name,
        last_name,
        id,
        phonenumber: phone,
        profile_image_url: image_url,
      } = this.userProfile

      // Inject user data into Intercom
      window.Intercom('boot', {
        email,
        name: `${first_name} ${last_name}`,
        user_id: id,
        phone,
        avatar: {
          type: 'avatar',
          image_url,
        },
      })

      // Intercom handlers:
      window.Intercom('onShow', () => this.$trackEvent({ action: 'Intercom Widget Opened' }))
      window.Intercom('onHide', () => this.$trackEvent({ action: 'Intercom Widget Closed' }))
    },
  },
  watch: {
    $route() {
      // Updates intercom widget with the latest route
      window.Intercom && window.Intercom('update')
    },
  },
}
</script>
