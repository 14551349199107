import ReviewContainer from './containers/ReviewContainer'
import InReview from './views/InReview'
import ReviewApproved from './views/ReviewApproved'
import CoSignerInReview from './views/CoSignerInReview'
import NominateOrDepositChoice from './views/NominateOrDepositChoice'
import ReviewEmailedUpdate from './views/ReviewEmailedUpdate'
import GroupApplicationInReview from './views/GroupApplicationInReview'
import GroupNeedsCosigner from './views/GroupNeedsCosigner'
import GroupLeaseSent from './views/GroupLeaseSent'
import GroupStatus from './views/GroupStatus'
import AddCosigner from '@/routes/applicantIncome/views/IncomeCosigner'

export default {
  path: 'review',
  component: ReviewContainer,
  meta: {
    section: 'review',
    requiresAuth: true,
    currentStep: 1,
  },
  children: [
    {
      path: '',
      name: 'review',
      redirect: 'in-review',
    },
    {
      path: 'in-review',
      name: 'applicationInReview',
      component: InReview,
    },
    {
      path: 'group-in-review',
      name: 'groupApplicationInReview',
      component: GroupApplicationInReview,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'group-status',
      name: 'groupStatus',
      component: GroupStatus,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'group-needs-cosigner',
      name: 'groupNeedsCosigner',
      component: GroupNeedsCosigner,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'group-lease-sent',
      name: 'groupLeaseSent',
      component: GroupLeaseSent,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'emailed-update',
      name: 'reviewReviewEmailedUpdate',
      component: ReviewEmailedUpdate,
    },
    {
      path: 'group-add-cosigner',
      name: 'groupAddCoSigner',
      component: AddCosigner,
      props: {
        nextPath: 'groupApplicationInReview',
        text: 'We’ll ask them to complete a short application so we can get your group application approved.',
      },
      meta: { applicationClasses: 'bg-white footer-white full-height' },
    },
    {
      path: 'add-cosigner',
      name: 'reviewAddCoSigner',
      component: AddCosigner,
      props: {
        nextPath: 'reviewCoSignerInReview',
        text: 'We’ll ask them to complete a short application so we can get yours approved.',
      },
      meta: { applicationClasses: 'bg-white footer-white full-height' },
    },
    {
      path: 'co-signer-in-review',
      name: 'reviewCoSignerInReview',
      component: CoSignerInReview,
    },
    {
      path: 'co-signer-or-deposit',
      name: 'reviewCosignOrDeposit',
      component: NominateOrDepositChoice,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'approved',
      name: 'reviewApplicationApproved',
      component: ReviewApproved,
      meta: { currentStep: 2 },
    },
  ],
}
