import { getField, updateField } from 'vuex-map-fields'
import { get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,

  sin_ssn: null,
  skipSsnEntry: false,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  getSecureIdNumber: (state) => state.sin_ssn,
  getSkipSsnEntry: ({ skipSsnEntry }) => skipSsnEntry,
  apiError: ({ error }) => _get(error, 'field_errors[0]'),
}

const actions = {
  submitApplication({ commit, rootGetters }) {
    const applicationId = rootGetters['application/applicationId']

    commit('setUpdating', true)

    return this._vm.$http
      .post('applications/application-submissions', { application: applicationId })
      .then(() => {
        commit('setUpdating', false)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  submitCosigner({ commit, rootGetters }) {
    const cosignerId = rootGetters['cosigner/cosignerId']

    commit('setUpdating', true)

    return this._vm.$http
      .post('cosigner/cosigner-submissions', { cosigner: cosignerId })
      .then(() => {
        commit('setUpdating', false)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  submitBackgroundCheck({ commit }, payload) {
    commit('setUpdating', true)

    return this._vm.$http
      .post('applications/background-check', payload)
      .then(() => {
        commit('setUpdating', false)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },

  submitCharge({ commit }, payload) {
    commit('setUpdating', true)

    return this._vm.$http
      .post('applications/application-charge', payload)
      .then(() => {
        commit('setUpdating', false)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
