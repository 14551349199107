<template>
  <nav class="navbar" v-if="!hideNav">
    <v-container :class="{ basic: basicNav }">
      <!-- Back Button -->
      <v-layout v-if="basicNav" align-center justify-start>
        <transition name="fade">
          <blw-button v-show="!disableBack" flat icon color="charcoal" @click="goBack">
            <v-icon color="charcoal">arrow_back</v-icon>
          </blw-button>
        </transition>
      </v-layout>

      <!-- Logo -->
      <v-layout class="logo" justify-center align-center>
        <Wordmark @click="goHome" />
      </v-layout>

      <!-- Progress -->
      <progress-bar v-if="!basicNav" class="progress-bar" :currentStep="currentStep" :steps="4" />

      <!-- Account -->
      <v-layout v-if="userProfile" align-center class="account-menu" :key="userProfile.id">
        <v-menu left bottom offset-y min-width="200px">
          <template v-slot:activator="{ on }">
            <v-layout class="user-profile" v-on="on" align-center justify-end>
              <div class="profile-avatar" v-if="userProfile">
                <v-avatar size="36" v-ripple data-cy="profile-image">
                  <img :src="profileImageURL" @error="useFallbackAvatar" alt="profile image" />
                </v-avatar>
              </div>
            </v-layout>
          </template>

          <!-- Dropdown(s) -->
          <v-list v-if="isStaff" class="staff-dropdown py-0 pt-1" data-cy="staff-menu">
            <v-layout
              align-center
              justify-space-between
              class="dropdown-header header-xs font-small"
            >
              Staff
              <v-icon size="16" color="charcoal">mdi-account</v-icon>
            </v-layout>
            <v-list-tile v-if="isAdmin" key="disable-admin" @click="disableAdmin()">
              <v-list-tile-title class="font-small">Disable admin mode</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-else key="enable-admin" @click="enableAdmin()">
              <v-list-tile-title class="font-small">Enable admin mode</v-list-tile-title>
            </v-list-tile>
            <v-list-tile disabled>
              <v-list-tile-title class="font-small">
                Enable Mirage testing (coming soon)
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
          <v-list v-if="isAdmin" class="admin-dropdown py-0 pt-1" data-cy="admin-menu">
            <v-layout
              align-center
              justify-space-between
              class="dropdown-header header-xs font-small"
            >
              Admin Mode
              <v-icon size="16" color="charcoal">build</v-icon>
            </v-layout>
            <v-list-tile
              v-for="item in availableAdminRoutes"
              :key="item.title"
              @click="item.onClick"
            >
              <v-list-tile-title class="font-small">{{ item.title }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
          <v-divider v-if="isStaff" />
          <v-list class="py-0">
            <v-list-tile v-for="item in dropdownItems" :key="item.title" @click="item.onClick">
              <v-list-tile-title class="font-small">{{ item.title }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-layout>
    </v-container>
    <v-snackbar multi-line :value="isAdmin">
      <v-layout align-center>
        <v-icon color="white">lock_open</v-icon>
        <p class="ml-2 text-xs-center font-bold">Admin functionality unlocked</p>
      </v-layout>
    </v-snackbar>
  </nav>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'

import Wordmark from '@/shared/Wordmark'
import ProgressBar from '@/shared/ProgressBar'

export default {
  name: 'Navbar',
  components: { Wordmark, ProgressBar },
  props: {
    basicNav: {
      type: Boolean,
      default: false,
    },
    disableBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 1,
      consoleMessage: null,
      dropdownItems: [
        {
          title: 'Logout',
          onClick: this.logoutHandler,
        },
      ],
      adminRoutes: [
        {
          title: 'Applications',
          onClick: () => this.goToRoute('applications'),
        },
        {
          title: 'Apply',
          onClick: () => this.goToRoute('apply'),
        },
        {
          title: 'Review',
          onClick: () => this.goToRoute('applicationInReview'),
        },
        {
          title: 'Profile',
          onClick: () => this.goToRoute('profile'),
        },
        {
          title: 'Home Selection',
          onClick: () => this.goToRoute('homeSelection'),
        },
        {
          title: 'Meet & Greet',
          onClick: () => this.goToRoute('meetGreet'),
        },
        {
          title: 'Debug',
          onClick: () => this.goToRoute('debug'),
        },
      ],
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.updateProgressStepper(val)
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('user', [
      'userProfile',
      'userId',
      'blueAvatar',
      'userProfileImage',
      'isAdmin',
      'isStaff',
    ]),
    ...mapGetters('profile', ['profileImage']),
    ...mapGetters('application', ['application']),
    availableAdminRoutes() {
      if (!this.application) {
        const baseRoutes = ['Applications', 'Debug']
        return this.adminRoutes.filter((route) => baseRoutes.includes(route.title))
      }

      return this.adminRoutes
    },
    profileImageURL() {
      return this.profileImage ? this.profileImage : this.userProfileImage
    },
    hideNav() {
      return this.$route.meta.hideNav
    },
    homeRoute() {
      const { applicationSource, cosignerSource } = this.$route.params

      if (applicationSource) return 'apply'
      if (cosignerSource) return 'cosignSteps'
      return 'applications'
    },
  },
  methods: {
    ...mapActions('user', ['logout', 'enableAdmin', 'disableAdmin']),
    ...mapActions(['resetState']),
    goToRoute(name) {
      const source = (this.application && this.application.source) || null

      if (this.$route.name !== name) {
        this.$routerPush({ name, params: { applicationSource: source } }).catch(() => {})
      }
    },
    useFallbackAvatar(event) {
      event.target.src = this.blueAvatar
    },
    updateProgressStepper({ matched, meta }) {
      if (meta.currentStep) return (this.currentStep = meta.currentStep)

      const currentStep = _.get(
        matched.find(({ meta }) => meta.currentStep !== undefined),
        'meta.currentStep'
      )

      this.currentStep = currentStep
    },
    goBack() {
      this.$trackEvent({
        action: 'Go Back',
        properties: {
          location: 'Navbar',
          CTA: 'Back arrow',
          category: 'Navigation',
          type: 'Icon',
        },
      })

      this.$router.back()
    },
    goHome() {
      return this.$routerPush({ name: 'applications' }).catch(() => {})
    },
    logoutHandler() {
      this.logout()
      this.$routerPush({ name: 'login' }).catch(() => {})
      this.resetState()
    },
  },
}
</script>

<style lang="scss">
.admin-dropdown,
.staff-dropdown {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 0;
  }

  .dropdown-header {
    padding: 0.5rem 1rem;
  }
}

.admin-dropdown {
  &::before {
    @include constructionTapeBG();
  }
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid $charcoal-20;
  display: flex;
  align-items: center;
  background: white;
  height: $nav-height;

  @include mobile {
    height: $nav-height-mobile;
  }

  .v-menu__content {
    margin-top: 0.5rem;
  }

  .container {
    display: grid;
    grid-template-rows: 2.5rem;
    grid-template-columns: 3.25rem 1fr 3.25rem;
    align-content: center;

    // Progress steps
    &:not(.basic) {
      max-width: 1400px;
      grid-template-columns: 10rem 1fr 10rem;

      @media (max-width: 1000px) {
        max-width: 100%;
        padding: 0 2.5rem;
      }

      @media (max-width: 650px) {
        grid-template-columns: 1fr;

        .account-menu,
        .logo {
          display: none;
        }
      }
    }
  }

  .wordmark {
    &:hover {
      cursor: pointer;
    }
  }

  a {
    line-height: 0;
  }

  .user-profile {
    .profile-avatar {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    img {
      border: 2px solid $charcoal-60;
    }
  }
}
</style>
