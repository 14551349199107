<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height text-xs-center">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 v-max-width="392" class="header-md mb-3">We’re reviewing your application</h1>
        <p class="mb-4">This process usually takes a few hours.</p>
        <blw-illustration name="lease" size="175" class="mb-4" />
        <div v-max-width="295">
          <p class="text-xs-center">
            We’ll notify you via email when we’ve completed reviewing your application.
          </p>
          <v-btn
            v-for="(option, i) in options"
            :key="i"
            @click="option.onClick"
            rounded
            block
            class="mt-3"
            v-bind="option.btnAttrs"
          >
            <span v-text="option.title" />
          </v-btn>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InReview',
  beforeMount() {
    //  Go to the groups status page if part of a group, it'll handle things from there:
    if (this.isGroupApplication) this.$router.replace({ name: 'groupStatus' }).catch(() => {})
  },
  computed: {
    ...mapGetters('application', ['isGroupApplication', 'groupProperty']),
    ...mapGetters('applicant', ['applicant']),
    options() {
      return [
        {
          title: 'About Bungalow',
          onClick: this.openWhyBungalowPage,
          btnAttrs: {
            color: 'primary',
          },
        },
        {
          title: 'Bungalow Reviews',
          onClick: this.openReviewsPage,
          btnAttrs: {
            outline: true,
          },
        },
        {
          title: 'Chat with our team',
          onClick: this.openIntercom,
          btnAttrs: {
            outline: true,
          },
        },
      ]
    },
  },
  methods: {
    openIntercom() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Chat with our team',
          color: 'Salmon',
          text: 'About Bungalow',
          CTA: 'Continue',
          category: 'Bungalow Info',
          type: 'Button',
        },
      })
      window.Intercom && window.Intercom('show')
    },
    openWhyBungalowPage() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'About Bungalow',
          CTA: 'Continue',
          category: 'Bungalow Info',
          type: 'Button',
        },
      })
      window.open('https://bungalow.com/why-bungalow')
    },
    openReviewsPage() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'Bungalow Reviews',
          CTA: 'Continue',
          category: 'Bungalow Info',
          type: 'Button',
        },
      })
      window.open('https://bungalow.com/reviews')
    },
  },
}
</script>
