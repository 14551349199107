import SubmitContainer from './SubmitContainer'
import SubmitPayment from './views/SubmitPayment'
import SubmitScreening from './views/SubmitScreening'
import SubmitIdentityVerification from './views/SubmitIdentityVerification'
import SubmitFailed from './views/SubmitFailed'
import SubmitIntro from './views/SubmitIntro'
import SubmitSuccess from './views/SubmitSuccess'

const routes = {
  path: 'submit',
  component: SubmitContainer,
  props: {
    validationNamespace: 'applicationValidation',
    fallbackRoute: 'apply',
  },
  children: [
    {
      path: '',
      name: 'submit',
      component: SubmitIntro,
      props: {
        nextRoute: 'screening',
        entityMarketCodeGetter: 'application/applicationMarketCountryCode',
        entityApplicationFeeHasBeenWaivedGetter: 'application/applicationFeeIsWaived',
        entityUSAddressCheckGetter: 'applicant/hasUSAddress',
      },
    },
    {
      path: 'verify-identity',
      name: 'identityVerification',
      component: SubmitIdentityVerification,
    },
    {
      path: 'screening',
      name: 'screening',
      component: SubmitScreening,
      props: {
        nextRoute: 'payment',
        entityMarketCodeGetter: 'application/applicationMarketCountryCode',
        entityBackgroundCheckGetter: 'applicant/backgroundCheck',
        entityUSAddressCheckGetter: 'applicant/hasUSAddress',
      },
    },
    {
      path: 'payment',
      name: 'payment',
      component: SubmitPayment,
    },
    {
      path: 'success',
      name: 'submitSuccess',
      component: SubmitSuccess,
      meta: { disableBack: true },
    },
    {
      path: 'failed',
      name: 'submitFailed',
      component: SubmitFailed,
    },
  ],
}

export default routes
