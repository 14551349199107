import queryString from 'query-string'

const constants = {
  list: (query) => `pricing/constants/?${queryString.stringify(query)}`,
}

const flags = {
  featureFlags: 'flags/',
}

export default {
  flags,
  constants,
}
