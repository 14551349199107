import ApplyContainer from './containers/ApplyContainer'
import ApplyHome from './views/ApplyHome.vue'
import GroupInvite from './views/GroupInvite.vue'
import GroupGetStarted from './views/GroupGetStarted.vue'
import SubmitRoutes from '@/routes/submit'

const routes = {
  path: 'apply',
  component: ApplyContainer,
  meta: {
    section: 'apply',
    requiresAuth: true,
    currentStep: 1,
    basicNav: false,
  },
  children: [
    {
      path: '',
      name: 'apply',
      component: ApplyHome,
    },
    {
      path: 'invite-your-group',
      name: 'groupsInvite',
      component: GroupInvite,
    },
    {
      path: 'get-started',
      name: 'groupsGetStarted',
      component: GroupGetStarted,
    },
    SubmitRoutes,
  ],
}

export default routes
