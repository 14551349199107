<template>
  <div id="property-room-pricing-slider" :class="['px-4 py-3', { closing }]">
    <v-layout class="mb-4 text-xs-center">
      <v-flex class="main-header accent-font font-bold pt-2">Adjust the price</v-flex>
    </v-layout>

    <v-layout class="mt-3 mb-2 text-xs-center">
      <v-flex xs12 class="header-md monthly-price-display accent-font font-weight-regular">
        ${{ roomPriceToShow }} / month
      </v-flex>
    </v-layout>
    <v-layout pb-4 pt-1 justify-center align-center class="grey--text subheader-sm">
      {{ leaseDuration }}-month lease (excl. utilities)
    </v-layout>
    <pricing-slider
      :basePrice="selectedRoomBasePrice"
      :discountedPrice="selectedRoomDiscountPrice"
      :moveInDate="move_in_date"
      :leaseEndDate="lease_end_date"
      :leaseDuration="leaseDuration"
      :availabilityDate="selectedRoomAvailabilityDate"
      :onChangeHandler="onSlidersChanged"
    />

    <v-layout v-if="invalidMoveInDate" mt-3>
      <v-flex class="text-xs-center red--text">
        <span
          >Room is available on {{ selectedRoom.availability_date }}. Please adjust your move in
          date.</span
        >
      </v-flex>
    </v-layout>

    <v-layout mt-2>
      <v-flex xs12>
        <blw-button
          :loading="loading"
          :disabled="invalidMoveInDate"
          block
          class="mt-3"
          label="Save"
          color="charcoal"
          @click="confirmLeaseLengthAndMoveInDate"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <blw-button
          class="mt-2"
          flat
          darkText
          @click="hideLeaseLengthSlider"
          block
          color="charcoal"
          label="Back"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isBefore } from 'date-fns'

import _ from 'lodash'
import PricingSlider from '@/shared/PricingSlider'

export default {
  name: 'PropertyRoomPricingSlider',
  components: { PricingSlider },
  props: {
    cancelClickHandler: {
      type: Function,
      required: true,
    },
    saveSelectionHandler: {
      type: Function,
      required: true,
    },
    selectedRoom: {
      type: Object,
      required: true,
    },
    move_in_date: {
      type: String,
    },
    lease_end_date: {
      type: String,
    },
    leaseLength: {
      type: [String, Number],
    },
  },
  data() {
    return {
      loading: false,
      closing: false,
      leaseDuration: this.leaseLength,
      moveInDate: this.move_in_date,
      leaseEndDate: this.lease_end_date,
      roomPrice: 0,
    }
  },
  beforeMount() {
    this.roomPrice = this.selectedRoomBasePrice
  },
  computed: {
    ...mapGetters('application', ['error', 'apiFieldError', 'updating']),
    invalidMoveInDate() {
      const moveInBeforeRoomAvailable = isBefore(
        this.moveInDate,
        this.selectedRoom.availability_date
      )
      return moveInBeforeRoomAvailable
    },
    selectedRoomAvailabilityDate() {
      return this.selectedRoom.availability_date
    },
    selectedRoomBasePrice() {
      if (!this.selectedRoom || !this.selectedRoom.price) return

      const [roomPrice] = this.selectedRoom.price
      return roomPrice
    },
    selectedRoomDiscountPrice() {
      return _.get(this.selectedRoom, 'discounted_price')
    },
    roomPriceToShow() {
      return this.roomPrice || this.selectedRoomBasePrice
    },
  },
  methods: {
    ...mapActions('application', ['updateApplication', 'updateApplicationByKey']),
    onSlidersChanged({ moveInDate, roomPrice, leaseLength, leaseEndDate }) {
      this.moveInDate = moveInDate
      this.roomPrice = roomPrice
      this.leaseDuration = leaseLength
      this.leaseEndDate = leaseEndDate
    },
    async confirmLeaseLengthAndMoveInDate() {
      this.loading = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Pricing Slider (back of flip card)',
          color: 'Charcoal',
          text: 'Adjust the price',
          CTA: 'Save',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      await this.saveSelectionHandler({
        move_in_date: this.moveInDate,
        lease_end_date: this.leaseEndDate,
        monthlyRoomPrice: this.roomPrice,
        leaseLength: this.leaseDuration,
      })

      this.loading = false
    },
    hideLeaseLengthSlider() {
      this.closing = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Pricing Slider (back of flip card)',
          color: 'Charcoal',
          text: 'Adjust the price',
          CTA: 'Back',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      setTimeout(() => this.cancelClickHandler(), 500)
    },
  },
}
</script>
<style lang="scss">
#property-room-pricing-slider {
  height: max-content;
  background: white;
  z-index: 4;
  width: 100%;
  opacity: 0;
  animation: fade-in 0.5s forwards;
  box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);

  .main-header {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  &.closing {
    animation: fade-out 0.5s forwards;
  }

  @include mobile {
    > .container {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .monthly-price-display accent-font {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
}
</style>
