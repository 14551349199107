<template>
  <blw-form
    :onSubmit="onContinue"
    :updating="updating || updatingApplicationRequest || loadingApplication"
    :error="error.message || applicationRequestError.message"
    :text="text"
    header="Nominate a co-signer"
    class="mx-auto"
  >
    <v-layout wrap>
      <v-flex xs12 md6>
        <blw-text-field
          :rules="[maxLength(50), required]"
          :error-messages="apiFieldError('first_name')"
          label="First name"
          hint="This should be their legal first name"
          v-model="first_name"
        />
      </v-flex>

      <v-flex xs12 md6>
        <blw-text-field
          :rules="[maxLength(50), required]"
          :error-messages="apiFieldError('last_name')"
          label="Last name"
          hint="This should be their legal last name"
          v-model="last_name"
        />
      </v-flex>

      <v-flex xs12 md6>
        <blw-text-field
          :rules="[emailField, maxLength(100), required]"
          :error-messages="apiFieldError('email')"
          label="Email"
          v-model="email"
        />
      </v-flex>
      <v-flex xs12 md6>
        <blw-phone
          :rules="[required]"
          :error-messages="apiFieldError('phonenumber')"
          label="Phone number"
          v-model="phonenumber"
        />
      </v-flex>
    </v-layout>
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { emailField, maxLength, required } from '@/utils/formValidations'

export default {
  name: 'IncomeCosigner',
  props: {
    nextPath: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    const { cosignerId } = this.$route.params

    this.resetCosignerSource()

    if (cosignerId) {
      const applicationCosigner = this.getCosignerSourceById(cosignerId)

      this.setCosignerSource(applicationCosigner)
    }
  },
  computed: {
    ...mapFields('cosignerSource', [
      'cosignerSource.first_name',
      'cosignerSource.last_name',
      'cosignerSource.email',
      'cosignerSource.phonenumber',
    ]),
    ...mapGetters('application', ['applicationId', 'getCosignerSourceById', 'applicationRequest']),
    ...mapGetters({
      applicationRequestError: 'application/error',
      updatingApplicationRequest: 'application/updating',
      loadingApplication: 'application/loading',
    }),
    ...mapGetters('cosignerSource', [
      'cosignerSource',
      'cosignerSourceId',
      'cosignerSourceSlug',
      'apiFieldError',
      'updating',
      'error',
    ]),
  },
  methods: {
    ...mapActions('cosignerSource', [
      'resetCosignerSource',
      'setCosignerSource',
      'createCosignerSource',
      'updateCosignerSource',
      'deleteCosignerSourceBySlug',
    ]),
    ...mapActions('application', ['fetchApplicationBySource', 'updateApplicationRequest']),
    required,
    emailField,
    maxLength,
    async onContinue() {
      const { name: routeName } = this.$route
      const attemptToUpdateApplicationRequest =
        routeName === 'reviewAddCoSigner' && this.applicationRequest

      this.cosignerSourceId
        ? await this.updateCosignerSource()
        : await this.createCosignerSource(this.applicationId)

      if (!this.error) {
        // re-fetch application which has the updated cosigner sources
        await this.fetchApplicationBySource(this.$route.params.applicationSource)

        if (attemptToUpdateApplicationRequest) {
          await this.updateApplicationRequest({
            requestId: this.applicationRequest.id,
            payload: {
              application: this.applicationId,
              status: 'completed',
            },
          })
          // do not continue if this request to update the application request fails
          if (this.applicationRequestError) {
            return
          }
        }
        // everything worked and we're ready to move forward !
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Nominate Co-signer',
            CTA: 'Continue',
            category: 'Employment Information',
            type: 'Button',
          },
        })

        this.$routerPush({ name: this.nextPath })
        // if we are doing this for an application request, show the fancy success modal !
        attemptToUpdateApplicationRequest &&
          setTimeout(() => {
            this.$root.$emit('openModal', {
              modalComponent: 'LeasingCosignerAddedModal',
              modalComponentProps: {
                cosignerFirstName: this.first_name,
              },
              modalSize: 'small',
            })
          }, 500)
      }
    },

    // Once designs are in plug this in
    async deleteCosigner(slug) {
      await this.deleteCosignerSourceBySlug(slug)
      !this.error && this.$routerPush({ name: this.nextPath })
    },
  },
}
</script>
