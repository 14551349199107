import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

import modules from './modules'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  getters: {
    getState: (state) => _.cloneDeep(state),
  },
  actions: {
    /**
     *
     * This handler searchers for all reset state functions of each module,
     * if one exists it will commit that reset action, if not nothing will happen
     */
    resetState({ commit }) {
      Object.entries(modules).forEach(([moduleName, moduleConfig]) => {
        const moduleResetFunction = _.get(moduleConfig, 'mutations.resetState')
        if (moduleResetFunction) {
          commit(`${moduleName}/resetState`)
        }
      })
    },
  },
  strict: debug,
})
