<template>
  <loading-spinner v-if="fetching" full-page />
  <router-view v-else v-bind="profileData" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'ProfileContainer',
  components: { LoadingSpinner },
  data() {
    return {
      fetching: true,
    }
  },
  computed: {
    ...mapGetters('application', ['applicationStatus']),
    ...mapGetters('profile', ['profile', 'roommate']),
    ...mapGetters('user', ['userId']),
    profileData() {
      const profile = this.isApplicantProfile ? this.profile : this.roommate

      return {
        ...profile,
        self: this.isApplicantProfile,
        status: this.applicationStatus,
        submitProfile: this.submitProfile,
      }
    },
    isApplicantProfile() {
      return this.$route.params.userId === this.userId
    },
    shouldFetchProfileData() {
      return !this.profile || !this.interests || !this.preferences
    },
  },
  methods: {
    ...mapActions('profile', ['fetchProfile', 'fetchRoommateProfile', 'submitProfile']),
    getOrFetchProfile() {
      return this.profile || this.fetchProfile()
    },
  },
  async beforeMount() {
    const userId = this.$route.params.userId

    if (!userId) return this.$routerPush({ name: 'notFound' })

    this.isApplicantProfile
      ? await this.getOrFetchProfile()
      : await this.fetchRoommateProfile(this.$route.params.userId)

    this.fetching = false
  },
}
</script>
