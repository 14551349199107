<template>
  <blw-form
    header="Where do you work?"
    text="Tell us about your current or upcoming job.<br/><b>This helps us match rooms in your budget that we know you'll love!</br>"
    :onSubmit="onContinue"
    :updating="updating"
    :error="error.message"
  >
    <v-layout row wrap justify-space-between>
      <v-flex xs12 md6>
        <blw-text-field
          :error-messages="apiFieldError('company_name')"
          label="Company"
          v-model="company_name"
          :rules="[required]"
        />
      </v-flex>

      <v-flex xs12 md6>
        <blw-text-field
          :error-messages="apiFieldError('job_title')"
          label="Job title"
          v-model="job_title"
          :rules="[required]"
        />
      </v-flex>
    </v-layout>

    <v-layout wrap justify-space-between>
      <v-flex xs4 md2>
        <blw-select
          :error-messages="apiFieldError('currency')"
          label="Currency"
          :items="currencies"
          v-model="currency"
          :rules="[required]"
        />
      </v-flex>
      <v-flex xs8 md4 class="monthly-salary-input">
        <blw-text-field
          :error-messages="apiFieldError('monthly_salary')"
          :hint="annualIncome"
          :rules="[required]"
          label="Monthly salary before tax"
          autocomplete="off"
          mask="$######"
          type="tel"
          v-model="monthly_salary"
        />
      </v-flex>
      <v-flex xs12 md6>
        <blw-calendar
          :birthday-mode="!start_date"
          :maxDate="maxStartDate"
          :error-messages="apiFieldError('start_date')"
          label="Start date"
          v-model="start_date"
          :rules="[required]"
        />
      </v-flex>
    </v-layout>
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { addMonths, format } from 'date-fns'

import { emailField, maxLength, required } from '@/utils/formValidations'
import { getAnnualIncome } from '@/utils/helpers'

export default {
  name: 'IncomeEmployment',
  props: {
    nextPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currencies: [
        { text: 'USD', value: 'usd' },
        { text: 'CAD', value: 'cad' },
      ],
    }
  },
  beforeMount() {
    this.contact_first_name = 'N/A'
    this.contact_last_name = 'N/A'
    this.contact_phonenumber = '+15195800887'
    this.contact_email = 'na@bungalow.com'
  },
  computed: {
    ...mapFields('income', [
      'income.company_name',
      'income.job_title',
      'income.monthly_salary',
      'income.currency',
      'income.start_date',
      'income.contact_first_name',
      'income.contact_last_name',
      'income.contact_phonenumber',
      'income.contact_email',
    ]),
    ...mapGetters('income', ['updating', 'error', 'apiFieldError']),
    annualIncome() {
      return this.getAnnualIncome(this.monthly_salary)
    },
    maxStartDate() {
      return format(addMonths(new Date(), 3), 'YYYY-MM-DD')
    },
  },
  methods: {
    emailField,
    maxLength,
    required,
    getAnnualIncome,
    ...mapActions('income', ['updateIncome']),
    async onContinue() {
      await this.updateIncome()

      if (!this.error) {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Primary Income',
            CTA: 'Continue',
            category: 'Employment Information',
            type: 'Button',
          },
        })

        this.$root.$emit('openModal', {
          modalComponent: 'ConfirmIncomeModal',
          modalSize: 'sm',
          modalComponentProps: {
            nextPath: this.nextPath,
            applicantIncome: this.monthly_salary,
            applicantEmployer: this.company_name,
          },
        })
      }
    },
  },
}
</script>
<style>
.monthly-salary-input div.v-text-field__slot > label {
  max-width: none;
}
</style>
