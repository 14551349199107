const modalModules = require.context('.', true, /\.vue$/)
const modals = {}
/**
 * Load all modals in this folder and export an object with
 * the Modal names as the keys and the actual modals as the values
 */
modalModules.keys().forEach((fileName) => {
  if (fileName === './index.js') return
  const modalModule = modalModules(fileName)
  modals[modalModule.default.name] = modalModules(fileName).default
})

export default modals
