import HomeSelectionContainer from './containers/HomeSelectionContainer'
import HomeSelection from './views/HomeSelection'
import HomeSelectionConfirm from './views/HomeSelectionConfirm'
import HomeSelectionComplete from './views/HomeSelectionComplete'

const routes = {
  path: 'home-selection',
  component: HomeSelectionContainer,
  meta: {
    section: 'homeSelection',
    requiresAuth: true,
    currentStep: 3,
  },
  children: [
    {
      path: '',
      name: 'homeSelection',
      component: HomeSelection,
    },
    {
      path: 'confirm',
      name: 'homeSelectionConfirm',
      component: HomeSelectionConfirm,
      meta: {
        applicationClasses: 'bg-white full-height',
      },
    },
    {
      path: 'complete',
      name: 'homeSelectionComplete',
      component: HomeSelectionComplete,
      meta: { currentStep: 4 },
    },
  ],
}

export default routes
