<template>
  <v-layout row wrap>
    <v-flex md6>
      <blw-text-field
        :rules="[maxLength(50), required]"
        :error-messages="apiFieldError('first_name')"
        label="First name"
        hint="This should be your legal name"
        @blur="savedFieldLog('First Name')"
        v-model="first_name"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-text-field
        :rules="[maxLength(50)]"
        :error-messages="apiFieldError('middle_name')"
        label="Middle name"
        hint="*optional"
        persistent-hint
        @blur="savedFieldLog('Middle Name')"
        v-model="middle_name"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-text-field
        :rules="[maxLength(50), required]"
        :error-messages="apiFieldError('last_name')"
        label="Last name"
        hint="This should be your legal name"
        v-model="last_name"
        @blur="savedFieldLog('Last Name')"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-text-field
        :rules="[emailField, maxLength(100), required]"
        :error-messages="apiFieldError('email')"
        label="Email"
        v-model="email"
        @blur="savedFieldLog('Email')"
        box
      />
    </v-flex>

    <v-flex md6>
      <blw-calendar
        birthday-mode
        :disableDateTyping="false"
        :age-restriction="18"
        :rules="[required, minAge(18, 'Bungalow residents must be at least 18 years of age.')]"
        :error-messages="apiFieldError('birthdate')"
        label="Date of birth"
        v-model="birthdate"
        box
      />
    </v-flex>
    <v-flex md6>
      <blw-phone
        boxDropdown
        :rules="[required]"
        :error-messages="apiFieldError('phonenumber')"
        label="Phone number"
        v-model="phonenumber"
        box
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import _ from 'lodash'

import { emailField, maxLength, required, minAge } from '@/utils/formValidations'

export default {
  name: 'PersonalInformation',
  computed: {
    ...mapFields('applicant', [
      'applicant.first_name',
      'applicant.middle_name',
      'applicant.last_name',
      'applicant.birthdate',
      'applicant.email',
      'applicant.phonenumber',
    ]),
    ...mapGetters('applicant', ['updating', 'error', 'apiFieldError', 'photo_id']),
    requiredFields() {
      return [this.first_name, this.last_name, this.birthdate, this.email, this.phonenumber]
    },
  },
  watch: {
    phonenumber(val, oldVal) {
      this.phonenumberChangeHandler(val, oldVal)
    },
    birthdate() {
      this.savedFieldLog('Date of birth')
    },
    requiredFields(val, oldVal) {
      if (val.every((fieldValue) => !!fieldValue) && !oldVal.every((fieldValue) => !!fieldValue)) {
        this.$trackEvent({
          action: 'Apply section complete',
          properties: {
            section: 'Personal Information',
          },
        })
      }
    },
  },
  methods: {
    ...{ minAge, required, emailField, maxLength },
    ...mapActions('applicant', ['updateApplicant']),
    phonenumberChangeHandler(val, oldVal) {
      if (val && val.length >= 12 && val !== oldVal) this.savedFieldLog('Phone number')
    },
    savedFieldLog(field) {
      this.$trackEvent({
        action: 'Field Saved',
        properties: {
          type: 'Application Page',
          text: field,
        },
      })
      this.debouncedUpdateApplicant()
    },
    debouncedUpdateApplicant: _.debounce(function () {
      this.updateApplicant()
    }, 500),
  },
}
</script>
