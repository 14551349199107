import MeetGreetContainer from './MeetGreetContainer'
import MeetGreetStart from './views/MeetGreetStart'
import MeetGreetTerms from './views/MeetGreetTerms'
import MeetGreetChat from './views/MeetGreetChat'
import MeetGreetReview from './views/MeetGreetReview'
import MeetGreetParticipantsView from './views/MeetGreetParticipantsView'
import MeetGreetPreferencesView from './views/MeetGreetPreferencesView'

import ProfileView from '../profile/views/ProfileView'
import ProfileEdit from '../profile/views/ProfileEdit'
import ProfileInterests from '../profile/views/ProfileInterests'
import ViewProfileContainer from '../profile/ViewProfileContainer'

export default {
  path: 'meet-and-greet',
  component: MeetGreetContainer,
  meta: {
    section: 'meetGreet',
    requiresAuth: true,
    hideNavMobile: true,
    currentStep: 4,
  },
  children: [
    {
      path: '',
      name: 'meetGreet',
      component: MeetGreetStart,
    },
    {
      path: 'terms',
      name: 'meetGreetTerms',
      component: MeetGreetTerms,
    },
    {
      path: 'chat',
      name: 'meetGreetChat',
      component: MeetGreetChat,
    },
    {
      path: 'participants',
      name: 'meetGreetParticipants',
      component: MeetGreetParticipantsView,
      meta: { hideNav: true },
    },
    {
      path: 'preferences',
      name: 'meetGreetPreferences',
      component: MeetGreetPreferencesView,
      meta: { hideNav: true },
    },
    {
      path: 'review',
      name: 'meetGreetReview',
      component: MeetGreetReview,
      meta: {
        currentStep: 4,
      },
    },
    {
      path: 'profile',
      component: ViewProfileContainer,
      children: [
        {
          path: ':userId',
          name: 'meetGreetProfileView',
          component: ProfileView,
          meta: { hideNav: true },
          props: {
            editRoute: 'meetGreetProfileEdit',
          },
        },
      ],
    },
    {
      path: 'edit',
      name: 'meetGreetProfileEdit',
      component: ProfileEdit,
      meta: { hideNav: true },
      props: {
        nextRoute: 'meetGreetChat',
        interestsRoute: 'meetGreetProfileInterests',
      },
    },
    {
      path: 'interests',
      name: 'meetGreetProfileInterests',
      component: ProfileInterests,
      meta: { hideNav: true },
      props: {
        editMode: true,
      },
    },
  ],
}
