<template>
  <v-container id="home-selection-confirm" class="py-5 mt-5">
    <v-layout>
      <v-flex xs12 justify-center d-flex header-md> Confirm your selection </v-flex>
    </v-layout>
    <v-layout pt-4>
      <v-flex xs12 d-flex justify-center>
        <v-img
          height="120"
          max-height="120"
          width="120"
          max-width="120"
          class="property-image"
          :src="propertyImage"
        />
      </v-flex>
    </v-layout>
    <v-layout justify-center pt-4>
      <div
        v-max-width="250"
        class="property-address font-bold accent-font text-xs-center"
        v-html="propertyAddress"
      />
    </v-layout>

    <v-layout pt-4 pb-1>
      <v-flex text-xs-center font-bold>{{ bedroomTitle }}</v-flex>
    </v-layout>
    <v-layout justify-center text-xs-center>
      <div v-max-width="215">{{ selectionSummaryText }}</div>
    </v-layout>

    <v-layout v-if="error" v-max-width="500" justify-center class="mt-4 mx-auto">
      <v-flex text-xs-center xs8 md5 class="confirm-text red--text">
        <div class="mt-2" v-for="(errorMsg, index) in error.non_field_errors" :key="index">
          {{ errorMsg }}
        </div>
        <div class="mt-2" v-for="(errorMsg, index) in error.field_errors" :key="index">
          {{ errorMsg }}
        </div>
      </v-flex>
    </v-layout>

    <v-layout justify-center pt-4 mt-4>
      <div class="text-xs-center confirm-text">
        This will notify the roommates and <br />kickoff the 24-hour meet and greet period.
      </div>
    </v-layout>

    <v-layout justify-center mt-3>
      <v-flex text-xs-center md6>
        <blw-button :loading="loading" @click="confirmSelection"> Confirm </blw-button>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex text-xs-center md6>
        <blw-button class="mt-2" flat darkText color="charcoal" @click="goBack" label="Back" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { get as _get, pick as _pick } from 'lodash'
import { format } from 'date-fns'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'HomeSelectionConfirm',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('application', [
      'application',
      'availableProperties',
      'homeSelectionDisplayState',
      'confirmRoomSelectionData',
      'error',
    ]),
    ...mapFields('leasePreference', [
      'leasePreference.move_in_date',
      'leasePreference.lease_end_date',
    ]),
    ...mapFields('application', {
      applicationRoom: 'application.room',
      applicationMoveInDate: 'application.move_in_date',
      applicationLeaseEndDate: 'application.lease_end_date',
    }),
    bedroomTitle() {
      return _get(this.confirmRoomSelectionData, 'roomDetails.room_type')
    },
    propertyAddress() {
      const propertyAddress = _get(
        this.confirmRoomSelectionData,
        'propertyDetails.property.address',
        {}
      )
      return `${propertyAddress.street_address}<br> ${propertyAddress.city} ${propertyAddress.region}, ${propertyAddress.postal_code}`
    },
    propertyImage() {
      return _get(
        this.confirmRoomSelectionData,
        'propertyDetails.property.images[0].sm_url',
        'https://dbjpekgzfghzs.cloudfront.net/18c2384c5e8b704748639b731725996f18460ac0-md.jpg'
      )
    },
    selectionSummaryText() {
      const monthlyPrice = _get(this.confirmRoomSelectionData, 'leaseDetails.monthlyRoomPrice')
      const leaseLengthInMonths = _get(this.confirmRoomSelectionData, 'leaseDetails.leaseLength')
      const leaseMoveInDate = format(
        _get(this.confirmRoomSelectionData, 'leaseDetails.move_in_date'),
        'MMMM Do'
      )

      return `$${monthlyPrice} / ${leaseLengthInMonths}-month lease starting ${leaseMoveInDate}`
    },
  },
  beforeMount() {
    if (!this.confirmRoomSelectionData) {
      this.$routerPush({ name: 'homeSelection' })
    }
  },
  methods: {
    ...mapActions('leasePreference', ['updateLeasePreference']),
    ...mapActions('application', ['saveApplicationRoomSelection', 'updateApplication']),
    async confirmSelection() {
      this.loading = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Home Confirmation',
          color: 'Salmon',
          text: 'Confirm your selection',
          CTA: 'Confirm',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.lease_end_date = this.confirmRoomSelectionData.leaseDetails.lease_end_date
      this.move_in_date = this.confirmRoomSelectionData.leaseDetails.move_in_date
      await this.updateLeasePreference()

      this.applicationRoom = this.confirmRoomSelectionData.leaseDetails.roomId
      this.applicationLeaseEndDate = this.confirmRoomSelectionData.leaseDetails.lease_end_date
      this.applicationMoveInDate = this.confirmRoomSelectionData.leaseDetails.move_in_date
      await this.updateApplication()

      await this.saveApplicationRoomSelection(this.confirmRoomSelectionData.leaseDetails.roomId)

      if (!this.error) {
        this.$routerPush({ name: 'homeSelectionComplete' })
      } else {
        this.$trackEvent({
          action: 'Home Selection Confirm Error',
          properties: {
            confirmRoomSelectionData: this.confirmRoomSelectionData,
            homeSelectionDisplayState: this.homeSelectionDisplayState,
            availableProperties: this.availableProperties.map((property) =>
              _pick(property.property, ['id', 'availableRooms', 'rooms'])
            ),
            lease_preferences: {
              move_in_date: this.move_in_date,
              move_out_date: this.lease_end_date,
            },
          },
        })
      }
      this.loading = false
    },
    goBack() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Home Confirmation',
          color: 'Flat',
          text: 'Confirm your selection',
          CTA: 'Back',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.$routerPush({ name: 'homeSelection' })
    },
  },
}
</script>

<style lang="scss">
#home-selection-confirm {
  .property-image {
    border-radius: 100%;
  }
  .confirm-text {
    font-size: 0.6875rem;
    line-height: 1rem;
  }
  .property-address {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
</style>
