<template>
  <div id="confirm-address-modal">
    <v-layout column align-center>
      <h2 class="modal-grid-header header-1 text-xs-center mt-2 mb-4">Track your application</h2>
      <p class="text-xs-center mb-4 px-4" v-max-width="280">
        There are a few more steps to complete before signing a lease.
      </p>
      <!-- Swiper -->
      <swiper @slideChange="slideChangeHandler" :options="swiperOptions" class="pb-4 mb-4">
        <swiper-slide :key="index" v-for="(item, index) in carouselItems">
          <v-layout column align-center justify-center>
            <blw-illustration type="spot" :name="item.spotIllustration" size="76" />
            <h2 class="lead pb-3 pt-3 text-uppercase">{{ item.title }}</h2>
            <h2 v-if="item.subtitle" class="lead pb-3 text-uppercase">
              {{ item.subtitle }}
            </h2>
          </v-layout>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
        <div
          v-ripple
          slot="button-prev"
          class="swiper-button swiper-button-round swiper-button-prev"
        >
          <v-icon color="charcoal">arrow_back</v-icon>
        </div>
        <div
          v-ripple
          slot="button-next"
          class="swiper-button swiper-button-round swiper-button-next"
        >
          <v-icon color="charcoal">arrow_forward</v-icon>
        </div>
      </swiper>
      <blw-button size="lg" color="charcoal" @click="onContinue" label="Continue" />
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'LeasingIntroductionModal',
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      carouselItems: [
        {
          spotIllustration: 'complete-profile',
          title: 'complete profile',
          subtitle: '2-3 minutes',
        },
        {
          spotIllustration: 'choose-your-home',
          title: 'choose your home',
          subtitle: '2-3 minutes',
        },
        {
          spotIllustration: 'meet-roommates',
          title: 'meet roommates',
          subtitle: '24 hour chat window',
        },
        {
          spotIllustration: 'keys',
          title: 'sign lease',
        },
      ],
    }
  },
  methods: {
    slideChangeHandler() {
      this.$trackEvent({
        action: 'Opened Intro Modal Slide Changed',
        properties: {
          location: 'IntroModal',
        },
      })
    },
    onContinue() {
      this.$trackEvent({
        action: 'CTA Clicked: Opened Intro Modal',
        properties: {
          CTA: 'Got It',
          event: 'CTA Clicked',
          location: 'IntroModal',
        },
      })
      this.$root.$emit('closeModal')
    },
  },
  mounted() {
    this.$trackEvent({
      action: 'Opened Intro Modal',
      properties: {
        event: 'open modal',
        CTA: 'Got it',
        location: 'IntroModal',
      },
    })
  },
  beforeDestroy() {
    const { applicationSource } = this.$route.params
    localStorage.setItem(`applicationLeaseIntroShown-${applicationSource}`, true)
  },
}
</script>
<style lang="scss">
#confirm-address-modal {
  .swiper-container,
  .swiper-slide {
    width: 100%;
    max-width: 420px;

    @include mobile {
      max-width: 300px;
    }
  }

  .swiper-button {
    display: flex;
    border: 1px solid black;
    @include mobile {
      height: 40px;
      width: 40px;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background: white;
    border: 1px solid $charcoal-60;
  }

  .swiper-pagination-bullet-active {
    background: $charcoal-60;
  }
}
</style>
