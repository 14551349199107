<template>
  <div
    :class="['flip-card', { flipped: visibleSide === 'back' }]"
    :style="{ height: cardHeight ? `calc(${cardHeight}px + 3rem)` : null }"
  >
    <div class="flip-card-inner">
      <div class="flip-card-front" ref="flipCardFrontRef">
        <slot
          name="flipCardFront"
          :flipCard="() => flipCard('back')"
          :reCalculateHeight="reCalculateHeight"
        />
      </div>
      <div class="flip-card-back" ref="flipCardBackRef">
        <slot
          name="flipCardBack"
          :flipCard="() => flipCard('front')"
          :reCalculateHeight="reCalculateHeight"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlipCard',
  props: {
    onCardFlip: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cardHeight: null,
      visibleSide: 'front',
    }
  },
  mounted() {
    const frontCardHeight = this.$refs.flipCardFrontRef.firstElementChild.clientHeight
    this.cardHeight = frontCardHeight
  },
  watch: {
    visibleSide() {
      this.reCalculateHeight()
    },
  },
  methods: {
    flipCard(frontFacingSide) {
      this.visibleSide = frontFacingSide
      this.onCardFlip(frontFacingSide)
    },
    reCalculateHeight() {
      setTimeout(() => {
        const newCardHeight =
          this.$refs[this.visibleSide === 'front' ? 'flipCardFrontRef' : 'flipCardBackRef']
            .firstElementChild.clientHeight
        this.cardHeight = newCardHeight
      })
    },
  },
}
</script>

<style lang="scss">
.flip-card {
  background-color: $cream;
  perspective: 62.5rem;
  transition: height 0.8s;
  padding: 0.25rem 1rem;

  @include mobile {
    padding: 0;
  }

  .flip-card-front,
  .flip-card-back {
    transition: opacity 0.4s !important;
  }

  &:not(.flipped) {
    .flip-card-back {
      pointer-events: none;
      opacity: 0;
    }
  }
  &.flipped {
    .flip-card-front {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(-180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>
