<template>
  <v-card class="home-shopping-card br-8 pa-3" flat>
    <div
      v-if="meetingDetailsBannerConfig"
      v-text="meetingDetailsBannerConfig.text"
      :class="`d-flex justify-center align-center pa-1 mb-2 font-weight-bold subheader-sm br-8 ${meetingDetailsBannerConfig.class}`"
    />
    <v-img class="white--text align-end relative br-8" height="156px" :src="mainImage">
      <div
        v-if="property._availableRoomCount"
        class="property-price-banner font-weight-bold green-20--bg green-60--txt br-4 px-2"
      >
        <span v-if="property._isGroupHome">${{ property.full_property_price }}</span>
        <template v-else>
          <span :class="{ 'discounted-active-price': showDiscountedPrice }">
            ${{ property._minBasePrice }}
          </span>
          <span v-if="showDiscountedPrice">${{ property._minDiscountedPrice }}</span>
        </template>
        <span>+ /mo</span>
      </div>
      <v-btn v-if="!hidden" @click="hideProperty" small icon class="hide-property-button br-4">
        <v-icon color="white">close</v-icon>
      </v-btn>
      <v-btn @click="previewProperty" small icon class="preview-home-button br-4">
        <v-icon color="white" size="20">open_in_new</v-icon>
      </v-btn>
    </v-img>
    <v-card-text class="text--primary px-2">
      <div class="header-sm font-weight-bold mb-2" v-html="propertyAddress" />
      <div class="property-summary">
        {{ property.total_room_count }} bed •
        <span v-if="property._isGroupHome">Entire Home</span>
        <span v-else>{{ property._petFriendly ? 'Pet Friendly' : 'No pets' }}</span>
      </div>
    </v-card-text>
    <div v-if="unavailablePropertyConfig" class="mt-4">
      <v-layout my-4>
        <v-flex d-flex justify-center align-center>
          <blw-icon :icon="unavailablePropertyConfig.icon" color="red" />
        </v-flex>
      </v-layout>
      <v-layout mt-3>
        <v-flex class="text-xs-center">
          <span v-text="unavailablePropertyConfig.text" />
        </v-flex>
      </v-layout>
    </div>
    <!-- for group homes block the ability to email welcomehome@bungalow.com
    and block the ability to start a chat if the home somehow has roommates -->
    <template v-else-if="property._isGroupHome || !property.roommates.length">
      <div
        v-if="!property.is_premarketing"
        class="text-xs-center subheader-sm font-weight-bold mt-2 mb-3"
      >
        <span v-if="!property._isGroupHome"> You'll be the first one living here! </span>
      </div>
      <v-card-actions class="mt-2">
        <blw-button
          v-if="property.is_premarketing"
          @click="preMarketingPropertyOnClick"
          color="primary"
          block
        >
          Join the waitlist
        </blw-button>
        <blw-button v-else @click="requestLeaseClickHandler" color="outlined" block>
          Request a lease
        </blw-button>
      </v-card-actions>
    </template>
    <template v-else>
      <v-layout v-if="roommatesWithAvailableProfiles.length" class="mb-3">
        <v-flex class="text-xs-center lead"> Click a roommate to view profile </v-flex>
      </v-layout>
      <v-container class="roommate-info br-8 py-3 px-2">
        <v-layout wrap>
          <v-flex xs2 v-for="(roommate, i) in roommateShortList" :key="i" class="flex-center">
            <v-avatar
              @click="viewRoommateProfile(roommate)"
              class="clickable"
              size="32"
              color="white lighten-4"
            >
              <span v-if="roommate._extraRoommateCount" class="font-small">
                +{{ roommate._extraRoommateCount }}
              </span>
              <img
                v-else
                :src="roommate.profile_image_url || roommateFallbackImage"
                alt="avatar"
                @error="imageOnLoadErrorHandler"
              />
            </v-avatar>
          </v-flex>
        </v-layout>
        <v-layout
          class="text-xs-center mt-3"
          v-if="property.roommate_shared_interests && property.roommate_shared_interests.length"
        >
          <div>
            <v-chip
              v-for="(interest, i) in property.roommate_shared_interests"
              :key="i"
              outline
              color="white"
              text-color="#413C3B"
            >
              <v-img height="15" width="15" :src="interest.icon" class="mr-2" />
              <b v-text="interest.label" />
            </v-chip>
          </div>
        </v-layout>
      </v-container>
      <v-card-actions class="mt-2 justify-center">
        <blw-button v-if="hidden" @click="hideProperty" color="primary" block>
          Re-add property
        </blw-button>
        <blw-button
          v-else-if="property.is_premarketing"
          @click="preMarketingPropertyOnClick"
          color="primary"
          block
        >
          Join the waitlist
        </blw-button>
        <blw-button
          v-else-if="!hidden && ctaConfig.onClick"
          @click="ctaConfig.onClick"
          :color="ctaConfig.btnColor"
          block
        >
          <span v-text="ctaConfig.btnText" />
        </blw-button>
        <span v-else class="lead mt-2" v-text="ctaConfig.btnText" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mapMutations, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'PropertyCard',
  props: {
    property: {
      type: Object,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    roommateFallbackImage: 'https://assets.bungalow.com/avatars/pineapple-avatar-4.png',
  }),
  computed: {
    ...mapFields('homeShopping', ['homeShopping.selectedProperty']),
    ...mapGetters('application', ['applicationMarketSlug']),
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('leasePreference', [
      'leasePreferencePetFriendly',
      'leasePreferenceMasterBedroom',
    ]),
    mainImage() {
      return _.get(
        this.property,
        'images[0].md_url',
        'https://assets.bungalow.com/images/property-placeholder.jpg'
      )
    },
    // returns first 6 roommates, if more than 6 returns 5 with the extra count
    roommateShortList() {
      const { roommates } = this.property
      const sortedRoommates = [...roommates].sort((a, b) => !!b.id - !!a.id)
      if (sortedRoommates.length > 6) {
        return [
          ...sortedRoommates.slice(0, 5),
          {
            _extraRoommateCount: sortedRoommates.length - 5,
          },
        ]
      }
      return sortedRoommates
    },
    roommatesWithAvailableProfiles() {
      return this.property.roommates.filter(({ id }) => !!id)
    },
    unavailablePropertyConfig() {
      const configurations = {
        fullHouse: {
          text: 'This home is already full',
          icon: 'home-selection/full-house',
        },
        noPets: {
          text: 'Pets not allowed in this home',
          icon: 'home-selection/no-pets',
        },
        masterUnavailable: {
          text: 'Master bedroom no longer available',
          icon: 'home-selection/no-master-bedroom',
        },
      }
      if (!this.property._availableRoomCount) return configurations.fullHouse
      if (this.leasePreferencePetFriendly && !this.property._petFriendly)
        return configurations.noPets
      if (this.leasePreferenceMasterBedroom && !this.property._masterBedroomAvailable)
        return configurations.masterUnavailable
      return null
    },
    ctaConfig() {
      const configurations = {
        default: {
          onClick: this.startPropertySelection,
          btnColor: 'primary',
          btnText: 'Chat with this home',
        },
        openMeeting: {
          onClick: this.continueChattingClickHandler,
          btnColor: 'pink-gradient',
          btnText: 'Return to chat',
        },
        pendingDecision: {
          btnText: 'Waiting for house decision',
        },
        rejectedMeeting: {
          btnText: 'Chat has ended',
        },
        approvedMeeting: {
          onClick: this.requestLeaseClickHandler,
          btnColor: 'pink-gradient',
          btnText: 'Accept invitation',
        },
      }
      // property meeting detected
      if (this.property._meeting) {
        const {
          status: meetingStatus,
          _timeRemaining: { hasExpired },
        } = this.property._meeting
        // if we have an open meeting, conditionally return a config respecting if the meeting is expired
        if (['open', 'closed'].includes(meetingStatus)) {
          return configurations[hasExpired ? 'pendingDecision' : 'openMeeting']
        }
        // only show the approved ( request a lease button if the meeting has been fully approved )
        if (meetingStatus === 'approved') {
          return configurations.approvedMeeting
        }
        // by default return the rejected ( chat has ended ) config
        return configurations.rejectedMeeting
      }
      // no chat found, return Chat with this home config
      return configurations.default
    },
    meetingDetailsBannerConfig() {
      if (this.property._meeting) {
        const { hours, hasExpired } = this.property._meeting._timeRemaining
        if (this.property._meeting.status === 'approved') {
          return {
            class: 'highlighted-gradient',
            text: `Congratulations! It's a match 🎉`,
          }
        }
        return this.property._meeting.status === 'open' && !hasExpired
          ? {
              class: hours > 6 ? 'green-20--bg green-60--txt' : 'salmon-20--bg salmon-60--txt',
              text: this.formattedMeetingTimeRemaining(),
            }
          : {
              class: 'charcoal-10--bg lightest--txt',
              text: 'This chat has ended',
            }
      }
      return null
    },
    propertyAddress() {
      const { street_address, unit, city, postal_code } = this.property.address
      return `${street_address} ${unit ? `#${unit}` : ''}<br> ${city}, ${postal_code}`
    },
    showDiscountedPrice() {
      return this.property._minBasePrice !== this.property._minDiscountedPrice
    },
    ctaText() {
      return 'Chat with this home'
    },
  },
  methods: {
    ...mapMutations('homeShopping', ['resetState']),
    hideProperty() {
      this.$root.$emit('openModal', {
        modalComponent: 'HidePropertyModal',
        modalComponentProps: {
          propertySlug: this.property.slug,
        },
        modalSize: 'small',
      })
    },
    viewRoommateProfile(roommate) {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Click a roommate to view profile',
          text: 'Click a roommate to view profile',
          category: 'Home Selection',
          property_id: this.property.id,
        },
      })
      if (roommate.id) {
        return this.$routerPush({
          name: 'profileView',
          params: { userId: roommate.id },
          query: { roommatePreview: true },
        })
      }
      this.$root.$emit('showSnackbar', {
        text: 'No profile available',
        timeout: 4000,
      })
    },
    imageOnLoadErrorHandler(event) {
      event.target.src = this.roommateFallbackImage
    },
    previewProperty() {
      this.$root.$emit('showIframe', {
        srcUrl: `${process.env.VUE_APP_BUNGALOW_WEBSITE_BASE_URL}listings/${this.applicationMarketSlug}/${this.property.slug}?preview_mode=true`,
        closeTopBarHeight: this.$vuetify.breakpoint.smAndDown ? '61px' : '100px',
      })
    },
    formattedMeetingTimeRemaining() {
      const { hours, minutes } = this.property._meeting._timeRemaining
      return `${hours} hours ${minutes} min remaining`
    },
    // sparks room and lease preference selection
    startPropertySelection() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Chat with this home',
          text: 'Chat with this home',
          category: 'Home Selection',
          property_id: this.property.id,
        },
      })
      this.resetState()
      window.scrollTo(0, 0)
      this.selectedProperty = this.property
      this.$root.$emit('openModal', {
        modalComponent: 'HomeSelectionBedroomModal',
        modalSize: this.$vuetify.breakpoint.smAndDown ? 'fullscreen' : 'small',
      })
    },
    // routes to meet and greet for this properties open chat
    continueChattingClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Return to chat',
          text: 'Return to chat',
          category: 'Home Selection',
          property_id: this.property.id,
        },
      })
      window.scrollTo(0, 0)
      this.$routerPush({
        name: 'meetGreetChat',
        query: {
          meetingId: this.property._meeting.id,
        },
      })
    },
    // open email with property details requesting lease
    requestLeaseClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Accept Invitation',
          text: 'Accept invitation',
          category: 'Home Selection',
          property_id: this.property.id,
        },
      })
      const { first_name, last_name } = this.userProfile
      const formattedPropertyAddress = this.propertyAddress.replace('<br>', '')
      const subject = `Request to move forward with the Bungalow home at ${formattedPropertyAddress}`
      const to = `${this.property._isGroupHome ? 'homes' : 'welcomehome'}@bungalow.com`
      const body = encodeURIComponent(
        `Hi there 👋 \n\nThis is ${first_name} ${last_name}. The Bungalow home at ${formattedPropertyAddress} looks like a great fit and would like to know next steps in the leasing process.\n\nThank you,\n${first_name}`
      )
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)
    },
    preMarketingPropertyOnClick() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Join the waitlist',
          text: 'Join the waitlist',
          category: 'Home Selection',
          property_id: this.property.id,
        },
      })
      const { first_name, last_name } = this.userProfile
      const formattedPropertyAddress = this.propertyAddress.replace('<br>', '')
      const subject = `Request to move forward with the Bungalow home at ${formattedPropertyAddress}`
      const to = 'welcomehome@bungalow.com'
      const body = encodeURIComponent(
        `Hi there 👋 \n\nThis is ${first_name} ${last_name}. The Bungalow home at ${formattedPropertyAddress} looks like a great fit and I would like to join the waitlist.\n\nThank you,\n${first_name}`
      )
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.home-shopping-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  .v-image {
    .property-price-banner {
      padding: 4px;
      width: max-content;
      margin: 16px;
      font-size: 13px;
      line-height: 20px;
      .discounted-active-price {
        color: black;
        text-decoration: line-through;
        margin-right: 0.3rem;
      }
    }
  }
  .property-summary {
    color: $charcoal-30;
  }
  .roommate-info {
    background-color: #e6e3e380;
    .v-chip {
      background: #fff !important;
    }
  }
  .preview-home-button {
    top: 16px;
    right: 16px;
    position: absolute;
    background-color: #00000078 !important;
    border-color: #00000082 !important;
  }
  .hide-property-button {
    top: 16px;
    left: 16px;
    position: absolute;
    background-color: #00000078 !important;
    border-color: #00000082 !important;
  }
}
</style>
