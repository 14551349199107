<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height text-xs-center">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 v-max-width="285" class="header-md mb-5">
          We’re waiting on your co-signer’s application
        </h1>
        <blw-illustration name="cat-clock" size="175" class="mb-4" />
        <div v-max-width="395">
          <p class="mb-4">
            Once your co-signer has submitted their application, it may take a few hours for us to
            get back to you.
          </p>
          <p class="text-xs-center">We’ll notify you via email.</p>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'CoSignerInReview',
}
</script>
