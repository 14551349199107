import { getField, updateField } from 'vuex-map-fields'
import { get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  cosignerValidation: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  validation: ({ cosignerValidation }) => cosignerValidation,
  isValid: ({ cosignerValidation }) => _get(cosignerValidation, 'valid'),
}

const actions = {
  fetchCosignerValidationById({ commit }, cosignerId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`cosigner/validation/${cosignerId}`)
      .then((response) => response.data)
      .then((cosignerValidation) => {
        commit('setError', null)
        commit('setCosignerValidation', cosignerValidation)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setCosignerValidation(state, cosignerValidation) {
    state.cosignerValidation = cosignerValidation
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
