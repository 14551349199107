import SubmitContainer from '@/routes/submit/SubmitContainer'
import SubmitPayment from '@/routes/submit/views/SubmitPayment'
import SubmitScreening from '@/routes/submit/views/SubmitScreening'
import SubmitFailed from '@/routes/submit/views/SubmitFailed'
import SubmitIntro from '@/routes/submit/views/SubmitIntro'
import InReview from '@/routes/review/views/InReview.vue'

const routes = {
  path: 'submit',
  component: SubmitContainer,
  props: {
    validationNamespace: 'cosignerValidation',
    fallbackRoute: 'cosignSteps',
  },
  children: [
    {
      path: '',
      name: 'cosigner-submit',
      component: SubmitIntro,
      props: {
        nextRoute: 'cosigner-screening',
        entityMarketCodeGetter: 'cosigner/cosignerMarketCountryCode',
        entityApplicationFeeHasBeenWaivedGetter: 'cosigner/applicationFeeIsWaived',
        entityUSAddressCheckGetter: 'cosigner/hasUSAddress',
      },
    },
    {
      path: 'screening',
      name: 'cosigner-screening',
      component: SubmitScreening,
      props: {
        nextRoute: 'cosigner-payment',
        entityMarketCodeGetter: 'cosigner/cosignerMarketCountryCode',
        entityBackgroundCheckGetter: 'cosigner/cosignerBackgroundCheck',
        entityUSAddressCheckGetter: 'cosigner/hasUSAddress',
      },
    },
    {
      path: 'payment',
      name: 'cosigner-payment',
      component: SubmitPayment,
    },
    {
      path: 'success',
      name: 'cosigner-submitSuccess',
      component: InReview,
    },
    {
      path: 'failed',
      name: 'cosigner-submitFailed',
      component: SubmitFailed,
    },
  ],
}

export default routes
