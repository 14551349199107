<template>
  <div class="bg-white">
    <v-container class="group-get-started" v-max-width="475">
      <v-layout column>
        <h1 :class="['header-lg', isMobile ? 'my-4' : 'mt-5 mb-4 font-black']">
          While everyone else is joining, let's get started on adding
          <i class="pr-1">your</i> information
        </h1>
        <p class="text-large mt-3 mb-4">Be sure to have this information on hand:</p>

        <v-layout class="mb-4">
          <div class="orb blue-10--bg br-full mr-3 blue-40--txt">
            <IdentificationIcon />
          </div>
          <v-flex column>
            <p class="font-bold">Photo identification</p>
            <p class="charcoal-40--txt">Driver's license, state ID, or passport</p>
          </v-flex>
        </v-layout>

        <v-layout class="mb-4">
          <div class="orb blue-10--bg br-full mr-3 blue-40--txt">
            <CurrencyIcon />
          </div>
          <v-flex column>
            <p class="font-bold">Proof of income</p>
            <p class="charcoal-40--txt">Pay stub, tax document, or bank statement</p>
          </v-flex>
        </v-layout>

        <v-layout class="mb-4" align-items-center>
          <div class="orb blue-10--bg br-full mr-3 blue-40--txt">
            <VerifiedIcon />
          </div>
          <div class="d-flex align-center">
            <p class="font-bold">Social security number</p>
          </div>
        </v-layout>
      </v-layout>

      <p class="charcoal-30--txt mb-5 mt-2">
        We'll use this information to run an industry-standard background and credit check.
      </p>

      <v-layout column justify-center class="pb-5">
        <blw-button v-max-width="250" color="charcoal" class="mx-auto" @click="goToApplication">
          Continue
        </blw-button>
      </v-layout>
    </v-container>

    <!-- Notification -->
    <v-snackbar multi-line :value="invitesSentToast" color="blue" :timeout="5000">
      <v-layout align-center justify-space-between>
        <p class="font-large font-bold">Invites sent! <span class="ml-1">👍</span></p>
        <v-btn flat icon color="white">
          <v-icon @click="invitesSentToast = false">close</v-icon>
        </v-btn>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BlwButton from '@/shared/components/BlwButton.vue'
import IdentificationIcon from '@/shared/components/icons/IdentificationIcon'
import CurrencyIcon from '@/shared/components/icons/CurrencyIcon'
import VerifiedIcon from '@/shared/components/icons/VerifiedIcon'

export default {
  name: 'GroupGetStarted',
  components: {
    BlwButton,
    IdentificationIcon,
    CurrencyIcon,
    VerifiedIcon,
  },
  data() {
    return { invitesSentToast: false }
  },
  mounted() {
    setTimeout(() => (this.invitesSentToast = true), 500)
  },
  computed: {
    ...mapGetters('applicationSource', ['applicationSource']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    goToApplication() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          category: 'group_applications',
          CTA: 'group_get_started_cta',
          text: 'Continue',
        },
      })

      this.$router.replace({
        name: 'apply',
        params: { applicationSource: this.applicationSource.slug },
      })
    },
  },
}
</script>

<style lang="scss">
.group-get-started {
  @include fullHeight();

  .orb {
    display: grid;
    place-content: center;
    width: 32px;
    height: 32px;

    svg {
      zoom: 0.8;
    }
  }
}
</style>
