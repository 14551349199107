import ProfileContainer from './ProfileContainer'
import ProfileEdit from './views/ProfileEdit'
import ProfileView from './views/ProfileView'
import ProfileInterests from './views/ProfileInterests'
import ProfilePreferences from './views/ProfilePreferences'
import ProfileComplete from './views/ProfileComplete'
import ProfileWaiting from './views/ProfileWaiting'
import ViewProfileContainer from './ViewProfileContainer'

import ProfileStart from './views/ProfileStart'

export default {
  path: 'profile',
  component: ProfileContainer,
  meta: {
    section: 'profile',
    requiresAuth: true,
    currentStep: 2,
  },
  children: [
    {
      path: '',
      name: 'profile',
      component: ProfileStart,
    },
    {
      path: 'preferences',
      name: 'profilePreferences',
      component: ProfilePreferences,
    },
    {
      path: 'interests',
      name: 'profileInterests',
      component: ProfileInterests,
    },
    {
      path: 'edit',
      name: 'profileEdit',
      component: ProfileEdit,
      props: {
        nextRoute: 'profileView',
      },
    },
    {
      path: 'view',
      component: ViewProfileContainer,
      children: [
        {
          path: ':userId',
          name: 'profileView',
          component: ProfileView,
          props: {
            editRoute: 'profileEdit',
          },
        },
      ],
    },
    {
      path: 'waiting',
      name: 'profileWaiting',
      component: ProfileWaiting,
    },
    {
      path: 'complete',
      name: 'profileComplete',
      component: ProfileComplete,
      meta: { currentStep: 3 },
    },
  ],
}
