<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.9987 1.33337C4.3187 1.33337 1.33203 4.32004 1.33203 8.00004C1.33203 11.68 4.3187 14.6667 7.9987 14.6667C11.6787 14.6667 14.6654 11.68 14.6654 8.00004C14.6654 4.32004 11.6787 1.33337 7.9987 1.33337ZM7.33203 4.66671V6.00004H8.66536V4.66671H7.33203ZM7.33203 7.33337V11.3334H8.66536V7.33337H7.33203ZM2.66536 8.00004C2.66536 10.94 5.0587 13.3334 7.9987 13.3334C10.9387 13.3334 13.332 10.94 13.332 8.00004C13.332 5.06004 10.9387 2.66671 7.9987 2.66671C5.0587 2.66671 2.66536 5.06004 2.66536 8.00004Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'InfoIcon' }
</script>
