<template>
  <div class="property-card-actions">
    <v-layout d-flex align-center pt-4 v-if="selectedPropertyRoom">
      <v-flex grow :class="['text-left w-100', { 'font-grey': !selectedPropertyRoom }]">
        <div
          :class="[
            'font-bold price-range-header',
            {
              'font-blue': !!selectedPropertyRoom && !selectedRoomPriceOutOfPreApprovedLimit,
            },
          ]"
        >
          {{ roomDetailHeader }}
        </div>
        <div
          :class="['price-range-text pt-1', { 'font-red': selectedRoomPriceOutOfPreApprovedLimit }]"
        >
          {{ propertyPriceRangeText }}
        </div>
      </v-flex>
      <v-flex shrink ml-3>
        <div
          v-ripple
          @click="flipCard"
          class="room-select-icon-wrapper d-flex justify-center align-center clickable"
        >
          <v-icon color="charcoal">edit</v-icon>
        </div>
      </v-flex>
    </v-layout>
    <v-layout pt-2 v-if="!selectedPropertyRoom">
      <v-flex xs12>
        <blw-button
          block
          class="mt-3"
          label="Select your room"
          color="primary"
          @click="selectRoomClickHandler"
        />
      </v-flex>
    </v-layout>
    <v-layout pt-2 v-else>
      <v-flex xs12>
        <blw-button
          block
          class="mt-3"
          :label="selectHomeButtonText"
          :loading="loading"
          :color="
            !selectedPropertyRoom || selectedRoomPriceOutOfPreApprovedLimit
              ? 'charcoal'
              : 'secondary'
          "
          @click="selectHomeButtonClickHandler"
          :disabled="confirmPropertySelectionDisabled"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default {
  name: 'PropertyCardActions',
  props: {
    loading: {
      type: Boolean,
    },
    confirmPropertySelection: {
      type: Function,
      required: true,
    },
    confirmPropertySelectionDisabled: {
      type: Boolean,
    },
    propertyDisplayState: {
      type: Object,
    },
    flipCard: {
      type: Function,
    },
    showRoomSelectionClickHandler: {
      type: Function,
      required: true,
    },
    availableRooms: {
      type: Array,
      required: true,
    },
    minPrice: {
      type: Number,
    },
    maxPrice: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('application', ['applicationPreApprovedMaxRent', 'applicationPreApprovedForAll']),
    selectHomeButtonText() {
      return this.selectedRoomPriceOutOfPreApprovedLimit ? 'Contact support' : 'Meet the roommates'
    },
    selectedPropertyRoom() {
      return this.availableRooms.find((room) => room.selected)
    },
    roomDetailHeader() {
      if (this.selectedPropertyRoom) {
        return this.selectedPropertyRoom.room_type
      } else {
        return 'No room selected'
      }
    },
    selectedRoomPriceOutOfPreApprovedLimit() {
      if (!this.propertyDisplayState.roomId) {
        return false
      }
      const preApprovedLimit = this.applicationPreApprovedMaxRent
      const approvedForAll = this.applicationPreApprovedForAll
      const selectedRoomMonthlyCost = _get(this.propertyDisplayState, 'monthlyRoomPrice', 0)
      return approvedForAll ? false : preApprovedLimit <= selectedRoomMonthlyCost
    },
    propertyPriceRangeText() {
      if (this.selectedRoomPriceOutOfPreApprovedLimit) {
        return `$${this.propertyDisplayState.monthlyRoomPrice} / Out of your approved rate`
      }
      return this.selectedPropertyRoom && this.propertyDisplayState
        ? `$${this.propertyDisplayState.monthlyRoomPrice} / ${this.propertyDisplayState.leaseLength}-month lease`
        : `$${this.minPrice} - $${this.maxPrice}`
    },
  },
  methods: {
    selectRoomClickHandler() {
      window.scrollTo({
        top: 285,
        behavior: 'smooth',
      })
      this.flipCard()
    },
    selectHomeButtonClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Property Card',
          color: 'Salmon',
          CTA: 'Select this home',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      if (this.selectedRoomPriceOutOfPreApprovedLimit)
        return window.Intercom && window.Intercom('show')

      this.confirmPropertySelection()
    },
  },
}
</script>

<style lang="scss">
.property-card-actions {
  .price-range-header {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .price-range-text {
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 300;
  }
}
</style>
