import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const LEASE_LENGTH_MULTIPLIER_IDENTIFIER = 'lease_length_multiplier'
const HOLDING_FEE_MULTIPLIER_IDENTIFIER = 'holding_fee_multiplier'
const SEASONAL_MULTIPLIER_IDENTIFIER = 'cold_zone_month_multiplier'

const BUNGALOW_SERVICE_FEE = 55
const SERVICE_FEE_EXEMPT_MARKETS = ['seattle', 'portland']
const CONSERVICE_SERVICE_FEE = 16.99
const UTILITIES_SETUP_FEE = 100

const getDefaultState = () => ({
  ...stateHelpers,
  constants: {
    count: 0,
    previous: '',
    next: '',
    results: [],
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  getField,
  constants: ({ constants: { results } }) => results,
  leaseLengthMultipliers: ({ constants: { results } }) =>
    _.get(
      results.find(({ identifier }) => identifier === LEASE_LENGTH_MULTIPLIER_IDENTIFIER),
      'default_value'
    ),
  holdingFeeMultipliers: ({ constants: { results } }) =>
    _.get(
      results.find(({ identifier }) => identifier === HOLDING_FEE_MULTIPLIER_IDENTIFIER),
      'default_value'
    ),
  seasonalMultiplier: ({ constants: { results } }) =>
    _.get(
      results.find(({ identifier }) => identifier === SEASONAL_MULTIPLIER_IDENTIFIER),
      'default_value'
    ),
  minimumLeaseLengthMultiplier: (state, getters) => {
    return getters.leaseLengthMultipliers
      ? Math.min(...Object.values(getters.leaseLengthMultipliers))
      : 1
  },
  bungalowServiceFee: () => BUNGALOW_SERVICE_FEE,
  conserviceServiceFee: () => CONSERVICE_SERVICE_FEE,
  utilitiesSetupFee: () => UTILITIES_SETUP_FEE,
  isMarketServiceFeeExempt: () => (market) => {
    return SERVICE_FEE_EXEMPT_MARKETS.includes(market)
  },
}

const actions = {
  async fetchConstants({ commit }, query) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.constants.list(query))
      commit('setConstants', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setConstants(state, payload) {
    state.constants = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
