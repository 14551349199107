<template>
  <loading-spinner full-page v-if="loading" />
  <cosign-before-you-begin v-else-if="showBeforeYouBegin" />
  <no-cosign-source v-else-if="noCosignSource" />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import LoadingSpinner from '@/shared/LoadingSpinner'
import NoCosignSource from './components/NoCosignSource'
import CosignBeforeYouBegin from './components/CosignBeforeYouBegin'

export default {
  name: 'CosignContainer',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    LoadingSpinner,
    NoCosignSource,
    CosignBeforeYouBegin,
  },
  async beforeMount() {
    this.loading = true
    const { source } = this.$route.query
    this.$trackEvent({
      action: 'Loading Cosigner Source',
      properties: {
        source,
      },
    })
    source && (await this.getCosignerSourceBySlug(source))
    this.$trackEvent({
      action: this.cosignerSource ? 'Cosigner Source Loaded' : 'No Cosigner Source Found',
      properties: {
        source,
        cosignerSource: this.cosignerSource,
        cosignerSourceError: this.cosignerSourceError,
      },
    })
    this.cosignerSource && (await this.fetchCosignerBySource(source))
    this.$trackEvent({
      action: this.cosigner ? 'Cosigner Document Loaded' : 'No Cosigner Document Found',
      properties: {
        source,
        cosigner: this.cosigner,
        cosignerSourceError: this.cosignerSourceError,
        cosignerError: this.cosignerError,
        cosignerSource: this.cosignerSource,
      },
    })
    this.loading = false
  },
  watch: {
    cosigner(val) {
      val &&
        this.$routerPush({
          name: 'cosignAboutApplication',
          params: { cosignerSource: val.source.slug },
        })
    },
  },
  computed: {
    ...mapGetters('cosigner', ['cosigner']),
    ...mapGetters('cosignerSource', ['cosignerSource']),
    ...mapGetters({
      cosignerError: 'cosigner/error',
      cosignerSourceError: 'cosignerSource/error',
    }),
    showBeforeYouBegin() {
      return this.cosignerSource && !this.cosigner
    },
    noCosignSource() {
      return !this.cosignerSource
    },
  },
  methods: {
    ...mapActions('cosigner', ['fetchCosignerBySource']),
    ...mapActions('cosignerSource', ['getCosignerSourceBySlug']),
  },
}
</script>
<style lang="sass"></style>
