import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import { delay } from '@/utils/helpers'
import { getTimeBetweenDates } from '@/utils/dates'

const getDefaultState = () => ({
  ...stateHelpers,
  meetings: {
    count: 0,
    previous: '',
    next: '',
    results: [],
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  meetings: (state) => state.meetings.results,
  getMeetingForProperty: (state) => (propertyId) => {
    const propertyMeeting = state.meetings.results.find(
      (meeting) => meeting.property.id === propertyId
    )
    if (propertyMeeting) {
      return {
        ...propertyMeeting,
        _timeRemaining: getTimeBetweenDates(new Date(), new Date(propertyMeeting.ends_at)),
      }
    }
    return propertyMeeting
  },
  openMeetingsCount: (state) =>
    state.meetings.results.filter(({ status, ends_at }) => {
      const { hasExpired } = getTimeBetweenDates(new Date(), new Date(ends_at))
      return status === 'open' && !hasExpired
    }).length,
}

const actions = {
  async fetchOpenMeetingsMock({ commit }) {
    commit('setLoading', true)
    await delay(1000)
    commit('setMeetings', {
      count: 1,
      previous: '',
      next: '',
      results: [
        {
          id: '265118104104562523',
          status: 'open',
          property: {
            id: '145',
          },
          ends_at: '2020-09-22',
        },
        {
          id: '265118104104562523',
          status: 'open',
          property: {
            id: '163',
          },
          ends_at: '2020-09-19',
        },
      ],
    })
    commit('setLoading', false)
  },
  // actual fetch meetings call
  // https://localhost:8888/api/v1/internal-docs/#meet-and-greet-meetings-list
  fetchOpenMeetings({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get('meet-and-greet/meetings/?all_meetings=true&is_applicant=true')
      .then(({ data }) => {
        commit('setMeetings', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  setMeetings: (state, payload) => (state.meetings = payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
