<template>
  <blw-form
    formName="income-source"
    header="Additional income"
    :onSubmit="onContinue"
    :error="error.message"
  >
    <v-layout wrap>
      <v-flex xs12>
        <blw-text-field
          :error-messages="apiFieldError('source_name')"
          :rules="[required, maxLength(50)]"
          label="Income source"
          hint="How do you earn this income?"
          v-model="income.source_name"
        />
      </v-flex>

      <v-flex xs4>
        <blw-select
          :error-messages="apiFieldError('currency')"
          label="Currency"
          :items="currencies"
          v-model="income.currency"
          :rules="[required]"
        />
      </v-flex>

      <v-flex xs8>
        <blw-text-field
          :error-messages="apiFieldError('monthly_salary')"
          :hint="annualIncome"
          :rules="[required]"
          label="Monthly income (before tax)"
          autocomplete="off"
          mask="$######"
          type="tel"
          v-model="income.monthly_salary"
        />
      </v-flex>

      <v-flex xs12 v-if="document">
        <blw-document
          :document="document"
          :documentDeleteSuccessHandler="documentDeleteSuccessHandler"
        />
      </v-flex>
      <v-flex mb-3>
        <p class="py-1 text-xs-center font-small">
          {{ documentHelpText }}
        </p>
        <blw-upload
          :hideUploadButton="hideUploadButton"
          :required="!document"
          v-model="fileToUpload"
        />
      </v-flex>
    </v-layout>
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { maxLength, required } from '@/utils/formValidations'
import { getAnnualIncome } from '@/utils/helpers'

export default {
  name: 'IncomeSources',
  props: {
    nextPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      income: {
        id: null,
        source_name: null,
        monthly_salary: null,
        document: null,
        currency: null,
      },
      fileToUpload: null,
      currencies: [
        { text: 'USD', value: 'usd' },
        { text: 'CAD', value: 'cad' },
      ],
    }
  },
  async beforeMount() {
    this.resetDocument()
    const income = this.getIncomeSourceById(this.$route.params.sourceId) || {}
    this.showLoader()
    income.document && (await this.fetchDocument(income.document))
    this.hideLoader()
    this.income = { ...income }
  },
  computed: {
    ...mapGetters('income', ['incomeId', 'getIncomeSourceById', 'apiFieldError']),
    ...mapGetters('documents', ['document']),
    ...mapGetters({
      uploadError: 'documents/error',
      incomeError: 'income/error',
    }),
    error() {
      return this.uploadError || this.incomeError
    },
    annualIncome() {
      return this.getAnnualIncome(this.income.monthly_salary)
    },
    documentHelpText() {
      return this.document
        ? 'Upload a new document if you want to replace the existing one'
        : 'Please provide a document to verify this income source'
    },
    hideUploadButton() {
      return !!this.document || !!this.fileToUpload
    },
  },
  methods: {
    getAnnualIncome,
    required,
    maxLength,
    ...mapActions('income', [
      'fetchIncome',
      'createAdditionalIncomeSource',
      'updateAdditionalIncomeSource',
    ]),
    ...mapActions('documents', ['fetchDocument', 'createDocument', 'resetDocument']),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    documentDeleteSuccessHandler() {
      this.fetchIncome(this.incomeId)
    },
    async onContinue() {
      this.showLoader()

      if (this.fileToUpload) {
        await this.createDocument({
          purpose: 'applicant_income',
          file: this.fileToUpload,
        })

        if (!this.uploadError) {
          this.income.document = this.document.id
        } else {
          this.$trackEvent({
            action: 'CTA Clicked',
            properties: {
              location: 'Center',
              color: 'White',
              text: 'Upload Supplementary Income',
              category: 'Employment Details',
              status: 'Fail',
            },
          })
        }
      }

      this.$route.params.sourceId
        ? await this.updateAdditionalIncomeSource(this.income)
        : await this.createAdditionalIncomeSource(this.income)

      if (!this.error) {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Add Supplementary Income',
            CTA: 'Continue',
            category: 'Employment Information',
            type: 'Button',
          },
        })

        this.$routerPush({ name: this.nextPath })
      }

      this.hideLoader()
    },
  },
}
</script>
