import IncomeEmployment from '@/routes/applicantIncome/views/IncomeEmployment'
import IncomeDocument from '@/routes/applicantIncome/views/IncomeDocument'
import IncomeSources from '@/routes/applicantIncome/views/IncomeSources'
import IncomeOverview from '@/routes/applicantIncome/views/IncomeOverview'
import CosignerIncomeContainer from './CosignerIncomeContainer'

const routes = {
  path: 'cosign-income',
  component: CosignerIncomeContainer,
  children: [
    {
      path: '',
      name: 'cosignIncome',
      component: IncomeOverview,
      props: {
        employmentDetailsRouteName: 'cosignIncomeEmployment',
        additionalIncomeRouteName: 'cosignIncomeSources',
        cosignersEnabled: false,
        nextRoute: 'cosignSteps',
      },
    },
    {
      path: 'employment',
      name: 'cosignIncomeEmployment',
      component: IncomeEmployment,
      props: { nextPath: 'cosignIncomeDocument' },
    },
    {
      path: 'documents',
      name: 'cosignIncomeDocument',
      component: IncomeDocument,
      props: { nextPath: 'cosignIncome' },
    },
    {
      path: 'additional-sources/:sourceId?',
      name: 'cosignIncomeSources',
      component: IncomeSources,
      props: { nextPath: 'cosignIncome' },
    },
  ],
}

export default routes
