import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  interests: [],
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  interests: (state) => state.interests,
  interestIds: (state) => {
    return state.interests.reduce((ids, interest) => {
      if (interest.selected) ids.push(interest.id)
      return ids
    }, [])
  },
  selected: (state) => {
    return state.interests.filter((interest) => interest.selected && interest)
  },
}

const actions = {
  fetchInterests({ commit }) {
    commit('setLoading', true)
    return this._vm.$http
      .get(`/profile/interests/`)
      .then(({ data }) => {
        commit('setInterests', data.results)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  toggleInterest({ commit, dispatch, getters }, interestId) {
    const interests = getters.interests
    const interestIndex = interests.findIndex(({ id }) => id === interestId)

    commit('toggleInterest', interestIndex)

    // update API...
    dispatch('updateInterests')
  },

  updateInterests({ commit, getters }) {
    // This is currently broken: https://localhost:8888/api/v1/docs/#profile-interests-put
    commit('setLoading', true)

    return this._vm.$http
      .put(`profile/interests`, { ids: getters.interestIds })
      .then(({ data }) => {
        commit('setInterests', data.results)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  setInterests: (state, payload) => (state.interests = payload),
  toggleInterest: (state, index) => {
    state.interests[index].selected = !state.interests[index].selected
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
