<template>
  <v-slider
    class="blw-slider blw-control"
    @input="onChange"
    :data-uid="uniqueId"
    v-bind="{ ...$props, ...$attrs }"
  />
</template>

<script>
export default {
  name: 'BlwSlider',
  props: {
    'track-color': {
      type: String,
      default: 'primary',
    },
    'always-dirty': {
      type: Boolean,
      default: true,
    },
    'thumb-label': {
      type: [String, Boolean],
      default: 'always',
    },
    'thumb-units': {
      type: String,
      default: '',
    },
  },
  computed: {
    uniqueId() {
      return `blw-slider__${this._uid}`
    },
  },
  methods: {
    addUnitsToSliderLabel(value) {
      if (!this.thumbLabel) return
      const { min, max } = this.$attrs

      let offset

      if (value == min) offset = 'padding-left: 3.75rem;'
      if (value == max) offset = 'padding-right: 3.75rem;'

      const domQuery = `[data-uid='${this.uniqueId}'] span`
      const newValue = `${value} ${this.thumbUnits}`
      const domNode = document.querySelector(domQuery)

      domNode.innerHTML = newValue
      domNode.setAttribute('style', offset)
    },
    onChange(value) {
      this.addUnitsToSliderLabel(value)
      this.$emit('input', value)
    },
  },
  mounted() {
    this.addUnitsToSliderLabel(this.$attrs.value)
  },
}
</script>

<style lang="scss">
.blw-slider {
  padding-top: 2rem;

  // Track Cursor
  .v-slider input {
    cursor: pointer;
  }

  // Custom Thumb Design
  .v-slider__thumb-container {
    @include grabHand();

    .v-slider__thumb {
      border: 2px solid;
      background-color: white !important;
      transform: translateY(-50%) scale(0.75) !important;
    }
    &:before {
      color: $blue-60;
    }
  }

  // Active/Focus State(s)
  .v-slider__thumb-container--is-active {
    .v-slider__thumb {
      border-color: $charcoal-60 !important;
    }
  }

  // Thumb Label
  .v-slider__thumb-label {
    background: transparent !important;
    position: relative;
    white-space: nowrap;
    color: $charcoal-60;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
    top: -2.5rem;

    span {
      @include animate(padding);
    }
  }

  // Messages (hints, errors)
  .v-messages {
    text-align: center;
    height: 1rem;

    &__message {
      width: 100%;
    }

    .message-transition-enter-to {
      width: 100%;
      transform: initial !important;
    }
  }
}
</style>
