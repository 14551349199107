<template>
  <div class="blw-svg" v-html="illustrationSVG" />
</template>

<script>
export default {
  name: 'BlwSvg',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    illustrationSVG() {
      return require(`!html-loader!@/assets/svg/${this.name}.svg`)
    },
  },
}
</script>
