<template>
  <loading-spinner full-page v-if="loading" />
  <main v-else class="before-you-begin grid-auto-rows">
    <blw-hero header="Before you begin" />

    <section class="white">
      <v-layout class="what-you-need py-5" column align-center justify-center>
        <p class="font-large mb-3 font-weight-bold">You'll need:</p>
        <ul>
          <li class="my-2 font-large">
            <v-icon class="inline pr-2" color="grey">check</v-icon>
            <span>Identification</span>
          </li>
          <li class="my-2 font-large">
            <v-icon class="inline pr-2" color="grey">check</v-icon>
            <span>Proof of Income</span>
          </li>
          <li class="my-2 font-large">
            <v-icon class="inline pr-2" color="grey">check</v-icon>
            <span>Residential History</span>
          </li>
        </ul>

        <v-layout align-baseline class="mt-5">
          <template v-if="!applicationFeeWaived">
            <p class="font-large accent-font">
              Application fee is ${{ applicationSource.application_fee }}
            </p>
            <span class="pl-1 text-uppercase accent-font font-small">
              {{ applicationSource.currency }}
            </span>
          </template>
          <template v-else>
            <p class="font-large accent-font">
              ${{ applicationSource.application_fee }} application fee waived!
            </p>
          </template>
        </v-layout>
      </v-layout>
    </section>

    <footer>
      <v-layout column align-center>
        <small class="text-xs-center font-grey pb-4">
          This application is unique to you <br />
          and cannot be shared with others.
        </small>
        <blw-button label="Continue" :loading="loading" @click="acceptSource" />
      </v-layout>

      <v-snackbar multi-line :value="error">
        Oops, something went wrong:
        <br />
        {{ error.message }}
        <br />
        Please contact Bungalow to request a new application
      </v-snackbar>
    </footer>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get as _get } from 'lodash'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'BeforeYouBegin',
  data() {
    return {
      loading: false,
    }
  },
  components: { LoadingSpinner },
  computed: {
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('application', ['application', 'error']),
    ...mapGetters('applicationSource', ['applicationSource', 'applicationFeeWaived']),
  },
  beforeMount() {
    // Convert the source into an application
    this.acceptSource()
  },
  methods: {
    ...mapActions('application', ['makeApplication']),
    ...mapActions('groupApplicationSource', [
      'addGroupMemberToGroupSource',
      'fetchGroupApplicationSource',
    ]),
    ...mapActions('user', ['fetchProfile']),
    async acceptSource() {
      this.loading = true

      await this.fetchProfile()

      // These actions trigger a redirect to `/apply` (application in store is watched in container)
      await this.makeApplication(this.$route.query.source)

      if (this.error) {
        this.$sentry.captureException(this.error)
        this.$trackEvent({
          action: 'Application creation failed',
          properties: {
            error: this.error.message,
          },
        })
      }

      !this.error &&
        this.$trackEvent({
          action: 'Application Opened',
          properties: {
            market: _get(this.application, 'market.display_name'),
            neighborhood: _get(this.application, 'property.address.neighborhood'),
            property: _get(this.application, 'property.id'),
            room: _get(this.application, 'room'),
            applicationType: 'Primary',
          },
        })

      this.loading = false
    },
  },
}
</script>
