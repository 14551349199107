import Vue from 'vue'
import Padlock from '@livebungalow/toolbox/padlock'

const ENV = process.env

const authConfig = {
  prefixUrl: ENV.VUE_APP_BACKEND_URL,
  callbackUrl: ENV.VUE_APP_OAUTH_URL,
  domain: ENV.VUE_APP_COOKIE_DOMAIN,
  tokenSeparator: ENV.VUE_APP_COOKIE_TOKEN_SEPARATOR,
  providers: {
    google: {
      clientId: ENV.VUE_APP_OAUTH_GOOGLE_CLIENT_ID,
    },
    facebook: {
      clientId: ENV.VUE_APP_OAUTH_FACEBOOK_CLIENT_ID,
    },
    linkedin: {
      clientId: ENV.VUE_APP_OAUTH_LINKEDIN_CLIENT_ID,
    },
    apple: {
      clientId: ENV.VUE_APP_OAUTH_APPLE_CLIENT_ID,
    },
  },
}

Vue.use(Padlock, authConfig)
