<template>
  <div class="mg-time-left">
    <span v-if="!meeting">
      <b class="mr-1">Chat has not started yet</b>
    </span>
    <span v-else-if="timeExpired">
      <b class="urgent mr-1">Chat expired</b>
    </span>
    <span v-else>
      Ends in <b :class="`${showTimeUrgency} mr-1`">{{ showTimeRemaining }}</b>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MeetGreetTimeLeft',
  computed: {
    ...mapGetters('meetGreet', ['meeting', 'timeLeft', 'timeExpired']),
    timeLeftCalculated() {
      return Object.keys(this.timeLeft).length
    },
    showTimeUrgency() {
      const { hours } = this.timeLeft

      if (hours < 12 && hours > 4) return 'warn'
      if (hours <= 0) return 'urgent'

      return 'safe'
    },
    showTimeRemaining() {
      const { hours, minutes, seconds } = this.timeLeft

      // Show hours left (if any)
      const hrsLeft = hours ? `${hours} hrs` : ''
      // Conditionally pluralize the word 'minutes'
      const minuteOrMinutes = minutes === 1 ? `${minutes} minute` : `${minutes} minutes`
      // Show 'mins' or 'minutes' depending on hours left (if any)
      const minsLeft = hours && minutes ? `${minutes} mins` : minutes ? minuteOrMinutes : ''
      // Show seconds left if that's all we have
      const secsLeft = !minutes && !hours ? `${seconds} seconds` : ''

      return `${hrsLeft} ${minsLeft} ${secsLeft}`
    },
  },
}
</script>

<style lang="scss">
.mg-time-left {
  display: flex;
  align-items: center;

  @include mobile {
    font-size: 14px;
  }

  .urgent {
    color: $red-80;
  }

  .warn {
    color: $salmon-40;
  }

  .safe {
    color: $green-80;
  }
}
</style>
