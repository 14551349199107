<template>
  <div class="grid-enabled sm-modal">
    <div class="modal-grid-lead">
      <blw-illustration class="mx-auto" type="full-color" name="house-chat" size="200" />
    </div>
    <span class="modal-grid-header header-1 text-xs-center">Introduce yourself!</span>
    <div class="modal-grid-content mt-3 mb-4 text-xs-center px-4">
      <div>
        Send a message to kick things off, and if you need inspiration, check out their profiles!
      </div>
      <v-divider class="mt-4" />
    </div>
    <div class="modal-grid-footer">
      <div class="text-xs-center">
        <span class="font-weight-bold">Ends in <span class="green-60--txt">24 hours</span></span>
      </div>
      <blw-button color="primary" class="mt-3" block @click="onContinueClickHandler">
        Let's go!
      </blw-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'MeetGreetStartChatting',
  computed: {
    ...mapFields('homeShopping', ['homeShopping.roomId', 'homeShopping.selectedProperty']),
  },
  methods: {
    ...mapMutations('homeShopping', {
      resetHomeShoppingState: 'resetState',
    }),
    onContinueClickHandler() {
      if (this.roomId && this.selectedProperty) {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            CTA: 'Confirm chat',
            text: `Let's go`,
            category: 'meet_greet',
            property_id: this.selectedProperty.id,
            bedroom_id: this.roomId,
          },
        })
        this.resetHomeShoppingState()
      }
      this.$root.$emit('closeModal')
    },
  },
}
</script>
