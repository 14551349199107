import Vue from 'vue'

import '@/plugins'

import App from './App'
import router from './router'
import store from './store'

Vue.config.productionTip = false

window.$getState = () => {
  const storeState = store.getters['getState']
  // eslint-disable-next-line
  console.log(storeState)
}

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
