import { find as _find, get as _get } from 'lodash'
import CountryCodes from '../data/countryRegionData'

const getFullCountryNameByCountryCode = ({ country_code }) => {
  const country = _find(CountryCodes, { value: country_code })
  return _get(country, 'text')
}

const getRegionNameByCountryAndRegionCode = ({ country_code, state_code }) => {
  const country = _find(CountryCodes, { value: country_code })
  const countryStates = _get(country, 'states')
  const requestedRegion =
    _find(
      countryStates,
      ({ value: stateCode }) => stateCode.toLowerCase() === state_code.toLowerCase()
    ) ||
    _find(
      countryStates,
      ({ text: stateLabel }) => stateLabel.toLowerCase() === state_code.toLowerCase()
    )
  return _get(requestedRegion, 'text')
}

const getAvailableStatesForCountry = (country_code) => {
  const country = _find(CountryCodes, { value: country_code })
  const countryStates = _get(country, 'states', [])
  return countryStates
}

export {
  CountryCodes,
  getFullCountryNameByCountryCode,
  getRegionNameByCountryAndRegionCode,
  getAvailableStatesForCountry,
}
