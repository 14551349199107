import ReviewRoutes from '@/routes/review'
import ProfileRoutes from '@/routes/profile'
import MeetGreetRoutes from '@/routes/meetGreet'
import HomeSelectionRoutes from '@/routes/homeSelection'
import HomeShoppingRoutes from '@/routes/homeShopping'
import ApplicationNotFound from '@/routes/applications/views/NoApplicationSource'
import PreMeetAndGreet from './views/PreMeetAndGreet.vue'
import Apply from '@/routes/apply'

import ApplicationContainer from './containers/ApplicationContainer'
import BasicRouterContainer from '@/shared/BasicRouterContainer'

const routes = {
  path: 'application',
  component: BasicRouterContainer,
  children: [
    {
      path: '',
      redirect: '/applications',
    },
    {
      path: ':applicationSource',
      component: ApplicationContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          redirect: {
            name: 'apply',
          },
        },
        {
          path: 'not-found',
          name: 'applicationNotFound',
          component: ApplicationNotFound,
          meta: {
            basicNav: true,
          },
        },
        {
          path: 'chatting',
          name: 'preMeetAndGreet',
          component: PreMeetAndGreet,
        },
        Apply,
        ReviewRoutes,
        ProfileRoutes,
        MeetGreetRoutes,
        HomeSelectionRoutes,
        HomeShoppingRoutes,
      ],
    },
  ],
}

export default routes
