<template>
  <loading-spinner v-if="loading" fullPage />
  <v-container v-else class="group-status">
    <v-layout column>
      <h1
        v-max-width="420"
        :class="['header-md text-xs-center mx-auto', isMobile ? 'my-4 px-4' : 'my-5 font-black']"
      >
        Only 2 steps left before we email your lease
      </h1>
    </v-layout>

    <div class="group-status__layout">
      <div :class="['br-12 charcoal-5--bg py-4 mb-4', isMobile ? 'px-3' : 'px-4']">
        <!-- Steps -->
        <v-layout
          align-center
          justify-space-between
          class="group-status__steps text-xs-center font-small"
        >
          <v-layout column align-center v-max-width="100">
            <div class="step-orb br-full charcoal-10--bg charcoal-20--border charcoal-50--txt">
              <FileIcon />
            </div>
            Everyone submits their info
          </v-layout>
          <v-layout column align-center v-max-width="100" class="charcoal-40--txt">
            <div
              class="step-orb bordered br-full charcoal-10--bg charcoal-20--border charcoal-30--txt"
            >
              <SearchIcon />
            </div>
            We approve your your info
          </v-layout>
          <v-layout column align-center v-max-width="100" class="charcoal-40--txt">
            <div
              class="step-orb bordered br-full charcoal-10--bg charcoal-20--border charcoal-30--txt"
            >
              <CheckmarkIcon />
            </div>
            We email your lease
          </v-layout>
        </v-layout>
        <p class="font-bold font-large charcoal-40--txt mt-4 mb-2">Your application status</p>
        <v-layout column>
          <!-- Group Member Cards -->
          <v-layout
            v-for="member in groupMembers"
            :key="member.email"
            align-center
            class="bg-white py-2 px-3 br-8 charcoal-10--border my-1"
          >
            <!-- Avatar -->
            <div
              :class="[
                'group-status__avatar text-uppercase br-full font-bold font-base mr-2',
                member.isSubmitted ? 'green-5--bg green-40--txt' : 'salmon-5--bg salmon-40--txt',
              ]"
            >
              <span v-if="!member.isSubmitted">
                {{ member.first_name[0] + member.last_name[0] }}
              </span>
              <CheckmarkIcon v-else />
            </div>

            <!-- Details -->
            <v-layout column pl-1 my-1>
              <p class="text-overflow-ellipsis font-bold">
                {{ member.first_name }} {{ member.last_name }}
                <span class="charcoal-30--txt font-xs" v-if="member.isActiveUser">&nbsp;(You)</span>
              </p>
              <p v-if="!member.isSubmitted" class="text-overflow-ellipsis">
                {{ member.email }}
              </p>
              <p class="text-overflow-ellipsis charcoal-40--txt">
                {{ member.isSubmitted ? 'Submitted' : 'Not submitted yet' }}
              </p>
            </v-layout>
            <v-btn
              v-if="!member.isSubmitted"
              class="group-status__remind"
              :disabled="!member.can_remind || disableReminderButtons"
              depressed
              small
              @click="sendReminder(member)"
            >
              Remind
            </v-btn>
          </v-layout>
        </v-layout>

        <!-- Lease Details -->
        <p class="font-bold font-large charcoal-40--txt mt-4 mb-2">Selected lease dates</p>
        <div class="bg-white pa-3 br-8 charcoal-10--border">
          <v-layout align-start>
            <v-layout column>
              <span class="pb-1 font-bold font-large">
                {{ leaseDetails.leaseLength }} month lease
              </span>
              <span class="pb-1 text-base charcoal-40--txt">
                {{ leaseDetails.moveInDate }} – {{ leaseDetails.leaseEndDate }}
              </span>
              <span class="font-large green-60--txt font-bold">
                {{ formatAsCurrency(monthlyCosts.baseRent) }}/mo
              </span>
            </v-layout>
            <div class="d-flex font-base charcoal-30--txt">
              <span v-if="!isMobile">Need to make changes?</span>
              <GroupTooltip
                title="Lease Details Adjustment"
                tooltip="These are the details of your lease. If you need to make any changes, just reach out to homes@bungalow.com"
              />
            </div>
          </v-layout>
        </div>
      </div>

      <!-- Pricing -->
      <div class="group-status__pricing">
        <table class="w-100">
          <!-- Monthly Charges -->
          <p class="lead charcoal-30--txt mb-2">Monthly Costs</p>
          <tr class="font-base charcoal-40--txt">
            <td>Home’s rent</td>
            <td class="text-xs-right">{{ formatAsCurrency(monthlyCosts.baseRent) }}/mo</td>
          </tr>
          <tr v-if="addBungalowServiceFee" class="font-base charcoal-40--txt">
            <td>
              <GroupTooltip
                label="Bungalow service fee"
                title="Bungalow service fee"
                tooltip="This fee goes toward our operating costs to support an improved resident
                      experience, including seamless digital payments, 24/7 resident support, and
                      maintenance scheduling and tracking."
              />
            </td>
            <td class="text-xs-right">{{ formatAsCurrency(monthlyCosts.bungalowFee) }}/mo</td>
          </tr>
          <tr class="font-base charcoal-40--txt">
            <td>
              <GroupTooltip
                label="Conservice fee"
                title="Conservice fee"
                tooltip="A service fee from our partner, Conservice, who consolidates monthly utilities cost for the home into one easy-to-pay charge that’s payable by our app."
              />
            </td>
            <td class="text-xs-right">{{ formatAsCurrency(monthlyCosts.conserviceFee) }}/mo</td>
          </tr>
          <tr>
            <td colspan="2">
              <hr class="my-2" />
            </td>
          </tr>
          <tr class="font-bold">
            <td>Monthly charges</td>
            <td class="text-xs-right">{{ formatAsCurrency(monthlyCharges) }} /mo</td>
          </tr>
          <div class="my-4" />

          <!-- Payment Due @ Signing -->
          <p class="lead charcoal-30--txt mb-2">Payment Due at signing</p>
          <tr class="font-base charcoal-40--txt">
            <td>
              <GroupTooltip
                label="1st month's rent"
                tooltip="1st month's rent covers your first month of rent expense."
              />
            </td>
            <td class="text-xs-right">{{ formatAsCurrency(paymentDue.firstMonths) }}</td>
          </tr>
          <tr class="font-base charcoal-40--txt">
            <td>
              <GroupTooltip
                label="Security deposit (1x month rent)"
                tooltip="Your security deposit is returned to you following your eventual move-out, net of any damages."
              />
            </td>
            <td class="text-xs-right">{{ formatAsCurrency(paymentDue.securityDeposit) }}</td>
          </tr>
          <tr class="font-base charcoal-40--txt">
            <td>Utilities set-up fee</td>
            <td class="text-xs-right">{{ formatAsCurrency(paymentDue.utilitiesFee) }}</td>
          </tr>
          <tr v-if="qualifiesForPromo && groupPromoDiscount" class="font-base charcoal-40--txt">
            <td>Promotion credit: {{ this.minLeaseLengthForPromo }}+ mo. lease ✨</td>
            <td class="text-xs-right green-60--txt">
              -{{ formatAsCurrency(paymentDue.promoDiscount) }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <hr class="my-2" />
            </td>
          </tr>
          <tr class="font-bold">
            <td>Total due at lease signing</td>
            <td class="text-xs-right">{{ formatAsCurrency(totalDueAtSigning) }}</td>
          </tr>
        </table>

        <div class="bg-white pa-3 br-8 charcoal-10--border my-5 mx-auto" v-max-width="320">
          <GroupContactSales horizontal tagline="Have questions? We've got you!" class="mx-auto" />
        </div>
      </div>
    </div>

    <!-- Notification -->
    <v-snackbar multi-line :value="reminderSentToast" color="blue" :timeout="5000">
      <v-layout align-center justify-space-between>
        <p class="font-large font-bold">
          Their invite has been re-sent <span class="ml-1">👍</span>
        </p>
        <v-btn flat icon color="white">
          <v-icon @click="reminderSentToast = false">close</v-icon>
        </v-btn>
      </v-layout>
    </v-snackbar>
  </v-container>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'
import { formatAsCurrency } from '@/utils/helpers'

import GroupContactSales from '@/routes/review/components/GroupContactSales'
import GroupTooltip from '@/routes/review/components/GroupTooltip'
import CheckmarkIcon from '@/shared/components/icons/CheckmarkIcon.vue'
import FileIcon from '@/shared/components/icons/FileIcon'
import SearchIcon from '@/shared/components/icons/SearchIcon'
import LoadingSpinner from '@/shared/LoadingSpinner.vue'

export default {
  name: 'GroupStatus',
  components: {
    FileIcon,
    SearchIcon,
    GroupTooltip,
    CheckmarkIcon,
    GroupContactSales,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: true,
      reminderSentToast: false,
      disableReminderButtons: false,
      minLeaseLengthForPromo: 6,
    }
  },
  beforeMount() {
    this.disableIntercom()

    // If every group member has submitted, show the review page instead:
    if (!this.groupMemberSubmissionsPending)
      return this.$router.push({ name: 'groupApplicationInReview' })

    this.fetchGroupPropertySelection()
  },
  mounted() {
    this.ensureApplicationIsComplete()
  },
  computed: {
    ...mapGetters('application', ['application', 'groupProperty']),
    ...mapGetters('applicationSource', ['applicationSource']),
    ...mapGetters('groupApplicationSource', [
      'loading',
      'groupApplicationSource',
      'incompleteGroupStatuses',
      'groupPropertySelection',
      'groupMemberSubmissionsPending',
    ]),
    ...mapGetters('constants', [
      'isMarketServiceFeeExempt',
      'bungalowServiceFee',
      'conserviceServiceFee',
      'utilitiesSetupFee',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    addBungalowServiceFee() {
      return !this.isMarketServiceFeeExempt(this.application.market.slug)
    },
    leaseDetails() {
      const { move_in_date, lease_end_date } = this.groupApplicationSource

      return {
        leaseLength: this.calculateLeaseLength(move_in_date, lease_end_date),
        moveInDate: format(move_in_date, 'MMM D, YYYY'),
        leaseEndDate: format(lease_end_date, 'MMM D, YYYY'),
      }
    },
    qualifiesForPromo() {
      return this.leaseDetails.leaseLength >= this.minLeaseLengthForPromo
    },
    groupMembers() {
      return (
        this.groupApplicationSource.members
          // Add a shortcut field for their submitted status
          .map((groupMember) => ({
            ...groupMember,
            isActiveUser: this.applicationSource.slug === groupMember.application_source_slug,
            isSubmitted: !this.incompleteGroupStatuses.includes(groupMember.application_status),
          }))
          // Sort the submitted members to the top
          .sort((memberA, memberB) => memberB.isSubmitted - memberA.isSubmitted)
      )
    },
    property() {
      return this.groupProperty.property
    },
    calculatedBaseRent() {
      // Use the calculated rent from Fieldstone if we have it:
      return this.groupPropertySelection && this.groupPropertySelection.resident_rent
        ? this.groupPropertySelection.resident_rent
        : this.property.full_property_price
    },
    calculatedPromoAmount() {
      return this.groupPropertySelection && this.groupPropertySelection.resident_rent
    },
    groupPromoDiscount() {
      const promoAmount =
        (this.groupPropertySelection && this.groupPropertySelection.promo_amount) || 0
      // NOTE: The promo is a discount, so the number should always be negative:
      return -promoAmount
    },
    // TODO: Replace all of this pricing detail /w API data:
    monthlyCosts() {
      return {
        baseRent: this.calculatedBaseRent,
        bungalowFee: this.addBungalowServiceFee ? this.bungalowServiceFee : 0,
        conserviceFee: this.conserviceServiceFee,
      }
    },
    monthlyCharges() {
      // Add up all of the monthly charges
      return Object.values(this.monthlyCosts).reduce((total, cost) => total + cost)
    },
    paymentDue() {
      return {
        firstMonths: this.calculatedBaseRent,
        securityDeposit: this.calculatedBaseRent,
        utilitiesFee: this.utilitiesSetupFee,
        promoDiscount: this.qualifiesForPromo ? this.groupPromoDiscount : 0,
      }
    },
    totalDueAtSigning() {
      // Add up all of the due payments
      return Object.values(this.paymentDue).reduce((total, cost) => total + cost)
    },
  },
  methods: {
    ...mapActions('groupApplicationSource', [
      'fetchGroupApplicationSource',
      'fetchGroupPropertySelection',
      'sendGroupMemberReminder',
    ]),
    formatAsCurrency,
    disableIntercom() {
      window.Intercom && window.Intercom('shutdown')
    },
    async sendReminder(groupMember) {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          category: 'group_applications',
          CTA: 'group_status_remind_cta',
          text: 'Remind',
        },
      })

      this.disableReminderButtons = true

      await this.sendGroupMemberReminder({
        group_source_slug: this.groupApplicationSource.slug,
        application_source_id: groupMember.application_source_id,
      })

      await this.fetchGroupApplicationSource(this.groupApplicationSource.slug)

      this.disableReminderButtons = false

      // Show toast notification:
      this.reminderSentToast = true
      setTimeout(() => (this.reminderSentToast = false), 5000)
    },
    calculateLeaseLength(moveInDate, leaseEndDate) {
      // NOTE: `date-fns/differenceInMonths` only counts FULL months, so the number can be wrong
      const dateFrom = new Date(moveInDate)
      const dateTo = new Date(leaseEndDate)
      // This calculation seems more reliable, somewhat temporary until we merge web <> apply
      const differenceInMonthsByMonth = dateTo.getMonth() - dateFrom.getMonth()
      const differenceInMonthsByYear = 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      const totalDifferenceInMonths = differenceInMonthsByMonth + differenceInMonthsByYear

      return totalDifferenceInMonths
    },
    ensureApplicationIsComplete() {
      // Don't show the status page if the user hasn't completed their application:
      if (this.incompleteGroupStatuses.includes(this.application.status)) {
        this.$router.replace({
          name: 'apply',
          params: { applicationSource: this.applicationSource.slug },
          query: this.$route.query,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.group-status {
  @include mobile {
    padding: 0;
  }

  &.container {
    max-width: 1000px !important;
  }

  &__layout {
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: 80px;

    @include tablet {
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__steps {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 3px;
      top: 26px;

      width: calc(100% - 60px);
      left: 30px;
      background: $charcoal-10;
    }

    .step-orb {
      display: grid;
      place-content: center;
      position: relative;
      width: 60px;
      height: 60px;
      border: 12px solid $charcoal-5;
      z-index: 1;

      svg {
        zoom: 0.8;
      }

      &.bordered {
        &::after {
          position: absolute;
          content: '';
          border-radius: 100%;
          width: 36px;
          height: 36px;
          border: 1px solid $charcoal-30;
        }
      }
    }
  }

  &__avatar {
    display: grid;
    place-content: center;
    width: 36px;
    height: 36px;
  }

  &__remind {
    font-size: 12px;
    border-radius: 20px;
    height: 30px;
    min-width: 72px;
  }

  &__pricing {
    @include mobile {
      padding: 0 28px;
    }

    td {
      padding: 4px 0;
    }
  }

  .text-overflow-ellipsis {
    max-width: 300px;

    @include mobile {
      max-width: 150px;
    }
  }
}
</style>
