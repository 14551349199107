<template>
  <div class="blw-control blw-date-picker">
    <div v-if="calendarOpen" class="date-picker-overlay" />
    <v-menu
      offset-y
      ref="calendar"
      :nudge-bottom="-20"
      min-width="290px"
      v-model="calendarOpen"
      :close-on-content-click="false"
      :return-value.sync="selectedDate"
    >
      <template v-slot:activator="{ on }">
        <blw-text-field
          v-on="on"
          :readonly="disableDateTyping"
          :label="label"
          v-model="textValue"
          :append-icon="!hideIcon ? 'event' : ''"
          @click="openCalendar"
          @click:append="toggleCalendar"
          @click:prepend-inner="toggleCalendar"
          @keyup.enter="validateInput"
          @blur="validateInput"
          v-bind="{ ...$attrs }"
          :rules="calendarRules()"
        >
        </blw-text-field>
      </template>
      <v-date-picker
        no-title
        ref="datePicker"
        class="blw-calendar py-1"
        color="primary"
        v-model="selectedDate"
        :max="max"
        :min="min"
        @input="onSubmit"
      />
    </v-menu>
  </div>
</template>

<script>
import { parse, subYears } from 'date-fns'
import { getFormattedDateFromStrDate } from '@/utils/dates'

export default {
  name: 'BlwCalendar',
  props: {
    disableDateTyping: {
      type: Boolean,
      default: true,
    },
    'header-color': {
      type: String,
      default: 'blue',
    },
    'no-title': {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    minDate: {
      type: String,
    },
    maxDate: {
      type: String,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    dateDisplayFormat: {
      type: String,
      default: 'MMMM DD, YYYY',
    },
    openOnEnter: {
      type: Boolean,
      default: true,
    },
    ageRestriction: {
      type: Number,
      default: 0,
    },
    birthdayMode: {
      type: Boolean,
      default: false,
    },
    inFuture: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendarOpen: false,
      selectedDate: this.$attrs.value,
      label: this.$attrs.label,
      textValue: this.formatDateForText(this.$attrs.value),
    }
  },
  watch: {
    calendarOpen(calendarIsOpen) {
      if (calendarIsOpen) {
        this.birthdayMode && setTimeout(() => (this.$refs.datePicker.activePicker = 'YEAR'))
      }

      if (!calendarIsOpen) {
        this.validateInput()
      }
    },
    textValue(text) {
      if (!text) {
        this.selectedDate = null
      }
    },
    selectedDate(date) {
      this.textValue = this.formatDateForText(date)
    },
  },
  computed: {
    min() {
      if (this.minDate) return this.minDate

      return this.inFuture ? this.currentDate : '1900-01-01'
    },
    max() {
      if (this.maxDate) return this.maxDate

      if (this.birthdayMode)
        return this.formatDateForCalendar(subYears(this.currentDate, this.ageRestriction))

      return this.formatDateForCalendar(this.currentDate)
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    inputIsValidDate() {
      return Date.parse(this.textValue) || false
    },
  },
  methods: {
    calendarRules() {
      const rules = this.$attrs.rules || []

      return [this.isValidDate, ...rules]
    },
    isValidDate(value) {
      if (!value) return true

      return Date.parse(value) ? true : 'Invalid Date Entered (try format: MM/DD/YYYY)'
    },
    openCalendar() {
      if (!this.openOnEnter) return
      this.calendarOpen = true
    },
    toggleCalendar() {
      this.calendarOpen = !this.calendarOpen
    },
    parseDateForCalendar(string) {
      return string ? parse(string) : false
    },
    formatDateForText(date) {
      if (!date) return

      const dateObj = new Date(date)
      if (isNaN(dateObj.getTime())) {
        return
      }

      return getFormattedDateFromStrDate(dateObj.toISOString(), this.dateDisplayFormat)
    },
    formatDateForCalendar(date) {
      let dateObj = new Date(date)
      if (isNaN(dateObj.getTime())) {
        dateObj = new Date()
      }

      return getFormattedDateFromStrDate(dateObj.toISOString(), 'YYYY-MM-DD')
    },
    validateInput(event = {}) {
      if (event.type == 'blur' && this.calendarOpen) return
      if (!this.textValue) return

      if (this.inputIsValidDate) {
        this.textValue = this.formatDateForText(this.inputIsValidDate)
        this.selectedDate = this.formatDateForCalendar(this.inputIsValidDate)
      }

      this.onSubmit()
    },
    onSubmit() {
      if (this.calendarOpen) this.$refs.calendar.save(this.selectedDate)
      this.$emit('input', this.selectedDate)
    },
  },
}
</script>

<style lang="scss">
.blw-date-picker {
  // Calendar Icon
  * {
    cursor: pointer !important;
  }
  .date-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 7;
  }
  .blw-text-field .v-icon {
    &:hover {
      color: $blue-60;
    }
    &:active {
      color: darken($blue-60, 20%);
    }
  }
}

.blw-calendar {
  // Current Date
  .v-btn--outline {
    border-width: 2px;
    .v-btn__content {
      font-weight: bold;
    }
  }

  // Selected Date
  .v-btn--active.primary {
    .v-btn__content {
      font-weight: bold;
    }
  }

  // Action Buttons
  .v-btn--small {
    .v-btn__content {
      font-weight: bold !important;
    }
  }

  .v-date-picker-table {
    min-height: 220px;
    height: max-content;

    .v-btn--floating .v-btn__content {
      padding-top: 1px;
    }
  }
}
</style>
