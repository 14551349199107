<template>
  <div class="list">
    <router-link
      v-for="(item, index) in itemsWithIcons"
      :class="['list-item', item.status, item.additional && 'additional-item']"
      :to="item.link || (item.linkName && { name: item.linkName }) || item.linkObj"
      :key="index"
    >
      <div
        class="list-item-wrapper container py-3"
        v-on="item.onClick ? { click: item.onClick } : {}"
      >
        <p
          class="list-item-header paragraph-1"
          :style="{ 'grid-row-end': !item.subHeader ? '2 span' : '' }"
        >
          {{ item.header }}
          <span v-if="item.tag" class="list-item-tag">{{ item.tag }}</span>
        </p>
        <p class="list-item-sub-header font-small" v-if="item.subHeader">
          {{ item.subHeader }}
        </p>
        <v-btn
          :outline="item.icon !== 'check'"
          icon
          absolute
          right
          :class="['mr-1 list-step-button', { complete: item.icon === 'check' }]"
          :to="item.link || (item.linkName && { name: item.linkName }) || item.linkObj"
          :color="item.icon === 'check' ? 'blue' : 'primary'"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </div>
    </router-link>
  </div>
</template>

<script>
const statusIcons = {
  complete: 'check',
}

export default {
  name: 'List',
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    itemsWithIcons() {
      return this.items.map(this.addStatusIcon)
    },
  },
  methods: {
    addStatusIcon: (item) => ({
      ...item,
      icon: (item.additional && 'add') || statusIcons[item.status] || 'arrow_forward',
    }),
  },
}
</script>

<style lang="scss">
.list {
  .list-step-button {
    transform: scale(0.7);
    transition: all 0.5s;
    @include mobile {
    }
    &.complete {
      i.v-icon {
        color: white !important;
      }
    }
  }
  .list-item {
    display: block;
    background-color: white;

    .list-item-wrapper {
      align-items: center;
      display: grid;
      position: relative;
      min-height: 6.5rem;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 50px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $spacer;
        right: $spacer;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      @include mobile {
        min-height: 5.5rem;
      }
    }

    &:first-child {
      .list-item-wrapper::before {
        border-top: 0;
      }
    }

    .list-item-tag {
      border: 1px solid $charcoal-40;
      border-radius: 2px;
      font-size: 12px;
      line-height: 1.25rem;
      padding: 0 0.5rem;
      margin-right: 1rem;
    }

    .list-item-header {
      grid-row-start: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .list-item-sub-header {
      grid-column-start: 1;
      color: $text-lighter;
    }

    .v-icon {
      grid-row: 1 / span 2;
      grid-column: 2 / 2;
      justify-content: center;
    }
  }

  .list-item.complete {
    p:first-of-type {
      color: $blue-60;
    }

    .v-icon {
      color: $blue-60 !important;
    }
  }

  .list-item.inactive {
    background-color: $charcoal-20;
    pointer-events: none;

    p:first-of-type {
      color: $charcoal-60;
    }

    .v-icon {
      color: $charcoal-40 !important;
    }
  }
}
</style>
