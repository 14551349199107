import { getField, updateField } from 'vuex-map-fields'
import { get as _get } from 'lodash'

import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  leasePreference: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  leasePreference: ({ leasePreference }) => leasePreference,
  leasePreferenceId: ({ leasePreference }) => _get(leasePreference, 'id'),
  leasePreferencePetFriendly: ({ leasePreference }) => _get(leasePreference, 'has_pets'),
  leasePreferenceMasterBedroom: ({ leasePreference }) => _get(leasePreference, 'sharing_room'),
}

const actions = {
  async fetchLeasePreferenceById({ commit }, leasePreferenceId) {
    commit('setLoading', true)
    commit('setError', false)

    return await this._vm.$http
      .get(`applications/lease-preference/${leasePreferenceId}/`)
      .then(({ data }) => {
        commit('setLeasePreference', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async updateLeasePreference({ commit, getters }) {
    const { leasePreference } = getters

    commit('setUpdating', true)
    commit('setError', false)

    return await this._vm.$http
      .put(`applications/lease-preference/${leasePreference.id}/`, leasePreference)
      .then(({ data }) => {
        commit('setLeasePreference', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setLeasePreference(state, leasePreference) {
    state.leasePreference = leasePreference
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
