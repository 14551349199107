export default {
  primary: '#241F21',
  secondary: '#FF6F4F',
  accent: '#4A6CED',

  salmon: {
    base: '#FF6F4F',
    lighten1: '#FDBAAB',
    lighten2: '#FFE7E2',
  },
  blue: {
    base: '#4A6CED',
    lighten1: '#A4B4F1',
    lighten2: '#F3F6FB',
  },
  cream: {
    base: '#F6F2ED',
  },
  charcoal: {
    base: '#24201F',
    lighten1: '#C8C7C7',
    lighten2: '#F4F4F4',
  },
  taupe: {
    base: '#D1B4A6',
    lighten1: '#F3ECE9',
  },
  grey: {
    base: '#6D6867',
  },
}
