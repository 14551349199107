const getDefaultState = () => ({
  showLoader: false,
})

const state = getDefaultState()

const getters = {
  loaderIsVisible: ({ showLoader }) => showLoader,
}

const actions = {
  showLoader({ commit }) {
    commit('setLoading', true)
  },
  hideLoader({ commit }) {
    commit('setLoading', false)
  },
}

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setLoading(state, showLoader) {
    state.showLoader = showLoader
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
