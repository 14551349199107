<template>
  <div id="confirm-address-modal">
    <v-layout column align-center class="text-xs-center">
      <blw-icon icon="checkmark" size="lg" />
      <h2 class="header-1 my-4">Thanks!</h2>
      <p v-max-width="250" class="mb-5">
        We’ve sent {{ cosignerFirstName }} a co-signer application via email.
      </p>
      <blw-button size="lg" color="charcoal" @click="onContinue" label="Continue" />
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'LeasingCosignerAddedModal',
  props: {
    cosignerFirstName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$trackEvent({
      action: 'Open Modal: You Nominated a Co-signer',
      properties: {
        location: 'Sent Co-signer Application Modal',
        CTA: 'Continue',
        event: 'Open Modal',
      },
    })
  },
  methods: {
    onContinue() {
      this.$trackEvent({
        action: 'CTA Clicked: You Nominated a Co-signer',
        properties: {
          location: 'Sent Co-signer Application Modal',
          CTA: 'Continue',
          event: 'CTA Clicked',
        },
      })
      this.$root.$emit('closeModal')
    },
  },
}
</script>
