<template>
  <main class="cosign-property-info main-grid grid-collapse-footer">
    <section>
      <v-container mt-5>
        <v-layout column text-xs-center justify-center pb-2>
          <h2 class="lead pb-3 pt-3 text-uppercase">You are co-signing for</h2>
          <h3 class="header-1">{{ applicantsFullName }}</h3>
        </v-layout>
      </v-container>
    </section>
    <footer class="mt-5">
      <v-layout justify-center>
        <blw-button label="Continue" @click="onContinue" />
      </v-layout>
    </footer>
  </main>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CosignAboutApplication',
  computed: {
    ...mapGetters('cosigner', [
      'cosignerSourceApplicantInfo',
      'cosignerSourceApplicantProperty',
      'cosignerSourceApplicantPropertyAddress',
    ]),
    applicantsFullName() {
      return `${this.cosignerSourceApplicantInfo.first_name} ${this.cosignerSourceApplicantInfo.last_name}`
    },
  },
  methods: {
    onContinue() {
      this.$routerPush({ name: 'cosignSteps' })
    },
  },
}
</script>
<style lang="scss" media="screen">
.cosign-property-info {
  img {
    width: 200px;
    height: 200px;
  }
}
</style>
