<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79499 15.8749L4.62499 11.7049L3.20499 13.1149L8.79499 18.7049L20.795 6.70492L19.385 5.29492L8.79499 15.8749Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'CheckmarkIcon' }
</script>
