<template>
  <div class="bg-white">
    <v-container id="apply-home">
      <v-layout column :class="isMobile ? 'text-xs-left pt-4' : 'text-xs-center align-center pt-5'">
        <h1 v-max-width="312" :class="['header-xl font-black', { 'font-black': !isMobile }]">
          Provide us with your information
        </h1>
        <p :class="['mt-3 font-subtitle', isMobile ? 'mb-1' : ' mb-4']">
          We'll use this information to run an industry - standard background and credit check. All
          of your information is kept strictly confidential. 🔒
        </p>
      </v-layout>
      <v-form ref="applyBaseFormRef" v-model="isValid">
        <div class="pt-3 pb-2 font-xl font-bold section-title">
          <PersonalBadge width="28" class="mr-2" />
          Personal Information
        </div>
        <p class="font-large charcoal-40--txt mb-3">
          Double-check this information to ensure it's accurate!
        </p>
        <PersonalInformation />
        <hr class="my-2" />

        <div class="pt-3 pb-2 font-xl font-bold section-title">
          <AddressBadge width="28" class="mr-2" />
          Current Address
        </div>
        <p class="font-large charcoal-40--txt mb-3">
          This is the address where you currently live.
        </p>
        <CurrentAddress />
        <hr class="my-2" />

        <!-- Argyle Flow -->
        <template v-if="incomeVerificationActive">
          <IncomeVerification
            :onAccountConnected="onAccountConnected"
            :onError="onIncomeVerifcationError"
            :requiredError="!byPassIncomeVerification && !incomeAccountConnected && submitAttempted"
            :toggleByPass="(v) => (byPassIncomeVerification = v)"
          />
        </template>

        <template v-else>
          <div class="pt-4 pb-2 font-xl font-bold charcoal-50--txt section-title">
            <IncomeBadge width="28" class="mr-2" />
            Income Verification
          </div>
          <p class="charcoal-40--txt mb-4">
            Please provide <span style="text-decoration: underline">your</span> personal monthly
            income. Do not include information about your co-signer if you happen to have one.
          </p>
          <IncomeInformation />
        </template>

        <hr class="my-2" />

        <!-- Screening -->
        <div class="pt-3 pb-2 font-xl font-bold section-title">
          <ScreeningBadge width="28" class="mr-2" />
          Screening
        </div>
        <p class="charcoal-40--txt mb-4 font-large">
          Bungalow performs credit and background screenings for every resident application.
        </p>

        <Screening />

        <hr class="my-2" />

        <div class="pt-3 pb-2 font-xl font-bold section-title">
          <AdditionalBadge width="28" class="mr-2" />
          Additional Information
        </div>
        <p class="charcoal-40--txt mb-4 font-large">
          Anything else we need to know about your liviing situation?
        </p>

        <AdditionalInfo />

        <v-layout class="mt-3" v-if="submissionError">
          <v-flex
            class="text-xs-center font-bold font-large red--txt salmon-5--bg px-3 py-2 salmon-20--border br-4"
          >
            <span class="mr-1">Error:</span>
            {{ defaultErrorMsg }}
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-flex xs12 class="text-xs-center">
            <span
              v-if="incomeVerificationMissing"
              :class="[
                'text-base salmon-80--txt',
                { invisible: !incomeVerificationMissing && submitAttempted },
              ]"
            >
              Please complete the income verification above!
            </span>
            <span
              v-else
              :class="[
                'text-base charcoal-40--txt',
                {
                  invisible: isValid,
                  'salmon-80--txt': !isValid && submitAttempted,
                },
              ]"
            >
              Please complete all required sections to continue
            </span>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3 pb-5">
          <v-flex md6 offset-md3 mb-5>
            <v-btn
              class="apply-submit-cta"
              @click="validateAndSubmit"
              :loading="loading"
              color="primary"
              block
            >
              Continue
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <!-- Income Error(s) -->
      <v-snackbar multi-line color="error" :value="incomeVerificationError">
        <div class="d-flex column">
          <p><b> Oops, something went wrong!</b></p>
          <p>We had an issue trying to connect, let just try manually</p>
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { differenceInMonths, differenceInDays } from 'date-fns'

import PersonalInformation from '../sections/PersonalInformation.vue'
import CurrentAddress from '../sections/CurrentAddress.vue'
import IncomeInformation from '../sections/IncomeInformation.vue'
import IncomeVerification from '../sections/IncomeVerification.vue'
import Screening from '../sections/Screening.vue'
import AdditionalInfo from '../sections/AdditionalInfo.vue'

import ScreeningBadge from '@/assets/badges/verified.svg'
import AddressBadge from '@/assets/badges/address.svg'
import PersonalBadge from '@/assets/badges/personal.svg'
import IncomeBadge from '@/assets/badges/income.svg'
import AdditionalBadge from '@/assets/badges/more.svg'

export default {
  name: 'ApplyHome',
  components: {
    PersonalInformation,
    CurrentAddress,
    IncomeInformation,
    Screening,
    AdditionalInfo,
    IncomeVerification,
    PersonalBadge,
    AddressBadge,
    ScreeningBadge,
    IncomeBadge,
    AdditionalBadge,
  },
  data() {
    return {
      loading: false,
      isValid: false,
      byPassIncomeVerification: false,
      submitAttempted: false,
      incomeAccountConnected: false,
      incomeVerificationError: false,
      incomeVerificationMissing: false,
      defaultErrorMsg: 'Oops, something went wrong, our team has been notified!',
    }
  },
  mounted() {
    this.$trackEvent({
      action: 'Page viewed',
      properties: {
        type: 'Application page',
        title: 'Your Bungalow application',
      },
    })
  },
  computed: {
    ...mapGetters('flags', ['featureFlags']),
    ...mapGetters('residence', ['residence']),
    ...mapGetters('applicant', ['backgroundCheck', 'hasUSAddress']),
    ...mapGetters('application', [
      'applicantInfoId',
      'applicationId',
      'application',
      'isGroupApplication',
      'applicationFeeIsWaived',
      'displayState',
    ]),
    ...mapGetters('submit', ['getSecureIdNumber', 'getSkipSsnEntry']),
    ...mapGetters({
      applicantError: 'applicant/error',
      residenceError: 'residence/error',
      incomeError: 'income/error',
      applicationError: 'application/error',
      submitError: 'submit/error',
      applicationFlagsError: 'applicationFlags/error',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    submissionError() {
      return (
        this.applicationFlagsError ||
        this.applicantError ||
        this.incomeError ||
        this.residenceError ||
        this.applicationError ||
        this.submitError
      )
    },
    submitText() {
      return this.applicationFeeIsWaived ? 'Submit Application' : 'Finalize and Pay'
    },
    incomeVerificationActive() {
      if (this.incomeVerificationError) return false

      // TEST: #124 Argyle Income Verification
      return this.featureFlags.includes('income_verification_active')
    },
  },
  methods: {
    ...mapActions('residence', ['createResidence', 'updateResidence']),
    ...mapActions('applicant', ['updateApplicant']),
    ...mapActions('income', ['updateIncome']),
    ...mapActions('application', ['fetchApplication', 'updateApplicationByKey']),
    ...mapActions('applicationFlags', ['syncApplicationFlags', 'fetchApplicationFlags']),
    ...mapActions('applicationValidation', ['fetchApplicationValidation']),
    ...mapActions('submit', ['submitApplication', 'submitBackgroundCheck']),
    ...mapActions('groupApplicationSource', ['fetchGroupApplicationSource']),
    onAccountConnected() {
      this.byPassIncomeVerification = false
      this.incomeAccountConnected = true
    },
    onIncomeVerifcationError(argyleError) {
      this.incomeVerificationError = true

      if (process.env.VUE_APP_TARGET !== 'production') return
      // Something went wrong, log to Sentry and fallback to default flow:
      this.$sentry.captureMessage('Argyle Income Verification Failed!', {
        extra: {
          applicationId: this.applicationId,
          error: argyleError,
        },
      })
    },
    validateAndSubmit() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Application page',
          CTA: this.submitText,
        },
      })

      // Validates all the form sections, proceed if we're good...
      if (this.$refs.applyBaseFormRef.validate()) {
        // Also check income since it's special:
        // the user can now choose to bypass the income verification to be done
        // at a later date manually
        if (
          this.incomeVerificationActive &&
          !this.byPassIncomeVerification &&
          !this.incomeAccountConnected
        ) {
          // You need to connect at least one source of income!
          this.incomeVerificationMissing = true
          return
        }

        this.$root.$emit('openModal', {
          modalComponent: 'BackgroundCheckConsent',
          modalSize: 'fullscreen',
          modalComponentProps: {
            onContinue: this.onContinue,
            submitText: this.submitText,
          },
        })
      } else {
        this.submitAttempted = true
      }
    },
    friendlyErrorMessage() {
      return this.defaultErrorMsg
    },
    async attemptBackgroundCheck() {
      const payload = {
        target_id: this.applicantInfoId,
        target_type: 'applicant_info',
      }
      if (this.hasUSAddress && this.getSecureIdNumber && !this.getSkipSsnEntry) {
        payload.sin_ssn = this.getSecureIdNumber
      }
      await this.submitBackgroundCheck(payload)
      // Record outcome of background check
      this.$trackEvent({
        action: `Applicant Background Check ${this.error ? 'Failure' : 'Success'}`,
        properties: this.error
          ? {
              error: this.error,
            }
          : {},
      })
    },
    async reFetchApplicationValidation() {
      await this.fetchApplicationValidation(this.applicationId)
    },
    async maybeTrackIncomeBypass() {
      this.$trackEvent({
        action: 'Income Verification ByPass',
        properties: {
          skipped_income_verification: this.byPassIncomeVerification,
        },
      })
      // save that the user has bypassed income verification into display state
      await this.updateApplicationByKey({
        field: 'display_state',
        value: {
          ...this.displayState,
          skipped_income_verification: this.byPassIncomeVerification,
        },
      })
    },
    /**
     * cascading submission, each step protects the next step from firing IF it has failed
     */
    async onContinue() {
      this.loading = true
      // chain of submission functions
      let submissionChain = [
        this.updateApplicant,
        this.syncApplicationFlags,
        this.fetchApplicationFlags,
        this.updateIncome,
        this.updateResidence,
        this.attemptBackgroundCheck,
        this.reFetchApplicationValidation,
      ]
      // If the application fee is waived, submit the application and go to the submit success route
      if (this.applicationFeeIsWaived) {
        submissionChain = [...submissionChain, this.submitApplication, this.submitSuccessHandler]
      } else {
        // if there's an outstanding fee last step is to direct them to the payment
        submissionChain = [...submissionChain, this.goToPaymentScreen]
      }
      submissionChain.push(this.maybeTrackIncomeBypass)
      // attempt to submit each section
      // if there is any error after any step we break the chain and show the errors
      for (let index = 0; index < submissionChain.length; index++) {
        const submissionFunction = submissionChain[index]
        await submissionFunction()
        if (this.submissionError) {
          this.$sentry.captureMessage('Application Submission Failure', {
            extra: {
              error: this.submissionError,
            },
          })
          this.$trackEvent({
            action: 'Applicant Application Submission Failure',
            properties: {
              error: this.submissionError,
            },
          })
          break
        }
      }
      this.loading = false
    },
    async goToPaymentScreen() {
      await this.$routerPush({
        name: 'payment',
      })
    },
    async submitSuccessHandler() {
      const market = _.get(this.application, 'market.display_name')
      const neighborhood = _.get(this.application, 'property.address.neighborhood')
      const property = _.get(this.application, 'property.id')
      const room = _.get(this.application, 'room')
      const moveIndate = _.get(this.application, 'move_in_date')
      const leaseEndDate = _.get(this.application, 'lease_end_date')

      const eventPayload = {
        fee: this.pendingFee,
        market,
        neighborhood,
        property,
        room,
        moveIndate,
        leaseLength: differenceInMonths(leaseEndDate, moveIndate),
        daysToMoveIn: differenceInDays(moveIndate, new Date()),
      }

      if (this.application.group_source) {
        this.$trackEvent({
          action: 'Homes Applicant Application Submitted',
          properties: eventPayload,
        })

        window?.gtag('event', 'Homes Applicant Application Submitted', eventPayload)
      } else {
        this.$trackEvent({
          action: 'Rooms Applicant Application Submitted',
          properties: eventPayload,
        })

        window?.gtag('event', 'Rooms Applicant Application Submitted', eventPayload)
      }

      // Group next step(s)
      if (this.isGroupApplication) {
        // Refetch group application data to ensure groups status and display state:
        await this.fetchGroupApplicationSource(this.isGroupApplication)
        // Fork to group UI flow
        return this.$router.replace({ name: 'applicationInReview' })
      }

      // Co-living next step(s)
      await this.$routerReplace({ name: 'submitSuccess' })
    },
  },
}
</script>

<style lang="scss">
#apply-home {
  .section-title {
    display: flex;
    align-items: center;
  }

  .apply-submit-cta {
    background-image: $gradient-sunrise;
    border-color: $salmon-60;
  }

  .v-form {
    .layout {
      margin: 0 -0.5rem;
      .flex {
        padding: 0 0.5rem;
      }
    }
  }
  .v-input__control {
    margin-bottom: 0.5rem;
  }

  .subheader-sm {
    color: $text-lightest;
  }

  .v-text-field .v-label {
    color: #24201fa1 !important;
  }

  .user-profile-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .layout .flex {
    padding-top: 0;
    padding-bottom: 0;
  }
  .reverse-checkbox .v-input__slot {
    background: white;
    flex-direction: row-reverse;
    .v-input--selection-controls__input {
      margin-left: 10px;
    }
  }
}
</style>
