<template>
  <div id="home-selection-start-meeting-modal" class="grid-enabled sm-modal">
    <div class="modal-grid-header header-1 text-xs-center">
      You’ll have 24 hours to<br />
      meet, chat, and decide
    </div>
    <div class="modal-grid-content mt-4 px-4">
      <v-layout class="flex-center">
        <v-flex xs4 class="flex-center">
          <v-avatar size="100">
            <img :src="userProfileImage || fallbackAvatar" @error="onErrorHandler" />
          </v-avatar>
        </v-flex>
        <v-flex xs4 offset-xs1>
          <v-layout row wrap>
            <v-flex
              :key="i"
              xs6
              v-for="(roommate, i) in roommateShortList"
              class="flex-center mb-1"
            >
              <v-avatar size="40" color="grey lighten-4">
                <span v-if="roommate._extraRoommateCount" class="font-small">
                  +{{ roommate._extraRoommateCount }}
                </span>
                <img
                  v-else
                  :src="roommate.profile_image_url || fallbackAvatar"
                  @error="onErrorHandler"
                  alt="avatar"
                />
              </v-avatar>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div class="mt-4">
        Bungalow is an inclusive, diverse, and anti-racist community — we ask that you, along with
        everyone else in our community:
      </div>
      <div class="mt-3">
        <div v-for="(ask, i) in communityAsks" :key="i" class="mt-2">
          <v-icon class="inline pr-2" color="green">check</v-icon>
          <b>
            {{ ask }}
          </b>
        </div>
      </div>
    </div>
    <div class="modal-grid-footer mt-4">
      <v-layout class="mb-3" v-if="saveRoomSelectionError || failedToLoadMeeting">
        <v-flex class="text-xs-center red--txt px-4">
          Error starting meeting, please contact support for assistance
        </v-flex>
      </v-layout>
      <v-layout justify-center row>
        <v-flex>
          <blw-button
            @click="startMeetingOnClickHandler"
            :loading="loading"
            class="pink-gradient"
            block
            label="I agree, start the 24-hour chat"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapActions } from 'vuex'

import { delay } from '@/utils/helpers'

export default {
  name: 'HomeSelectionStartMeetingModal',
  props: {
    initialMessages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    failedToLoadMeeting: false,
    fallbackAvatar: 'https://assets.bungalow.com/avatars/pineapple-avatar-4.png',
    communityAsks: [
      'Engage with an open mind',
      'Be kind to each other',
      'Treat everyone with respect',
    ],
  }),
  computed: {
    ...mapFields('homeShopping', [
      'homeShopping.roomId',
      'homeShopping.roomPrice',
      'homeShopping.roomAvailabilityDate',
      'homeShopping.roomDiscountedPrice',
      'homeShopping.moveInDate',
      'homeShopping.leaseDuration',
      'homeShopping.leaseEndDate',
      'homeShopping.selectedProperty',
    ]),
    ...mapGetters('user', ['userProfileImage']),
    ...mapGetters('meetings', ['getMeetingForProperty']),
    ...mapGetters('homeShopping', {
      saveRoomSelectionError: 'error',
    }),
    // returns first 6 roommates, if more than 6 returns 5 with the extra count
    roommateShortList() {
      const { roommates } = this.selectedProperty
      if (roommates.length > 4) {
        return [
          ...roommates.slice(0, 3),
          {
            _extraRoommateCount: roommates.length - 3,
          },
        ]
      }
      return roommates
    },
  },
  methods: {
    ...mapActions('homeShopping', ['saveRoomSelection']),
    ...mapActions('meetings', ['fetchOpenMeetings']),
    ...mapActions('meetGreet', ['sendInitialMessage']),
    ...mapActions('application', ['fetchApplicationBySource']),
    async startMeetingOnClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Start Chat',
          text: 'Start the 24 hour chat',
          category: 'meet_greet',
          property_id: this.selectedProperty.id,
          bedroom_id: this.roomId,
        },
      })
      this.loading = true
      this.failedToLoadMeeting = false
      await this.saveRoomSelection()
      if (this.saveRoomSelectionError) return (this.loading = false)
      await this.waitForAndOpenPropertyMeeting()
      if (!this.failedToLoadMeeting) this.$root.$emit('closeModal')
      this.loading = false
    },

    async waitForAndOpenPropertyMeeting(attempt = 1, maxTries = 8) {
      if (attempt >= maxTries) return (this.failedToLoadMeeting = true)
      await this.fetchOpenMeetings()
      const propertyMeeting = this.getMeetingForProperty(this.selectedProperty.id)
      if (propertyMeeting) {
        await this.fetchApplicationBySource(this.$route.params.applicationSource)
        // send all initial messages to server
        for (let index = 0; index < this.initialMessages.length; index++) {
          const message = this.initialMessages[index]
          await this.sendInitialMessage({
            channel_id: propertyMeeting.chat_channel.id,
            message,
          })
        }
        setTimeout(
          () =>
            this.$root.$emit('openModal', {
              modalComponent: 'MeetGreetStartChatting',
              modalSize: 'small',
            }),
          1000
        )
        return await this.$routerPush({
          name: 'meetGreetChat',
          query: {
            meetingId: propertyMeeting.id,
          },
        })
      }
      await delay(1000)
      await this.waitForAndOpenPropertyMeeting(attempt + 1)
    },

    onErrorHandler() {
      event.target.src = this.fallbackAvatar
    },
  },
}
</script>
<style lang="scss"></style>
