<template>
  <div>
    <v-layout column align-center class="text-xs-center">
      <img
        src="https://assets.bungalow.com/illustrations/spot/chat_with_roommates.png"
        height="100"
        class="mb-4"
      />
      <h1 class="header-xl font-black mb-3">Need Support?</h1>
      <p class="font-xl">
        We're here to help! Contact us to connect with one of our professional leasing agents.
      </p>
      <v-layout column class="font-bold font-xl blue--txt my-3">
        <a class="my-1 blue--txt" href="tel:+14159973615">
          <span class="pr-1">📲</span> (415) 997-3615
        </a>
        <a
          class="my-1 blue--txt"
          mailto="welcomehome@bugnalow.com?subject=Bungalow Application Question"
        >
          <span class="pr-1">✉️</span>
          welcomehome@bungalow.com
        </a>
      </v-layout>
      <p v-max-width="300" class="charcoal-40--txt">
        For email and voicemail we typically respond within 3 business hours.
      </p>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'SupportModal',
}
</script>
