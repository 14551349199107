import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  user: null,
  admin: false,
  avatarS3Bucket: 'https://assets.bungalow.com/avatars',
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  isAdmin: (state) => state.admin,
  isStaff: (state) => state.user && state.user.is_staff,
  isPersonaVerified: (state) => state.user && state.user.is_persona_verified,
  userProfile: (state) => state.user,
  userId: ({ user }) => (user && user.id) || null,
  userProfileImage: (state) => state.user.profile_image_url,
  randomAvatar: (state) => {
    const avatarIndex = Math.max(1, Math.round(Math.random() * 8))
    return `${state.avatarS3Bucket}/pineapple-avatar-${avatarIndex}.png`
  },
  blueAvatar: (state) => {
    return `${state.avatarS3Bucket}/pineapple-avatar-5.png`
  },
  salmonAvatar: (state) => {
    return `${state.avatarS3Bucket}/pineapple-avatar-4.png`
  },
}

const actions = {
  fetchProfile({ commit }) {
    commit('setError', false)

    return this._vm.$http
      .get('auth/me/')
      .then(({ data }) => {
        const { id, email, first_name, last_name, phonenumber } = data
        this._vm.$identify({
          userId: email,
          userProperties: {
            firstName: first_name,
            lastName: last_name,
            email,
            phoneNumber: phonenumber,
          },
        })
        this._vm.$sentry.setUser({
          id,
          email,
        })
        commit('setUser', data)
      })
      .catch((error) => commit('setError', error))
  },
  authenticate({ commit }, provider) {
    commit('setError', false)
    commit('setUpdating', true)

    return this._vm.$auth
      .authenticate(provider)
      .then((user) => {
        commit('setUser', user)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  logout({ commit }) {
    this._vm.$auth.logout()
    commit('setUser', null)
    commit('profile/setProfile', null, { root: true })

    // Reset the Segment user
    window.analytics && window.analytics.reset()
  },
  resetApplicationAndRedirectToLogin({ dispatch }, { vm }) {
    const { fullPath, query } = vm.$route

    dispatch('resetState', null, { root: true })
    localStorage.setItem('redirectRoute', fullPath)
    this._vm.$auth.logout()
    vm.$routerPush({ path: '/login', query }).catch(() => {})
  },
  enableAdmin({ commit }) {
    commit('setAdmin', true)
  },
  disableAdmin({ commit }) {
    commit('setAdmin', false)
  },
}

const mutations = {
  ...mutationHelpers,
  setUser: (state, payload) => (state.user = payload),
  setAdmin: (state, payload) => (state.admin = payload),
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
