<template>
  <blw-form
    formName="cosigner-identity"
    header="Can we see your ID?"
    text="Please upload a picture of your government issued ID<br>( Drivers license, state ID or passport work )."
    :onSubmit="onContinue"
    :updating="updating"
    :error="error"
  >
    <v-flex pb-4 v-if="document">
      <blw-document
        :document="document"
        :documentDeleteSuccessHandler="documentDeleteSuccessHandler"
      />
    </v-flex>
    <blw-upload
      :required="!document"
      :hideUploadButton="hideUploadButton"
      v-model="fileToUpload"
      showUploadButton="showUploadButton"
    />
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CosignerIdentity',
  data() {
    return {
      fileToUpload: null,
    }
  },
  async beforeMount() {
    const cosignerPhotoId = this.cosigner.photo_id

    this.resetDocument()
    this.showLoader()
    cosignerPhotoId && (await this.fetchDocument(cosignerPhotoId))
    this.hideLoader()
  },
  computed: {
    ...mapGetters({
      uploadError: 'documents/error',
      cosignerError: 'cosigner/error',

      updatingCosigner: 'cosigner/updating',
      loadingDocument: 'documents/loading',

      cosigner: 'cosigner/cosigner',
      cosignerId: 'cosigner/cosignerId',
      document: 'documents/document',
    }),
    error() {
      return this.uploadError || this.cosignerError
    },
    updating() {
      return this.updatingCosigner || this.loadingDocument
    },
    hideUploadButton() {
      return !!this.document || !!this.fileToUpload
    },
  },
  methods: {
    ...mapActions('cosigner', ['updateCosignerByKey', 'fetchCosignerById']),
    ...mapActions('documents', ['fetchDocument', 'createDocument', 'resetDocument']),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    documentDeleteSuccessHandler() {
      this.fetchCosignerById(this.cosignerId)
    },
    async onContinue() {
      this.showLoader()
      if (this.fileToUpload) {
        await this.createDocument({
          purpose: 'applicant_identity',
          file: this.fileToUpload,
        })
        !this.uploadError &&
          (await this.updateCosignerByKey({ field: 'photo_id', value: this.document.id }))
      }

      !this.error && this.$routerPush({ name: 'cosignSteps' })
      this.hideLoader()
    },
  },
}
</script>
