import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import pkg from '../../package.json'

const name = pkg.name
const version = pkg.version

// Init SDK
Sentry.init({
  release: `${name}@${version}`,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: process.env.VUE_APP_SENTRY_ENVIRONMENT === 'development',
    }),
  ],
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
})

// Vue Install
Vue.prototype.$sentry = Sentry

// Export errorLogger
export function httpErrorLogger(response) {
  // Extract Useful Error Message(s)
  let errorMessage = ''
  const apiErrorResponse = response.error || response
  const nonFieldErrors = apiErrorResponse.non_field_errors || []
  const fieldErrors = apiErrorResponse.field_errors || []

  // Format Fieldstone specific error messages...
  if (nonFieldErrors && nonFieldErrors.length) {
    nonFieldErrors.forEach((errorMessageText) => (errorMessage += errorMessageText))
  } else if (fieldErrors) {
    for (let [key, value] of Object.entries(fieldErrors)) {
      errorMessage += `${key.toUpperCase()}: ${value}`
    }
  } else {
    errorMessage = apiErrorResponse.message
  }

  // Log to Sentry
  Sentry.withScope((scope) => {
    scope.setExtra('data', apiErrorResponse ? apiErrorResponse : response)
    const fingerPrint = ['{{ default }}', apiErrorResponse.message, apiErrorResponse.type]
    errorMessage && fingerPrint.push(errorMessage)
    apiErrorResponse && scope.setFingerprint(fingerPrint)

    // Use the best/most relevant error we have
    Sentry.captureException(apiErrorResponse ? Error(errorMessage) : response)
  })
}
