import { getField, updateField } from 'vuex-map-fields'

import { find as _find, set as _set, omit as _omit } from 'lodash'

import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const generateDefaultFlagData = (applicationId, flag) => ({
  application: applicationId,
  flag,
  flag_data: {},
  expanded: false,
})

const getDefaultState = () => ({
  ...stateHelpers,

  vehicleFlag: null,
  smokerFlag: null,
  petFlag: null,
  roommateFlag: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  smokerFlag: ({ smokerFlag }) => smokerFlag,
  vehicleFlag: ({ vehicleFlag }) => vehicleFlag,
  petFlag: ({ petFlag }) => petFlag,
  roommateFlag: ({ roommateFlag }) => roommateFlag,
}

const actions = {
  fetchApplicationFlags({ commit, rootGetters }) {
    const applicationId = rootGetters['application/applicationId']

    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/flag/?application__id=${applicationId}`)
      .then(({ data }) => data.results)
      .then((applicationFlags) => {
        // First we try to find all 4 possible application flags from the applicationFlags response array
        const vehicleFlagData = _find(applicationFlags, { flag: 'vehicle' })
        const petFlagData = _find(applicationFlags, { flag: 'pet' })
        const roommateFlagData = _find(applicationFlags, { flag: 'roommate' })
        const smokerFlagData = _find(applicationFlags, { flag: 'smoker' })

        /**
         * We now detemrine what should be stored in state
         * IF a flag was found in the response we store this and set an expanded flag to true
         * IF the flag was not found in the reponse we create a placeholder flag using the generateDefaultFlagData function
         * the expanded flag controls if the flag is selected and the respective fields are visible
         */
        const vehicleFlag = vehicleFlagData
          ? _set(vehicleFlagData, 'expanded', true)
          : generateDefaultFlagData(applicationId, 'vehicle')

        const petFlag = petFlagData
          ? _set(petFlagData, 'expanded', true)
          : generateDefaultFlagData(applicationId, 'pet')

        const roommateFlag = roommateFlagData
          ? _set(roommateFlagData, 'expanded', true)
          : generateDefaultFlagData(applicationId, 'roommate')

        const smokerFlag = smokerFlagData
          ? _set(smokerFlagData, 'expanded', true)
          : generateDefaultFlagData(applicationId, 'smoker')

        commit('setFlag', { flag: 'vehicleFlag', flagData: vehicleFlag })
        commit('setFlag', { flag: 'petFlag', flagData: petFlag })
        commit('setFlag', { flag: 'roommateFlag', flagData: roommateFlag })
        commit('setFlag', { flag: 'smokerFlag', flagData: smokerFlag })
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  createApplicationFlag({ getters, commit }, flag) {
    const flagToUpdate = getters[flag]
    const payload = _omit(flagToUpdate, 'expanded')

    return this._vm.$http
      .post('applications/flag/', payload)
      .catch((error) => commit('setError', error))
  },

  updateApplicationFlag({ getters, commit }, flag) {
    const flagToUpdate = getters[flag]
    const payload = _omit(flagToUpdate, 'expanded')

    return this._vm.$http
      .put(`applications/flag/${flagToUpdate.id}/`, payload)
      .catch((error) => commit('setError', error))
  },

  deleteApplicationFlag({ getters, commit }, flag) {
    const flagToUpdate = getters[flag]

    return this._vm.$http
      .delete(`applications/flag/${flagToUpdate.id}/`)
      .catch((error) => commit('setError', error))
  },

  async syncApplicationFlags({ commit, getters, dispatch }) {
    const { vehicleFlag, petFlag, smokerFlag, roommateFlag } = getters

    commit('setUpdating', true)
    commit('setError', false)

    // Loop through all flags and determine which actions are required ( if any )
    await Promise.all(
      [vehicleFlag, petFlag, smokerFlag, roommateFlag].map((flagData) => {
        const stateFlagId = `${flagData.flag}Flag`

        /*
          The id of a flag is a key field because --IF-- the flag does have an 'id'
          field, it is from the api (meaning the actions are going to be changed)
          if a flag is expanded that means the user has selected its applicable,
          therefore expected to be filled out, fields are required.
        */
        const requiresUpdate = flagData.expanded && flagData.id
        const requiresCreation = flagData.expanded && !flagData.id
        const requiresDeletion = !flagData.expanded && flagData.id

        if (requiresUpdate) return dispatch('updateApplicationFlag', stateFlagId)
        if (requiresCreation) return dispatch('createApplicationFlag', stateFlagId)
        if (requiresDeletion) return dispatch('deleteApplicationFlag', stateFlagId)
      })
    )

    commit('setUpdating', false)
  },
  toggleApplicationFlag({ commit }, flag) {
    commit('toggleApplicationFlag', flag)
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setFlag(state, { flag, flagData }) {
    state[flag] = flagData
  },

  toggleApplicationFlag(state, flag) {
    const applicationFlag = state[flag]

    applicationFlag.expanded = !applicationFlag.expanded
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
