<template>
  <div class="blw-control blw-upload-btn">
    <vuetify-upload-button
      :key="file && file.lastModified"
      :fileChangedCallback="onFileChangedHandler"
      v-bind="{ ...$props, ...$attrs }"
      icon
      round
      outline
    >
      <template slot="icon">
        <v-icon class="shadow-tight" color="charcoal">edit</v-icon>
      </template>
    </vuetify-upload-button>
  </div>
</template>

<script>
import getFileSize from 'filesize'
import VuetifyUploadButton from 'vuetify-upload-button'

export default {
  name: 'BlwUploadButton',
  components: {
    VuetifyUploadButton,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    onFileChanged: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      key: null,
      file: null,
      fileError: false,
      errorMsg: 'Photo should be PNG or JPEG and under 2MB',
    }
  },
  methods: {
    getFileSize,
    fileRequired(val) {
      return val ? true : 'This document is required'
    },
    onFileChangedHandler(file) {
      const maxFileSizeInBytes = 2000000
      const acceptedFileTypes = ['image/jpeg', 'image/png']

      if (file && acceptedFileTypes.includes(file.type) && file.size <= maxFileSizeInBytes) {
        this.fileError = null
        this.file = file
        this.$emit('input', file)
      } else {
        this.fileError = this.errorMsg
      }
      const fileSize = this.getFileSize(file.size)
      // Fire callback
      this.onFileChanged({ file, fileSize, error: this.fileError })
    },
  },
}
</script>

<style lang="scss">
.blw-upload-btn {
  .v-btn,
  label.upload-btn {
    background-color: white;
    border: none;
    padding: 0px;
    &.v-btn--outline {
      background-color: white !important;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
    }
  }
}
</style>
