<template>
  <div class="mg-start mg-interstitial">
    <v-container>
      <v-layout column align-center justify-flex-end>
        <v-layout column align-center>
          <div class="lead mb-3">Meet the roommates</div>
          <h1 class="header-1 text-xs-center mb-5">Meet, chat, decide.</h1>
          <v-layout class="mb-5" justify-center>
            <blw-avatar v-if="meeting" class="mx-2" :avatars="meeting.residents" />
            <blw-illustration v-else class="mx-auto mb-4" type="spot" name="meet-roommates" />
          </v-layout>
          <p class="font-large text-xs-center mb-5" v-max-width="320">
            You and the roommates will have 24 hours to get to know each other and decide if this is
            the right home.
          </p>
        </v-layout>
        <meet-greet-time-left />
        <blw-button
          :disabled="!meeting"
          size="lg"
          color="charcoal"
          class="mt-2"
          :label="!meeting ? 'Refresh to check' : 'Continue'"
          @click="goToTerms"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MeetGreetTimeLeft from '../components/MeetGreetTimeLeft'

export default {
  name: 'MeetGreetTerms',
  components: { MeetGreetTimeLeft },
  computed: {
    ...mapGetters('meetGreet', ['meeting']),
    applicants() {
      const { primary_applicant, secondary_applicant } = this.meeting
      const applicants = [primary_applicant]
      if (secondary_applicant) applicants.push(secondary_applicant)

      return applicants
    },
  },
  methods: {
    goToTerms() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Meet & Greet - Start',
          color: 'Charcoal',
          text: 'Meet, chat, decide.',
          CTA: 'Continue',
          category: 'Meet & Greet',
          type: 'Button',
        },
      })

      this.$routerPush({ name: 'meetGreetTerms' })
    },
  },
  mounted() {
    if (window.Intercom) window.Intercom('shutdown')
  },
}
</script>
