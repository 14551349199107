<template>
  <div class="property-bedroom-selection bg-white">
    <div class="px-4 py-4">
      <v-layout mb-4>
        <v-flex>
          <div class="main-header accent-font text-xs-center font-bold pt-2">
            Choose your bedroom
          </div>
        </v-flex>
      </v-layout>
      <v-btn
        flat
        block
        v-ripple
        v-for="room in availableRooms"
        :key="room.id"
        class="room-selection-option clickable w-100 d-flex"
        @click="selectRoom(room)"
      >
        <div class="py-3">
          <v-layout align-center px-2>
            <v-flex grow>
              <div :class="{ 'blue--text': selectedRoomId === room.id }">
                <span class="mr-3">
                  {{ room.room_type }}
                </span>
                <v-btn
                  v-if="room.matterport_url"
                  @click="showMatterportPreview(room.matterport_url)"
                  class="view-room-matterport-btn"
                  icon
                >
                  <blw-icon icon="picture" />
                </v-btn>
              </div>
              <div class="room-item-subheader font-thin font-grey pt-2">
                Starting at ${{ roundedRoomPrice(room.discounted_price || room.price[0]) }}
              </div>
            </v-flex>
            <v-flex shrink>
              <div class="selected-room-icon">
                <div :class="['check round', { checked: selectedRoomId === room.id }]">
                  <v-icon color="white" size="20">check</v-icon>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout px-2>
            <div class="available-rooms px-2 mt-3">
              {{ formatAvailabilityDate(room.availability_date) }}
            </div>
          </v-layout>
        </div>
      </v-btn>
      <v-layout>
        <v-flex xs12>
          <blw-button
            block
            class="mt-3"
            label="Save"
            color="charcoal"
            @click="confirmSelectionHandler"
            :disabled="!selectedRoomId"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12>
          <blw-button
            class="mt-2"
            flat
            color="charcoal"
            darkText
            @click="onCancel"
            block
            label="Cancel"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { format, differenceInDays } from 'date-fns'
import { roundPriceToNearestFiveDollars } from '@livebungalow/toolbox/abacus'

export default {
  name: 'PropertyBedroomSelection',
  data() {
    return {
      selectedRoomId: this.roomId,
    }
  },
  props: {
    roomId: {
      type: String,
      default: null,
    },
    cancelRoomSelectionClickHandler: {
      type: Function,
      required: true,
    },
    availableRooms: {
      type: Array,
      required: true,
    },
    saveRoomSelectionHandler: {
      type: Function,
      required: true,
    },
  },
  methods: {
    showMatterportPreview(matterportUrl) {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Room List Item (back of flip card)',
          color: 'Flat',
          CTA: 'Preview Room Matterport',
          category: 'Home Selection',
          type: 'Icon',
        },
      })
      this.$root.$emit('showIframe', {
        srcUrl: `${matterportUrl}&play=1`,
      })
    },
    roundedRoomPrice(basePrice) {
      return roundPriceToNearestFiveDollars(basePrice)
    },
    selectRoom(room) {
      this.selectedRoomId = room.id

      this.$trackEvent({
        action: 'Room Selected',
        properties: {
          location: 'Room List (back of flip card)',
          text: 'Choose your bedroom',
          category: 'Home Selection',
          type: 'Radio',
        },
      })
    },
    roomAvailabilityDate() {},
    formatAvailabilityDate(availabilityDate) {
      const roomAvailabilityDate = new Date(availabilityDate)
      const roomAvailabilityIsInThePast = differenceInDays(roomAvailabilityDate, new Date()) < 1
      const dateToShow = !roomAvailabilityIsInThePast ? roomAvailabilityDate : new Date()

      return `Available ${format(dateToShow, 'MMMM Do')}`
    },
    onCancel() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Room List (back of flip card)',
          color: 'Flat',
          CTA: 'Cancel',
          text: 'Choose your bedroom',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.cancelRoomSelectionClickHandler()
    },
    confirmSelectionHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Room List (back of flip card)',
          color: 'Charcoal',
          CTA: 'Save',
          text: 'Choose your bedroom',
          category: 'Home Selection',
          type: 'Button',
        },
      })
      window.scrollTo({
        top: 285,
        behavior: 'smooth',
      })
      this.saveRoomSelectionHandler(this.selectedRoomId)
    },
  },
}
</script>

<style lang="scss">
.property-bedroom-selection {
  .view-room-matterport-btn {
    border: 1px solid black;
    border-radius: 50%;
    position: absolute;
    top: 17px;
    height: 30px;
    width: 30px;
    .blw-icon {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .room-itme-header {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .room-item-subheader {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }
  .main-header {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
  z-index: 2;
  width: 100%;
  min-height: 100%;
  opacity: 1;
  transition: opacity 0.5s;
  &.hide-selection {
    opacity: 0;
  }
}
button.room-selection-option {
  height: max-content;
  .v-btn__content {
    width: 100%;
    display: block;
    text-align: left;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid $charcoal-20;
  }
}
.selected-room-icon {
  .check {
    transition: all 0.25s ease;
    transition-delay: 0;
    min-width: 1.75rem;
    min-height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $charcoal-40;
    user-select: none;

    .v-icon {
      display: none;
    }

    &.checked {
      background: $blue-60;
      border-color: transparent;

      .v-icon {
        display: block;
      }
    }
  }
}
.availability-date-wrapper {
  border: 1px solid $charcoal-40;
  box-sizing: border-box;
  border-radius: 2px;

  font-size: 0.6875rem;
  line-height: 1.125rem;
  /* identical to box height, or 164% */
  text-align: center;

  color: $charcoal-60;
}
</style>
