<template>
  <div v-if="applications.length" class="grid-expanding-center pt-5">
    <blw-hero header="Applications" text="Select an application to begin" />
    <list :items="applicationList" />
  </div>
  <v-layout v-else column align-center justify-center class="full-height">
    <div>
      <blw-hero
        icon="error"
        header="No Applications Found"
        text="You do not have any open applications"
      />
      <p v-max-width="350" class="text-xs-center mx-auto">
        If you need help, contact the Bungalow team at
        <a class="cta-link" href="mailto:welcomehome@bungalow.com">welcomehome@bungalow.com</a> for
        assistance.
      </p>
      <footer>
        <v-layout column align-center justify-center>
          <blw-button size="lg" class="mt-4" href="https://bungalow.com">
            Visit Bungalow.com
            <v-icon right color="white">open_in_new</v-icon>
          </blw-button>
          <blw-button dark-text color="charcoal" flat size="lg" @click="openChat">
            Chat with the team
          </blw-button>
        </v-layout>
      </footer>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import List from '@/shared/List'
import ErrorIcon from '@/assets/icons/error_sm.svg'

export default {
  name: 'ApplicationsHome',
  components: { List },
  data() {
    return {
      ErrorIcon,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('applications', ['applications', 'statusText']),
    applicationList() {
      return this.applications.map(({ status, source, available_properties }) => {
        const friendlyStatus = this.statusText[status] || 'Pending'
        const helperText = available_properties.length
          ? ` - ${available_properties.length} Available Properties`
          : ''

        return {
          header: `Bungalow Application`,
          subHeader: `${friendlyStatus}${helperText}`,
          tag: this.isAdmin && source,
          linkObj: {
            name: 'apply',
            params: { applicationSource: source },
          },
        }
      })
    },
  },
  methods: {
    openChat() {
      window.Intercom && window.Intercom('show')
    },
  },
}
</script>
