<template>
  <div id="confirm-address-modal" class="grid-enabled">
    <span class="modal-grid-header header-1 text-xs-center">Confirm Address</span>
    <div class="modal-grid-content mt-2">
      <v-layout justify-center row pb-3 px-3 class="text-xs-center confirm-address-intro">
        There's a problem with the address provided.<br />
        Is this what you meant?
      </v-layout>
      <v-layout row wrap>
        <v-flex
          v-ripple
          v-for="(address, i) in addressesWithHelperFields"
          :key="i"
          @click="suggestedAddressSelected = address.suggested"
        >
          <div
            :class="[
              'address-box',
              {
                selected: address.suggested === suggestedAddressSelected,
              },
            ]"
          >
            <div class="address-details">
              <div class="lead">
                {{ address.title }}
              </div>
              <span v-for="addressField in addressFields" :key="addressField.key">
                <span
                  :class="{
                    'highlighted-suggested-field':
                      address.suggested && isHighlightedSuggestedAddressField(addressField.key),
                  }"
                >
                  {{ renderAddressFieldText(addressField, address) }}</span
                ><br v-if="addressField.break" />{{ addressField.addComma ? ', ' : '' }}
              </span>
            </div>
            <div class="selected-address-identifier">
              <div>
                <v-icon color="white">check</v-icon>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div class="modal-grid-footer mt-4">
      <v-layout justify-center row>
        <v-flex xs12 sm8>
          <blw-button @click="saveAddressClickHanlder" block label="Save address" />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { pick as _pick } from 'lodash'

export default {
  name: 'ConfirmAddressModal',
  props: {
    originalAddress: {
      type: Object,
      required: true,
    },
    suggestedAddress: {
      type: Object,
      required: true,
    },
    saveAddress: {
      type: Function,
      required: true,
    },
    addressFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      suggestedAddressSelected: true,
    }
  },
  mounted() {
    document.addEventListener('keydown', this.enterKeyListener)
  },
  destroyed() {
    document.removeEventListener('keydown', this.enterKeyListener)
  },
  computed: {
    addressesWithHelperFields() {
      return [
        { ...this.originalAddress, suggested: false, title: 'Original Address' },
        { ...this.suggestedAddress, suggested: true, title: 'Suggested Address' },
      ]
    },
  },
  methods: {
    enterKeyListener({ key }) {
      if (key === 'Enter') {
        this.saveAddressClickHanlder()
      }
    },
    isHighlightedSuggestedAddressField(key) {
      return this.originalAddress[key] !== this.suggestedAddress[key]
    },
    saveAddressClickHanlder() {
      this.$root.$emit('closeModal')
      this.$trackEvent({
        action: 'Save Address',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          CTA: 'Save Address',
          suggestedAddress: this.suggestedAddressSelected,
          category: 'Address Validation',
          type: 'Button',
        },
      })
      this.saveAddress(this.suggestedAddressSelected ? this.suggestedAddress : this.originalAddress)
    },
    renderAddressFieldText(addressField, address) {
      if (addressField.translateUtility) {
        const translateUtilityFields = _pick(address, addressField.translateUtility.fields)
        return addressField.translateUtility.method(translateUtilityFields)
      }
      return address[addressField.key]
    },
  },
}
</script>
<style lang="scss">
#confirm-address-modal {
  @include mobile {
    .confirm-address-intro {
      br {
        display: none;
      }
    }
    .modal-grid-content {
      padding: 0;
    }
  }
  .address-box {
    cursor: pointer;
    transition: all 0.5s;

    * {
      transition: all 0.5s;
    }

    display: flex;
    padding: 2rem;
    border: 1px solid #d6d6d6;
    margin: 5px;
    opacity: 0.5;

    &.selected {
      opacity: 1;
      box-shadow: 0px 2px 0px #000000, 0px 4px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid white;
      .selected-address-identifier > div {
        border-color: $blue-60;
        background: $blue-60;
      }
    }

    .address-details {
      flex: 1;
      .lead {
        padding-bottom: 0.75rem;
        width: max-content;
      }
    }

    .selected-address-identifier {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selected-address-identifier {
      padding-left: 1rem;
      > div {
        background: white;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid #d6d6d6;
      }
    }
  }
  .highlighted-suggested-field {
    color: $salmon-60;
  }
}
</style>
