<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height text-xs-center">
      <div class="d-flex column align-center justify-center mb-5">
        <blw-icon icon="error" size="lg" class="mb-5" />
        <h1 class="header-md mb-3">Your application has failed</h1>
        <p>Please go back and try submitting again.</p>
      </div>
      <blw-button class="mt-5" size="lg" label="Back to application" @click="backToApplication" />
    </v-layout>
    <v-snackbar :value="submissionError" :timeout="0">
      {{ submissionError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { get as _get } from 'lodash'

export default {
  name: 'SubmitFailed',
  computed: {
    ...mapGetters('submit', ['error']),
    submissionError() {
      const fieldErrors = _get(this.error, 'field_errors')
      return fieldErrors && this.error.field_errors.map((error) => error).join(', ')
    },
  },
  methods: {
    backToApplication() {
      this.$routerPush({ name: 'apply' })
    },
  },
}
</script>
