<template>
  <main>
    <section class="pt-4 mt-2">
      <blw-hero :header="header" :text="lead" />
      <list :items="sections" />
    </section>

    <footer>
      <v-layout justify-center row>
        <v-flex xs12 sm6 md3 d-flex justify-center>
          <blw-button
            :label="`Finalize${applicationFeeIsWaived ? ' free application' : ' and pay'}`"
            @click="onClick"
            :disabled="!isValid"
          />
        </v-flex>
      </v-layout>
    </footer>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get as _get, countBy as _countBy, set as _set } from 'lodash'
import { format } from 'date-fns'
import List from '@/shared/List'
import { pullObjectValues } from '@/utils/helpers'

export default {
  name: 'ApplicationSteps',
  props: {
    fetchValidationAction: {
      type: String,
      required: true,
    },
    updateEntityByKeyAction: {
      type: String,
      required: true,
    },
    validationNamespace: {
      type: String,
      required: true,
    },
    entityNamespace: {
      type: String,
      required: true,
    },
    sectionsData: {
      type: Array,
      required: true,
    },
    entityIdGetter: {
      type: String,
      required: true,
    },
    nextRoute: {
      type: String,
      required: true,
    },
  },
  components: { List },
  beforeMount() {
    this.fetchValidation(this.entityId)
  },
  mounted() {
    this.$trackEvent({
      action: 'Your Application',
      properties: {
        sections: this.remainingSections,
        timeStamp: format(Date.now()).toString(),
      },
    })
  },
  computed: {
    ...mapState({
      isValid(state, getters) {
        return getters[`${this.validationNamespace}/isValid`]
      },
      validation(state, getters) {
        return getters[`${this.validationNamespace}/validation`]
      },
      sectionStarted(state, getters) {
        return getters[`${this.entityNamespace}/sectionStarted`]
      },
      displayState(state, getters) {
        return getters[`${this.entityNamespace}/displayState`]
      },
      applicationFeeIsWaived(state, getters) {
        return getters[`${this.entityNamespace}/applicationFeeIsWaived`]
      },
      entityId(state, getters) {
        return getters[`${this.entityNamespace}/${this.entityIdGetter}`]
      },
    }),
    remainingSections() {
      const remainingSections = this.sections.filter((item) => item.status !== 'complete')
      return remainingSections && remainingSections.length
    },
    completedSections() {
      const completeSections = this.sections.filter((item) => item.status === 'complete')
      return completeSections && completeSections.length
    },
    multipleRemainingSections() {
      return this.remainingSections > 1
    },
    header() {
      if (!this.completedSections) {
        return "Let's start your application"
      }
      return this.isValid
        ? 'All sections complete'
        : `${this.completedSections} section${this.completedSections > 1 ? 's' : ''} complete`
    },
    lead() {
      return this.isValid
        ? 'Your application is ready to be submitted'
        : `You have ${this.remainingSections} section${
            this.multipleRemainingSections ? 's' : ''
          } left to complete.<br />We'll save your info as you go.`
    },
    sections() {
      return this.sectionsData.map(({ header, linkName, validationField }) => ({
        header,
        linkName,
        status: this.checkSectionStatus(validationField),
        subHeader: this.countRemainingFields(validationField),
        onClick: () => this.maybeSetSectionAsStarted(validationField, header),
      }))
    },
  },
  methods: {
    ...mapActions({
      fetchValidation(dispatch, payload) {
        return dispatch(`${this.validationNamespace}/${this.fetchValidationAction}`, payload)
      },
      updateEntityByKey(dispatch, payload) {
        return dispatch(`${this.entityNamespace}/${this.updateEntityByKeyAction}`, payload)
      },
    }),
    maybeSetSectionAsStarted(section, title) {
      const sectionHasAlreadyBeenStarted = this.sectionStarted(section)
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Center',
          color: 'White',
          text: title,
          CTA: 'Start',
          category: 'Home Page',
          type: 'Link',
        },
      })
      if (!sectionHasAlreadyBeenStarted) {
        const newDisplayState = _set(this.displayState || {}, `sectionStatus.${section}`, 'started')
        this.updateEntityByKey({ field: 'display_state', value: newDisplayState })
      }
    },
    checkSectionStatus(section) {
      const sectionStatus = _get(this.validation, `summary.${section}`)
      return sectionStatus === 'valid' ? 'complete' : 'pending'
    },
    countRemainingFields(section) {
      const sectionStarted = this.sectionStarted(section)
      if (!sectionStarted) return null
      const sectionValidation = _get(this.validation, `resources.${section}`)
      const validationValues = pullObjectValues(sectionValidation)
      const { true: remainingFields } = _countBy(
        validationValues,
        (fieldStatus) => fieldStatus === 'required'
      )
      return remainingFields
        ? `${remainingFields} field${remainingFields > 1 ? 's' : ''} remaining`
        : null
    },
    onClick() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Center',
          color: 'Salmon',
          text: 'Finalize and Pay',
          CTA: 'Start',
          category: 'Home Page',
          type: 'Button',
        },
      })
      this.$routerPush({ name: this.nextRoute })
    },
  },
}
</script>
