<template>
  <div id="home-selection-lease-length-modal" class="grid-enabled sm-modal">
    <span class="modal-grid-header header-1 text-xs-center">
      Select your lease length<br />
      and move-in date
    </span>
    <div class="modal-grid-content my-4">
      <div class="lease-details-container pb-4">
        <v-layout class="mt-3 text-xs-center">
          <v-flex xs12 class="monthly-price-display">
            <span class="header-md">${{ roomPrice }} </span>
            <span class="font-weight-regular">/mo</span>
          </v-flex>
        </v-layout>
        <v-layout pb-4 pt-1 justify-center align-center class="grey--text subheader-sm">
          excl. ${{ marketUtilityFee }}/mo Services Fee
        </v-layout>
        <pricing-slider
          :basePrice="roomPrice"
          :discountedPrice="roomDiscountedPrice"
          :availabilityDate="roomAvailabilityDate"
          :moveInDate="moveInDate"
          :leaseEndDate="leaseEndDate"
          :onChangeHandler="onSlidersChanged"
        />
      </div>
    </div>
    <div class="modal-grid-footer">
      <v-layout justify-center row>
        <v-flex>
          <blw-button
            @click="confirmLeaseDetailsClickHandler"
            :disabled="priceOutOfApprovedRange"
            :label="priceOutOfApprovedRange ? 'Out of approved price range' : 'Continue'"
            color="primary"
            block
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PricingSlider from '@/shared/PricingSlider'

// overrides for specific markets
const MARKET_UTILITY_FEES = {}
const DEFAULT_SERVICE_FEE = 99

export default {
  name: 'HomeSelectionLeaseLengthModal',
  components: {
    PricingSlider,
  },
  computed: {
    ...mapFields('homeShopping', [
      'homeShopping.roomPrice',
      'homeShopping.roomAvailabilityDate',
      'homeShopping.roomDiscountedPrice',
      'homeShopping.moveInDate',
      'homeShopping.leaseDuration',
      'homeShopping.leaseEndDate',
      'homeShopping.selectedProperty',
      'homeShopping.roomId',
    ]),
    ...mapGetters('application', [
      'applicationPreApprovedForAll',
      'applicationPreApprovedMaxRent',
      'applicationMarketSlug',
    ]),
    priceOutOfApprovedRange() {
      return (
        !this.applicationPreApprovedForAll && this.applicationPreApprovedMaxRent < this.roomPrice
      )
    },
    marketUtilityFee() {
      return MARKET_UTILITY_FEES[this.applicationMarketSlug] || DEFAULT_SERVICE_FEE
    },
  },
  methods: {
    onSlidersChanged({ moveInDate, roomPrice, leaseLength, leaseEndDate }) {
      this.moveInDate = moveInDate
      this.roomPrice = roomPrice
      this.leaseDuration = leaseLength
      this.leaseEndDate = leaseEndDate
    },
    confirmLeaseDetailsClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Continue',
          text: 'Select your lease length',
          category: 'Home Selection',
          property_id: this.selectedProperty.id,
          bedroom_id: this.roomId,
        },
      })
      this.$root.$emit('openModal', {
        modalComponent: 'HomeSelectionInitialMessageModal',
        modalSize: this.$vuetify.breakpoint.smAndDown ? 'fullscreen' : 'small',
      })
    },
  },
}
</script>
<style lang="scss">
#home-selection-lease-length-modal {
  .lease-details-container {
    border: 1px solid rgba(230, 227, 227, 0.5);
    border-radius: 8px;
    @include mobile {
      height: max-content;
    }
  }
  .pricing-slider {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
</style>
