import { valuesIn as _valuesIn, flattenDeep as _flattenDeep } from 'lodash'

const ADDRESS_VALIDATION_FIELDSTONE_TO_EKATA_FIELDS = [
  {
    fieldstoneKey: 'street_address',
    ekataKey: 'street_line_1',
  },
  {
    fieldstoneKey: 'city',
    ekataKey: 'city',
  },
  {
    fieldstoneKey: 'postal_code',
    ekataKey: 'postal_code',
  },
  {
    fieldstoneKey: 'region_code',
    ekataKey: 'state_code',
  },
  {
    fieldstoneKey: 'country_code',
    ekataKey: 'country_code',
  },
]

const EKATA_ADDRESS_FIELDS = ['street_line_1', 'city', 'postal_code', 'state_code', 'country_code']

const getAnnualIncome = (monthlyIncome) => {
  if (!monthlyIncome) return

  const annualIncome = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
  }).format(monthlyIncome * 12)

  return `${annualIncome} annually (approx.)`
}

const pullObjectValues = (objectToPullValuesFrom) => {
  const objectValuesArray = _valuesIn(objectToPullValuesFrom)

  const valuesWithPossibleNestedObjects = objectValuesArray.map((objectValue) => {
    if (typeof objectValue === 'object') {
      return pullObjectValues(objectValue)
    }

    return objectValue
  })

  // Pull all nested array values onto the top level of the array
  return _flattenDeep(valuesWithPossibleNestedObjects)
}

const delay = (delayTime) => new Promise((resolve) => setTimeout(resolve, delayTime))

const convertFieldstoneOrEtakaAddress = (addressToConvert, fieldstoneToEtaka = true) => {
  const convertedAddress = {}
  ADDRESS_VALIDATION_FIELDSTONE_TO_EKATA_FIELDS.forEach(({ ekataKey, fieldstoneKey }) => {
    convertedAddress[fieldstoneToEtaka ? ekataKey : fieldstoneKey] =
      addressToConvert[fieldstoneToEtaka ? fieldstoneKey : ekataKey]
  })
  return convertedAddress
}

const copyToClipboard = (content) => {
  // create temporary input to hold address
  const tempElement = document.createElement('textarea')
  // add the input to the dom
  document.body.append(tempElement)
  // set the value
  tempElement.value = content
  // select input text
  tempElement.select()
  // copy text to clipboard
  document.execCommand('copy')
  // remove element
  tempElement.remove()
}

const formatAsCurrency = (value, withDecimals = false) => {
  // Ensure value is provided, or at least 0
  if (!value && value !== 0 && value !== '$') return value

  // Strip out any non-numeric characters:
  value = String(value).replace(/[^.0-9]/g, '')

  // If that left nothing, return nothing...
  if (!value) return value

  // Attempt to remove any non-digit chars:
  value = parseFloat(value)

  // Return value formatted as currency:
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: withDecimals ? 2 : 0,
  }).format(value)
}

export {
  formatAsCurrency,
  getAnnualIncome,
  copyToClipboard,
  pullObjectValues,
  delay,
  convertFieldstoneOrEtakaAddress,
  EKATA_ADDRESS_FIELDS,
}
