<template>
  <div
    v-ripple="clickable"
    :class="['blw-avatar', avatarList.length && layout, clickable]"
    @click="onClickHandler"
  >
    <!-- List (...) -->
    <div v-if="asList" class="blw-avatar__list">
      <v-icon v-if="clickable" class="chevron" size="28">chevron_right</v-icon>
      <template v-for="avatar in reversedAvatars">
        <img :key="avatar.id" :src="avatar.profile_image_url || avatarFallback" :width="size" />
      </template>
    </div>
    <div v-else v-for="avatar in avatarList" :key="avatar.id" class="blw-avatar__wrap">
      <!-- Split (2) -->
      <div
        v-if="layout === 'split'"
        :style="{ backgroundImage: `url(${avatar.profile_image_url || avatarFallback})` }"
      />
      <!-- Circle (1) | Triangle (3) | Square (4) | Group (5+) -->
      <img
        v-else
        :src="avatar.profile_image_url || avatarFallback"
        :width="size"
        @error="useFallback"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlwAvatar',
  props: {
    avatar: {
      type: [Object, String],
    },
    avatars: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '120',
    },
    asList: {
      type: Boolean,
      default: false,
    },
    clickHandler: {
      type: [Function, Boolean],
      default: false,
    },
  },
  data() {
    return {
      layouts: {
        1: 'circle',
        2: 'split',
        3: 'triangle',
        4: 'square',
        5: 'group',
      },
      avatarFallback: 'https://assets.bungalow.com/avatars/pineapple-avatar-4.png',
      limitToShow: 3,
      fontScale: 5,
    }
  },
  computed: {
    clickable() {
      return this.clickHandler ? 'clickable' : ''
    },
    reversedAvatars() {
      return [...this.avatarList].reverse()
    },
    layout() {
      if (this.asList) return 'list'
      return this.layouts[this.avatarList.length] || 'group'
    },
    singleAvatar() {
      const avatar = this.avatarList[0] || {}
      return avatar.profile_image_url || this.avatarFallback
    },
    avatarList() {
      const avatarList = [].concat(this.avatars)

      if (this.avatar) {
        typeof this.avatar === 'string'
          ? avatarList.push({ profile_image_url: this.avatar })
          : avatarList.push(this.avatar)
      }

      return avatarList
    },
  },
  methods: {
    useFallback(event) {
      // Set new src on image:
      event.target.src = this.avatarFallback
    },
    onClickHandler() {
      if (typeof this.clickHandler === 'function') this.clickHandler(this.avatarList)
    },
    injectCSSVariables() {
      // Add componenty size:
      this.$el.style.setProperty('--size', `${this.size}px`)
      // How many avatars are there
      this.$el.style.setProperty('--avatars', `${this.avatarList.length}`)
      // Specify how many avatars are hidden:
      const hidden = this.avatarList.length - this.limitToShow
      this.$el.style.setProperty('--hidden', `'+${hidden}'`)
      // Set the dynamic font-size for the group option:
      const fontSize = (this.size / this.fontScale).toFixed(2)
      this.$el.style.setProperty('--font', `${fontSize}px`)
    },
  },
  mounted() {
    this.injectCSSVariables()
  },
}
</script>

<style lang="scss">
.blw-avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: var(--size);
  max-height: var(--size);
  user-select: none;

  &.clickable {
    cursor: pointer;
  }

  img {
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
    background-color: $charcoal-20;
    user-select: none;
  }

  .blw-avatar__list {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    img {
      width: var(--size);
      height: var(--size);
      margin-right: -0.65rem;
      border: 2px solid white;

      // Hide past 4th avatar
      &:nth-child(n + 6) {
        display: none;
      }
    }

    .chevron {
      color: $text-lighter;
      margin-left: 0.75rem;
    }

    &:hover {
      .chevron {
        color: $charcoal-60;
      }
    }
  }

  .blw-avatar__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }

  &.list {
    height: 100%;
    max-height: calc(var(--size) + 1rem);
    width: auto;
    max-width: initial;
    padding: 2px 4px;

    .blw-icon {
      margin-left: 0.5rem;
    }
  }

  &.circle {
    .v-image,
    img {
      width: var(--size);
      height: var(--size);
    }
  }

  &.split {
    flex-wrap: nowrap;
    overflow: hidden;
    border-radius: 100%;
    min-width: var(--size);
    height: var(--size);
    background: $charcoal-20;

    div {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    }
  }

  &.triangle {
    height: var(--size);

    .blw-avatar__wrap {
      padding: 0 1px;
      width: 50%;

      &:first-child {
        width: 100%;

        img {
          width: 50%;
          margin-bottom: -10%;
          height: calc(var(--size) / 2);
        }
      }
    }
  }

  &.square,
  &.group {
    justify-content: space-between;

    .blw-avatar__wrap {
      width: calc(50% - 2px);
    }
    img {
      background: white;
      margin: 2px 0;
    }
  }

  &.group {
    .blw-avatar__wrap {
      // Hide past the 4th roommate
      &:nth-child(n + 4) {
        display: none;
      }
    }

    // Add extra number for hidden roommates
    &::after {
      content: var(--hidden);
      font-size: var(--font);
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.5px;
      font-weight: bold;
      width: 50%;
      border-radius: 100%;
      border: 1px solid white;
      background: $charcoal-20;
    }
  }
}
</style>
