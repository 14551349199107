<template>
  <div id="initial-message-modal" class="grid-enabled sm-modal">
    <div class="modal-grid-header header-1 text-xs-center">Hi there!</div>
    <div class="modal-grid-content mt-4 px-4">
      <v-form ref="initialMessageForm">
        <v-layout class="flex-center">
          <v-flex xs4 class="flex-center">
            <v-avatar size="100">
              <img :src="userProfileImage || fallbackAvatar" @error="onErrorHandler" />
            </v-avatar>
          </v-flex>
          <v-flex xs4 offset-xs1>
            <v-layout row wrap>
              <v-flex
                :key="i"
                xs6
                v-for="(roommate, i) in roommateShortList"
                class="flex-center mb-1"
              >
                <v-avatar size="40" color="grey lighten-4">
                  <span v-if="roommate._extraRoommateCount" class="font-small">
                    +{{ roommate._extraRoommateCount }}
                  </span>
                  <img
                    v-else
                    :src="roommate.profile_image_url || fallbackAvatar"
                    @error="onErrorHandler"
                    alt="avatar"
                  />
                </v-avatar>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <div class="mt-4 text-center">
              We’re excited you’ve chosen our home. Tell us about yourself and why you want to live
              here.
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <blw-text-area
              v-model="introMessage"
              placeholder="Introduce yourself"
              :rules="[required]"
              :rows="4"
              required
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs12>
            <div class="text-center">
              What makes you a great roommate? What are your cleaning standards, schedule, and
              experience living with other people?
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <blw-text-area
              v-model="roommateSummary"
              placeholder="Type a message"
              :rules="[required]"
              :rows="4"
              required
            />
          </v-flex>
        </v-layout>
      </v-form>
    </div>
    <div :class="['modal-grid-footer mt-3', { 'pb-5': $vuetify.breakpoint.smAndDown }]">
      <v-layout justify-center row>
        <v-flex>
          <blw-button @click="onContinue" color="primary" block label="Continue" />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import { required } from '@/utils/formValidations'

const INITIAL_MESSAGE_LOCAL_STORAGE_KEY = 'home-shopping-initial-message'
const ROOMMATE_STYLE_LOCAL_STORAGE_KEY = 'home-shopping-roommate-style-message'

export default {
  name: 'HomeSelectionInitialMessageModal',
  data: () => ({
    fallbackAvatar: 'https://assets.bungalow.com/avatars/pineapple-avatar-4.png',
    introMessage: '',
    roommateSummary: localStorage.getItem(ROOMMATE_STYLE_LOCAL_STORAGE_KEY) || '',
  }),
  computed: {
    ...mapFields('homeShopping', ['homeShopping.selectedProperty']),
    ...mapGetters('user', ['userProfileImage']),
    // returns first 6 roommates, if more than 6 returns 5 with the extra count
    roommateShortList() {
      const { roommates } = this.selectedProperty
      if (roommates.length > 4) {
        return [
          ...roommates.slice(0, 3),
          {
            _extraRoommateCount: roommates.length - 3,
          },
        ]
      }
      return roommates
    },
  },
  methods: {
    required,
    onErrorHandler() {
      event.target.src = this.fallbackAvatar
    },
    async onContinue() {
      if (this.$refs.initialMessageForm.validate()) {
        localStorage.setItem(INITIAL_MESSAGE_LOCAL_STORAGE_KEY, this.initialMessage)
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            CTA: 'Continue',
            text: 'Confirm initial message',
            category: 'meet_greet',
            property_id: this.selectedProperty.id,
          },
        })
        const initialMessages = [
          this.introMessage,
          `Q. What makes you a great roommate?\n\nA. ${this.roommateSummary}`,
        ]
        this.$root.$emit('openModal', {
          modalComponent: 'HomeSelectionStartMeetingModal',
          modalComponentProps: {
            initialMessages,
          },
          modalSize: this.$vuetify.breakpoint.smAndDown ? 'fullscreen' : 'small',
        })
      }
    },
  },
}
</script>
<style lang="scss">
#initial-message-modal {
  .v-input__slot {
    background: $cream;
  }
  textarea {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
