<template>
  <v-layout row wrap>
    <v-flex xs12>
      <blw-text-field
        label="Cute! What kind of pet(s) do you have?"
        v-model="animal"
        :rules="petFlag.expanded ? [required, maxLength(50)] : []"
        :box="box"
      />
    </v-flex>
    <v-flex xs12 mb-2>
      <blw-text-field
        label="What is your pet’s name?"
        v-model="animal_name"
        :rules="petFlag.expanded ? [required, maxLength(50)] : []"
        :box="box"
      />
    </v-flex>
    <v-flex align-center justify-center>
      <v-checkbox
        label="One or more of my pets is an Emotional Support Animal or Service Animal"
        v-model="includes_service_animal"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

import { required, maxLength } from '@/utils/formValidations'

export default {
  name: 'PropertyAnimal',
  props: {
    box: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapFields('applicationFlags', [
      'petFlag.flag_data.animal',
      'petFlag.flag_data.animal_name',
      'petFlag.flag_data.includes_service_animal',
    ]),
    ...mapGetters('applicationFlags', ['petFlag']),
  },
  methods: {
    required,
    maxLength,
  },
}
</script>
