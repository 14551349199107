<template>
  <blw-form
    :onSubmit="confirmAddressAndContinue"
    :updating="updating"
    :error="error.message"
    header="Your current address"
    text="If you currently own your home or live with family, please include that address here."
    formName="cosigner-address"
  >
    <v-layout row wrap>
      <v-flex md8>
        <blw-text-field
          :label="street_address ? 'Street address' : null"
          :placeholder="street_address ? null : 'Street address'"
          :rules="[maxLength(50), required]"
          id="street-address-input"
          v-model="street_address"
          :error-messages="apiFieldError('street_address')"
        />
      </v-flex>
      <v-flex md4>
        <blw-text-field
          :rules="[maxLength(50), required]"
          :error-messages="apiFieldError('city')"
          label="City"
          v-model="city"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md4>
        <blw-select
          v-model="country_code"
          :error-messages="apiFieldError('country_code')"
          label="Country"
          :rules="[required]"
          :items="CountryCodes"
        />
      </v-flex>

      <v-flex xs12 md4>
        <blw-select
          :rules="[required]"
          v-model="region_code"
          :label="country_code === 'US' ? 'State' : 'Province'"
          :items="selectedCountryStates"
        />
      </v-flex>

      <v-flex xs12 md4>
        <blw-text-field
          :rules="[maxLength(100), required]"
          :error-messages="apiFieldError('zip')"
          :label="country_code === 'US' ? 'Zip code' : 'Postal code'"
          v-model="postal_code"
        />
      </v-flex>
    </v-layout>
  </blw-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { find as _find } from 'lodash'

import { emailField, maxLength, required } from '@/utils/formValidations'
import { CountryCodes, getAvailableStatesForCountry } from '@/utils/regionData'
import { GoogleAddressAutoCompleteMixin } from '@/mixins'

export default {
  name: 'CosignAboutYourself',
  beforeMount() {
    // default country code to us
    if (!this.country_code || !this.country) {
      this.country_code = 'US'
      this.country = 'United States'
    }
    if (!this.region || !this.region_code) {
      this.region_code = ''
      this.region = ''
    }
  },
  mixins: [GoogleAddressAutoCompleteMixin],
  mounted() {
    this.enableGoogleAutoComplete('#street-address-input input')
  },
  data() {
    return {
      CountryCodes,
    }
  },
  computed: {
    ...mapFields('cosigner', [
      'cosigner.address',
      'cosigner.address.street_address',
      'cosigner.address.city',
      'cosigner.address.postal_code',
      'cosigner.address.region',
      'cosigner.address.region_code',
      'cosigner.address.country',
      'cosigner.address.country_code',
    ]),
    ...mapGetters('cosigner', ['updating', 'error', 'apiFieldError']),
    selectedCountryStates() {
      return this.country_code ? getAvailableStatesForCountry(this.country_code) : []
    },
  },
  watch: {
    country_code(value) {
      if (!value) return
      const selectedCountry = _find(this.CountryCodes, { value })
      this.country = selectedCountry.text
      if (this.region || this.region_code) {
        this.region_code = ''
        this.region = ''
      }
    },
    region_code(value) {
      if (!value) return
      const selectedRegion =
        _find(
          this.selectedCountryStates,
          ({ value: stateCode }) => stateCode.toLowerCase() === value.toLowerCase()
        ) ||
        _find(
          this.selectedCountryStates,
          ({ text: stateLabel }) => stateLabel.toLowerCase() === value.toLowerCase()
        )
      if (selectedRegion) {
        this.region = selectedRegion.text
        this.region_code = selectedRegion.value
      } else {
        this.region = ''
        this.region_code = ''
      }
    },
  },
  methods: {
    required,
    emailField,
    maxLength,
    ...mapActions('cosigner', ['updateCosigner']),
    confirmAddressAndContinue() {
      this.$root.$emit('openModal', {
        modalComponent: 'ConfirmAddressErrorModal',
        modalSize: 'small',
        modalComponentProps: {
          originalAddress: this.address,
          saveAddress: this.onContinue,
          addressFields: [
            {
              key: 'street_address',
              break: true,
            },
            {
              key: 'city',
            },
            {
              key: 'region_code',
              addComma: true,
            },
            {
              key: 'postal_code',
            },
            {
              key: 'country_code',
            },
          ],
        },
      })
    },
    async onContinue() {
      await this.updateCosigner()
      !this.error && this.$routerPush({ name: 'cosignIdentity' })
    },
  },
}
</script>
