<template>
  <blw-form
    :iconComponent="PersonIcon"
    formName="applicant-identity"
    header="Identity Verification"
    text="Please upload a picture of your <br><b>driver license, state ID or passport.</br>"
    :onSubmit="onContinue"
    :updating="updating"
    :error="error.message"
  >
    <v-flex pb-3 v-if="document">
      <blw-document :document="document" :documentDeleteSuccessHandler="fetchApplicant" />
    </v-flex>

    <blw-upload :required="!document" :hideUploadButton="hideUploadButton" v-model="fileToUpload" />
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PersonIcon from '@/assets/icons/person_lg.svg'

export default {
  name: 'ApplicantIdentity',
  data() {
    return {
      PersonIcon,
      fileToUpload: null,
    }
  },
  async beforeMount() {
    this.showLoader()
    await this.fetchApplicant()
    const applicantPhotoId = this.applicant.photo_id

    this.resetDocument()
    applicantPhotoId && (await this.fetchDocument(applicantPhotoId))
    this.hideLoader()
  },
  computed: {
    ...mapGetters({
      uploadError: 'documents/error',
      applicantError: 'applicant/error',

      updatingApplicant: 'applicant/updating',
      loadingDocument: 'documents/loading',

      applicant: 'applicant/applicant',
      document: 'documents/document',
    }),
    error() {
      return this.uploadError || this.applicantError
    },
    updating() {
      return this.updatingApplicant || this.loadingDocument
    },
    hideUploadButton() {
      return !!this.document || !!this.fileToUpload
    },
  },
  methods: {
    ...mapActions('applicant', ['updateApplicantByKey', 'fetchApplicant']),
    ...mapActions('documents', [
      'fetchDocument',
      'createDocument',
      'resetDocument',
      'deleteDocument',
    ]),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    async onContinue() {
      this.showLoader()

      if (this.fileToUpload) {
        await this.createDocument({
          purpose: 'applicant_identity',
          file: this.fileToUpload,
        })

        if (!this.uploadError) {
          await this.updateApplicantByKey({ field: 'photo_id', value: this.document.id })
        } else {
          this.$trackEvent({
            action: 'CTA Clicked',
            properties: {
              location: 'Center',
              color: 'White',
              text: 'Upload ID',
              category: 'About Yourself',
              status: 'Fail',
            },
          })
        }
      }

      if (!this.error) {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Upload ID',
            CTA: 'Continue',
            category: 'About Yourself',
            type: 'Button',
          },
        })

        this.$routerPush({ name: 'screening' })
      }

      this.hideLoader()
    },
  },
}
</script>
