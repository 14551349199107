<template>
  <div class="mg-preferences-view">
    <v-container>
      <blw-button class="go-back" color="charcoal" icon flat @click="goBack">
        <v-icon color="charcoal">close</v-icon>
      </blw-button>
      <v-layout column align-center justify-center>
        <h3 class="header-1 mb-2">Living preferences</h3>
        <p class="mb-4">See how compatible you are</p>
        <preferences v-bind="preferences" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Preferences from '../components/MeetGreetPreferences'

export default {
  name: 'MeetGreetChat',
  components: { Preferences },
  computed: {
    ...mapGetters('meetGreet', ['meeting']),
    preferences() {
      return {
        pageView: true,
        roommates: this.meeting.residents,
        overlaps: this.meeting.lp_overlaps,
      }
    },
  },
  methods: {
    goBack() {
      this.$trackEvent({
        action: 'Go Back',
        properties: {
          location: 'Meet & Greet - Preferences',
          CTA: 'X icon',
          category: 'Meet & Greet',
          type: 'Icon',
        },
      })

      this.$router.back()
    },
  },
  mounted() {
    if (window.Intercom) window.Intercom('shutdown')
  },
}
</script>

<style lang="scss">
.mg-preferences-view {
  text-align: center;
  padding: 6rem 0;

  .go-back {
    position: absolute;
    left: 2.25rem;
    top: 2rem;
    background: white;
    @include boxShadow($tight: true);
  }
}
</style>
