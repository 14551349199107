import { get as _get } from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  applicationSource: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,

  applicationSource: ({ applicationSource }) => applicationSource,
  isGroupSource: ({ applicationSource }) => !!_get(applicationSource, 'group_source'),
  applicationSourceHasBeenUsed: ({ applicationSource }) => _get(applicationSource, 'used'),
  applicationFeeWaived: ({ applicationSource }) => _get(applicationSource, 'waive_application_fee'),
}

const actions = {
  async fetchApplicationSource({ commit }, source) {
    commit('setLoading', true)
    commit('setError', false)

    return await this._vm.$http
      .get(`applications/source/${source}/`)
      .then(({ data }) => data)
      .then((applicationSource) => {
        commit('setApplicationSource', applicationSource)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setApplicationSource(state, applicationSource) {
    state.applicationSource = applicationSource
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
