<template>
  <div id="lease-preferences-modal" class="grid-enabled px-2">
    <span class="modal-grid-header header-md text-xs-center">Your lease preferences</span>
    <div class="modal-grid-content my-3 py-2 mx-0 px-0">
      <v-btn
        v-for="preference in preferenceOptions"
        :key="preference.field"
        class="custom-button-wrapper py-3 px-0 font-small"
        block
        flat
        @click="preference.selected = !preference.selected"
      >
        <v-layout align-center px-3>
          <v-flex xs9 class="font-small">
            <div class="font-bold">{{ preference.title }}</div>
            <div class="font-thin pt-2">
              {{ preference.description }}
            </div>
          </v-flex>
          <v-flex text-xs-right xs3>
            <v-icon medium :color="preference.selected ? 'blue' : '#C7C7C7'">
              {{ preference.selected ? 'check_circle' : 'panorama_fish_eye' }}
            </v-icon>
          </v-flex>
        </v-layout>
      </v-btn>
    </div>
    <div class="modal-grid-footer">
      <v-layout justify-center row>
        <v-flex xs12 sm12>
          <blw-button
            block
            color="charcoal"
            :loading="loading"
            @click="savePreferences"
            size="lg"
            label="Change preferences"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LeasePreferenceModal',
  data() {
    return {
      checkbox: false,
      loading: false,
      preferenceOptions: [],
    }
  },
  beforeMount() {
    this.preferenceOptions = [
      {
        title: 'Pet Friendly',
        description: 'Required if your pet is not an ESA or service animal.',
        field: 'has_pets',
        selected: this.has_pets,
      },
      {
        title: 'Master Bedroom',
        description: 'Required if you are sharing a room.',
        field: 'sharing_room',
        selected: this.sharing_room,
      },
    ]
  },
  computed: {
    ...mapFields('leasePreference', ['leasePreference.has_pets', 'leasePreference.sharing_room']),
    ...mapGetters('leasePreference', ['leasePreference']),
  },
  methods: {
    ...mapActions('leasePreference', ['updateLeasePreference']),
    async savePreferences() {
      this.loading = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Modal',
          color: 'Charcoal',
          CTA: 'Save preferences',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.preferenceOptions.forEach(({ field, selected }) => (this[field] = !!selected))
      await this.updateLeasePreference()
      this.$root.$emit('closeModal')
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
#lease-preferences-modal {
  .custom-button-wrapper {
    height: max-content;
    .v-btn__content {
      white-space: normal !important;
      width: 100%;
      text-align: left;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $charcoal-20;
    }
  }
  .v-icon {
    transition: all 0.5s;
  }
}
</style>
