<template>
  <div class="mg-modal">
    <v-layout column align-center justify-space-between>
      <div>
        <blw-illustration class="mx-auto mb-4" type="spot" name="choose-your-home" size="80" />
        <h2 class="modal-grid-header header-md text-xs-center mb-4">Are you sure?</h2>
        <p class="mb-4">
          There are other people interested in this home, if you decide to pass on it you’ll forfeit
          your spot.
        </p>
      </div>
      <loading-spinner v-if="pending" size="50" class="py-1 my-4 mx-auto" />
      <div v-else>
        <blw-button size="sm" class="mt-3 mb-4" color="charcoal" @click="$root.$emit('closeModal')">
          Nevermind, I'm still interested
        </blw-button>
        <a class="cta-link" @click="onChangedMind"> Yes, let's choose a different home </a>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'MeetGreetNotSure',
  components: { LoadingSpinner },
  data() {
    return {
      pending: false,
    }
  },
  computed: {
    ...mapGetters('application', ['applicationStatus']),
  },
  methods: {
    ...mapActions('meetGreet', ['abortMeeting']),
    ...mapActions('application', ['fetchApplicationBySource']),
    async onChangedMind() {
      this.pending = true

      await this.abortMeeting()
      await this.fetchApplicationBySource(this.$route.params.applicationSource)
      const applicationInHomeShopping = this.applicationStatus === 'meet_and_greet'
      this.$routerPush({ name: applicationInHomeShopping ? 'homeShopping' : 'homeSelection' })
      applicationInHomeShopping
        ? this.$root.$emit('closeModal')
        : this.$root.$emit('openModal', 'MeetGreetChangedMind')
    },
  },
}
</script>
