<template>
  <div id="home-selection-bedroom-modal" class="grid-enabled sm-modal">
    <span class="modal-grid-header header-1 text-xs-center">Select your bedroom</span>
    <div class="modal-grid-content mt-2 py-2">
      <v-layout>
        <v-flex class="text-xs-center font-large">
          You can always adjust these details<br />
          before signing a lease.
        </v-flex>
      </v-layout>
      <v-layout class="pt-1">
        <v-flex>
          <v-radio-group v-model="roomId">
            <div
              v-for="availableRoom in _annotatedAvailableRooms"
              :key="availableRoom.id"
              @click="selectRoomClickHandler(availableRoom)"
              :class="[
                'room-selection-item justify-space-between py-3 px-1',
                {
                  dampen: roomId && availableRoom.id !== roomId,
                },
              ]"
              v-ripple
            >
              <div class="d-flex align-center">
                <v-btn
                  v-if="availableRoom.matterport_url"
                  @click="showMatterportPreview(availableRoom.matterport_url)"
                  class="view-room-matterport-btn mr-3"
                  icon
                >
                  <blw-icon icon="picture" />
                </v-btn>
                <div>
                  <div class="header-xs regular-font" v-text="availableRoom.room_type" />
                  <div class="subheader-sm">
                    <span class="font-weight-bold">
                      ${{ availableRoom._displayPrice }}+ / mo •
                    </span>
                    {{ availableRoom._earliestAvailableDate }}
                  </div>
                </div>
              </div>
              <div class="d-flex justify-end">
                <v-radio class="mr-0" :value="availableRoom.id" />
              </div>
            </div>
          </v-radio-group>
        </v-flex>
      </v-layout>
    </div>
    <div class="modal-grid-footer mt-4">
      <v-layout justify-center row>
        <v-flex>
          <blw-button
            @click="confirmClickHandler"
            :disabled="!roomId"
            color="primary"
            block
            label="Continue"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { roundPriceToNearestFiveDollars } from '@livebungalow/toolbox/abacus'

export default {
  name: 'HomeSelectionBedroomModal',
  computed: {
    ...mapFields('homeShopping', [
      'homeShopping.roomId',
      'homeShopping.roomAvailabilityDate',
      'homeShopping.roomPrice',
      'homeShopping.roomDiscountedPrice',
      'homeShopping.selectedProperty',
    ]),
    ...mapGetters('constants', ['minimumLeaseLengthMultiplier']),
    _annotatedAvailableRooms() {
      return this.selectedProperty._availableRooms
        .map((room) => ({
          ...room,
          _displayPrice: roundPriceToNearestFiveDollars(
            (room.discounted_price || room.price[0]) * this.minimumLeaseLengthMultiplier
          ),
          _earliestAvailableDate: `available ${format(
            new Date(room.availability_date),
            'MMM Do[,] YYYY'
          )}`,
        }))
        .sort((a, b) => a._displayPrice - b._displayPrice)
    },
  },
  methods: {
    selectRoomClickHandler({ id, price: [roomPrice], discounted_price, availability_date }) {
      this.roomId = id
      this.roomPrice = roomPrice
      this.roomDiscountedPrice = discounted_price
      this.roomAvailabilityDate = availability_date
    },
    confirmClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Continue',
          text: 'Select your bedroom',
          category: 'Home Selection',
          property_id: this.selectedProperty.id,
          bedroom_id: this.roomId,
        },
      })
      this.$root.$emit('openModal', {
        modalSize: this.$vuetify.breakpoint.smAndDown ? 'fullscreen' : 'small',
        modalComponent: 'HomeSelectionLeaseLengthModal',
      })
    },
    showMatterportPreview(matterportUrl) {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Room List Item (back of flip card)',
          color: 'Flat',
          CTA: 'Preview Room Matterport',
          category: 'Home Selection',
          type: 'Icon',
        },
      })
      this.$root.$emit('showIframe', {
        srcUrl: `${matterportUrl}&play=1`,
      })
    },
  },
}
</script>
<style lang="scss">
#home-selection-bedroom-modal {
  .modal-grid-content {
    overflow: scroll;
    @include desktop {
      max-height: 400px;
    }
  }
  .v-input__control {
    width: 100%;
  }
  .room-selection-item {
    display: flex;
    border-bottom: 1px solid rgba(230, 227, 227, 0.5);
    cursor: pointer;
    transition: opacity 0.5s;
    &.dampen {
      opacity: 0.5;
    }
  }
  .view-room-matterport-btn {
    border: 1px solid black;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    .blw-icon {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
