<template>
  <v-layout row wrap id="screening">
    <v-flex md6 order-md1 order-xs2>
      <blw-text-field
        v-model="sin_ssn"
        label="Social security number (SSN)"
        :rules="skipSsnEntry ? [] : [required, maxLength(9)]"
        autocomplete="off"
        maxlength="9"
        box
        mask="###-##-####"
        @blur="savedFieldLog('Social security number')"
      />
      <v-checkbox
        v-model="skipSsnEntry"
        class="reverse-checkbox"
        label="I don’t have a US social security number"
      />
    </v-flex>
    <v-flex v-if="!isPersonaVerified" md6 order-md2 order-xs1>
      <p class="font-bold font-large">Identity Verification</p>
      <div class="font-base charcoal-40--txt mb-3">
        Please upload a picture of your driver license, state ID or passport.
      </div>
      <blw-upload-v2
        :documentUrl="documentUrl"
        :fileOnChangeHandler="uploadAndSaveDocument"
        :deleteFileHandler="documentDeleteSuccessHandler"
        inputId="identification-document"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { required, maxLength } from '@/utils/formValidations'

export default {
  name: 'Screening',
  beforeMount() {
    this.resetDocument()
    if (this.photo_id) this.fetchDocument(this.photo_id)
  },
  computed: {
    ...mapFields('submit', ['skipSsnEntry', 'sin_ssn']),
    ...mapGetters('applicant', ['photo_id']),
    ...mapGetters('documents', ['documentById', 'document']),
    ...mapGetters('documents', {
      uploadError: 'error',
      loadingFileUpload: 'loading',
    }),
    ...mapGetters('user', ['isPersonaVerified']),
    photoIdDocument() {
      return this.documentById(this.photo_id)
    },
    documentUrl() {
      return this.photoIdDocument ? this.photoIdDocument.url : null
    },
    requiredFields() {
      return [this.photoIdDocument, this.skipSsnEntry, this.sin_ssn]
    },
  },
  watch: {
    skipSsnEntry(val) {
      if (val) {
        this.sin_ssn = null
      }
    },
    requiredFields([photoId, skipSsnEntry, sinSsn]) {
      if (photoId && (skipSsnEntry || sinSsn)) {
        this.$trackEvent({
          action: 'Apply section complete',
          properties: {
            section: 'Screening',
          },
        })
      }
    },
  },
  methods: {
    required,
    maxLength,
    ...mapActions('applicant', ['updateApplicant', 'updateApplicantByKey', 'fetchApplicant']),
    ...mapActions('documents', [
      'fetchDocument',
      'createDocument',
      'resetDocument',
      'deleteDocument',
    ]),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    async uploadAndSaveDocument(fileToUpload) {
      this.showLoader()
      this.resetDocument()
      await this.createDocument({
        purpose: 'applicant_identity',
        file: fileToUpload,
      })
      if (!this.uploadError && this.document) {
        this.savedFieldLog('Identification document')
        await this.updateApplicantByKey({ field: 'photo_id', value: this.document.id })
      }
      this.hideLoader()
    },
    async documentDeleteSuccessHandler() {
      this.resetDocument()
      this.photo_id && (await this.deleteDocument(this.photo_id))
      this.fetchApplicant()
    },
    savedFieldLog(field) {
      this.$trackEvent({
        action: 'Field Saved',
        properties: {
          type: 'Application Page',
          text: field,
        },
      })
    },
  },
}
</script>

<style lang="scss">
#screening {
  .reverse-checkbox {
    label {
      font-size: 14px;
    }
  }
}
</style>
