<template>
  <main class="before-you-begin grid-expanding-center">
    <blw-hero header="Before you begin" />

    <section class="white pb-5">
      <v-layout class="what-you-need" column align-center justify-center>
        <p class="font-large mt-5 mb-3 font-weight-bold">You'll need:</p>
        <ul>
          <li class="my-2 font-large">
            <v-icon class="pr-1 inline" color="grey">check</v-icon>
            <span>Identification</span>
          </li>
          <li class="my-2 font-large">
            <v-icon class="pr-1 inline" color="grey">check</v-icon>
            <span>Proof of Income</span>
          </li>
        </ul>
      </v-layout>

      <v-layout justify-center align-baseline mt-5 v-if="!cosignerSourceFeeIsWaived">
        <p class="font-large accent-font">Application fee is ${{ fee }}</p>
        <span class="pl-1 text-uppercase accent-font font-small">
          {{ currency }}
        </span>
      </v-layout>
    </section>

    <footer>
      <v-layout justify-center>
        <blw-button label="Continue" :loading="loading" @click="onClick" />
      </v-layout>
      <v-snackbar multi-line :value="error">
        Oops, something went wrong:
        <br />
        {{ error.message }}
        <br />
        Please contact Bungalow to request a new application
        <v-btn flat color="accent" class="text-uppercase" @click="error = null"> Dismiss </v-btn>
      </v-snackbar>
    </footer>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BeforeYouBegin',
  methods: {
    ...mapActions('cosigner', ['createCosignerBySource']),
    async onClick() {
      const { source } = this.$route.query
      await this.createCosignerBySource(source)
    },
  },
  computed: {
    ...mapGetters('cosigner', ['loading', 'error']),
    ...mapGetters('cosignerSource', ['cosignerSourceApplication', 'cosignerSourceFeeIsWaived']),
    fee() {
      return this.cosignerSourceApplication.application_fee
    },
    currency() {
      return this.cosignerSourceApplication.currency.toUpperCase()
    },
  },
}
</script>

<style lang="scss">
.before-you-begin {
  ul {
    list-style: none;
    padding-left: 0;
  }
}
</style>
