var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-enabled",attrs:{"id":"confirm-address-modal"}},[_c('span',{staticClass:"modal-grid-header header-1 text-xs-center"},[_vm._v("Confirm Address")]),_c('div',{staticClass:"modal-grid-content mt-2"},[_c('v-layout',{staticClass:"text-xs-center confirm-address-intro",attrs:{"justify-center":"","row":"","pb-3":"","px-3":""}},[_vm._v(" There's a problem with the address provided."),_c('br'),_vm._v(" Is this what you meant? ")]),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.addressesWithHelperFields),function(address,i){return _c('v-flex',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,on:{"click":function($event){_vm.suggestedAddressSelected = address.suggested}}},[_c('div',{class:[
            'address-box',
            {
              selected: address.suggested === _vm.suggestedAddressSelected,
            },
          ]},[_c('div',{staticClass:"address-details"},[_c('div',{staticClass:"lead"},[_vm._v(" "+_vm._s(address.title)+" ")]),_vm._l((_vm.addressFields),function(addressField){return _c('span',{key:addressField.key},[_c('span',{class:{
                  'highlighted-suggested-field':
                    address.suggested && _vm.isHighlightedSuggestedAddressField(addressField.key),
                }},[_vm._v(" "+_vm._s(_vm.renderAddressFieldText(addressField, address)))]),(addressField.break)?_c('br'):_vm._e(),_vm._v(_vm._s(addressField.addComma ? ', ' : '')+" ")])})],2),_c('div',{staticClass:"selected-address-identifier"},[_c('div',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("check")])],1)])])])}),1)],1),_c('div',{staticClass:"modal-grid-footer mt-4"},[_c('v-layout',{attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":""}},[_c('blw-button',{attrs:{"block":"","label":"Save address"},on:{"click":_vm.saveAddressClickHanlder}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }