import Vue from 'vue'

/* Registers all Blw* shared components with Vue, globally importing them. */

// TODO: Write more validation steps for shared components

const requireBlwComponent = require.context('@/shared/components', true, /Blw[\w]+\.vue$/)

requireBlwComponent.keys().forEach((fileName) => {
  const componentConfig = requireBlwComponent(fileName)
  const componentName = componentConfig.default.name

  if (!componentName)
    throw new Error(`No Component Name Provided: \n Offending Component --> ${fileName}`)

  Vue.component(componentName, componentConfig.default || componentConfig)
})
