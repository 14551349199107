<template>
  <div class="pricing-slider">
    <div v-if="isAdmin" class="days-out-view">
      <v-layout class="justify-space-between">
        <p class="pricing-slider__label">Days out</p>
        <p class="lead">{{ daysUntilMoveIn }} Days ({{ calculatedMoveInFormatted }})</p>
      </v-layout>
      <blw-slider
        :min="minMoveIn"
        :max="maxMoveIn"
        :thumb-label="false"
        v-model="daysUntilMoveIn"
      />
    </div>
    <v-layout justify-center>
      <v-flex xs12 class="relative">
        <div class="pricing-slider__label date-picker-label">Move-in date</div>
        <v-icon class="chevron pl-1" size="20">keyboard_arrow_down</v-icon>
        <blw-calendar
          v-model="selectedDate"
          :minDate="earliestMoveInDateString"
          :maxDate="maxMoveInDateString"
          :dateDisplayFormat="moveInDateFormat"
          :hideIcon="true"
        />
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs12 d-flex align-center>
        <div class="pricing-slider__label">{{ leaseLength }} month lease</div>
        <div class="lead text-xs-right">
          {{ leaseEndDateFormatted }}
        </div>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs12>
        <div :class="['relative', { 'in-best-price-zone': inBestPriceZone }]">
          <blw-slider :min="minLease" :max="maxLease" :thumb-label="false" v-model="leaseLength" />
          <div class="best-deal-zone" v-if="basePrice">
            <div
              v-for="(zone, index) in availableLeaseLengths"
              :key="`lease-zone-${zone}`"
              :class="[
                'zone',
                {
                  active: isBestPriceZone(index),
                  only: isOnlyBestPrice(index),
                },
              ]"
            >
              <div :class="['best-price-flag', { active: isOnlyBestPrice(index) }]">
                <div class="best-price-box paragraph-2 font-weight-bold">Best deal</div>
              </div>
            </div>
            <div :class="['best-price-flag group', { active: !onlyOneBestDeal }]">
              <div class="best-price-box paragraph-2 font-weight-bold">Best Deals</div>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { format, addDays, differenceInDays } from 'date-fns'
import {
  getLeaseEndDate,
  getCalculatedRoomPrice,
  getAvailableLeaseLengths,
} from '@livebungalow/toolbox/abacus'

export default {
  name: 'PricingSlider',
  props: {
    basePrice: {
      type: Number,
      default: 0,
    },
    discountedPrice: {
      type: Number,
      default: 0,
    },
    moveInDate: {
      type: [Date, String],
    },
    leaseEndDate: {
      type: [Date, String],
    },
    availabilityDate: {
      type: [Date, String],
      default: () => new Date(),
    },
    onChangeHandler: {
      type: Function,
      required: true,
    },
    errorHandler: {
      type: Function,
      default: (e) => console.error(e),
    },
    moveInDateFormat: {
      type: String,
      default: 'MMM D, YYYY',
    },
    leaseDuration: {
      type: [String, Number],
      default: 12,
    },
    leaseEndDateFormat: {
      type: String,
      default: 'MMM D, YYYY',
    },
    dateStringFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    compactLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: null,
      earliestMoveInDate: null,
      earliestMoveInDateString: null,
      maxMoveInDateString: null,
      leaseLength: 12,
      daysUntilMoveIn: 0,
      minMoveIn: 0,
      maxMoveIn: 59,
      moveInTimeframeDays: 60,
      onlyOneBestDeal: false,
      bestPriceTrackWidth: 0,
      bestPriceTrackMargin: 0,
      bestPriceMatrix: {},
      priceMatrix: {},
    }
  },
  beforeMount() {
    this.setInitialValues()
    this.generatePriceMatrix()
    this.calculateBestDealZones()
    if (new Date().getFullYear() < 2021) {
      this.leaseLength = 6
    }
  },
  mounted() {
    this.valuesChanged()
  },
  watch: {
    selectedDate(selectedDate) {
      const moveInDate = this.parseDate(selectedDate)
      /* Since the pricing matrix starts from 0, add one for correct indexing, Math.max() here
         is used to guard against old bad saved dates in the past that no longer compute */
      this.daysUntilMoveIn = Math.max(0, differenceInDays(moveInDate, this.earliestMoveInDate) + 1)
      this.updateBestDealZones()
    },
    daysUntilMoveIn() {
      this.updateBestDealZones()
      this.valuesChanged()
    },
    leaseLength() {
      this.valuesChanged()
    },
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('constants', [
      'leaseLengthMultipliers',
      'holdingFeeMultipliers',
      'seasonalMultiplier',
    ]),
    useDiscountPricing() {
      return this.leaseLength >= 12 && this.daysUntilMoveIn <= 30
    },
    priceMatrixKey() {
      return this.useDiscountPricing ? 'discountPriceMatrix' : 'basePriceMatrix'
    },
    minLease() {
      return this.availableLeaseLengths[0]
    },
    maxLease() {
      return this.availableLeaseLengths[this.availableLeaseLengths.length - 1]
    },
    calculatedLeaseEndDate() {
      return getLeaseEndDate({
        moveInDate: this.calculatedMoveInDate,
        leaseLength: this.leaseLength,
      })
    },
    leaseEndDateFormatted() {
      return format(this.calculatedLeaseEndDate, this.leaseEndDateFormat)
    },
    calculatedMoveInDate() {
      return addDays(this.roomAvailabilityDate, this.daysUntilMoveIn)
    },
    calculatedMoveInFormatted() {
      return format(this.calculatedMoveInDate, this.moveInDateFormat)
    },
    moveInDateFormatted() {
      return format(this.selectedDate, this.moveInDateFormat)
    },
    moveInDateReturnValue() {
      return format(this.selectedDate, this.dateStringFormat)
    },
    leaseEndDateReturnValue() {
      return format(this.calculatedLeaseEndDate, this.dateStringFormat)
    },
    earliestMoveInDateFormatted() {
      return format(this.earliestMoveInDate, this.moveInDateFormat)
    },
    roomAvailabilityDate() {
      const roomAvailabilityDate = new Date(this.availabilityDate)
      const roomAvailabilityInThePast = differenceInDays(roomAvailabilityDate, new Date()) < 1

      return !roomAvailabilityInThePast ? roomAvailabilityDate : new Date()
    },
    calculatedRoomPrice() {
      return this.priceMatrix[this.priceMatrixKey][this.daysUntilMoveIn][this.leaseLengthIndex]
    },
    availableLeaseLengths() {
      return getAvailableLeaseLengths()
    },
    bestPricesByMoveInPeriod() {
      return this.bestPriceMatrix[this.priceMatrixKey][this.daysUntilMoveIn]
    },
    leaseLengthIndex() {
      const leaseLength = Number(this.leaseLength)
      return this.availableLeaseLengths.indexOf(leaseLength)
    },
    inBestPriceZone() {
      if (!this.basePrice) return

      return this.bestPricesByMoveInPeriod.isBestPriceArray[this.leaseLengthIndex]
    },
  },
  methods: {
    isBestPriceZone(leaseLengthIndex) {
      return this.bestPricesByMoveInPeriod.isBestPriceArray[leaseLengthIndex]
    },
    isOnlyBestPrice(leaseLength) {
      return this.isBestPriceZone(leaseLength) && this.bestPricesByMoveInPeriod.isOnlyBestPrice
    },

    updateBestDealZones() {
      this.$nextTick(() => (this.onlyOneBestDeal = document.querySelector('.only')))
    },
    calculateBestDealZones() {
      const generateBestDealMatrix = (matrix, pricesByLeaseLength, timeframe) => {
        const lowestPrice = [...pricesByLeaseLength].sort((a, b) => a - b)[0]
        const isBestPriceArray = pricesByLeaseLength.map((price) => price == lowestPrice)
        const isOnlyBestPrice = isBestPriceArray.filter((price) => !!price).length == 1

        matrix[timeframe] = { isBestPriceArray, isOnlyBestPrice }

        return matrix
      }

      const basePriceMatrix = _.reduce(
        this.priceMatrix['basePriceMatrix'],
        generateBestDealMatrix,
        {}
      )

      const discountPriceMatrix = _.reduce(
        this.priceMatrix['discountPriceMatrix'],
        generateBestDealMatrix,
        {}
      )

      this.bestPriceMatrix = { basePriceMatrix, discountPriceMatrix }

      // Paint the best deal zones
      this.updateBestDealZones()
    },
    generatePriceMatrix() {
      // For every possible day available for move-in, calculate the price variables...
      const basePriceMatrix = _.times(this.moveInTimeframeDays + 1).reduce((matrix, timeframe) => {
        // Every day, by every available lease length...
        if (!matrix[timeframe]) matrix[timeframe] = []
        this.availableLeaseLengths.forEach((leaseLength) => {
          // Caluclate the price variables:
          const roomPrice = getCalculatedRoomPrice({
            basePrice: this.basePrice,
            availabilityDate: this.earliestMoveInDate,
            daysUntilMoveIn: timeframe,
            leaseLength,
            _seasonalMultiplier: this.seasonalMultiplier,
            _holdingFeeMultipliers: this.holdingFeeMultipliers,
            _leaseLengthMultipliers: this.leaseLengthMultipliers,
          }).roomPrice
          // Store the result at the corresponding lease/day combination
          matrix[timeframe].push(roomPrice)
        })
        return matrix
      }, {})

      const discountPriceMatrix = _.times(this.moveInTimeframeDays + 1).reduce(
        (matrix, timeframe) => {
          // Every day, by every available lease length...
          if (!matrix[timeframe]) matrix[timeframe] = []
          this.availableLeaseLengths.forEach((leaseLength) => {
            // Caluclate the price variables:
            const roomPrice = getCalculatedRoomPrice({
              basePrice: this.discountedPrice || this.basePrice,
              availabilityDate: this.earliestMoveInDate,
              daysUntilMoveIn: timeframe,
              leaseLength,
              _seasonalMultiplier: this.seasonalMultiplier,
              _holdingFeeMultipliers: this.holdingFeeMultipliers,
              _leaseLengthMultipliers: this.leaseLengthMultipliers,
            }).roomPrice
            // Store the result at the corresponding lease/day combination
            matrix[timeframe].push(roomPrice)
          })
          return matrix
        },
        {}
      )

      this.priceMatrix = { basePriceMatrix, discountPriceMatrix }
    },
    valuesChanged() {
      // Sends values back up to the parent component
      this.$nextTick(() => {
        this.onChangeHandler({
          moveInDate: this.moveInDateReturnValue,
          leaseLength: this.leaseLength,
          leaseEndDate: this.leaseEndDateReturnValue,
          roomPrice: this.calculatedRoomPrice,
        })
      })
    },
    setInitialValues() {
      // Snapshot the earliest available move-in date
      this.earliestMoveInDate = this.calculatedMoveInDate

      // Get the date string of the earliest possible move-in date
      this.earliestMoveInDateString = format(this.calculatedMoveInDate, this.dateStringFormat)

      // Get the date string of the furthest possible move-in date
      this.maxMoveInDateString = format(
        addDays(this.calculatedMoveInDate, this.maxMoveIn),
        this.dateStringFormat
      )

      // Set the date-picker to the saved date, or the earliest available.
      this.selectedDate = this.moveInDate || this.earliestMoveInDateString

      // Set lease length slider to the default, or last selected value
      this.leaseLength = this.leaseDuration
    },
    parseDate(dateString = '') {
      // If it's already a Date just return
      if (dateString instanceof Date) return dateString

      // Parse the date string
      const dateParts = dateString.split('-')

      // We can expect this order, because FS provides it this way:
      const [year, month, day, ...timestamp] = dateParts

      // Explicitly build the date, accounting for month number off-by-one
      return new Date(year, month - 1, day, ...timestamp)
    },
  },
}
</script>

<style lang="scss">
.pricing-slider {
  width: 272px;
  margin: auto;

  @media (max-width: 374px) {
    width: auto;
  }

  pre {
    font-family: monospace;
  }

  .best-deal-zone {
    display: flex;
    position: absolute;
    justify-content: space-between;
    left: 0;
    right: 0;
    bottom: 39px;
    margin-left: -9px;
    margin-right: -9px;

    .zone {
      transition: background 0.25s ease;
      width: 100%;
      height: 2px;
      background: transparent;
      font-size: 10px;
      position: relative;
      pointer-events: none;

      &:first-of-type {
        width: 50%;
        margin-left: 9px;
      }

      &.active {
        background-color: $blue-60;
      }
    }
  }

  .blw-date-picker {
    .blw-text-field {
      padding-top: 0;
    }

    .v-text-field__slot {
      & input {
        font-family: $base-font;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: right;
      }
    }
  }

  .date-picker-label {
    top: 10px;
    display: flex;
    position: absolute;
  }

  .chevron {
    top: 10px;
    position: absolute;
    right: 85px;
  }

  .pricing-slider__label {
    font-weight: normal;
    white-space: nowrap;

    @include mobile() {
      font-size: 13px;
    }
  }

  .blw-slider {
    padding: 0;
    margin: 0;
  }

  .v-slider__thumb.primary {
    z-index: 1;
    transition: border-color 0.25s;
  }

  .in-best-price-zone {
    .v-slider__thumb.primary {
      border-color: $blue-60 !important;
    }
  }

  &__label {
    font-size: 15px;
    line-height: 1.25rem;
  }

  .best-price {
    height: 2px;
    background: $blue-60;
    position: absolute;
    top: 0.9375rem;
  }

  .best-price-box {
    line-height: 1.25rem;
  }

  .best-price-flag {
    background: $blue-60;
    color: white;
    padding: 0.3rem 0.5rem;
    min-width: max-content;
    position: absolute;
    top: 1.25rem;
    left: -1.75rem;
    text-align: center;
    transition: all 0.3s;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    // Caret arrow
    &::before {
      content: '';
      display: block;
      left: calc(50% - 2.5px);
      height: 7px;
      width: 7px;
      position: absolute;
      background: $blue-60;
      top: -3.5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.group {
      width: 100px;
      left: unset;
      right: 1.5rem;
    }
  }

  .days-out-view {
    margin-bottom: -1rem;
  }
}
</style>
