<template>
  <div id="popup-iframe-container" :class="{ closing }" v-if="isOpen" :style="backgroundImageStyle">
    <div
      @click="closeIframeHandler"
      :style="topClosingBarStyle"
      v-ripple
      class="close-iframe-button clickable"
    >
      Back to application
    </div>
    <iframe :src="srcUrl" frameBorder="0" />
  </div>
</template>

<script>
const defaultPopupIframeState = () => ({
  // determins weither or not to show the modal
  isOpen: false,
  // closing flag is set to reverse the animation
  closing: false,
  // Props that will be passed down to the modal component
  srcUrl: null,
  // on close handler
  onCloseHandler: () => {},
  // height of closing top bar
  closeTopBarHeight: '50px',
})

export default {
  name: 'PopupIframe',
  data() {
    return defaultPopupIframeState()
  },
  computed: {
    backgroundImageStyle() {
      return `background-image: url(${require('@/assets/loading-animated.gif')})`
    },
    topClosingBarStyle() {
      return `height: ${this.closeTopBarHeight};`
    },
  },
  mounted() {
    this.$root.$on('showIframe', this.showIframeHandler)
    this.$root.$on('closeModal', this.closeIframeHandler)

    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape' && this.isOpen) {
        this.closeIframeHandler()
      }
    })
  },
  methods: {
    showIframeHandler(config) {
      this.updateData(config)
      this.isOpen = true
      this.$nextTick(function () {
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      })
    },
    closeIframeHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Bungalow website iframe',
          color: 'Salmon',
          CTA: 'Back to application',
          category: 'Home Selection',
          type: 'Banner',
        },
      })

      const body = document.body
      const scrollY = body.style.top
      body.style.position = ''
      body.style.top = ''
      body.style.width = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      // sets the closing flag to true which triggers the closing animations
      // After 300 ms ( when the animation is over ) the modal state is set back to the default
      this.onCloseHandler()
      this.closing = true
      setTimeout(() => {
        this.updateData(defaultPopupIframeState())
      }, 300)
    },
    updateData(newData) {
      for (let [key, value] of Object.entries(newData)) {
        this[key] = value
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes slideIframeUp {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideIframeDown {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}

#popup-iframe-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // one more than the intercom widget
  z-index: 2147483001;
  transform: translateY(100%);
  animation: slideIframeUp 0.5s;
  animation-fill-mode: forwards;
  background: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12.5rem;
  iframe {
    height: 100%;
    width: 100%;
  }
  .close-iframe-button {
    background: #f98e77;
    color: white;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    @include mobile {
      height: 61px;
    }
  }
  &.closing {
    animation: slideIframeDown 0.5s forwards;
  }
  .loading-container {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
