import dateFns from 'date-fns'
/**
 *
 * @param {Date} initialTime
 * @param {Date} endsAt
 *
 * returns the different in hours, minutes, seconds and includes a hasExpired flag
 * if the time between is 0
 */
const getTimeBetweenDates = (initialTime, endsAt) => {
  // Calculate hours remaining, add them to the duration
  const hours = Math.max(0, dateFns.differenceInHours(endsAt, initialTime))
  initialTime = dateFns.addHours(initialTime, hours)
  // Now calculate the remainder of minutes left
  const minutes = Math.max(0, dateFns.differenceInMinutes(endsAt, initialTime))
  initialTime = dateFns.addMinutes(initialTime, minutes)
  // For the final minute, also calculate seconds left
  const seconds = Math.max(0, dateFns.differenceInSeconds(endsAt, initialTime))
  initialTime = dateFns.addSeconds(initialTime, seconds)
  // Flag if time has expired
  const hasExpired = hours === 0 && minutes === 0 && seconds === 0
  // Save computed time left into store
  return {
    hours,
    minutes,
    seconds,
    hasExpired,
  }
}

const getFormattedDateFromStrDate = (dateStr, dateFormat = 'yyyy-MM-dd') => {
  // JS dates can be one day off depending on string format, using slashes helps:
  try {
    // replaces '-' with '/'
    const dateStringWithSlashes = dateStr?.replace(/-/g, '/').replace(/T.+/, '')
    // Update the value before sending back.
    const dateObj = new Date(dateStringWithSlashes)
    return dateFns.format(dateObj, dateFormat)
  } catch (dateParsingError) {
    console.error('Date Field Error:', dateParsingError)
    return null
  }
}

export { getFormattedDateFromStrDate, getTimeBetweenDates }
