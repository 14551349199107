<template>
  <div class="blw-document">
    <v-card flat>
      <template v-if="document">
        <p class="lead mb-1">{{ purposeMap[document.purpose] || '' }} Document:</p>
        <v-divider class="mb-2" />
        <v-layout align-center justify-space-between>
          <v-layout align-baseline wrap class="mr-2">
            <span class="document-name font-small pr-2">
              <strong>{{ document.filename }}</strong>
            </span>
            <span class="grey--text">({{ getFileSize(document.size) }})</span>
          </v-layout>
          <span @click="deleteDocumentHandler(document.id)" class="delete"> Delete </span>
        </v-layout>
      </template>
      <template v-else>
        <p>Please provide a file to display.</p>
      </template>
    </v-card>
  </div>
</template>

<script>
import getFileSize from 'filesize'
import { mapActions } from 'vuex'

export default {
  name: 'BlwDocument',
  props: {
    document: {
      type: Object,
    },
    documentDeleteSuccessHandler: {
      type: Function,
    },
  },
  data() {
    return {
      purposeMap: {
        applicant_income: 'Proof of Income',
        applicant_identity: 'Identification',
      },
    }
  },
  methods: {
    getFileSize,
    ...mapActions('documents', ['resetDocument', 'deleteDocument']),
    async deleteDocumentHandler(documentId) {
      await this.deleteDocument(documentId)
      await this.resetDocument()
      this.documentDeleteSuccessHandler && this.documentDeleteSuccessHandler()
    },
  },
}
</script>

<style lang="scss">
.blw-document {
  .document-name {
    word-break: break-word;
  }
  .v-card {
    border-radius: 0;
    border: 1px solid $charcoal-40;
    padding: 1.75rem $spacer-lg;

    @include mobile {
      padding: 1.5rem $spacer;
    }
  }

  .delete {
    user-select: none;
    &:hover {
      color: $red-60;
    }
  }
}
</style>
