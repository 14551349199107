import Vue from 'vue'
import Vuetify from 'vuetify'

import '@/styles/main.styl'
import vuetifyTheme from '@/styles/vuetifyTheme.js'

Vue.use(Vuetify, {
  theme: vuetifyTheme,
  options: {
    customProperties: true,
  },
})
