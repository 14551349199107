<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 3H3C1.9 3 1 3.88 1 4.98V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V4.98C23 3.88 22.1 3 21 3ZM11 11H19V17H11V11ZM13 15H17V13H13V15ZM3 19.02H21V4.97H3V19.02Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'IdentificationIcon' }
</script>
