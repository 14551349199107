<template>
  <v-container class="group-in-review" v-max-width="478">
    <v-layout column :class="['text-xs-center', isMobile ? 'mt-5' : 'pt-4 mt-5']">
      <span class="header-xl">⏳</span>
      <h1 class="header-md mt-4">Hang tight while we review your application</h1>

      <p class="mt-4 font-large">
        It typically takes <b>a few hours</b> to review <span>-</span> once approved, we'll email
        your lease!
      </p>

      <hr class="my-5" />

      <GroupContactSales />
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import GroupContactSales from '../components/GroupContactSales'

export default {
  name: 'GroupApplicationInReview',
  components: { GroupContactSales },
  beforeMount() {
    // If any group members haven't submitted, show the status page instead:
    if (this.groupMemberSubmissionsPending) this.$router.push({ name: 'groupStatus' })
  },
  computed: {
    ...mapGetters('application', ['application']),
    ...mapGetters('groupApplicationSource', [
      'groupApplicationSource',
      'groupMemberSubmissionsPending',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
