import HomeShoppingContainer from './containers/HomeShoppingContainer'
import HomeShopping from './views/HomeShopping'

const routes = {
  path: 'home-shopping',
  component: HomeShoppingContainer,
  meta: {
    section: 'homeShopping',
    requiresAuth: true,
    hideNavMobile: true,
    currentStep: 4,
  },
  children: [
    {
      path: '',
      name: 'homeShopping',
      component: HomeShopping,
    },
  ],
}

export default routes
