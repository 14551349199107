<template>
  <div class="mg-participants-view">
    <participants pageView v-bind="meeting" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Participants from '../components/MeetGreetParticipants'

export default {
  name: 'MeetGreetChat',
  components: { Participants },
  computed: {
    ...mapGetters('meetGreet', ['meeting']),
  },
  mounted() {
    if (window.Intercom) window.Intercom('shutdown')
  },
}
</script>
