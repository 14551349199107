<template>
  <ApplicationIntro
    :currentStep="2"
    :currentStepStartHandler="isFunnel2 ? null : goToProfile"
    blurFutureSteps
    :title="title"
    :subtitle="subtitle"
    :overrideSteps="overrideSteps"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ApplicationIntro from '@/routes/apply/components/ApplicationIntro'

export default {
  name: 'SubmitSuccess',
  components: {
    ApplicationIntro,
  },
  computed: {
    ...mapGetters('application', ['displayState', 'isGroupApplication']),
    isFunnel2() {
      return this.displayState.is_funnel_20
    },
    title() {
      return this.isFunnel2
        ? 'Your application is being reviewed by our team! 🙌'
        : 'Our team is reviewing your application!'
    },
    subtitle() {
      return this.isFunnel2
        ? ''
        : 'Usually we can get you approved in a minute or less. While you wait, create a profile so your future roommates can get to know you.'
    },
    overrideSteps() {
      return this.isFunnel2
        ? [
            {
              title: 'Submit your application',
              description: '',
            },
            {
              title: 'Get approved',
              description:
                'You’ll receive an email and call once our team has finished reviewing your application within 24-hours.',
            },
            {
              title: 'Sign your lease!',
              description:
                'We’ll send your lease as soon as your application is approved. Just review and sign it, electronically – it’s that easy!',
            },
          ]
        : null
    },
  },
  mounted() {
    this.$trackEvent({
      action: 'Page viewed',
      properties: {
        type: 'Preview Page',
        title: 'Create a profile',
      },
    })
  },
  methods: {
    goToProfile() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Preview page',
          title: 'Create a profile',
          CTA: 'Start',
        },
      })
      this.$routerPush({ name: 'profile' })
    },
  },
}
</script>
