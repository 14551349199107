<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 class="header-md mb-3 text-center">Congratulations {{ userProfile.first_name }}!</h1>
        <blw-illustration name="success" size="175" class="mb-4" />
        <h1 class="lead">You’ve been approved!</h1>
        <div class="w-100 mt-3">
          <v-btn @click="openApplicationFlowModal" color="primary" rounded block>
            Here's whats next
          </v-btn>
          <v-btn @click="openIntercom" outline rounded block class="mt-3">
            Chat with our team
          </v-btn>
          <v-btn @click="openWhyBungalowPage" outline rounded block class="mt-2">
            About Bungalow
          </v-btn>
          <v-btn @click="openReviewsPage" outline rounded block class="mt-2">
            Bungalow Reviews
          </v-btn>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReviewApproved',
  computed: {
    ...mapGetters('user', ['userProfile']),
  },
  methods: {
    openApplicationFlowModal() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          text: 'Whats next',
          location: 'applicationApproved',
        },
      })
      this.$root.$emit('openModal', {
        modalComponent: 'ApplyIntroductionModal',
        modalComponentProps: {
          customHeaderText: 'Congratulations!',
          customSubtitleText: 'Our team will reach out with lease details shortly.',
          buttonText: 'Got it',
          initialSlide: 4,
        },
      })
    },
    openIntercom() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          text: 'Chat with our team',
          location: 'applicationApproved',
        },
      })
      window.Intercom && window.Intercom('show')
    },
    openWhyBungalowPage() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'About Bungalow',
          CTA: 'Continue',
          category: 'Bungalow Info Approved',
          type: 'Button',
        },
      })
      window.open('https://bungalow.com/why-bungalow')
    },
    openReviewsPage() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'Bungalow Reviews',
          CTA: 'Continue',
          category: 'Bungalow Info Approved',
          type: 'Button',
        },
      })
      window.open('https://bungalow.com/reviews')
    },
  },
}
</script>
