<template>
  <v-container
    id="application-intro"
    :class="['px-4 py-4 h-100', { 'blur-future-steps': blurFutureSteps }]"
  >
    <v-layout>
      <v-flex xs12>
        <div class="header-1" v-text="title" />
        <div class="subtitle mt-2 mb-4" v-text="subtitle" />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
        xs12
        v-for="(step, i) in steps"
        :key="i"
        :class="[
          'mt-3 d-flex application-step',
          { complete: i + 1 < currentStep, 'current-step': i + 1 === currentStep },
        ]"
      >
        <div class="step">
          <div class="step-count mr-3 d-flex justify-center align-center label-3">
            <v-icon small color="white" v-if="i + 1 < currentStep">check</v-icon>
            <template v-else>
              {{ i + 1 }}
            </template>
          </div>
        </div>
        <div>
          <div class="header-sm mt-1 mb-2 regular-font" v-html="step.title" />
          <div class="font-large" v-html="step.description" />
          <template v-if="i + 1 === currentStep">
            <blw-button
              v-if="currentStepStartHandler"
              depressed
              class="mt-3 step-start-button"
              color="pink-gradient"
              @click="currentStepStartHandler"
            >
              Start
            </blw-button>
            <slot v-else />
          </template>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationIntro',
  props: {
    overrideSteps: {
      type: Array,
    },
    currentStep: {
      type: Number,
    },
    currentStepStartHandler: {
      type: Function,
    },
    blurFutureSteps: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('application', ['applicationInApply1_5Flow']),
    steps() {
      return (
        this.overrideSteps || [
          {
            title: 'Complete an application',
            description: 'Get approved for all Bungalow homes in less than five minutes',
          },
          {
            title: 'Create a profile',
            description:
              'Let us get to know you so your future roommates can get a sense of who you are',
          },
          {
            title: 'Chat with the roommates',
            description: this.applicationInApply1_5Flow
              ? 'You can reach out to as many homes as you like (for 24 hours each) to find a great fit!'
              : `Chat with the residents to make sure you've found a great fit`,
          },
          {
            title: 'Select your room and sign the lease',
            description: 'Confirm your room and lease details before signing your lease',
          },
        ]
      )
    },
  },
}
</script>

<style lang="scss">
#application-intro {
  height: calc(100vh - 80px);
  width: 432px;
  @include mobile {
    width: auto;
    height: calc(100vh - 60px);
  }
  .header-1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 18px;
  }
  &.blur-future-steps {
    .application-step {
      opacity: 1;
    }
  }
  .step {
    flex: 0 !important;
  }
  .step-count {
    font-size: 18px;
    border: 1px solid $charcoal-10;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background-color: white;
  }
  .application-step {
    &.current-step {
      opacity: 1;
    }
    &.complete {
      opacity: 1;
      .subheader-sm {
        display: none;
      }
      .step-count {
        background: green;
        border-color: green;
      }
    }
  }
  .step-start-button {
    width: 200px;
    height: 36px;
  }
}
</style>
