<template>
  <div>
    <loading-spinner full-page v-if="loading" />
    <template v-else>
      <section class="payment-screen-header">
        <img
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mb-3' : 'mt-5 mb-4'"
          :width="$vuetify.breakpoint.smAndDown ? 140 : 180"
          src="https://assets.bungalow.com/illustrations/full-color/income-report.png"
        />
        <h1 class="header-xl font-black mb-2">Pay Application Fee</h1>
        <p class="font-xl px-3 pb-2" v-max-width="$vuetify.breakpoint.smAndDown ? 360 : 475">
          There's a one-time fee of <b>${{ pendingFee }} USD</b> required in order to cover the cost
          of processing your application.
        </p>
      </section>
      <blw-form
        class="mx-auto pb-5"
        v-max-width="500"
        form-name="payment-form"
        button-label="Submit Application"
        :on-submit="onSubmit"
        :updating="updating"
        :error="error && error.message"
        :errorDisplayDuration="0"
      >
        <v-layout row>
          <v-flex xs12>
            <div id="card-number" />
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <div id="card-expiry" />
          </v-flex>

          <v-flex xs12>
            <div id="card-cvc" />
          </v-flex>
        </v-layout>

        <div id="card-errors" role="alert" />
      </blw-form>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { isEmpty as _isEmpty, get as _get } from 'lodash'
import { differenceInMonths, differenceInDays } from 'date-fns'

import LoadingSpinner from '@/shared/LoadingSpinner'

const getElementsStyle = () => ({
  style: {
    base: {
      color: '#241F21',
      fontFamily: '"AkkuratPro", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '17px',
      '::placeholder': {
        color: '#C8C7C7',
      },
    },
    invalid: {
      color: '#F44336',
      iconColor: '#F44336',
    },
  },
})

export default {
  name: 'SubmitPayment',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      mounted: false,
      loading: false,
      elementsList: [],
      elementsReadyState: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
      },
      disabledElements: false,
      stripe: {},
      cosignerRoute: this.$route.name === 'cosigner-payment',
    }
  },
  async mounted() {
    this.mounted = true
    this.loading = true
    await this.fetchLatestApplicationAndEntityInfo()
    await this.maybeSubmitApplication()
    this.loading = false
    this.mounted && this.$nextTick(this.createCard)
  },
  beforeDestroy() {
    this.mounted = false
    this.fetchLatestApplicationAndEntityInfo()
  },
  computed: {
    ...mapGetters('application', [
      'application',
      'applicationId',
      'applicationMarketCountryCode',
      'applicantInfoId',
      'applicationFee',
      'applicationChargeStatus',
      'applicationSourceSlug',
      'isGroupApplication',
    ]),
    ...mapGetters('applicant', ['applicant', 'backgroundCheck']),
    ...mapGetters('cosigner', [
      'cosigner',
      'cosignerId',
      'cosignerFee',
      'cosignerMarketCountryCode',
      'cosignerChargeStatus',
      'cosignerBackgroundCheck',
    ]),
    ...mapGetters({
      cosignerFeeIsWaived: 'cosigner/applicationFeeIsWaived',
      applicationFeeWaived: 'application/applicationFeeIsWaived',
    }),
    ...mapGetters('submit', ['getSecureIdNumber', 'getSkipSsnEntry', 'error', 'apiError']),
    ...mapFields('submit', ['updating']),
    pendingFee() {
      return this.cosignerRoute ? this.cosignerFee : this.applicationFee
    },
    headerText() {
      return `
        There's a one-time fee of <b>$${this.pendingFee}</b>.
      `
    },
    entity() {
      return this.cosignerRoute ? 'Cosigner' : 'Applicant'
    },
    entityChargeStatus() {
      return this.cosignerRoute ? this.cosignerChargeStatus : this.applicationChargeStatus
    },
    entityBackgroundCheck() {
      return this.cosignerRoute ? this.cosignerBackgroundCheck : this.backgroundCheck
    },
    entityApplciationFeeIsWaived() {
      return this.cosignerRoute ? this.cosignerFeeIsWaived : this.applicationFeeWaived
    },
    noOutstandingPayment() {
      const paymentMade = this.entityChargeStatus === 'success'
      const noPendingFee = this.pendingFee <= 0
      return paymentMade || noPendingFee || this.entityApplciationFeeIsWaived
    },
    noOutstandingBackgroundCheck() {
      return !!this.entityBackgroundCheck
    },
  },
  methods: {
    ...mapActions('submit', [
      'submitCharge',
      'submitBackgroundCheck',
      'submitApplication',
      'submitCosigner',
    ]),
    ...mapActions('application', ['fetchApplicationBySource']),
    ...mapActions('applicant', ['fetchApplicant']),
    ...mapActions('applicationSource', ['fetchApplicationSource']),
    ...mapActions('cosigner', ['fetchCosignerBySource']),
    ...mapActions('groupApplicationSource', ['fetchGroupApplicationSource']),
    async fetchLatestApplicationAndEntityInfo() {
      const { cosignerSource, applicationSource } = this.$route.params
      this.updating = true
      if (this.cosignerRoute) {
        await this.fetchCosignerBySource(cosignerSource)
      } else {
        await this.fetchApplicationBySource(applicationSource)
        await this.fetchApplicant()
        await this.fetchApplicationSource(this.applicationSourceSlug)
      }
      this.updating = false
    },
    setElementsReadyState({ elementType, complete }) {
      this.elementsReadyState[elementType] = complete
    },
    createCard() {
      let elements
      let cardNumber
      let cardExpiry
      let cardCvc
      let stripeApiKey

      const { VUE_APP_STRIPE_KEY_US, VUE_APP_STRIPE_KEY_CA } = process.env
      const countryCode = this.cosignerRoute
        ? this.cosignerMarketCountryCode
        : this.applicationMarketCountryCode

      stripeApiKey = countryCode === 'US' ? VUE_APP_STRIPE_KEY_US : VUE_APP_STRIPE_KEY_CA

      this.stripe = window.Stripe(stripeApiKey)

      elements = this.stripe.elements()

      cardNumber = elements.create('cardNumber', getElementsStyle())
      cardNumber.mount('#card-number')
      cardNumber.on('change', this.setElementsReadyState)

      cardExpiry = elements.create('cardExpiry', getElementsStyle())
      cardExpiry.mount('#card-expiry')
      cardExpiry.on('change', this.setElementsReadyState)

      cardCvc = elements.create('cardCvc', getElementsStyle())
      cardCvc.mount('#card-cvc')
      cardCvc.on('change', this.setElementsReadyState)

      this.elements = elements
      this.elementsList = [cardNumber, cardExpiry, cardCvc]
    },
    toggleCards() {
      this.disabledElements = !this.disabledElements

      this.elementsList.forEach((element) => {
        element.update({ disabled: this.disabledElements })
      })
    },
    async attemptPaymentSubmission(stripe_token) {
      this.toggleCards()

      const payload = {
        stripe_token,
        target_id: this.cosignerRoute ? this.cosignerId : this.applicationId,
        target_type: this.cosignerRoute ? 'cosigner' : 'application',
      }
      // Attempt Payment
      await this.submitCharge(payload)
      // Record outcome of payment attempt
      this.$trackEvent({
        action: `${this.entity} Payment ${this.error ? 'Failure' : 'Success'}`,
        properties: this.error
          ? {
              fee: this.pendingFee,
              error: this.error,
            }
          : { fee: this.pendingFee },
      })

      this.toggleCards()
      const paymentSuccessful = !this.error
      return paymentSuccessful
    },
    async attemptBackgroundCheckSubmission() {
      const payload = {
        target_id: this.cosignerRoute ? this.cosignerId : this.applicantInfoId,
        target_type: this.cosignerRoute ? 'cosigner' : 'applicant_info',
      }
      if (this.getSecureIdNumber) {
        payload.sin_ssn = this.getSecureIdNumber
      }
      await this.submitBackgroundCheck(payload)
      // Record outcome of background check
      this.$trackEvent({
        action: `${this.entity} Background Check ${this.error ? 'Failure' : 'Success'}`,
        properties: this.error
          ? {
              error: this.error,
            }
          : {},
      })
      const backgroundCheckSuccessful = !this.error
      return backgroundCheckSuccessful
    },
    async attemptToSubmitApplication() {
      this.cosignerRoute ? await this.submitCosigner() : await this.submitApplication()
      this.$trackEvent({
        action: `${this.entity} Submission ${this.error ? 'Failure' : 'Success'}`,
        properties: this.error
          ? {
              fee: this.pendingFee,
              error: this.error,
            }
          : {},
      })
      const applicationSubmissionSuccessful = !this.error
      return applicationSubmissionSuccessful
    },
    async submitApplicationSuccessHandler() {
      const market = _get(this.application, 'market.display_name')
      const neighborhood = _get(this.application, 'property.address.neighborhood')
      const property = _get(this.application, 'property.id')
      const room = _get(this.application, 'room')
      const moveIndate = _get(this.application, 'move_in_date')
      const leaseEndDate = _get(this.application, 'lease_end_date')
      if (this.application.group_source) {
        this.$trackEvent({
          action: `Homes ${this.entity} Application Submitted`,
          properties: {
            fee: this.pendingFee,
            market,
            neighborhood,
            property,
            room,
            moveIndate,
            leaseLength: differenceInMonths(leaseEndDate, moveIndate),
            daysToMoveIn: differenceInDays(moveIndate, new Date()),
          },
        })
      } else {
        this.$trackEvent({
          action: `Rooms ${this.entity} Application Submitted`,
          properties: {
            fee: this.pendingFee,
            market,
            neighborhood,
            property,
            room,
            moveIndate,
            leaseLength: differenceInMonths(leaseEndDate, moveIndate),
            daysToMoveIn: differenceInDays(moveIndate, new Date()),
          },
        })
      }

      // If this is a homes application fork to that UI instead:
      if (this.isGroupApplication) {
        // Refetch group application data to ensure groups status and display state:
        await this.fetchGroupApplicationSource(this.isGroupApplication)
        // Fork to group UI flow
        return this.$router.replace({ name: 'applicationInReview' })
      }

      // Proceed to rooms flow:
      return this.$routerReplace({
        name: this.cosignerRoute ? 'cosigner-submitSuccess' : 'submitSuccess',
      })
    },
    async onSubmit() {
      this.updating = true
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          CTA: 'Submit payment',
          text: 'Submit payment',
          category: 'Apply',
          entity: this.entity,
        },
      })
      const [cardNumber] = this.elementsList

      try {
        const stripeTokenReponse = await this.stripe.createToken(cardNumber)
        if (_isEmpty(stripeTokenReponse.token)) {
          this.$trackEvent({
            action: `${this.entity} Stripe Token Creation Failure - No Token Returned`,
            properties: {
              error: stripeTokenReponse,
            },
          })
        } else {
          // Attempt Payment
          const paymentSuccessful = this.noOutstandingPayment
            ? true
            : await this.attemptPaymentSubmission(stripeTokenReponse.token.id)
          // if payment failure, show error
          if (!paymentSuccessful) return this.fetchLatestApplicationAndEntityInfo()

          // Attempt background check submission
          const backgroundCheckSuccessful = this.noOutstandingBackgroundCheck
            ? true
            : await this.attemptBackgroundCheckSubmission()
          // if background check submission failure show error
          if (!backgroundCheckSuccessful) return this.fetchLatestApplicationAndEntityInfo()

          // Payment and background check success, attempt to submit application
          const applicationSubmissionSuccessful = await this.attemptToSubmitApplication()

          // application submission error
          if (applicationSubmissionSuccessful) {
            this.submitApplicationSuccessHandler()
          } else {
            return this.$routerReplace({
              name: this.cosignerRoute ? 'cosigner-submitFailed' : 'submitFailed',
            })
          }
        }
      } catch (error) {
        this.$trackEvent({
          action: `${this.entity} Stripe Token Creation Failure`,
          properties: {
            error,
          },
        })
      }
      this.updating = false
    },
    async maybeSubmitApplication() {
      if (this.noOutstandingPayment) {
        // Attempt background check submission
        const backgroundCheckSuccessful = this.noOutstandingBackgroundCheck
          ? true
          : await this.attemptBackgroundCheckSubmission()
        // if background check submission failure show error
        if (!backgroundCheckSuccessful) {
          return this.$routerReplace({
            name: this.cosignerRoute ? 'cosigner-submitFailed' : 'submitFailed',
          })
        }

        // Payment and background check success, attempt to submit application
        const applicationSubmissionSuccessful = await this.attemptToSubmitApplication()

        // application submission error
        if (applicationSubmissionSuccessful) {
          this.submitApplicationSuccessHandler()
        } else {
          return this.$routerReplace({
            name: this.cosignerRoute ? 'cosigner-submitFailed' : 'submitFailed',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.payment-screen-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: $salmon-80;
}

.StripeElement--webkit-autofill {
  background-color: $cream !important;
}
</style>
