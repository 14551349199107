<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'ApplicationContainer',
  data() {
    return {
      loading: false,
    }
  },
  components: { LoadingSpinner },
  computed: {
    ...mapGetters('application', [
      'application',
      'applicationStatus',
      'isGroupApplication',
      'applicationSourceSlug',
      'displayState',
    ]),
    ...mapGetters('applicationSource', ['applicationSource']),
    ...mapGetters('applicant', ['applicant']),
    ...mapGetters('user', ['userId']),
  },
  methods: {
    ...mapActions('application', ['fetchApplicationBySource', 'getSectionByStatus']),
    ...mapActions('applicationSource', ['fetchApplicationSource']),
    ...mapActions('groupApplicationSource', ['fetchGroupApplicationSource']),
    ...mapActions('applicant', ['fetchApplicant']),
    ...mapActions('user', ['fetchProfile']),
    async ensureCorrectSection(route) {
      // Check if a new destination is required based on application status
      let newDestination = await this.getSectionByStatus(route)

      if (this.isGroupApplication && this.applicationStatus === 'submitted') {
        return this.$routerReplace({ name: 'groupStatus' })
      }

      // Lock funnel 2.0 to submit success
      if (this.applicationStatus === 'submitted' && this.displayState.is_funnel_20) {
        return this.$routerReplace({ name: 'submitSuccess' })
      }

      // Lead can complete the profile section of the application before being pre-approved
      if (this.applicationStatus === 'submitted' && this.displayState.profileCompleted) {
        return this.$routerReplace({ name: 'applicationInReview' })
      }

      if (this.applicationStatus === 'preapproved' && this.displayState.profileCompleted) {
        return this.$routerReplace({
          name: 'profileView',
          params: { userId: this.userId },
        })
      }

      // Navigate to the new route (if not already there)
      if (newDestination && newDestination !== route.name) {
        // emails are sent out which includes the directly link to the chat
        // if application status is meet and greet and the url is valid
        // allow the natural navigation to the chat
        if (
          this.applicationStatus === 'meet_and_greet' &&
          route.name === 'meetGreetChat' &&
          route.query.meetingId
        )
          // allow the chat to be loaded directly
          return

        const { params, meta, query } = route

        this.$routerReplace({ name: newDestination, params, meta, query })
      }
    },
  },
  watch: {
    $route(route) {
      if (!this.application && route.name !== 'applicationNotFound') {
        return this.$routerPush({ name: 'applicationNotFound' })
      }
    },
  },
  async beforeMount() {
    this.loading = true
    const { applicationSource } = this.$route.params

    // Fetch application by source (verifies source is valid)
    await this.fetchApplicationBySource(applicationSource)

    // Application found, proceed...
    if (this.application) {
      // Fetch the application data we'll need
      await this.fetchApplicationSource(this.applicationSourceSlug)
      await this.fetchApplicant()
      // If this is a group application, fetch the source
      if (this.applicationSource.group_source)
        await this.fetchGroupApplicationSource(this.applicationSource.group_source)
    } else {
      if (this.$route.name !== 'applicationNotFound')
        this.$routerPush({ name: 'applicationNotFound' })
    }

    // Redirect to correct section based on application status
    await this.ensureCorrectSection(this.$route)

    this.loading = false
  },
}
</script>
