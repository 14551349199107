import Vue from 'vue'
import packageJson from '../../package.json'

const defaultIntegrationOptions = {
  Salesforce: false,
  Intercom: false,
}

const SegmentEventTrackingPlugin = {
  install(Vue) {
    Vue.prototype.$segment = {
      track(event, properties = {}, options, callback) {
        try {
          if (!window.analytics) return
          window.analytics.track(
            event,
            {
              version: packageJson.version,
              ...properties,
            },
            options,
            callback
          )
        } catch {}
      },
    }
    Vue.prototype.$trackEvent = function ({ action, properties = {}, options = {} }) {
      const proxiesUserEmail = this.$store.getters['proxy/proxyUserEmail']
      properties.version = packageJson.version
      if (proxiesUserEmail) {
        properties.proxy = true
        properties.proxiesUserEmail = proxiesUserEmail
      }
      try {
        window.analytics.track(action, properties, {
          ...defaultIntegrationOptions,
          ...options,
          app: {
            version: `apply@${packageJson.version}`,
          },
        })
      } catch (e) {
        // console.log('Segment Event Tracking Error: ', e);
      }
    }
    Vue.prototype.$identify = ({ userId, userProperties, options = {} }) => {
      try {
        window.analytics.identify(userId, userProperties, {
          ...defaultIntegrationOptions,
          ...options,
          app: {
            version: `apply@${packageJson.version}`,
          },
        })
      } catch (e) {
        // console.log('Segment Identify Error: ', e);
      }
    }
    Vue.prototype.$trackPageChange = function ({ name, properties = {} }) {
      properties.version = packageJson.version
      const proxiesUserEmail = this.$store.getters['proxy/proxyUserEmail']
      if (proxiesUserEmail) {
        properties.proxy = true
        properties.proxiesUserEmail = proxiesUserEmail
      }
      try {
        window.analytics.page(name, properties, {
          app: {
            version: `apply@${packageJson.version}`,
          },
        })
      } catch (e) {
        // console.log('Segment page tracking error: ', e);
      }
    }
  },
}

Vue.use(SegmentEventTrackingPlugin)
