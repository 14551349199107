<template>
  <v-layout id="additional-info">
    <v-flex>
      <blw-sections class="mb-3" :contents="sections" squareCheckBox />
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import PropertyRoomShare from '@/routes/applicant/components/PropertyRoomShare'
import PropertyAnimal from '@/routes/applicant/components/PropertyAnimal'

export default {
  name: 'PropertyMoreInfo',
  data() {
    return {
      sections: [],
    }
  },
  beforeMount() {
    this.buildSectionData()
  },
  computed: {
    ...mapGetters('applicationFlags', [
      'vehicleFlag',
      'smokerFlag',
      'petFlag',
      'roommateFlag',
      'error',
      'updating',
    ]),
    ...mapGetters('application', ['isGroupApplication']),
  },
  methods: {
    ...mapActions('applicationFlags', ['toggleApplicationFlag']),
    buildSectionData() {
      this.sections = this.isGroupApplication
        ? [
            {
              component: PropertyAnimal,
              props: { box: true },
              title: "I'm bringing my pet(s)",
              onToggle: () => {
                this.toggleApplicationFlag('petFlag')
                _.get(this.petFlag, 'expanded') && this.savedFieldLog('Bringing pets')
              },
              expanded: _.get(this.petFlag, 'expanded'),
            },
          ]
        : [
            {
              component: PropertyRoomShare,
              props: { box: true },
              title: 'I’m sharing my room',
              onToggle: () => {
                this.toggleApplicationFlag('roommateFlag')
                _.get(this.roommateFlag, 'expanded') && this.savedFieldLog('Sharing room')
              },
              expanded: _.get(this.roommateFlag, 'expanded'),
            },
            {
              component: PropertyAnimal,
              props: { box: true },
              title: "I'm bringing my pet(s)",
              onToggle: () => {
                this.toggleApplicationFlag('petFlag')
                _.get(this.petFlag, 'expanded') && this.savedFieldLog('Bringing pets')
              },
              expanded: _.get(this.petFlag, 'expanded'),
            },
          ]
    },
    savedFieldLog(field) {
      this.$trackEvent({
        action: 'Field Saved',
        properties: {
          type: 'Application Page',
          text: field,
        },
      })
    },
  },
}
</script>
<style lang="scss">
#additional-info {
  .v-expansion-panel__header {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .v-expansion-panel {
    box-shadow: none;
  }
  .blw-sections .v-expansion-panel__header {
    min-height: initial;
  }
  .v-input--checkbox .v-input__slot {
    background: white;
  }
  .blw-sections > .container {
    padding: 0;
  }
  .v-expansion-panel__container {
    border: none;
  }
}
</style>
