<template>
  <div class="blw-sections white">
    <v-container>
      <v-expansion-panel v-bind="{ ...$props, ...$attrs }" v-model="panels">
        <v-expansion-panel-content
          v-for="(content, index) of contents"
          :key="`content_${index}`"
          :disabled="content.disabled"
        >
          <template v-slot:actions>
            <v-checkbox v-if="squareCheckBox" :value="panels[index]" class="ma-0" />
            <v-icon v-else color="grey" size="25" :class="panelState(index)">{{
              icon[panelState(index)]
            }}</v-icon>
          </template>

          <template v-slot:header>
            <div class="header px-3">
              <h5 class="font-large font-bold mb-1">
                {{ content.title }}
              </h5>
              <span class="charcoal-40--txt font-base" v-if="content.subtitle">
                {{ content.subtitle }}
              </span>
            </div>
          </template>

          <v-card>
            <component :is="content.component" v-bind="content.props" />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'BlwSections',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: true,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    popout: {
      type: Boolean,
      default: false,
    },
    contents: {
      type: Array,
    },
    squareCheckBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panels: this.initialPanels(),
      icon: {
        open: 'check_circle',
        closed: 'radio_button_unchecked',
      },
    }
  },
  watch: {
    panels(prevState, nextState) {
      const toggledPanel = prevState.reduce((toggled, panel, index) => {
        // Compare arrays of panel states, the mismatch was toggled
        if (panel != nextState[index]) toggled = index

        return toggled
      }, null)

      // Fire the onToggled callback, if it was provided
      if (this.contents[toggledPanel].onToggle) this.contents[toggledPanel].onToggle()
    },
  },
  methods: {
    panelState(index) {
      return this.panels[index] ? 'open' : 'closed'
    },
    initialPanels() {
      return this.contents.map((panel) => panel.expanded || false)
    },
  },
}
</script>

<style lang="scss">
.blw-sections {
  background: white;

  .v-input--checkbox label {
    font-size: 13px;
  }

  .header-subtitle {
    color: $text-lighter;
  }

  .v-expansion-panel {
    box-shadow: none !important;

    .v-icon {
      border: 3px solid transparent;
      border-radius: 100%;
    }
  }

  .v-expansion-panel__header {
    padding: 0.25rem !important;
    min-height: 100px;
    user-select: none;

    label {
      cursor: pointer;
    }
  }

  .v-expansion-panel__container--active,
  .v-expansion-panel__container--active .section-subtitle {
    .header-title {
      color: $blue-60 !important;
    }
  }

  // Remove Icon Animation
  .v-expansion-panel__container--active
    > .v-expansion-panel__header
    .v-expansion-panel__header__icon
    .v-icon {
    transform: none !important;

    &.open {
      color: $blue-60 !important;
    }
  }

  // Focus State
  .v-expansion-panel--focusable .v-expansion-panel__container {
    &:focus {
      background: inherit !important;
    }
  }
  .v-expansion-panel__header__icon {
    .v-input__control {
      .v-input__slot {
        margin-bottom: 0px !important;
      }
      .v-messages {
        display: none;
      }
    }
  }
}
</style>
