<template>
  <div class="login-page">
    <div :class="['overlay', { updating }]" />
    <v-container>
      <div class="login-page__header">
        <bungalow-logo class="login-page__logo" />
        <h1 class="header-md mt-4" v-html="headlineCopy" />
        <p v-max-width="320" v-if="isCosigner" class="pt-3 font-small">
          This application can only be opened by one individual. Please do not share this link with
          anyone else, or you will be unable to access the application!
        </p>
        <p v-else class="pt-3">Login so we can save your progress</p>
      </div>
      <div class="login-page__inner">
        <img
          class="login-page__illustration"
          src="https://assets.bungalow.com/illustrations/full-color/five-plants.png"
        />
        <login-buttons />
      </div>
      <p class="login-page__disclaimer">
        By clicking Continue with Google, you agree to Bungalow’s
        <a href="https://bungalow.com/terms-of-use" target="_blank">Terms of Use</a>&nbsp;and
        <a href="https://bungalow.com/privacy-policy" target="_blank">Privacy Policy</a>.
      </p>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginButtons from './LoginButtons'
import BungalowLogo from '@/assets/svg/logo_wordmark.svg'

export default {
  name: 'Login',
  components: {
    LoginButtons,
    BungalowLogo,
  },
  data() {
    return {
      isCosigner: false,
    }
  },
  computed: {
    ...mapGetters('user', ['updating']),
    headlineCopy() {
      return this.isCosigner ? 'Co-signer Application' : 'Resident Application'
    },
  },
  beforeMount() {
    const redirectUrl = window.localStorage.getItem('redirectRoute')

    if (redirectUrl) this.isCosigner = redirectUrl.includes('/cosign?source=')
  },
}
</script>

<style lang="scss">
.login-page {
  @include pageFixedHeight;
  min-height: 700px;
  background: $cream;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;

    &.updating {
      height: 100%;
      width: 100%;
      z-index: 9999999;
      background: rgba($charcoal-60, 0.4);
    }
  }

  @include mobile {
    min-height: 500px;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 130px;
    padding-bottom: 40px;

    // Support v-small screens:
    @media (max-width: 450px) {
      padding-top: 40px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 350px) {
      flex: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  &__logo {
    width: 240px;

    @include mobile {
      width: 165px;
    }
  }

  &__illustration {
    width: 240px;
    object-fit: contain;
    margin: 2rem 0;

    @include mobile {
      width: 165px;
    }

    @media (max-width: 350px) {
      display: none;
    }
  }

  &__disclaimer {
    font-size: 13px;
    text-align: center;
    margin: auto;

    @media (max-width: 350px) {
      margin-bottom: 0 !important;
    }

    a {
      color: $blue-80;
      text-decoration: underline;
      font-weight: bold;

      &:focus,
      &:hover {
        outline: 0;
      }
    }
  }
}
</style>
