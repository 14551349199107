<template>
  <v-textarea
    class="blw-control blw-textarea"
    @input="$emit('input', $event)"
    v-bind="{ ...$props, ...$attrs }"
  />
</template>

<script>
export default {
  name: 'BlwTextArea',
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    'auto-grow': {
      type: Boolean,
      default: true,
    },
  },
}
</script>
