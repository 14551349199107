<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 class="header-1 mb-5">Oops!</h1>
        <blw-illustration name="puzzle" size="175" class="mb-4" />
        <h3 v-max-width="350" class="font-large accent-font text-xs-center">
          Page not found, let's serve up something fresh!
        </h3>
      </div>
      <blw-button class="mt-5" size="lg" label="View your applications" @click="goToApplication" />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goToApplication() {
      this.$routerPush({ name: 'applications' })
    },
  },
}
</script>
