<template>
  <loading-spinner full-page v-if="loading" />
  <div v-else class="profile-view" v-max-width="560">
    <v-container :class="{ 'nav-hidden': navHidden }">
      <blw-button v-if="showGoBack" class="go-back" color="charcoal" icon flat @click="goBack">
        <v-icon color="charcoal">close</v-icon>
      </blw-button>
      <div v-if="self" class="can-edit">
        <blw-button round icon class="shadow-tight" color="white" @click="editProfile">
          <v-icon color="charcoal">edit</v-icon>
        </blw-button>
      </div>
      <div class="profile-photo">
        <blw-avatar class="mx-auto" :avatar="profile_image_url" size="150" />
        <div class="pet-icon round shadow-tight" v-if="pets.length">
          <blw-icon icon="pets" color="salmon" />
        </div>
      </div>
      <v-layout column align-center class="text-xs-center">
        <h1 class="header-md mt-3 mb-2">
          {{ first_name }} {{ last_name }}
          <div v-for="(pet, index) in pets" :key="index">
            <span v-if="pet.animal_name">& {{ pet.animal_name }}</span>
            <span v-if="pet.animal"> (the {{ pet.animal }})</span>
          </div>
        </h1>
        <small class="mb-4">
          {{ jobTitle }} <span v-if="jobTitle && employerName">@</span> {{ employerName }}
        </small>
        <v-icon v-if="bio" class="quote-icon mt-3" size="26" color="charcoal">format_quote</v-icon>
        <p v-max-width="295" class="bio-text accent-font mt-1 mb-5">
          {{ bio }}
        </p>
      </v-layout>
    </v-container>
    <!-- Preferences -->
    <div v-if="!self && overlaps.preferences && overlaps.preferences.length">
      <overlap-preferences
        :preferences="overlaps.preferences"
        :theirProfileImage="profile_image_url"
      />
    </div>
    <!-- Interests -->
    <v-container v-if="interests.length" class="interests-container">
      <v-layout column>
        <template v-if="hasSharedInterestOverlap">
          <div v-if="sharedInterests.length">
            <h2 class="lead text-left">Shared Interests</h2>
            <interests-grid list read-only :interests="sharedInterests" />
          </div>
          <div class="mt-4" v-if="otherInterests">
            <h2 class="lead text-left">{{ sharedInterests.length ? 'Other ' : '' }}Interests</h2>
            <interests-grid list read-only :interests="otherInterests" />
          </div>
        </template>
        <template v-else>
          <h2 class="lead text-left">Interests</h2>
          <interests-grid list read-only :interests="interests" />
        </template>
      </v-layout>
    </v-container>

    <footer v-if="self && !showGoBack" class="sticky-bottom-footer">
      <v-layout justify-center>
        <blw-button :loading="loading" size="md" label="Continue" @click="onContinue" />
      </v-layout>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InterestsGrid from '../components/InterestsGrid'
import OverlapPreferences from '../components/OverlapPreferences'

import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'ProfileView',
  components: { InterestsGrid, OverlapPreferences, LoadingSpinner },
  props: {
    // Profile
    first_name: {
      type: String,
    },
    last_name: {
      type: String,
    },
    interests: {
      type: Array,
    },
    living_preferences: {
      type: Array,
    },
    employment_history: {
      type: Array,
      default: () => [],
    },
    profile_image_url: {
      type: String,
      default: '',
    },
    bio: {
      type: String,
    },
    pets: {
      type: Array,
      default: () => [],
    },
    overlaps: {
      type: Object,
      default: () => ({
        interests: [],
        preferences: [],
      }),
    },
    // Flags
    self: {
      type: Boolean,
      default: false,
    },
    preferences: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
    // Actions
    submitProfile: {
      type: Function,
      default: () => {},
    },
    editRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  beforeMount() {
    if (this.$route.name === 'profileView' && !this.$route.query.roommatePreview) this.onContinue()
  },
  computed: {
    ...mapGetters('application', ['applicationStatus', 'displayState']),
    showGoBack() {
      return this.$route.name !== 'profileView' || this.$route.query.roommatePreview
    },
    navHidden() {
      return this.$route.meta.hideNav
    },
    jobTitle() {
      const employmentRecord = this.employment_history[0] || {}
      return employmentRecord.title
    },
    employerName() {
      const employmentRecord = this.employment_history[0] || {}
      return employmentRecord.employer
    },
    shouldSubmitApplication() {
      const correctStatus = this.status === 'preapproved'
      return this.self && correctStatus
    },
    hasSharedInterestOverlap() {
      return this.sharedInterests.length || this.otherInterests.length
    },
    interestOverlapIds() {
      return this.overlaps.interests.map((interest) => interest.id)
    },
    sharedInterests() {
      return this.interests.filter((interest) => this.interestOverlapIds.includes(interest.id))
    },
    otherInterests() {
      return this.interests.filter((interest) => !this.interestOverlapIds.includes(interest.id))
    },
  },
  methods: {
    ...mapActions('application', ['updateApplicationByKey']),
    editProfile() {
      this.$trackEvent({
        action: 'Edit Profile',
        properties: {
          location: 'Profile View (top right)',
          color: 'White',
          CTA: 'pencil',
          category: 'Profile',
          type: 'Button',
        },
      })

      this.$routerPush({ name: this.editRoute })
    },
    async onContinue() {
      this.loading = true
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Profile View',
          color: 'Salmon',
          CTA: 'Continue',
          category: 'Profile',
          type: 'Button',
        },
      })

      if (this.shouldSubmitApplication) {
        // Submit application and re-fetch (using source from url params)
        await this.submitProfile(this.$route.params.applicationSource)
      }

      await this.updateApplicationByKey({
        field: 'display_state',
        value: {
          ...this.displayState,
          profileCompleted: true,
        },
      })

      this.loading = false
      this.$routerPush({ name: 'profileComplete' })
    },
    goBack() {
      this.$trackEvent({
        action: 'Go Back',
        properties: {
          location: 'Profile View (top left)',
          color: 'White',
          CTA: 'X icon',
          category: 'Profile',
          type: 'Icon',
        },
      })

      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.profile-view {
  position: relative;
  margin: 3.75rem auto;

  .go-back {
    position: absolute;
    left: 1.75rem;
    background: white;
    @include boxShadow($tight: true);
  }

  .can-edit {
    position: absolute;
    right: 1.75rem;
  }

  .nav-hidden {
    padding-top: 1rem;

    .can-edit,
    .go-back {
      top: -1.5rem;
    }
  }

  .quote-icon {
    transform: rotate(180deg);
  }

  .bio-text {
    font-size: 18px;
    line-height: 28px;
  }

  .profile-photo {
    margin: auto;
    margin-bottom: 2rem;
    position: relative;
    width: 150px;
    height: 150px;

    .pet-icon {
      background-color: $salmon-20;
      padding: 8px;
      position: absolute;
      right: -8px;
      bottom: -2px;

      .blw-icon {
        zoom: 0.75;
      }
    }
  }

  .interests-container {
    margin-bottom: 2rem;

    .lead {
      margin-bottom: 1rem;
    }

    @media (max-width: 500px) {
      .lead {
        padding: 0 1rem;
      }

      padding: 0 !important;
      max-width: 100%;
      width: 100%;
    }
  }
}
</style>
