<template>
  <main class="grid full-height">
    <v-layout align-center justify-center column>
      <blw-hero
        :iconComponent="IncomeSvg"
        header="Tell us about your job"
        text="Have your proof of income ready!"
      />
    </v-layout>

    <section>
      <v-container>
        <v-layout justify-center>
          <v-flex sm7 text-xs-center>
            <p
              v-if="buttonSubText"
              v-html="buttonSubText"
              class="font-small grey--text mb-3 text-xs-center"
            />
            <blw-button class="mb-3" label="Continue" @click="onCreateIncome" />
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IncomeSvg from '@/assets/icons/income.svg'

export default {
  name: 'IncomeIntro',
  data() {
    return {
      IncomeSvg,
    }
  },
  props: {
    updateEntityFunction: {
      type: Function,
      required: true,
    },
    nextPath: {
      type: String,
      required: true,
    },
    buttonSubText: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('income', ['income', 'error']),
  },
  methods: {
    ...mapActions('income', ['createIncome']),

    async onCreateIncome() {
      await this.createIncome()

      if (!this.error) {
        this.updateEntityFunction({ field: 'income', value: this.income.id })

        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Splash screen',
            CTA: 'Continue',
            category: 'Employment Information',
            type: 'Button',
          },
        })

        this.$routerPush({ name: this.nextPath })
      }
    },
  },
}
</script>
