<template>
  <div class="login-buttons">
    <button
      block
      v-for="provider in featuredProviders"
      :key="provider.key"
      :class="['login-buttons__button featured', provider.key]"
      @click="loginWithProvider(provider.key)"
    >
      <component :is="provider.component" />
      <div class="login-buttons__button-text">Continue with {{ provider.name }}</div>
    </button>
    <button class="login-buttons__button text-only more" @click="viewMoreOptions">
      <div class="login-buttons__button-text">More options</div>
    </button>

    <v-snackbar multi-line :value="oauthError">{{ oauthError }}</v-snackbar>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import LoginMoreOptionsModal from './LoginMoreOptionsModal'
import GoogleLogo from '@/assets/oauth/google_logo.svg'
import FacebookLogo from '@/assets/oauth/facebook_logo.svg'
import LinkedinLogo from '@/assets/oauth/linkedin_logo.svg'
import AppleLogo from '@/assets/oauth/apple_logo.svg'

export default {
  name: 'LoginButtons',
  data() {
    return {
      featuredProviders: [{ key: 'google', name: 'Google', component: GoogleLogo }],
      otherProviders: [
        // { key: 'facebook', name: 'Facebook', component: FacebookLogo },
        { key: 'apple', name: 'Apple', component: AppleLogo },
        { key: 'linkedin', name: 'LinkedIn', component: LinkedinLogo },
      ],
      errorMessage: 'An error occurred attempting to login, please try again.',
    }
  },
  computed: {
    ...mapGetters('user', ['error']),
    oauthError() {
      if (!this.error) return

      const friendlyError = _.get(this.error, 'non_field_errors', [this.errorMessage])
        .map((errorMsg) => errorMsg)
        // Add space between error messages
        .join(' ')

      return friendlyError
    },
  },
  methods: {
    ...mapActions('user', ['authenticate']),
    async loginWithProvider(providerKey) {
      // Close login options window (if open)
      if (this.otherProviders.map((provider) => provider.key).includes(providerKey))
        this.$root.$emit('closeModal')
      // Login /w padlock:
      await this.authenticate(providerKey)
      // Continue or redirect:
      if (!this.error) this.redirectAfterSuccesfulAuth()
    },
    viewMoreOptions() {
      if (!this.isMobile) {
        this.$root.$emit('openModal', {
          modalComponent: LoginMoreOptionsModal,
          modalSize: 'small',
          modalComponentProps: {
            providers: this.otherProviders,
            authenticate: this.loginWithProvider,
          },
        })
      }
    },
    redirectAfterSuccesfulAuth() {
      const redirectRoute = localStorage.getItem('redirectRoute')

      localStorage.removeItem('redirectRoute')

      this.$routerPush(
        redirectRoute || {
          path: '/applications',
          query: this.$route.query,
        }
      )
    },
  },
}
</script>

<style lang="scss">
.login-buttons {
  $button-background: #f2f1f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__button {
    transition: all 0.15s ease;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 312px;
    height: 52px;
    border: 1px solid $charcoal-10;
    border-radius: 4px;
    margin-bottom: 0.75rem;
    padding: 14px 24px;
    background: $button-background;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: 0 !important;
    }

    &:active {
      box-shadow: none;
    }

    &-text {
      flex: 1;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      box-shadow: none;
    }

    &.featured {
      background: white;

      &:focus,
      &:hover {
        border-color: $charcoal-20;
      }
    }

    &.text-only {
      box-shadow: none;
      background: transparent;
      border-color: transparent;

      &.more {
        &:focus,
        &:hover {
          background: #eeeae4;
        }
      }

      &.close {
        &:focus,
        &:hover {
          background: $button-background;
        }
      }
    }
  }
}
</style>
