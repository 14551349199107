<template>
  <div v-if="promoConfig" :class="['promo-banner', { 'navbar-hidden': hiddenNavbar }]">
    <blw-icon class="mr-3" :icon="promoConfig.icon" />
    <span class="paragraph-2" v-html="promoConfig.content" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/**
 * Route meta based promo component
 *
 * to use simply add your new promo to the `promotions` data object with a icon and content field
 * then add the `promo` meta object with the respective key
 *
 * *TIP* if you want to conditionally show the banner based off data,
 * add a `conditionCheck` field to your promo object ( function which returns true || false ) to conditionally show it
 */
export default {
  name: 'PromoBanner',
  data() {
    return {
      promotions: {
        quickApply: {
          icon: 'urgent',
          content: 'Complete our <b>online application</b><br/> in <b>less than 5 minutes!</b>',
        },
        waiveAppFee: {
          icon: 'urgent',
          content: '<b>Apply today!</b> $35 application fee waived for a limited time.',
        },
        twoMonthsFree: {
          icon: 'urgent',
          content: '<b>Sign a lease in September and get two months free. Inquire for details.</b>',
          conditionsCheck: () => {
            return (
              this.applicationMarketSlug &&
              [
                'new-york-city',
                'washington-dc',
                'bay-area',
                'los-angeles',
                'chicago',
                'seattle',
              ].includes(this.applicationMarketSlug)
            )
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('application', ['applicationMarketSlug']),
    promoConfig() {
      const { promo } = this.$route.meta
      const promoConfig = this.promotions[promo]
      if (promoConfig) {
        return promoConfig.conditionsCheck
          ? promoConfig.conditionsCheck() && promoConfig
          : promoConfig
      }
      return null
    },
    hiddenNavbar() {
      const { meta, matched } = this.$route
      if (meta.hideNav) return meta.hideNav

      return matched.some(
        ({ meta }) => meta.hideNav || (meta.hideNavMobile && this.$vuetify.breakpoint.smAndDown)
      )
    },
  },
}
</script>

<style lang="scss">
.promo-banner {
  position: sticky;
  top: $nav-height;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid $charcoal-20;
  padding: 0 24px;
  margin-bottom: -56px;
  background: white;
  z-index: 10000;
  &.navbar-hidden {
    top: 0;
  }
  svg {
    zoom: 0.75;
  }

  @include mobile {
    top: $nav-height-mobile;

    span {
      max-width: 225px;
    }
  }
}
</style>
