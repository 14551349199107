import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const DEFAULT_EMPLOYMENT_RECORD = {
  title: '',
  employer: '',
}

const ensureEmploymentData = ({ employment_history = [], ...profile }) => {
  if (!employment_history.length) employment_history.push(DEFAULT_EMPLOYMENT_RECORD)

  return { ...profile, employment_history }
}

const getDefaultState = () => ({
  ...stateHelpers,
  profile: null,
  roommate: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,
  profile: (state) => state.profile,
  profileId: (state) => _.get(state, 'profile.id', null),
  profileImage: (state) => _.get(state, 'profile.profile_image_url', null),
  roommate: (state) => state.roommate,
  userData: (state) => {
    return {
      first_name: state.profile.first_name,
      last_name: state.profile.last_name,
      bio: state.profile.bio,
    }
  },
  userEmploymentData: (state) => {
    const employmentRecord = _.get(state, 'profile.employment_history[0]', {})

    return {
      id: employmentRecord.id,
      title: employmentRecord.title,
      employer: employmentRecord.employer,
    }
  },
}

const actions = {
  fetchProfile({ commit, rootGetters }) {
    commit('setLoading', true)
    const id = rootGetters['user/userId']

    return this._vm.$http
      .get(`/profiles/${id}`)
      .then(({ data }) => {
        const dataWithEmployment = ensureEmploymentData(data)

        commit('setProfile', dataWithEmployment)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => {
        commit('setLoading', false)
        commit('setUpdating', false)
      })
  },

  updateProfile({ commit, dispatch, getters }) {
    commit('setUpdating', true)

    const saveEmploymentAction = getters.userEmploymentData.id
      ? 'updateEmployment'
      : 'createEmployment'

    return Promise.all([dispatch('updateUser'), dispatch(saveEmploymentAction)])
  },

  updateUser({ commit, getters }) {
    commit('setLoading', true)

    return this._vm.$http
      .patch(`auth/me/`, getters.userData)
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  updateEmployment({ commit, getters }) {
    commit('setLoading', true)
    const { userEmploymentData } = getters
    const payload = _.omit(userEmploymentData, ['id'])
    return this._vm.$http
      .put(`profile/employment/${userEmploymentData.id}`, payload)
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  createEmployment({ commit, getters }) {
    commit('setLoading', true)
    const { userEmploymentData } = getters
    const payload = _.omit(userEmploymentData, ['id'])

    return this._vm.$http
      .post(`profile/employment/`, payload)
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async submitProfile({ commit, dispatch, rootGetters }, applicationSource) {
    const application = rootGetters['application/applicationId']

    await this._vm.$http
      .post('applications/profile-submission/', { application })
      .catch((error) => commit('setError', error))

    return dispatch('application/fetchApplicationBySource', applicationSource, {
      root: true,
    })
  },

  async fetchRoommateProfile({ commit, dispatch }, roommateId) {
    try {
      // Attempt to fetch all of the roomate profile components...
      const [roommate, interests, preferences] = await Promise.all([
        dispatch('fetchRoommate', roommateId),
        dispatch('fetchInterestsOverlap', roommateId),
        dispatch('fetchPreferencesOverlap', roommateId),
      ])
      // Combine the roommate data into a single profile object:
      const roommateProfile = { ...roommate, overlaps: { interests, preferences } }

      commit('setRoommate', roommateProfile)
    } catch (error) {
      commit('setError', error)
    }
  },

  fetchRoommate(store, roommateId) {
    return this._vm.$http
      .get(`/profiles/${roommateId}`)
      .then(({ data }) => ensureEmploymentData(data))
  },

  fetchInterestsOverlap(store, userId) {
    return this._vm.$http
      .get(`profile/overlaps/interests/?user_id=${userId}`)
      .then(({ data }) => data.results)
  },

  fetchPreferencesOverlap(store, userId) {
    return this._vm.$http
      .get(`profile/overlaps/living-preferences/?user_id=${userId}`)
      .then(({ data }) => data)
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,
  setProfile: (state, payload) => (state.profile = payload),
  setRoommate: (state, payload) => (state.roommate = payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
