<template>
  <ApplicationIntro
    :currentStep="1"
    :currentStepStartHandler="goToLogin"
    :overrideSteps="overrideSteps"
    class="bg-white"
    title="Getting started with Bungalow"
    subtitle="Join the community where everyone can truly feel at home and live wherever they want to live"
    blurFutureSteps
  />
</template>

<script>
import { mapActions } from 'vuex'
import ApplicationIntro from '@/routes/apply/components/ApplicationIntro'

export default {
  name: 'GetStarted',
  components: {
    ApplicationIntro,
  },
  mounted() {
    this.$trackEvent({
      action: 'Page viewed',
      properties: {
        type: 'Preview page',
        title: 'Complete an application',
      },
    })
  },
  computed: {
    overrideSteps() {
      const { isGroup } = this.$route.query
      return isGroup
        ? [
            {
              title: 'Complete an application',
              description: 'Get approved for all Bungalow homes in less than five minutes',
            },
            {
              title: 'Select your room and sign the lease',
              description: 'Confirm your room and lease details before signing your lease',
            },
          ]
        : null
    },
  },
  methods: {
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    goToLogin() {
      this.showLoader()
      this.$nextTick(() => {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Preview page',
            title: 'Complete an application',
            CTA: 'Start',
          },
        })
        this.$routerPush({ name: 'login' })
        this.hideLoader()
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
