<template>
  <loading-spinner full-page v-if="loading" />
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'HomeSelectionContainer',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('application', ['applicationLeasePreferenceId', 'application']),
    ...mapGetters('user', ['userProfile']),
  },
  methods: {
    ...mapActions('application', ['initializeHomeSelectionDisplayStateData']),
    ...mapActions('leasePreference', ['fetchLeasePreferenceById']),
  },
  async beforeMount() {
    this.loading = true
    if (this.applicationLeasePreferenceId) {
      await this.fetchLeasePreferenceById(this.applicationLeasePreferenceId)
    } else {
      this.$trackEvent({
        action: 'Home Selection - No Lease Preference',
        properties: {
          applicationId: this.application.id,
          userEmail: this.userProfile.email,
        },
      })
    }
    this.initializeHomeSelectionDisplayStateData()
    this.loading = false
  },
}
</script>
