export default [
  {
    text: 'United States',
    value: 'US',
    states: [
      {
        text: 'Alabama',
        value: 'AL',
      },
      {
        text: 'Alaska',
        value: 'AK',
      },
      {
        text: 'American Samoa',
        value: 'AS',
      },
      {
        text: 'Arizona',
        value: 'AZ',
      },
      {
        text: 'Arkansas',
        value: 'AR',
      },
      {
        text: 'California',
        value: 'CA',
      },
      {
        text: 'Colorado',
        value: 'CO',
      },
      {
        text: 'Connecticut',
        value: 'CT',
      },
      {
        text: 'Delaware',
        value: 'DE',
      },
      {
        text: 'District of Columbia',
        value: 'DC',
      },
      {
        text: 'Micronesia',
        value: 'FM',
      },
      {
        text: 'Florida',
        value: 'FL',
      },
      {
        text: 'Georgia',
        value: 'GA',
      },
      {
        text: 'Guam',
        value: 'GU',
      },
      {
        text: 'Hawaii',
        value: 'HI',
      },
      {
        text: 'Idaho',
        value: 'ID',
      },
      {
        text: 'Illinois',
        value: 'IL',
      },
      {
        text: 'Indiana',
        value: 'IN',
      },
      {
        text: 'Iowa',
        value: 'IA',
      },
      {
        text: 'Kansas',
        value: 'KS',
      },
      {
        text: 'Kentucky',
        value: 'KY',
      },
      {
        text: 'Louisiana',
        value: 'LA',
      },
      {
        text: 'Maine',
        value: 'ME',
      },
      {
        text: 'Marshall Islands',
        value: 'MH',
      },
      {
        text: 'Maryland',
        value: 'MD',
      },
      {
        text: 'Massachusetts',
        value: 'MA',
      },
      {
        text: 'Michigan',
        value: 'MI',
      },
      {
        text: 'Minnesota',
        value: 'MN',
      },
      {
        text: 'Mississippi',
        value: 'MS',
      },
      {
        text: 'Missouri',
        value: 'MO',
      },
      {
        text: 'Montana',
        value: 'MT',
      },
      {
        text: 'Nebraska',
        value: 'NE',
      },
      {
        text: 'Nevada',
        value: 'NV',
      },
      {
        text: 'New Hampshire',
        value: 'NH',
      },
      {
        text: 'New Jersey',
        value: 'NJ',
      },
      {
        text: 'New Mexico',
        value: 'NM',
      },
      {
        text: 'New York',
        value: 'NY',
      },
      {
        text: 'North Carolina',
        value: 'NC',
      },
      {
        text: 'North Dakota',
        value: 'ND',
      },
      {
        text: 'Northern Mariana Islands',
        value: 'MP',
      },
      {
        text: 'Ohio',
        value: 'OH',
      },
      {
        text: 'Oklahoma',
        value: 'OK',
      },
      {
        text: 'Oregon',
        value: 'OR',
      },
      {
        text: 'Palau',
        value: 'PW',
      },
      {
        text: 'Pennsylvania',
        value: 'PA',
      },
      {
        text: 'Puerto Rico',
        value: 'PR',
      },
      {
        text: 'Rhode Island',
        value: 'RI',
      },
      {
        text: 'South Carolina',
        value: 'SC',
      },
      {
        text: 'South Dakota',
        value: 'SD',
      },
      {
        text: 'Tennessee',
        value: 'TN',
      },
      {
        text: 'Texas',
        value: 'TX',
      },
      {
        text: 'Utah',
        value: 'UT',
      },
      {
        text: 'Vermont',
        value: 'VT',
      },
      {
        text: 'Virgin Islands',
        value: 'VI',
      },
      {
        text: 'Virginia',
        value: 'VA',
      },
      {
        text: 'Washington',
        value: 'WA',
      },
      {
        text: 'West Virginia',
        value: 'WV',
      },
      {
        text: 'Wisconsin',
        value: 'WI',
      },
      {
        text: 'Wyoming',
        value: 'WY',
      },
      {
        text: 'Armed Forces Americas',
        value: 'AA',
      },
      {
        text: 'Armed Forces Europe, Canada, Africa and Middle East',
        value: 'AE',
      },
      {
        text: 'Armed Forces Pacific',
        value: 'AP',
      },
    ],
  },
  {
    text: 'Canada',
    value: 'CA',
    states: [
      {
        text: 'Alberta',
        value: 'AB',
      },
      {
        text: 'British Columbia',
        value: 'BC',
      },
      {
        text: 'Manitoba',
        value: 'MB',
      },
      {
        text: 'New Brunswick',
        value: 'NB',
      },
      {
        text: 'Newfoundland and Labrador',
        value: 'NL',
      },
      {
        text: 'Northwest Territories',
        value: 'NT',
      },
      {
        text: 'Nova Scotia',
        value: 'NS',
      },
      {
        text: 'Nunavut',
        value: 'NU',
      },
      {
        text: 'Ontario',
        value: 'ON',
      },
      {
        text: 'Prince Edward Island',
        value: 'PE',
      },
      {
        text: 'Quebec',
        value: 'QC',
      },
      {
        text: 'Saskatchewan',
        value: 'SK',
      },
      {
        text: 'Yukon',
        value: 'YT',
      },
    ],
  },
  {
    text: 'Afghanistan',
    value: 'AF',
    states: [
      {
        text: 'Badakhshan',
        value: 'BDS',
      },
      {
        text: 'Badghis',
        value: 'BDG',
      },
      {
        text: 'Baghlan',
        value: 'BGL',
      },
      {
        text: 'Balkh',
        value: 'BAL',
      },
      {
        text: 'Bamyan',
        value: 'BAM',
      },
      {
        text: 'Daykundi',
        value: 'DAY',
      },
      {
        text: 'Farah',
        value: 'FRA',
      },
      {
        text: 'Faryab',
        value: 'FYB',
      },
      {
        text: 'Ghazni',
        value: 'GHA',
      },
      {
        text: 'Ghor',
        value: 'GHO',
      },
      {
        text: 'Helmand',
        value: 'HEL',
      },
      {
        text: 'Herat',
        value: 'HER',
      },
      {
        text: 'Jowzjan',
        value: 'JOW',
      },
      {
        text: 'Kabul',
        value: 'KAB',
      },
      {
        text: 'Kandahar',
        value: 'KAN',
      },
      {
        text: 'Kapisa',
        value: 'KAP',
      },
      {
        text: 'Khost',
        value: 'KHO',
      },
      {
        text: 'Kunar',
        value: 'KNR',
      },
      {
        text: 'Kunduz',
        value: 'KDZ',
      },
      {
        text: 'Laghman',
        value: 'LAG',
      },
      {
        text: 'Logar',
        value: 'LOW',
      },
      {
        text: 'Maidan Wardak',
        value: 'WAR',
      },
      {
        text: 'Nangarhar',
        value: 'NAN',
      },
      {
        text: 'Nimruz',
        value: 'NIM',
      },
      {
        text: 'Nuristan',
        value: 'NUR',
      },
      {
        text: 'Paktia',
        value: 'PIA',
      },
      {
        text: 'Paktika',
        value: 'PKA',
      },
      {
        text: 'Panjshir',
        value: 'PAN',
      },
      {
        text: 'Parwan',
        value: 'PAR',
      },
      {
        text: 'Samangan',
        value: 'SAM',
      },
      {
        text: 'Sar-e Pol',
        value: 'SAR',
      },
      {
        text: 'Takhar',
        value: 'TAK',
      },
      {
        text: 'Urozgan',
        value: 'ORU',
      },
      {
        text: 'Zabul',
        value: 'ZAB',
      },
    ],
  },
  {
    text: 'Åland Islands',
    value: 'AX',
    states: [
      {
        text: 'Brändö',
        value: 'BR',
      },
      {
        text: 'Eckerö',
        value: 'EC',
      },
      {
        text: 'Finström',
        value: 'FN',
      },
      {
        text: 'Föglö',
        value: 'FG',
      },
      {
        text: 'Geta',
        value: 'GT',
      },
      {
        text: 'Hammarland',
        value: 'HM',
      },
      {
        text: 'Jomala',
        value: 'JM',
      },
      {
        text: 'Kumlinge',
        value: 'KM',
      },
      {
        text: 'Kökar',
        value: 'KK',
      },
      {
        text: 'Lemland',
        value: 'LE',
      },
      {
        text: 'Lumparland',
        value: 'LU',
      },
      {
        text: 'Mariehamn',
        value: 'MH',
      },
      {
        text: 'Saltvik',
        value: 'SV',
      },
      {
        text: 'Sottunga',
        value: 'ST',
      },
      {
        text: 'Sund',
        value: 'SD',
      },
      {
        text: 'Vårdö',
        value: 'VR',
      },
    ],
  },
  {
    text: 'Albania',
    value: 'AL',
    states: [
      {
        text: 'Berat',
        value: '01',
      },
      {
        text: 'Dibër',
        value: '09',
      },
      {
        text: 'Durrës',
        value: '02',
      },
      {
        text: 'Elbasan',
        value: '03',
      },
      {
        text: 'Fier',
        value: '04',
      },
      {
        text: 'Gjirokastër',
        value: '05',
      },
      {
        text: 'Korçë',
        value: '06',
      },
      {
        text: 'Kukës',
        value: '07',
      },
      {
        text: 'Lezhë',
        value: '08',
      },
      {
        text: 'Shkodër',
        value: '10',
      },
      {
        text: 'Tirana',
        value: '11',
      },
      {
        text: 'Vlorë',
        value: '12',
      },
    ],
  },
  {
    text: 'Algeria',
    value: 'DZ',
    states: [
      {
        text: 'Adrar',
        value: '01',
      },
      {
        text: 'Aïn Defla',
        value: '44',
      },
      {
        text: 'Aïn Témouchent',
        value: '46',
      },
      {
        text: 'Algiers',
        value: '16',
      },
      {
        text: 'Annaba',
        value: '23',
      },
      {
        text: 'Batna',
        value: '05',
      },
      {
        text: 'Béchar',
        value: '08',
      },
      {
        text: 'Béjaïa',
        value: '06',
      },
      {
        text: 'Biskra',
        value: '07',
      },
      {
        text: 'Blida',
        value: '09',
      },
      {
        text: 'Bordj Bou Arréridj',
        value: '34',
      },
      {
        text: 'Bouïra',
        value: '10',
      },
      {
        text: 'Boumerdès',
        value: '35',
      },
      {
        text: 'Chlef',
        value: '02',
      },
      {
        text: 'Constantine',
        value: '25',
      },
      {
        text: 'Djelfa',
        value: '17',
      },
      {
        text: 'El Bayadh',
        value: '32',
      },
      {
        text: 'El Oued',
        value: '39',
      },
      {
        text: 'El Tarf',
        value: '36',
      },
      {
        text: 'Ghardaïa',
        value: '47',
      },
      {
        text: 'Guelma',
        value: '24',
      },
      {
        text: 'Illizi',
        value: '33',
      },
      {
        text: 'Jijel',
        value: '18',
      },
      {
        text: 'Khenchela',
        value: '40',
      },
      {
        text: 'Laghouat',
        value: '03',
      },
      {
        text: 'Mascara',
        value: '29',
      },
      {
        text: 'Médéa',
        value: '26',
      },
      {
        text: 'Mila',
        value: '43',
      },
      {
        text: 'Mostaganem',
        value: '27',
      },
      {
        text: 'Msila',
        value: '28',
      },
      {
        text: 'Naâma',
        value: '45',
      },
      {
        text: 'Oran',
        value: '31',
      },
      {
        text: 'Ouargla',
        value: '30',
      },
      {
        text: 'Oum el Bouaghi',
        value: '04',
      },
      {
        text: 'Relizane',
        value: '48',
      },
      {
        text: 'Saïda',
        value: '20',
      },
      {
        text: 'Sétif',
        value: '19',
      },
      {
        text: 'Sidi Bel Abbès',
        value: '22',
      },
      {
        text: 'Skikda',
        value: '21',
      },
      {
        text: 'Souk Ahras',
        value: '41',
      },
      {
        text: 'Tamanghasset',
        value: '11',
      },
      {
        text: 'Tébessa',
        value: '12',
      },
      {
        text: 'Tiaret',
        value: '14',
      },
      {
        text: 'Tindouf',
        value: '37',
      },
      {
        text: 'Tipaza',
        value: '42',
      },
      {
        text: 'Tissemsilt',
        value: '38',
      },
      {
        text: 'Tizi Ouzou',
        value: '15',
      },
      {
        text: 'Tlemcen',
        value: '13',
      },
    ],
  },
  {
    text: 'American Samoa',
    value: 'AS',
    states: [
      {
        text: 'Tutuila',
        value: '01',
      },
      {
        text: "Aunu'u",
        value: '02',
      },
      {
        text: "Ta'ū",
        value: '03',
      },
      {
        text: 'Ofu‑Olosega',
        value: '04',
      },
      {
        text: 'Rose Atoll',
        value: '21',
      },
      {
        text: 'Swains Island',
        value: '22',
      },
    ],
  },
  {
    text: 'Andorra',
    value: 'AD',
    states: [
      {
        text: 'Andorra la Vella',
        value: '07',
      },
      {
        text: 'Canillo',
        value: '02',
      },
      {
        text: 'Encamp',
        value: '03',
      },
      {
        text: 'Escaldes-Engordany',
        value: '08',
      },
      {
        text: 'La Massana',
        value: '04',
      },
      {
        text: 'Ordino',
        value: '05',
      },
      {
        text: 'Sant Julià de Lòria',
        value: '06',
      },
    ],
  },
  {
    text: 'Angola',
    value: 'AO',
    states: [
      {
        text: 'Bengo',
        value: 'BGO',
      },
      {
        text: 'Benguela',
        value: 'BGU',
      },
      {
        text: 'Bié',
        value: 'BIE',
      },
      {
        text: 'Cabinda',
        value: 'CAB',
      },
      {
        text: 'Cuando Cubango',
        value: 'CCU',
      },
      {
        text: 'Cuanza Norte',
        value: 'CNO',
      },
      {
        text: 'Cuanza Sul',
        value: 'CUS',
      },
      {
        text: 'Cunene',
        value: 'CNN',
      },
      {
        text: 'Huambo',
        value: 'HUA',
      },
      {
        text: 'Huíla',
        value: 'HUI',
      },
      {
        text: 'Luanda',
        value: 'LUA',
      },
      {
        text: 'Lunda Norte',
        value: 'LNO',
      },
      {
        text: 'Lunda Sul',
        value: 'LSU',
      },
      {
        text: 'Malanje',
        value: 'MAL',
      },
      {
        text: 'Moxico',
        value: 'MOX',
      },
      {
        text: 'Namibe',
        value: 'NAM',
      },
      {
        text: 'Uíge',
        value: 'UIG',
      },
      {
        text: 'Zaire',
        value: 'ZAI',
      },
    ],
  },
  {
    text: 'Anguilla',
    value: 'AI',
    states: [
      {
        text: 'Anguilla',
        value: '01',
      },
      {
        text: 'Anguillita Island',
        value: '02',
      },
      {
        text: 'Blowing Rock',
        value: '03',
      },
      {
        text: 'Cove Cay',
        value: '04',
      },
      {
        text: 'Crocus Cay',
        value: '05',
      },
      {
        text: "Deadman's Cay",
        value: '06',
      },
      {
        text: 'Dog Island',
        value: '07',
      },
      {
        text: 'East Cay',
        value: '08',
      },
      {
        text: 'Little Island',
        value: '09',
      },
      {
        text: 'Little Scrub Island',
        value: '10',
      },
      {
        text: 'Mid Cay',
        value: '11',
      },
      {
        text: 'North Cay',
        value: '12',
      },
      {
        text: 'Prickly Pear Cays',
        value: '13',
      },
      {
        text: 'Rabbit Island',
        value: '14',
      },
      {
        text: 'Sandy Island/Sand Island',
        value: '15',
      },
      {
        text: 'Scilly Cay',
        value: '16',
      },
      {
        text: 'Scrub Island',
        value: '17',
      },
      {
        text: 'Seal Island',
        value: '18',
      },
      {
        text: 'Sombrero/Hat Island',
        value: '19',
      },
      {
        text: 'South Cay',
        value: '20',
      },
      {
        text: 'South Wager Island',
        value: '21',
      },
      {
        text: 'West Cay',
        value: '22',
      },
    ],
  },
  {
    text: 'Antarctica',
    value: 'AQ',
    states: [
      {
        text: 'Antarctica',
        value: 'AQ',
      },
    ],
  },
  {
    text: 'Antigua and Barbuda',
    value: 'AG',
    states: [
      {
        text: 'Antigua Island',
        value: '01',
      },
      {
        text: 'Barbuda Island',
        value: '02',
      },
      {
        text: 'Bird Island',
        value: '04',
      },
      {
        text: 'Bishop Island',
        value: '05',
      },
      {
        text: 'Blake Island',
        value: '06',
      },
      {
        text: 'Crump Island',
        value: '09',
      },
      {
        text: 'Dulcina Island',
        value: '10',
      },
      {
        text: 'Exchange Island',
        value: '11',
      },
      {
        text: 'Five Islands',
        value: '12',
      },
      {
        text: 'Great Bird Island',
        value: '13',
      },
      {
        text: 'Green Island',
        value: '14',
      },
      {
        text: 'Guiana Island',
        value: '15',
      },
      {
        text: 'Hawes Island',
        value: '17',
      },
      {
        text: 'Hells Gate Island',
        value: '16',
      },
      {
        text: 'Henry Island',
        value: '18',
      },
      {
        text: 'Johnson Island',
        value: '19',
      },
      {
        text: 'Kid Island',
        value: '20',
      },
      {
        text: 'Lobster Island',
        value: '22',
      },
      {
        text: 'Maiden Island',
        value: '24',
      },
      {
        text: 'Moor Island',
        value: '25',
      },
      {
        text: 'Nanny Island',
        value: '26',
      },
      {
        text: 'Pelican Island',
        value: '27',
      },
      {
        text: 'Prickly Pear Island',
        value: '28',
      },
      {
        text: 'Rabbit Island',
        value: '29',
      },
      {
        text: 'Red Head Island',
        value: '31',
      },
      {
        text: 'Redonda Island',
        value: '03',
      },
      {
        text: 'Sandy Island',
        value: '32',
      },
      {
        text: 'Smith Island',
        value: '33',
      },
      {
        text: 'The Sisters',
        value: '34',
      },
      {
        text: 'Vernon Island',
        value: '35',
      },
      {
        text: 'Wicked Will Island',
        value: '36',
      },
      {
        text: 'York Island',
        value: '37',
      },
    ],
  },
  {
    text: 'Argentina',
    value: 'AR',
    states: [
      {
        text: 'Buenos Aires',
        value: 'B',
      },
      {
        text: 'Capital Federal',
        value: 'C',
      },
      {
        text: 'Catamarca',
        value: 'K',
      },
      {
        text: 'Chaco',
        value: 'H',
      },
      {
        text: 'Chubut',
        value: 'U',
      },
      {
        text: 'Córdoba',
        value: 'X',
      },
      {
        text: 'Corrientes',
        value: 'W',
      },
      {
        text: 'Entre Ríos',
        value: 'E',
      },
      {
        text: 'Formosa',
        value: 'P',
      },
      {
        text: 'Jujuy',
        value: 'Y',
      },
      {
        text: 'La Pampa',
        value: 'L',
      },
      {
        text: 'La Rioja',
        value: 'F',
      },
      {
        text: 'Mendoza',
        value: 'M',
      },
      {
        text: 'Misiones',
        value: 'N',
      },
      {
        text: 'Neuquén',
        value: 'Q',
      },
      {
        text: 'Río Negro',
        value: 'R',
      },
      {
        text: 'Salta',
        value: 'A',
      },
      {
        text: 'San Juan',
        value: 'J',
      },
      {
        text: 'San Luis',
        value: 'D',
      },
      {
        text: 'Santa Cruz',
        value: 'Z',
      },
      {
        text: 'Santa Fe',
        value: 'S',
      },
      {
        text: 'Santiago del Estero',
        value: 'G',
      },
      {
        text: 'Tierra del Fuego',
        value: 'V',
      },
      {
        text: 'Tucumán',
        value: 'T',
      },
    ],
  },
  {
    text: 'Armenia',
    value: 'AM',
    states: [
      {
        text: 'Aragatsotn',
        value: 'AG',
      },
      {
        text: 'Ararat',
        value: 'AR',
      },
      {
        text: 'Armavir',
        value: 'AV',
      },
      {
        text: 'Gegharkunik',
        value: 'GR',
      },
      {
        text: 'Kotayk',
        value: 'KT',
      },
      {
        text: 'Lori',
        value: 'LO',
      },
      {
        text: 'Shirak',
        value: 'SH',
      },
      {
        text: 'Syunik',
        value: 'SU',
      },
      {
        text: 'Tavush',
        value: 'TV',
      },
      {
        text: 'Vayots Dzor',
        value: 'VD',
      },
      {
        text: 'Yerevan',
        value: 'ER',
      },
    ],
  },
  {
    text: 'Aruba',
    value: 'AW',
    states: [
      {
        text: 'Aruba',
        value: 'AW',
      },
    ],
  },
  {
    text: 'Australia',
    value: 'AU',
    states: [
      {
        text: 'Australian Capital Territory',
        value: 'ACT',
      },
      {
        text: 'New South Wales',
        value: 'NSW',
      },
      {
        text: 'Northern Territory',
        value: 'NT',
      },
      {
        text: 'Queensland',
        value: 'QLD',
      },
      {
        text: 'South Australia',
        value: 'SA',
      },
      {
        text: 'Tasmania',
        value: 'TAS',
      },
      {
        text: 'Victoria',
        value: 'VIC',
      },
      {
        text: 'Western Australia',
        value: 'WA',
      },
    ],
  },
  {
    text: 'Austria',
    value: 'AT',
    states: [
      {
        text: 'Burgenland',
        value: '1',
      },
      {
        text: 'Kärnten',
        value: '2',
      },
      {
        text: 'Niederösterreich',
        value: '3',
      },
      {
        text: 'Oberösterreich',
        value: '4',
      },
      {
        text: 'Salzburg',
        value: '5',
      },
      {
        text: 'Steiermark',
        value: '6',
      },
      {
        text: 'Tirol',
        value: '7',
      },
      {
        text: 'Vorarlberg',
        value: '8',
      },
      {
        text: 'Wien',
        value: '9',
      },
    ],
  },
  {
    text: 'Azerbaijan',
    value: 'AZ',
    states: [
      {
        text: 'Abşeron',
        value: 'ABS',
      },
      {
        text: 'Ağcabədi',
        value: 'AGC',
      },
      {
        text: 'Ağdam',
        value: 'AGM',
      },
      {
        text: 'Ağdaş',
        value: 'AGS',
      },
      {
        text: 'Ağstafa',
        value: 'AGA',
      },
      {
        text: 'Ağsu',
        value: 'AGU',
      },
      {
        text: 'Astara',
        value: 'AST',
      },
      {
        text: 'Bakı',
        value: 'BAK',
      },
      {
        text: 'Babək',
        value: 'BAB',
      },
      {
        text: 'Balakən',
        value: 'BAL',
      },
      {
        text: 'Bərdə',
        value: 'BAR',
      },
      {
        text: 'Beyləqan',
        value: 'BEY',
      },
      {
        text: 'Biləsuvar',
        value: 'BIL',
      },
      {
        text: 'Cəbrayıl',
        value: 'CAB',
      },
      {
        text: 'Cəlilabad',
        value: 'CAL',
      },
      {
        text: 'Culfa',
        value: 'CUL',
      },
      {
        text: 'Daşkəsən',
        value: 'DAS',
      },
      {
        text: 'Füzuli',
        value: 'FUZ',
      },
      {
        text: 'Gədəbəy',
        value: 'GAD',
      },
      {
        text: 'Goranboy',
        value: 'GOR',
      },
      {
        text: 'Göyçay',
        value: 'GOY',
      },
      {
        text: 'Göygöl',
        value: 'GYG',
      },
      {
        text: 'Hacıqabul',
        value: 'HAC',
      },
      {
        text: 'İmişli',
        value: 'IMI',
      },
      {
        text: 'İsmayıllı',
        value: 'ISM',
      },
      {
        text: 'Kəlbəcər',
        value: 'KAL',
      },
      {
        text: 'Kǝngǝrli',
        value: 'KAN',
      },
      {
        text: 'Kürdəmir',
        value: 'KUR',
      },
      {
        text: 'Laçın',
        value: 'LAC',
      },
      {
        text: 'Lənkəran',
        value: 'LAN',
      },
      {
        text: 'Lerik',
        value: 'LER',
      },
      {
        text: 'Masallı',
        value: 'MAS',
      },
      {
        text: 'Neftçala',
        value: 'NEF',
      },
      {
        text: 'Oğuz',
        value: 'OGU',
      },
      {
        text: 'Ordubad',
        value: 'ORD',
      },
      {
        text: 'Qəbələ',
        value: 'QAB',
      },
      {
        text: 'Qax',
        value: 'QAX',
      },
      {
        text: 'Qazax',
        value: 'QAZ',
      },
      {
        text: 'Qobustan',
        value: 'QOB',
      },
      {
        text: 'Quba',
        value: 'QBA',
      },
      {
        text: 'Qubadli',
        value: 'QBI',
      },
      {
        text: 'Qusar',
        value: 'QUS',
      },
      {
        text: 'Saatlı',
        value: 'SAT',
      },
      {
        text: 'Sabirabad',
        value: 'SAB',
      },
      {
        text: 'Şabran',
        value: 'SBN',
      },
      {
        text: 'Sədərək',
        value: 'SAD',
      },
      {
        text: 'Şahbuz',
        value: 'SAH',
      },
      {
        text: 'Şəki',
        value: 'SAK',
      },
      {
        text: 'Salyan',
        value: 'SAL',
      },
      {
        text: 'Şamaxı',
        value: 'SMI',
      },
      {
        text: 'Şəmkir',
        value: 'SKR',
      },
      {
        text: 'Samux',
        value: 'SMX',
      },
      {
        text: 'Şərur',
        value: 'SAR',
      },
      {
        text: 'Siyəzən',
        value: 'SIY',
      },
      {
        text: 'Şuşa',
        value: 'SUS',
      },
      {
        text: 'Tərtər',
        value: 'TAR',
      },
      {
        text: 'Tovuz',
        value: 'TOV',
      },
      {
        text: 'Ucar',
        value: 'UCA',
      },
      {
        text: 'Xaçmaz',
        value: 'XAC',
      },
      {
        text: 'Xızı',
        value: 'XIZ',
      },
      {
        text: 'Xocalı',
        value: 'XCI',
      },
      {
        text: 'Xocavənd',
        value: 'XVD',
      },
      {
        text: 'Yardımlı',
        value: 'YAR',
      },
      {
        text: 'Yevlax',
        value: 'YEV',
      },
      {
        text: 'Zəngilan',
        value: 'ZAN',
      },
      {
        text: 'Zaqatala',
        value: 'ZAQ',
      },
      {
        text: 'Zərdab',
        value: 'ZAR',
      },
    ],
  },
  {
    text: 'Bahamas',
    value: 'BS',
    states: [
      {
        text: 'Acklins Island',
        value: '01',
      },
      {
        text: 'Berry Islands',
        value: '22',
      },
      {
        text: 'Bimini',
        value: '02',
      },
      {
        text: 'Black Point',
        value: '23',
      },
      {
        text: 'Cat Island',
        value: '03',
      },
      {
        text: 'Central Abaco',
        value: '24',
      },
      {
        text: 'Crooked Island and Long Cay',
        value: '28',
      },
      {
        text: 'East Grand Bahama',
        value: '29',
      },
      {
        text: 'Exuma',
        value: '04',
      },
      {
        text: 'Freeport',
        value: '05',
      },
      {
        text: 'Fresh Creek',
        value: '06',
      },
      {
        text: "Governor's Harbour",
        value: '07',
      },
      {
        text: 'Green Turtle Cay',
        value: '08',
      },
      {
        text: 'Harbour Island',
        value: '09',
      },
      {
        text: 'High Rock',
        value: '10',
      },
      {
        text: 'Inagua',
        value: '11',
      },
      {
        text: 'Kemps Bay',
        value: '12',
      },
      {
        text: 'Long Island',
        value: '13',
      },
      {
        text: 'Marsh Harbour',
        value: '14',
      },
      {
        text: 'Mayaguana',
        value: '15',
      },
      {
        text: 'Moore’s Island',
        value: '40',
      },
      {
        text: 'New Providence',
        value: '16',
      },
      {
        text: 'Nichollstown and Berry Islands',
        value: '17',
      },
      {
        text: 'North Abaco',
        value: '42',
      },
      {
        text: 'North Andros',
        value: '41',
      },
      {
        text: 'North Eleuthera',
        value: '33',
      },
      {
        text: 'Ragged Island',
        value: '18',
      },
      {
        text: 'Rock Sound',
        value: '19',
      },
      {
        text: 'San Salvador and Rum Cay',
        value: '20',
      },
      {
        text: 'Sandy Point',
        value: '21',
      },
      {
        text: 'South Abaco',
        value: '35',
      },
      {
        text: 'South Andros',
        value: '36',
      },
      {
        text: 'South Eleuthera',
        value: '37',
      },
      {
        text: 'West Grand Bahama',
        value: '39',
      },
    ],
  },
  {
    text: 'Bahrain',
    value: 'BH',
    states: [
      {
        text: 'Al Janūbīyah',
        value: '14',
      },
      {
        text: 'Al Manāmah',
        value: '13',
      },
      {
        text: 'Al Muḩarraq',
        value: '15',
      },
      {
        text: 'Al Wusţá',
        value: '16',
      },
      {
        text: 'Ash Shamālīyah',
        value: '17',
      },
    ],
  },
  {
    text: 'Bangladesh',
    value: 'BD',
    states: [
      {
        text: 'Barisal',
        value: 'A',
      },
      {
        text: 'Chittagong',
        value: 'B',
      },
      {
        text: 'Dhaka',
        value: 'C',
      },
      {
        text: 'Khulna',
        value: 'D',
      },
      {
        text: 'Mymensingh',
        value: 'M',
      },
      {
        text: 'Rajshahi',
        value: 'E',
      },
      {
        text: 'Rangpur',
        value: 'F',
      },
      {
        text: 'Sylhet',
        value: 'G',
      },
    ],
  },
  {
    text: 'Barbados',
    value: 'BB',
    states: [
      {
        text: 'Christ Church',
        value: '01',
      },
      {
        text: 'Saint Andrew',
        value: '02',
      },
      {
        text: 'Saint George',
        value: '03',
      },
      {
        text: 'Saint James',
        value: '04',
      },
      {
        text: 'Saint John',
        value: '05',
      },
      {
        text: 'Saint Joseph',
        value: '06',
      },
      {
        text: 'Saint Lucy',
        value: '07',
      },
      {
        text: 'Saint Michael',
        value: '08',
      },
      {
        text: 'Saint Peter',
        value: '09',
      },
      {
        text: 'Saint Philip',
        value: '10',
      },
      {
        text: 'Saint Thomas',
        value: '11',
      },
    ],
  },
  {
    text: 'Belarus',
    value: 'BY',
    states: [
      {
        text: 'Brest voblast',
        value: 'BR',
      },
      {
        text: 'Gorod Minsk',
        value: 'HO',
      },
      {
        text: 'Homiel voblast',
        value: 'HO',
      },
      {
        text: 'Hrodna voblast',
        value: 'HR',
      },
      {
        text: 'Mahilyow voblast',
        value: 'MA',
      },
      {
        text: 'Minsk voblast',
        value: 'MI',
      },
      {
        text: 'Vitsebsk voblast',
        value: 'VI',
      },
    ],
  },
  {
    text: 'Belgium',
    value: 'BE',
    states: [
      {
        text: 'Bruxelles-Capitale',
        value: 'BRU',
      },
      {
        text: 'Région Flamande',
        value: 'VLG',
      },
      {
        text: 'Région Wallonië',
        value: 'WAL',
      },
    ],
  },
  {
    text: 'Belize',
    value: 'BZ',
    states: [
      {
        text: 'Belize District',
        value: 'BZ',
      },
      {
        text: 'Cayo District',
        value: 'CY',
      },
      {
        text: 'Corozal District',
        value: 'CZL',
      },
      {
        text: 'Orange Walk District',
        value: 'OW',
      },
      {
        text: 'Stann Creek District',
        value: 'SC',
      },
      {
        text: 'Toledo District',
        value: 'TOL',
      },
    ],
  },
  {
    text: 'Benin',
    value: 'BJ',
    states: [
      {
        text: 'Alibori',
        value: 'AL',
      },
      {
        text: 'Atakora',
        value: 'AK',
      },
      {
        text: 'Atlantique',
        value: 'AQ',
      },
      {
        text: 'Borgou',
        value: 'BO',
      },
      {
        text: 'Collines Department',
        value: 'CO',
      },
      {
        text: 'Donga',
        value: 'DO',
      },
      {
        text: 'Kouffo',
        value: 'KO',
      },
      {
        text: 'Littoral Department',
        value: 'LI',
      },
      {
        text: 'Mono Department',
        value: 'MO',
      },
      {
        text: 'Ouémé',
        value: 'OU',
      },
      {
        text: 'Plateau',
        value: 'PL',
      },
      {
        text: 'Zou',
        value: 'ZO',
      },
    ],
  },
  {
    text: 'Bermuda',
    value: 'BM',
    states: [
      {
        text: 'City of Hamilton',
        value: '03',
      },
      {
        text: 'Devonshire Parish',
        value: '01',
      },
      {
        text: 'Hamilton Parish',
        value: '02',
      },
      {
        text: 'Paget Parish',
        value: '04',
      },
      {
        text: 'Pembroke Parish',
        value: '05',
      },
      {
        text: 'Sandys Parish',
        value: '08',
      },
      {
        text: "Smith's Parish",
        value: '09',
      },
      {
        text: 'Southampton Parish',
        value: '10',
      },
      {
        text: "St. George's Parish",
        value: '07',
      },
      {
        text: 'Town of St. George',
        value: '06',
      },
      {
        text: 'Warwick Parish',
        value: '11',
      },
    ],
  },
  {
    text: 'Bhutan',
    value: 'BT',
    states: [
      {
        text: 'Bumthang',
        value: '33',
      },
      {
        text: 'Chhukha',
        value: '12',
      },
      {
        text: 'Dagana',
        value: '22',
      },
      {
        text: 'Gasa',
        value: 'GA',
      },
      {
        text: 'Haa',
        value: '13',
      },
      {
        text: 'Lhuntse',
        value: '44',
      },
      {
        text: 'Mongar',
        value: '42',
      },
      {
        text: 'Paro',
        value: '11',
      },
      {
        text: 'Pemagatshel',
        value: '43',
      },
      {
        text: 'Punakha',
        value: '23',
      },
      {
        text: 'Samdrup Jongkhar',
        value: '45',
      },
      {
        text: 'Samtse',
        value: '14',
      },
      {
        text: 'Sarpang',
        value: '31',
      },
      {
        text: 'Thimphu',
        value: '15',
      },
      {
        text: 'Trashigang',
        value: '41',
      },
      {
        text: 'Trashiyangtse',
        value: 'TY',
      },
      {
        text: 'Trongsa',
        value: '32',
      },
      {
        text: 'Tsirang',
        value: '21',
      },
      {
        text: 'Wangdue Phodrang',
        value: '24',
      },
      {
        text: 'Zhemgang',
        value: '34',
      },
    ],
  },
  {
    text: 'Bolivia',
    value: 'BO',
    states: [
      {
        text: 'Beni',
        value: 'B',
      },
      {
        text: 'Chuquisaca',
        value: 'H',
      },
      {
        text: 'Cochabamba',
        value: 'C',
      },
      {
        text: 'La Paz',
        value: 'L',
      },
      {
        text: 'Oruro',
        value: 'O',
      },
      {
        text: 'Pando',
        value: 'N',
      },
      {
        text: 'Potosí',
        value: 'P',
      },
      {
        text: 'Santa Cruz',
        value: 'S',
      },
      {
        text: 'Tarija',
        value: 'T',
      },
    ],
  },
  {
    text: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    states: [
      {
        text: 'Bonaire',
        value: 'BO',
      },
      {
        text: 'Saba Isand',
        value: 'SA',
      },
      {
        text: 'Sint Eustatius',
        value: 'SE',
      },
    ],
  },
  {
    text: 'Bosnia and Herzegovina',
    value: 'BA',
    states: [
      {
        text: 'Brčko Distrikt',
        value: 'BRC',
      },
      {
        text: 'Federacija Bosne i Hercegovine',
        value: 'BIH',
      },
      {
        text: 'Republika Srpska',
        value: 'SRP',
      },
    ],
  },
  {
    text: 'Botswana',
    value: 'BW',
    states: [
      {
        text: 'Central',
        value: 'CE',
      },
      {
        text: 'Ghanzi',
        value: 'GH',
      },
      {
        text: 'Kgalagadi',
        value: 'KG',
      },
      {
        text: 'Kgatleng',
        value: 'KL',
      },
      {
        text: 'Kweneng',
        value: 'KW',
      },
      {
        text: 'North West',
        value: 'NW',
      },
      {
        text: 'North-East',
        value: 'NE',
      },
      {
        text: 'South East',
        value: 'SE',
      },
      {
        text: 'Southern',
        value: 'SO',
      },
    ],
  },
  {
    text: 'Bouvet Island',
    value: 'BV',
    states: [
      {
        text: 'Bouvet Island',
        value: 'BV',
      },
    ],
  },
  {
    text: 'Brazil',
    value: 'BR',
    states: [
      {
        text: 'Acre',
        value: 'AC',
      },
      {
        text: 'Alagoas',
        value: 'AL',
      },
      {
        text: 'Amapá',
        value: 'AP',
      },
      {
        text: 'Amazonas',
        value: 'AM',
      },
      {
        text: 'Bahia',
        value: 'BA',
      },
      {
        text: 'Ceará',
        value: 'CE',
      },
      {
        text: 'Distrito Federal',
        value: 'DF',
      },
      {
        text: 'Espírito Santo',
        value: 'ES',
      },
      {
        text: 'Goiás',
        value: 'GO',
      },
      {
        text: 'Maranhão',
        value: 'MA',
      },
      {
        text: 'Mato Grosso',
        value: 'MT',
      },
      {
        text: 'Mato Grosso do Sul',
        value: 'MS',
      },
      {
        text: 'Minas Gerais',
        value: 'MG',
      },
      {
        text: 'Pará',
        value: 'PA',
      },
      {
        text: 'Paraíba',
        value: 'PB',
      },
      {
        text: 'Paraná',
        value: 'PR',
      },
      {
        text: 'Pernambuco',
        value: 'PE',
      },
      {
        text: 'Piauí',
        value: 'PI',
      },
      {
        text: 'Rio de Janeiro',
        value: 'RJ',
      },
      {
        text: 'Rio Grande do Norte',
        value: 'RN',
      },
      {
        text: 'Rio Grande do Sul',
        value: 'RS',
      },
      {
        text: 'Rondônia',
        value: 'RO',
      },
      {
        text: 'Roraima',
        value: 'RR',
      },
      {
        text: 'Santa Catarina',
        value: 'SC',
      },
      {
        text: 'São Paulo',
        value: 'SP',
      },
      {
        text: 'Sergipe',
        value: 'SE',
      },
      {
        text: 'Tocantins',
        value: 'TO',
      },
    ],
  },
  {
    text: 'British Indian Ocean Territory',
    value: 'IO',
    states: [
      {
        text: 'British Indian Ocean Territory',
        value: 'IO',
      },
    ],
  },
  {
    text: 'Brunei Darussalam',
    value: 'BN',
    states: [
      {
        text: 'Belait',
        value: 'BE',
      },
      {
        text: 'Brunei Muara',
        value: 'BM',
      },
      {
        text: 'Temburong',
        value: 'TE',
      },
      {
        text: 'Tutong',
        value: 'TU',
      },
    ],
  },
  {
    text: 'Bulgaria',
    value: 'BG',
    states: [
      {
        text: 'Blagoevgrad',
        value: '01',
      },
      {
        text: 'Burgas',
        value: '02',
      },
      {
        text: 'Dobrich',
        value: '08',
      },
      {
        text: 'Gabrovo',
        value: '07',
      },
      {
        text: 'Jambol',
        value: '28',
      },
      {
        text: 'Khaskovo',
        value: '26',
      },
      {
        text: 'Kjustendil',
        value: '10',
      },
      {
        text: 'Kurdzhali',
        value: '09',
      },
      {
        text: 'Lovech',
        value: '11',
      },
      {
        text: 'Montana',
        value: '12',
      },
      {
        text: 'Pazardzhik',
        value: '13',
      },
      {
        text: 'Pernik',
        value: '14',
      },
      {
        text: 'Pleven',
        value: '15',
      },
      {
        text: 'Plovdiv',
        value: '16',
      },
      {
        text: 'Razgrad',
        value: '17',
      },
      {
        text: 'Ruse',
        value: '18',
      },
      {
        text: 'Shumen',
        value: '27',
      },
      {
        text: 'Silistra',
        value: '19',
      },
      {
        text: 'Sliven',
        value: '20',
      },
      {
        text: 'Smoljan',
        value: '21',
      },
      {
        text: 'Sofija',
        value: '23',
      },
      {
        text: 'Sofija-Grad',
        value: '22',
      },
      {
        text: 'Stara Zagora',
        value: '24',
      },
      {
        text: 'Turgovishhe',
        value: '25',
      },
      {
        text: 'Varna',
        value: '03',
      },
      {
        text: 'Veliko Turnovo',
        value: '04',
      },
      {
        text: 'Vidin',
        value: '05',
      },
      {
        text: 'Vraca',
        value: '06',
      },
    ],
  },
  {
    text: 'Burkina Faso',
    value: 'BF',
    states: [
      {
        text: 'Balé',
        value: 'BAL',
      },
      {
        text: 'Bam/Lake Bam',
        value: 'BAM',
      },
      {
        text: 'Banwa Province',
        value: 'BAN',
      },
      {
        text: 'Bazèga',
        value: 'BAZ',
      },
      {
        text: 'Bougouriba',
        value: 'BGR',
      },
      {
        text: 'Boulgou Province',
        value: 'BLG',
      },
      {
        text: 'Boulkiemdé',
        value: 'BLK',
      },
      {
        text: 'Comoé/Komoe',
        value: 'COM',
      },
      {
        text: 'Ganzourgou Province',
        value: 'GAN',
      },
      {
        text: 'Gnagna',
        value: 'GNA',
      },
      {
        text: 'Gourma Province',
        value: 'GOU',
      },
      {
        text: 'Houet',
        value: 'HOU',
      },
      {
        text: 'Ioba',
        value: 'IOB',
      },
      {
        text: 'Kadiogo',
        value: 'KAD',
      },
      {
        text: 'Kénédougou',
        value: 'KEN',
      },
      {
        text: 'Komondjari',
        value: 'KMD',
      },
      {
        text: 'Kompienga',
        value: 'KMP',
      },
      {
        text: 'Kossi Province',
        value: 'KOS',
      },
      {
        text: 'Koulpélogo',
        value: 'KOP',
      },
      {
        text: 'Kouritenga',
        value: 'KOT',
      },
      {
        text: 'Kourwéogo',
        value: 'KOW',
      },
      {
        text: 'Léraba',
        value: 'LER',
      },
      {
        text: 'Loroum',
        value: 'LOR',
      },
      {
        text: 'Mouhoun',
        value: 'MOU',
      },
      {
        text: 'Namentenga',
        value: 'NAM',
      },
      {
        text: 'Naouri/Nahouri',
        value: 'NAO',
      },
      {
        text: 'Nayala',
        value: 'NAY',
      },
      {
        text: 'Noumbiel',
        value: 'NOU',
      },
      {
        text: 'Oubritenga',
        value: 'OUB',
      },
      {
        text: 'Oudalan',
        value: 'OUD',
      },
      {
        text: 'Passoré',
        value: 'PAS',
      },
      {
        text: 'Poni',
        value: 'PON',
      },
      {
        text: 'Sanguié',
        value: 'SNG',
      },
      {
        text: 'Sanmatenga',
        value: 'SMT',
      },
      {
        text: 'Séno',
        value: 'SEN',
      },
      {
        text: 'Sissili',
        value: 'SIS',
      },
      {
        text: 'Soum',
        value: 'SOM',
      },
      {
        text: 'Sourou',
        value: 'SOR',
      },
      {
        text: 'Tapoa',
        value: 'TAP',
      },
      {
        text: 'Tui/Tuy',
        value: 'TUI',
      },
      {
        text: 'Yagha',
        value: 'YAG',
      },
      {
        text: 'Yatenga',
        value: 'YAT',
      },
      {
        text: 'Ziro',
        value: 'ZIR',
      },
      {
        text: 'Zondoma',
        value: 'ZON',
      },
      {
        text: 'Zoundwéogo',
        value: 'ZOU',
      },
    ],
  },
  {
    text: 'Burundi',
    value: 'BI',
    states: [
      {
        text: 'Bubanza',
        value: 'BB',
      },
      {
        text: 'Bujumbura Mairie',
        value: 'BM',
      },
      {
        text: 'Bujumbura Rural',
        value: 'BL',
      },
      {
        text: 'Bururi',
        value: 'BR',
      },
      {
        text: 'Cankuzo',
        value: 'CA',
      },
      {
        text: 'Cibitoke',
        value: 'CI',
      },
      {
        text: 'Gitega',
        value: 'GI',
      },
      {
        text: 'Karuzi',
        value: 'KR',
      },
      {
        text: 'Kayanza',
        value: 'KY',
      },
      {
        text: 'Kirundo',
        value: 'KI',
      },
      {
        text: 'Makamba',
        value: 'MA',
      },
      {
        text: 'Muramvya',
        value: 'MU',
      },
      {
        text: 'Muyinga',
        value: 'MY',
      },
      {
        text: 'Mwaro',
        value: 'MW',
      },
      {
        text: 'Ngozi',
        value: 'NG',
      },
      {
        text: 'Rutana',
        value: 'RT',
      },
      {
        text: 'Ruyigi',
        value: 'RY',
      },
    ],
  },
  {
    text: 'Cambodia',
    value: 'KH',
    states: [
      {
        text: 'Baat Dambang',
        value: '2',
      },
      {
        text: 'Banteay Mean Chey',
        value: '1',
      },
      {
        text: 'Kampong Chaam',
        value: '3',
      },
      {
        text: 'Kampong Chhnang',
        value: '4',
      },
      {
        text: 'Kampong Spueu',
        value: '5',
      },
      {
        text: 'Kampong Thum',
        value: '6',
      },
      {
        text: 'Kampot',
        value: '7',
      },
      {
        text: 'Kandaal',
        value: '8',
      },
      {
        text: 'Kaoh Kong',
        value: '9',
      },
      {
        text: 'Kracheh',
        value: '10',
      },
      {
        text: 'Krong Kaeb',
        value: '23',
      },
      {
        text: 'Krong Pailin',
        value: '24',
      },
      {
        text: 'Krong Preah Sihanouk',
        value: '18',
      },
      {
        text: 'Mondol Kiri',
        value: '11',
      },
      {
        text: 'Otdar Mean Chey',
        value: '22',
      },
      {
        text: 'Phnom Penh',
        value: '12',
      },
      {
        text: 'Pousaat',
        value: '15',
      },
      {
        text: 'Preah Vihear',
        value: '13',
      },
      {
        text: 'Prey Veaeng',
        value: '14',
      },
      {
        text: 'Rotanah Kiri',
        value: '16',
      },
      {
        text: 'Siem Reab',
        value: '17',
      },
      {
        text: 'Stueng Treng',
        value: '19',
      },
      {
        text: 'Svaay Rieng',
        value: '20',
      },
      {
        text: 'Taakaev',
        value: '21',
      },
      {
        text: 'Tbong Khmum',
        value: '25',
      },
    ],
  },
  {
    text: 'Cameroon',
    value: 'CM',
    states: [
      {
        text: 'Adamaoua',
        value: 'AD',
      },
      {
        text: 'Centre',
        value: 'CE',
      },
      {
        text: 'Est',
        value: 'ES',
      },
      {
        text: 'Extrême-Nord',
        value: 'EN',
      },
      {
        text: 'Littoral',
        value: 'LT',
      },
      {
        text: 'Nord',
        value: 'NO',
      },
      {
        text: 'Nord-Ouest',
        value: 'NW',
      },
      {
        text: 'Ouest',
        value: 'OU',
      },
      {
        text: 'Sud',
        value: 'SU',
      },
      {
        text: 'Sud-Ouest',
        value: 'SW',
      },
    ],
  },
  {
    text: 'Cape Verde',
    value: 'CV',
    states: [
      {
        text: 'Boa Vista',
        value: 'BV',
      },
      {
        text: 'Brava',
        value: 'BR',
      },
      {
        text: 'Calheta de São Miguel',
        value: 'CS',
      },
      {
        text: 'Maio',
        value: 'MA',
      },
      {
        text: 'Mosteiros',
        value: 'MO',
      },
      {
        text: 'Paúl',
        value: 'PA',
      },
      {
        text: 'Porto Novo',
        value: 'PN',
      },
      {
        text: 'Praia',
        value: 'PR',
      },
      {
        text: 'Ribeira Brava',
        value: 'RB',
      },
      {
        text: 'Ribeira Grande',
        value: 'RG',
      },
      {
        text: 'Sal',
        value: 'SL',
      },
      {
        text: 'Santa Catarina',
        value: 'CA',
      },
      {
        text: 'Santa Cruz',
        value: 'CR',
      },
      {
        text: 'São Domingos',
        value: 'SD',
      },
      {
        text: 'São Filipe',
        value: 'SF',
      },
      {
        text: 'São Nicolau',
        value: 'SN',
      },
      {
        text: 'São Vicente',
        value: 'SV',
      },
      {
        text: 'Tarrafal',
        value: 'TA',
      },
      {
        text: 'Tarrafal de São Nicolau',
        value: 'TS',
      },
    ],
  },
  {
    text: 'Cayman Islands',
    value: 'KY',
    states: [
      {
        text: 'Creek',
      },
      {
        text: 'Eastern',
      },
      {
        text: 'Midland',
      },
      {
        text: 'South Town',
      },
      {
        text: 'Spot Bay',
      },
      {
        text: 'Stake Bay',
      },
      {
        text: 'West End',
      },
      {
        text: 'Western',
      },
    ],
  },
  {
    text: 'Central African Republic',
    value: 'CF',
    states: [
      {
        text: 'Bamingui-Bangoran',
        value: 'BB',
      },
      {
        text: 'Bangui',
        value: 'BGF',
      },
      {
        text: 'Basse-Kotto',
        value: 'BK',
      },
      {
        text: 'Haute-Kotto',
        value: 'HK',
      },
      {
        text: 'Haut-Mbomou',
        value: 'HM',
      },
      {
        text: 'Kémo',
        value: 'KG',
      },
      {
        text: 'Lobaye',
        value: 'LB',
      },
      {
        text: 'Mambéré-Kadéï',
        value: 'HS',
      },
      {
        text: 'Mbomou',
        value: 'MB',
      },
      {
        text: 'Nana-Grebizi',
        value: '10',
      },
      {
        text: 'Nana-Mambéré',
        value: 'NM',
      },
      {
        text: "Ombella-M'Poko",
        value: 'MP',
      },
      {
        text: 'Ouaka',
        value: 'UK',
      },
      {
        text: 'Ouham',
        value: 'AC',
      },
      {
        text: 'Ouham Péndé',
        value: 'OP',
      },
      {
        text: 'Sangha-Mbaéré',
        value: 'SE',
      },
      {
        text: 'Vakaga',
        value: 'VK',
      },
    ],
  },
  {
    text: 'Chad',
    value: 'TD',
    states: [
      {
        text: 'Bahr el Ghazal',
        value: 'BG',
      },
      {
        text: 'Batha',
        value: 'BA',
      },
      {
        text: 'Borkou',
        value: 'BO',
      },
      {
        text: 'Chari-Baguirmi',
        value: 'CB',
      },
      {
        text: 'Ennedi-Est',
        value: 'EE',
      },
      {
        text: 'Ennedi-Ouest',
        value: 'EO',
      },
      {
        text: 'Guéra',
        value: 'GR',
      },
      {
        text: 'Hadjer Lamis',
        value: 'HL',
      },
      {
        text: 'Kanem',
        value: 'KA',
      },
      {
        text: 'Lac',
        value: 'LC',
      },
      {
        text: 'Logone Occidental',
        value: 'LO',
      },
      {
        text: 'Logone Oriental',
        value: 'LR',
      },
      {
        text: 'Mondoul',
        value: 'MA',
      },
      {
        text: 'Mayo-Kébbi-Est',
        value: 'ME',
      },
      {
        text: 'Moyen-Chari',
        value: 'MC',
      },
      {
        text: 'Ouaddai',
        value: 'OD',
      },
      {
        text: 'Salamat',
        value: 'SA',
      },
      {
        text: 'Sila',
        value: 'SI',
      },
      {
        text: 'Tandjilé',
        value: 'TA',
      },
      {
        text: 'Tibesti',
        value: 'TI',
      },
      {
        text: 'Ville de Ndjamena',
        value: 'ND',
      },
      {
        text: 'Wadi Fira',
        value: 'WF',
      },
    ],
  },
  {
    text: 'Chile',
    value: 'CL',
    states: [
      {
        text: 'Aisén del General Carlos Ibáñez del Campo',
        value: 'AI',
      },
      {
        text: 'Antofagasta',
        value: 'AN',
      },
      {
        text: 'Araucanía',
        value: 'AR',
      },
      {
        text: 'Arica y Parinacota',
        value: 'AP',
      },
      {
        text: 'Atacama',
        value: 'AT',
      },
      {
        text: 'Bío-Bío',
        value: 'BI',
      },
      {
        text: 'Coquimbo',
        value: 'CO',
      },
      {
        text: "Libertador General Bernardo O'Higgins",
        value: 'LI',
      },
      {
        text: 'Los Lagos',
        value: 'LL',
      },
      {
        text: 'Los Ríos',
        value: 'LR',
      },
      {
        text: 'Magallanes y Antartica Chilena',
        value: 'MA',
      },
      {
        text: 'Marga-Marga',
        value: '',
      },
      {
        text: 'Maule',
        value: 'ML',
      },
      {
        text: 'Región Metropolitana de Santiago',
        value: 'RM',
      },
      {
        text: 'Tarapacá',
        value: 'TA',
      },
      {
        text: 'Valparaíso',
        value: 'VS',
      },
    ],
  },
  {
    text: 'China',
    value: 'CN',
    states: [
      {
        text: 'Anhui',
        value: '34',
      },
      {
        text: 'Beijing',
        value: '11',
      },
      {
        text: 'Chongqing',
        value: '50',
      },
      {
        text: 'Fujian',
        value: '35',
      },
      {
        text: 'Gansu',
        value: '62',
      },
      {
        text: 'Guangdong',
        value: '44',
      },
      {
        text: 'Guangxi',
        value: '45',
      },
      {
        text: 'Guizhou',
        value: '52',
      },
      {
        text: 'Hainan',
        value: '46',
      },
      {
        text: 'Hebei',
        value: '13',
      },
      {
        text: 'Heilongjiang',
        value: '23',
      },
      {
        text: 'Henan',
        value: '41',
      },
      {
        text: 'Hong Kong',
        value: '91',
      },
      {
        text: 'Hubei',
        value: '42',
      },
      {
        text: 'Hunan',
        value: '43',
      },
      {
        text: 'Inner Mongolia',
        value: '15',
      },
      {
        text: 'Jiangsu',
        value: '32',
      },
      {
        text: 'Jiangxi',
        value: '36',
      },
      {
        text: 'Jilin',
        value: '22',
      },
      {
        text: 'Liaoning',
        value: '21',
      },
      {
        text: 'Macau',
        value: '92',
      },
      {
        text: 'Ningxia',
        value: '64',
      },
      {
        text: 'Qinghai',
        value: '63',
      },
      {
        text: 'Shaanxi',
        value: '61',
      },
      {
        text: 'Shandong',
        value: '37',
      },
      {
        text: 'Shanghai',
        value: '31',
      },
      {
        text: 'Shanxi',
        value: '14',
      },
      {
        text: 'Sichuan',
        value: '51',
      },
      {
        text: 'Tianjin',
        value: '12',
      },
      {
        text: 'Tibet',
        value: '54',
      },
      {
        text: 'Xinjiang',
        value: '65',
      },
      {
        text: 'Yunnan',
        value: '53',
      },
      {
        text: 'Zhejiang',
        value: '33',
      },
    ],
  },
  {
    text: 'Christmas Island',
    value: 'CX',
    states: [
      {
        text: 'Christmas Island',
        value: 'CX',
      },
    ],
  },
  {
    text: 'Cocos (Keeling) Islands',
    value: 'CC',
    states: [
      {
        text: 'Direction Island',
        value: 'DI',
      },
      {
        text: 'Home Island',
        value: 'HM',
      },
      {
        text: 'Horsburgh Island',
        value: 'HR',
      },
      {
        text: 'North Keeling Island',
        value: 'NK',
      },
      {
        text: 'South Island',
        value: 'SI',
      },
      {
        text: 'West Island',
        value: 'WI',
      },
    ],
  },
  {
    text: 'Colombia',
    value: 'CO',
    states: [
      {
        text: 'Amazonas',
        value: 'AMA',
      },
      {
        text: 'Antioquia',
        value: 'ANT',
      },
      {
        text: 'Arauca',
        value: 'ARA',
      },
      {
        text: 'Archipiélago de San Andrés',
        value: 'SAP',
      },
      {
        text: 'Atlántico',
        value: 'ATL',
      },
      {
        text: 'Bogotá D.C.',
        value: 'DC',
      },
      {
        text: 'Bolívar',
        value: 'BOL',
      },
      {
        text: 'Boyacá',
        value: 'BOY',
      },
      {
        text: 'Caldas',
        value: 'CAL',
      },
      {
        text: 'Caquetá',
        value: 'CAQ',
      },
      {
        text: 'Casanare',
        value: 'CAS',
      },
      {
        text: 'Cauca',
        value: 'CAU',
      },
      {
        text: 'Cesar',
        value: 'CES',
      },
      {
        text: 'Chocó',
        value: 'CHO',
      },
      {
        text: 'Córdoba',
        value: 'COR',
      },
      {
        text: 'Cundinamarca',
        value: 'CUN',
      },
      {
        text: 'Guainía',
        value: 'GUA',
      },
      {
        text: 'Guaviare',
        value: 'GUV',
      },
      {
        text: 'Huila',
        value: 'HUI',
      },
      {
        text: 'La Guajira',
        value: 'LAG',
      },
      {
        text: 'Magdalena',
        value: 'MAG',
      },
      {
        text: 'Meta',
        value: 'MET',
      },
      {
        text: 'Nariño',
        value: 'NAR',
      },
      {
        text: 'Norte de Santander',
        value: 'NSA',
      },
      {
        text: 'Putumayo',
        value: 'PUT',
      },
      {
        text: 'Quindío',
        value: 'QUI',
      },
      {
        text: 'Risaralda',
        value: 'RIS',
      },
      {
        text: 'Santander',
        value: 'SAN',
      },
      {
        text: 'Sucre',
        value: 'SUC',
      },
      {
        text: 'Tolima',
        value: 'TOL',
      },
      {
        text: 'Valle del Cauca',
        value: 'VAC',
      },
      {
        text: 'Vaupés',
        value: 'VAU',
      },
      {
        text: 'Vichada',
        value: 'VID',
      },
    ],
  },
  {
    text: 'Comoros',
    value: 'KM',
    states: [
      {
        text: 'Andjazîdja',
        value: 'G',
      },
      {
        text: 'Andjouân',
        value: 'A',
      },
      {
        text: 'Moûhîlî',
        value: 'M',
      },
    ],
  },
  {
    text: 'Congo, Republic of the (Brazzaville)',
    value: 'CG',
    states: [
      {
        text: 'Bouenza',
        value: '11',
      },
      {
        text: 'Brazzaville',
        value: 'BZV',
      },
      {
        text: 'Cuvette',
        value: '8',
      },
      {
        text: 'Cuvette-Ouest',
        value: '15',
      },
      {
        text: 'Kouilou',
        value: '5',
      },
      {
        text: 'Lékoumou',
        value: '2',
      },
      {
        text: 'Likouala',
        value: '7',
      },
      {
        text: 'Niari',
        value: '9',
      },
      {
        text: 'Plateaux',
        value: '14',
      },
      {
        text: 'Pointe-Noire',
        value: '16',
      },
      {
        text: 'Pool',
        value: '12',
      },
      {
        text: 'Sangha',
        value: '13',
      },
    ],
  },
  {
    text: 'Congo, the Democratic Republic of the (Kinshasa)',
    value: 'CD',
    states: [
      {
        text: 'Bandundu',
        value: 'BN',
      },
      {
        text: 'Bas-Congo',
        value: 'BC',
      },
      {
        text: 'Équateur',
        value: 'EQ',
      },
      {
        text: 'Kasaï-Occidental',
        value: 'KE',
      },
      {
        text: 'Kasaï-Oriental',
        value: 'KW',
      },
      {
        text: 'Katanga',
        value: 'KA',
      },
      {
        text: 'Kinshasa',
        value: 'KN',
      },
      {
        text: 'Maniema',
        value: 'MA',
      },
      {
        text: 'Nord-Kivu',
        value: 'NK',
      },
      {
        text: 'Orientale',
        value: 'OR',
      },
      {
        text: 'Sud-Kivu',
        value: 'SK',
      },
    ],
  },
  {
    text: 'Cook Islands',
    value: 'CK',
    states: [
      {
        text: 'Aitutaki',
      },
      {
        text: 'Atiu',
      },
      {
        text: 'Avarua',
      },
      {
        text: 'Mangaia',
      },
      {
        text: 'Manihiki',
      },
      {
        text: "Ma'uke",
      },
      {
        text: 'Mitiaro',
      },
      {
        text: 'Nassau',
      },
      {
        text: 'Palmerston',
      },
      {
        text: 'Penrhyn',
      },
      {
        text: 'Pukapuka',
      },
      {
        text: 'Rakahanga',
      },
    ],
  },
  {
    text: 'Costa Rica',
    value: 'CR',
    states: [
      {
        text: 'Alajuela',
        value: '2',
      },
      {
        text: 'Cartago',
        value: '3',
      },
      {
        text: 'Guanacaste',
        value: '5',
      },
      {
        text: 'Heredia',
        value: '4',
      },
      {
        text: 'Limón',
        value: '7',
      },
      {
        text: 'Puntarenas',
        value: '6',
      },
      {
        text: 'San José',
        value: '1',
      },
    ],
  },
  {
    text: "Côte d'Ivoire, Republic of",
    value: 'CI',
    states: [
      {
        text: 'Agnéby',
        value: '16',
      },
      {
        text: 'Bafing',
        value: '17',
      },
      {
        text: 'Bas-Sassandra',
        value: '09',
      },
      {
        text: 'Denguélé',
        value: '10',
      },
      {
        text: 'Dix-Huit Montagnes',
        value: '06',
      },
      {
        text: 'Fromager',
        value: '18',
      },
      {
        text: 'Haut-Sassandra',
        value: '02',
      },
      {
        text: 'Lacs',
        value: '07',
      },
      {
        text: 'Lagunes',
        value: '01',
      },
      {
        text: 'Marahoué',
        value: '12',
      },
      {
        text: 'Moyen-Cavally',
        value: '19',
      },
      {
        text: 'Moyen-Comoé',
        value: '05',
      },
      {
        text: "N'zi-Comoé",
        value: '11',
      },
      {
        text: 'Savanes',
        value: '03',
      },
      {
        text: 'Sud-Bandama',
        value: '15',
      },
      {
        text: 'Sud-Comoé',
        value: '13',
      },
      {
        text: 'Vallée du Bandama',
        value: '04',
      },
      {
        text: 'Worodougou',
        value: '14',
      },
      {
        text: 'Zanzan',
        value: '08',
      },
    ],
  },
  {
    text: 'Croatia',
    value: 'HR',
    states: [
      {
        text: 'Bjelovarsko-Bilogorska Županija',
        value: '07',
      },
      {
        text: 'Brodsko-Posavska Županija',
        value: '12',
      },
      {
        text: 'Dubrovačko-Neretvanska Županija',
        value: '19',
      },
      {
        text: 'Grad Zagreb',
        value: '21',
      },
      {
        text: 'Istarska Županija',
        value: '18',
      },
      {
        text: 'Karlovačka Županija',
        value: '04',
      },
      {
        text: 'Koprivničko-Krizevačka Županija',
        value: '06',
      },
      {
        text: 'Krapinsko-Zagorska Županija',
        value: '02',
      },
      {
        text: 'Ličko-Senjska Županija',
        value: '09',
      },
      {
        text: 'Međimurska Županija',
        value: '20',
      },
      {
        text: 'Osječko-Baranjska Županija',
        value: '14',
      },
      {
        text: 'Požeško-Slavonska Županija',
        value: '11',
      },
      {
        text: 'Primorsko-Goranska Županija',
        value: '08',
      },
      {
        text: 'Sisačko-Moslavačka Županija',
        value: '03',
      },
      {
        text: 'Splitsko-Dalmatinska Županija',
        value: '17',
      },
      {
        text: 'Sibensko-Kninska Županija',
        value: '15',
      },
      {
        text: 'Varaždinska Županija',
        value: '05',
      },
      {
        text: 'Virovitičko-Podravska Županija',
        value: '10',
      },
      {
        text: 'Vukovarsko-Srijemska Županija',
        value: '16',
      },
      {
        text: 'Zadarska Županija',
        value: '13',
      },
      {
        text: 'Zagrebacka Zupanija',
        value: '01',
      },
    ],
  },
  {
    text: 'Cuba',
    value: 'CU',
    states: [
      {
        text: 'Artemisa',
        value: '15',
      },
      {
        text: 'Camagüey',
        value: '09',
      },
      {
        text: 'Ciego de Ávila',
        value: '08',
      },
      {
        text: 'Cienfuegos',
        value: '06',
      },
      {
        text: 'Granma',
        value: '12',
      },
      {
        text: 'Guantánamo',
        value: '14',
      },
      {
        text: 'Holguín',
        value: '11',
      },
      {
        text: 'Isla de la Juventud',
        value: '99',
      },
      {
        text: 'La Habana',
        value: '03',
      },
      {
        text: 'Las Tunas',
        value: '10',
      },
      {
        text: 'Matanzas',
        value: '04',
      },
      {
        text: 'Mayabeque',
        value: '16',
      },
      {
        text: 'Pinar del Río',
        value: '01',
      },
      {
        text: 'Sancti Spíritus',
        value: '07',
      },
      {
        text: 'Santiago de Cuba',
        value: '13',
      },
      {
        text: 'Villa Clara',
        value: '05',
      },
    ],
  },
  {
    text: 'Curaçao',
    value: 'CW',
    states: [
      {
        text: 'Curaçao',
        value: 'CW',
      },
    ],
  },
  {
    text: 'Cyprus',
    value: 'CY',
    states: [
      {
        text: 'Ammochostos',
        value: '04',
      },
      {
        text: 'Keryneia',
        value: '05',
      },
      {
        text: 'Larnaka',
        value: '03',
      },
      {
        text: 'Lefkosia',
        value: '01',
      },
      {
        text: 'Lemesos',
        value: '02',
      },
      {
        text: 'Pafos',
        value: '05',
      },
    ],
  },
  {
    text: 'Czech Republic',
    value: 'CZ',
    states: [
      {
        text: 'Hlavní město Praha',
        value: 'PR',
      },
      {
        text: 'Jihočeský kraj',
        value: 'JC',
      },
      {
        text: 'Jihomoravský kraj',
        value: 'JM',
      },
      {
        text: 'Karlovarský kraj',
        value: 'KA',
      },
      {
        text: 'Královéhradecký kraj',
        value: 'KR',
      },
      {
        text: 'Liberecký kraj',
        value: 'LI',
      },
      {
        text: 'Moravskoslezský kraj',
        value: 'MO',
      },
      {
        text: 'Olomoucký kraj',
        value: 'OL',
      },
      {
        text: 'Pardubický kraj',
        value: 'PA',
      },
      {
        text: 'Plzeňský kraj',
        value: 'PL',
      },
      {
        text: 'Středočeský kraj',
        value: 'ST',
      },
      {
        text: 'Ústecký kraj',
        value: 'US',
      },
      {
        text: 'Vysočina',
        value: 'VY',
      },
      {
        text: 'Zlínský kraj',
        value: 'ZL',
      },
    ],
  },
  {
    text: 'Denmark',
    value: 'DK',
    states: [
      {
        text: 'Hovedstaden',
        value: '84',
      },
      {
        text: 'Kujalleq',
        value: 'GL-KU',
      },
      {
        text: 'Midtjylland',
        value: '82',
      },
      {
        text: 'Norderøerne',
        value: 'FO-01',
      },
      {
        text: 'Nordjylland',
        value: '81',
      },
      {
        text: 'Østerø',
        value: 'FO-06',
      },
      {
        text: 'Qaasuitsup',
        value: 'GL-QA',
      },
      {
        text: 'Qeqqata',
        value: 'GL-QE',
      },
      {
        text: 'Sandø',
        value: 'FO-02',
      },
      {
        text: 'Sermersooq',
        value: 'GL-SM',
      },
      {
        text: 'Sjælland',
        value: '85',
      },
      {
        text: 'Strømø',
        value: 'FO-03',
      },
      {
        text: 'Suderø',
        value: 'FO-04',
      },
      {
        text: 'Syddanmark',
        value: '83',
      },
      {
        text: 'Vågø',
        value: 'FO-05',
      },
    ],
  },
  {
    text: 'Djibouti',
    value: 'DJ',
    states: [
      {
        text: 'Ali Sabieh',
        value: 'AS',
      },
      {
        text: 'Arta',
        value: 'AR',
      },
      {
        text: 'Dikhil',
        value: 'DI',
      },
      {
        text: 'Obock',
        value: 'OB',
      },
      {
        text: 'Tadjourah',
        value: 'TA',
      },
    ],
  },
  {
    text: 'Dominica',
    value: 'DM',
    states: [
      {
        text: 'Saint Andrew Parish',
        value: '02',
      },
      {
        text: 'Saint David Parish',
        value: '03',
      },
      {
        text: 'Saint George Parish',
        value: '04',
      },
      {
        text: 'Saint John Parish',
        value: '05',
      },
      {
        text: 'Saint Joseph Parish',
        value: '06',
      },
      {
        text: 'Saint Luke Parish',
        value: '07',
      },
      {
        text: 'Saint Mark Parish',
        value: '08',
      },
      {
        text: 'Saint Patrick Parish',
        value: '09',
      },
      {
        text: 'Saint Paul Parish',
        value: '10',
      },
      {
        text: 'Saint Peter Parish',
        value: '11',
      },
    ],
  },
  {
    text: 'Dominican Republic',
    value: 'DO',
    states: [
      {
        text: 'Cibao Central',
        value: '02',
      },
      {
        text: 'Del Valle',
        value: '37',
      },
      {
        text: 'Distrito Nacional',
        value: '01',
      },
      {
        text: 'Enriquillo',
        value: '38',
      },
      {
        text: 'Norcentral',
        value: '04',
      },
      {
        text: 'Nordeste',
        value: '34',
      },
      {
        text: 'Noroeste',
        value: '34',
      },
      {
        text: 'Norte',
        value: '35',
      },
      {
        text: 'Valdesia',
        value: '42',
      },
    ],
  },
  {
    text: 'Ecuador',
    value: 'EC',
    states: [
      {
        text: 'Azuay',
        value: 'A',
      },
      {
        text: 'Bolívar',
        value: 'B',
      },
      {
        text: 'Cañar',
        value: 'F',
      },
      {
        text: 'Carchi',
        value: 'C',
      },
      {
        text: 'Chimborazo',
        value: 'H',
      },
      {
        text: 'Cotopaxi',
        value: 'X',
      },
      {
        text: 'El Oro',
        value: 'O',
      },
      {
        text: 'Esmeraldas',
        value: 'E',
      },
      {
        text: 'Galápagos',
        value: 'W',
      },
      {
        text: 'Guayas',
        value: 'G',
      },
      {
        text: 'Imbabura',
        value: 'I',
      },
      {
        text: 'Loja',
        value: 'L',
      },
      {
        text: 'Los Ríos',
        value: 'R',
      },
      {
        text: 'Manabí',
        value: 'M',
      },
      {
        text: 'Morona-Santiago',
        value: 'S',
      },
      {
        text: 'Napo',
        value: 'N',
      },
      {
        text: 'Orellana',
        value: 'D',
      },
      {
        text: 'Pastaza',
        value: 'Y',
      },
      {
        text: 'Pichincha',
        value: 'P',
      },
      {
        text: 'Santa Elena',
        value: 'SE',
      },
      {
        text: 'Santo Domingo de los Tsáchilas',
        value: 'SD',
      },
      {
        text: 'Sucumbíos',
        value: 'U',
      },
      {
        text: 'Tungurahua',
        value: 'T',
      },
      {
        text: 'Zamora-Chinchipe',
        value: 'Z',
      },
    ],
  },
  {
    text: 'Egypt',
    value: 'EG',
    states: [
      {
        text: 'Alexandria',
        value: 'ALX',
      },
      {
        text: 'Aswan',
        value: 'ASN',
      },
      {
        text: 'Asyout',
        value: 'AST',
      },
      {
        text: 'Bani Sueif',
        value: 'BNS',
      },
      {
        text: 'Beheira',
        value: 'BH',
      },
      {
        text: 'Cairo',
        value: 'C',
      },
      {
        text: 'Daqahlia',
        value: 'DK',
      },
      {
        text: 'Dumiat',
        value: 'DT',
      },
      {
        text: 'El Bahr El Ahmar',
        value: 'BA',
      },
      {
        text: 'El Ismailia',
        value: 'IS',
      },
      {
        text: 'El Suez',
        value: 'SUZ',
      },
      {
        text: 'El Wadi El Gedeed',
        value: 'WAD',
      },
      {
        text: 'Fayoum',
        value: 'FYM',
      },
      {
        text: 'Gharbia',
        value: 'GH',
      },
      {
        text: 'Giza',
        value: 'SUZ',
      },
      {
        text: 'Helwan',
        value: 'HU',
      },
      {
        text: 'Kafr El Sheikh',
        value: 'KFS',
      },
      {
        text: 'Luxor',
        value: 'LX',
      },
      {
        text: 'Matrouh',
        value: 'MT',
      },
      {
        text: 'Menia',
        value: 'MN',
      },
      {
        text: 'Menofia',
        value: 'MNF',
      },
      {
        text: 'North Sinai',
        value: 'SIN',
      },
      {
        text: 'Port Said',
        value: 'PTS',
      },
      {
        text: 'Qalubia',
        value: 'KB',
      },
      {
        text: 'Qena',
        value: 'KN',
      },
      {
        text: 'Sharqia',
        value: 'SHR',
      },
      {
        text: 'Sixth of October',
        value: 'SU',
      },
      {
        text: 'Sohag',
        value: 'SHG',
      },
      {
        text: 'South Sinai',
        value: 'JS',
      },
    ],
  },
  {
    text: 'El Salvador',
    value: 'SV',
    states: [
      {
        text: 'Ahuachapán',
        value: 'AH',
      },
      {
        text: 'Cabañas',
        value: 'CA',
      },
      {
        text: 'Cuscatlán',
        value: 'CU',
      },
      {
        text: 'Chalatenango',
        value: 'CH',
      },
      {
        text: 'La Libertad',
        value: 'LI',
      },
      {
        text: 'La Paz',
        value: 'PA',
      },
      {
        text: 'La Unión',
        value: 'UN',
      },
      {
        text: 'Morazán',
        value: 'MO',
      },
      {
        text: 'San Miguel',
        value: 'SM',
      },
      {
        text: 'San Salvador',
        value: 'SS',
      },
      {
        text: 'Santa Ana',
        value: 'SA',
      },
      {
        text: 'San Vicente',
        value: 'SV',
      },
      {
        text: 'Sonsonate',
        value: 'SO',
      },
      {
        text: 'Usulután',
        value: 'US',
      },
    ],
  },
  {
    text: 'Equatorial Guinea',
    value: 'GQ',
    states: [
      {
        text: 'Annobón',
        value: 'AN',
      },
      {
        text: 'Bioko Norte',
        value: 'BN',
      },
      {
        text: 'Bioko Sur',
        value: 'BS',
      },
      {
        text: 'Centro Sur',
        value: 'CS',
      },
      {
        text: 'Kié-Ntem',
        value: 'KN',
      },
      {
        text: 'Litoral',
        value: 'LI',
      },
      {
        text: 'Wele-Nzas',
        value: 'WN',
      },
    ],
  },
  {
    text: 'Eritrea',
    value: 'ER',
    states: [
      {
        text: 'Anseba',
        value: 'AN',
      },
      {
        text: 'Debub',
        value: 'DU',
      },
      {
        text: 'Debub-Keih-Bahri',
        value: 'DK',
      },
      {
        text: 'Gash-Barka',
        value: 'GB',
      },
      {
        text: 'Maekel',
        value: 'MA',
      },
      {
        text: 'Semien-Keih-Bahri',
        value: 'SK',
      },
    ],
  },
  {
    text: 'Estonia',
    value: 'EE',
    states: [
      {
        text: 'Harjumaa (Tallinn)',
        value: '37',
      },
      {
        text: 'Hiiumaa (Kardla)',
        value: '39',
      },
      {
        text: 'Ida-Virumaa (Johvi)',
        value: '44',
      },
      {
        text: 'Järvamaa (Paide)',
        value: '41',
      },
      {
        text: 'Jõgevamaa (Jogeva)',
        value: '49',
      },
      {
        text: 'Läänemaa',
        value: '57',
      },
      {
        text: 'Lääne-Virumaa (Rakvere)',
        value: '59',
      },
      {
        text: 'Pärnumaa (Parnu)',
        value: '67',
      },
      {
        text: 'Põlvamaa (Polva)',
        value: '65',
      },
      {
        text: 'Raplamaa (Rapla)',
        value: '70',
      },
      {
        text: 'Saaremaa (Kuessaare)',
        value: '74',
      },
      {
        text: 'Tartumaa (Tartu)',
        value: '78',
      },
      {
        text: 'Valgamaa (Valga)',
        value: '82',
      },
      {
        text: 'Viljandimaa (Viljandi)',
        value: '84',
      },
      {
        text: 'Võrumaa (Voru)',
        value: '86',
      },
    ],
  },
  {
    text: 'Ethiopia',
    value: 'ET',
    states: [
      {
        text: 'Addis Ababa',
        value: 'AA',
      },
      {
        text: 'Afar',
        value: 'AF',
      },
      {
        text: 'Amhara',
        value: 'AM',
      },
      {
        text: 'Benshangul-Gumaz',
        value: 'BE',
      },
      {
        text: 'Dire Dawa',
        value: 'DD',
      },
      {
        text: 'Gambela',
        value: 'GA',
      },
      {
        text: 'Harari',
        value: 'HA',
      },
      {
        text: 'Oromia',
        value: 'OR',
      },
      {
        text: 'Somali',
        value: 'SO',
      },
      {
        text: "Southern Nations Nationalities and People's Region",
        value: 'SN',
      },
      {
        text: 'Tigray',
        value: 'TI',
      },
    ],
  },
  {
    text: 'Falkland Islands (Islas Malvinas)',
    value: 'FK',
    states: [
      {
        text: 'Falkland Islands (Islas Malvinas)',
      },
    ],
  },
  {
    text: 'Faroe Islands',
    value: 'FO',
    states: [
      {
        text: 'Bordoy',
      },
      {
        text: 'Eysturoy',
      },
      {
        text: 'Mykines',
      },
      {
        text: 'Sandoy',
      },
      {
        text: 'Skuvoy',
      },
      {
        text: 'Streymoy',
      },
      {
        text: 'Suduroy',
      },
      {
        text: 'Tvoroyri',
      },
      {
        text: 'Vagar',
      },
    ],
  },
  {
    text: 'Fiji',
    value: 'FJ',
    states: [
      {
        text: 'Ba',
        value: '01',
      },
      {
        text: 'Bua',
        value: '01',
      },
      {
        text: 'Cakaudrove',
        value: '03',
      },
      {
        text: 'Kadavu',
        value: '04',
      },
      {
        text: 'Lau',
        value: '05',
      },
      {
        text: 'Lomaiviti',
        value: '06',
      },
      {
        text: 'Macuata',
        value: '07',
      },
      {
        text: 'Nadroga and Navosa',
        value: '08',
      },
      {
        text: 'Naitasiri',
        value: '09',
      },
      {
        text: 'Namosi',
        value: '10',
      },
      {
        text: 'Ra',
        value: '011',
      },
      {
        text: 'Rewa',
        value: '12',
      },
      {
        text: 'Rotuma',
        value: 'R',
      },
      {
        text: 'Serua',
        value: '12',
      },
      {
        text: 'Tailevu',
        value: '14',
      },
    ],
  },
  {
    text: 'Finland',
    value: 'FI',
    states: [
      {
        text: 'Ahvenanmaan lääni',
        value: 'AL',
      },
      {
        text: 'Etelä-Suomen lääni',
        value: 'ES',
      },
      {
        text: 'Itä-Suomen lääni',
        value: 'IS',
      },
      {
        text: 'Länsi-Suomen lääni',
        value: 'LS',
      },
      {
        text: 'Lapin lääni',
        value: 'LL',
      },
      {
        text: 'Oulun lääni',
        value: 'OL',
      },
    ],
  },
  {
    text: 'France',
    value: 'FR',
    states: [
      {
        text: 'Auvergne-Rhône-Alpes',
        value: 'ARA',
      },
      {
        text: 'Bourgogne-Franche-Comté',
        value: 'BFC',
      },
      {
        text: 'Bretagne',
        value: 'BRE',
      },
      {
        text: 'Centre-Val de Loire',
        value: 'CVL',
      },
      {
        text: 'Corse',
        value: 'COR',
      },
      {
        text: 'Grand Est',
        value: 'GES',
      },
      {
        text: 'Hauts-de-France',
        value: 'HDF',
      },
      {
        text: 'Île-de-France',
        value: 'IDF',
      },
      {
        text: 'Normandie',
        value: 'NOR',
      },
      {
        text: 'Nouvelle-Aquitaine',
        value: 'NAQ',
      },
      {
        text: 'Occitanie',
        value: 'OCC',
      },
      {
        text: 'Pays de la Loire',
        value: 'PDL',
      },
      {
        text: "Provence-Alpes-Cote d'Azur",
        value: 'PAC',
      },
      {
        text: 'Clipperton',
        value: 'CP',
      },
      {
        text: 'Guadeloupe',
        value: 'GP',
      },
      {
        text: 'Guyane',
        value: 'GF',
      },
      {
        text: 'Martinique',
        value: 'MQ',
      },
      {
        text: 'Mayotte',
        value: 'YT',
      },
      {
        text: 'Novelle-Calédonie',
        value: 'NC',
      },
      {
        text: 'Polynésie',
        value: 'PF',
      },
      {
        text: 'Saint-Pierre-et-Miquelon',
        value: 'PM',
      },
      {
        text: 'Saint Barthélemy',
        value: 'BL',
      },
      {
        text: 'Saint Martin',
        value: 'MF',
      },
      {
        text: 'Réunion',
        value: 'RE',
      },
      {
        text: 'Terres Australes Françaises',
        value: 'TF',
      },
      {
        text: 'Wallis-et-Futuna',
        value: 'WF',
      },
    ],
  },
  {
    text: 'French Guiana',
    value: 'GF',
    states: [
      {
        text: 'French Guiana',
      },
    ],
  },
  {
    text: 'French Polynesia',
    value: 'PF',
    states: [
      {
        text: 'Archipel des Marquises',
      },
      {
        text: 'Archipel des Tuamotu',
      },
      {
        text: 'Archipel des Tubuai',
      },
      {
        text: 'Iles du Vent',
      },
      {
        text: 'Iles Sous-le-Vent',
      },
    ],
  },
  {
    text: 'French Southern and Antarctic Lands',
    value: 'TF',
    states: [
      {
        text: 'Adelie Land',
      },
      {
        text: 'Ile Crozet',
      },
      {
        text: 'Iles Kerguelen',
      },
      {
        text: 'Iles Saint-Paul et Amsterdam',
      },
    ],
  },
  {
    text: 'Gabon',
    value: 'GA',
    states: [
      {
        text: 'Estuaire',
        value: '1',
      },
      {
        text: 'Haut-Ogooué',
        value: '2',
      },
      {
        text: 'Moyen-Ogooué',
        value: '3',
      },
      {
        text: 'Ngounié',
        value: '4',
      },
      {
        text: 'Nyanga',
        value: '5',
      },
      {
        text: 'Ogooué-Ivindo',
        value: '6',
      },
      {
        text: 'Ogooué-Lolo',
        value: '7',
      },
      {
        text: 'Ogooué-Maritime',
        value: '8',
      },
      {
        text: 'Woleu-Ntem',
        value: '9',
      },
    ],
  },
  {
    text: 'Gambia, The',
    value: 'GM',
    states: [
      {
        text: 'Banjul',
        value: 'B',
      },
      {
        text: 'Central River',
        value: 'M',
      },
      {
        text: 'Lower River',
        value: 'L',
      },
      {
        text: 'North Bank',
        value: 'N',
      },
      {
        text: 'Upper River',
        value: 'U',
      },
      {
        text: 'Western',
        value: 'W',
      },
    ],
  },
  {
    text: 'Georgia',
    value: 'GE',
    states: [
      {
        text: 'Abkhazia (Sokhumi)',
        value: 'AB',
      },
      {
        text: "Ajaria (Bat'umi)",
        value: 'AJ',
      },
      {
        text: 'Guria',
        value: 'GU',
      },
      {
        text: 'Imereti',
        value: 'IM',
      },
      {
        text: "K'akheti",
        value: 'KA',
      },
      {
        text: 'Kvemo Kartli',
        value: 'KK',
      },
      {
        text: 'Mtshkheta-Mtianeti',
        value: 'MM',
      },
      {
        text: "Rach'a-Lexhkumi-KvemoSvaneti",
        value: 'RL',
      },
      {
        text: 'Samegrelo-Zemo Svaneti',
        value: 'SZ',
      },
      {
        text: 'Samtskhe-Javakheti',
        value: 'SJ',
      },
      {
        text: 'Shida Kartli',
        value: 'SK',
      },
      {
        text: 'Tbilisi',
        value: 'TB',
      },
    ],
  },
  {
    text: 'Germany',
    value: 'DE',
    states: [
      {
        text: 'Baden-Württemberg',
        value: 'BW',
      },
      {
        text: 'Bayern',
        value: 'BY',
      },
      {
        text: 'Berlin',
        value: 'BE',
      },
      {
        text: 'Brandenburg',
        value: 'BB',
      },
      {
        text: 'Bremen',
        value: 'HB',
      },
      {
        text: 'Hamburg',
        value: 'HH',
      },
      {
        text: 'Hessen',
        value: 'HE',
      },
      {
        text: 'Mecklenburg-Vorpommern',
        value: 'MV',
      },
      {
        text: 'Niedersachsen',
        value: 'NI',
      },
      {
        text: 'Nordrhein-Westfalen',
        value: 'NW',
      },
      {
        text: 'Rheinland-Pfalz',
        value: 'RP',
      },
      {
        text: 'Saarland',
        value: 'SL',
      },
      {
        text: 'Sachsen',
        value: 'SN',
      },
      {
        text: 'Sachsen-Anhalt',
        value: 'ST',
      },
      {
        text: 'Schleswig-Holstein',
        value: 'SH',
      },
      {
        text: 'Thüringen',
        value: 'TH',
      },
    ],
  },
  {
    text: 'Ghana',
    value: 'GH',
    states: [
      {
        text: 'Ahafo',
      },
      {
        text: 'Ashanti',
        value: 'AH',
      },
      {
        text: 'Bono',
      },
      {
        text: 'Bono East',
      },
      {
        text: 'Central',
        value: 'CP',
      },
      {
        text: 'Eastern',
        value: 'EP',
      },
      {
        text: 'Greater Accra',
        value: 'AA',
      },
      {
        text: 'Northern',
        value: 'NP',
      },
      {
        text: 'North East',
      },
      {
        text: 'Oti',
      },
      {
        text: 'Savannah',
      },
      {
        text: 'Upper East',
        value: 'UE',
      },
      {
        text: 'Upper West',
        value: 'UW',
      },
      {
        text: 'Volta',
        value: 'TV',
      },
      {
        text: 'Western',
        value: 'WP',
      },
      {
        text: 'Western North',
      },
    ],
  },
  {
    text: 'Gibraltar',
    value: 'GI',
    states: [
      {
        text: 'Gibraltar',
      },
    ],
  },
  {
    text: 'Greece',
    value: 'GR',
    states: [
      {
        text: 'Anatolikí Makedonía kai Thráki',
        value: 'A',
      },
      {
        text: 'Attikḯ',
        value: 'I',
      },
      {
        text: 'Dytikí Elláda',
        value: 'G',
      },
      {
        text: 'Dytikí Makedonía',
        value: 'C',
      },
      {
        text: 'Ionía Nísia',
        value: 'F',
      },
      {
        text: 'Kentrikí Makedonía',
        value: 'B',
      },
      {
        text: 'Krítí',
        value: 'M',
      },
      {
        text: 'Notío Aigaío',
        value: 'L',
      },
      {
        text: 'Peloponnísos',
        value: 'J',
      },
      {
        text: 'Stereá Elláda',
        value: 'H',
      },
      {
        text: 'Thessalía',
        value: 'E',
      },
      {
        text: 'Voreío Aigaío',
        value: 'K',
      },
      {
        text: 'Ípeiros',
        value: 'D',
      },
      {
        text: 'Ágion Óros',
        value: '69',
      },
    ],
  },
  {
    text: 'Greenland',
    value: 'GL',
    states: [
      {
        text: 'Kommune Kujalleq',
        value: 'KU',
      },
      {
        text: 'Kommuneqarfik Sermersooq',
        value: 'SM',
      },
      {
        text: 'Qaasuitsup Kommunia',
        value: 'QA',
      },
      {
        text: 'Qeqqata Kommunia',
        value: 'QE',
      },
    ],
  },
  {
    text: 'Grenada',
    value: 'GD',
    states: [
      {
        text: 'Saint Andrew',
        value: '01',
      },
      {
        text: 'Saint David',
        value: '02',
      },
      {
        text: 'Saint George',
        value: '03',
      },
      {
        text: 'Saint John',
        value: '04',
      },
      {
        text: 'Saint Mark',
        value: '05',
      },
      {
        text: 'Saint Patrick',
        value: '06',
      },
      {
        text: 'Southern Grenadine Islands',
        value: '10',
      },
    ],
  },
  {
    text: 'Guadeloupe',
    value: 'GP',
    states: [
      {
        text: 'Guadeloupe',
      },
    ],
  },
  {
    text: 'Guam',
    value: 'GU',
    states: [
      {
        text: 'Guam',
      },
    ],
  },
  {
    text: 'Guatemala',
    value: 'GT',
    states: [
      {
        text: 'Alta Verapaz',
        value: 'AV',
      },
      {
        text: 'Baja Verapaz',
        value: 'BV',
      },
      {
        text: 'Chimaltenango',
        value: 'CM',
      },
      {
        text: 'Chiquimula',
        value: 'CQ',
      },
      {
        text: 'El Progreso',
        value: 'PR',
      },
      {
        text: 'Escuintla',
        value: 'ES',
      },
      {
        text: 'Guatemala',
        value: 'GU',
      },
      {
        text: 'Huehuetenango',
        value: 'HU',
      },
      {
        text: 'Izabal',
        value: 'IZ',
      },
      {
        text: 'Jalapa',
        value: 'JA',
      },
      {
        text: 'Jutiapa',
        value: 'JU',
      },
      {
        text: 'Petén',
        value: 'PE',
      },
      {
        text: 'Quetzaltenango',
        value: 'QZ',
      },
      {
        text: 'Quiché',
        value: 'QC',
      },
      {
        text: 'Retalhuleu',
        value: 'Re',
      },
      {
        text: 'Sacatepéquez',
        value: 'SA',
      },
      {
        text: 'San Marcos',
        value: 'SM',
      },
      {
        text: 'Santa Rosa',
        value: 'SR',
      },
      {
        text: 'Sololá',
        value: 'SO',
      },
      {
        text: 'Suchitepéquez',
        value: 'SU',
      },
      {
        text: 'Totonicapán',
        value: 'TO',
      },
      {
        text: 'Zacapa',
        value: 'ZA',
      },
    ],
  },
  {
    text: 'Guernsey',
    value: 'GG',
    states: [
      {
        text: 'Castel',
      },
      {
        text: 'Forest',
      },
      {
        text: 'St. Andrew',
      },
      {
        text: 'St. Martin',
      },
      {
        text: 'St. Peter Port',
      },
      {
        text: 'St. Pierre du Bois',
      },
      {
        text: 'St. Sampson',
      },
      {
        text: 'St. Saviour',
      },
      {
        text: 'Torteval',
      },
      {
        text: 'Vale',
      },
    ],
  },
  {
    text: 'Guinea',
    value: 'GN',
    states: [
      {
        text: 'Boké',
        value: 'B',
      },
      {
        text: 'Conakry',
        value: 'C',
      },
      {
        text: 'Faranah',
        value: 'F',
      },
      {
        text: 'Kankan',
        value: 'K',
      },
      {
        text: 'Kindia',
        value: 'D',
      },
      {
        text: 'Labé',
        value: 'L',
      },
      {
        text: 'Mamou',
        value: 'M',
      },
      {
        text: 'Nzérékoré',
        value: 'N',
      },
    ],
  },
  {
    text: 'Guinea-Bissau',
    value: 'GW',
    states: [
      {
        text: 'Bafatá',
        value: 'BA',
      },
      {
        text: 'Biombo',
        value: 'BM',
      },
      {
        text: 'Bissau',
        value: 'BS',
      },
      {
        text: 'Bolama-Bijagos',
        value: 'BL',
      },
      {
        text: 'Cacheu',
        value: 'CA',
      },
      {
        text: 'Gabú',
        value: 'GA',
      },
      {
        text: 'Oio',
        value: 'OI',
      },
      {
        text: 'Quinara',
        value: 'QU',
      },
      {
        text: 'Tombali',
        value: 'TO',
      },
    ],
  },
  {
    text: 'Guyana',
    value: 'GY',
    states: [
      {
        text: 'Barima-Waini',
        value: 'BA',
      },
      {
        text: 'Cuyuni-Mazaruni',
        value: 'CU',
      },
      {
        text: 'Demerara-Mahaica',
        value: 'DE',
      },
      {
        text: 'East Berbice-Corentyne',
        value: 'EB',
      },
      {
        text: 'Essequibo Islands-West Demerara',
        value: 'ES',
      },
      {
        text: 'Mahaica-Berbice',
        value: 'MA',
      },
      {
        text: 'Pomeroon-Supenaam',
        value: 'PM',
      },
      {
        text: 'Potaro-Siparuni',
        value: 'PT',
      },
      {
        text: 'Upper Demerara-Berbice',
        value: 'UD',
      },
      {
        text: 'Upper Takutu-Upper Essequibo',
        value: 'UT',
      },
    ],
  },
  {
    text: 'Haiti',
    value: 'HT',
    states: [
      {
        text: 'Artibonite',
        value: 'AR',
      },
      {
        text: 'Centre',
        value: 'CE',
      },
      {
        text: "Grand'Anse",
        value: 'GA',
      },
      { text: 'Nippes', value: 'NI' },
      {
        text: 'Nord',
        value: 'ND',
      },
      {
        text: 'Nord-Est',
        value: 'NE',
      },
      {
        text: 'Nord-Ouest',
        value: 'NO',
      },
      {
        text: 'Ouest',
        value: 'OU',
      },
      {
        text: 'Sud',
        value: 'SD',
      },
      {
        text: 'Sud-Est',
        value: 'SE',
      },
    ],
  },
  {
    text: 'Heard Island and McDonald Islands',
    value: 'HM',
    states: [
      {
        text: 'Heard Island and McDonald Islands',
      },
    ],
  },
  {
    text: 'Holy See (Vatican City)',
    value: 'VA',
    states: [
      {
        text: 'Holy See (Vatican City)',
        value: '01',
      },
    ],
  },
  {
    text: 'Honduras',
    value: 'HN',
    states: [
      {
        text: 'Atlántida',
        value: 'AT',
      },
      {
        text: 'Choluteca',
        value: 'CH',
      },
      {
        text: 'Colón',
        value: 'CL',
      },
      {
        text: 'Comayagua',
        value: 'CM',
      },
      {
        text: 'Copán',
        value: 'CP',
      },
      {
        text: 'Cortés',
        value: 'CR',
      },
      {
        text: 'El Paraíso',
        value: 'EP',
      },
      {
        text: 'Francisco Morazan',
        value: 'FM',
      },
      {
        text: 'Gracias a Dios',
        value: 'GD',
      },
      {
        text: 'Intibucá',
        value: 'IN',
      },
      {
        text: 'Islas de la Bahía',
        value: 'IB',
      },
      {
        text: 'La Paz',
        value: 'LP',
      },
      {
        text: 'Lempira',
        value: 'LE',
      },
      {
        text: 'Ocotepeque',
        value: 'OC',
      },
      {
        text: 'Olancho',
        value: 'OL',
      },
      {
        text: 'Santa Bárbara',
        value: 'SB',
      },
      {
        text: 'Valle',
        value: 'VA',
      },
      {
        text: 'Yoro',
        value: 'YO',
      },
    ],
  },
  {
    text: 'Hong Kong',
    value: 'HK',
    states: [
      {
        text: 'Hong Kong',
        value: 'HK',
      },
    ],
  },
  {
    text: 'Hungary',
    value: 'HU',
    states: [
      {
        text: 'Bács-Kiskun',
        value: 'BK',
      },
      {
        text: 'Baranya',
        value: 'BA',
      },
      {
        text: 'Békés',
        value: 'BE',
      },
      {
        text: 'Békéscsaba',
        value: 'BC',
      },
      {
        text: 'Borsod-Abauj-Zemplen',
        value: 'BZ',
      },
      {
        text: 'Budapest',
        value: 'BU',
      },
      {
        text: 'Csongrád',
        value: 'CS',
      },
      {
        text: 'Debrecen',
        value: 'DE',
      },
      {
        text: 'Dunaújváros',
        value: 'DU',
      },
      {
        text: 'Eger',
        value: 'EG',
      },
      {
        text: 'Érd',
        value: 'ER',
      },
      {
        text: 'Fejér',
        value: 'FE',
      },
      {
        text: 'Győr',
        value: 'GY',
      },
      {
        text: 'Győr-Moson-Sopron',
        value: 'GS',
      },
      {
        text: 'Hajdú-Bihar',
        value: 'HB',
      },
      {
        text: 'Heves',
        value: 'HE',
      },
      {
        text: 'Hódmezővásárhely',
        value: 'HV',
      },
      {
        text: 'Jász-Nagykun-Szolnok',
        value: 'N',
      },
      {
        text: 'Kaposvár',
        value: 'KV',
      },
      {
        text: 'Kecskemét',
        value: 'KM',
      },
      {
        text: 'Komárom-Esztergom',
        value: 'KE',
      },
      {
        text: 'Miskolc',
        value: 'MI',
      },
      {
        text: 'Nagykanizsa',
        value: 'NK',
      },
      {
        text: 'Nógrád',
        value: 'NO',
      },
      {
        text: 'Nyíregyháza',
        value: 'NY',
      },
      {
        text: 'Pécs',
        value: 'PS',
      },
      {
        text: 'Pest',
        value: 'PE',
      },
      { text: 'Salgótarján', value: 'ST' },
      {
        text: 'Somogy',
        value: 'SO',
      },
      {
        text: 'Sopron',
        value: 'SN',
      },
      {
        text: 'Szabolcs-á-Bereg',
        value: 'SZ',
      },
      {
        text: 'Szeged',
        value: 'SD',
      },
      {
        text: 'Székesfehérvár',
        value: 'SF',
      },
      { text: 'Szekszárd', value: 'SS' },
      {
        text: 'Szolnok',
        value: 'SK',
      },
      {
        text: 'Szombathely',
        value: 'SH',
      },
      {
        text: 'Tatabánya',
        value: 'TB',
      },
      {
        text: 'Tolna',
        value: 'TO',
      },
      {
        text: 'Vas',
        value: 'VA',
      },
      {
        text: 'Veszprém',
        value: 'VE',
      },
      {
        text: 'Veszprém (City)',
        value: 'VM',
      },
      {
        text: 'Zala',
        value: 'ZA',
      },
      {
        text: 'Zalaegerszeg',
        value: 'ZE',
      },
    ],
  },
  {
    text: 'Iceland',
    value: 'IS',
    states: [
      {
        text: 'Austurland',
        value: '7',
      },
      {
        text: 'Höfuðborgarsvæði utan Reykjavíkur',
        value: '1',
      },
      {
        text: 'Norðurland eystra',
        value: '6',
      },
      {
        text: 'Norðurland vestra',
        value: '5',
      },
      {
        text: 'Suðurland',
        value: '8',
      },
      {
        text: 'Suðurnes',
        value: '2',
      },
      {
        text: 'Vestfirðir',
        value: '4',
      },
      {
        text: 'Vesturland',
        value: '3',
      },
    ],
  },
  {
    text: 'India',
    value: 'IN',
    states: [
      {
        text: 'Andaman and Nicobar Islands',
        value: 'AN',
      },
      {
        text: 'Andhra Pradesh',
        value: 'AP',
      },
      {
        text: 'Arunachal Pradesh',
        value: 'AR',
      },
      {
        text: 'Assam',
        value: 'AS',
      },
      {
        text: 'Bihar',
        value: 'BR',
      },
      {
        text: 'Chandigarh',
        value: 'CH',
      },
      {
        text: 'Chhattisgarh',
        value: 'CT',
      },
      {
        text: 'Dadra and Nagar Haveli',
        value: 'DN',
      },
      {
        text: 'Daman and Diu',
        value: 'DD',
      },
      {
        text: 'Delhi',
        value: 'DL',
      },
      {
        text: 'Goa',
        value: 'GA',
      },
      {
        text: 'Gujarat',
        value: 'GJ',
      },
      {
        text: 'Haryana',
        value: 'HR',
      },
      {
        text: 'Himachal Pradesh',
        value: 'HP',
      },
      {
        text: 'Jammu and Kashmir',
        value: 'JK',
      },
      {
        text: 'Jharkhand',
        value: 'JH',
      },
      {
        text: 'Karnataka',
        value: 'KA',
      },
      {
        text: 'Kerala',
        value: 'KL',
      },
      {
        text: 'Lakshadweep',
        value: 'LD',
      },
      {
        text: 'Madhya Pradesh',
        value: 'MP',
      },
      {
        text: 'Maharashtra',
        value: 'MH',
      },
      {
        text: 'Manipur',
        value: 'MN',
      },
      {
        text: 'Meghalaya',
        value: 'ML',
      },
      {
        text: 'Mizoram',
        value: 'MZ',
      },
      {
        text: 'Nagaland',
        value: 'NL',
      },
      {
        text: 'Odisha',
        value: 'OR',
      },
      {
        text: 'Puducherry',
        value: 'PY',
      },
      {
        text: 'Punjab',
        value: 'PB',
      },
      {
        text: 'Rajasthan',
        value: 'RJ',
      },
      {
        text: 'Sikkim',
        value: 'WK',
      },
      {
        text: 'Tamil Nadu',
        value: 'TN',
      },
      {
        text: 'Telangana',
        value: 'TG',
      },
      {
        text: 'Tripura',
        value: 'TR',
      },
      {
        text: 'Uttarakhand',
        value: 'UT',
      },
      {
        text: 'Uttar Pradesh',
        value: 'UP',
      },
      {
        text: 'West Bengal',
        value: 'WB',
      },
    ],
  },
  {
    text: 'Indonesia',
    value: 'ID',
    states: [
      {
        text: 'Aceh',
        value: 'AC',
      },
      {
        text: 'Bali',
        value: 'BA',
      },
      {
        text: 'Bangka Belitung',
        value: 'BB',
      },
      {
        text: 'Banten',
        value: 'BT',
      },
      {
        text: 'Bengkulu',
        value: 'BE',
      },
      {
        text: 'Gorontalo',
        value: 'GO',
      },
      {
        text: 'Jakarta Raya',
        value: 'JK',
      },
      {
        text: 'Jambi',
        value: 'JA',
      },
      {
        text: 'Jawa Barat',
        value: 'JB',
      },
      {
        text: 'Jawa Tengah',
        value: 'JT',
      },
      {
        text: 'Jawa Timur',
        value: 'JI',
      },
      {
        text: 'Kalimantan Barat',
        value: 'KB',
      },
      {
        text: 'Kalimantan Selatan',
        value: 'KS',
      },
      {
        text: 'Kalimantan Tengah',
        value: 'KT',
      },
      {
        text: 'Kalimantan Timur',
        value: 'KI',
      },
      {
        text: 'Kalimantan Utara',
        value: 'KU',
      },
      {
        text: 'Kepulauan Riau',
        value: 'KR',
      },
      {
        text: 'Lampung',
        value: 'LA',
      },
      {
        text: 'Maluku',
        value: 'MA',
      },
      {
        text: 'Maluku Utara',
        value: 'MU',
      },
      {
        text: 'Nusa Tenggara Barat',
        value: 'NB',
      },
      {
        text: 'Nusa Tenggara Timur',
        value: 'NT',
      },
      {
        text: 'Papua',
        value: 'PA',
      },
      {
        text: 'Papua Barat',
        value: 'PB',
      },
      {
        text: 'Riau',
        value: 'RI',
      },
      {
        text: 'Sulawesi Selatan',
        value: 'SR',
      },
      {
        text: 'Sulawesi Tengah',
        value: 'ST',
      },
      {
        text: 'Sulawesi Tenggara',
        value: 'SG',
      },
      {
        text: 'Sulawesi Utara',
        value: 'SA',
      },
      {
        text: 'Sumatera Barat',
        value: 'SB',
      },
      {
        text: 'Sumatera Selatan',
        value: 'SS',
      },
      {
        text: 'Sumatera Utara',
        value: 'SU',
      },
      {
        text: 'Yogyakarta',
        value: 'YO',
      },
    ],
  },
  {
    text: 'Iran, Islamic Republic of',
    value: 'IR',
    states: [
      {
        text: 'Alborz',
        value: '32',
      },
      {
        text: 'Ardabīl',
        value: '03',
      },
      {
        text: 'Āz̄arbāyjān-e Gharbī',
        value: '02',
      },
      {
        text: 'Āz̄arbāyjān-e Sharqī',
        value: '01',
      },
      {
        text: 'Būshehr',
        value: '06',
      },
      {
        text: 'Chahār Maḩāl va Bakhtīārī',
        value: '08',
      },
      {
        text: 'Eşfahān',
        value: '04',
      },
      {
        text: 'Fārs',
        value: '14',
      },
      {
        text: 'Gīlān',
        value: '19',
      },
      {
        text: 'Golestān',
        value: '27',
      },
      {
        text: 'Hamadān',
        value: '24',
      },
      {
        text: 'Hormozgān',
        value: '23',
      },
      {
        text: 'Īlām',
        value: '05',
      },
      {
        text: 'Kermān',
        value: '15',
      },
      {
        text: 'Kermānshāh',
        value: '17',
      },
      {
        text: 'Khorāsān-e Jonūbī',
        value: '29',
      },
      {
        text: 'Khorāsān-e Raẕavī',
        value: '30',
      },
      {
        text: 'Khorāsān-e Shomālī',
        value: '61',
      },
      {
        text: 'Khūzestān',
        value: '10',
      },
      {
        text: 'Kohgīlūyeh va Bowyer Aḩmad',
        value: '18',
      },
      {
        text: 'Kordestān',
        value: '16',
      },
      {
        text: 'Lorestān',
        value: '20',
      },
      {
        text: 'Markazi',
        value: '22',
      },
      {
        text: 'Māzandarān',
        value: '21',
      },
      {
        text: 'Qazvīn',
        value: '28',
      },
      {
        text: 'Qom',
        value: '26',
      },
      {
        text: 'Semnān',
        value: '12',
      },
      {
        text: 'Sīstān va Balūchestān',
        value: '13',
      },
      {
        text: 'Tehrān',
        value: '07',
      },
      {
        text: 'Yazd',
        value: '25',
      },
      {
        text: 'Zanjān',
        value: '11',
      },
    ],
  },
  {
    text: 'Iraq',
    value: 'IQ',
    states: [
      {
        text: 'Al Anbār',
        value: 'AN',
      },
      {
        text: 'Al Başrah',
        value: 'BA',
      },
      {
        text: 'Al Muthanná',
        value: 'MU',
      },
      {
        text: 'Al Qādisīyah',
        value: 'QA',
      },
      {
        text: 'An Najaf',
        value: 'NA',
      },
      {
        text: 'Arbīl',
        value: 'AR',
      },
      {
        text: 'As Sulaymānīyah',
        value: 'SU',
      },
      {
        text: 'Bābil',
        value: 'BB',
      },
      {
        text: 'Baghdād',
        value: 'BG',
      },
      {
        text: 'Dohuk',
        value: 'DA',
      },
      {
        text: 'Dhī Qār',
        value: 'DQ',
      },
      {
        text: 'Diyālá',
        value: 'DI',
      },
      {
        text: "Karbalā'",
        value: 'KA',
      },
      {
        text: 'Kirkuk',
        value: 'KI',
      },
      {
        text: 'Maysān',
        value: 'MA',
      },
      {
        text: 'Nīnawá',
        value: 'NI',
      },
      {
        text: 'Şalāḩ ad Dīn',
        value: 'SD',
      },
      {
        text: 'Wāsiţ',
        value: 'WA',
      },
    ],
  },
  {
    text: 'Ireland',
    value: 'IE',
    states: [
      {
        text: 'Carlow',
        value: 'CW',
      },
      {
        text: 'Cavan',
        value: 'CN',
      },
      {
        text: 'Clare',
        value: 'CE',
      },
      {
        text: 'Cork',
        value: 'CO',
      },
      {
        text: 'Donegal',
        value: 'DL',
      },
      {
        text: 'Dublin',
        value: 'D',
      },
      {
        text: 'Galway',
        value: 'G',
      },
      {
        text: 'Kerry',
        value: 'KY',
      },
      {
        text: 'Kildare',
        value: 'KE',
      },
      {
        text: 'Kilkenny',
        value: 'KK',
      },
      {
        text: 'Laois',
        value: 'LS',
      },
      {
        text: 'Leitrim',
        value: 'LM',
      },
      {
        text: 'Limerick',
        value: 'LK',
      },
      {
        text: 'Longford',
        value: 'LD',
      },
      {
        text: 'Louth',
        value: 'LH',
      },
      {
        text: 'Mayo',
        value: 'MO',
      },
      {
        text: 'Meath',
        value: 'MH',
      },
      {
        text: 'Monaghan',
        value: 'MN',
      },
      {
        text: 'Offaly',
        value: 'OY',
      },
      {
        text: 'Roscommon',
        value: 'RN',
      },
      {
        text: 'Sligo',
        value: 'SO',
      },
      {
        text: 'Tipperary',
        value: 'TA',
      },
      {
        text: 'Waterford',
        value: 'WD',
      },
      {
        text: 'Westmeath',
        value: 'WH',
      },
      {
        text: 'Wexford',
        value: 'WX',
      },
      {
        text: 'Wicklow',
        value: 'WW',
      },
    ],
  },
  {
    text: 'Isle of Man',
    value: 'IM',
    states: [
      {
        text: 'Isle of Man',
      },
    ],
  },
  {
    text: 'Israel',
    value: 'IL',
    states: [
      {
        text: 'HaDarom',
        value: 'D',
      },
      {
        text: 'HaMerkaz',
        value: 'M',
      },
      {
        text: 'HaTsafon',
        value: 'Z',
      },
      {
        text: 'H̱efa',
        value: 'HA',
      },
      {
        text: 'Tel-Aviv',
        value: 'TA',
      },
      {
        text: 'Yerushalayim',
        value: 'JM',
      },
    ],
  },
  {
    text: 'Italy',
    value: 'IT',
    states: [
      {
        text: 'Abruzzo',
        value: '65',
      },
      {
        text: 'Basilicata',
        value: '77',
      },
      {
        text: 'Calabria',
        value: '78',
      },
      {
        text: 'Campania',
        value: '72',
      },
      {
        text: 'Emilia-Romagna',
        value: '45',
      },
      {
        text: 'Friuli-Venezia Giulia',
        value: '36',
      },
      {
        text: 'Lazio',
        value: '62',
      },
      {
        text: 'Liguria',
        value: '42',
      },
      {
        text: 'Lombardia',
        value: '25',
      },
      {
        text: 'Marche',
        value: '57',
      },
      {
        text: 'Molise',
        value: '67',
      },
      {
        text: 'Piemonte',
        value: '21',
      },
      {
        text: 'Puglia',
        value: '75',
      },
      {
        text: 'Sardegna',
        value: '88',
      },
      {
        text: 'Sicilia',
        value: '82',
      },
      {
        text: 'Toscana',
        value: '52',
      },
      {
        text: 'Trentino-Alto Adige',
        value: '32',
      },
      {
        text: 'Umbria',
        value: '55',
      },
      {
        text: "Valle d'Aosta",
        value: '23',
      },
      {
        text: 'Veneto',
        value: '34',
      },
    ],
  },
  {
    text: 'Jamaica',
    value: 'JM',
    states: [
      {
        text: 'Clarendon',
        value: '13',
      },
      {
        text: 'Hanover',
        value: '09',
      },
      {
        text: 'Kingston',
        value: '01',
      },
      {
        text: 'Manchester',
        value: '12',
      },
      {
        text: 'Portland',
        value: '04',
      },
      {
        text: 'Saint Andrew',
        value: '02',
      },
      {
        text: 'Saint Ann',
        value: '06',
      },
      {
        text: 'Saint Catherine',
        value: '14',
      },
      {
        text: 'Saint Elizabeth',
        value: '11',
      },
      {
        text: 'Saint James',
        value: '08',
      },
      {
        text: 'Saint Mary',
        value: '05',
      },
      {
        text: 'Saint Thomas',
        value: '03',
      },
      {
        text: 'Trelawny',
        value: '07',
      },
      {
        text: 'Westmoreland',
        value: '10',
      },
    ],
  },
  {
    text: 'Japan',
    value: 'JP',
    states: [
      {
        text: 'Aichi',
        value: '23',
      },
      {
        text: 'Akita',
        value: '05',
      },
      {
        text: 'Aomori',
        value: '02',
      },
      {
        text: 'Chiba',
        value: '12',
      },
      {
        text: 'Ehime',
        value: '38',
      },
      {
        text: 'Fukui',
        value: '18',
      },
      {
        text: 'Fukuoka',
        value: '40',
      },
      {
        text: 'Fukushima',
        value: '07',
      },
      {
        text: 'Gifu',
        value: '21',
      },
      {
        text: 'Gunma',
        value: '10',
      },
      {
        text: 'Hiroshima',
        value: '34',
      },
      {
        text: 'Hokkaido',
        value: '01',
      },
      {
        text: 'Hyogo',
        value: '28',
      },
      {
        text: 'Ibaraki',
        value: '08',
      },
      {
        text: 'Ishikawa',
        value: '17',
      },
      {
        text: 'Iwate',
        value: '03',
      },
      {
        text: 'Kagawa',
        value: '37',
      },
      {
        text: 'Kagoshima',
        value: '46',
      },
      {
        text: 'Kanagawa',
        value: '14',
      },
      {
        text: 'Kochi',
        value: '39',
      },
      {
        text: 'Kumamoto',
        value: '43',
      },
      {
        text: 'Kyoto',
        value: '26',
      },
      {
        text: 'Mie',
        value: '24',
      },
      {
        text: 'Miyagi',
        value: '04',
      },
      {
        text: 'Miyazaki',
        value: '45',
      },
      {
        text: 'Nagano',
        value: '20',
      },
      {
        text: 'Nagasaki',
        value: '42',
      },
      {
        text: 'Nara',
        value: '29',
      },
      {
        text: 'Niigata',
        value: '15',
      },
      {
        text: 'Oita',
        value: '44',
      },
      {
        text: 'Okayama',
        value: '33',
      },
      {
        text: 'Okinawa',
        value: '47',
      },
      {
        text: 'Osaka',
        value: '27',
      },
      {
        text: 'Saga',
        value: '41',
      },
      {
        text: 'Saitama',
        value: '11',
      },
      {
        text: 'Shiga',
        value: '25',
      },
      {
        text: 'Shimane',
        value: '32',
      },
      {
        text: 'Shizuoka',
        value: '22',
      },
      {
        text: 'Tochigi',
        value: '09',
      },
      {
        text: 'Tokushima',
        value: '36',
      },
      {
        text: 'Tokyo',
        value: '13',
      },
      {
        text: 'Tottori',
        value: '31',
      },
      {
        text: 'Toyama',
        value: '16',
      },
      {
        text: 'Wakayama',
        value: '30',
      },
      {
        text: 'Yamagata',
        value: '06',
      },
      {
        text: 'Yamaguchi',
        value: '35',
      },
      {
        text: 'Yamanashi',
        value: '19',
      },
    ],
  },
  {
    text: 'Jersey',
    value: 'JE',
    states: [
      {
        text: 'Jersey',
      },
    ],
  },
  {
    text: 'Jordan',
    value: 'JO',
    states: [
      {
        text: '‘Ajlūn',
        value: 'AJ',
      },
      {
        text: "Al 'Aqabah",
        value: 'AQ',
      },
      {
        text: 'Al Balqā’',
        value: 'BA',
      },
      {
        text: 'Al Karak',
        value: 'KA',
      },
      {
        text: 'Al Mafraq',
        value: 'MA',
      },
      {
        text: 'Al ‘A̅şimah',
        value: 'AM',
      },
      {
        text: 'Aţ Ţafīlah',
        value: 'AT',
      },
      {
        text: 'Az Zarqā’',
        value: 'AZ',
      },
      {
        text: 'Irbid',
        value: 'IR',
      },
      {
        text: 'Jarash',
        value: 'JA',
      },
      {
        text: 'Ma‘ān',
        value: 'MN',
      },
      {
        text: 'Mādabā',
        value: 'MD',
      },
    ],
  },
  {
    text: 'Kazakhstan',
    value: 'KZ',
    states: [
      {
        text: 'Almaty',
        value: 'ALA',
      },
      {
        text: 'Aqmola',
        value: 'AKM',
      },
      {
        text: 'Aqtobe',
        value: 'AKT',
      },
      {
        text: 'Astana',
        value: 'AST',
      },
      {
        text: 'Atyrau',
        value: 'ATY',
      },
      {
        text: 'Batys Qazaqstan',
        value: 'ZAP',
      },
      {
        text: 'Bayqongyr',
      },
      {
        text: 'Mangghystau',
        value: 'MAN',
      },
      {
        text: 'Ongtustik Qazaqstan',
        value: 'YUZ',
      },
      {
        text: 'Pavlodar',
        value: 'PAV',
      },
      {
        text: 'Qaraghandy',
        value: 'KAR',
      },
      {
        text: 'Qostanay',
        value: 'KUS',
      },
      {
        text: 'Qyzylorda',
        value: 'KZY',
      },
      {
        text: 'Shyghys Qazaqstan',
        value: 'VOS',
      },
      {
        text: 'Soltustik Qazaqstan',
        value: 'SEV',
      },
      {
        text: 'Zhambyl',
        value: 'ZHA',
      },
    ],
  },
  {
    text: 'Kenya',
    value: 'KE',
    states: [
      {
        text: 'Baringo',
        value: '01',
      },
      {
        text: 'Bomet',
        value: '02',
      },
      {
        text: 'Bungoma',
        value: '03',
      },
      {
        text: 'Busia',
        value: '04',
      },
      {
        text: 'Eleyo/Marakwet',
        value: '05',
      },
      {
        text: 'Embu',
        value: '06',
      },
      {
        text: 'Garissa',
        value: '07',
      },
      {
        text: 'Homa Bay',
        value: '08',
      },
      {
        text: 'Isiolo',
        value: '09',
      },
      {
        text: 'Kajiado',
        value: '10',
      },
      {
        text: 'Kakamega',
        value: '11',
      },
      {
        text: 'Kericho',
        value: '12',
      },
      {
        text: 'Kiambu',
        value: '13',
      },
      {
        text: 'Kilifi',
        value: '14',
      },
      {
        text: 'Kirinyaga',
        value: '15',
      },
      {
        text: 'Kisii',
        value: '16',
      },
      {
        text: 'Kisumu',
        value: '17',
      },
      {
        text: 'Kitui',
        value: '18',
      },
      {
        text: 'Kwale',
        value: '19',
      },
      {
        text: 'Laikipia',
        value: '20',
      },
      {
        text: 'Lamu',
        value: '21',
      },
      {
        text: 'Machakos',
        value: '22',
      },
      {
        text: 'Makueni',
        value: '23',
      },
      {
        text: 'Mandera',
        value: '24',
      },
      {
        text: 'Marsabit',
        value: '25',
      },
      {
        text: 'Meru',
        value: '26',
      },
      {
        text: 'Migori',
        value: '27',
      },
      {
        text: 'Mombasa',
        value: '28',
      },
      {
        text: "Murang'a",
        value: '29',
      },
      {
        text: 'Nairobi City',
        value: '30',
      },
      {
        text: 'Nakuru',
        value: '31',
      },
      {
        text: 'Nandi',
        value: '32',
      },
      {
        text: 'Narok',
        value: '33',
      },
      {
        text: 'Nyamira',
        value: '34',
      },
      {
        text: 'Nyandarua',
        value: '35',
      },
      {
        text: 'Nyeri',
        value: '36',
      },
      {
        text: 'Samburu',
        value: '37',
      },
      {
        text: 'Siaya',
        value: '38',
      },
      {
        text: 'Taita/Taveta',
        value: '39',
      },
      {
        text: 'Tana River',
        value: '40',
      },
      {
        text: 'Tharaka-Nithi',
        value: '41',
      },
      {
        text: 'Trans Nzoia',
        value: '42',
      },
      {
        text: 'Turkana',
        value: '43',
      },
      {
        text: 'Uasin Gishu',
        value: '44',
      },
      {
        text: 'Vihiga',
        value: '45',
      },
      {
        text: 'Wajir',
        value: '46',
      },
      {
        text: 'West Pokot',
        value: '47',
      },
    ],
  },
  {
    text: 'Kiribati',
    value: 'KI',
    states: [
      {
        text: 'Abaiang',
      },
      {
        text: 'Abemama',
      },
      {
        text: 'Aranuka',
      },
      {
        text: 'Arorae',
      },
      {
        text: 'Banaba',
      },
      {
        text: 'Beru',
      },
      {
        text: 'Butaritari',
      },
      {
        text: 'Central Gilberts',
      },
      {
        text: 'Gilbert Islands',
        value: 'G',
      },
      {
        text: 'Kanton',
      },
      {
        text: 'Kiritimati',
      },
      {
        text: 'Kuria',
      },
      {
        text: 'Line Islands',
        value: 'L',
      },
      {
        text: 'Maiana',
      },
      {
        text: 'Makin',
      },
      {
        text: 'Marakei',
      },
      {
        text: 'Nikunau',
      },
      {
        text: 'Nonouti',
      },
      {
        text: 'Northern Gilberts',
      },
      {
        text: 'Onotoa',
      },
      {
        text: 'Phoenix Islands',
        value: 'P',
      },
      {
        text: 'Southern Gilberts',
      },
      {
        text: 'Tabiteuea',
      },
      {
        text: 'Tabuaeran',
      },
      {
        text: 'Tamana',
      },
      {
        text: 'Tarawa',
      },
      {
        text: 'Teraina',
      },
    ],
  },
  {
    text: "Korea, Democratic People's Republic of",
    value: 'KP',
    states: [
      {
        text: 'Chagang-do (Chagang Province)',
        value: '04',
      },
      {
        text: 'Hamgyong-bukto (North Hamgyong Province)',
        value: '09',
      },
      {
        text: 'Hamgyong-namdo (South Hamgyong Province)',
        value: '08',
      },
      {
        text: 'Hwanghae-bukto (North Hwanghae Province)',
        value: '06',
      },
      {
        text: 'Hwanghae-namdo (South Hwanghae Province)',
        value: '05',
      },
      {
        text: 'Kangwon-do (Kangwon Province)',
        value: '07',
      },
      {
        text: 'Nasŏn (Najin-Sŏnbong)',
        value: '13',
      },
      {
        text: "P'yongan-bukto (North P'yongan Province)",
        value: '03',
      },
      {
        text: "P'yongan-namdo (South P'yongan Province)",
        value: '02',
      },
      {
        text: "P'yongyang-si (P'yongyang City)",
        value: '01',
      },
      {
        text: 'Yanggang-do (Yanggang Province)',
        value: '10',
      },
    ],
  },
  {
    text: 'Korea, Republic of',
    value: 'KR',
    states: [
      {
        text: "Ch'ungch'ongbuk-do",
        value: '43',
      },
      {
        text: "Ch'ungch'ongnam-do",
        value: '44',
      },
      {
        text: 'Cheju-do',
        value: '49',
      },
      {
        text: 'Chollabuk-do',
        value: '45',
      },
      {
        text: 'Chollanam-do',
        value: '46',
      },
      {
        text: "Inch'on-Kwangyokhi",
        value: '28',
      },
      {
        text: 'Kang-won-do',
        value: '42',
      },
      {
        text: 'Kwangju-Kwangyokshi',
        value: '28',
      },
      {
        text: 'Kyonggi-do',
        value: '41',
      },
      {
        text: 'Kyongsangbuk-do',
        value: '47',
      },
      {
        text: 'Kyongsangnam-do',
        value: '48',
      },
      {
        text: 'Pusan-Kwangyokshi',
        value: '26',
      },
      {
        text: "Seoul-T'ukpyolshi",
        value: '11',
      },
      {
        text: 'Sejong',
        value: '50',
      },
      {
        text: 'Taegu-Kwangyokshi',
        value: '27',
      },
      {
        text: 'Taejon-Kwangyokshi',
        value: '30',
      },
      {
        text: 'Ulsan-Kwangyokshi',
        value: '31',
      },
    ],
  },
  {
    text: 'Kuwait',
    value: 'KW',
    states: [
      {
        text: 'Al Aḩmadi',
        value: 'AH',
      },
      {
        text: 'Al Farwānīyah',
        value: 'FA',
      },
      {
        text: 'Al Jahrā’',
        value: 'JA',
      },
      {
        text: 'Al ‘Āşimah',
        value: 'KU',
      },
      {
        text: 'Ḩawallī',
        value: 'HA',
      },
      {
        text: 'Mubārak al Kabir',
        value: 'MU',
      },
    ],
  },
  {
    text: 'Kyrgyzstan',
    value: 'KG',
    states: [
      {
        text: 'Batken Oblasty',
        value: 'B',
      },
      {
        text: 'Bishkek Shaary',
        value: 'GB',
      },
      {
        text: 'Chuy Oblasty (Bishkek)',
        value: 'C',
      },
      {
        text: 'Jalal-Abad Oblasty',
        value: 'J',
      },
      {
        text: 'Naryn Oblasty',
        value: 'N',
      },
      {
        text: 'Osh Oblasty',
        value: 'O',
      },
      {
        text: 'Talas Oblasty',
        value: 'T',
      },
      {
        text: 'Ysyk-Kol Oblasty (Karakol)',
        value: 'Y',
      },
    ],
  },
  {
    text: 'Laos',
    value: 'LA',
    states: [
      {
        text: 'Attapu',
        value: 'AT',
      },
      {
        text: 'Bokèo',
        value: 'BK',
      },
      {
        text: 'Bolikhamxai',
        value: 'BL',
      },
      {
        text: 'Champasak',
        value: 'CH',
      },
      {
        text: 'Houaphan',
        value: 'HO',
      },
      {
        text: 'Khammouan',
        value: 'KH',
      },
      {
        text: 'Louang Namtha',
        value: 'LM',
      },
      {
        text: 'Louangphabang',
        value: 'LP',
      },
      {
        text: 'Oudômxai',
        value: 'OU',
      },
      {
        text: 'Phôngsali',
        value: 'PH',
      },
      {
        text: 'Salavan',
        value: 'SL',
      },
      {
        text: 'Savannakhét',
        value: 'SV',
      },
      {
        text: 'Vientiane',
        value: 'VI',
      },
      {
        text: 'Xaignabouli',
        value: 'XA',
      },
      {
        text: 'Xékong',
        value: 'XE',
      },
      {
        text: 'Xaisomboun',
        value: 'XS',
      },
      {
        text: 'Xiangkhouang',
        value: 'XI',
      },
    ],
  },
  {
    text: 'Latvia',
    value: 'LV',
    states: [
      {
        text: 'Aglona',
        value: '001',
      },
      {
        text: 'Aizkraukle',
        value: '002',
      },
      {
        text: 'Aizpute',
        value: '003',
      },
      {
        text: 'Aknīste',
        value: '004',
      },
      {
        text: 'Aloja',
        value: '005',
      },
      {
        text: 'Alsunga',
        value: '06',
      },
      {
        text: 'Alūksne',
        value: '007',
      },
      {
        text: 'Amata',
        value: '008',
      },
      {
        text: 'Ape',
        value: '009',
      },
      {
        text: 'Auce',
        value: '010',
      },
      {
        text: 'Ādaži',
        value: '011',
      },
      {
        text: 'Babīte',
        value: '012',
      },
      {
        text: 'Baldone',
        value: '013',
      },
      {
        text: 'Baltinava',
        value: '014',
      },
      {
        text: 'Balvi',
        value: '015',
      },
      {
        text: 'Bauska',
        value: '016',
      },
      {
        text: 'Beverīna',
        value: '017',
      },
      {
        text: 'Brocēni',
        value: '018',
      },
      {
        text: 'Burtnieki',
        value: '019',
      },
      {
        text: 'Carnikava',
        value: '020',
      },
      {
        text: 'Cesvaine',
        value: '021',
      },
      {
        text: 'Cēsis',
        value: '022',
      },
      {
        text: 'Cibla',
        value: '023',
      },
      {
        text: 'Dagda',
        value: '024',
      },
      {
        text: 'Daugavpils',
        value: '025',
      },
      {
        text: 'Daugavpils (City)',
        value: 'DGV',
      },
      {
        text: 'Dobele',
        value: '026',
      },
      {
        text: 'Dundaga',
        value: '027',
      },
      {
        text: 'Durbe',
        value: '028',
      },
      {
        text: 'Engure',
        value: '029',
      },
      {
        text: 'Ērgļi',
        value: '030',
      },
      {
        text: 'Garkalne',
        value: '031',
      },
      {
        text: 'Grobiņa',
        value: '032',
      },
      {
        text: 'Gulbene',
        value: '033',
      },
      {
        text: 'Iecava',
        value: '034',
      },
      {
        text: 'Ikšķile',
        value: '035',
      },
      {
        text: 'Ilūkste',
        value: '036',
      },
      {
        text: 'Inčukalns',
        value: '037',
      },
      {
        text: 'Jaunjelgava',
        value: '038',
      },
      {
        text: 'Jaunpiebalga',
        value: '039',
      },
      {
        text: 'Jaunpils',
        value: '040',
      },
      {
        text: 'Jelgava',
        value: '041',
      },
      {
        text: 'Jelgava (City)',
        value: 'JEL',
      },
      {
        text: 'Jēkabpils',
        value: '042',
      },
      {
        text: 'Jēkabpils (City)',
        value: 'JKB',
      },
      {
        text: 'Jūrmala (City)',
        value: 'JUR',
      },
      {
        text: 'Kandava',
        value: '043',
      },
      {
        text: 'Kārsava',
        value: '044',
      },
      {
        text: 'Kocēni',
        value: '045',
      },
      {
        text: 'Koknese',
        value: '046',
      },
      {
        text: 'Krāslava',
        value: '047',
      },
      {
        text: 'Krimulda',
        value: '048',
      },
      {
        text: 'Krustpils',
        value: '049',
      },
      {
        text: 'Kuldīga',
        value: '050',
      },
      {
        text: 'Ķegums',
        value: '051',
      },
      {
        text: 'Ķekava',
        value: '052',
      },
      {
        text: 'Lielvārde',
        value: '053',
      },
      {
        text: 'Liepāja',
        value: 'LPX',
      },
      {
        text: 'Limbaži',
        value: '054',
      },
      {
        text: 'Līgatne',
        value: '055',
      },
      {
        text: 'Līvāni',
        value: '056',
      },
      {
        text: 'Lubāna',
        value: '057',
      },
      {
        text: 'Ludza',
        value: '058',
      },
      {
        text: 'Madona',
        value: '059',
      },
      {
        text: 'Mazsalaca',
        value: '060',
      },
      {
        text: 'Mālpils',
        value: '061',
      },
      {
        text: 'Mārupe',
        value: '062',
      },
      {
        text: 'Mērsrags',
        value: '063',
      },
      {
        text: 'Naukšēni',
        value: '064',
      },
      {
        text: 'Nereta',
        value: '065',
      },
      {
        text: 'Nīca',
        value: '066',
      },
      {
        text: 'Ogre',
        value: '067',
      },
      {
        text: 'Olaine',
        value: '068',
      },
      {
        text: 'Ozolnieki',
        value: '069',
      },
      {
        text: 'Pārgauja',
        value: '070',
      },
      {
        text: 'Pāvilosta',
        value: '071',
      },
      {
        text: 'Pļaviņas',
        value: '072',
      },
      {
        text: 'Preiļi',
        value: '073',
      },
      {
        text: 'Priekule',
        value: '074',
      },
      {
        text: 'Priekuļi',
        value: '075',
      },
      {
        text: 'Rauna',
        value: '076',
      },
      {
        text: 'Rēzekne',
        value: '077',
      },
      {
        text: 'Rēzekne (City)',
        value: 'REZ',
      },
      {
        text: 'Riebiņi',
        value: '078',
      },
      {
        text: 'Rīga',
        value: 'RIX',
      },
      {
        text: 'Roja',
        value: '079',
      },
      {
        text: 'Ropaži',
        value: '080',
      },
      {
        text: 'Rucava',
        value: '081',
      },
      {
        text: 'Rugāji',
        value: '082',
      },
      {
        text: 'Rundāle',
        value: '083',
      },
      {
        text: 'Rūjiena',
        value: '084',
      },
      {
        text: 'Sala',
        value: '085',
      },
      {
        text: 'Salacgrīva',
        value: '086',
      },
      {
        text: 'Salaspils',
        value: '087',
      },
      {
        text: 'Saldus',
        value: '088',
      },
      {
        text: 'Saulkrasti',
        value: '089',
      },
      {
        text: 'Sēja',
        value: '090',
      },
      {
        text: 'Sigulda',
        value: '091',
      },
      {
        text: 'Skrīveri',
        value: '092',
      },
      {
        text: 'Skrunda',
        value: '093',
      },
      {
        text: 'Smiltene',
        value: '094',
      },
      {
        text: 'Stopiņi',
        value: '095',
      },
      {
        text: 'Strenči',
        value: '096',
      },
      {
        text: 'Talsi',
        value: '097',
      },
      {
        text: 'Tērvete',
        value: '098',
      },
      {
        text: 'Tukums',
        value: '099',
      },
      {
        text: 'Vaiņode',
        value: '100',
      },
      {
        text: 'Valka',
        value: '101',
      },
      {
        text: 'Valmiera',
        value: 'VMR',
      },
      {
        text: 'Varakļāni',
        value: '102',
      },
      {
        text: 'Vārkava',
        value: '103',
      },
      {
        text: 'Vecpiebalga',
        value: '104',
      },
      {
        text: 'Vecumnieki',
        value: '105',
      },
      {
        text: 'Ventspils',
        value: '106',
      },
      {
        text: 'Ventspils (City)',
        value: 'VEN',
      },
      {
        text: 'Viesīte',
        value: '107',
      },
      {
        text: 'Viļaka',
        value: '108',
      },
      {
        text: 'Viļāni',
        value: '109',
      },
      {
        text: 'Zilupe',
        value: '110',
      },
    ],
  },
  {
    text: 'Lebanon',
    value: 'LB',
    states: [
      {
        text: 'Aakkâr',
        value: 'AK',
      },
      {
        text: 'Baalbelk-Hermel',
        value: 'BH',
      },
      {
        text: 'Béqaa',
        value: 'BI',
      },
      {
        text: 'Beyrouth',
        value: 'BA',
      },
      {
        text: 'Liban-Nord',
        value: 'AS',
      },
      {
        text: 'Liban-Sud',
        value: 'JA',
      },
      {
        text: 'Mont-Liban',
        value: 'JL',
      },
      {
        text: 'Nabatîyé',
        value: 'NA',
      },
    ],
  },
  {
    text: 'Lesotho',
    value: 'LS',
    states: [
      {
        text: 'Berea',
        value: 'D',
      },
      {
        text: 'Butha-Buthe',
        value: 'B',
      },
      {
        text: 'Leribe',
        value: 'C',
      },
      {
        text: 'Mafeteng',
        value: 'E',
      },
      {
        text: 'Maseru',
        value: 'A',
      },
      {
        text: 'Mohales Hoek',
        value: 'F',
      },
      {
        text: 'Mokhotlong',
        value: 'J',
      },
      {
        text: "Qacha's Nek",
        value: 'H',
      },
      {
        text: 'Quthing',
        value: 'G',
      },
      {
        text: 'Thaba-Tseka',
        value: 'K',
      },
    ],
  },
  {
    text: 'Liberia',
    value: 'LR',
    states: [
      {
        text: 'Bomi',
        value: 'BM',
      },
      {
        text: 'Bong',
        value: 'BG',
      },
      {
        text: 'Gbarpolu',
        value: 'GP',
      },
      {
        text: 'Grand Bassa',
        value: 'GB',
      },
      {
        text: 'Grand Cape Mount',
        value: 'CM',
      },
      {
        text: 'Grand Gedeh',
        value: 'GG',
      },
      {
        text: 'Grand Kru',
        value: 'GK',
      },
      {
        text: 'Lofa',
        value: 'LO',
      },
      {
        text: 'Margibi',
        value: 'MG',
      },
      {
        text: 'Maryland',
        value: 'MY',
      },
      {
        text: 'Montserrado',
        value: 'MO',
      },
      {
        text: 'Nimba',
        value: 'NI',
      },
      {
        text: 'River Cess',
        value: 'RI',
      },
      {
        text: 'River Geee',
        value: 'RG',
      },
      {
        text: 'Sinoe',
        value: 'SI',
      },
    ],
  },
  {
    text: 'Libya',
    value: 'LY',
    states: [
      {
        text: 'Al Buţnān',
        value: 'BU',
      },
      {
        text: 'Al Jabal al Akhḑar',
        value: 'JA',
      },
      {
        text: 'Al Jabal al Gharbī',
        value: 'JG',
      },
      {
        text: 'Al Jafārah',
        value: 'JA',
      },
      {
        text: 'Al Jufrah',
        value: 'JU',
      },
      {
        text: 'Al Kufrah',
        value: 'FK',
      },
      {
        text: 'Al Marj',
        value: 'MJ',
      },
      {
        text: 'Al Marquab',
        value: 'MB',
      },
      {
        text: 'Al Wāḩāt',
        value: 'WA',
      },
      {
        text: 'An Nuqaţ al Khams',
        value: 'NQ',
      },
      {
        text: 'Az Zāwiyah',
        value: 'ZA',
      },
      {
        text: 'Banghāzī',
        value: 'BA',
      },
      {
        text: 'Darnah',
        value: 'DR',
      },
      {
        text: 'Ghāt',
        value: 'GH',
      },
      {
        text: 'Mişrātah',
        value: 'MI',
      },
      {
        text: 'Murzuq',
        value: 'MQ',
      },
      {
        text: 'Nālūt',
        value: 'NL',
      },
      {
        text: 'Sabhā',
        value: 'SB',
      },
      {
        text: 'Surt',
        value: 'SR',
      },
      {
        text: 'Ţarābulus',
        value: 'TB',
      },
      {
        text: 'Yafran',
        value: 'WD',
      },
      {
        text: 'Wādī ash Shāţiʾ',
        value: 'WS',
      },
    ],
  },
  {
    text: 'Liechtenstein',
    value: 'LI',
    states: [
      {
        text: 'Balzers',
        value: '01',
      },
      {
        text: 'Eschen',
        value: '02',
      },
      {
        text: 'Gamprin',
        value: '03',
      },
      {
        text: 'Mauren',
        value: '04',
      },
      {
        text: 'Planken',
        value: '05',
      },
      {
        text: 'Ruggell',
        value: '06',
      },
      {
        text: 'Schaan',
        value: '07',
      },
      {
        text: 'Schellenberg',
        value: '08',
      },
      {
        text: 'Triesen',
        value: '09',
      },
      {
        text: 'Triesenberg',
        value: '10',
      },
      {
        text: 'Vaduz',
        value: '11',
      },
    ],
  },
  {
    text: 'Lithuania',
    value: 'LT',
    states: [
      {
        text: 'Alytaus',
        value: 'AL',
      },
      {
        text: 'Kauno',
        value: 'KU',
      },
      {
        text: 'Klaipėdos',
        value: 'KL',
      },
      {
        text: 'Marijampolės',
        value: 'MR',
      },
      {
        text: 'Panevėžio',
        value: 'PN',
      },
      {
        text: 'Šiaulių',
        value: 'SA',
      },
      {
        text: 'Tauragės',
        value: 'TA',
      },
      {
        text: 'Telšių',
        value: 'TE',
      },
      {
        text: 'Utenos',
        value: 'UT',
      },
      {
        text: 'Vilniaus',
        value: 'VL',
      },
    ],
  },
  {
    text: 'Luxembourg',
    value: 'LU',
    states: [
      {
        text: 'Capellen',
        value: 'CA',
      },
      {
        text: 'Clevaux',
        value: 'CL',
      },
      {
        text: 'Diekirch',
        value: 'DI',
      },
      {
        text: 'Echternach',
        value: 'EC',
      },
      {
        text: 'Esch-sur-Alzette',
        value: 'ES',
      },
      {
        text: 'Grevenmacher',
        value: 'GR',
      },
      {
        text: 'Luxembourg',
        value: 'LU',
      },
      {
        text: 'Mersch',
        value: 'ME',
      },
      {
        text: 'Redange',
        value: 'RD',
      },
      {
        text: 'Remich',
        value: 'RM',
      },
      {
        text: 'Vianden',
        value: 'VD',
      },
      {
        text: 'Wiltz',
        value: 'WI',
      },
    ],
  },
  {
    text: 'Macao',
    value: 'MO',
    states: [
      {
        text: 'Macao',
      },
    ],
  },
  {
    text: 'Macedonia, Republic of',
    value: 'MK',
    states: [
      {
        text: 'Aračinovo',
        value: '02',
      },
      {
        text: 'Berovo',
        value: '03',
      },
      {
        text: 'Bitola',
        value: '04',
      },
      {
        text: 'Bogdanci',
        value: '05',
      },
      {
        text: 'Bogovinje',
        value: '06',
      },
      {
        text: 'Bosilovo',
        value: '07',
      },
      {
        text: 'Brvenica',
        value: '08',
      },
      {
        text: 'Centar Župa',
        value: '78',
      },
      {
        text: 'Čaška',
        value: '08',
      },
      {
        text: 'Češinovo-Obleševo',
        value: '81',
      },
      {
        text: 'Čučer Sandevo',
        value: '82',
      },
      {
        text: 'Debar',
        value: '21',
      },
      {
        text: 'Debarca',
        value: '22',
      },
      {
        text: 'Delčevo',
        value: '23',
      },
      {
        text: 'Demir Hisar',
        value: '25',
      },
      {
        text: 'Demir Kapija',
        value: '24',
      },
      {
        text: 'Doran',
        value: '26',
      },
      {
        text: 'Dolneni',
        value: '27',
      },
      {
        text: 'Gevgelija',
        value: '18',
      },
      {
        text: 'Gostivar',
        value: '19',
      },
      {
        text: 'Gradsko',
        value: '20',
      },
      {
        text: 'Ilinden',
        value: '34',
      },
      {
        text: 'Jegunovce',
        value: '35',
      },
      {
        text: 'Karbinci',
        value: '37',
      },
      {
        text: 'Kavadarci',
        value: '36',
      },
      {
        text: 'Kičevo',
        value: '40',
      },
      {
        text: 'Kočani',
        value: '42',
      },
      {
        text: 'Konče',
        value: '41',
      },
      {
        text: 'Kratovo',
        value: '43',
      },
      {
        text: 'Kriva Palanka',
        value: '44',
      },
      {
        text: 'Krivogaštani',
        value: '45',
      },
      {
        text: 'Kruševo',
        value: '46',
      },
      {
        text: 'Kumanovo',
        value: '47',
      },
      {
        text: 'Lipkovo',
        value: '48',
      },
      {
        text: 'Lozovo',
        value: '49',
      },
      {
        text: 'Makedonska Kamenica',
        value: '51',
      },
      {
        text: 'Makedonski Brod',
        value: '52',
      },
      {
        text: 'Mavrovo i Rostuša',
        value: '50',
      },
      {
        text: 'Mogila',
        value: '53',
      },
      {
        text: 'Negotino',
        value: '54',
      },
      {
        text: 'Novaci',
        value: '55',
      },
      {
        text: 'Novo Selo',
        value: '56',
      },
      {
        text: 'Ohrid',
        value: '58',
      },
      {
        text: 'Pehčevo',
        value: '60',
      },
      {
        text: 'Petrovec',
        value: '59',
      },
      {
        text: 'Plasnica',
        value: '61',
      },
      {
        text: 'Prilep',
        value: '62',
      },
      {
        text: 'Probištip',
        value: '63',
      },
      {
        text: 'Radoviš',
        value: '',
      },
      {
        text: 'Rankovce',
        value: '65',
      },
      {
        text: 'Resen',
        value: '66',
      },
      {
        text: 'Rosoman',
        value: '67',
      },
      {
        text: 'Skopje',
        value: '85',
      },
      {
        text: 'Sopište',
        value: '70',
      },
      {
        text: 'Staro Nagoričane',
        value: '71',
      },
      {
        text: 'Struga',
        value: '72',
      },
      {
        text: 'Strumica',
        value: '73',
      },
      {
        text: 'Studeničani',
        value: '74',
      },
      {
        text: 'Sveti Nikole',
        value: '69',
      },
      {
        text: 'Štip',
        value: '83',
      },
      {
        text: 'Tearce',
        value: '75',
      },
      {
        text: 'Tetovo',
        value: '76',
      },
      {
        text: 'Valandovo',
        value: '10',
      },
      {
        text: 'Vasilevo',
        value: '11',
      },
      {
        text: 'Veles',
        value: '13',
      },
      {
        text: 'Vevčani',
        value: '12',
      },
      {
        text: 'Vinica',
        value: '14',
      },
      {
        text: 'Vrapčište',
        value: '16',
      },
      {
        text: 'Zelenikovo',
        value: '32',
      },
      {
        text: 'Zrnovci',
        value: '33',
      },
      {
        text: 'Želino',
        value: '30',
      },
    ],
  },
  {
    text: 'Madagascar',
    value: 'MG',
    states: [
      {
        text: 'Antananarivo',
        value: 'T',
      },
      {
        text: 'Antsiranana',
        value: 'D',
      },
      {
        text: 'Fianarantsoa',
        value: 'F',
      },
      {
        text: 'Mahajanga',
        value: 'M',
      },
      {
        text: 'Toamasina',
        value: 'A',
      },
      {
        text: 'Toliara',
        value: 'U',
      },
    ],
  },
  {
    text: 'Malawi',
    value: 'MW',
    states: [
      {
        text: 'Balaka',
        value: 'BA',
      },
      {
        text: 'Blantyre',
        value: 'BL',
      },
      {
        text: 'Chikwawa',
        value: 'CK',
      },
      {
        text: 'Chiradzulu',
        value: 'CR',
      },
      {
        text: 'Chitipa',
        value: 'CT',
      },
      {
        text: 'Dedza',
        value: 'DE',
      },
      {
        text: 'Dowa',
        value: 'DO',
      },
      {
        text: 'Karonga',
        value: 'KR',
      },
      {
        text: 'Kasungu',
        value: 'KS',
      },
      {
        text: 'Likoma',
        value: 'LK',
      },
      {
        text: 'Lilongwe',
        value: 'LI',
      },
      {
        text: 'Machinga',
        value: 'MH',
      },
      {
        text: 'Mangochi',
        value: 'MG',
      },
      {
        text: 'Mchinji',
        value: 'MC',
      },
      {
        text: 'Mulanje',
        value: 'MU',
      },
      {
        text: 'Mwanza',
        value: 'MW',
      },
      {
        text: 'Mzimba',
        value: 'MZ',
      },
      {
        text: 'Nkhata Bay',
        value: 'NE',
      },
      {
        text: 'Nkhotakota',
        value: 'NB',
      },
      {
        text: 'Nsanje',
        value: 'NS',
      },
      {
        text: 'Ntcheu',
        value: 'NU',
      },
      {
        text: 'Ntchisi',
        value: 'NI',
      },
      {
        text: 'Phalombe',
        value: 'PH',
      },
      {
        text: 'Rumphi',
        value: 'RU',
      },
      {
        text: 'Salima',
        value: 'SA',
      },
      {
        text: 'Thyolo',
        value: 'TH',
      },
      {
        text: 'Zomba',
        value: 'ZO',
      },
    ],
  },
  {
    text: 'Malaysia',
    value: 'MY',
    states: [
      {
        text: 'Johor',
        value: '01',
      },
      {
        text: 'Kedah',
        value: '02',
      },
      {
        text: 'Kelantan',
        value: '03',
      },
      {
        text: 'Melaka',
        value: '04',
      },
      {
        text: 'Negeri Sembilan',
        value: '05',
      },
      {
        text: 'Pahang',
        value: '06',
      },
      {
        text: 'Perak',
        value: '08',
      },
      {
        text: 'Perlis',
        value: '09',
      },
      {
        text: 'Pulau Pinang',
        value: '07',
      },
      {
        text: 'Sabah',
        value: '12',
      },
      {
        text: 'Sarawak',
        value: '13',
      },
      {
        text: 'Selangor',
        value: '10',
      },
      {
        text: 'Terengganu',
        value: '11',
      },
      {
        text: 'Wilayah Persekutuan (Kuala Lumpur)',
        value: '14',
      },
      {
        text: 'Wilayah Persekutuan (Labuan)',
        value: '15',
      },
      {
        text: 'Wilayah Persekutuan (Putrajaya)',
        value: '16',
      },
    ],
  },
  {
    text: 'Maldives',
    value: 'MV',
    states: [
      {
        text: 'Alifu Alifu',
        value: '02',
      },
      {
        text: 'Alifu Dhaalu',
        value: '00',
      },
      {
        text: 'Baa',
        value: '20',
      },
      {
        text: 'Dhaalu',
        value: '17',
      },
      {
        text: 'Faafu',
        value: '14',
      },
      {
        text: 'Gaafu Alifu',
        value: '27',
      },
      {
        text: 'Gaafu Dhaalu',
        value: '28',
      },
      {
        text: 'Gnaviyani',
        value: '29',
      },
      {
        text: 'Haa Alifu',
        value: '07',
      },
      {
        text: 'Haa Dhaalu',
        value: '23',
      },
      {
        text: 'Kaafu',
        value: '29',
      },
      {
        text: 'Laamu',
        value: '05',
      },
      {
        text: 'Lhaviyani',
        value: '03',
      },
      {
        text: 'Malé',
        value: 'MLE',
      },
      {
        text: 'Meemu',
        value: '12',
      },
      {
        text: 'Noonu',
        value: '25',
      },
      {
        text: 'Raa',
        value: '13',
      },
      {
        text: 'Seenu',
        value: '01',
      },
      {
        text: 'Shaviyani',
        value: '24',
      },
      {
        text: 'Thaa',
        value: '08',
      },
      {
        text: 'Vaavu',
        value: '04',
      },
    ],
  },
  {
    text: 'Mali',
    value: 'ML',
    states: [
      {
        text: 'Bamako',
        value: 'BKO',
      },
      {
        text: 'Gao',
        value: '7',
      },
      {
        text: 'Kayes',
        value: '1',
      },
      {
        text: 'Kidal',
        value: '8',
      },
      {
        text: 'Koulikoro',
        value: '2',
      },
      {
        text: 'Mopti',
        value: '5',
      },
      {
        text: 'Segou',
        value: '4',
      },
      {
        text: 'Sikasso',
        value: '3',
      },
      {
        text: 'Tombouctou',
        value: '6',
      },
    ],
  },
  {
    text: 'Malta',
    value: 'MT',
    states: [
      {
        text: 'Attard',
        value: '01',
      },
      {
        text: 'Balzan',
        value: '02',
      },
      {
        text: 'Birgu',
        value: '03',
      },
      {
        text: 'Birkirkara',
        value: '04',
      },
      {
        text: 'Birżebbuġa',
        value: '05',
      },
      {
        text: 'Bormla',
        value: '06',
      },
      {
        text: 'Dingli',
        value: '07',
      },
      {
        text: 'Fgura',
        value: '08',
      },
      {
        text: 'Floriana',
        value: '09',
      },
      {
        text: 'Fontana',
        value: '10',
      },
      {
        text: 'Guda',
        value: '11',
      },
      {
        text: 'Gżira',
        value: '12',
      },
      {
        text: 'Għajnsielem',
        value: '13',
      },
      {
        text: 'Għarb',
        value: '14',
      },
      {
        text: 'Għargħur',
        value: '15',
      },
      {
        text: 'Għasri',
        value: '16',
      },
      {
        text: 'Għaxaq',
        value: '17',
      },
      {
        text: 'Ħamrun',
        value: '18',
      },
      {
        text: 'Iklin',
        value: '19',
      },
      {
        text: 'Isla',
        value: '20',
      },
      {
        text: 'Kalkara',
        value: '21',
      },
      {
        text: 'Kerċem',
        value: '22',
      },
      {
        text: 'Kirkop',
        value: '23',
      },
      {
        text: 'Lija',
        value: '24',
      },
      {
        text: 'Luqa',
        value: '25',
      },
      {
        text: 'Marsa',
        value: '26',
      },
      {
        text: 'Marsaskala',
        value: '27',
      },
      {
        text: 'Marsaxlokk',
        value: '28',
      },
      {
        text: 'Mdina',
        value: '29',
      },
      {
        text: 'Mellieħa',
        value: '30',
      },
      {
        text: 'Mġarr',
        value: '31',
      },
      {
        text: 'Mosta',
        value: '32',
      },
      {
        text: 'Mqabba',
        value: '33',
      },
      {
        text: 'Msida',
        value: '34',
      },
      {
        text: 'Mtarfa',
        value: '35',
      },
      {
        text: 'Munxar',
        value: '36',
      },
      {
        text: 'Nadur',
        value: '37',
      },
      {
        text: 'Naxxar',
        value: '38',
      },
      {
        text: 'Paola',
        value: '39',
      },
      {
        text: 'Pembroke',
        value: '40',
      },
      {
        text: 'Pietà',
        value: '41',
      },
      {
        text: 'Qala',
        value: '42',
      },
      {
        text: 'Qormi',
        value: '43',
      },
      {
        text: 'Qrendi',
        value: '44',
      },
      {
        text: 'Rabat Għawdex',
        value: '45',
      },
      {
        text: 'Rabat Malta',
        value: '46',
      },
      {
        text: 'Safi',
        value: '47',
      },
      {
        text: 'San Ġiljan',
        value: '48',
      },
      {
        text: 'San Ġwann',
        value: '49',
      },
      {
        text: 'San Lawrenz',
        value: '50',
      },
      {
        text: 'San Pawl il-Baħar',
        value: '51',
      },
      {
        text: 'Sannat',
        value: '52',
      },
      {
        text: 'Santa Luċija',
        value: '53',
      },
      {
        text: 'Santa Venera',
        value: '54',
      },
      {
        text: 'Siġġiewi',
        value: '55',
      },
      {
        text: 'Sliema',
        value: '56',
      },
      {
        text: 'Swieqi',
        value: '57',
      },
      {
        text: 'Tai Xbiex',
        value: '58',
      },
      {
        text: 'Tarzien',
        value: '59',
      },
      {
        text: 'Valletta',
        value: '60',
      },
      {
        text: 'Xagħra',
        value: '61',
      },
      {
        text: 'Xewkija',
        value: '62',
      },
      {
        text: 'Xgħajra',
        value: '63',
      },
      {
        text: 'Żabbar',
        value: '64',
      },
      {
        text: 'Żebbuġ Għawde',
        value: '65',
      },
      {
        text: 'Żebbuġ Malta',
        value: '66',
      },
      {
        text: 'Żejtun',
        value: '67',
      },
      {
        text: 'Żurrieq',
        value: '68',
      },
    ],
  },
  {
    text: 'Marshall Islands',
    value: 'MH',
    states: [
      {
        text: 'Ailinglaplap',
        value: 'ALL',
      },
      {
        text: 'Ailuk',
        value: 'ALK',
      },
      {
        text: 'Arno',
        value: 'ARN',
      },
      {
        text: 'Aur',
        value: 'AUR',
      },
      {
        text: 'Bikini and Kili',
        value: 'KIL',
      },
      {
        text: 'Ebon',
        value: 'EBO',
      },
      {
        text: 'Jabat',
        value: 'JAB',
      },
      {
        text: 'Jaluit',
        value: 'JAL',
      },
      {
        text: 'Kwajalein',
        value: 'KWA',
      },
      {
        text: 'Lae',
        value: 'LAE',
      },
      {
        text: 'Lib',
        value: 'LIB',
      },
      {
        text: 'Likiep',
        value: 'LIK',
      },
      {
        text: 'Majuro',
        value: 'MAJ',
      },
      {
        text: 'Maloelap',
        value: 'MAL',
      },
      {
        text: 'Mejit',
        value: 'MEJ',
      },
      {
        text: 'Namdrik',
        value: 'NMK',
      },
      {
        text: 'Namu',
        value: 'NMU',
      },
      {
        text: 'Rongelap',
        value: 'RON',
      },
      {
        text: 'Ujae',
        value: 'UJA',
      },
      {
        text: 'Utrik',
        value: 'UTI',
      },
      {
        text: 'Wotho',
        value: 'WTH',
      },
      {
        text: 'Wotje',
        value: 'WTJ',
      },
    ],
  },
  {
    text: 'Martinique',
    value: 'MQ',
    states: [
      {
        text: 'Martinique',
      },
    ],
  },
  {
    text: 'Mauritania',
    value: 'MR',
    states: [
      {
        text: 'Adrar',
        value: '07',
      },
      {
        text: 'Assaba',
        value: '03',
      },
      {
        text: 'Brakna',
        value: '05',
      },
      {
        text: 'Dakhlet Nouadhibou',
        value: '08',
      },
      {
        text: 'Gorgol',
        value: '04',
      },
      {
        text: 'Guidimaka',
        value: '10',
      },
      {
        text: 'Hodh Ech Chargui',
        value: '01',
      },
      {
        text: 'Hodh El Gharbi',
        value: '02',
      },
      {
        text: 'Inchiri',
        value: '12',
      },
      {
        text: 'Nouakchott Nord',
        value: '14',
      },
      {
        text: 'Nouakchott Ouest',
        value: '13',
      },
      {
        text: 'Nouakchott Sud',
        value: '15',
      },
      {
        text: 'Tagant',
        value: '09',
      },
      {
        text: 'Tiris Zemmour',
        value: '11',
      },
      {
        text: 'Trarza',
        value: '06',
      },
    ],
  },
  {
    text: 'Mauritius',
    value: 'MU',
    states: [
      {
        text: 'Agalega Islands',
        value: 'AG',
      },
      {
        text: 'Beau Bassin-Rose Hill',
        value: 'BR',
      },
      {
        text: 'Black River',
        value: 'BL',
      },
      {
        text: 'Cargados Carajos Shoals',
        value: 'CC',
      },
      {
        text: 'Curepipe',
        value: 'CU',
      },
      {
        text: 'Flacq',
        value: 'FL',
      },
      {
        text: 'Grand Port',
        value: 'GP',
      },
      {
        text: 'Moka',
        value: 'MO',
      },
      {
        text: 'Pamplemousses',
        value: 'PA',
      },
      {
        text: 'Plaines Wilhems',
        value: 'PW',
      },
      {
        text: 'Port Louis (City)',
        value: 'PU',
      },
      {
        text: 'Port Louis',
        value: 'PL',
      },
      {
        text: 'Riviere du Rempart',
        value: 'RR',
      },
      {
        text: 'Rodrigues Island',
        value: 'RO',
      },
      {
        text: 'Savanne',
        value: 'SA',
      },
      {
        text: 'Vacoas-Phoenix',
        value: 'CP',
      },
    ],
  },
  {
    text: 'Mayotte',
    value: 'YT',
    states: [
      {
        text: 'Dzaoudzi',
        value: '01',
      },
      {
        text: 'Pamandzi',
        value: '02',
      },
      {
        text: 'Mamoudzou',
        value: '03',
      },
      {
        text: 'Dembeni',
        value: '04',
      },
      {
        text: 'Bandrélé',
        value: '05',
      },
      {
        text: 'Kani-Kéli',
        value: '06',
      },
      {
        text: 'Bouéni',
        value: '07',
      },
      {
        text: 'Chirongui',
        value: '08',
      },
      {
        text: 'Sada',
        value: '09',
      },
      {
        text: 'Ouangani',
        value: '10',
      },
      {
        text: 'Chiconi',
        value: '11',
      },
      {
        text: 'Tsingoni',
        value: '12',
      },
      {
        text: "M'Tsangamouji",
        value: '13',
      },
      {
        text: 'Acoua',
        value: '14',
      },
      {
        text: 'Mtsamboro',
        value: '15',
      },
      {
        text: 'Bandraboua',
        value: '16',
      },
      {
        text: 'Koungou',
        value: '17',
      },
    ],
  },
  {
    text: 'Mexico',
    value: 'MX',
    states: [
      {
        text: 'Aguascalientes',
        value: 'AGU',
      },
      {
        text: 'Baja California',
        value: 'BCN',
      },
      {
        text: 'Baja California Sur',
        value: 'BCS',
      },
      {
        text: 'Campeche',
        value: 'CAM',
      },
      {
        text: 'Ciudad de México',
        value: 'DIF',
      },
      {
        text: 'Chiapas',
        value: 'CHP',
      },
      {
        text: 'Chihuahua',
        value: 'CHH',
      },
      {
        text: 'Coahuila de Zaragoza',
        value: 'COA',
      },
      {
        text: 'Colima',
        value: 'COL',
      },
      {
        text: 'Durango',
        value: 'DUR',
      },
      {
        text: 'Estado de México',
        value: 'MEX',
      },
      {
        text: 'Guanajuato',
        value: 'GUA',
      },
      {
        text: 'Guerrero',
        value: 'GRO',
      },
      {
        text: 'Hidalgo',
        value: 'HID',
      },
      {
        text: 'Jalisco',
        value: 'JAL',
      },
      {
        text: 'Michoacán de Ocampo',
        value: 'MIC',
      },
      {
        text: 'Morelos',
        value: 'MOR',
      },
      {
        text: 'Nayarit',
        value: 'NAY',
      },
      {
        text: 'Nuevo León',
        value: 'NLE',
      },
      {
        text: 'Oaxaca',
        value: 'OAX',
      },
      {
        text: 'Puebla',
        value: 'PUE',
      },
      {
        text: 'Querétaro de Arteaga',
        value: 'QUE',
      },
      {
        text: 'Quintana Roo',
        value: 'ROO',
      },
      {
        text: 'San Luis Potosí',
        value: 'SLP',
      },
      {
        text: 'Sinaloa',
        value: 'SIN',
      },
      {
        text: 'Sonora',
        value: 'SON',
      },
      {
        text: 'Tabasco',
        value: 'TAB',
      },
      {
        text: 'Tamaulipas',
        value: 'TAM',
      },
      {
        text: 'Tlaxcala',
        value: 'TLA',
      },
      {
        text: 'Veracruz',
        value: 'VER',
      },
      {
        text: 'Yucatán',
        value: 'YUC',
      },
      {
        text: 'Zacatecas',
        value: 'ZAC',
      },
    ],
  },
  {
    text: 'Micronesia, Federated States of',
    value: 'FM',
    states: [
      {
        text: 'Chuuk (Truk)',
        value: 'TRK',
      },
      {
        text: 'Kosrae',
        value: 'KSA',
      },
      {
        text: 'Pohnpei',
        value: 'PNI',
      },
      {
        text: 'Yap',
        value: 'YAP',
      },
    ],
  },
  {
    text: 'Moldova',
    value: 'MD',
    states: [
      {
        text: 'Aenii Noi',
        value: 'AN',
      },
      {
        text: 'Basarabeasca',
        value: 'BS',
      },
      {
        text: 'Bălți',
        value: 'BA',
      },
      {
        text: 'Bender',
        value: 'BD',
      },
      {
        text: 'Briceni',
        value: 'BR',
      },
      {
        text: 'Cahul',
        value: 'CA',
      },
      {
        text: 'Cantemir',
        value: 'CT',
      },
      {
        text: 'Călărași',
        value: 'CL',
      },
      {
        text: 'Căușeni',
        value: 'CS',
      },
      {
        text: 'Chișinău',
        value: 'CU',
      },
      {
        text: 'Cimișlia',
        value: 'CM',
      },
      {
        text: 'Criuleni',
        value: 'CR',
      },
      {
        text: 'Dondușeni',
        value: 'DO',
      },
      {
        text: 'Drochia',
        value: 'DR',
      },
      {
        text: 'Dubăsari',
        value: 'DU',
      },
      {
        text: 'Edineț',
        value: 'ED',
      },
      {
        text: 'Fălești',
        value: 'FA',
      },
      {
        text: 'Florești',
        value: 'FL',
      },
      {
        text: 'Găgăuzia',
        value: 'GA',
      },
      {
        text: 'Glodeni',
        value: 'GL',
      },
      {
        text: 'Hîncești',
        value: 'HI',
      },
      {
        text: 'Ialoveni',
        value: 'IA',
      },
      {
        text: 'Leova',
        value: 'LE',
      },
      {
        text: 'Nisporeni',
        value: 'NI',
      },
      {
        text: 'Ocnița',
        value: 'OC',
      },
      {
        text: 'Orhei',
        value: 'OR',
      },
      {
        text: 'Rezina',
        value: 'RE',
      },
      {
        text: 'Rîșcani',
        value: 'RI',
      },
      {
        text: 'Sîngerei',
        value: 'SI',
      },
      {
        text: 'Soroca',
        value: 'SO',
      },
      {
        text: 'Stînga Nistrului',
        value: 'SN',
      },
      {
        text: 'Strășeni',
        value: 'ST',
      },
      {
        text: 'Șoldănești',
        value: 'SD',
      },
      {
        text: 'Ștefan Vodă',
        value: 'SV',
      },
      {
        text: 'Taraclia',
        value: 'TA',
      },
      {
        text: 'Telenești',
        value: 'TE',
      },
      {
        text: 'Ungheni',
        value: 'UN',
      },
    ],
  },
  {
    text: 'Monaco',
    value: 'MC',
    states: [
      {
        text: 'Colle',
        value: 'CL',
      },
      {
        text: 'Condamine',
        value: 'CO',
      },
      {
        text: 'Fontvieille',
        value: 'FO',
      },
      {
        text: 'Gare',
        value: 'GA',
      },
      {
        text: 'Jardin Exotique',
        value: 'JE',
      },
      {
        text: 'Larvotto',
        value: 'LA',
      },
      {
        text: 'Malbousquet',
        value: 'MA',
      },
      {
        text: 'Monaco-Ville',
        value: 'MO',
      },
      {
        text: 'Moneghetti',
        value: 'MG',
      },
      {
        text: 'Monte-Carlo',
        value: 'MC',
      },
      {
        text: 'Moulins',
        value: 'MU',
      },
      {
        text: 'Port-Hercule',
        value: 'PH',
      },
      {
        text: 'Saint-Roman',
        value: 'SR',
      },
      {
        text: 'Sainte-Dévote',
        value: 'SD',
      },
      {
        text: 'Source',
        value: 'SO',
      },
      {
        text: 'Spélugues',
        value: 'SP',
      },
      {
        text: 'Vallon de la Rousse',
        value: 'VR',
      },
    ],
  },
  {
    text: 'Mongolia',
    value: 'MN',
    states: [
      {
        text: 'Arhangay',
        value: '073',
      },
      {
        text: 'Bayan-Olgiy',
        value: '071',
      },
      {
        text: 'Bayanhongor',
        value: '069',
      },
      {
        text: 'Bulgan',
        value: '067',
      },
      {
        text: 'Darhan',
        value: '037',
      },
      {
        text: 'Dornod',
        value: '061',
      },
      {
        text: 'Dornogovi',
        value: '063',
      },
      {
        text: 'Dundgovi',
        value: '059',
      },
      {
        text: 'Dzavhan',
        value: '065',
      },
      {
        text: 'Govi-Altay',
        value: '065',
      },
      {
        text: 'Govi-Sumber',
        value: '064',
      },
      {
        text: 'Hovd',
        value: '043',
      },
      {
        text: 'Hovsgol',
        value: '041',
      },
      {
        text: 'Omnogovi',
        value: '053',
      },
      {
        text: 'Ovorhangay',
        value: '055',
      },
      {
        text: 'Selenge',
        value: '049',
      },
      {
        text: 'Suhbaatar',
        value: '051',
      },
      {
        text: 'Tov',
        value: '047',
      },
      {
        text: 'Ulaanbaatar',
        value: '1',
      },
      {
        text: 'Uvs',
        value: '046',
      },
    ],
  },
  {
    text: 'Montenegro',
    value: 'ME',
    states: [
      {
        text: 'Andrijevica',
        value: '01',
      },
      {
        text: 'Bar',
        value: '02',
      },
      {
        text: 'Berane',
        value: '03',
      },
      {
        text: 'Bijelo Polje',
        value: '04',
      },
      {
        text: 'Budva',
        value: '05',
      },
      {
        text: 'Cetinje',
        value: '06',
      },
      {
        text: 'Danilovgrad',
        value: '07',
      },
      {
        text: 'Gusinje',
        value: '22',
      },
      {
        text: 'Herceg Novi',
        value: '08',
      },
      {
        text: 'Kolašin',
        value: '09',
      },
      {
        text: 'Kotor',
        value: '10',
      },
      {
        text: 'Mojkovac',
        value: '11',
      },
      {
        text: 'Nikšić',
        value: '12',
      },
      {
        text: 'Petnica',
        value: '23',
      },
      {
        text: 'Plav',
        value: '13',
      },
      {
        text: 'Plužine',
        value: '14',
      },
      {
        text: 'Pljevlja',
        value: '15',
      },
      {
        text: 'Podgorica',
        value: '16',
      },
      {
        text: 'Rožaje',
        value: '17',
      },
      {
        text: 'Šavnik',
        value: '18',
      },
      {
        text: 'Tivat',
        value: '19',
      },
      {
        text: 'Ulcinj',
        value: '20',
      },
      {
        text: 'Žabljak',
        value: '21',
      },
    ],
  },
  {
    text: 'Montserrat',
    value: 'MS',
    states: [
      {
        text: 'Saint Anthony',
      },
      {
        text: 'Saint Georges',
      },
      {
        text: "Saint Peter's",
      },
    ],
  },
  {
    text: 'Morocco',
    value: 'MA',
    states: [
      {
        text: 'Chaouia-Ouardigha',
        value: '09',
      },
      {
        text: 'Doukhala-Abda',
        value: '10',
      },
      {
        text: 'Fès-Boulemane',
        value: '05',
      },
      {
        text: 'Gharb-Chrarda-Beni Hssen',
        value: '02',
      },
      {
        text: 'Grand Casablanca',
        value: '08',
      },
      {
        text: 'Guelmim-Es Semara',
        value: '14',
      },
      {
        text: 'Laâyoune-Boujdour-Sakia el Hamra',
        value: '15',
      },
      {
        text: 'Marrakech-Tensift-Al Haouz',
        value: '11',
      },
      {
        text: 'Meknès-Tafilalet',
        value: '06',
      },
      {
        text: 'Oriental',
        value: '04',
      },
      {
        text: 'Oued ed Dahab-Lagouira',
        value: '16',
      },
      {
        text: 'Souss-Massa-Drâa',
        value: '13',
      },
      {
        text: 'Tadla-Azilal',
        value: '12',
      },
      {
        text: 'Tanger-Tétouan',
        value: '01',
      },
      {
        text: 'Taza-Al Hoceima-Taounate',
        value: '03',
      },
    ],
  },
  {
    text: 'Mozambique',
    value: 'MZ',
    states: [
      {
        text: 'Cabo Delgado',
        value: 'P',
      },
      {
        text: 'Gaza',
        value: 'G',
      },
      {
        text: 'Inhambane',
        value: 'I',
      },
      {
        text: 'Manica',
        value: 'B',
      },
      {
        text: 'Maputo',
        value: 'L',
      },
      {
        text: 'Maputo (City)',
        value: 'MPM',
      },
      {
        text: 'Nampula',
        value: 'N',
      },
      {
        text: 'Niassa',
        value: 'A',
      },
      {
        text: 'Sofala',
        value: 'S',
      },
      {
        text: 'Tete',
        value: 'T',
      },
      {
        text: 'Zambezia',
        value: 'Q',
      },
    ],
  },
  {
    text: 'Myanmar',
    value: 'MM',
    states: [
      {
        text: 'Ayeyarwady',
        value: '07',
      },
      {
        text: 'Bago',
        value: '02',
      },
      {
        text: 'Chin',
        value: '14',
      },
      {
        text: 'Kachin',
        value: '11',
      },
      {
        text: 'Kayah',
        value: '12',
      },
      {
        text: 'Kayin',
        value: '13',
      },
      {
        text: 'Magway',
        value: '03',
      },
      {
        text: 'Mandalay',
        value: '04',
      },
      {
        text: 'Mon',
        value: '15',
      },
      {
        text: 'Nay Pyi Taw',
        value: '18',
      },
      {
        text: 'Rakhine',
        value: '16',
      },
      {
        text: 'Sagaing',
        value: '01',
      },
      {
        text: 'Shan',
        value: '17',
      },
      {
        text: 'Tanintharyi',
        value: '05',
      },
      {
        text: 'Yangon',
        value: '06',
      },
    ],
  },
  {
    text: 'Namibia',
    value: 'NA',
    states: [
      {
        text: 'Erongo',
        value: 'ER',
      },
      {
        text: 'Hardap',
        value: 'HA',
      },
      {
        text: 'Kavango East',
        value: 'KE',
      },
      {
        text: 'Kavango West',
        value: 'KW',
      },
      {
        text: 'Karas',
        value: 'KA',
      },
      {
        text: 'Khomas',
        value: 'KH',
      },
      {
        text: 'Kunene',
        value: 'KU',
      },
      {
        text: 'Ohangwena',
        value: 'OW',
      },
      {
        text: 'Omaheke',
        value: 'OH',
      },
      {
        text: 'Omusati',
        value: 'OS',
      },
      {
        text: 'Oshana',
        value: 'ON',
      },
      {
        text: 'Oshikoto',
        value: 'OT',
      },
      {
        text: 'Otjozondjupa',
        value: 'OD',
      },
      {
        text: 'Zambezi',
        value: 'CA',
      },
    ],
  },
  {
    text: 'Nauru',
    value: 'NR',
    states: [
      {
        text: 'Aiwo',
        value: '01',
      },
      {
        text: 'Anabar',
        value: '02',
      },
      {
        text: 'Anetan',
        value: '03',
      },
      {
        text: 'Anibare',
        value: '04',
      },
      {
        text: 'Baiti',
        value: '05',
      },
      {
        text: 'Boe',
        value: '06',
      },
      {
        text: 'Buada',
        value: '07',
      },
      {
        text: 'Denigomodu',
        value: '08',
      },
      {
        text: 'Ewa',
        value: '09',
      },
      {
        text: 'Ijuw',
        value: '10',
      },
      {
        text: 'Meneng',
        value: '11',
      },
      {
        text: 'Nibok',
        value: '12',
      },
      {
        text: 'Uaboe',
        value: '13',
      },
      {
        text: 'Yaren',
        value: '14',
      },
    ],
  },
  {
    text: 'Nepal',
    value: 'NP',
    states: [
      {
        text: 'Bagmati',
        value: 'BA',
      },
      {
        text: 'Bheri',
        value: 'BH',
      },
      {
        text: 'Dhawalagiri',
        value: 'DH',
      },
      {
        text: 'Gandaki',
        value: 'GA',
      },
      {
        text: 'Janakpur',
        value: 'JA',
      },
      {
        text: 'Karnali',
        value: 'KA',
      },
      {
        text: 'Kosi',
        value: 'KO',
      },
      {
        text: 'Lumbini',
        value: 'LU',
      },
      {
        text: 'Mahakali',
        value: 'MA',
      },
      {
        text: 'Mechi',
        value: 'ME',
      },
      {
        text: 'Narayani',
        value: 'NA',
      },
      {
        text: 'Rapti',
        value: 'RA',
      },
      {
        text: 'Sagarmatha',
        value: 'SA',
      },
      {
        text: 'Seti',
        value: 'SE',
      },
    ],
  },
  {
    text: 'Netherlands',
    value: 'NL',
    states: [
      {
        text: 'Drenthe',
        value: 'DR',
      },
      {
        text: 'Flevoland',
        value: 'FL',
      },
      {
        text: 'Friesland',
        value: 'FR',
      },
      {
        text: 'Gelderland',
        value: 'GE',
      },
      {
        text: 'Groningen',
        value: 'GR',
      },
      {
        text: 'Limburg',
        value: 'LI',
      },
      {
        text: 'Noord-Brabant',
        value: 'NB',
      },
      {
        text: 'Noord-Holland',
        value: 'NH',
      },
      {
        text: 'Overijssel',
        value: 'OV',
      },
      {
        text: 'Utrecht',
        value: 'UT',
      },
      {
        text: 'Zeeland',
        value: 'ZE',
      },
      {
        text: 'Zuid-Holland',
        value: 'ZH',
      },
    ],
  },
  {
    text: 'New Caledonia',
    value: 'NC',
    states: [
      {
        text: 'Iles Loyaute',
      },
      {
        text: 'Nord',
      },
      {
        text: 'Sud',
      },
    ],
  },
  {
    text: 'New Zealand',
    value: 'NZ',
    states: [
      {
        text: 'Auckland',
        value: 'AUK',
      },
      {
        text: 'Bay of Plenty',
        value: 'BOP',
      },
      {
        text: 'Canterbury',
        value: 'CAN',
      },
      {
        text: 'Gisborne',
        value: 'GIS',
      },
      {
        text: "Hawke's Bay",
        value: 'HKB',
      },
      {
        text: 'Marlborough',
        value: 'MBH',
      },
      {
        text: 'Manawatu-Wanganui',
        value: 'MWT',
      },
      {
        text: 'Northland',
        value: 'NTL',
      },
      {
        text: 'Nelson',
        value: 'NSN',
      },
      {
        text: 'Otago',
        value: 'OTA',
      },
      {
        text: 'Southland',
        value: 'STL',
      },
      {
        text: 'Taranaki',
        value: 'TKI',
      },
      {
        text: 'Tasman',
        value: 'TAS',
      },
      {
        text: 'Waikato',
        value: 'WKO',
      },
      {
        text: 'Wellington',
        value: 'WGN',
      },
      {
        text: 'West Coast',
        value: 'WTC',
      },
      {
        text: 'Chatham Islands Territory',
        value: 'CIT',
      },
    ],
  },
  {
    text: 'Nicaragua',
    value: 'NI',
    states: [
      {
        text: 'Boaco',
        value: 'BO',
      },
      {
        text: 'Carazo',
        value: 'CA',
      },
      {
        text: 'Chinandega',
        value: 'CI',
      },
      {
        text: 'Chontales',
        value: 'CO',
      },
      {
        text: 'Estelí',
        value: 'ES',
      },
      {
        text: 'Granada',
        value: 'GR',
      },
      {
        text: 'Jinotega',
        value: 'JI',
      },
      {
        text: 'León',
        value: 'LE',
      },
      {
        text: 'Madriz',
        value: 'MD',
      },
      {
        text: 'Managua',
        value: 'MN',
      },
      {
        text: 'Masaya',
        value: 'MS',
      },
      {
        text: 'Matagalpa',
        value: 'MT',
      },
      {
        text: 'Nueva Segovia',
        value: 'NS',
      },
      {
        text: 'Río San Juan',
        value: 'SJ',
      },
      {
        text: 'Rivas',
        value: 'RI',
      },
      {
        text: 'Atlántico Norte',
        value: 'AN',
      },
      {
        text: 'Atlántico Sur',
        value: 'AS',
      },
    ],
  },
  {
    text: 'Niger',
    value: 'NE',
    states: [
      {
        text: 'Agadez',
        value: '1',
      },
      {
        text: 'Diffa',
        value: '2',
      },
      {
        text: 'Dosso',
        value: '3',
      },
      {
        text: 'Maradi',
        value: '4',
      },
      {
        text: 'Niamey',
        value: '8',
      },
      {
        text: 'Tahoua',
        value: '5',
      },
      {
        text: 'Tillabéri',
        value: '6',
      },
      {
        text: 'Zinder',
        value: '7',
      },
    ],
  },
  {
    text: 'Nigeria',
    value: 'NG',
    states: [
      {
        text: 'Abia',
        value: 'AB',
      },
      {
        text: 'Abuja Federal Capital Territory',
        value: 'FC',
      },
      {
        text: 'Adamawa',
        value: 'AD',
      },
      {
        text: 'Akwa Ibom',
        value: 'AK',
      },
      {
        text: 'Anambra',
        value: 'AN',
      },
      {
        text: 'Bauchi',
        value: 'BA',
      },
      {
        text: 'Bayelsa',
        value: 'BY',
      },
      {
        text: 'Benue',
        value: 'BE',
      },
      {
        text: 'Borno',
        value: 'BO',
      },
      {
        text: 'Cross River',
        value: 'CR',
      },
      {
        text: 'Delta',
        value: 'DE',
      },
      {
        text: 'Ebonyi',
        value: 'EB',
      },
      {
        text: 'Edo',
        value: 'ED',
      },
      {
        text: 'Ekiti',
        value: 'EK',
      },
      {
        text: 'Enugu',
        value: 'EN',
      },
      {
        text: 'Gombe',
        value: 'GO',
      },
      {
        text: 'Imo',
        value: 'IM',
      },
      {
        text: 'Jigawa',
        value: 'JI',
      },
      {
        text: 'Kaduna',
        value: 'KD',
      },
      {
        text: 'Kano',
        value: 'KN',
      },
      {
        text: 'Katsina',
        value: 'KT',
      },
      {
        text: 'Kebbi',
        value: 'KE',
      },
      {
        text: 'Kogi',
        value: 'KO',
      },
      {
        text: 'Kwara',
        value: 'KW',
      },
      {
        text: 'Lagos',
        value: 'LA',
      },
      {
        text: 'Nassarawa',
        value: 'NA',
      },
      {
        text: 'Niger',
        value: 'NI',
      },
      {
        text: 'Ogun',
        value: 'OG',
      },
      {
        text: 'Ondo',
        value: 'ON',
      },
      {
        text: 'Osun',
        value: 'OS',
      },
      {
        text: 'Oyo',
        value: 'OY',
      },
      {
        text: 'Plateau',
        value: 'PL',
      },
      {
        text: 'Rivers',
        value: 'RI',
      },
      {
        text: 'Sokoto',
        value: 'SO',
      },
      {
        text: 'Taraba',
        value: 'TA',
      },
      {
        text: 'Yobe',
        value: 'YO',
      },
      {
        text: 'Zamfara',
        value: 'ZA',
      },
    ],
  },
  {
    text: 'Niue',
    value: 'NU',
    states: [
      {
        text: 'Niue',
      },
    ],
  },
  {
    text: 'Norfolk Island',
    value: 'NF',
    states: [
      {
        text: 'Norfolk Island',
      },
    ],
  },
  {
    text: 'Northern Mariana Islands',
    value: 'MP',
    states: [
      {
        text: 'Northern Islands',
      },
      {
        text: 'Rota',
      },
      {
        text: 'Saipan',
      },
      {
        text: 'Tinian',
      },
    ],
  },
  {
    text: 'Norway',
    value: 'NO',
    states: [
      {
        text: 'Akershus',
        value: '02',
      },
      {
        text: 'Aust-Agder',
        value: '09',
      },
      {
        text: 'Buskerud',
        value: '06',
      },
      {
        text: 'Finnmark',
        value: '20',
      },
      {
        text: 'Hedmark',
        value: '04',
      },
      {
        text: 'Hordaland',
        value: '12',
      },
      {
        text: 'Møre og Romsdal',
        value: '15',
      },
      {
        text: 'Nordland',
        value: '18',
      },
      {
        text: 'Nord-Trøndelag',
        value: '17',
      },
      {
        text: 'Oppland',
        value: '05',
      },
      {
        text: 'Oslo',
        value: '03',
      },
      {
        text: 'Rogaland',
        value: '11',
      },
      {
        text: 'Sogn og Fjordane',
        value: '14',
      },
      {
        text: 'Sør-Trøndelag',
        value: '16',
      },
      {
        text: 'Telemark',
        value: '08',
      },
      {
        text: 'Troms',
        value: '19',
      },
      {
        text: 'Vest-Agder',
        value: '10',
      },
      {
        text: 'Vestfold',
        value: '07',
      },
      {
        text: 'Østfold',
        value: '01',
      },
      {
        text: 'Jan Mayen',
        value: '22',
      },
      {
        text: 'Svalbard',
        value: '21',
      },
    ],
  },
  {
    text: 'Oman',
    value: 'OM',
    states: [
      {
        text: 'Ad Dakhiliyah',
        value: 'DA',
      },
      {
        text: 'Al Buraymi',
        value: 'BU',
      },
      {
        text: 'Al Wusta',
        value: 'WU',
      },
      {
        text: 'Az Zahirah',
        value: 'ZA',
      },
      {
        text: 'Janub al Batinah',
        value: 'BS',
      },
      {
        text: 'Janub ash Sharqiyah',
        value: 'SS',
      },
      {
        text: 'Masqat',
        value: 'MA',
      },
      {
        text: 'Musandam',
        value: 'MU',
      },
      {
        text: 'Shamal al Batinah',
        value: 'BJ',
      },
      {
        text: 'Shamal ash Sharqiyah',
        value: 'SJ',
      },
      {
        text: 'Zufar',
        value: 'ZU',
      },
    ],
  },
  {
    text: 'Pakistan',
    value: 'PK',
    states: [
      {
        text: 'Āzād Kashmīr',
        value: 'JK',
      },
      {
        text: 'Balōchistān',
        value: 'BA',
      },
      {
        text: 'Gilgit-Baltistān',
        value: 'GB',
      },
      {
        text: 'Islāmābād',
        value: 'IS',
      },
      {
        text: 'Khaībar Pakhtūnkhwās',
        value: 'KP',
      },
      {
        text: 'Punjāb',
        value: 'PB',
      },
      {
        text: 'Sindh',
        value: 'SD',
      },
      {
        text: 'Federally Administered Tribal Areas',
        value: 'TA',
      },
    ],
  },
  {
    text: 'Palau',
    value: 'PW',
    states: [
      {
        text: 'Aimeliik',
        value: '002',
      },
      {
        text: 'Airai',
        value: '004',
      },
      {
        text: 'Angaur',
        value: '010',
      },
      {
        text: 'Hatobohei',
        value: '050',
      },
      {
        text: 'Kayangel',
        value: '100',
      },
      {
        text: 'Koror',
        value: '150',
      },
      {
        text: 'Melekeok',
        value: '212',
      },
      {
        text: 'Ngaraard',
        value: '214',
      },
      {
        text: 'Ngarchelong',
        value: '218',
      },
      {
        text: 'Ngardmau',
        value: '222',
      },
      {
        text: 'Ngatpang',
        value: '224',
      },
      {
        text: 'Ngchesar',
        value: '226',
      },
      {
        text: 'Ngeremlengui',
        value: '227',
      },
      {
        text: 'Ngiwal',
        value: '228',
      },
      {
        text: 'Peleliu',
        value: '350',
      },
      {
        text: 'Sonsoral',
        value: '350',
      },
    ],
  },
  {
    text: 'Palestine, State of',
    value: 'PS',
    states: [
      {
        text: 'Ak Khalīl',
        value: 'HBN',
      },
      {
        text: 'Al Quds',
        value: 'JEM',
      },
      {
        text: 'Arīḩā wal Aghwār',
        value: 'JRH',
      },
      {
        text: 'Bayt Laḩm',
        value: 'BTH',
      },
      {
        text: 'Dayr al Balaḩ',
        value: 'DEB',
      },
      {
        text: 'Ghazzah',
        value: 'GZA',
      },
      {
        text: 'Janīn',
        value: 'JEN',
      },
      {
        text: 'Khān Yūnis',
        value: 'KYS',
      },
      {
        text: 'Nāblus',
        value: 'NBS',
      },
      {
        text: 'Qalqīyah',
        value: 'QQA',
      },
      {
        text: 'Rafaḩ',
        value: 'RFH',
      },
      {
        text: 'Rām Allāh wal Bīrah',
        value: 'RBH',
      },
      {
        text: 'Salfīt',
        value: 'SLT',
      },
      {
        text: 'Shamāl Ghazzah',
        value: 'NGZ',
      },
      {
        text: 'Ţūbās',
        value: 'TBS',
      },
      {
        text: 'Ţūlkarm',
        value: 'TKM',
      },
    ],
  },
  {
    text: 'Panama',
    value: 'PA',
    states: [
      {
        text: 'Bocas del Toro',
        value: '1',
      },
      {
        text: 'Chiriquí',
        value: '4',
      },
      {
        text: 'Coclé',
        value: '2',
      },
      {
        text: 'Colón',
        value: '3',
      },
      {
        text: 'Darién',
        value: '5',
      },
      {
        text: 'Emberá',
        value: 'EM',
      },
      {
        text: 'Herrera',
        value: '6',
      },
      {
        text: 'Kuna Yala',
        value: 'KY',
      },
      {
        text: 'Los Santos',
        value: '7',
      },
      {
        text: 'Ngäbe-Buglé',
        value: 'NB',
      },
      {
        text: 'Panamá',
        value: '8',
      },
      {
        text: 'Panamá Oeste',
        value: '10',
      },
      {
        text: 'Veraguas',
        value: '9',
      },
    ],
  },
  {
    text: 'Papua New Guinea',
    value: 'PG',
    states: [
      {
        text: 'Bougainville',
        value: 'NSB',
      },
      {
        text: 'Central',
        value: 'CPM',
      },
      {
        text: 'Chimbu',
        value: 'CPK',
      },
      {
        text: 'East New Britain',
        value: 'EBR',
      },
      {
        text: 'East Sepik',
        value: 'ESW',
      },
      {
        text: 'Eastern Highlands',
        value: 'EHG',
      },
      {
        text: 'Enga',
        value: 'EPW',
      },
      {
        text: 'Gulf',
        value: 'GPK',
      },
      {
        text: 'Hela',
        value: 'HLA',
      },
      {
        text: 'Jiwaka',
        value: 'JWK',
      },
      {
        text: 'Madang',
        value: 'MOM',
      },
      {
        text: 'Manus',
        value: 'MRL',
      },
      {
        text: 'Milne Bay',
        value: 'MBA',
      },
      {
        text: 'Morobe',
        value: 'MPL',
      },
      {
        text: 'Port Moresby',
        value: 'NCD',
      },
      {
        text: 'New Ireland',
        value: 'NIK',
      },
      {
        text: 'Northern',
        value: 'NPP',
      },
      {
        text: 'Southern Highlands',
        value: 'SHM',
      },
      {
        text: 'West New Britain',
        value: 'WBK',
      },
      {
        text: 'West Sepik',
        value: 'SAN',
      },
      {
        text: 'Western',
        value: 'WPD',
      },
      {
        text: 'Western Highlands',
        value: 'WHM',
      },
    ],
  },
  {
    text: 'Paraguay',
    value: 'PY',
    states: [
      {
        text: 'Alto Paraguay',
        value: '16',
      },
      {
        text: 'Alto Parana',
        value: '10',
      },
      {
        text: 'Amambay',
        value: '13',
      },
      {
        text: 'Asuncion',
        value: 'ASU',
      },
      {
        text: 'Caaguazu',
        value: '5',
      },
      {
        text: 'Caazapa',
        value: '6',
      },
      {
        text: 'Canindeyu',
        value: '14',
      },
      {
        text: 'Central',
        value: '11',
      },
      {
        text: 'Concepcion',
        value: '1',
      },
      {
        text: 'Cordillera',
        value: '3',
      },
      {
        text: 'Guaira',
        value: '4',
      },
      {
        text: 'Itapua',
        value: '7',
      },
      {
        text: 'Misiones',
        value: '8',
      },
      {
        text: 'Neembucu',
        value: '12',
      },
      {
        text: 'Paraguari',
        value: '9',
      },
      {
        text: 'Presidente Hayes',
        value: '15',
      },
      {
        text: 'San Pedro',
        value: '2',
      },
    ],
  },
  {
    text: 'Peru',
    value: 'PE',
    states: [
      {
        text: 'Amazonas',
        value: 'AMA',
      },
      {
        text: 'Ancash',
        value: 'ANC',
      },
      {
        text: 'Apurimac',
        value: 'APU',
      },
      {
        text: 'Arequipa',
        value: 'ARE',
      },
      {
        text: 'Ayacucho',
        value: 'AYA',
      },
      {
        text: 'Cajamarca',
        value: 'CAJ',
      },
      {
        text: 'Callao',
        value: 'CAL',
      },
      {
        text: 'Cusco',
        value: 'CUS',
      },
      {
        text: 'Huancavelica',
        value: 'HUV',
      },
      {
        text: 'Huanuco',
        value: 'HUC',
      },
      {
        text: 'Ica',
        value: 'ICA',
      },
      {
        text: 'Junin',
        value: 'JUN',
      },
      {
        text: 'La Libertad',
        value: 'LAL',
      },
      {
        text: 'Lambayeque',
        value: 'LAM',
      },
      {
        text: 'Lima',
        value: 'LIM',
      },
      {
        text: 'Loreto',
        value: 'LOR',
      },
      {
        text: 'Madre de Dios',
        value: 'MDD',
      },
      {
        text: 'Moquegua',
        value: 'MOQ',
      },
      {
        text: 'Municipalidad Metropolitana de Lima',
        value: 'LMA',
      },
      {
        text: 'Pasco',
        value: 'PAS',
      },
      {
        text: 'Piura',
        value: 'PIU',
      },
      {
        text: 'Puno',
        value: 'PUN',
      },
      {
        text: 'San Martin',
        value: 'SAM',
      },
      {
        text: 'Tacna',
        value: 'TAC',
      },
      {
        text: 'Tumbes',
        value: 'TUM',
      },
      {
        text: 'Ucayali',
        value: 'UCA',
      },
    ],
  },
  {
    text: 'Philippines',
    value: 'PH',
    states: [
      {
        text: 'Abra',
        value: 'ABR',
      },
      {
        text: 'Agusan del Norte',
        value: 'AGN',
      },
      {
        text: 'Agusan del Sur',
        value: 'AGS',
      },
      {
        text: 'Aklan',
        value: 'AKL',
      },
      {
        text: 'Albay',
        value: 'ALB',
      },
      {
        text: 'Antique',
        value: 'ANT',
      },
      {
        text: 'Apayao',
        value: 'APA',
      },
      {
        text: 'Aurora',
        value: 'AUR',
      },
      {
        text: 'Basilan',
        value: 'BAS',
      },
      {
        text: 'Bataan',
        value: 'BAN',
      },
      {
        text: 'Batanes',
        value: 'BTN',
      },
      {
        text: 'Batangas',
        value: 'BTG',
      },
      {
        text: 'Benguet',
        value: 'BEN',
      },
      {
        text: 'Biliran',
        value: 'BIL',
      },
      {
        text: 'Bohol',
        value: 'BOH',
      },
      {
        text: 'Bukidnon',
        value: 'BUK',
      },
      {
        text: 'Bulacan',
        value: 'BUL',
      },
      {
        text: 'Cagayan',
        value: 'CAG',
      },
      {
        text: 'Camarines Norte',
        value: 'CAN',
      },
      {
        text: 'Camarines Sur',
        value: 'CAS',
      },
      {
        text: 'Camiguin',
        value: 'CAM',
      },
      {
        text: 'Capiz',
        value: 'CAP',
      },
      {
        text: 'Catanduanes',
        value: 'CAT',
      },
      {
        text: 'Cavite',
        value: 'CAV',
      },
      {
        text: 'Cebu',
        value: 'CEB',
      },
      {
        text: 'Compostela',
        value: 'COM',
      },
      {
        text: 'Cotabato',
        value: 'NCO',
      },
      {
        text: 'Davao del Norte',
        value: 'DAV',
      },
      {
        text: 'Davao del Sur',
        value: 'DAS',
      },
      {
        text: 'Davao Occidental',
        value: 'DVO',
      },
      {
        text: 'Davao Oriental',
        value: 'DAO',
      },
      {
        text: 'Dinagat Islands',
        value: 'DIN',
      },
      {
        text: 'Eastern Samar',
        value: 'EAS',
      },
      {
        text: 'Guimaras',
        value: 'GUI',
      },
      {
        text: 'Ifugao',
        value: 'IFU',
      },
      {
        text: 'Ilocos Norte',
        value: 'ILN',
      },
      {
        text: 'Ilocos Sur',
        value: 'ILS',
      },
      {
        text: 'Iloilo',
        value: 'ILI',
      },
      {
        text: 'Isabela',
        value: 'ISA',
      },
      {
        text: 'Kalinga',
        value: 'KAL',
      },
      {
        text: 'La Union',
        value: 'LUN',
      },
      {
        text: 'Laguna',
        value: 'LAG',
      },
      {
        text: 'Lanao del Norte',
        value: 'LAN',
      },
      {
        text: 'Lanao del Sur',
        value: 'LAS',
      },
      {
        text: 'Leyte',
        value: 'LEY',
      },
      {
        text: 'Maguindanao',
        value: 'MAG',
      },
      {
        text: 'Masbate',
        value: 'MAS',
      },
      {
        text: 'Metro Manila',
        value: '00',
      },
      {
        text: 'Mindoro Occidental',
        value: 'MDC',
      },
      {
        text: 'Mindoro Oriental',
        value: 'MDR',
      },
      {
        text: 'Misamis Occidental',
        value: 'MSC',
      },
      {
        text: 'Misamis Oriental',
        value: 'MSR',
      },
      {
        text: 'Mountain Province',
        value: 'MOU',
      },
      {
        text: 'Negros Occidental',
        value: 'NEC',
      },
      {
        text: 'Negros Oriental',
        value: 'NER',
      },
      {
        text: 'Northern Samar',
        value: 'NSA',
      },
      {
        text: 'Nueva Ecija',
        value: 'NUE',
      },
      {
        text: 'Nueva Vizcaya',
        value: 'NUV',
      },
      {
        text: 'Palawan',
        value: 'PLW',
      },
      {
        text: 'Pampanga',
        value: 'PAM',
      },
      {
        text: 'Pangasinan',
        value: 'PAN',
      },
      {
        text: 'Quezon',
        value: 'QUE',
      },
      {
        text: 'Quirino',
        value: 'QUI',
      },
      {
        text: 'Rizal',
        value: 'RIZ',
      },
      {
        text: 'Romblon',
        value: 'ROM',
      },
      {
        text: 'Samar',
        value: 'WSA',
      },
      {
        text: 'Sarangani',
        value: 'SAR',
      },
      {
        text: 'Siquijor',
        value: 'SIG',
      },
      {
        text: 'Sorsogon',
        value: 'SOR',
      },
      {
        text: 'Southern Leyte',
        value: 'SLE',
      },
      {
        text: 'Sultan Kudarat',
        value: 'AUK',
      },
      {
        text: 'Sulu',
        value: 'SLU',
      },
      {
        text: 'Surigao del Norte',
        value: 'SUN',
      },
      {
        text: 'Surigao del Sur',
        value: 'SUR',
      },
      {
        text: 'Tarlac',
        value: 'TAR',
      },
      {
        text: 'Tawi-Tawi',
        value: 'TAW',
      },
      {
        text: 'Zambales',
        value: 'ZMB',
      },
      {
        text: 'Zamboanga del Norte',
        value: 'ZAN',
      },
      {
        text: 'Zamboanga del Sur',
        value: 'ZAS',
      },
      {
        text: 'Zamboanga Sibugay',
        value: 'ZSI',
      },
    ],
  },
  {
    text: 'Pitcairn',
    value: 'PN',
    states: [
      {
        text: 'Pitcairn Islands',
      },
    ],
  },
  {
    text: 'Poland',
    value: 'PL',
    states: [
      {
        text: 'Dolnośląskie',
        value: 'DS',
      },
      {
        text: 'Kujawsko-pomorskie',
        value: 'KP',
      },
      {
        text: 'Łódzkie',
        value: 'LD',
      },
      {
        text: 'Lubelskie',
        value: 'LU',
      },
      {
        text: 'Lubuskie',
        value: 'LB',
      },
      {
        text: 'Malopolskie',
        value: 'MA',
      },
      {
        text: 'Mazowieckie',
        value: 'MZ',
      },
      {
        text: 'Opolskie',
        value: 'OP',
      },
      {
        text: 'Podkarpackie',
        value: 'PK',
      },
      {
        text: 'Podlaskie',
        value: 'PD',
      },
      {
        text: 'Pomorskie',
        value: 'PM',
      },
      {
        text: 'Śląskie',
        value: 'SL',
      },
      {
        text: 'Świętokrzyskie',
        value: 'SK',
      },
      {
        text: 'Warmińsko-mazurskie',
        value: 'WN',
      },
      {
        text: 'Wielkopolskie',
        value: 'WP',
      },
      {
        text: 'Zachodniopomorskie',
        value: 'ZP',
      },
    ],
  },
  {
    text: 'Portugal',
    value: 'PT',
    states: [
      {
        text: 'Açores',
        value: '20',
      },
      {
        text: 'Aveiro',
        value: '01',
      },
      {
        text: 'Beja',
        value: '02',
      },
      {
        text: 'Braga',
        value: '03',
      },
      {
        text: 'Bragança',
        value: '04',
      },
      {
        text: 'Castelo Branco',
        value: '05',
      },
      {
        text: 'Coimbra',
        value: '06',
      },
      {
        text: 'Évora',
        value: '07',
      },
      {
        text: 'Faro',
        value: '08',
      },
      {
        text: 'Guarda',
        value: '09',
      },
      {
        text: 'Leiria',
        value: '10',
      },
      {
        text: 'Lisboa',
        value: '11',
      },
      {
        text: 'Madeira',
        value: '30',
      },
      {
        text: 'Portalegre',
        value: '12',
      },
      {
        text: 'Porto',
        value: '13',
      },
      {
        text: 'Santarém',
        value: '14',
      },
      {
        text: 'Setúbal',
        value: '15',
      },
      {
        text: 'Viana do Castelo',
        value: '16',
      },
      {
        text: 'Vila Real',
        value: '17',
      },
      {
        text: 'Viseu',
        value: '18',
      },
    ],
  },
  {
    text: 'Puerto Rico',
    value: 'PR',
    states: [
      {
        text: 'Adjuntas',
        value: '1',
      },
      {
        text: 'Aguada',
        value: '2',
      },
      {
        text: 'Aguadilla',
        value: '3',
      },
      {
        text: 'Aguas Buenas',
        value: '4',
      },
      {
        text: 'Aibonito',
        value: '5',
      },
      {
        text: 'Anasco',
        value: '6',
      },
      {
        text: 'Arecibo',
        value: '7',
      },
      {
        text: 'Arroyo',
        value: '8',
      },
      {
        text: 'Barceloneta',
        value: '9',
      },
      {
        text: 'Barranquitas',
        value: '10',
      },
      {
        text: 'Bayamon',
        value: '11',
      },
      {
        text: 'Cabo Rojo',
        value: '12',
      },
      {
        text: 'Caguas',
        value: '13',
      },
      {
        text: 'Camuy',
        value: '14',
      },
      {
        text: 'Canovanas',
        value: '15',
      },
      {
        text: 'Carolina',
        value: '16',
      },
      {
        text: 'Cat',
        value: '17',
      },
      {
        text: 'Ceiba',
        value: '18',
      },
      {
        text: 'Ciales',
        value: '19',
      },
      {
        text: 'Cidra',
        value: '20',
      },
      {
        text: 'Coamo',
        value: '21',
      },
      {
        text: 'Comerio',
        value: '22',
      },
      {
        text: 'Corozal',
        value: '23',
      },
      {
        text: 'Culebra',
        value: '24',
      },
      {
        text: 'Dorado',
        value: '25',
      },
      {
        text: 'Fajardo',
        value: '26',
      },
      {
        text: 'Florida',
        value: '27',
      },
      {
        text: 'Guanica',
        value: '28',
      },
      {
        text: 'Guayama',
        value: '29',
      },
      {
        text: 'Guayanilla',
        value: '30',
      },
      {
        text: 'Guaynabo',
        value: '31',
      },
      {
        text: 'Gurabo',
        value: '32',
      },
      {
        text: 'Hatillo',
        value: '33',
      },
      {
        text: 'Hormigueros',
        value: '34',
      },
      {
        text: 'Humacao',
        value: '35',
      },
      {
        text: 'Isabe',
        value: '36',
      },
      {
        text: 'Juana Diaz',
        value: '37',
      },
      {
        text: 'Juncos',
        value: '38',
      },
      {
        text: 'Lajas',
        value: '39',
      },
      {
        text: 'Lares',
        value: '40',
      },
      {
        text: 'Las Marias',
        value: '41',
      },
      {
        text: 'Las oiza',
        value: '42',
      },
      {
        text: 'Luquillo',
        value: '43',
      },
      {
        text: 'Manati',
        value: '44',
      },
      {
        text: 'Maricao',
        value: '45',
      },
      {
        text: 'Maunabo',
        value: '46',
      },
      {
        text: 'Mayaguez',
        value: '47',
      },
      {
        text: 'Moca',
        value: '48',
      },
      {
        text: 'Morovis',
        value: '49',
      },
      {
        text: 'Naguabo',
        value: '50',
      },
      {
        text: 'Naranjito',
        value: '51',
      },
      {
        text: 'Orocovis',
        value: '52',
      },
      {
        text: 'Patillas',
        value: '53',
      },
      {
        text: 'Penuelas',
        value: '54',
      },
      {
        text: 'Ponce',
        value: '55',
      },
      {
        text: 'Quebradillas',
        value: '56',
      },
      {
        text: 'Rincon',
        value: '57',
      },
      {
        text: 'Rio Grande',
        value: '58',
      },
      {
        text: 'Sabana linas',
        value: '59',
      },
      {
        text: 'San German',
        value: '60',
      },
      {
        text: 'San Juan',
        value: '61',
      },
      {
        text: 'San Lorenzo',
        value: '62',
      },
      {
        text: 'San Sebastian',
        value: '63',
      },
      {
        text: 'Santa Isabel',
        value: '64',
      },
      {
        text: 'Toa Alta',
        value: '65',
      },
      {
        text: 'Toa Baja',
        value: '66',
      },
      {
        text: 'Trujillo Alto',
        value: '67',
      },
      {
        text: 'Utuado',
        value: '68',
      },
      {
        text: 'Vega Alta',
        value: '69',
      },
      {
        text: 'Vega ues',
        value: '70',
      },
      {
        text: 'Villalba',
        value: '71',
      },
      {
        text: 'Yabucoa',
        value: '72',
      },
      {
        text: 'Yauco',
        value: '73',
      },
    ],
  },
  {
    text: 'Qatar',
    value: 'QA',
    states: [
      {
        text: 'Ad Dawḩah',
        value: 'DA',
      },
      {
        text: 'Al Khawr wa adh Dhakhīrah',
        value: 'KH',
      },
      {
        text: 'Al Wakrah',
        value: 'WA',
      },
      {
        text: 'Ar Rayyān',
        value: 'RA',
      },
      {
        text: 'Ash Shamāl',
        value: 'MS',
      },
      {
        text: 'Az̧ Za̧`āyin',
        value: 'ZA',
      },
      {
        text: 'Umm Şalāl',
        value: 'US',
      },
    ],
  },
  {
    text: 'Réunion',
    value: 'RE',
    states: [
      {
        text: 'Réunion',
      },
    ],
  },
  {
    text: 'Romania',
    value: 'RO',
    states: [
      {
        text: 'Alba',
        value: 'AB',
      },
      {
        text: 'Arad',
        value: 'AR',
      },
      {
        text: 'Arges',
        value: 'AG',
      },
      {
        text: 'Bacau',
        value: 'BC',
      },
      {
        text: 'Bihor',
        value: 'BH',
      },
      {
        text: 'Bistrita-Nasaud',
        value: 'BN',
      },
      {
        text: 'Botosani',
        value: 'BT',
      },
      {
        text: 'Braila',
        value: 'BR',
      },
      {
        text: 'Brasov',
        value: 'BV',
      },
      {
        text: 'Bucuresti',
        value: 'B',
      },
      {
        text: 'Buzau',
        value: 'BZ',
      },
      {
        text: 'Calarasi',
        value: 'CL',
      },
      {
        text: 'Caras-Severin',
        value: 'CS',
      },
      {
        text: 'Cluj',
        value: 'CJ',
      },
      {
        text: 'Constanta',
        value: 'CT',
      },
      {
        text: 'Covasna',
        value: 'CV',
      },
      {
        text: 'Dambovita',
        value: 'DB',
      },
      {
        text: 'Dolj',
        value: 'DJ',
      },
      {
        text: 'Galati',
        value: 'GL',
      },
      {
        text: 'Giurgiu',
        value: 'GR',
      },
      {
        text: 'Gorj',
        value: 'GJ',
      },
      {
        text: 'Harghita',
        value: 'HR',
      },
      {
        text: 'Hunedoara',
        value: 'HD',
      },
      {
        text: 'Ialomita',
        value: 'IL',
      },
      {
        text: 'Iasi',
        value: 'IS',
      },
      {
        text: 'Maramures',
        value: 'MM',
      },
      {
        text: 'Mehedinti',
        value: 'MH',
      },
      {
        text: 'Mures',
        value: 'MS',
      },
      {
        text: 'Neamt',
        value: 'NT',
      },
      {
        text: 'Olt',
        value: 'OT',
      },
      {
        text: 'Prahova',
        value: 'PH',
      },
      {
        text: 'Salaj',
        value: 'SJ',
      },
      {
        text: 'Satu Mare',
        value: 'SM',
      },
      {
        text: 'Sibiu',
        value: 'SB',
      },
      {
        text: 'Suceava',
        value: 'SV',
      },
      {
        text: 'Teleorman',
        value: 'TR',
      },
      {
        text: 'Timis',
        value: 'TM',
      },
      {
        text: 'Tulcea',
        value: 'TL',
      },
      {
        text: 'Valcea',
        value: 'VL',
      },
      {
        text: 'Vaslui',
        value: 'VS',
      },
      {
        text: 'Vrancea',
        value: 'VN',
      },
    ],
  },
  {
    text: 'Russian Federation',
    value: 'RU',
    states: [
      {
        text: 'Republic of Adygea',
        value: 'AD',
      },
      {
        text: 'Republic of Altai (Gorno-Altaysk)',
        value: 'AL',
      },
      {
        text: 'Altai Krai',
        value: 'ALT',
      },
      {
        text: 'Amur Oblast',
        value: 'AMU',
      },
      {
        text: 'Arkhangelsk Oblast',
        value: 'ARK',
      },
      {
        text: 'Astrakhan Oblast',
        value: 'AST',
      },
      {
        text: 'Republic of Bashkortostan',
        value: 'BA',
      },
      {
        text: 'Belgorod Oblast',
        value: 'BEL',
      },
      {
        text: 'Bryansk Oblast',
        value: 'BRY',
      },
      {
        text: 'Republic of Buryatia',
        value: 'BU',
      },
      {
        text: 'Chechen Republic',
        value: 'CE',
      },
      {
        text: 'Chelyabinsk Oblast',
        value: 'CHE',
      },
      {
        text: 'Chukotka Autonomous Okrug',
        value: 'CHU',
      },
      {
        text: 'Chuvash Republic',
        value: 'CU',
      },
      {
        text: 'Republic of Dagestan',
        value: 'DA',
      },
      {
        text: 'Republic of Ingushetia',
        value: 'IN',
      },
      {
        text: 'Irkutsk Oblast',
        value: 'IRK',
      },
      {
        text: 'Ivanovo Oblast',
        value: 'IVA',
      },
      {
        text: 'Jewish Autonomous Oblast',
        value: 'JEW',
      },
      {
        text: 'Kabardino-Balkar Republic',
        value: 'KB',
      },
      {
        text: 'Kaliningrad Oblast',
        value: 'KLN',
      },
      {
        text: 'Republic of Kalmykia',
        value: 'KL',
      },
      {
        text: 'Kaluga Oblast',
        value: 'KLU',
      },
      {
        text: 'Kamchatka Krai',
        value: 'KAM',
      },
      {
        text: 'Karachay-Cherkess Republic',
        value: 'KC',
      },
      {
        text: 'Republic of Karelia',
        value: 'KR',
      },
      {
        text: 'Khabarovsk Krai',
        value: 'KHA',
      },
      {
        text: 'Republic of Khakassia',
        value: 'KK',
      },
      {
        text: 'Khanty-Mansi Autonomous Okrug - Yugra',
        value: 'KHM',
      },
      {
        text: 'Kemerovo Oblast',
        value: 'KEM',
      },
      {
        text: 'Kirov Oblast',
        value: 'KIR',
      },
      {
        text: 'Komi Republic',
        value: 'KO',
      },
      {
        text: 'Kostroma Oblast',
        value: 'KOS',
      },
      {
        text: 'Krasnodar Krai',
        value: 'KDA',
      },
      {
        text: 'Krasnoyarsk Krai',
        value: 'KYA',
      },
      {
        text: 'Kurgan Oblast',
        value: 'KGN',
      },
      {
        text: 'Kursk Oblast',
        value: 'KRS',
      },
      {
        text: 'Leningrad Oblast',
        value: 'LEN',
      },
      {
        text: 'Lipetsk Oblast',
        value: 'LIP',
      },
      {
        text: 'Magadan Oblast',
        value: 'MAG',
      },
      {
        text: 'Mari El Republic',
        value: 'ME',
      },
      {
        text: 'Republic of Mordovia',
        value: 'MO',
      },
      {
        text: 'Moscow Oblast',
        value: 'MOS',
      },
      {
        text: 'Moscow',
        value: 'MOW',
      },
      {
        text: 'Murmansk Oblast',
        value: 'MU',
      },
      {
        text: 'Nenets Autonomous Okrug',
        value: 'NEN',
      },
      {
        text: 'Nizhny Novgorod Oblast',
        value: 'NIZ',
      },
      {
        text: 'Novgorod Oblast',
        value: 'NGR',
      },
      {
        text: 'Novosibirsk Oblast',
        value: 'NVS',
      },
      {
        text: 'Omsk Oblast',
        value: 'OMS',
      },
      {
        text: 'Orenburg Oblast',
        value: 'ORE',
      },
      {
        text: 'Oryol Oblast',
        value: 'ORL',
      },
      {
        text: 'Penza Oblast',
        value: 'PNZ',
      },
      {
        text: 'Perm Krai',
        value: 'PER',
      },
      {
        text: 'Primorsky Krai',
        value: 'PRI',
      },
      {
        text: 'Pskov Oblast',
        value: 'PSK',
      },
      {
        text: 'Rostov Oblast',
        value: 'ROS',
      },
      {
        text: 'Ryazan Oblast',
        value: 'RYA',
      },
      {
        text: 'Saint Petersburg',
        value: 'SPE',
      },
      {
        text: 'Sakha (Yakutia) Republic',
        value: 'SA',
      },
      {
        text: 'Sakhalin Oblast',
        value: 'SAK',
      },
      {
        text: 'Samara Oblast',
        value: 'SAM',
      },
      {
        text: 'Saratov Oblast',
        value: 'SAR',
      },
      {
        text: 'Republic of North Ossetia-Alania',
        value: 'NOA',
      },
      {
        text: 'Smolensk Oblast',
        value: 'SMO',
      },
      {
        text: 'Stavropol Krai',
        value: 'STA',
      },
      {
        text: 'Sverdlovsk Oblast',
        value: 'SVE',
      },
      {
        text: 'Tambov Oblast',
        value: 'TAM',
      },
      {
        text: 'Republic of Tatarstan',
        value: 'TA',
      },
      {
        text: 'Tomsk Oblast',
        value: 'TOM',
      },
      {
        text: 'Tuva Republic',
        value: 'TU',
      },
      {
        text: 'Tula Oblast',
        value: 'TUL',
      },
      {
        text: 'Tver Oblast',
        value: 'TVE',
      },
      {
        text: 'Tyumen Oblast',
        value: 'TYU',
      },
      {
        text: 'Udmurt Republic',
        value: 'UD',
      },
      {
        text: 'Ulyanovsk Oblast',
        value: 'ULY',
      },
      {
        text: 'Vladimir Oblast',
        value: 'VLA',
      },
      {
        text: 'Volgograd Oblast',
        value: 'VGG',
      },
      {
        text: 'Vologda Oblast',
        value: 'VLG',
      },
      {
        text: 'Voronezh Oblast',
        value: 'VOR',
      },
      {
        text: 'Yamalo-Nenets Autonomous Okrug',
        value: 'YAN',
      },
      {
        text: 'Yaroslavl Oblast',
        value: 'YAR',
      },
      {
        text: 'Zabaykalsky Krai',
        value: 'ZAB',
      },
    ],
  },
  {
    text: 'Rwanda',
    value: 'RW',
    states: [
      {
        text: 'Kigali',
        value: '01',
      },
      {
        text: 'Eastern',
        value: '02',
      },
      {
        text: 'Northern',
        value: '03',
      },
      {
        text: 'Western',
        value: '04',
      },
      {
        text: 'Southern',
        value: '05',
      },
    ],
  },
  {
    text: 'Saint Barthélemy',
    value: 'BL',
    states: [
      {
        text: 'Au Vent',
        value: '02',
      },
      {
        text: 'Sous le Vent',
        value: '01',
      },
    ],
  },
  {
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    states: [
      {
        text: 'Ascension',
        value: 'AC',
      },
      {
        text: 'Saint Helena',
        value: 'HL',
      },
      {
        text: 'Tristan da Cunha',
        value: 'TA',
      },
    ],
  },
  {
    text: 'Saint Kitts and Nevis',
    value: 'KN',
    states: [
      {
        text: 'Saint Kitts',
        value: 'K',
      },
      {
        text: 'Nevis',
        value: 'N',
      },
    ],
  },
  {
    text: 'Saint Lucia',
    value: 'LC',
    states: [
      {
        text: 'Anse-la-Raye',
        value: '01',
      },
      {
        text: 'Canaries',
        value: '12',
      },
      {
        text: 'Castries',
        value: '02',
      },
      {
        text: 'Choiseul',
        value: '03',
      },
      {
        text: 'Dennery',
        value: '05',
      },
      {
        text: 'Gros Islet',
        value: '06',
      },
      {
        text: 'Laborie',
        value: '07',
      },
      {
        text: 'Micoud',
        value: '08',
      },
      {
        text: 'Soufriere',
        value: '10',
      },
      {
        text: 'Vieux Fort',
        value: '11',
      },
    ],
  },
  {
    text: 'Saint Martin',
    value: 'MF',
    states: [
      {
        text: 'Saint Martin',
      },
    ],
  },
  {
    text: 'Saint Pierre and Miquelon',
    value: 'PM',
    states: [
      {
        text: 'Miquelon',
      },
      {
        text: 'Saint Pierre',
      },
    ],
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'VC',
    states: [
      {
        text: 'Charlotte',
        value: '01',
      },
      {
        text: 'Grenadines',
        value: '06',
      },
      {
        text: 'Saint Andrew',
        value: '02',
      },
      {
        text: 'Saint David',
        value: '03',
      },
      {
        text: 'Saint George',
        value: '04',
      },
      {
        text: 'Saint Patrick',
        value: '05',
      },
    ],
  },
  {
    text: 'Samoa',
    value: 'WS',
    states: [
      {
        text: "A'ana",
        value: 'AA',
      },
      {
        text: 'Aiga-i-le-Tai',
        value: 'AL',
      },
      {
        text: 'Atua',
        value: 'AT',
      },
      {
        text: "Fa'asaleleaga",
        value: 'FA',
      },
      {
        text: "Gaga'emauga",
        value: 'GE',
      },
      {
        text: 'Gagaifomauga',
        value: 'GI',
      },
      {
        text: 'Palauli',
        value: 'PA',
      },
      {
        text: "Satupa'itea",
        value: 'SA',
      },
      {
        text: 'Tuamasaga',
        value: 'TU',
      },
      {
        text: "Va'a-o-Fonoti",
        value: 'VF',
      },
      {
        text: 'Vaisigano',
        value: 'VS',
      },
    ],
  },
  {
    text: 'San Marino',
    value: 'SM',
    states: [
      {
        text: 'Acquaviva',
        value: '01',
      },
      {
        text: 'Borgo Maggiore',
        value: '06',
      },
      {
        text: 'Chiesanuova',
        value: '02',
      },
      {
        text: 'Domagnano',
        value: '03',
      },
      {
        text: 'Faetano',
        value: '04',
      },
      {
        text: 'Fiorentino',
        value: '05',
      },
      {
        text: 'Montegiardino',
        value: '08',
      },
      {
        text: 'San Marino',
        value: '07',
      },
      {
        text: 'Serravalle',
        value: '09',
      },
    ],
  },
  {
    text: 'Sao Tome and Principe',
    value: 'ST',
    states: [
      {
        text: 'Principe',
        value: 'P',
      },
      {
        text: 'Sao Tome',
        value: 'S',
      },
    ],
  },
  {
    text: 'Saudi Arabia',
    value: 'SA',
    states: [
      {
        text: "'Asir",
        value: '14',
      },
      {
        text: 'Al Bahah',
        value: '11',
      },
      {
        text: 'Al Hudud ash Shamaliyah',
        value: '08',
      },
      {
        text: 'Al Jawf',
        value: '12',
      },
      {
        text: 'Al Madinah al Munawwarah',
        value: '03',
      },
      {
        text: 'Al Qasim',
        value: '05',
      },
      {
        text: 'Ar Riyad',
        value: '01',
      },
      {
        text: 'Ash Sharqiyah',
        value: '04',
      },
      {
        text: "Ha'il",
        value: '06',
      },
      {
        text: 'Jazan',
        value: '09',
      },
      {
        text: 'Makkah al Mukarramah',
        value: '02',
      },
      {
        text: 'Najran',
        value: '10',
      },
      {
        text: 'Tabuk',
        value: '07',
      },
    ],
  },
  {
    text: 'Senegal',
    value: 'SN',
    states: [
      {
        text: 'Dakar',
        value: 'DK',
      },
      {
        text: 'Diourbel',
        value: 'DB',
      },
      {
        text: 'Fatick',
        value: 'FK',
      },
      {
        text: 'Kaffrine',
        value: 'KA',
      },
      {
        text: 'Kaolack',
        value: 'KL',
      },
      {
        text: 'Kedougou',
        value: 'KE',
      },
      {
        text: 'Kolda',
        value: 'KD',
      },
      {
        text: 'Louga',
        value: 'LG',
      },
      {
        text: 'Matam',
        value: 'MT',
      },
      {
        text: 'Saint-Louis',
        value: 'SL',
      },
      {
        text: 'Sedhiou',
        value: 'SE',
      },
      {
        text: 'Tambacounda',
        value: 'TC',
      },
      {
        text: 'Thies',
        value: 'TH',
      },
      {
        text: 'Ziguinchor',
        value: 'ZG',
      },
    ],
  },
  {
    text: 'Serbia',
    value: 'RS',
    states: [
      {
        text: 'Beograd (Belgrade)',
        value: '00',
      },
      {
        text: 'Borski',
        value: '14',
      },
      {
        text: 'Braničevski',
        value: '11',
      },
      {
        text: 'Jablanički',
        value: '23',
      },
      {
        text: 'Južnobački',
        value: '06',
      },
      {
        text: 'Južnobanatski',
        value: '04',
      },
      {
        text: 'Kolubarski',
        value: '09',
      },
      {
        text: 'Kosovski',
        value: '25',
      },
      {
        text: 'Kosovsko-Mitrovački',
        value: '28',
      },
      {
        text: 'Kosovsko-Pomoravski',
        value: '29',
      },
      {
        text: 'Mačvanski',
        value: '08',
      },
      {
        text: 'Moravički',
        value: '17',
      },
      {
        text: 'Nišavski',
        value: '20',
      },
      {
        text: 'Pčinjski',
        value: '24',
      },
      {
        text: 'Pećki',
        value: '26',
      },
      {
        text: 'Pirotski',
        value: '22',
      },
      {
        text: 'Podunavski',
        value: '10',
      },
      {
        text: 'Pomoravski',
        value: '13',
      },
      {
        text: 'Prizrenski',
        value: '27',
      },
      {
        text: 'Rasinski',
        value: '19',
      },
      {
        text: 'Raški',
        value: '18',
      },
      {
        text: 'Severnobački',
        value: '01',
      },
      {
        text: 'Severnobanatski',
        value: '03',
      },
      {
        text: 'Srednjebanatski',
        value: '02',
      },
      {
        text: 'Sremski',
        value: '07',
      },
      {
        text: 'Šumadijski',
        value: '12',
      },
      {
        text: 'Toplički',
        value: '21',
      },
      {
        text: 'Zaječarski',
        value: '15',
      },
      {
        text: 'Zapadnobački',
        value: '05',
      },
      {
        text: 'Zlatiborski',
        value: '16',
      },
    ],
  },
  {
    text: 'Seychelles',
    value: 'SC',
    states: [
      {
        text: 'Anse aux Pins',
        value: '01',
      },
      {
        text: 'Anse Boileau',
        value: '02',
      },
      {
        text: 'Anse Etoile',
        value: '03',
      },
      {
        text: 'Anse Royale',
        value: '05',
      },
      {
        text: 'Anu Cap',
        value: '04',
      },
      {
        text: 'Baie Lazare',
        value: '06',
      },
      {
        text: 'Baie Sainte Anne',
        value: '07',
      },
      {
        text: 'Beau Vallon',
        value: '08',
      },
      {
        text: 'Bel Air',
        value: '09',
      },
      {
        text: 'Bel Ombre',
        value: '10',
      },
      {
        text: 'Cascade',
        value: '11',
      },
      {
        text: 'Glacis',
        value: '12',
      },
      {
        text: "Grand'Anse Mahe",
        value: '13',
      },
      {
        text: "Grand'Anse Praslin",
        value: '14',
      },
      {
        text: 'La Digue',
        value: '15',
      },
      {
        text: 'La Riviere Anglaise',
        value: '16',
      },
      {
        text: 'Les Mamelles',
        value: '24',
      },
      {
        text: 'Mont Buxton',
        value: '17',
      },
      {
        text: 'Mont Fleuri',
        value: '18',
      },
      {
        text: 'Plaisance',
        value: '19',
      },
      {
        text: 'Pointe La Rue',
        value: '20',
      },
      {
        text: 'Port Glaud',
        value: '21',
      },
      {
        text: 'Roche Caiman',
        value: '25',
      },
      {
        text: 'Saint Louis',
        value: '22',
      },
      {
        text: 'Takamaka',
        value: '23',
      },
    ],
  },
  {
    text: 'Sierra Leone',
    value: 'SL',
    states: [
      {
        text: 'Eastern',
        value: 'E',
      },
      {
        text: 'Northern',
        value: 'N',
      },
      {
        text: 'Southern',
        value: 'S',
      },
      {
        text: 'Western',
        value: 'W',
      },
    ],
  },
  {
    text: 'Singapore',
    value: 'SG',
    states: [
      {
        text: 'Central Singapore',
        value: '01',
      },
      {
        text: 'North East',
        value: '02',
      },
      {
        text: 'North West',
        value: '03',
      },
      {
        text: 'South East',
        value: '04',
      },
      {
        text: 'South West',
        value: '05',
      },
    ],
  },
  {
    text: 'Sint Maarten (Dutch part)',
    value: 'SX',
    states: [
      {
        text: 'Sint Maarten',
      },
    ],
  },
  {
    text: 'Slovakia',
    value: 'SK',
    states: [
      {
        text: 'Banskobystricky',
        value: 'BC',
      },
      {
        text: 'Bratislavsky',
        value: 'BL',
      },
      {
        text: 'Kosicky',
        value: 'KI',
      },
      {
        text: 'Nitriansky',
        value: 'NI',
      },
      {
        text: 'Presovsky',
        value: 'PV',
      },
      {
        text: 'Trenciansky',
        value: 'TC',
      },
      {
        text: 'Trnavsky',
        value: 'TA',
      },
      {
        text: 'Zilinsky',
        value: 'ZI',
      },
    ],
  },
  {
    text: 'Slovenia',
    value: 'SI',
    states: [
      {
        text: 'Ajdovscina',
        value: '001',
      },
      {
        text: 'Apace',
        value: '195',
      },
      {
        text: 'Beltinci',
        value: '002',
      },
      {
        text: 'Benedikt',
        value: '148',
      },
      {
        text: 'Bistrica ob Sotli',
        value: '149',
      },
      {
        text: 'Bled',
        value: '003',
      },
      {
        text: 'Bloke',
        value: '150',
      },
      {
        text: 'Bohinj',
        value: '004',
      },
      {
        text: 'Borovnica',
        value: '005',
      },
      {
        text: 'Bovec',
        value: '006',
      },
      {
        text: 'Braslovce',
        value: '151',
      },
      {
        text: 'Brda',
        value: '007',
      },
      {
        text: 'Brezice',
        value: '009',
      },
      {
        text: 'Brezovica',
        value: '008',
      },
      {
        text: 'Cankova',
        value: '152',
      },
      {
        text: 'Celje',
        value: '011',
      },
      {
        text: 'Cerklje na Gorenjskem',
        value: '012',
      },
      {
        text: 'Cerknica',
        value: '013',
      },
      {
        text: 'Cerkno',
        value: '014',
      },
      {
        text: 'Cerkvenjak',
        value: '153',
      },
      {
        text: 'Cirkulane',
        value: '196',
      },
      {
        text: 'Crensovci',
        value: '015',
      },
      {
        text: 'Crna na Koroskem',
        value: '016',
      },
      {
        text: 'Crnomelj',
        value: '017',
      },
      {
        text: 'Destrnik',
        value: '018',
      },
      {
        text: 'Divaca',
        value: '019',
      },
      {
        text: 'Dobje',
        value: '154',
      },
      {
        text: 'Dobrepolje',
        value: '020',
      },
      {
        text: 'Dobrna',
        value: '155',
      },
      {
        text: 'Dobrova-Polhov Gradec',
        value: '021',
      },
      {
        text: 'Dobrovnik',
        value: '156',
      },
      {
        text: 'Dol pri Ljubljani',
        value: '022',
      },
      {
        text: 'Dolenjske Toplice',
        value: '157',
      },
      {
        text: 'Domzale',
        value: '023',
      },
      {
        text: 'Dornava',
        value: '024',
      },
      {
        text: 'Dravograd',
        value: '025',
      },
      {
        text: 'Duplek',
        value: '026',
      },
      {
        text: 'Gorenja Vas-Poljane',
        value: '027',
      },
      {
        text: 'Gorisnica',
        value: '028',
      },
      {
        text: 'Gorje',
        value: '207',
      },
      {
        text: 'Gornja Radgona',
        value: '029',
      },
      {
        text: 'Gornji Grad',
        value: '030',
      },
      {
        text: 'Gornji Petrovci',
        value: '031',
      },
      {
        text: 'Grad',
        value: '158',
      },
      {
        text: 'Grosuplje',
        value: '032',
      },
      {
        text: 'Hajdina',
        value: '159',
      },
      {
        text: 'Hoce-Slivnica',
        value: '160',
      },
      {
        text: 'Hodos',
        value: '161',
      },
      {
        text: 'Horjul',
        value: '162',
      },
      {
        text: 'Hrastnik',
        value: '034',
      },
      {
        text: 'Hrpelje-Kozina',
        value: '035',
      },
      {
        text: 'Idrija',
        value: '036',
      },
      {
        text: 'Ig',
        value: '037',
      },
      {
        text: 'Ilirska Bistrica',
        value: '038',
      },
      {
        text: 'Ivancna Gorica',
        value: '039',
      },
      {
        text: 'Izola',
        value: '040s',
      },
      {
        text: 'Jesenice',
        value: '041',
      },
      {
        text: 'Jursinci',
        value: '042',
      },
      {
        text: 'Kamnik',
        value: '043',
      },
      {
        text: 'Kanal',
        value: '044',
      },
      {
        text: 'Kidricevo',
        value: '045',
      },
      {
        text: 'Kobarid',
        value: '046',
      },
      {
        text: 'Kobilje',
        value: '047',
      },
      {
        text: 'Kocevje',
        value: '048',
      },
      {
        text: 'Komen',
        value: '049',
      },
      {
        text: 'Komenda',
        value: '164',
      },
      {
        text: 'Koper',
        value: '050',
      },
      {
        text: 'Kodanjevica na Krki',
        value: '197',
      },
      {
        text: 'Kostel',
        value: '165',
      },
      {
        text: 'Kozje',
        value: '051',
      },
      {
        text: 'Kranj',
        value: '052',
      },
      {
        text: 'Kranjska Gora',
        value: '053',
      },
      {
        text: 'Krizevci',
        value: '166',
      },
      {
        text: 'Krsko',
        value: '054',
      },
      {
        text: 'Kungota',
        value: '055',
      },
      {
        text: 'Kuzma',
        value: '056',
      },
      {
        text: 'Lasko',
        value: '057',
      },
      {
        text: 'Lenart',
        value: '058',
      },
      {
        text: 'Lendava',
        value: '059',
      },
      {
        text: 'Litija',
        value: '068',
      },
      {
        text: 'Ljubljana',
        value: '061',
      },
      {
        text: 'Ljubno',
        value: '062',
      },
      {
        text: 'Ljutomer',
        value: '063',
      },
      {
        text: 'Log-Dragomer',
        value: '208',
      },
      {
        text: 'Logatec',
        value: '064',
      },
      {
        text: 'Loska Dolina',
        value: '065',
      },
      {
        text: 'Loski Potok',
        value: '066',
      },
      {
        text: 'Lovrenc na Pohorju',
        value: '167',
      },
      {
        text: 'Lukovica',
        value: '068',
      },
      {
        text: 'Luce',
        value: '067',
      },
      {
        text: 'Majsperk',
        value: '069',
      },
      {
        text: 'Makole',
        value: '198',
      },
      {
        text: 'Maribor',
        value: '070',
      },
      {
        text: 'Markovci',
        value: '168',
      },
      {
        text: 'Medvode',
        value: '071',
      },
      {
        text: 'Menges',
        value: '072',
      },
      {
        text: 'Metlika',
        value: '073',
      },
      {
        text: 'Mezica',
        value: '074',
      },
      {
        text: 'Miklavz na Dravskem Polju',
        value: '169',
      },
      {
        text: 'Miren-Kostanjevica',
        value: '075',
      },
      {
        text: 'Mirna',
        value: '212',
      },
      {
        text: 'Mirna Pec',
        value: '170',
      },
      {
        text: 'Mislinja',
        value: '076',
      },
      {
        text: 'Mokronog-Trebelno',
        value: '199',
      },
      {
        text: 'Moravce',
        value: '077',
      },
      {
        text: 'Moravske Toplice',
        value: '078',
      },
      {
        text: 'Mozirje',
        value: '079',
      },
      {
        text: 'Murska Sobota',
        value: '080',
      },
      {
        text: 'Naklo',
        value: '082',
      },
      {
        text: 'Nazarje',
        value: '083',
      },
      {
        text: 'Nova Gorica',
        value: '084',
      },
      {
        text: 'Novo Mesto',
        value: '085',
      },
      {
        text: 'Odranci',
        value: '086',
      },
      {
        text: 'Ormoz',
        value: '087',
      },
      {
        text: 'Osilnica',
        value: '088',
      },
      {
        text: 'Pesnica',
        value: '089',
      },
      {
        text: 'Piran',
        value: '090',
      },
      {
        text: 'Pivka',
        value: '091',
      },
      {
        text: 'Podcetrtek',
        value: '092',
      },
      {
        text: 'Podlehnik',
        value: '172',
      },
      {
        text: 'Podvelka',
        value: '093',
      },
      {
        text: 'Poljcane',
        value: '200',
      },
      {
        text: 'Postojna',
        value: '094',
      },
      {
        text: 'Prebold',
        value: '174',
      },
      {
        text: 'Preddvor',
        value: '095',
      },
      {
        text: 'Prevalje',
        value: '175',
      },
      {
        text: 'Ptuj',
        value: '096',
      },
      {
        text: 'Race-Fram',
        value: '098',
      },
      {
        text: 'Radece',
        value: '099',
      },
      {
        text: 'Radenci',
        value: '100',
      },
      {
        text: 'Radlje ob Dravi',
        value: '101',
      },
      {
        text: 'Radovljica',
        value: '102',
      },
      {
        text: 'Ravne na Koroskem',
        value: '103',
      },
      {
        text: 'Razkrizje',
        value: '176',
      },
      {
        text: 'Recica ob Savinji',
        value: '209',
      },
      {
        text: 'Rence-Vogrsko',
        value: '201',
      },
      {
        text: 'Ribnica',
        value: '104',
      },
      {
        text: 'Ribnica na Poboriu',
        value: '177',
      },
      {
        text: 'Rogaska Slatina',
        value: '106',
      },
      {
        text: 'Rogasovci',
        value: '105',
      },
      {
        text: 'Rogatec',
        value: '107',
      },
      {
        text: 'Ruse',
        value: '108',
      },
      {
        text: 'Salovci',
        value: '033',
      },
      {
        text: 'Selnica ob Dravi',
        value: '178',
      },
      {
        text: 'Semic',
        value: '109',
      },
      {
        text: 'Sempeter-Vrtojba',
        value: '183',
      },
      {
        text: 'Sencur',
        value: '117',
      },
      {
        text: 'Sentilj',
        value: '118',
      },
      {
        text: 'Sentjernej',
        value: '119',
      },
      {
        text: 'Sentjur',
        value: '120',
      },
      {
        text: 'Sentrupert',
        value: '211',
      },
      {
        text: 'Sevnica',
        value: '110',
      },
      {
        text: 'Sezana',
        value: '111',
      },
      {
        text: 'Skocjan',
        value: '121',
      },
      {
        text: 'Skofja Loka',
        value: '122',
      },
      {
        text: 'Skofljica',
        value: '123',
      },
      {
        text: 'Slovenj Gradec',
        value: '112',
      },
      {
        text: 'Slovenska Bistrica',
        value: '113',
      },
      {
        text: 'Slovenske Konjice',
        value: '114',
      },
      {
        text: 'Smarje pri elsah',
        value: '124',
      },
      {
        text: 'Smarjeske Toplice',
        value: '206',
      },
      {
        text: 'Smartno ob Paki',
        value: '125',
      },
      {
        text: 'Smartno pri Litiji',
        value: '194',
      },
      {
        text: 'Sodrazica',
        value: '179',
      },
      {
        text: 'Solcava',
        value: '180',
      },
      {
        text: 'Sostanj',
        value: '126',
      },
      {
        text: 'Sredisce ob Dravi',
        value: '202',
      },
      {
        text: 'Starse',
        value: '115',
      },
      {
        text: 'Store',
        value: '127',
      },
      {
        text: 'Straza',
        value: '203',
      },
      {
        text: 'Sveta Ana',
        value: '181',
      },
      {
        text: 'Sveta Trojica v Slovenskih Goricah',
        value: '204',
      },
      {
        text: 'Sveta Andraz v Slovenskih Goricah',
        value: '182',
      },
      {
        text: 'Sveti Jurij',
        value: '116',
      },
      {
        text: 'Sveti Jurij v Slovenskih Goricah',
        value: '210',
      },
      {
        text: 'Sveti Tomaz',
        value: '205',
      },
      {
        text: 'Tabor',
        value: '184',
      },
      {
        text: 'Tisina',
        value: '128',
      },
      {
        text: 'Tolmin',
        value: '128',
      },
      {
        text: 'Trbovlje',
        value: '129',
      },
      {
        text: 'Trebnje',
        value: '130',
      },
      {
        text: 'Trnovska Vas',
        value: '185',
      },
      {
        text: 'Trzin',
        value: '186',
      },
      {
        text: 'Trzic',
        value: '131',
      },
      {
        text: 'Turnisce',
        value: '132',
      },
      {
        text: 'Velenje',
        value: '133',
      },
      {
        text: 'Velika Polana',
        value: '187',
      },
      {
        text: 'Velike Lasce',
        value: '134',
      },
      {
        text: 'Verzej',
        value: '188',
      },
      {
        text: 'Videm',
        value: '135',
      },
      {
        text: 'Vipava',
        value: '136',
      },
      {
        text: 'Vitanje',
        value: '137',
      },
      {
        text: 'Vodice',
        value: '138',
      },
      {
        text: 'Vojnik',
        value: '139',
      },
      {
        text: 'Vransko',
        value: '189',
      },
      {
        text: 'Vrhnika',
        value: '140',
      },
      {
        text: 'Vuzenica',
        value: '141',
      },
      {
        text: 'Zagorje ob Savi',
        value: '142',
      },
      {
        text: 'Zavrc',
        value: '143',
      },
      {
        text: 'Zrece',
        value: '144',
      },
      {
        text: 'Zalec',
        value: '190',
      },
      {
        text: 'Zelezniki',
        value: '146',
      },
      {
        text: 'Zetale',
        value: '191',
      },
      {
        text: 'Ziri',
        value: '147',
      },
      {
        text: 'Zirovnica',
        value: '192',
      },
      {
        text: 'Zuzemberk',
        value: '193',
      },
    ],
  },
  {
    text: 'Solomon Islands',
    value: 'SB',
    states: [
      {
        text: 'Central',
        value: 'CE',
      },
      {
        text: 'Choiseul',
        value: 'CH',
      },
      {
        text: 'Guadalcanal',
        value: 'GU',
      },
      {
        text: 'Honiara',
        value: 'CT',
      },
      {
        text: 'Isabel',
        value: 'IS',
      },
      {
        text: 'Makira-Ulawa',
        value: 'MK',
      },
      {
        text: 'Malaita',
        value: 'ML',
      },
      {
        text: 'Rennell and Bellona',
        value: 'RB',
      },
      {
        text: 'Temotu',
        value: 'TE',
      },
      {
        text: 'Western',
        value: 'WE',
      },
    ],
  },
  {
    text: 'Somalia',
    value: 'SO',
    states: [
      {
        text: 'Awdal',
        value: 'AW',
      },
      {
        text: 'Bakool',
        value: 'BK',
      },
      {
        text: 'Banaadir',
        value: 'BN',
      },
      {
        text: 'Bari',
        value: 'BR',
      },
      {
        text: 'Bay',
        value: 'BY',
      },
      {
        text: 'Galguduud',
        value: 'GA',
      },
      {
        text: 'Gedo',
        value: 'GE',
      },
      {
        text: 'Hiiraan',
        value: 'HI',
      },
      {
        text: 'Jubbada Dhexe',
        value: 'JD',
      },
      {
        text: 'Jubbada Hoose',
        value: 'JH',
      },
      {
        text: 'Mudug',
        value: 'MU',
      },
      {
        text: 'Nugaal',
        value: 'NU',
      },
      {
        text: 'Sanaag',
        value: 'SA',
      },
      {
        text: 'Shabeellaha Dhexe',
        value: 'SD',
      },
      {
        text: 'Shabeellaha Hoose',
        value: 'SH',
      },
      {
        text: 'Sool',
        value: 'SO',
      },
      {
        text: 'Togdheer',
        value: 'TO',
      },
      {
        text: 'Woqooyi Galbeed',
        value: 'WO',
      },
    ],
  },
  {
    text: 'South Africa',
    value: 'ZA',
    states: [
      {
        text: 'Eastern Cape',
        value: 'EC',
      },
      {
        text: 'Free State',
        value: 'FS',
      },
      {
        text: 'Gauteng',
        value: 'GT',
      },
      {
        text: 'KwaZulu-Natal',
        value: 'NL',
      },
      {
        text: 'Limpopo',
        value: 'LP',
      },
      {
        text: 'Mpumalanga',
        value: 'MP',
      },
      {
        text: 'Northern Cape',
        value: 'NC',
      },
      {
        text: 'North West',
        value: 'NW',
      },
      {
        text: 'Western Cape',
        value: 'WC',
      },
    ],
  },
  {
    text: 'South Georgia and South Sandwich Islands',
    value: 'GS',
    states: [
      {
        text: 'Bird Island',
      },
      {
        text: 'Bristol Island',
      },
      {
        text: 'Clerke Rocks',
      },
      {
        text: 'Montagu Island',
      },
      {
        text: 'Saunders Island',
      },
      {
        text: 'South Georgia',
      },
      {
        text: 'Southern Thule',
      },
      {
        text: 'Traversay Islands',
      },
    ],
  },
  {
    text: 'South Sudan',
    value: 'SS',
    states: [
      {
        text: 'Central Equatoria',
        value: 'CE',
      },
      {
        text: 'Eastern Equatoria',
        value: 'EE',
      },
      {
        text: 'Jonglei',
        value: 'JG',
      },
      {
        text: 'Lakes',
        value: 'LK',
      },
      {
        text: 'Northern Bahr el Ghazal',
        value: 'BN',
      },
      {
        text: 'Unity',
        value: 'UY',
      },
      {
        text: 'Upper Nile',
        value: 'NU',
      },
      {
        text: 'Warrap',
        value: 'WR',
      },
      {
        text: 'Western Bahr el Ghazal',
        value: 'BW',
      },
      {
        text: 'Western Equatoria',
        value: 'EW',
      },
    ],
  },
  {
    text: 'Spain',
    value: 'ES',
    states: [
      {
        text: 'Albacete',
        value: 'CM',
      },
      {
        text: 'Alicante',
        value: 'VC',
      },
      {
        text: 'Almería',
        value: 'AN',
      },
      {
        text: 'Araba/Álava',
        value: 'VI',
      },
      {
        text: 'Asturias',
        value: 'O',
      },
      {
        text: 'Ávila',
        value: 'AV',
      },
      {
        text: 'Badajoz',
        value: 'BA',
      },
      {
        text: 'Barcelona',
        value: 'B',
      },
      {
        text: 'Bizkaia',
        value: 'BI',
      },
      {
        text: 'Burgos',
        value: 'BU',
      },
      {
        text: 'Cáceres',
        value: 'CC',
      },
      {
        text: 'Cádiz',
        value: 'CA',
      },
      {
        text: 'Cantabria',
        value: 'S',
      },
      {
        text: 'Castellón',
        value: 'CS',
      },
      {
        text: 'Cueta',
        value: 'CU',
      },
      {
        text: 'Ciudad Real',
        value: 'CR',
      },
      {
        text: 'Córdoba',
        value: 'CO',
      },
      {
        text: 'A Coruña',
        value: 'C',
      },
      {
        text: 'Cuenca',
        value: 'CU',
      },
      {
        text: 'Gipuzkoa',
        value: 'SS',
      },
      {
        text: 'Girona',
        value: 'GI',
      },
      {
        text: 'Granada',
        value: 'GR',
      },
      {
        text: 'Guadalajara',
        value: 'GU',
      },
      {
        text: 'Huelva',
        value: 'H',
      },
      {
        text: 'Huesca',
        value: 'HU',
      },
      {
        text: 'Illes Balears',
        value: 'PM',
      },
      {
        text: 'Jaén',
        value: 'J',
      },
      {
        text: 'León',
        value: 'LE',
      },
      {
        text: 'Lleida',
        value: 'L',
      },
      {
        text: 'Lugo',
        value: 'LU',
      },
      {
        text: 'Madrid',
        value: 'M',
      },
      {
        text: 'Málaga',
        value: 'MA',
      },
      {
        text: 'Melilla',
        value: 'ML',
      },
      {
        text: 'Murcia',
        value: 'MU',
      },
      {
        text: 'Navarre',
        value: 'NA',
      },
      {
        text: 'Ourense',
        value: 'OR',
      },
      {
        text: 'Palencia',
        value: 'P',
      },
      {
        text: 'Las Palmas',
        value: 'GC',
      },
      {
        text: 'Pontevedra',
        value: 'PO',
      },
      {
        text: 'La Rioja',
        value: 'LO',
      },
      {
        text: 'Salamanca',
        value: 'SA',
      },
      {
        text: 'Santa Cruz de Tenerife',
        value: 'TF',
      },
      {
        text: 'Segovia',
        value: 'SG',
      },
      {
        text: 'Sevilla',
        value: 'SE',
      },
      {
        text: 'Soria',
        value: 'SO',
      },
      {
        text: 'Tarragona',
        value: 'T',
      },
      {
        text: 'Teruel',
        value: 'TE',
      },
      {
        text: 'Toledo',
        value: 'TO',
      },
      {
        text: 'Valencia',
        value: 'V',
      },
      {
        text: 'Valladolid',
        value: 'VA',
      },
      {
        text: 'Zamora',
        value: 'ZA',
      },
      {
        text: 'Zaragoza',
        value: 'Z',
      },
    ],
  },
  {
    text: 'Sri Lanka',
    value: 'LK',
    states: [
      {
        text: 'Basnahira',
        value: '1',
      },
      {
        text: 'Dakunu',
        value: '3',
      },
      {
        text: 'Madhyama',
        value: '2',
      },
      {
        text: 'Naegenahira',
        value: '5',
      },
      {
        text: 'Sabaragamuwa',
        value: '9',
      },
      {
        text: 'Uturu',
        value: '4',
      },
      {
        text: 'Uturumaeda',
        value: '7',
      },
      {
        text: 'Vayamba',
        value: '6',
      },
      {
        text: 'Uva',
        value: '8',
      },
    ],
  },
  {
    text: 'Sudan',
    value: 'SD',
    states: [
      {
        text: 'Al Bahr al Ahmar',
        value: 'RS',
      },
      {
        text: 'Al Jazirah',
        value: 'GZ',
      },
      {
        text: 'Al Khartum',
        value: 'KH',
      },
      {
        text: 'Al Qadarif',
        value: 'GD',
      },
      {
        text: 'An Nil al Abyad',
        value: 'NW',
      },
      {
        text: 'An Nil al Azraq',
        value: 'NB',
      },
      {
        text: 'Ash Shamaliyah',
        value: 'NO',
      },
      {
        text: 'Gharb Darfur',
        value: 'DW',
      },
      {
        text: 'Gharb Kurdufan',
        value: 'GK',
      },
      {
        text: 'Janub Darfur',
        value: 'DS',
      },
      {
        text: 'Janub Kurdufan',
        value: 'KS',
      },
      {
        text: 'Kassala',
        value: 'KA',
      },
      {
        text: 'Nahr an Nil',
        value: 'NR',
      },
      {
        text: 'Shamal Darfur',
        value: 'DN',
      },
      {
        text: 'Sharq Darfur',
        value: 'DE',
      },
      {
        text: 'Shiamal Kurdufan',
        value: 'KN',
      },
      {
        text: 'Sinnar',
        value: 'SI',
      },
      {
        text: 'Wasat Darfur Zalinjay',
        value: 'DC',
      },
    ],
  },
  {
    text: 'Suriname',
    value: 'SR',
    states: [
      {
        text: 'Brokopondo',
        value: 'BR',
      },
      {
        text: 'Commewijne',
        value: 'CM',
      },
      {
        text: 'Coronie',
        value: 'CR',
      },
      {
        text: 'Marowijne',
        value: 'MA',
      },
      {
        text: 'Nickerie',
        value: 'NI',
      },
      {
        text: 'Para',
        value: 'PR',
      },
      {
        text: 'Paramaribo',
        value: 'PM',
      },
      {
        text: 'Saramacca',
        value: 'SA',
      },
      {
        text: 'Sipaliwini',
        value: 'SI',
      },
      {
        text: 'Wanica',
        value: 'WA',
      },
    ],
  },
  {
    text: 'Swaziland',
    value: 'SZ',
    states: [
      {
        text: 'Hhohho',
        value: 'HH',
      },
      {
        text: 'Lubombo',
        value: 'LU',
      },
      {
        text: 'Manzini',
        value: 'MA',
      },
      {
        text: 'Shiselweni',
        value: 'SH',
      },
    ],
  },
  {
    text: 'Sweden',
    value: 'SE',
    states: [
      {
        text: 'Blekinge',
        value: 'K',
      },
      {
        text: 'Dalarnas',
        value: 'W',
      },
      {
        text: 'Gotlands',
        value: 'X',
      },
      {
        text: 'Gavleborgs',
        value: 'I',
      },
      {
        text: 'Hallands',
        value: 'N',
      },
      {
        text: 'Jamtlands',
        value: 'Z',
      },
      {
        text: 'Jonkopings',
        value: 'F',
      },
      {
        text: 'Kalmar',
        value: 'H',
      },
      {
        text: 'Kronobergs',
        value: 'G',
      },
      {
        text: 'Norrbottens',
        value: 'BD',
      },
      {
        text: 'Orebro',
        value: 'T',
      },
      {
        text: 'Ostergotlands',
        value: 'E',
      },
      {
        text: 'Skane',
        value: 'M',
      },
      {
        text: 'Sodermanlands',
        value: 'D',
      },
      {
        text: 'Stockholm',
        value: 'AB',
      },
      {
        text: 'Varmlands',
        value: 'S',
      },
      {
        text: 'Vasterbottens',
        value: 'AC',
      },
      {
        text: 'Vasternorrlands',
        value: 'Y',
      },
      {
        text: 'Vastmanlands',
        value: 'U',
      },
      {
        text: 'Vastra Gotalands',
        value: 'O',
      },
    ],
  },
  {
    text: 'Switzerland',
    value: 'CH',
    states: [
      {
        text: 'Aargau',
        value: 'AG',
      },
      {
        text: 'Appenzell Ausserrhoden',
        value: 'AR',
      },
      {
        text: 'Appenzell Innerhoden',
        value: 'AI',
      },
      {
        text: 'Basel-Landschaft',
        value: 'BL',
      },
      {
        text: 'Basel-Stadt',
        value: 'BS',
      },
      {
        text: 'Bern',
        value: 'BE',
      },
      {
        text: 'Fribourg',
        value: 'FR',
      },
      {
        text: 'Genève',
        value: 'GE',
      },
      {
        text: 'Glarus',
        value: 'GL',
      },
      {
        text: 'Graubünden',
        value: 'GR',
      },
      {
        text: 'Jura',
        value: 'JU',
      },
      {
        text: 'Luzern',
        value: 'LU',
      },
      {
        text: 'Neuchâtel',
        value: 'NE',
      },
      {
        text: 'Nidwalden',
        value: 'NW',
      },
      {
        text: 'Obwalden',
        value: 'OW',
      },
      {
        text: 'Sankt Gallen',
        value: 'SG',
      },
      {
        text: 'Schaffhausen',
        value: 'SH',
      },
      {
        text: 'Schwyz',
        value: 'SZ',
      },
      {
        text: 'Solothurn',
        value: 'SO',
      },
      {
        text: 'Thurgau',
        value: 'TG',
      },
      {
        text: 'Ticino',
        value: 'TI',
      },
      {
        text: 'Uri',
        value: 'UR',
      },
      {
        text: 'Valais',
        value: 'VS',
      },
      {
        text: 'Vaud',
        value: 'VD',
      },
      {
        text: 'Zug',
        value: 'ZG',
      },
      {
        text: 'Zürich',
        value: 'ZH',
      },
    ],
  },
  {
    text: 'Syrian Arab Republic',
    value: 'SY',
    states: [
      {
        text: 'Al Hasakah',
        value: 'HA',
      },
      {
        text: 'Al Ladhiqiyah',
        value: 'LA',
      },
      {
        text: 'Al Qunaytirah',
        value: 'QU',
      },
      {
        text: 'Ar Raqqah',
        value: 'RA',
      },
      {
        text: "As Suwayda'",
        value: 'SU',
      },
      {
        text: "Dar'a",
        value: 'DR',
      },
      {
        text: 'Dayr az Zawr',
        value: 'DY',
      },
      {
        text: 'Dimashq',
        value: 'DI',
      },
      {
        text: 'Halab',
        value: 'HL',
      },
      {
        text: 'Hamah',
        value: 'HM',
      },
      {
        text: 'Hims',
        value: 'HI',
      },
      {
        text: 'Idlib',
        value: 'ID',
      },
      {
        text: 'Rif Dimashq',
        value: 'RD',
      },
      {
        text: 'Tartus',
        value: 'TA',
      },
    ],
  },
  {
    text: 'Taiwan',
    value: 'TW',
    states: [
      {
        text: 'Chang-hua',
        value: 'CHA',
      },
      {
        text: 'Chia-i',
        value: 'CYQ',
      },
      {
        text: 'Hsin-chu',
        value: 'HSQ',
      },
      {
        text: 'Hua-lien',
        value: 'HUA',
      },
      {
        text: 'Kao-hsiung',
        value: 'KHH',
      },
      {
        text: 'Keelung',
        value: 'KEE',
      },
      {
        text: 'Kinmen',
        value: 'KIN',
      },
      {
        text: 'Lienchiang',
        value: 'LIE',
      },
      {
        text: 'Miao-li',
        value: 'MIA',
      },
      {
        text: "Nan-t'ou",
        value: 'NAN',
      },
      {
        text: "P'eng-hu",
        value: 'PEN',
      },
      {
        text: 'New Taipei',
        value: 'NWT',
      },
      {
        text: "P'ing-chung",
        value: 'PIF',
      },
      {
        text: "T'ai-chung",
        value: 'TXG',
      },
      {
        text: "T'ai-nan",
        value: 'TNN',
      },
      {
        text: "T'ai-pei",
        value: 'TPE',
      },
      {
        text: "T'ai-tung",
        value: 'TTT',
      },
      {
        text: "T'ao-yuan",
        value: 'TAO',
      },
      {
        text: 'Yi-lan',
        value: 'ILA',
      },
      {
        text: 'Yun-lin',
        value: 'YUN',
      },
    ],
  },
  {
    text: 'Tajikistan',
    value: 'TJ',
    states: [
      {
        text: 'Dushanbe',
        value: 'DU',
      },
      {
        text: 'Kŭhistoni Badakhshon',
        value: 'GB',
      },
      {
        text: 'Khatlon',
        value: 'KT',
      },
      {
        text: 'Sughd',
        value: 'SU',
      },
    ],
  },
  {
    text: 'Tanzania, United Republic of',
    value: 'TZ',
    states: [
      {
        text: 'Arusha',
        value: '01',
      },
      {
        text: 'Coast',
        value: '19',
      },
      {
        text: 'Dar es Salaam',
        value: '02',
      },
      {
        text: 'Dodoma',
        value: '03',
      },
      {
        text: 'Iringa',
        value: '04',
      },
      {
        text: 'Kagera',
        value: '05',
      },
      {
        text: 'Kigoma',
        value: '08',
      },
      {
        text: 'Kilimanjaro',
        value: '09',
      },
      {
        text: 'Lindi',
        value: '12',
      },
      {
        text: 'Manyara',
        value: '26',
      },
      {
        text: 'Mara',
        value: '13',
      },
      {
        text: 'Mbeya',
        value: '14',
      },
      {
        text: 'Morogoro',
        value: '16',
      },
      {
        text: 'Mtwara',
        value: '17',
      },
      {
        text: 'Mwanza',
        value: '18',
      },
      {
        text: 'Pemba North',
        value: '06',
      },
      {
        text: 'Pemba South',
        value: '10',
      },
      {
        text: 'Rukwa',
        value: '20',
      },
      {
        text: 'Ruvuma',
        value: '21',
      },
      {
        text: 'Shinyanga',
        value: '22',
      },
      {
        text: 'Singida',
        value: '23',
      },
      {
        text: 'Tabora',
        value: '24',
      },
      {
        text: 'Tanga',
        value: '25',
      },
      {
        text: 'Zanzibar North',
        value: '07',
      },
      {
        text: 'Zanzibar Central/South',
        value: '11',
      },
      {
        text: 'Zanzibar Urban/West',
        value: '15',
      },
    ],
  },
  {
    text: 'Thailand',
    value: 'TH',
    states: [
      {
        text: 'Amnat Charoen',
        value: '37',
      },
      {
        text: 'Ang Thong',
        value: '15',
      },
      {
        text: 'Bueng Kan',
        value: '38',
      },
      {
        text: 'Buri Ram',
        value: '31',
      },
      {
        text: 'Chachoengsao',
        value: '24',
      },
      {
        text: 'Chai Nat',
        value: '18',
      },
      {
        text: 'Chaiyaphum',
        value: '36',
      },
      {
        text: 'Chanthaburi',
        value: '22',
      },
      {
        text: 'Chiang Mai',
        value: '50',
      },
      {
        text: 'Chiang Rai',
        value: '57',
      },
      {
        text: 'Chon Buri',
        value: '20',
      },
      {
        text: 'Chumphon',
        value: '86',
      },
      {
        text: 'Kalasin',
        value: '46',
      },
      {
        text: 'Kamphaeng Phet',
        value: '62',
      },
      {
        text: 'Kanchanaburi',
        value: '71',
      },
      {
        text: 'Khon Kaen',
        value: '40',
      },
      {
        text: 'Krabi',
        value: '81',
      },
      {
        text: 'Krung Thep Mahanakhon (Bangkok)',
        value: '10',
      },
      {
        text: 'Lampang',
        value: '52',
      },
      {
        text: 'Lamphun',
        value: '51',
      },
      {
        text: 'Loei',
        value: '42',
      },
      {
        text: 'Lop Buri',
        value: '16',
      },
      {
        text: 'Mae Hong Son',
        value: '58',
      },
      {
        text: 'Maha Sarakham',
        value: '44',
      },
      {
        text: 'Mukdahan',
        value: '49',
      },
      {
        text: 'Nakhon Nayok',
        value: '26',
      },
      {
        text: 'Nakhon Phathom',
        value: '73',
      },
      {
        text: 'Nakhon Phanom',
        value: '48',
      },
      {
        text: 'Nakhon Ratchasima',
        value: '30',
      },
      {
        text: 'Nakhon Sawan',
        value: '60',
      },
      {
        text: 'Nakhon Si Thammarat',
        value: '80',
      },
      {
        text: 'Nan',
        value: '55',
      },
      {
        text: 'Narathiwat',
        value: '96',
      },
      {
        text: 'Nong Bua Lam Phu',
        value: '39',
      },
      {
        text: 'Nong Khai',
        value: '43',
      },
      {
        text: 'Nonthaburi',
        value: '12',
      },
      {
        text: 'Pathum Thani',
        value: '13',
      },
      {
        text: 'Pattani',
        value: '94',
      },
      {
        text: 'Phangnga',
        value: '82',
      },
      {
        text: 'Phatthalung',
        value: '93',
      },
      {
        text: 'Phayao',
        value: '56',
      },
      {
        text: 'Phetchabun',
        value: '76',
      },
      {
        text: 'Phetchaburi',
        value: '76',
      },
      {
        text: 'Phichit',
        value: '66',
      },
      {
        text: 'Phitsanulok',
        value: '65',
      },
      {
        text: 'Phra Nakhon Si Ayutthaya',
        value: '14',
      },
      {
        text: 'Phrae',
        value: '54',
      },
      {
        text: 'Phuket',
        value: '83',
      },
      {
        text: 'Prachin Buri',
        value: '25',
      },
      {
        text: 'Prachuap Khiri Khan',
        value: '77',
      },
      {
        text: 'Ranong',
        value: '85',
      },
      {
        text: 'Ratchaburi',
        value: '70',
      },
      {
        text: 'Rayong',
        value: '21',
      },
      {
        text: 'Roi Et',
        value: '45',
      },
      {
        text: 'Sa Kaeo',
        value: '27',
      },
      {
        text: 'Sakon Nakhon',
        value: '47',
      },
      {
        text: 'Samut Prakan',
        value: '11',
      },
      {
        text: 'Samut Sakhon',
        value: '74',
      },
      {
        text: 'Samut Songkhram',
        value: '75',
      },
      {
        text: 'Saraburi',
        value: '19',
      },
      {
        text: 'Satun',
        value: '91',
      },
      {
        text: 'Sing Buri',
        value: '17',
      },
      {
        text: 'Si Sa ket',
        value: '33',
      },
      {
        text: 'Songkhla',
        value: '90',
      },
      {
        text: 'Sukhothai',
        value: '64',
      },
      {
        text: 'Suphan Buri',
        value: '72',
      },
      {
        text: 'Surat Thani',
        value: '84',
      },
      {
        text: 'Surin',
        value: '32',
      },
      {
        text: 'Tak',
        value: '63',
      },
      {
        text: 'Trang',
        value: '92',
      },
      {
        text: 'Trat',
        value: '23',
      },
      {
        text: 'Ubon Ratchathani',
        value: '34',
      },
      {
        text: 'Udon Thani',
        value: '41',
      },
      {
        text: 'Uthai Thani',
        value: '61',
      },
      {
        text: 'Uttaradit',
        value: '53',
      },
      {
        text: 'Yala',
        value: '95',
      },
      {
        text: 'Yasothon',
        value: '35',
      },
    ],
  },
  {
    text: 'Timor-Leste',
    value: 'TL',
    states: [
      {
        text: 'Aileu',
        value: 'AL',
      },
      {
        text: 'Ainaro',
        value: 'AN',
      },
      {
        text: 'Baucau',
        value: 'BA',
      },
      {
        text: 'Bobonaro',
        value: 'BO',
      },
      {
        text: 'Cova Lima',
        value: 'CO',
      },
      {
        text: 'Dili',
        value: 'DI',
      },
      {
        text: 'Ermera',
        value: 'ER',
      },
      {
        text: 'Lautem',
        value: 'LA',
      },
      {
        text: 'Liquica',
        value: 'LI',
      },
      {
        text: 'Manatuto',
        value: 'MT',
      },
      {
        text: 'Manufahi',
        value: 'MF',
      },
      {
        text: 'Oecussi',
        value: 'OE',
      },
      {
        text: 'Viqueque',
        value: 'VI',
      },
    ],
  },
  {
    text: 'Togo',
    value: 'TG',
    states: [
      {
        text: 'Centre',
        value: 'C',
      },
      {
        text: 'Kara',
        value: 'K',
      },
      {
        text: 'Maritime',
        value: 'M',
      },
      {
        text: 'Plateaux',
        value: 'P',
      },
      {
        text: 'Savannes',
        value: 'S',
      },
    ],
  },
  {
    text: 'Tokelau',
    value: 'TK',
    states: [
      {
        text: 'Atafu',
      },
      {
        text: 'Fakaofo',
      },
      {
        text: 'Nukunonu',
      },
    ],
  },
  {
    text: 'Tonga',
    value: 'TO',
    states: [
      {
        text: "'Eua",
        value: '01',
      },
      {
        text: "Ha'apai",
        value: '02',
      },
      {
        text: 'Niuas',
        value: '03',
      },
      {
        text: 'Tongatapu',
        value: '04',
      },
      {
        text: "Vava'u",
        value: '05',
      },
    ],
  },
  {
    text: 'Trinidad and Tobago',
    value: 'TT',
    states: [
      {
        text: 'Arima',
        value: 'ARI',
      },
      {
        text: 'Chaguanas',
        value: 'CHA',
      },
      {
        text: 'Couva-Tabaquite-Talparo',
        value: 'CTT',
      },
      {
        text: 'Diefo Martin',
        value: 'DMN',
      },
      {
        text: 'Mayaro-Rio Claro',
        value: 'MRC',
      },
      {
        text: 'Penal-Debe',
        value: 'PED',
      },
      {
        text: 'Point Fortin',
        value: 'PTF',
      },
      {
        text: 'Port-of-Spain',
        value: 'POS',
      },
      {
        text: 'Princes Town',
        value: 'PRT',
      },
      {
        text: 'San Fernando',
        value: 'SFO',
      },
      {
        text: 'San Juan-Laventille',
        value: 'SJL',
      },
      {
        text: 'Sangre Grande',
        value: 'SGE',
      },
      {
        text: 'Siparia',
        value: 'SIP',
      },
      {
        text: 'Tobago',
        value: 'TOB',
      },
      {
        text: 'Tunapuna-Piarco',
        value: 'TUP',
      },
    ],
  },
  {
    text: 'Tunisia',
    value: 'TN',
    states: [
      {
        text: 'Ariana',
        value: '12',
      },
      {
        text: 'Beja',
        value: '31',
      },
      {
        text: 'Ben Arous',
        value: '13',
      },
      {
        text: 'Bizerte',
        value: '23',
      },
      {
        text: 'Gabes',
        value: '81',
      },
      {
        text: 'Gafsa',
        value: '71',
      },
      {
        text: 'Jendouba',
        value: '32',
      },
      {
        text: 'Kairouan',
        value: '41',
      },
      {
        text: 'Kasserine',
        value: '42',
      },
      {
        text: 'Kebili',
        value: '73',
      },
      {
        text: 'Kef',
        value: '33',
      },
      {
        text: 'Mahdia',
        value: '53',
      },
      {
        text: 'Medenine',
        value: '82',
      },
      {
        text: 'Monastir',
        value: '52',
      },
      {
        text: 'Nabeul',
        value: '21',
      },
      {
        text: 'Sfax',
        value: '61',
      },
      {
        text: 'Sidi Bouzid',
        value: '43',
      },
      {
        text: 'Siliana',
        value: '34',
      },
      {
        text: 'Sousse',
        value: '51',
      },
      {
        text: 'Tataouine',
        value: '83',
      },
      {
        text: 'Tozeur',
        value: '72',
      },
      {
        text: 'Tunis',
        value: '11',
      },
      {
        text: 'Zaghouan',
        value: '22',
      },
    ],
  },
  {
    text: 'Turkey',
    value: 'TR',
    states: [
      {
        text: 'Adana',
        value: '01',
      },
      {
        text: 'Adiyaman',
        value: '02',
      },
      {
        text: 'Afyonkarahisar',
        value: '03',
      },
      {
        text: 'Agri',
        value: '04',
      },
      {
        text: 'Aksaray',
        value: '68',
      },
      {
        text: 'Amasya',
        value: '05',
      },
      {
        text: 'Ankara',
        value: '06',
      },
      {
        text: 'Antalya',
        value: '07',
      },
      {
        text: 'Ardahan',
        value: '75',
      },
      {
        text: 'Artvin',
        value: '08',
      },
      {
        text: 'Aydin',
        value: '09',
      },
      {
        text: 'Balikesir',
        value: '10',
      },
      {
        text: 'Bartin',
        value: '74',
      },
      {
        text: 'Batman',
        value: '72',
      },
      {
        text: 'Bayburt',
        value: '69',
      },
      {
        text: 'Bilecik',
        value: '11',
      },
      {
        text: 'Bingol',
        value: '12',
      },
      {
        text: 'Bitlis',
        value: '13',
      },
      {
        text: 'Bolu',
        value: '14',
      },
      {
        text: 'Burdur',
        value: '15',
      },
      {
        text: 'Bursa',
        value: '16',
      },
      {
        text: 'Canakkale',
        value: '17',
      },
      {
        text: 'Cankiri',
        value: '18',
      },
      {
        text: 'Corum',
        value: '19',
      },
      {
        text: 'Denizli',
        value: '20',
      },
      {
        text: 'Diyarbakir',
        value: '21',
      },
      {
        text: 'Duzce',
        value: '81',
      },
      {
        text: 'Edirne',
        value: '22',
      },
      {
        text: 'Elazig',
        value: '23',
      },
      {
        text: 'Erzincan',
        value: '24',
      },
      {
        text: 'Erzurum',
        value: '25',
      },
      {
        text: 'Eskisehir',
        value: '26',
      },
      {
        text: 'Gaziantep',
        value: '27',
      },
      {
        text: 'Giresun',
        value: '28',
      },
      {
        text: 'Gumushane',
        value: '29',
      },
      {
        text: 'Hakkari',
        value: '30',
      },
      {
        text: 'Hatay',
        value: '31',
      },
      {
        text: 'Igdir',
        value: '76',
      },
      {
        text: 'Isparta',
        value: '32',
      },
      {
        text: 'Istanbul',
        value: '34',
      },
      {
        text: 'Izmir',
        value: '35',
      },
      {
        text: 'Kahramanmaras',
        value: '46',
      },
      {
        text: 'Karabuk',
        value: '78',
      },
      {
        text: 'Karaman',
        value: '70',
      },
      {
        text: 'Kars',
        value: '36',
      },
      {
        text: 'Kastamonu',
        value: '37',
      },
      {
        text: 'Kayseri',
        value: '38',
      },
      {
        text: 'Kilis',
        value: '79',
      },
      {
        text: 'Kirikkale',
        value: '71',
      },
      {
        text: 'Kirklareli',
        value: '39',
      },
      {
        text: 'Kirsehir',
        value: '40',
      },
      {
        text: 'Kocaeli',
        value: '41',
      },
      {
        text: 'Konya',
        value: '42',
      },
      {
        text: 'Kutahya',
        value: '43',
      },
      {
        text: 'Malatya',
        value: '44',
      },
      {
        text: 'Manisa',
        value: '45',
      },
      {
        text: 'Mardin',
        value: '47',
      },
      {
        text: 'Mersin',
        value: '33',
      },
      {
        text: 'Mugla',
        value: '48',
      },
      {
        text: 'Mus',
        value: '49',
      },
      {
        text: 'Nevsehir',
        value: '50',
      },
      {
        text: 'Nigde',
        value: '51',
      },
      {
        text: 'Ordu',
        value: '52',
      },
      {
        text: 'Osmaniye',
        value: '80',
      },
      {
        text: 'Rize',
        value: '53',
      },
      {
        text: 'Sakarya',
        value: '54',
      },
      {
        text: 'Samsun',
        value: '55',
      },
      {
        text: 'Sanliurfa',
        value: '63',
      },
      {
        text: 'Siirt',
        value: '56',
      },
      {
        text: 'Sinop',
        value: '57',
      },
      {
        text: 'Sirnak',
        value: '73',
      },
      {
        text: 'Sivas',
        value: '58',
      },
      {
        text: 'Tekirdag',
        value: '59',
      },
      {
        text: 'Tokat',
        value: '60',
      },
      {
        text: 'Trabzon',
        value: '61',
      },
      {
        text: 'Tunceli',
        value: '62',
      },
      {
        text: 'Usak',
        value: '64',
      },
      {
        text: 'Van',
        value: '65',
      },
      {
        text: 'Yalova',
        value: '77',
      },
      {
        text: 'Yozgat',
        value: '66',
      },
      {
        text: 'Zonguldak',
        value: '67',
      },
    ],
  },
  {
    text: 'Turkmenistan',
    value: 'TM',
    states: [
      {
        text: 'Ahal',
        value: 'A',
      },
      {
        text: 'Asgabat',
        value: 'S',
      },
      {
        text: 'Balkan',
        value: 'B',
      },
      {
        text: 'Dashoguz',
        value: 'D',
      },
      {
        text: 'Lebap',
        value: 'L',
      },
      {
        text: 'Mary',
        value: 'M',
      },
    ],
  },
  {
    text: 'Turks and Caicos Islands',
    value: 'TC',
    states: [
      {
        text: 'Turks and Caicos Islands',
      },
    ],
  },
  {
    text: 'Tuvalu',
    value: 'TV',
    states: [
      {
        text: 'Funafuti',
        value: 'FUN',
      },
      {
        text: 'Nanumanga',
        value: 'NMG',
      },
      {
        text: 'Nanumea',
        value: 'NMA',
      },
      {
        text: 'Niutao',
        value: 'NIT',
      },
      {
        text: 'Nui',
        value: 'NUI',
      },
      {
        text: 'Nukufetau',
        value: 'NKF',
      },
      {
        text: 'Nukulaelae',
        value: 'NKL',
      },
      {
        text: 'Vaitupu',
        value: 'VAU',
      },
    ],
  },
  {
    text: 'Uganda',
    value: 'UG',
    states: [
      {
        text: 'Abim',
        value: '317',
      },
      {
        text: 'Adjumani',
        value: '301',
      },
      {
        text: 'Amolatar',
        value: '314',
      },
      {
        text: 'Amuria',
        value: '216',
      },
      {
        text: 'Amuru',
        value: '319',
      },
      {
        text: 'Apac',
        value: '302',
      },
      {
        text: 'Arua',
        value: '303',
      },
      {
        text: 'Budaka',
        value: '217',
      },
      {
        text: 'Bududa',
        value: '223',
      },
      {
        text: 'Bugiri',
        value: '201',
      },
      {
        text: 'Bukedea',
        value: '224',
      },
      {
        text: 'Bukwa',
        value: '218',
      },
      {
        text: 'Buliisa',
        value: '419',
      },
      {
        text: 'Bundibugyo',
        value: '401',
      },
      {
        text: 'Bushenyi',
        value: '402',
      },
      {
        text: 'Busia',
        value: '202',
      },
      {
        text: 'Butaleja',
        value: '219',
      },
      {
        text: 'Dokolo',
        value: '318',
      },
      {
        text: 'Gulu',
        value: '304',
      },
      {
        text: 'Hoima',
        value: '403',
      },
      {
        text: 'Ibanda',
        value: '416',
      },
      {
        text: 'Iganga',
        value: '203',
      },
      {
        text: 'Isingiro',
        value: '417',
      },
      {
        text: 'Jinja',
        value: '204',
      },
      {
        text: 'Kaabong',
        value: '315',
      },
      {
        text: 'Kabale',
        value: '404',
      },
      {
        text: 'Kabarole',
        value: '405',
      },
      {
        text: 'Kaberamaido',
        value: '213',
      },
      {
        text: 'Kalangala',
        value: '101',
      },
      {
        text: 'Kaliro',
        value: '220',
      },
      {
        text: 'Kampala',
        value: '102',
      },
      {
        text: 'Kamuli',
        value: '205',
      },
      {
        text: 'Kamwenge',
        value: '413',
      },
      {
        text: 'Kanungu',
        value: '414',
      },
      {
        text: 'Kapchorwa',
        value: '206',
      },
      {
        text: 'Kasese',
        value: '406',
      },
      {
        text: 'Katakwi',
        value: '207',
      },
      {
        text: 'Kayunga',
        value: '112',
      },
      {
        text: 'Kibaale',
        value: '407',
      },
      {
        text: 'Kiboga',
        value: '103',
      },
      {
        text: 'Kiruhura',
        value: '418',
      },
      {
        text: 'Kisoro',
        value: '408',
      },
      {
        text: 'Kitgum',
        value: '305',
      },
      {
        text: 'Koboko',
        value: '316',
      },
      {
        text: 'Kotido',
        value: '306',
      },
      {
        text: 'Kumi',
        value: '208',
      },
      {
        text: 'Kyenjojo',
        value: '415',
      },
      {
        text: 'Lira',
        value: '307',
      },
      {
        text: 'Luwero',
        value: '104',
      },
      {
        text: 'Lyantonde',
        value: '116',
      },
      {
        text: 'Manafwa',
        value: '221',
      },
      {
        text: 'Maracha',
        value: '320',
      },
      {
        text: 'Masaka',
        value: '105',
      },
      {
        text: 'Masindi',
        value: '409',
      },
      {
        text: 'Mayuge',
        value: '214',
      },
      {
        text: 'Mbale',
        value: '209',
      },
      {
        text: 'Mbarara',
        value: '410',
      },
      {
        text: 'Mityana',
        value: '114',
      },
      {
        text: 'Moroto',
        value: '308',
      },
      {
        text: 'Moyo',
        value: '309',
      },
      {
        text: 'Mpigi',
        value: '106',
      },
      {
        text: 'Mubende',
        value: '107',
      },
      {
        text: 'Mukono',
        value: '108',
      },
      {
        text: 'Nakapiripirit',
        value: '311',
      },
      {
        text: 'Nakaseke',
        value: '115',
      },
      {
        text: 'Nakasongola',
        value: '109',
      },
      {
        text: 'Namutumba',
        value: '222',
      },
      {
        text: 'Nebbi',
        value: '310',
      },
      {
        text: 'Ntungamo',
        value: '411',
      },
      {
        text: 'Oyam',
        value: '321',
      },
      {
        text: 'Pader',
        value: '312',
      },
      {
        text: 'Pallisa',
        value: '210',
      },
      {
        text: 'Rakai',
        value: '110',
      },
      {
        text: 'Rukungiri',
        value: '412',
      },
      {
        text: 'Sembabule',
        value: '111',
      },
      {
        text: 'Sironko',
        value: '215',
      },
      {
        text: 'Soroti',
        value: '211',
      },
      {
        text: 'Tororo',
        value: '212',
      },
      {
        text: 'Wakiso',
        value: '113',
      },
      {
        text: 'Yumbe',
        value: '313',
      },
    ],
  },
  {
    text: 'Ukraine',
    value: 'UA',
    states: [
      {
        text: 'Cherkasy',
        value: '71',
      },
      {
        text: 'Chernihiv',
        value: '74',
      },
      {
        text: 'Chernivtsi',
        value: '77',
      },
      {
        text: 'Dnipropetrovsk',
        value: '12',
      },
      {
        text: 'Donetsk',
        value: '14',
      },
      {
        text: 'Ivano-Frankivsk',
        value: '26',
      },
      {
        text: 'Kharkiv',
        value: '63',
      },
      {
        text: 'Kherson',
        value: '65',
      },
      {
        text: 'Khmelnytskyi',
        value: '68',
      },
      {
        text: 'Kiev',
        value: '32',
      },
      {
        text: 'Kirovohrad',
        value: '35',
      },
      {
        text: 'Luhansk',
        value: '09',
      },
      {
        text: 'Lviv',
        value: '46',
      },
      {
        text: 'Mykolaiv',
        value: '48',
      },
      {
        text: 'Odessa',
        value: '51',
      },
      {
        text: 'Poltava',
        value: '53',
      },
      {
        text: 'Rivne',
        value: '56',
      },
      {
        text: 'Sumy',
        value: '59',
      },
      {
        text: 'Ternopil',
        value: '61',
      },
      {
        text: 'Vinnytsia',
        value: '05',
      },
      {
        text: 'Volyn',
        value: '07',
      },
      {
        text: 'Zakarpattia',
        value: '21',
      },
      {
        text: 'Zaporizhia',
        value: '23',
      },
      {
        text: 'Zhytomyr',
        value: '18',
      },
      {
        text: 'Avtonomna Respublika Krym',
        value: '43',
      },
      {
        text: 'Kyïv',
        value: '30',
      },
      {
        text: 'Sevastopol',
        value: '40',
      },
    ],
  },
  {
    text: 'United Arab Emirates',
    value: 'AE',
    states: [
      {
        text: 'Abu Dhabi',
        value: 'AZ',
      },
      {
        text: 'Ajman',
        value: 'AJ',
      },
      {
        text: 'Dubai',
        value: 'DU',
      },
      {
        text: 'Fujairah',
        value: 'FU',
      },
      {
        text: 'Ras al Khaimah',
        value: 'RK',
      },
      {
        text: 'Sharjah',
        value: 'SH',
      },
      {
        text: 'Umm Al Quwain',
        value: 'UQ',
      },
    ],
  },
  {
    text: 'United Kingdom',
    value: 'GB',
    states: [
      {
        text: 'Avon',
        value: 'AVN',
      },
      {
        text: 'Bedfordshire',
        value: 'BDF',
      },
      {
        text: 'Berkshire',
        value: 'BRK',
      },
      {
        text: 'Bristol, City of',
        value: 'COB',
      },
      {
        text: 'Buckinghamshire',
        value: 'BKM',
      },
      {
        text: 'Cambridgeshire',
        value: 'CAM',
      },
      {
        text: 'Cheshire',
        value: 'CHS',
      },
      {
        text: 'Cleveland',
        value: 'CLV',
      },
      {
        text: 'Cornwall',
        value: 'CON',
      },
      {
        text: 'Cumbria',
        value: 'CMA',
      },
      {
        text: 'Derbyshire',
        value: 'DBY',
      },
      {
        text: 'Devon',
        value: 'DEV',
      },
      {
        text: 'Dorset',
        value: 'DOR',
      },
      {
        text: 'Durham',
        value: 'DUR',
      },
      {
        text: 'East Sussex',
        value: 'SXE',
      },
      {
        text: 'Essex',
        value: 'ESS',
      },
      {
        text: 'Gloucestershire',
        value: 'GLS',
      },
      {
        text: 'Greater London',
        value: 'LND',
      },
      {
        text: 'Greater Manchester',
        value: 'GTM',
      },
      {
        text: 'Hampshire',
        value: 'HAM',
      },
      {
        text: 'Hereford and Worcester',
        value: 'HWR',
      },
      {
        text: 'Herefordshire',
        value: 'HEF',
      },
      {
        text: 'Hertfordshire',
        value: 'HRT',
      },
      {
        text: 'Isle of Wight',
        value: 'IOW',
      },
      {
        text: 'Kent',
        value: 'KEN',
      },
      {
        text: 'Lancashire',
        value: 'LAN',
      },
      {
        text: 'Leicestershire',
        value: 'LEI',
      },
      {
        text: 'Lincolnshire',
        value: 'LIN',
      },
      {
        text: 'London',
        value: 'LDN',
      },
      {
        text: 'Merseyside',
        value: 'MSY',
      },
      {
        text: 'Middlesex',
        value: 'MDX',
      },
      {
        text: 'Norfolk',
        value: 'NFK',
      },
      {
        text: 'Northamptonshire',
        value: 'NTH',
      },
      {
        text: 'Northumberland',
        value: 'NBL',
      },
      {
        text: 'North Humberside',
        value: 'NHM',
      },
      {
        text: 'North Yorkshire',
        value: 'NYK',
      },
      {
        text: 'Nottinghamshire',
        value: 'NTT',
      },
      {
        text: 'Oxfordshire',
        value: 'OXF',
      },
      {
        text: 'Rutland',
        value: 'RUT',
      },
      {
        text: 'Shropshire',
        value: 'SAL',
      },
      {
        text: 'Somerset',
        value: 'SOM',
      },
      {
        text: 'South Humberside',
        value: 'SHM',
      },
      {
        text: 'South Yorkshire',
        value: 'SYK',
      },
      {
        text: 'Staffordshire',
        value: 'STS',
      },
      {
        text: 'Suffolk',
        value: 'SFK',
      },
      {
        text: 'Surrey',
        value: 'SRY',
      },
      {
        text: 'Tyne and Wear',
        value: 'TWR',
      },
      {
        text: 'Warwickshire',
        value: 'WAR',
      },
      {
        text: 'West Midlands',
        value: 'WMD',
      },
      {
        text: 'West Sussex',
        value: 'SXW',
      },
      {
        text: 'West Yorkshire',
        value: 'WYK',
      },
      {
        text: 'Wiltshire',
        value: 'WIL',
      },
      {
        text: 'Worcestershire',
        value: 'WOR',
      },
      {
        text: 'Antrim',
        value: 'ANT',
      },
      {
        text: 'Armagh',
        value: 'ARM',
      },
      {
        text: 'Belfast, City of',
        value: 'BLF',
      },
      {
        text: 'Down',
        value: 'DOW',
      },
      {
        text: 'Fermanagh',
        value: 'FER',
      },
      {
        text: 'Londonderry',
        value: 'LDY',
      },
      {
        text: 'Derry, City of',
        value: 'DRY',
      },
      {
        text: 'Tyrone',
        value: 'TYR',
      },
      {
        text: 'Aberdeen, City of',
        value: 'AN',
      },
      {
        text: 'Aberdeenshire',
        value: 'ABD',
      },
      {
        text: 'Angus (Forfarshire)',
        value: 'ANS',
      },
      {
        text: 'Argyll',
        value: 'AGB',
      },
      {
        text: 'Ayrshire',
        value: 'ARG',
      },
      {
        text: 'Banffshire',
        value: 'BAN',
      },
      {
        text: 'Berwickshire',
        value: 'BEW',
      },
      {
        text: 'Bute',
        value: 'BUT',
      },
      {
        text: 'Caithness',
        value: 'CAI',
      },
      {
        text: 'Clackmannanshire',
        value: 'CLK',
      },
      {
        text: 'Cromartyshire',
        value: 'COC',
      },
      {
        text: 'Dumfriesshire',
        value: 'DFS',
      },
      {
        text: 'Dunbartonshire (Dumbarton)',
        value: 'DNB',
      },
      {
        text: 'Dundee, City of',
        value: 'DD',
      },
      {
        text: 'East Lothian (Haddingtonshire)',
        value: 'ELN',
      },
      {
        text: 'Edinburgh, City of',
        value: 'EB',
      },
      {
        text: 'Fife',
        value: 'FIF',
      },
      {
        text: 'Glasgow, City of',
        value: 'GLA',
      },
      {
        text: 'Inverness-shire',
        value: 'INV',
      },
      {
        text: 'Kincardineshire',
        value: 'KCD',
      },
      {
        text: 'Kinross-shire',
        value: 'KRS',
      },
      {
        text: 'Kirkcudbrightshire',
        value: 'KKD',
      },
      {
        text: 'Lanarkshire',
        value: 'LKS',
      },
      {
        text: 'Midlothian (County of Edinburgh)',
        value: 'MLN',
      },
      {
        text: 'Moray (Elginshire)',
        value: 'MOR',
      },
      {
        text: 'Nairnshire',
        value: 'NAI',
      },
      {
        text: 'Orkney',
        value: 'OKI',
      },
      {
        text: 'Peeblesshire',
        value: 'PEE',
      },
      {
        text: 'Perthshire',
        value: 'PER',
      },
      {
        text: 'Renfrewshire',
        value: 'RFW',
      },
      {
        text: 'Ross and Cromarty',
        value: 'ROC',
      },
      {
        text: 'Ross-shire',
        value: 'ROS',
      },
      {
        text: 'Roxburghshire',
        value: 'ROX',
      },
      {
        text: 'Selkirkshire',
        value: 'SEL',
      },
      {
        text: 'Shetland (Zetland)',
        value: 'SHI',
      },
      {
        text: 'Stirlingshire',
        value: 'STI',
      },
      {
        text: 'Sutherland',
        value: 'SUT',
      },
      {
        text: 'West Lothian (Linlithgowshire)',
        value: 'WLN',
      },
      {
        text: 'Wigtownshire',
        value: 'WIG',
      },
      {
        text: 'Clwyd',
        value: 'CWD',
      },
      {
        text: 'Dyfed',
        value: 'DFD',
      },
      {
        text: 'Gwent',
        value: 'GNT',
      },
      {
        text: 'Gwynedd',
        value: 'GWN',
      },
      {
        text: 'Mid Glamorgan',
        value: 'MGM',
      },
      {
        text: 'Powys',
        value: 'POW',
      },
      {
        text: 'South Glamorgan',
        value: 'SGM',
      },
      {
        text: 'West Glamorgan',
        value: 'WGM',
      },
    ],
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'UM',
    states: [
      {
        text: 'Baker Island',
        value: '81',
      },
      {
        text: 'Howland Island',
        value: '84',
      },
      {
        text: 'Jarvis Island',
        value: '86',
      },
      {
        text: 'Johnston Atoll',
        value: '67',
      },
      {
        text: 'Kingman Reef',
        value: '89',
      },
      {
        text: 'Midway Islands',
        value: '71',
      },
      {
        text: 'Navassa Island',
        value: '76',
      },
      {
        text: 'Palmyra Atoll',
        value: '95',
      },
      {
        text: 'Wake Island',
        value: '79',
      },
      {
        text: 'Bajo Nuevo Bank',
        value: 'BN',
      },
      {
        text: 'Serranilla Bank',
        value: 'SB',
      },
    ],
  },
  {
    text: 'Uruguay',
    value: 'UY',
    states: [
      {
        text: 'Artigas',
        value: 'AR',
      },
      {
        text: 'Canelones',
        value: 'CA',
      },
      {
        text: 'Cerro Largo',
        value: 'CL',
      },
      {
        text: 'Colonia',
        value: 'CO',
      },
      {
        text: 'Durazno',
        value: 'DU',
      },
      {
        text: 'Flores',
        value: 'FS',
      },
      {
        text: 'Florida',
        value: 'FD',
      },
      {
        text: 'Lavalleja',
        value: 'LA',
      },
      {
        text: 'Maldonado',
        value: 'MA',
      },
      {
        text: 'Montevideo',
        value: 'MO',
      },
      {
        text: 'Paysandú',
        value: 'PA',
      },
      {
        text: 'Río Negro',
        value: 'RN',
      },
      {
        text: 'Rivera',
        value: 'RV',
      },
      {
        text: 'Rocha',
        value: 'RO',
      },
      {
        text: 'Salto',
        value: 'SA',
      },
      {
        text: 'San José',
        value: 'SJ',
      },
      {
        text: 'Soriano',
        value: 'SO',
      },
      {
        text: 'Tacuarembó',
        value: 'TA',
      },
      {
        text: 'Treinta y Tres',
        value: 'TT',
      },
    ],
  },
  {
    text: 'Uzbekistan',
    value: 'UZ',
    states: [
      {
        text: 'Toshkent shahri',
        value: 'TK',
      },
      {
        text: 'Andijon',
        value: 'AN',
      },
      {
        text: 'Buxoro',
        value: 'BU',
      },
      {
        text: 'Farg‘ona',
        value: 'FA',
      },
      {
        text: 'Jizzax',
        value: 'JI',
      },
      {
        text: 'Namangan',
        value: 'NG',
      },
      {
        text: 'Navoiy',
        value: 'NW',
      },
      {
        text: 'Qashqadaryo (Qarshi)',
        value: 'QA',
      },
      {
        text: 'Samarqand',
        value: 'SA',
      },
      {
        text: 'Sirdaryo (Guliston)',
        value: 'SI',
      },
      {
        text: 'Surxondaryo (Termiz)',
        value: 'SU',
      },
      {
        text: 'Toshkent wiloyati',
        value: 'TO',
      },
      {
        text: 'Xorazm (Urganch)',
        value: 'XO',
      },
      {
        text: 'Qoraqalpog‘iston Respublikasi (Nukus)',
        value: 'QR',
      },
    ],
  },
  {
    text: 'Vanuatu',
    value: 'VU',
    states: [
      {
        text: 'Malampa',
        value: 'MAP',
      },
      {
        text: 'Pénama',
        value: 'PAM',
      },
      {
        text: 'Sanma',
        value: 'SAM',
      },
      {
        text: 'Shéfa',
        value: 'SEE',
      },
      {
        text: 'Taféa',
        value: 'TAE',
      },
      {
        text: 'Torba',
        value: 'TOB',
      },
    ],
  },
  {
    text: 'Venezuela, Bolivarian Republic of',
    value: 'VE',
    states: [
      {
        text: 'Dependencias Federales',
        value: 'W',
      },
      {
        text: 'Distrito Federal',
        value: 'A',
      },
      {
        text: 'Amazonas',
        value: 'Z',
      },
      {
        text: 'Anzoátegui',
        value: 'B',
      },
      {
        text: 'Apure',
        value: 'C',
      },
      {
        text: 'Aragua',
        value: 'D',
      },
      {
        text: 'Barinas',
        value: 'E',
      },
      {
        text: 'Bolívar',
        value: 'F',
      },
      {
        text: 'Carabobo',
        value: 'G',
      },
      {
        text: 'Cojedes',
        value: 'H',
      },
      {
        text: 'Delta Amacuro',
        value: 'Y',
      },
      {
        text: 'Falcón',
        value: 'I',
      },
      {
        text: 'Guárico',
        value: 'J',
      },
      {
        text: 'Lara',
        value: 'K',
      },
      {
        text: 'Mérida',
        value: 'L',
      },
      {
        text: 'Miranda',
        value: 'M',
      },
      {
        text: 'Monagas',
        value: 'N',
      },
      {
        text: 'Nueva Esparta',
        value: 'O',
      },
      {
        text: 'Portuguesa',
        value: 'P',
      },
      {
        text: 'Sucre',
        value: 'R',
      },
      {
        text: 'Táchira',
        value: 'S',
      },
      {
        text: 'Trujillo',
        value: 'T',
      },
      {
        text: 'Vargas',
        value: 'X',
      },
      {
        text: 'Yaracuy',
        value: 'U',
      },
      {
        text: 'Zulia',
        value: 'V',
      },
    ],
  },
  {
    text: 'Vietnam',
    value: 'VN',
    states: [
      {
        text: 'Đồng Nai',
        value: '39',
      },
      {
        text: 'Đồng Tháp',
        value: '45',
      },
      {
        text: 'Gia Lai',
        value: '30',
      },
      {
        text: 'Hà Giang',
        value: '03',
      },
      {
        text: 'Hà Nam',
        value: '63',
      },
      {
        text: 'Hà Tây',
        value: '15',
      },
      {
        text: 'Hà Tĩnh',
        value: '23',
      },
      {
        text: 'Hải Dương',
        value: '61',
      },
      {
        text: 'Hậu Giang',
        value: '73',
      },
      {
        text: 'Hòa Bình',
        value: '14',
      },
      {
        text: 'Hưng Yên',
        value: '66',
      },
      {
        text: 'Khánh Hòa',
        value: '34',
      },
      {
        text: 'Kiên Giang',
        value: '47',
      },
      {
        text: 'Kon Tum',
        value: '28',
      },
      {
        text: 'Lai Châu',
        value: '01',
      },
      {
        text: 'Lâm Đồng',
        value: '35',
      },
      {
        text: 'Lạng Sơn',
        value: '09',
      },
      {
        text: 'Lào Cai',
        value: '02',
      },
      {
        text: 'Long An',
        value: '41',
      },
      {
        text: 'Nam Định',
        value: '67',
      },
      {
        text: 'Nghệ An',
        value: '22',
      },
      {
        text: 'Ninh Bình',
        value: '18',
      },
      {
        text: 'Ninh Thuận',
        value: '36',
      },
      {
        text: 'Phú Thọ',
        value: '68',
      },
      {
        text: 'Phú Yên',
        value: '32',
      },
      {
        text: 'Quảng Bình',
        value: '24',
      },
      {
        text: 'Quảng Nam',
        value: '27',
      },
      {
        text: 'Quảng Ngãi',
        value: '29',
      },
      {
        text: 'Quảng Ninh',
        value: '13',
      },
      {
        text: 'Quảng Trị',
        value: '25',
      },
      {
        text: 'Sóc Trăng',
        value: '52',
      },
      {
        text: 'Sơn La',
        value: '05',
      },
      {
        text: 'Tây Ninh',
        value: '37',
      },
      {
        text: 'Thái Bình',
        value: '20',
      },
      {
        text: 'Thái Nguyên',
        value: '69',
      },
      {
        text: 'Thanh Hóa',
        value: '21',
      },
      {
        text: 'Thừa Thiên–Huế',
        value: '26',
      },
      {
        text: 'Tiền Giang',
        value: '46',
      },
      {
        text: 'Trà Vinh',
        value: '51',
      },
      {
        text: 'Tuyên Quang',
        value: '07',
      },
      {
        text: 'Vĩnh Long',
        value: '49',
      },
      {
        text: 'Vĩnh Phúc',
        value: '70',
      },
      {
        text: 'Yên Bái',
        value: '06',
      },
      {
        text: 'Cần Thơ',
        value: 'CT',
      },
      {
        text: 'Đà Nẵng',
        value: 'DN',
      },
      {
        text: 'Hà Nội',
        value: 'HN',
      },
      {
        text: 'Hải Phòng',
        value: 'HP',
      },
      {
        text: 'Hồ Chí Minh (Sài Gòn)',
        value: 'SG',
      },
    ],
  },
  {
    text: 'Virgin Islands, British',
    value: 'VG',
    states: [
      {
        text: 'Anegada',
        value: 'ANG',
      },
      {
        text: 'Jost Van Dyke',
        value: 'JVD',
      },
      {
        text: 'Tortola',
        value: 'TTA',
      },
      {
        text: 'Virgin Gorda',
        value: 'VGD',
      },
    ],
  },
  {
    text: 'Virgin Islands, U.S.',
    value: 'VI',
    states: [
      {
        text: 'St. Thomas',
        value: 'STH',
      },
      {
        text: 'St. John',
        value: 'SJO',
      },
      {
        text: 'St. Croix',
        value: 'SCR',
      },
    ],
  },
  {
    text: 'Wallis and Futuna',
    value: 'WF',
    states: [
      {
        text: 'Alo',
        value: 'ALO',
      },
      {
        text: 'Sigave',
        value: 'SIG',
      },
      {
        text: 'Wallis',
        value: 'WAL',
      },
    ],
  },
  {
    text: 'Western Sahara',
    value: 'EH',
    states: [
      {
        text: 'Es Smara',
        value: 'ESM',
      },
      {
        text: 'Boujdour',
        value: 'BOD',
      },
      {
        text: 'Laâyoune',
        value: 'LAA',
      },
      {
        text: 'Aousserd',
        value: 'AOU',
      },
      {
        text: 'Oued ed Dahab',
        value: 'OUD',
      },
    ],
  },
  {
    text: 'Yemen',
    value: 'YE',
    states: [
      {
        text: 'Abyān',
        value: 'AB',
      },
      {
        text: "'Adan",
        value: 'AD',
      },
      {
        text: "Aḑ Ḑāli'",
        value: 'DA',
      },
      {
        text: "Al Bayḑā'",
        value: 'BA',
      },
      {
        text: 'Al Ḩudaydah',
        value: 'HU',
      },
      {
        text: 'Al Jawf',
        value: 'JA',
      },
      {
        text: 'Al Mahrah',
        value: 'MR',
      },
      {
        text: 'Al Maḩwīt',
        value: 'MW',
      },
      {
        text: "'Amrān",
        value: 'AM',
      },
      {
        text: 'Dhamār',
        value: 'DH',
      },
      {
        text: 'Ḩaḑramawt',
        value: 'HD',
      },
      {
        text: 'Ḩajjah',
        value: 'HJ',
      },
      {
        text: 'Ibb',
        value: 'IB',
      },
      {
        text: 'Laḩij',
        value: 'LA',
      },
      {
        text: "Ma'rib",
        value: 'MA',
      },
      {
        text: 'Raymah',
        value: 'RA',
      },
      {
        text: 'Şā‘dah',
        value: 'SD',
      },
      {
        text: "Şan‘ā'",
        value: 'SN',
      },
      {
        text: 'Shabwah',
        value: 'SH',
      },
      {
        text: 'Tā‘izz',
        value: 'TA',
      },
    ],
  },
  {
    text: 'Zambia',
    value: 'ZM',
    states: [
      {
        text: 'Central',
        value: '02',
      },
      {
        text: 'Copperbelt',
        value: '08',
      },
      {
        text: 'Eastern',
        value: '03',
      },
      {
        text: 'Luapula',
        value: '04',
      },
      {
        text: 'Lusaka',
        value: '09',
      },
      {
        text: 'Northern',
        value: '05',
      },
      {
        text: 'North-Western',
        value: '06',
      },
      {
        text: 'Southern',
        value: '07',
      },
      {
        text: 'Western',
        value: '01',
      },
    ],
  },
  {
    text: 'Zimbabwe',
    value: 'ZW',
    states: [
      {
        text: 'Bulawayo',
        value: 'BU',
      },
      {
        text: 'Harare',
        value: 'HA',
      },
      {
        text: 'Manicaland',
        value: 'MA',
      },
      {
        text: 'Mashonaland Central',
        value: 'MC',
      },
      {
        text: 'Mashonaland East',
        value: 'ME',
      },
      {
        text: 'Mashonaland West',
        value: 'MW',
      },
      {
        text: 'Masvingo',
        value: 'MV',
      },
      {
        text: 'Matabeleland North',
        value: 'MN',
      },
      {
        text: 'Matabeleland South',
        value: 'MS',
      },
      {
        text: 'Midlands',
        value: 'MI',
      },
    ],
  },
]
