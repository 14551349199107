<template>
  <blw-form
    formName="income-document"
    header="Upload proof of income"
    text="Please upload a recent paystub or offer letter to verify your income. We also accept two months of bank statements that show regular monthly deposits. <br/><br/> <b>If you are a student without a primary income, please upload your student ID or acceptance letter.</b>"
    :onSubmit="onContinue"
    :updating="updating"
    :error="error.message"
  >
    <v-flex pb-4 v-if="document">
      <blw-document
        :document="document"
        :documentDeleteSuccessHandler="documentDeleteSuccessHandler"
      />
    </v-flex>
    <v-flex>
      <blw-upload
        :hideUploadButton="hideUploadButton"
        :required="!document"
        v-model="fileToUpload"
      />
    </v-flex>
  </blw-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'IncomeDocument',
  props: {
    nextPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileToUpload: null,
    }
  },
  async beforeMount() {
    const incomeDocumentId = this.income.document

    this.resetDocument()
    this.showLoader()
    incomeDocumentId && (await this.fetchDocument(incomeDocumentId))
    this.hideLoader()
  },
  computed: {
    ...mapGetters({
      uploadError: 'documents/error',
      incomeUpdateError: 'income/error',

      loadingIncome: 'income/loading',
      loadingFileUpload: 'documents/loading',
      updatingIncome: 'income/updating',

      income: 'income/income',
      incomeId: 'income/incomeId',
      document: 'documents/document',
    }),
    error() {
      return this.uploadError || this.incomeUpdateError
    },
    updating() {
      return this.loadingIncome || this.loadingFileUpload || this.updatingIncome
    },
    hideUploadButton() {
      return !!this.document || !!this.fileToUpload
    },
  },
  methods: {
    ...mapActions('income', ['updateIncomeByKey', 'fetchIncome']),
    ...mapActions('documents', ['fetchDocument', 'createDocument', 'resetDocument']),
    ...mapActions('loader', ['showLoader', 'hideLoader']),
    documentDeleteSuccessHandler() {
      this.fetchIncome(this.incomeId)
    },
    async onContinue() {
      this.showLoader()
      if (this.fileToUpload) {
        await this.createDocument({
          purpose: 'applicant_income',
          file: this.fileToUpload,
        })

        if (!this.uploadError && this.document) {
          await this.updateIncomeByKey({ field: 'document', value: this.document.id })
        } else {
          this.$trackEvent({
            action: 'CTA Clicked',
            properties: {
              location: 'Center',
              color: 'White',
              text: 'Upload Income',
              category: 'Employment Information',
              status: 'Fail',
            },
          })
        }
      }

      if (!this.error) {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            location: 'Footer',
            color: 'Salmon',
            text: 'Upload Proof of Income',
            CTA: 'Continue',
            category: 'Employment Information',
            type: 'Button',
          },
        })

        this.$routerPush({ name: this.nextPath })
      }

      this.hideLoader()
    },
  },
}
</script>
