<template>
  <div id="confirm-address-error-modal" class="grid-enabled">
    <span class="modal-grid-header header-1 text-xs-center">Confirm Address</span>
    <div class="modal-grid-content mt-2">
      <v-layout justify-center row pb-3 px-3 class="text-xs-center confirm-address-intro">
        Please review the address below
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <div class="address-box selected">
            <div class="address-details">
              <div class="lead">Original Address</div>
              <span v-for="addressField in addressFields" :key="addressField.key">
                <span> {{ originalAddress[addressField.key] }}</span
                ><br v-if="addressField.break" />{{ addressField.addComma ? ', ' : '' }}
              </span>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-layout class="modal-grid-footer mt-4">
      <v-flex xs6 class="px-2">
        <blw-button color="charcoal" @click="$root.$emit('closeModal')" label="Edit" />
      </v-flex>
      <v-flex xs6 class="px-2">
        <blw-button color="salmon" @click="saveAddressClickHandler" label="Confirm" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'ConfirmAddressErrorModal',
  props: {
    originalAddress: {
      type: Object,
      required: true,
    },
    saveAddress: {
      type: Function,
      required: true,
    },
    addressFields: {
      type: Array,
      required: true,
    },
  },
  methods: {
    saveAddressClickHandler() {
      this.$root.$emit('closeModal')
      this.$trackEvent({
        action: 'Save Address',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          CTA: 'Save Address',
          category: 'Address Validation',
          type: 'Button',
        },
      })
      this.saveAddress()
    },
  },
}
</script>
<style lang="scss">
#confirm-address-error-modal {
  .address-box {
    display: flex;
    padding: 1rem;
    border: 1px solid #d6d6d6;
    margin: 5px;

    border: 1px solid black;

    .address-details {
      flex: 1;
      .lead {
        padding-bottom: 0.75rem;
        width: max-content;
      }
    }
  }
}
</style>
