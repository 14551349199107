import dateFns from 'date-fns'
import _ from 'lodash'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  chatUser: null,
  meeting: null,
  timeLeft: {},
  timeExpired: false,
  timeRemainingInterval: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  chatUser: (state) => state.chatUser,
  meeting: (state) => state.meeting,
  meetingChatChannel: (state) => _.get(state.meeting, 'chat_channel.provider_id'),
  meetingId: (state) => state.meeting.id,
  residents: (state) => state.meeting.residents,
  timeLeft: (state) => state.timeLeft,
  timeExpired: (state) => state.timeExpired,
}

const actions = {
  fetchChatUser({ commit }) {
    return this._vm.$http
      .get(`/chat/users`)
      .then(({ data }) => commit('setChatUser', data))
      .catch((error) => commit('setError', error))
  },

  fetchMeeting({ commit, dispatch, rootGetters }, _meetingId) {
    commit('setUpdating', false)
    const meetingId = _meetingId || rootGetters['application/meetingId']

    if (!meetingId) return

    return this._vm.$http
      .get(`/meet-and-greet/meetings/${meetingId}/`)
      .then(async ({ data }) => {
        await commit('setMeeting', data)
        await dispatch('checkTimeLeft')
      })
      .catch((error) => commit('setError', error))
  },

  watchTimeRemaining({ commit, dispatch }) {
    const intervalId = setInterval(() => dispatch('checkTimeLeft'), 1000)
    commit('setTimeRemainingInterval', intervalId)
  },

  stopWatchingTimeRemaining({ commit, state }) {
    if (!state.timeRemainingInterval) return

    clearInterval(state.timeRemainingInterval)
    commit('setTimeRemainingInterval', null)
  },

  checkTimeLeft({ commit, dispatch, getters, state }) {
    // Empty Date to mutate (now)
    let timeDiff = new Date()
    // End time that difference is calcluated from
    const endsAt = new Date(getters.meeting.ends_at)

    // Calculate hours remaining, add them to the duration
    const hours = Math.max(0, dateFns.differenceInHours(endsAt, timeDiff))
    timeDiff = dateFns.addHours(timeDiff, hours)
    // Now calculate the remainder of minutes left
    const minutes = Math.max(0, dateFns.differenceInMinutes(endsAt, timeDiff))
    timeDiff = dateFns.addMinutes(timeDiff, minutes)
    // For the final minute, also calculate seconds left
    const seconds = Math.max(0, dateFns.differenceInSeconds(endsAt, timeDiff))
    timeDiff = dateFns.addSeconds(timeDiff, seconds)
    // Flag if time has expired
    const hasExpired = hours === 0 && minutes === 0 && seconds === 0
    // Save computed time left into store
    const timeLeft = { hours, minutes, seconds, hasExpired }

    commit('setTimeLeft', timeLeft)

    // If time has expired, set flag:
    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      commit('setTimeExpired', true)
      dispatch('stopWatchingTimeRemaining')
    } else {
      // Time remaining, set up interval (if not already done)...
      if (!state.timeRemainingInterval) dispatch('watchTimeRemaining')
    }
  },

  abortMeeting({ commit, dispatch, getters, rootGetters }) {
    commit('setUpdating', true)
    const application = rootGetters['application/applicationId']
    const meeting = getters.meetingId

    return this._vm.$http
      .post(`meet-and-greet/applicant-left-event/`, { application, meeting })
      .then(() => {
        dispatch('stopWatchingTimeRemaining')
        dispatch('resetState')
      })
      .catch((error) => commit('setError', error))
  },
  /**
   *
   * @param {
   *  channel_id: *Channel which to send this message*,
   *  message: *Text message to send*
   * } payload
   */
  sendInitialMessage({ commit }, payload) {
    return this._vm.$http
      .post('chat/pending-messages/', payload)
      .catch((error) => commit('setError', error))
  },

  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const mutations = {
  ...mutationHelpers,
  setChatUser: (state, payload) => (state.chatUser = payload),
  setMeeting: (state, payload) => (state.meeting = payload),
  setTimeLeft: (state, payload) => (state.timeLeft = payload),
  setTimeExpired: (state, payload) => (state.timeExpired = payload),
  setTimeRemainingInterval: (state, payload) => (state.timeRemainingInterval = payload),
}

export default { namespaced: true, state, getters, actions, mutations }
