import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
import { isPast, differenceInDays, addDays } from 'date-fns'
import { roundPriceToNearestFiveDollars } from '@livebungalow/toolbox/abacus'

import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const defaultHomeShoppingData = {
  roomId: null,
  roomAvailabilityDate: null,
  roomPrice: null,
  roomDiscountedPrice: null,
  moveInDate: null,
  leaseDuration: null,
  leaseEndDate: null,
  selectedProperty: null,
}

const getDefaultState = () => ({
  ...stateHelpers,
  homeShopping: { ...defaultHomeShoppingData },
})

const state = getDefaultState()

const MINIMUM_AVAILABILITY_DATE_BUFFER = 2

const getters = {
  getField,
  ...getterHelpers,
  homeShopping: ({ homeShopping }) => homeShopping,
  availableProperties: (state, getters, rootState, rootGetter) => {
    const availableProperties = _.get(
      rootGetter['application/application'],
      'available_properties',
      []
    ).map(({ property: availableProperty }) => {
      const _availableRooms = _.get(availableProperty, 'rooms', [])
        .filter(
          ({ is_available, has_active_room_selection }) =>
            is_available && !has_active_room_selection
        )
        .map((room) => ({
          ...room,
          availability_date:
            room.availability_date &&
            (isPast(new Date(room.availability_date)) ||
              differenceInDays(new Date(room.availability_date), new Date()) <
                MINIMUM_AVAILABILITY_DATE_BUFFER)
              ? addDays(new Date(), MINIMUM_AVAILABILITY_DATE_BUFFER)
              : addDays(new Date(room.availability_date), 1),
        }))
      const _minBasePrice = roundPriceToNearestFiveDollars(
        Math.min(..._availableRooms.map(({ price: [basePrice] }) => basePrice)) *
          rootGetter['constants/minimumLeaseLengthMultiplier']
      )
      const _minDiscountedPrice = roundPriceToNearestFiveDollars(
        Math.min(..._availableRooms.map(({ discounted_price }) => discounted_price)) *
          rootGetter['constants/minimumLeaseLengthMultiplier']
      )
      const _petFriendly = !!availableProperty.amenities.find(
        ({ display_name }) => display_name === 'Pet Friendly'
      )
      const _masterBedroomAvailable = !!_availableRooms.find(({ room_amenities }) => {
        return !!room_amenities.find(({ display_name }) => display_name === 'Master Bedroom')
      })
      const _meeting = rootGetter['meetings/getMeetingForProperty'](availableProperty.id)
      return {
        _availableRooms,
        _availableRoomCount: _availableRooms.length,
        _petFriendly,
        _masterBedroomAvailable,
        _minBasePrice,
        _minDiscountedPrice,
        _meeting,
        _isGroupHome: availableProperty.property_marketing_type === 'group_living',
        ...availableProperty,
      }
    })
    const propertiesWithOpenChats = availableProperties.filter(
      ({ _meeting }) =>
        _.get(_meeting, 'status') === 'open' && !_.get(_meeting, '_timeRemaining.hasExpired')
    )
    const propertiesWithEndedChats = availableProperties.filter(
      ({ _meeting }) =>
        (_meeting && _.get(_meeting, 'status') !== 'open') ||
        _.get(_meeting, '_timeRemaining.hasExpired')
    )
    const propertiesWithoutChats = availableProperties.filter(({ _meeting }) => !_meeting)
    return [...propertiesWithOpenChats, ...propertiesWithEndedChats, ...propertiesWithoutChats]
  },
}

const actions = {
  saveRoomSelection({ commit, getters, rootGetters }) {
    commit('setLoading', true)
    commit('setError', false)

    const application = rootGetters['application/applicationId']
    const {
      roomId: room,
      moveInDate: move_in_date,
      leaseEndDate: lease_end_date,
    } = getters.homeShopping

    return this._vm.$http
      .post('applications/room-selection/', { application, room, lease_end_date, move_in_date })
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
