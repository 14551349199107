<template>
  <div class="profile-preferences-view">
    <v-container>
      <!-- QUESTION  -->
      <v-layout
        column
        class="question"
        v-max-width="312"
        v-for="(question, index) in questionsWithResponses"
        :key="question.id"
        v-show="activeQuestion === index"
      >
        <p class="text-xs-center font-grey font-xsmall pb-2">
          Question {{ index + 1 }} of {{ questionsWithResponses.length }}
        </p>
        <h3 class="question-title header-sm text-xs-center">{{ question.copy }}</h3>
        <!-- YOU -->
        <div v-if="perspective === 'me'" key="me">
          <v-layout class="my-3" align-center>
            <blw-avatar class="mr-2" :avatar="blueAvatar" size="30" />
            <span>Your answer</span>
          </v-layout>
          <div class="answer-block me">
            <div
              v-ripple
              tabindex="0"
              class="answer"
              :key="answer.id"
              v-for="answer in question.answers.slice().reverse()"
              @click="(event) => toggleMyResponse(question, answer.id, event)"
              @keyup.space.enter="(event) => toggleMyResponse(question, answer.id, event)"
            >
              <span>{{ answer.copy }}</span>
              <div :class="['check round', { checked: question.response.me === answer.id }]">
                <v-icon color="white" size="20">check</v-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- THEM -->
        <div v-if="perspective === 'them'" key="them">
          <v-layout class="my-3" align-center>
            <blw-avatar class="mr-2" :avatar="salmonAvatar" size="30" />
            <span>Your ideal roommates answer</span>
          </v-layout>
          <div class="answer-block them">
            <div
              v-ripple
              tabindex="0"
              class="answer reversed"
              :key="answer.id"
              v-for="answer in question.answers.slice().reverse()"
              @click="(event) => toggleTheirResponse(question, answer.id, event)"
              @keyup.space.enter="(event) => toggleTheirResponse(question, answer.id, event)"
            >
              <span class="text-xs-right">{{ answer.copy }}</span>
              <div :class="['check', { checked: question.response.them.includes(answer.id) }]">
                <v-icon color="white" size="20">check</v-icon>
              </div>
            </div>
          </div>
          <v-layout justify-center class="py-2 mt-1">
            <small v-if="question.response.them.length" class="font-grey">
              Deselect any that don’t apply
            </small>
            <small v-else class="error-msg">Select at least one</small>
          </v-layout>
        </div>
        <blw-button key="action" label="Continue" @click="nextStep" :disabled="noSelectionMade" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfilePreferences',
  data() {
    return {
      activeQuestion: 0,
      perspective: 'me',
      progress: {},
    }
  },
  computed: {
    ...mapGetters('user', ['blueAvatar', 'salmonAvatar']),
    ...mapGetters('preferences', ['questions', 'responses']),
    questionsWithResponses() {
      return this.questions.map((question) => {
        const response = this.responses[question.id]
        return { ...question, response }
      })
    },
    onLastQuestion() {
      const finalQuestion = this.activeQuestion === this.questions.length - 1
      const theirPerspective = this.perspective === 'them'

      return finalQuestion && theirPerspective
    },
    noSelectionMade() {
      return this.perspective === 'me' ? this.ensureMyResponse() : this.ensureTheirResponses()
    },
  },
  methods: {
    ...mapActions('preferences', [
      'fetchQuestions',
      'fetchResponses',
      'resetResponses',
      'setYourResponse',
      'setTheirResponse',
    ]),
    ensureTheirResponses() {
      const selections = this.questionsWithResponses[this.activeQuestion]

      return selections ? selections.response.them.length == 0 : false
    },
    ensureMyResponse() {
      const selection = this.questionsWithResponses[this.activeQuestion]

      return (selection && !selection.response.me) || false
    },
    toggleMyResponse(question, answerId, event) {
      if (event) event.target.blur()

      this.trackResponseChange('My', question)
      this.setYourResponse({ questionId: question.id, answerId })
    },
    toggleTheirResponse(question, answerId, event) {
      if (event) event.target.blur()

      this.trackResponseChange('Their', question)
      this.setTheirResponse({ questionId: question.id, answerId })
    },
    trackResponseChange(perspective, question) {
      this.$trackEvent({
        action: 'Response Changed',
        properties: {
          location: `${perspective} Preferences`,
          color: 'Blue',
          text: question.copy,
          category: 'Profile',
          type: 'Radio',
        },
      })
    },
    nextStep() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Preferences',
          color: 'Salmon',
          CTA: 'Continue',
          category: 'Profile',
          type: 'Button',
        },
      })

      // No more questions, next section!
      if (this.onLastQuestion) return this.nextSection()

      // Next question
      if (this.perspective === 'them') {
        this.perspective = 'me'
        this.activeQuestion++
        return
      }

      // Next question part
      if (this.perspective === 'me') this.perspective = 'them'
    },
    async nextSection() {
      this.$routerPush({ name: 'profileInterests' })
    },
  },
  async beforeMount() {
    await Promise.all([this.fetchQuestions(), this.fetchResponses()])
  },
}
</script>

<style lang="scss">
.profile-preferences-view {
  .question {
    margin: 3.75rem auto;

    @include mobile {
      margin: 2rem auto;
    }
  }

  .question-title {
    min-height: 5.25rem;
    margin-bottom: 1.5rem;
    line-height: 2rem;

    @include mobile {
      font-size: 22px;
    }
  }

  small {
    font-size: 13px;

    &.error-msg {
      color: $salmon-80;
    }
  }

  .check {
    transition: all 0.25s ease;
    transition-delay: 0;
    min-width: 1.75rem;
    min-height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $charcoal-20;
    user-select: none;

    .v-icon {
      display: none;
    }

    &.checked {
      background: $blue-60;
      border-color: transparent;

      .v-icon {
        display: block;
      }
    }
  }

  .answer-block {
    width: 100%;
    background: white;

    span {
      font-size: 14px;
    }

    &.me {
      margin-bottom: 42px;

      span {
        padding-right: 1rem;
      }
    }

    &.them span {
      padding-left: 1rem;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input__control {
      flex-grow: 1;
    }
    .v-messages {
      display: none;
    }

    .answer {
      padding: 1.5rem;
      cursor: pointer;
      transition: background 0.25s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;
      position: relative;

      &::after {
        position: absolute;
        display: block;
        content: ' ';
        height: 1px;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 0;
        background: $charcoal-10;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      &.reversed {
        flex-direction: row-reverse;
      }

      &:focus,
      &:hover {
        outline: 0;
        background: $blue-20;
      }
    }
  }
}
</style>
