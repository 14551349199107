<template>
  <div class="mg-terms mg-interstitial">
    <v-container>
      <v-layout column align-center>
        <v-layout column align-center>
          <div class="lead mb-3">Meet the roommates</div>
          <h1 class="header-1 text-xs-center mb-5">Before we continue...</h1>
          <div class="d-flex mb-5">
            <blw-avatar class="mx-2" :avatars="applicants" :size="avatarSize" />
            <blw-avatar class="mx-2" :avatars="meeting.residents" :size="avatarSize" />
          </div>
          <p class="font-large text-xs-center mb-3" v-max-width="320">
            We ask that you, along with everyone else in our community
          </p>
          <ul class="mb-4">
            <li class="font-small my-2" v-for="(ask, idx) in asks" :key="idx">
              <v-layout align-center>
                <v-icon class="mr-3" color="charcoal">check</v-icon>
                <b>{{ ask }}</b>
              </v-layout>
            </li>
          </ul>
        </v-layout>
        <blw-button size="lg" class="mt-4" label="Continue" @click="goToChat" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MeetGreetTerms',
  data() {
    return {
      asks: ['Engage with an open mind', 'Be kind to each other', 'Treat everyone with respect'],
    }
  },
  computed: {
    ...mapGetters('meetGreet', ['meeting', 'timeLeft']),
    avatarSize() {
      return this.$vuetify.breakpoint.smAndDown ? '80' : '120'
    },
    applicants() {
      const { primary_applicant, secondary_applicant } = this.meeting
      const applicants = [primary_applicant]
      if (secondary_applicant) applicants.push(secondary_applicant)

      return applicants
    },
  },
  methods: {
    goToChat() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Meet & Greet - Terms',
          color: 'Salmon',
          text: 'Before we continue...',
          CTA: 'Continue',
          category: 'Meet & Greet',
          type: 'Button',
        },
      })

      this.$routerPush({ name: 'meetGreetChat' })
    },
  },
  mounted() {
    if (window.Intercom) window.Intercom('shutdown')
  },
}
</script>
