<template>
  <loading-spinner full-page v-if="!profileReady" />
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'ProfileContainer',
  components: { LoadingSpinner },
  data() {
    return {
      profileReady: false,
    }
  },
  methods: {
    ...mapActions('application', ['fetchApplicationBySource']),
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('interests', ['fetchInterests']),
    ...mapActions('preferences', ['fetchQuestions', 'fetchResponses']),
    goToWaitingPage() {
      if (this.$route.name !== 'profileWaiting') this.$routerPush({ name: 'profileWaiting' })
    },
  },
  computed: {
    ...mapGetters('application', ['applicationStatus']),
    ...mapGetters('profile', ['error', 'loading']),
  },
  watch: {
    // each time the route change we need to re-fetch the application
    // this allows up to safely check for updates to status etc. during the
    // profile section
    $route({ params: { applicationSource } }) {
      this.fetchApplicationBySource(applicationSource)
    },
  },
  async beforeMount() {
    await Promise.all([
      this.fetchProfile(),
      this.fetchInterests(),
      this.fetchQuestions(),
      this.fetchResponses(),
    ])

    this.profileReady = true
  },
  mounted() {
    const interstitialStatuses = [
      'profile_completed_request_pending',
      'profile_completed_roommate_pending',
    ]

    if (interstitialStatuses.includes(this.applicationStatus)) this.goToWaitingPage()
  },
}
</script>
