<template>
  <div
    v-if="isOpen"
    :class="['blw-modal', { isFullScreen, closeAnimation }]"
    @click.self="closeModal"
  >
    <div :class="`${modalSize}-modal`">
      <v-btn v-if="onGoBack" flat class="go-back" @click="goBack" @keyup.enter.space="goBack">
        <v-icon :color="navIconColor">arrow_left</v-icon>
      </v-btn>
      <v-btn flat class="close-modal" @click="closeModal" @keyup.enter.space="closeModal">
        <v-icon :color="navIconColor">close</v-icon>
      </v-btn>
      <component
        ref="modalComponent"
        class="modal-component"
        :is="modalComponentToRender"
        v-bind="modalComponentProps"
      />
    </div>
  </div>
</template>

<script>
/**
 * This modal component listens to a root event name 'openModal'
 * it accepts any of the fields in the defaultModalState bellow to dynamically use the internal functionality
 * ( You can send the name of the modal you wish to render OR the actual component !!)
 *
 * EXAMPLES:
 *
    import CommunityEventsModal from '@/components/website/Property/CommunityEventsModal.vue'
     this.$root.$emit('openModal', {
      modalComponent: CommunityEventsModal,
      modalComponentProps: {
        marketCommunityEvents: this.marketCommunityEvents
      },
      modalSize: 'lg'
    })
     OR
     this.$root.$emit('openModal', {
      modalComponent: 'CommunityEventsModal',
      modalComponentProps: {
        marketCommunityEvents: this.marketCommunityEvents
      }
    })
     OR
    ( when you don't need any extra functionality )
    this.$root.$emit('openModal', 'CommunityEventsModal')
  */

import modals from '@/shared/components/Modals'

const defaultModalState = () => ({
  isOpen: false,
  closeAnimation: false,
  modalComponent: null,
  modalComponentProps: {},
  modalSize: 'medium',
  onGoBack: false,
  modals,
})

export default {
  name: 'Modal',
  data() {
    return defaultModalState()
  },
  created() {
    // Root Event Listeners
    this.$root.$on('openModal', this.openModalEmitHandler)
    this.$root.$on('closeModal', this.closeModalEmitHandler)
    // 'Esc' Handler (close modal)
    window.addEventListener('keyup', this.closeModal)
  },
  destroyed() {
    window.removeEventListener('keyup', this.closeModal)
  },
  computed: {
    modalComponentToRender() {
      const modalToRender =
        typeof this.modalComponent === 'string'
          ? this.modals[this.modalComponent]
          : this.modalComponent

      if (!modalToRender) {
        return console.error(
          `No Modal Component Found!
          Make sure to send the component as the 'modalComponent' prop
          OR the name of the vue modal component that is in the '/Modals' folder`
        )
      }
      return modalToRender
    },
    navIconColor() {
      return 'charcoal'
    },
    isFullScreen() {
      return this.modalSize === 'fullscreen'
    },
  },
  methods: {
    goBack() {
      typeof this.onGoBack === 'function' && this.onGoBack()
    },
    closeModal(evt) {
      if (!this.isOpen || (evt.key && evt.key !== 'Escape')) return

      this.$root.$emit('closeModal', { modal: this.modalComponent })
    },
    openModalEmitHandler(configOrModalName) {
      const config =
        typeof configOrModalName === 'string'
          ? { modalComponent: configOrModalName }
          : configOrModalName

      this.updateModalData(config)

      if (!this.isOpen) {
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      }

      this.isOpen = true
    },
    closeModalEmitHandler() {
      // Re-enable page scrolling
      const body = document.body
      const scrollY = body.style.top
      body.style.position = ''
      body.style.top = ''
      body.style.width = ''
      this.$nextTick(function () {
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      })

      // Sets the closing flag to true, triggering the closing animation(s)
      // After ~300 ms (when animation completes) the modal state is set back reset to default
      this.closeAnimation = true
      setTimeout(() => {
        this.updateModalData(defaultModalState())
      }, 150)
    },
    updateModalData(newData) {
      // Loop through the object keys and replace current data values
      for (const [key, value] of Object.entries(newData)) this[key] = value
    },
  },
}
</script>

<style lang="scss">
$smallModal: 440px;
$mediumModal: 612px;
$largeModal: 800px;
$modalBackdrop: #0000005e;
$zIndexAboveIntercom: 2147483001;

.blw-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 1rem;
  background: $modalBackdrop;
  top: 0;
  left: 0;
  z-index: $zIndexAboveIntercom;
  display: flex;
  overflow-y: scroll;
  animation: fade-in 0.35s forwards;

  &.isFullScreen {
    padding: 0;
  }

  &.closeAnimation {
    animation: fade-out 0.3s forwards;
    > * {
      animation: slide-down 0.3s forwards;
    }
  }

  > * {
    display: flex;
    margin: auto;
    height: max-content;
    width: 100%;
    max-width: $mediumModal;
    border-radius: 4px;

    .modal-component {
      width: 100%;
    }

    &.sm-modal,
    &.small-modal {
      max-width: $smallModal;
    }

    &.md-modal,
    &.medium-modal {
      max-width: $mediumModal;
    }

    &.lg-modal,
    &.large-modal {
      max-width: $largeModal;
    }

    &.fullscreen-modal {
      width: 100%;
      height: 100%;
      overflow: scroll;
      max-width: unset;
    }
  }

  .go-back,
  .close-modal {
    transition: background 0.25s ease;
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    border-radius: 100%;
    min-width: initial !important;
    width: 2rem !important;
    height: 2rem !important;

    &:focus,
    &:hover {
      outline: 0;
      background: $charcoal-20;
    }

    &:active {
      background: $charcoal-40;
    }
  }

  .go-back {
    left: 1.75rem;
    @include mobile {
      left: 1rem;
    }
  }

  .close-modal {
    right: 1.75rem;
    @include mobile {
      right: 1rem;
    }
  }

  // Rendered Modal
  > * {
    position: relative;
    animation: slide-up 0.3s forwards;
    padding: 4rem 2.25rem 3.5rem 2.25rem;
    background: white;

    @include mobile {
      padding: 3.5rem 1.5rem 3rem 1.5rem;
    }
  }

  // Grid Layout (optional)
  .grid-enabled {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-template-areas:
      'lead'
      'header'
      'content'
      'footer';

    .modal-grid-lead {
      grid-area: lead;
      text-align: center;
    }

    .modal-grid-header {
      grid-area: header;
      text-align: center;
    }

    .modal-grid-content {
      grid-area: content;
    }

    .modal-grid-footer {
      grid-area: footer;
    }
  }
}
</style>
