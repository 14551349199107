import { getField, updateField } from 'vuex-map-fields'
import { get as _get, set as _set, cloneDeep as _cloneDeep } from 'lodash'

import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  applicationValidation: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  validation: ({ applicationValidation }) => {
    const clonedApplicationValidation = _cloneDeep(applicationValidation || {})
    _set(clonedApplicationValidation, 'resources.applicant_info.photo_id', 'valid')
    _set(
      clonedApplicationValidation,
      'summary.applicant_info',
      Object.values(_get(clonedApplicationValidation, 'resources.applicant_info')).every(
        (value) => value !== 'required'
      )
        ? 'valid'
        : 'invalid'
    )
    _set(
      clonedApplicationValidation,
      'valid',
      Object.values(_get(clonedApplicationValidation, 'summary')).every(
        (value) => value === 'valid'
      )
    )
    return clonedApplicationValidation
  },
  isValid: (state, { validation }) => {
    return _get(validation, 'valid')
  },
}

const actions = {
  fetchApplicationValidation({ commit }, applicationId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/application-validations/${applicationId}`)
      .then((response) => response.data)
      .then((applicationValidation) => {
        commit('setError', null)
        commit('setApplicationValidation', applicationValidation)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setApplicationValidation(state, applicationValidation) {
    state.applicationValidation = applicationValidation
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
