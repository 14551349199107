<template>
  <v-container id="leasing-co-signer-or-deposit" class="py-5">
    <v-layout class="mb-4" align-center justify-center>
      <v-flex class="text-xs-center">
        <h1 class="header-md text-xs-center mb-3">
          Before you meet the roommates,<br v-if="$vuetify.breakpoint.mdAndUp" />
          we'll need a bit more information
        </h1>
        <span>
          We reviewed your application and you'll need<br v-if="$vuetify.breakpoint.mdAndUp" />
          to take one of these steps to get approved
        </span>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-max-width="450" class="mx-auto">
      <v-flex v-for="(option, index) in options" :key="index" xs12>
        <v-card @click="option.onClick" class="selection-item mb-2 pa-3" v-ripple>
          <v-layout>
            <v-flex>
              <blw-icon :icon="option.icon" size="sm" />
            </v-flex>
            <v-flex>
              <v-card-title class="pb-0 pt-2">
                <b>{{ option.header }}</b>
              </v-card-title>
              <v-card-text class="pt-2">
                <span v-text="option.text" />
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout class="mt-3">
      <v-flex class="text-xs-center">
        <div @click="openChat">
          <b>Have questions? <span class="clickable"> Chat with our leasing team</span></b> to
          <br v-if="$vuetify.breakpoint.mdAndUp" />discuss how we can get you cleared to move in
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NominateOrDepositChoice',
  data() {
    return {
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 'auto',
        centeredSlides: true,
        simulateTouch: false,
      },
      options: [],
    }
  },
  mounted() {
    this.$trackEvent({
      action: 'Page viewed',
      properties: {
        type: 'Approval prompt',
        title: 'Co-signer_double_deposit',
      },
    })
    this.options = [
      {
        icon: 'cash',
        header: 'Nominate a co-signer',
        text: 'Choose this option if you have a family member or friend who is willing to co-sign your lease with you',
        onClick: this.nominateCosignerClickHandler,
      },
      {
        icon: 'people',
        header: this.doubleDepositTextConditionalByRegion,
        text: `Choose this option if you’d prefer to ${this.doubleDepositTextConditionalByRegion.toLowerCase()}.`,
        onClick: this.doubleDepositClickHandler,
      },
    ]
  },
  computed: {
    ...mapGetters('application', ['doubleDepositTextConditionalByRegion', 'application']),
  },
  methods: {
    openChat() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Approval prompt',
          title: 'Co-signer_double_deposit',
          CTA: 'Chat_with_leasing_team',
        },
      })
      window.Intercom && window.Intercom('show')
    },
    nominateCosignerClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Approval prompt',
          title: 'Co-signer_double_deposit',
          CTA: 'Continue',
          type: 'Co-signer',
        },
      })
      this.$routerPush({
        name: 'reviewAddCoSigner',
      })
    },
    doubleDepositClickHandler() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Approval prompt',
          title: 'Co-signer_double_deposit',
          CTA: 'Continue',
          type: 'Double-deposit',
        },
      })
      this.$root.$emit('openModal', {
        modalSize: 'sm',
        modalComponent: 'DoubleDepositPromptModal',
      })
    },
  },
}
</script>

<style lang="scss">
#leasing-co-signer-or-deposit {
  .selection-item {
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #e6e3e3;
  }
  .blw-icon.sm {
    padding-top: 21px !important;
    height: 18px;
    width: 18px;
  }
  .clickable {
    color: $blue-80;
  }
}
</style>
