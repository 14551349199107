<template>
  <div class="confirm-consent-modal">
    <div class="mx-auto" v-max-width="512">
      <v-layout column class="text-xs-left">
        <v-flex :class="!isMobile && 'py-4'">
          <h1 class="header-lg font-black regular-font mb-2">
            Confirm we can run a <br />
            background check
          </h1>
          <div class="charcoal-40--txt font-large">
            Contact homes@bungalow.com to request a copy of your report
          </div>
          <v-divider class="my-4" />
          <h4 class="font-bold mb-3">Background Check Authorization</h4>
          <div class="mb-3">
            In connection with my rental application, I authorize Bungalow Living, Inc. (the
            “Company”) to order a “consumer report” (a background report) about me. The background
            check company who will be conducting such checks is:<br /><br />

            Certn (Canada) Inc<br />
            300-1006 Fort Street<br />
            Victoria BC V8V 3K4, Canada<br /><br />

            <a class="cta-link blue--text" href="https://certn.co/" target="_blank"
              >https://certn.co/</a
            ><br />
            <a class="cta-link blue--text" href="mailto:support@certn.co" target="_blank"
              >support@certn.co</a
            ><br /><br />

            <b class="font-large">Important Disclosures:</b><br />
            <ul>
              <li>
                <a
                  class="cta-link blue--text"
                  href="https://s3.us-west-2.amazonaws.com/downloads.bungalow.com/cfpb_summary_your-rights-under-fcra.pdf"
                  target="_blank"
                >
                  A Summary of Your Rights Under the Fair Credit Reporting Act</a
                >
              </li>
              <li>
                <a
                  class="cta-link blue--text"
                  href="https://downloads.bungalow.com/notice-to-user-of-consumer-reports-obligations-of-users-under-the-fcra.pdf"
                  target="_blank"
                >
                  Notice to User of Consumer Reports - Obligations of Users Under the FCRA</a
                >
              </li>
              <li>
                <a
                  class="cta-link blue--text"
                  href="https://downloads.bungalow.com/cfpb_summary_remedying-the-effects-of-id-theft-fcra.pdf"
                  target="_blank"
                >
                  Remedying the Effects of Identity Theft (FCRA)</a
                >
              </li>
            </ul>

            <br />

            I acknowledge the background report may contain information concerning my character,
            general reputation, personal characteristics, mode of living, and credit
            history/standing. The types of information that may be ordered include, but are not
            limited to: social security number verification; criminal records check; verification of
            prior employment; and credit reports.<br /><br />

            Selection criteria that may result in denial of my rental application includes: criminal
            history (as allowed by law); previous rental history; credit history; or failure to
            provide accurate or complete information on the application form.<br /><br />

            I agree the Company may rely on this form to order background reports throughout my
            tenancy without asking me for my authorization again as allowed by law. I also agree
            that a copy of this form is valid like the signed original. I certify that all of the
            personal information I provided is true and correct.<br /><br />

            <strong
              >Clicking continue indicates that you have had the opportunity to review the
              landlord’s tenant selection criteria. The tenant selection criteria may include
              factors such as criminal history, credit history, current income, and rental history.
              If you do not meet the selection criteria, or if you provide inaccurate or incomplete
              information, your application may be rejected and your application fee will not be
              refunded.</strong
            ><br /><br />
          </div>
        </v-flex>
      </v-layout>
    </div>
    <!-- Footer -->
    <div class="confirm-consent-modal__footer">
      <v-layout v-max-width="495" class="mx-auto">
        <v-flex>
          <div class="font-small text-xs-center mb-3 px-3">
            By clicking “{{ submitText }}” I acknowledge that I have read and agree to the
            Background Check Authorization. I authorize Bungalow to order reports about me.
          </div>
          <v-btn
            block
            depressed
            v-max-width="300"
            @click="consentGiven"
            :loading="loading"
            color="primary"
            class="mb-4 mx-auto"
          >
            {{ submitText }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackgroundCheckConsent',
  props: {
    onContinue: {
      type: Function,
      required: true,
    },
    submitText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$trackEvent({
      action: 'Open Modal: Background Check Consent',
      properties: {
        location: 'Background Check Consent',
        CTA: 'Continue',
        event: 'Open Modal',
      },
    })
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    async consentGiven() {
      this.loading = true
      this.$trackEvent({
        action: 'CTA Clicked: Background Check Consent Given',
        properties: {
          location: 'Background Check Consent',
          CTA: 'Continue',
          event: 'CTA Clicked',
        },
      })
      await this.onContinue()
      this.loading = false
      this.$root.$emit('closeModal')
    },
  },
}
</script>
<style lang="scss">
.confirm-consent-modal {
  position: relative;

  ul {
    list-style: initial;
    padding-left: 1.5rem;

    .cta-link {
      font-size: 14px;
    }
  }

  &__footer {
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    padding-top: 1.5rem;
    position: sticky;
    background: white;
    bottom: -56px;
    margin: 0 -36px;

    @include mobile {
      bottom: -48px;
      margin: 0 -24px;
    }
  }
}
</style>
