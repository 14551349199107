<template>
  <div>
    <div class="banner charcoal-5--bg text-xs-center pa-3">
      <p class="mb-2 font-bold font-large">Lock this home down 🔏</p>
      <p class="mx-auto" v-max-width="440">
        <b>A few other parties</b> are looking at this home. Act quickly to sign the lease so you
        don't miss out
      </p>
    </div>
    <v-container class="group-lease-sent" v-max-width="435">
      <v-layout
        column
        :class="['text-xs-center pt-5', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-5']"
      >
        <span class="header-xl">🎉</span>
        <h1 class="header-md mt-4">Your lease is sent & ready to sign!</h1>

        <p class="px-3 mt-4">We've emailed a lease to each applicant in the application</p>

        <div class="mt-5 mx-auto w-100" v-max-width="290">
          <blw-button @click="goToInbox" color="charcoal">Go to your email inbox</blw-button>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import BlwButton from '@/shared/components/BlwButton.vue'
export default {
  name: 'GroupLeaseSent',
  components: { BlwButton },
  methods: {
    goToInbox() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          category: 'group_applications',
          CTA: 'group_lease_sent_cta',
          text: 'Go to your email inbox',
        },
      })

      window.open('mailto:')
    },
  },
}
</script>
