<template>
  <div class="blw-phone blw-control">
    <blw-select
      tabindex="-1"
      dense
      :items="callCodes"
      v-model="callCode"
      label="Locale"
      :box="boxDropdown"
    />
    <blw-text-field
      type="tel"
      name="phone"
      :key="phoneMask"
      :mask="phoneMask"
      :value="phoneNumber"
      :return-masked-value="!this.callCode"
      @input="onChange"
      v-bind="$attrs"
      validate-on-blur
      :rules="validationRules"
    />
  </div>
</template>

<script>
import GooglePhoneLib from 'google-libphonenumber'
import { trimStart as _trimStart } from 'lodash'

const phoneUtils = GooglePhoneLib.PhoneNumberUtil.getInstance()
const MIN_PARSE_LENGTH = 4

const CALL_CODES = [
  { text: 'US/CA (+1)', value: 1 },
  { text: 'UK (+44)', value: 44 },
  { text: 'Mexico (+52)', value: 52 },
  { text: 'China (+86)', value: 86 },
  { text: 'India (+91)', value: 91 },
  { text: 'Other', value: null },
]

export default {
  name: 'BlwPhone',
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    boxDropdown: {
      type: Boolean,
    },
  },
  data() {
    return {
      callCode: this.defaultLocale(),
      callCodes: CALL_CODES,
    }
  },
  watch: {
    callCode() {
      this.onChange(this.phoneNumber)
    },
  },
  computed: {
    validationRules() {
      return [...this.rules, this.isValidPhoneNumber]
    },
    isValidPhoneNumber() {
      const newNumber = this.parseNumber()
      if (!newNumber) {
        return true
      }
      const isValidPhoneNumber = phoneUtils.isValidNumber(newNumber)
      return isValidPhoneNumber ? true : 'Invalid phone number.'
    },
    phoneMask() {
      return this.callCode === null ? '+#################' : '(###) ### - ####'
    },
    phoneNumber() {
      if (!this.callCode) return this.$attrs.value

      const parsed = this.parseNumber(this.$attrs.value)

      const number = parsed
        ? parsed.getNationalNumber()
        : _trimStart(this.$attrs.value, `+${this.callCode}`)

      return number
    },
  },
  methods: {
    defaultLocale() {
      const number = this.parseNumber(this.$attrs.value)
      const callCode = (number && number.getCountryCode()) || 1
      const callCodeInList = CALL_CODES.find((code) => code.value == callCode)

      if (callCodeInList) return callCode

      return callCode ? null : 1
    },
    parseNumber(value = this.$attrs.value) {
      if (!value) return

      try {
        const parsableLength = value >= MIN_PARSE_LENGTH
        const parsableLocale = this.callCode !== null

        return parsableLength && parsableLocale ? phoneUtils.parse(value) : false
      } catch (error) {
        return false
      }
    },
    onChange(rawPhoneNumber) {
      if (!rawPhoneNumber || this.callCode === null || this.callCode === undefined)
        return this.$emit('input', rawPhoneNumber)

      const fullPhoneNumber = `+${this.callCode}${rawPhoneNumber}`

      this.$emit('input', fullPhoneNumber)
    },
  },
}
</script>

<style lang="scss">
.blw-phone {
  display: flex;

  .blw-select {
    .v-input__slot {
      border-top-right-radius: 0px !important;
      &::before,
      &::after {
        display: none;
      }
    }

    .v-messages__wrapper {
      display: none;
    }

    max-width: 7rem;

    .v-select__selection {
      white-space: nowrap;
      font-size: 13px;
    }

    .v-input__append-inner {
      padding-left: 0 !important;
    }
    .v-select__selection--comma {
      margin-right: 0 !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .blw-text-field {
    width: auto;

    input {
      word-spacing: -2px;
    }

    // Extend Underline
    .v-input__slot {
      border-top-left-radius: 0px !important;
      &::before,
      &::after {
        left: -7rem !important;
        right: 0 !important;
        width: calc(100% + 7rem) !important;
      }
    }

    label {
      padding-left: $spacer-sm * 1.2;
    }
    input {
      padding-left: $spacer-sm !important;
    }

    .v-text-field__details {
      overflow: visible;
    }

    .v-messages__message {
      margin-left: -7rem !important;
    }
  }
}
</style>
