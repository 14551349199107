<template>
  <v-app>
    <div :class="`${routeApplicationClasses}`">
      <navbar v-if="!hideNav" :basicNav="showBasicNav" :disableBack="disableBack" />
      <promo-banner />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <loading-spinner appLoader full-page v-if="loaderIsVisible" />
      <Modal />
      <PopupIframe />
      <Snackbar />
      <SupportButton />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Navbar from '@/shared/Navbar'
import LoadingSpinner from '@/shared/LoadingSpinner'
import PopupIframe from '@/shared/PopupIframe'
import PromoBanner from '@/shared/PromoBanner'
import Snackbar from '@/shared/Snackbar'
import Modal from '@/shared/Modal'
import SupportButton from '@/shared/SupportButton'

export default {
  name: 'App',
  components: {
    Navbar,
    LoadingSpinner,
    Modal,
    PopupIframe,
    PromoBanner,
    Snackbar,
    SupportButton,
  },
  mounted() {
    this.fetchFeatureFlags()
  },
  computed: {
    ...mapGetters('loader', ['loaderIsVisible']),
    ...mapGetters('applicationSource', ['isGroupSource']),
    ...mapGetters('application', ['displayState']),
    routeApplicationClasses() {
      const { applicationClasses = '' } = this.$route.meta
      return applicationClasses
    },
    disableBack() {
      const {
        meta,
        matched,
        query: { isGroup },
      } = this.$route
      if (isGroup || this.isGroupSource) return true
      if (meta.disableBack) return meta.disableBack

      return matched.some(({ meta }) => meta.disableBack)
    },
    showBasicNav() {
      const {
        meta,
        matched,
        query: { isGroup },
      } = this.$route
      if (isGroup || (this.displayState && this.displayState.is_funnel_20) || this.isGroupSource)
        return true
      if (meta.basicNav) return meta.basicNav

      return matched.some(({ meta }) => meta.basicNav)
    },
    hideNav() {
      const { meta, matched } = this.$route
      if (meta.hideNav) return meta.hideNav

      return matched.some(
        ({ meta }) => meta.hideNav || (meta.hideNavMobile && this.$vuetify.breakpoint.smAndDown)
      )
    },
  },
  watch: {
    $route: {
      handler({ name }) {
        this.$trackPageChange({ name })
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('flags', ['fetchFeatureFlags']),
  },
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

#app {
  @include pageTransitions;
}
</style>
