<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height text-xs-center">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 v-max-width="285" class="header-md mb-5">
          Waiting for your roommate to complete their profile
        </h1>
        <blw-illustration name="cat-clock" size="175" class="mb-4" />
        <div v-max-width="215">
          <p class="mb-4">We’ll notify you via email once they're done.</p>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileWaiting',
}
</script>
