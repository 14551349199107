<template>
  <loading-spinner v-if="loading" full-page />
  <v-container v-else class="text-xs-center">
    <v-layout v-max-width="350" align-center justify-center column class="full-height mx-auto">
      <blw-illustration name="puzzle" />
      <h1 class="header-md my-3">Application source required</h1>
      <p class="mb-3">
        This application source doesn't seem to exist, or you don't have access to it.
      </p>
      <p class="mb-3 font-small">
        Applications cannot be shared from one user to another. If you were sent this application by
        someone else, you will not be able to access it. For assistance, please contact the Bungalow
        team at
        <a class="cta-link" href="mailto:welcomehome@bungalow.com">welcomehome@bungalow.com</a>.
      </p>
      <!-- Has Applications -->
      <template v-if="applications.length">
        <p v-max-width="300" class="font-small my-4">
          However, it looks like you have other open applications, you can view them below!
        </p>
        <blw-button size="lg" @click="goToApplications"> View your applications </blw-button>
      </template>

      <!-- No Applications -->
      <template v-else>
        <p v-max-width="460" class="mt-5 mb-2 font-weight-bold">
          If you don't see your application here, make sure you're logged in with the same email
          address used to create it
        </p>
        <small v-max-width="325" class="mt-3 mb-3">
          Don't have a Bungalow in mind, take a look our available properties!
        </small>
        <blw-button color="accent" href="https://bungalow.com">
          Visit Bungalow.com
          <v-icon right color="white">open_in_new</v-icon>
        </blw-button>
      </template>
      <blw-button color="charcoal" size="lg" flat darkText @click="openChat">
        Chat with the team
      </blw-button>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'

export default {
  name: 'NoApplicationSource',
  components: { LoadingSpinner },
  computed: {
    ...mapGetters('applications', ['applications', 'loading']),
  },
  methods: {
    ...mapActions('applications', ['fetchApplications']),
    goToApplications() {
      this.$routerPush({ name: 'applications' })
    },
    openChat() {
      window.Intercom && window.Intercom('show')
    },
  },
  beforeMount() {
    this.fetchApplications()
  },
}
</script>
