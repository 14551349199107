<template>
  <v-layout
    column
    :class="['relative property-option bg-white', { 'no-rooms-available': !availableRoomCount }]"
    ref="propertyCardRef"
  >
    <div class="property-card-slide-to-card" @click="scrollToProperty" />
    <flip-card :onCardFlip="cardFlipHandler">
      <template #flipCardFront="{ flipCard }">
        <div class="pa-4 d-flex column front-card-content shadow">
          <v-flex>
            <div
              class="header-sm font-weight-bold property-option-address"
              v-html="propertyAddress"
            />
          </v-flex>
          <v-flex xs12 pt-2 class="text-base">
            <v-layout>
              <div>{{ propertiesAvailableRoomsText }}</div>
            </v-layout>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-layout>
              <div
                v-ripple
                class="property-image-container relative"
                @click="viewProperty"
                :style="{ backgroundImage: `url(${propertyImage})` }"
              >
                <div class="price-range-overlay subheader-sm">Starting at ${{ minPrice }}</div>
                <v-btn absolute top right small icon color="white" v-if="availableRoomCount">
                  <v-icon color="charcoal" size="20">open_in_new</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-flex>
          <div class="relative mt-3">
            <div class="front-card-background" />
            <v-flex xs12 v-if="property.roommates.length">
              <div class="text-xs-center mt-3 mb-3 lead">roommates</div>
              <v-layout wrap>
                <v-flex
                  xs3
                  v-for="(roommate, i) in property.roommates"
                  :key="i"
                  class="text-xs-center my-1"
                >
                  <v-avatar class="relative">
                    <img
                      class="roommate-image"
                      :src="
                        roommate.profile_image_url ||
                        'https://assets.bungalow.com/avatars/pineapple-avatar-1.png'
                      "
                      onerror="this.onerror=null;this.src='https://assets.bungalow.com/avatars/pineapple-avatar-1.png';"
                    />
                  </v-avatar>
                  <div class="mt-2 roommate-first-name subheader-sm">
                    {{ roommate.first_name }}
                  </div>
                </v-flex>
              </v-layout>
              <v-divider class="mt-3" />
            </v-flex>
            <v-flex v-else class="text-xs-center">
              <div class="mt-3">
                <b>You'd be the first person to live here!</b>
              </div>
              <div class="mt-1">But we'll find you some amazing people to live with</div>
            </v-flex>
            <v-flex
              xs12
              pt-4
              v-if="roommate_living_preferences && roommate_living_preferences.length"
            >
              <v-layout class="text-xs-center text-small">
                <div v-html="constructedRoommateLivingPreferences" />
              </v-layout>
            </v-flex>
            <v-flex xs12 pt-2 v-if="roommate_shared_interests && roommate_shared_interests.length">
              <div class="lead text-xs-center mb-3 mt-1">we like</div>
              <v-layout class="text-xs-center">
                <div>
                  <v-chip
                    v-for="(interest, i) in roommate_shared_interests"
                    :key="i"
                    outline
                    color="#d9d9d9"
                    text-color="#413C3B"
                  >
                    <v-img height="15" width="15" :src="interest.icon" class="mr-2" />
                    <b>
                      {{ interest.label }}
                    </b>
                  </v-chip>
                </div>
              </v-layout>
            </v-flex>
            <PropertyCardActionsTest
              v-if="showPropertyActions"
              :loading="loading"
              :propertyDisplayState="propertyDisplayState"
              :flipCard="flipCard"
              :showRoomSelectionClickHandler="showPropertyRoomDetails"
              :availableRooms="availableRooms"
              :minPrice="minPrice"
              :maxPrice="maxPrice"
              :confirmPropertySelection="confirmPropertySelection"
              :confirmPropertySelectionDisabled="selectHouseButtonDisabled"
            />
            <div v-else class="mt-4">
              <v-layout my-4>
                <v-flex d-flex justify-center align-center>
                  <blw-icon :icon="unavailablePropertyIcon" color="red" />
                </v-flex>
              </v-layout>
              <v-layout mt-3>
                <v-flex class="text-xs-center">
                  {{ unavailablePropertyText }}
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </template>
      <template #flipCardBack="{ flipCard, reCalculateHeight }">
        <SelectedPropertyCardDetails
          :reCalculateHeight="reCalculateHeight"
          :selectedProperty="property"
          :hidePropertyDetails="() => flipCardAndScroll(flipCard)"
        />
      </template>
    </flip-card>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { get as _get } from 'lodash'
import PropertyCardActionsTest from './PropertyCardActions'
import SelectedPropertyCardDetails from './SelectedPropertyCardDetails'
import FlipCard from '@/shared/FlipCard'

export default {
  name: 'PropertyCard',
  components: {
    PropertyCardActionsTest,
    FlipCard,
    SelectedPropertyCardDetails,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    scrollToProperty: {
      type: Function,
      required: true,
    },
    showPropertyRoomDetails: {
      type: Function,
    },
    headline: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
    },
    total_room_count: {
      type: Number,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    market_slug: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    availableRooms: {
      type: Array,
      required: true,
    },
    minPrice: {
      type: Number,
    },
    maxPrice: {
      type: Number,
    },
    petFriendly: {
      type: Boolean,
    },
    masterBedroomAvailable: {
      type: Boolean,
    },
    roommate_shared_interests: {
      type: Array,
    },
    roommate_living_preferences: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('application', ['homeSelectionPropertyDetails', 'applicationMarketSlug']),
    ...mapGetters('leasePreference', [
      'leasePreferencePetFriendly',
      'leasePreferenceMasterBedroom',
    ]),
    constructedRoommateLivingPreferences() {
      return `We're looking for someone who ${this.roommate_living_preferences
        .map((preference) => `<u>${preference.top_response}</u>`)
        .join(' and ')}`
    },
    availableRoomCount() {
      return this.availableRooms.length
    },
    showPropertyActions() {
      let shouldShowPropertyActions = true
      if (this.leasePreferencePetFriendly && !this.petFriendly) {
        shouldShowPropertyActions = false
      }
      if (this.leasePreferenceMasterBedroom && !this.masterBedroomAvailable) {
        shouldShowPropertyActions = false
      }
      if (!this.availableRoomCount) {
        shouldShowPropertyActions = false
      }
      return shouldShowPropertyActions
    },
    unavailablePropertyText() {
      if (!this.availableRoomCount) {
        return 'This home is already full'
      }
      if (this.leasePreferencePetFriendly && !this.petFriendly) {
        return 'Pets not allowed in this home'
      }
      if (this.leasePreferenceMasterBedroom && !this.masterBedroomAvailable) {
        return 'Master bedroom no longer available'
      }
      return null
    },
    unavailablePropertyIcon() {
      if (!this.availableRoomCount) {
        return 'home-selection/full-house'
      }
      if (this.leasePreferencePetFriendly && !this.petFriendly) {
        return 'home-selection/no-pets'
      }
      if (this.leasePreferenceMasterBedroom && !this.masterBedroomAvailable) {
        return 'home-selection/no-master-bedroom'
      }
      return null
    },
    // @TODO re-introduce region_code when the salesforce sync fixes the bad data issue
    propertyAddress() {
      return `${this.address.street_address}<br> ${this.address.city}, ${this.address.postal_code}`
    },
    propertyDisplayState() {
      return this.homeSelectionPropertyDetails(this.property.id)
    },
    selectHouseButtonDisabled() {
      return !this.availableRoomCount || !this.availableRooms.find((room) => room.selected)
    },
    propertiesAvailableRoomsText() {
      return `${this.availableRoomCount} of ${this.total_room_count} room${
        this.total_room_count > 1 ? 's' : ''
      } available`
    },
    propertyImage() {
      return _get(
        this.images,
        '[0].sm_url',
        'https://dbjpekgzfghzs.cloudfront.net/18c2384c5e8b704748639b731725996f18460ac0-md.jpg'
      )
    },
  },
  methods: {
    ...mapActions('application', [
      'selectPropertyToConfirm',
      'updateApplication',
      'fetchApplicationBySource',
    ]),
    cardFlipHandler(frontFacingSide) {
      this.$trackEvent({
        action: `Card Flipped (to ${frontFacingSide})`,
        properties: {
          location: 'Flip Card',
          category: 'Home Selection',
          type: 'Button',
        },
      })
    },
    flipCardAndScroll(flipCard) {
      flipCard()
      setTimeout(() => {
        window.scrollTo({
          top: this.$refs.propertyCardRef.scrollHeight - 315,
          behavior: 'smooth',
        })
      }, 800)
    },
    async confirmPropertySelection() {
      this.loading = true

      this.selectPropertyToConfirm(this.property.id)

      await this.updateApplication()

      this.$routerPush({ name: 'homeSelectionConfirm' })

      this.loading = false
    },
    viewProperty() {
      this.$trackEvent({
        action: 'View Property',
        properties: {
          location: 'Property Card',
          color: 'White',
          CTA: 'open_in_new_icon',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.availableRoomCount &&
        this.$root.$emit('showIframe', {
          srcUrl: `${process.env.VUE_APP_BUNGALOW_WEBSITE_BASE_URL}listings/${this.applicationMarketSlug}/${this.slug}?preview_mode=true`,
          closeTopBarHeight: this.$vuetify.breakpoint.smAndDown ? '61px' : '100px',
        })
    },
  },
}
</script>

<style lang="scss">
.property-card-slide-to-card {
  display: none;
}
.swiper-slide:not(.swiper-slide-active) {
  .property-card-slide-to-card {
    transition: background 0.5s;
    background: #00000000;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
}
.property-option {
  z-index: 1;
  background: white;

  .front-card-background {
    border-top: 1px solid #e6e3e380;
    position: absolute;
    top: 0px;
    left: -30px;
    height: calc(100% + 30px);
    width: calc(100% + 60px);
    background: #e6e3e333;
    pointer-events: none;
  }

  .room-select-icon-wrapper {
    border: 1px solid $charcoal-40;
    height: 2.5rem;
    width: 2.5rem;
  }
  .roommate-first-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .property-image-container {
    height: 157px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .price-range-overlay {
      background: #24201f66;
      border-radius: 4px;
      color: white;
      margin: 5px;
      width: max-content;
      padding: 3px 10px;
    }
    .v-btn {
      border-radius: 5px;
      top: 5px;
      right: 5px;
    }
  }
  .v-btn {
    padding: 0;
  }
  .v-btn__content {
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }

  .v-icon,
  .blw-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.no-rooms-available .property-image {
    filter: grayscale(100%);
    opacity: 0.6;
  }
  .front-card-content {
    background: white;
  }
  .property-option-address {
    // importart to override vuetify
    line-height: 1.875rem !important;
  }
}
</style>
