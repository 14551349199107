<template>
  <div class="profile-edit">
    <blw-form
      :updating="updating"
      sticky-footer
      custom-slot-container
      :updatingText="fileURL && 'Upload in progress'"
      :onSubmit="updateProfileAndContinue"
    >
      <v-container>
        <!-- Profile Avatar -->
        <div :class="['profile-photo', { 'has-photo': profileImage || fileURL }]">
          <blw-avatar
            v-if="profileImage || fileURL"
            class="mb-4 mx-auto round"
            :avatar="fileURL || profileImage"
          />
          <div v-else class="photo-upload mx-auto">
            <small class="font-weight-bold">Add profile image</small>
          </div>
          <blw-upload-button
            class="profile-image-upload-btn"
            v-model="fileToUpload"
            :onFileChanged="newFileSelected"
          />
          <v-layout align-center justify-center class="save-helper">
            <template v-if="fileError">
              <span class="font-red">{{ fileError }}</span>
            </template>
            <template v-else-if="fileURL && fileSize">
              <v-icon size="18" class="mr-1">cloud_upload</v-icon>
              {{ fileSize }}
            </template>
          </v-layout>
        </div>

        <v-layout row wrap>
          <v-flex xs6>
            <blw-text-field
              label="First name"
              v-model="first_name"
              :rules="[maxLength(50), required]"
            />
          </v-flex>
          <v-flex xs6>
            <blw-text-field
              label="Last name"
              v-model="last_name"
              :rules="[maxLength(50), required]"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <blw-text-field label="Job title" v-model="title" :rules="[maxLength(100), required]" />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <blw-text-field
              label="Employer name"
              v-model="employer"
              :rules="[maxLength(100), required]"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <blw-text-area
              :rules="[required, maxLength(250)]"
              label="Short bio"
              hint="Will be shared with other members of your Bungalow"
              v-model="bio"
              counter="250"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-container class="interests-container">
        <v-layout column>
          <h2 class="lead text-left">Interests</h2>
          <interests-grid
            list
            editable
            read-only
            :interests="selected"
            :interestsRoute="interestsRoute"
            :onRemoveInterest="removeInterestHandler"
          />
        </v-layout>
      </v-container>
    </blw-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { maxLength, required } from '@/utils/formValidations'

import InterestsGrid from '../components/InterestsGrid'

export default {
  name: 'ProfileEdit',
  components: { InterestsGrid },
  props: {
    nextRoute: {
      type: String,
      required: true,
    },
    interestsRoute: {
      type: String,
    },
  },
  data() {
    return {
      updating: false,
      fileToUpload: null,
      fileSize: false,
      fileError: false,
    }
  },
  computed: {
    fileURL() {
      return this.fileToUpload ? URL.createObjectURL(this.fileToUpload) : this.profileImage
    },
    ...mapGetters('profile', ['profile', 'profileImage']),
    ...mapGetters('interests', ['selected']),
    ...mapGetters('user', ['userId']),
    ...mapGetters('documents', ['document']),
    ...mapFields('profile', [
      'profile.first_name',
      'profile.last_name',
      'profile.bio',
      'profile.employment_history[0].employer',
      'profile.employment_history[0].title',
    ]),
  },
  methods: {
    maxLength,
    required,
    ...mapActions('profile', ['updateProfile', 'fetchProfile']),
    ...mapActions('interests', ['toggleInterest']),
    ...mapActions('documents', ['createDocument']),
    removeInterestHandler(interest) {
      this.trackInterestChange(interest)
      this.toggleInterest(interest.id)
    },
    trackInterestChange(interest) {
      this.$trackEvent({
        action: 'Remove Interest',
        properties: {
          location: 'Profile Edit',
          color: 'Salmon',
          CTA: 'X icon',
          text: interest.label,
          category: 'Profile',
          type: 'Icon',
        },
      })
    },
    newFileSelected({ fileSize, error }) {
      this.fileError = error
      if (fileSize) this.fileSize = fileSize

      this.$trackEvent({
        action: 'New Profile Image Selected',
        properties: {
          location: 'Profile Edit',
          color: 'White',
          text: 'Edit profile photo',
          CTA: '+',
          category: 'Profile',
          type: 'Button',
        },
      })
    },
    async updateProfileAndContinue() {
      this.updating = true

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Edit Profile',
          color: 'Salmon',
          text: this.fileURL && 'Upload in progress',
          CTA: 'Save and continue',
          category: 'Profile',
          type: 'Button',
        },
      })

      if (this.fileToUpload) {
        await this.createDocument({
          purpose: 'profile_image',
          file: this.fileToUpload,
        })
      }

      await this.updateProfile()
      await this.fetchProfile()

      this.$routerPush({
        name: this.nextRoute,
        params: {
          userId: this.userId,
          verify: true,
        },
      })

      this.updating = false
    },
  },
}
</script>

<style lang="scss">
.profile-edit {
  margin: 3.75rem auto;

  .save-helper {
    text-align: center;
    font-size: 12px;
    color: $text-lighter;
    padding-top: 0.5rem;

    .v-icon {
      color: $text-lighter !important;
    }
  }

  .profile-photo {
    margin: auto;
    margin-bottom: 3.75rem;
    position: relative;
    width: 150px;
    height: 150px;

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    &:not(.has-photo) {
      &::after {
        display: none;
      }
    }

    &::after {
      transition: opacity 0.25s ease;
      opacity: 0;
      content: 'Edit profile photo';
      font-size: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    .blw-button {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    .photo-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px dashed $text-lighter;
      border-radius: 100%;
      height: 150px;
      width: 150px;

      small {
        font-size: 14px;
      }
    }

    .profile-image-upload-btn {
      z-index: 1;
      position: absolute;
      right: -1rem;
      bottom: 0;
    }
  }

  .interests-container {
    margin-bottom: 2rem;

    .lead {
      margin-bottom: 1rem;
    }

    @media (max-width: 500px) {
      padding: 0 !important;
      max-width: 100%;
      width: 100%;

      .lead {
        padding: 0 2rem;
      }
    }
  }
}
</style>
