<template>
  <blw-form
    :onSubmit="onSubmit"
    :iconComponent="SubmitScreeningIcon"
    header="Secure background check"
    text="We need a valid SSN in order to approve all co-signers"
    custom-slot-container
  >
    <section class="white mb-2">
      <v-container py-2>
        <v-flex xs12 mb-3>
          <component :is="SubmitSecureIcon" />
          <v-flex inline pt-2>Secure Input</v-flex>
        </v-flex>

        <v-layout row wrap>
          <v-flex xs12 sm4 px-1>
            <blw-select
              :items="selectItems"
              :rules="[required]"
              label="Country of issuance"
              v-model="selectValue"
            />
          </v-flex>
          <v-flex column xs12 sm8 px-1 justify-center>
            <blw-text-field
              :label="selectValue === 'US' ? 'Social security number' : 'Social insurance number'"
              :mask="selectValue === 'US' ? '###-##-####' : '###-###-###'"
              :rules="[required, exactLength(9)]"
              validate-on-blur
              v-model="sin_ssn"
            />
          </v-flex>
        </v-layout>

        <p class="font-small grey--text my-3 text-xs-center">
          <strong>This information is, and always will be, kept private.</strong>
        </p>
      </v-container>
    </section>
  </blw-form>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import SubmitScreeningIcon from '@/assets/icons/screening.svg'
import SubmitSecureIcon from '@/assets/icons/secure.svg'

import { required, exactLength } from '@/utils/formValidations'

export default {
  name: 'SubmitScreening',
  props: {
    nextRoute: {
      type: String,
      required: true,
    },
    entityMarketCodeGetter: {
      type: String,
      required: true,
    },
    entityBackgroundCheckGetter: {
      type: String,
      required: true,
    },
    entityUSAddressCheckGetter: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapFields('submit', ['sin_ssn', 'skipSsnEntry']),
    ...mapState({
      entityCountryCode(state, getters) {
        return getters[this.entityMarketCodeGetter]
      },
      entityBackgroundCheck(state, getters) {
        return getters[this.entityBackgroundCheckGetter]
      },
      entityHasUSAddress(state, getters) {
        return getters[this.entityUSAddressCheckGetter]
      },
    }),
    noSSNLabel() {
      return `I do not have a ${this.selectValue} social ${
        this.selectValue === 'US' ? 'security' : 'insurance'
      } number`
    },
  },
  methods: {
    required,
    exactLength,
    onSubmit() {
      if (this.skipSsnEntry) this.sin_ssn = null

      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'Screening Reports',
          CTA: 'Continue',
          category: 'Finalize and Pay',
          type: 'Button',
        },
      })

      this.$routerPush({ name: this.nextRoute })
    },
  },
  data() {
    return {
      SubmitScreeningIcon,
      SubmitSecureIcon,
      selectValue: null,
      selectItems: [
        { text: 'Canada', value: 'CA' },
        { text: 'United States', value: 'US' },
      ],
    }
  },
  beforeMount() {
    // background check has already taken place, do not re-attempt
    if (this.entityBackgroundCheck) {
      return this.$routerReplace({ name: this.nextRoute })
    }
    // if the user has NOT entered a US address
    // do not prompt the user for a SSN number
    // and mark the skipSsnEntry as true
    // this prevents the background check from being submitted
    if (!this.entityHasUSAddress) {
      this.skipSsnEntry = true
      return this.$routerReplace({ name: this.nextRoute })
    }
    this.selectValue = this.entityCountryCode || 'US'
  },
}
</script>

<style lang="scss">
.screening-no-ssn {
  justify-content: center;

  .v-input__slot {
    flex-direction: row-reverse;
    margin-bottom: 0 !important;
    .v-input--selection-controls__input {
      margin: 8px;
    }
  }
}
</style>
