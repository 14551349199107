import Vue from 'vue'

const Routing = {
  install(Vue) {
    Vue.prototype.$routerPush = async function (properties) {
      try {
        await this.$router.push(properties)
      } catch (e) {}
    }
    Vue.prototype.$routerReplace = async function (properties) {
      try {
        await this.$router.replace(properties)
      } catch (e) {}
    }
  },
}

Vue.use(Routing)
