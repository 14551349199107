<template>
  <div class="groups-contact-sales">
    <v-layout :class="horizontal ? 'justify-center' : 'column'" align-center>
      <div :class="['headshot br-full', horizontal ? 'mr-3' : 'mb-2']" />
      <div :class="horizontal ? 'text-xs-left' : 'text-xs-center'">
        <p class="font-bold">{{ tagline }}</p>
        <a class="font-base" href="mailto:homes@bungalow.com">homes@bungalow.com</a>
        <p class="charcoal-40--txt">Available 8am &#8211;&nbsp;5pm PST</p>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'GroupContactSales',
  props: {
    tagline: {
      type: String,
      default: `Questions? Reach out!`,
    },
    horizontal: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
.groups-contact-sales {
  .headshot {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    background-image: url('https://assets.bungalow.com/headshots/ryan.png');
    background-size: 175% 175%;
    background-position: center -16px;
  }
}
</style>
