import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const EMPTY_RESPONSE = { me: false, them: [] }

const getDefaultState = () => ({
  ...stateHelpers,
  questions: [],
  responses: [],
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,

  questions: (state) => state.questions,
  rawResponses: (state) => state.responses,
  responses: ({ questions, responses }) => {
    return questions.reduce((structuredResponses, question) => {
      // Look for matching response from API, or use default (empty)
      const response = responses.find((res) => res.question === question.id) || EMPTY_RESPONSE

      // Build response mappings
      structuredResponses[question.id] = {
        question: question.id,
        me: response.me,
        them: response.them,
      }

      return structuredResponses
    }, {})
  },
}

const actions = {
  fetchQuestions({ commit }) {
    commit('setLoading', true)

    return this._vm.$http
      .get(`/living-preferences/questions/`)
      .then(({ data }) => {
        commit('setQuestions', data.results)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  fetchResponses({ commit }) {
    commit('setLoading', true)

    return this._vm.$http
      .get(`/living-preferences/responses/`)
      .then(({ data }) => {
        commit('setResponses', data.results)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  setYourResponse({ commit, dispatch, getters }, { questionId, answerId }) {
    const existingResponse = getters.responses[questionId]

    const updatedResponse = {
      ...existingResponse,
      me: answerId,
    }

    commit('setResponse', updatedResponse)
    dispatch('updateResponse', updatedResponse)
  },

  setTheirResponse({ commit, dispatch, getters }, { questionId, answerId }) {
    const existingResponse = getters.responses[questionId]
    const theirResponses = new Set(existingResponse.them)

    // Remove the  if already selected, or add if new
    theirResponses.has(answerId) ? theirResponses.delete(answerId) : theirResponses.add(answerId)

    // Build API Schema
    const updatedResponse = {
      ...existingResponse,
      them: [...theirResponses],
    }

    commit('setResponse', updatedResponse)
    dispatch('updateResponse', updatedResponse)
  },

  updateResponse({ commit }, updatedResponse) {
    commit('setLoading', true)

    return this._vm.$http
      .post(`/living-preferences/responses/`, updatedResponse)
      .then(({ data }) => {
        commit('setResponse', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  resetResponses({ commit }) {
    commit('setResponses', [])
  },
}

const mutations = {
  ...mutationHelpers,
  setQuestions: (state, payload) => (state.questions = payload),
  setResponses: (state, payload) => (state.responses = payload),
  setResponse: (state, updatedResponse) => {
    // Find the index of the response to update...
    const responseIndexToUpdate = state.responses.findIndex((response) => {
      if (response.question === updatedResponse.question) return response
    })

    // Clone to ensure reactivity
    const updatedResponses = [...state.responses]

    // Update the response in-place, or add if new:
    responseIndexToUpdate !== -1
      ? (updatedResponses[responseIndexToUpdate] = updatedResponse)
      : updatedResponses.push(updatedResponse)

    state.responses = updatedResponses
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
