<template>
  <section class="mb-3">
    <div class="d-flex align-items-center">
      <h2 class="font-xl pt-4 pb-2 font-bold section-title">
        <IncomeBadge width="28" class="mr-2" />
        Income Verification
        <blw-tooltip
          class="charcoal-30--txt"
          title="⚡️ Easily Verify Your Income"
          tooltip="When you connect your employer or upload pay stub(s), we'll automatically verify your income. This helps us determine your eligibility for a lease."
        ></blw-tooltip>
      </h2>
      <!-- Argyle Badge -->
      <div class="argyle-badge mt-2">
        <small class="lead charcoal-30--txt" style="font-size: 10px"> Powered by </small>
        <svg
          :width="$vuetify.breakpoint.smAndDown ? 70 : 84"
          height="28"
          viewBox="0 0 84 28"
          class="Header__StyledArgyleLogo-sc-hxjhfh-4 epaEcr"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="#000">
              <g>
                <path
                  d="M14.029 13.5c0-3.142-2.252-5.76-5.572-5.76-3.002 0-5.455 2.59-5.455 5.76 0 3.027 2.453 5.674 5.455 5.674 3.118 0 5.572-2.619 5.572-5.674zm-.059 8.03V19.03h-.057c-1.126 1.92-3.147 2.91-5.427 2.91C3.81 21.938 0 18.388 0 13.732c0-5.15 3.666-8.758 8.37-8.758 2.339 0 4.302.873 5.543 2.706h.057V5.412h3.002v16.119H13.97zM19.687 21.53V5.413h2.8v1.454h.058c.722-1.076 1.963-1.774 4.07-1.89v3.112c-2.597.088-3.926 1.688-3.926 4.394v9.049h-3.002zM40.817 13.471c0-3.782-2.857-5.732-5.628-5.732-2.973 0-5.484 2.59-5.484 5.732 0 3.085 2.54 5.703 5.513 5.703 3.493 0 5.6-3.084 5.6-5.703zm-.057 5.936c-1.242 1.688-3.233 2.532-5.513 2.532-4.59 0-8.544-3.987-8.544-8.351 0-4.86 3.752-8.613 8.572-8.613 2.079 0 4.272.873 5.542 2.3V5.411h3.002v13.995c0 5.237-3.204 8.467-8.63 8.467-3.666 0-6.552-1.95-7.91-5.092h3.35c1.125 1.6 2.626 2.328 4.589 2.328 3.666 0 5.6-2.008 5.6-5.703h-.058zM49.329 26.914L51.666 21.211 45.287 5.412 48.52 5.412 53.225 17.923 58.045 5.412 61.278 5.412 52.445 26.914zM62.756 21.531L65.758 21.531 65.758 0 62.756 0zM80.998 12.453c-.231-2.735-2.627-4.714-5.195-4.714-2.454 0-4.908 1.95-5.139 4.714h10.334zM70.664 14.78c.693 2.677 2.8 4.393 5.254 4.393 1.876 0 3.637-.99 4.56-2.706h3.003c-1.242 3.318-4.244 5.47-7.678 5.47-4.503 0-8.14-4.015-8.14-8.322 0-5.09 3.81-8.64 8.053-8.64C80.421 4.975 84 8.64 84 13.267c0 .494 0 .96-.086 1.513h-13.25z"
                  transform="translate(-40 -31) translate(40 31)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <p class="font-large charcoal-40--txt mb-3">
      Connect your employer or upload pay stub(s) to instantly verify your income.
    </p>
    <v-layout row wrap>
      <!-- Argyle Badge -->
      <v-flex> </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex class="d-flex align-items-center">
        <v-btn
          block
          class="mr-3"
          :style="{ maxWidth: $vuetify.breakpoint.smAndDown ? '100%' : '250px' }"
          color="primary"
          @click="() => launchFlow('connect')"
          :disabled="argyleIsPending"
          :loading="argyleIsPending"
        >
          {{ $vuetify.breakpoint.smAndDown ? 'Connect' : 'Connect Account' }}
        </v-btn>
        <v-btn
          block
          :style="{ maxWidth: $vuetify.breakpoint.smAndDown ? '100%' : '250px' }"
          color="primary"
          @click="() => launchFlow('upload')"
          :disabled="argyleIsPending"
          :loading="argyleIsPending"
        >
          {{ $vuetify.breakpoint.smAndDown ? 'Upload' : 'Upload Documents' }}
        </v-btn>
      </v-flex>
    </v-layout>
    <div
      :class="[
        'd-flex align-center mt-2 pt-1 message-slot',
        { visible: hasConnectedAccounts || requiredError },
      ]"
    >
      <template v-if="requiredError">
        <p class="red--txt font-base">
          <b>Required:</b> please provide at least one source of income.
        </p>
      </template>
      <template v-else-if="hasConnectedAccounts">
        <v-icon medium color="green" v-max-width="30">check_circle</v-icon>
        <p class="pl-2 charcoal-40--txt">You've provided at least one source of income!</p>
      </template>
    </div>
    <div v-if="!hasConnectedAccounts" class="d-flex">
      <div style="flex: 0 !important; padding-top: 1px" class="mr-3">
        <blw-tooltip
          class="charcoal-30--txt"
          tooltip="After submission, we'll work with you to verify your income."
        />
      </div>
      <v-checkbox
        v-model="byPass"
        hide-details
        class="reverse-checkbox"
        label="Complete this later."
        @change="toggleByPass"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadScript } from 'vue-plugin-load-script'
import { delay } from 'lodash'
import IncomeBadge from '@/assets/badges/income.svg'

export default {
  name: 'IncomeVerification',
  components: { IncomeBadge },
  props: {
    onAccountConnected: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    requiredError: {
      type: Boolean,
      default: false,
    },
    toggleByPass: {
      type: Function,
      required: true,
    },
  },
  async beforeMount() {
    await loadScript('https://plugin.argyle.com/argyle.web.v5.js')
    this.getArgyleUser()
  },
  data() {
    return {
      byPass: false,
      argyleIsPending: false,
      hasConnectedAccounts: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
  },
  methods: {
    async launchFlow(linkType) {
      this.argyleIsPending = true
      this.launchArgyleLink(linkType)
    },
    async getArgyleUser() {
      const { argyle_access_token, webhook_accounts_added_at } = await this.$http
        .get('/argyle/user')
        .then(({ data }) => data)

      // If the user already has connected income sources to an Argyle account, they can submit!
      if (webhook_accounts_added_at) {
        this.hasConnectedAccounts = true
        this.onAccountConnected()
      }

      // We need the token to open the Argyle flow, so throw an error if we don't have one
      if (argyle_access_token) {
        this.userToken = argyle_access_token
      } else {
        // UX delay...
        await delay(500)
        this.onError('Unable to generate user token')
      }
    },
    launchArgyleLink(linkType = 'connect') {
      const customizationId =
        linkType === 'upload'
          ? process.env.VUE_APP_ARGYLE_UPLOAD_FLOW_ID
          : process.env.VUE_APP_ARGYLE_CONNECT_FLOW_ID
      const argyle = window.Argyle.create({
        customizationId,
        linkKey: process.env.VUE_APP_ARGYLE_LINK_KEY,
        sandbox: process.env.VUE_APP_TARGET !== 'production',
        // Authentication token used to connect the user to their previous session/account
        userToken: this.userToken,
        // Add Link item IDs (e.g. 'Starbucks') if you want to constrain the Link item list in the search screen
        // linkItems: [],
        onUserCreated: () => {
          /*
          A callback function invoked when a new user is created.
          This callback function provides userId and userToken. The user object is created on the first attempt of a new user to connect an account.
          */
        },
        onAccountCreated: () => {
          /*
          A callback function invoked immediately after a user clicks connect and a new account is created. The callback will be invoked before authenticating the account with the Link item.
          This callback function provides accountId, userId, and linkItemId.
          */
        },
        onAccountConnected: (args) => {
          /*
            A callback function invoked every time a new account is successfully authenticated with a Link item, including any multi-factor authentication requests. If your linkItems list contains only one Link item, it is safe to close Argyle Link at this point.
            This callback function provides accountId, userId, and linkItemId.
          */
          this.hasConnectedAccounts = true
          this.$trackEvent({ action: 'Argyle Income Source Connected' })
          this.onAccountConnected(args)
        },
        onDocumentsSubmitted: (args) => {
          /*
          Invoked when the user selects Submit after uploading documents through Link's document upload workflow.
          */
          this.hasConnectedAccounts = true
          this.$trackEvent({ action: 'Argyle Income Document Connected' })
          this.onAccountConnected(args)
        },
        onTokenExpired: () => {
          // When providing `userToken` to Argyle, this callback MUST be defined to prevent an error.
          this.getArgyleUser()
        },
        onError: (args) => {
          /*
          A callback function invoked when Link encounters an internal problem that breaks the flow for the user.
          This callback function provides the type of the error that occurred.
          Possible values: INVALID_LINK_ITEMS, INVALID_PD_CONFIG, INVALID_LINK_KEY, INVALID_USER_TOKEN, GENERIC
          For more details and troubleshooting, see: https://docs.argyle.com/guides/docs/link-errors
          */
          this.onError(args)
        },
        onClose: (args) => {
          this.argyleIsPending = false
          /* A callback function invoked immediately after the user closes Link. */
          this.onClose(args)
          this.$trackEvent({ action: 'Argyle Closed' })
        },
      })
      // Open Argyle Modal Experience
      argyle.open()
      this.$trackEvent({ action: 'Argyle Started' })
    },
  },
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
}

.argyle-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
}

.message-slot {
  transition: opacity 0.5s ease;
  opacity: 0;
  min-height: 30px;

  &.visible {
    opacity: 1;
  }
}
</style>
