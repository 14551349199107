<template>
  <loading-spinner full-page v-if="loading" />
  <income-intro
    :updateEntityFunction="updateCosignerByKey"
    nextPath="cosignIncomeEmployment"
    v-else-if="!income"
  />
  <router-view v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import LoadingSpinner from '@/shared/LoadingSpinner'
import IncomeIntro from '@/routes/applicantIncome/components/IncomeIntro'

export default {
  name: 'CosignerIncomeContainer',
  components: {
    IncomeIntro,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters('income', ['income', 'loading']),
    ...mapGetters('cosigner', ['cosignerIncomeId']),
  },
  methods: {
    ...mapActions('income', ['fetchIncome']),
    ...mapActions('cosigner', ['updateCosignerByKey']),
  },
  beforeMount() {
    this.fetchIncome(this.cosignerIncomeId)
  },
}
</script>
