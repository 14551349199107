<template>
  <v-container class="group-needs-cosigner" v-max-width="478">
    <v-layout
      column
      :class="['text-xs-center pt-5', $vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-5']"
    >
      <span class="header-xl">👋</span>
      <h1 v-max-width="350" class="header-md mt-4 mx-auto">You need a co-signer to get approved</h1>

      <p class="px-3 mt-4">
        We've reviewed your application and a
        <b>co-signer is required to be approved for a lease</b>
      </p>

      <div class="mt-5 mx-auto w-100" v-max-width="290">
        <blw-button @click="nominateCosigners" color="charcoal">+ Add your co-signer(s)</blw-button>
      </div>

      <hr class="my-5" />

      <GroupContactSales />
    </v-layout>
  </v-container>
</template>

<script>
import GroupContactSales from '../components/GroupContactSales'

export default {
  name: 'GroupNeedsCosigner',
  components: { GroupContactSales },
  methods: {
    nominateCosigners() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          category: 'group_applications',
          CTA: 'group_invite_cta',
          text: 'Invite & Continue',
        },
      })

      this.$router.replace({ name: 'groupAddCoSigner' }).catch(() => {})
    },
  },
}
</script>
