<template>
  <div class="blw-illustration">
    <v-img :src="imageSource" :width="size" :max-width="size" />
  </div>
</template>

<script>
/*
 *   Available Illustrations can be found in the Bungalow Frontend AWS account in S3:
 *   https://s3.console.aws.amazon.com/s3/buckets/production-static-assets-64774?region=us-east-1&prefix=illustrations/&showversions=false
 */

export default {
  name: 'BlwIllustration',
  data() {
    return {
      baseUrl: 'https://assets.bungalow.com/illustrations',
      loaded: false,
    }
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'full-color',
      validator: (value) => ['full-color', 'spot'].includes(value),
    },
    size: {
      type: String,
      default: '165',
    },
  },
  computed: {
    imageSource() {
      return `${this.baseUrl}/${this.type}/${this.name}.png`
    },
  },
  mounted() {
    this.$el.style.setProperty('--size', `${this.size}px`)
  },
}
</script>

<style lang="scss">
.blw-illustration {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);
}
</style>
