<template>
  <ApplicationIntro
    :currentStep="3"
    :currentStepStartHandler="nextStep"
    blurFutureSteps
    title="Looks great! Can’t wait for you to meet our community"
    subtitle="Explore properties and future roommates to find the best fit for you. Reach out to homes that you are interested in."
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ApplicationIntro from '@/routes/apply/components/ApplicationIntro'

export default {
  name: 'ProfileComplete',
  components: {
    ApplicationIntro,
  },
  mounted() {
    this.$trackEvent({
      action: 'Page viewed',
      properties: {
        type: 'Preview page',
        title: 'Meet your future roommates',
      },
    })
  },
  computed: {
    ...mapGetters('application', ['applicationStatus']),
    waitingForPartner() {
      return this.applicationStatus === 'profile_completed_roommate_pending'
    },
  },
  methods: {
    nextStep() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Preview page',
          title: 'Meet your future roommates',
          CTA: 'Start',
        },
      })

      if (this.waitingForPartner) {
        return this.$routerPush({ name: 'profileWaiting' })
      }

      if (this.applicationStatus === 'meet_and_greet') {
        return this.$routerPush({
          name: 'homeShopping',
        })
      }

      this.$routerPush({
        name: this.applicationStatus === 'profile_completed' ? 'homeSelection' : 'review',
      })
    },
  },
}
</script>
