import { getField, updateField } from 'vuex-map-fields'

import _ from 'lodash'

import { roundPriceToNearestFiveDollars } from '@livebungalow/toolbox/abacus'

import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  application: null,
})

const state = getDefaultState()

const getters = {
  getField,
  ...getterHelpers,

  getAvailablePropertyAddressById:
    ({ application }) =>
    (propertyId) => {
      const property = _.get(application, 'available_properties', []).find(
        ({ property: { id } }) => id === propertyId
      )
      if (property) {
        const { street_address, unit, city, postal_code } = property.property.address
        return `${street_address} ${unit ? `#${unit}` : ''} ${city}, ${postal_code}`
      }
      return null
    },
  hiddenProperties: ({ application }) => _.get(application, 'display_state.hiddenProperties', []),
  applicationInApply1_5Flow: ({ application }) =>
    ['bay-area'].includes(_.get(application, 'market.slug')),
  applicantInfoId: ({ application }) => _.get(application, 'applicant_info'),
  application: ({ application }) => application,
  applicationRequest: ({ application }) => _.get(application, 'application_requests'),
  applicationChargeStatus: ({ application }) => _.get(application, 'charge.status'),
  applicationFee: ({ application }) => _.get(application, 'application_fee', 0),
  applicationFeeIsWaived: ({ application }) => _.get(application, 'waive_application_fee'),
  applicationId: ({ application }) => _.get(application, 'id'),
  applicationMarketName: ({ application }) => _.get(application, 'market.display_name'),
  applicationMarketSlug: ({ application }) => _.get(application, 'market.slug'),
  applicationMarketCountryCode: ({ application }) => _.get(application, 'market.country_code'),
  applicationSourceSlug: ({ application }) => _.get(application, 'source'),
  applicationStatus: ({ application }) => _.get(application, 'status'),
  availableProperties: ({ application }) => {
    const applicationDisplayState = _.cloneDeep(_.get(application, 'display_state', {}))
    return _.get(application, 'available_properties', []).map((property) => {
      const { property: availableProperty } = property
      const { street_address, unit, city, postal_code } = availableProperty.address
      const propertyDisplayState = _.get(
        applicationDisplayState,
        `homeSelection.properties.${availableProperty.id}`,
        {}
      )
      const propertyRooms = _.get(availableProperty, 'rooms', [])
        .filter((room) => !room.has_active_room_selection && room.is_available)
        .map((availableRoom) => {
          return {
            ...availableRoom,
            selected: availableRoom.id === propertyDisplayState.roomId,
          }
        })
      const propertyMinPrice =
        propertyRooms.length &&
        propertyRooms.reduce(
          (min, newProperty) =>
            (newProperty.discounted_price || newProperty.price[0]) < min
              ? newProperty.discounted_price || newProperty.price[0]
              : min,
          propertyRooms[0].discounted_price || propertyRooms[0].price[0]
        )
      const propertyMaxPrice =
        propertyRooms.length &&
        propertyRooms.reduce(
          (min, newProperty) =>
            (newProperty.discounted_price || newProperty.price[0]) > min
              ? newProperty.discounted_price || newProperty.price[0]
              : min,
          propertyRooms[0].discounted_price || propertyRooms[0].price[0]
        )
      const petFriendly = !!availableProperty.amenities.find(
        ({ display_name }) => display_name === 'Pet Friendly'
      )
      const masterBedroomAvailable = !!propertyRooms.find(({ room_amenities }) => {
        return !!room_amenities.find(({ display_name }) => display_name === 'Master Bedroom')
      })
      availableProperty.availableRooms = propertyRooms
      availableProperty.minPrice = roundPriceToNearestFiveDollars(propertyMinPrice)
      availableProperty.maxPrice = roundPriceToNearestFiveDollars(propertyMaxPrice)
      availableProperty.petFriendly = petFriendly
      availableProperty.masterBedroomAvailable = masterBedroomAvailable
      availableProperty._formattedAddress = `${street_address} ${
        unit ? `#${unit}` : ''
      } ${city}, ${postal_code}`
      property.property = availableProperty
      return property
    })
  },
  cosignerSources: ({ application }) => _.get(application, 'cosigner_sources'),
  getCosignerSourceById:
    ({ application }) =>
    (cosignerId) =>
      _.find(_.get(application, 'cosigner_sources', []), { id: cosignerId }),
  property: ({ application }) => _.get(application, 'property', 'foobar'),
  selectedRoom: ({ application }) =>
    _.find(_.get(application, 'available_rooms', []), { id: _.get(application, 'room') }),
  displayState: ({ application }) => _.get(application, 'display_state'),
  isGroupApplication: ({ application }) => _.get(application, 'group_source'),
  groupProperty: (state, { availableProperties }) =>
    availableProperties.find(({ property }) => property.property_marketing_type === 'group_living'),
  homeSelectionDisplayState: ({ application }) => _.get(application, 'display_state.homeSelection'),
  homeSelectionPropertyDetails:
    ({ application }) =>
    (propertyId) =>
      _.get(_.cloneDeep(application), `display_state.homeSelection.properties.${propertyId}`),
  sectionStarted:
    ({ application }) =>
    (section) =>
      _.get(application, `display_state.sectionStatus.${section}`) === 'started',
  doubleDepositTextConditionalByRegion: ({ application }) =>
    ['DC', 'MA', 'IL'].includes(_.get(application, 'market.region_code'))
      ? "Pay your last month's rent up front"
      : 'Provide a double deposit',
  confirmRoomSelectionData: ({ application }) => {
    const applicationClone = _.cloneDeep(application)
    const selectedProperty = _.get(applicationClone, 'display_state.homeSelection.selectedProperty')
    if (selectedProperty) {
      const propertyDisplayState = _.get(
        application,
        `display_state.homeSelection.properties.${selectedProperty}`
      )
      const propertyDetails = _.get(applicationClone, 'available_properties', []).find(
        ({ property }) => property.id === selectedProperty
      )
      const roomDetails = _.get(propertyDetails, 'property.availableRooms', []).find(
        ({ id }) => id === propertyDisplayState.roomId
      )
      return {
        leaseDetails: _.get(
          applicationClone,
          `display_state.homeSelection.properties.${selectedProperty}`
        ),
        roomDetails,
        propertyDetails,
      }
    } else {
      return null
    }
  },
  applicationPreApprovedMaxRent: ({ application }) => _.get(application, 'preapproved_max_rent'),
  applicationPreApprovedForAll: ({ application }) => _.get(application, 'preapproved_for_all'),

  applicationLeasePreferenceId: ({ application }) => _.get(application, 'lease_preference'),

  meetingId: ({ application }) => _.get(application, 'open_meeting'),

  // Application Statuses: https://bit.ly/2s6W34u
  sectionsByStatus: () => ({
    pre_meet_and_greet: 'preMeetAndGreet',
    // Apply
    open: 'apply',
    rejected: 'review',
    // Review
    approved: 'review',
    submitted: 'submitSuccess',
    submitted_request_pending: 'review',
    preapproved_roommate_pending: 'review',
    lease_sent: 'review',
    // Profile
    preapproved: 'profile',
    profile_completed_request_pending: 'profile',
    profile_completed_roommate_pending: 'profile',
    // Home Selection
    profile_completed: 'homeSelection',
    // Apply V1.5 routes
    meet_and_greet: 'homeShopping',
    // Meet & Greet
    room_selected: 'meetGreet',
  }),
}

const actions = {
  fetchApplication({ commit }, applicationId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/applications/${applicationId}`)
      .then(({ data }) => {
        commit('setApplication', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  fetchApplicationBySource({ commit }, source) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`applications/applications/?source__slug=${source}`)
      .then(({ data }) => _.get(data, 'results.0'))
      .then((applicationResult) => {
        commit('setApplication', applicationResult)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  makeApplication({ commit }, source_slug) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post('applications/applications', {
        source: source_slug,
      })
      .then(({ data }) => {
        commit('setApplication', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  updateApplication({ commit, getters }) {
    const { application } = getters

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/applications/${application.id}`, application)
      .then(({ data }) => {
        commit('setApplication', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  updateApplicationByKey({ commit, getters }, { field, value }) {
    const { application } = getters

    const payload = { ...application, [field]: value }

    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/applications/${application.id}`, payload)
      .then(({ data }) => {
        commit('setApplication', data)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  updateApplicationRequest({ commit }, { requestId, payload }) {
    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .put(`applications/request/${requestId}/`, payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  acceptDoubleDepositForApplication({ commit }, applicationId) {
    commit('setUpdating', true)
    commit('setError', false)

    return this._vm.$http
      .post('applications/double-deposit/', { application: applicationId })
      .then(() => {
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setUpdating', false))
  },
  initializeHomeSelectionDisplayStateData({ commit, getters }) {
    const displayState = _.cloneDeep(getters.displayState)
    const availableProperties = _.cloneDeep(getters.availableProperties)

    displayState.homeSelection = {}
    displayState.homeSelection.properties = {}
    displayState.homeSelection.selectedProperty = null

    availableProperties.forEach(({ property: availableProperty }) => {
      const propertyDisplayState = _.cloneDeep(
        _.get(displayState, `homeSelection.properties.${availableProperty.id}`, {})
      )
      propertyDisplayState.roomId = null
      propertyDisplayState.lease_end_date = null
      propertyDisplayState.move_in_date = null
      displayState.homeSelection.properties[availableProperty.id] = propertyDisplayState
    })
    commit('setDisplayState', displayState)
  },
  updateDisplayState({ commit }, newDisplayState) {
    commit('setDisplayState', newDisplayState)
  },
  updatePropertyDisplayState({ commit }, config) {
    commit('setPropertyDisplayState', config)
  },
  selectPropertyToConfirm({ commit, getters }, propertyId) {
    const displayState = _.cloneDeep(getters.displayState)

    displayState.homeSelection.selectedProperty = propertyId

    commit('setDisplayState', displayState)
  },
  saveApplicationRoomSelection({ commit, getters }, roomId) {
    commit('setLoading', true)
    commit('setError', false)

    const { applicationId } = getters

    return this._vm.$http
      .post('applications/room-selection/', { application: applicationId, room: roomId })
      .then(() => commit('setError', false))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  getSectionByStatus({ getters }, route) {
    try {
      // Get the expected section name by application status:
      const expectedSection = getters.sectionsByStatus[getters.applicationStatus]

      // Inspect the route stack to see if the destination matches what's expected...
      const actualSection = route.matched.reduce((section, route) => {
        if (route.meta.section) section = route.meta.section
        return section
      })

      // If there's a mis-match, return the new target section name
      return expectedSection === actualSection ? false : expectedSection
    } catch (e) {
      console.error(e)
    }
  },
}

const mutations = {
  updateField,
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setApplication(state, application) {
    state.application = application
  },
  setDisplayState(state, newDisplayState) {
    state.application.display_state = newDisplayState
  },
  setPropertyDisplayState(state, { propertyId, data }) {
    state.application.display_state.homeSelection.properties[propertyId] = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
