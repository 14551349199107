import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  document: null,
  documents: {},
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,

  document: ({ document }) => document,
  documents: ({ documents }) => documents,
  documentById:
    ({ documents }) =>
    (documentId) =>
      documents[documentId],
}

const actions = {
  resetDocument({ commit }) {
    commit('setDocument', null)
  },
  fetchDocument({ commit, state }, documentId) {
    const existingDocument = state.documents[documentId]

    if (existingDocument) {
      return commit('setDocument', existingDocument)
    }

    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(`files/${documentId}`)
      .then((result) => {
        commit('setError', false)
        commit('setDocument', result.data)
        commit('saveDocumentToCache', result.data)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  createDocument({ commit }, { purpose, file }) {
    const formData = new FormData()

    commit('setLoading', true)
    commit('setError', false)

    formData.append('purpose', purpose)
    formData.append('file', file)

    return this._vm.$http
      .post('files/', formData)
      .then((result) => {
        commit('setError', false)
        commit('setDocument', result.data)
        commit('saveDocumentToCache', result.data)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  deleteDocument({ commit }, documentId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .delete(`/files/${documentId}`)
      .then(() => {
        commit('deleteDocumentFromCache', documentId)
        commit('setError', false)
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,

  resetState(state) {
    Object.assign(state, getDefaultState())
  },

  setDocument: (state, document) => (state.document = document),
  saveDocumentToCache: (state, document) => {
    const documents = { ...state.documents }
    documents[document.id] = document
    state.documents = documents
  },
  deleteDocumentFromCache: (state, documentId) => delete state.documents[documentId],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
