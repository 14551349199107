<template>
  <div :class="['blw-spinner', { 'full-page': fullPage, 'app-loader': appLoader }]">
    <template v-if="fullPage">
      <v-layout fill-height justify-center align-center>
        <v-progress-circular v-bind="$props" />
      </v-layout>
    </template>

    <v-progress-circular v-else v-bind="$props" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    size: {
      type: String,
      default: '125',
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    appLoader: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
.blw-spinner {
  &.full-page {
    height: 50vh;
  }
  &.app-loader {
    z-index: 100;
    background: #f8f7ec;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
}
</style>
