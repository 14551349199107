<template>
  <v-snackbar id="snackbar" v-model="visible" :timeout="timeout" :color="color">
    <b>{{ text }}</b>
  </v-snackbar>
</template>

<script>
const defaultState = () => ({
  color: 'success',
  text: '',
  timeout: 2000,
})
export default {
  name: 'Snackbar',
  data: () => ({
    visible: false,
    ...defaultState(),
  }),
  mounted() {
    this.$root.$on('showSnackbar', this.updateSnackbarConfig)
  },
  methods: {
    updateSnackbarConfig(config) {
      for (const [key, value] of Object.entries({ ...defaultState(), ...config })) {
        this[key] = value
      }
      this.visible = true
    },
  },
}
</script>

<style lang="scss">
#snackbar {
  z-index: 10000001;
}
</style>
