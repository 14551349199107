const GoogleAddressAutoCompleteMixin = {
  methods: {
    enableGoogleAutoComplete(fieldSelector) {
      if (window.google) {
        const autocompleteInput = document.querySelector(fieldSelector)
        const autoCompleteListener = new window.google.maps.places.Autocomplete(autocompleteInput)
        autoCompleteListener.setComponentRestrictions({ country: ['us', 'ca'] })
        autoCompleteListener.setFields(['address_components'])
        autoCompleteListener.addListener('place_changed', () => {
          const selectedAddress = autoCompleteListener.getPlace()
          this.autoCompleteAddressClickHandler(selectedAddress)
        })
      }
    },
    autoCompleteAddressClickHandler(value) {
      if (!value || !value.address_components) {
        return
      }
      const routeName = this.$route.name
      this.$trackEvent({
        action: `Autocomplete Address Click${routeName === 'cosignAddress' ? ' - Cosigner' : ''}`,
        properties: {
          autocomplete_address: value,
        },
      })
      // reset address handler
      this.street_address = ''

      const findField = (field) =>
        value.address_components.find((addressItem) => {
          return addressItem.types.indexOf(field) !== -1
        })
      // set street address
      const street_number = findField('street_number')
      const street_route = findField('route')
      if (street_number && street_route) {
        this.street_address = `${street_number.long_name} ${street_route.short_name}`
      }
      // set county
      const county = findField('administrative_area_level_2')
      if (county) {
        console.log(county)
        this.county = county.short_name
      }
      // set country
      const country = findField('country')
      if (country) {
        this.country_code = country.short_name
      }
      // set region using timeout ( allowing time for the available states based off the country to update )
      const region = findField('administrative_area_level_1')
      region &&
        setTimeout(() => {
          this.region_code = region.short_name
        })
      // update city
      const city = findField('locality')
      if (city) {
        this.city = city.long_name
      }
      // update postal_code
      const postal_code = findField('postal_code')
      if (postal_code) {
        this.postal_code = postal_code.long_name
      }
      const postal_code_suffix = findField('postal_code_suffix')
      if (postal_code_suffix) {
        this.postal_code = `${this.postal_code}-${postal_code_suffix.long_name}`
      }
    },
  },
}

export default GoogleAddressAutoCompleteMixin
