<template>
  <div :class="['interest-grid', { 'as-list': list }]">
    <div
      class="interest"
      v-for="interest in interests"
      @click="onInterestClick(interest)"
      :class="{ selected: interest.selected, 'read-only': readOnly, editable }"
      :key="`content_${interest.label}`"
    >
      <div v-ripple v-if="editable" class="remove-interest">
        <v-icon color="salmon" size="17" @click="onRemoveInterest(interest)">close</v-icon>
      </div>
      <v-icon v-else class="checked" color="#4A6CED">check_circle</v-icon>
      <v-layout align-center>
        <v-img
          :class="['interest-icon', { selected: !readOnly && interest.selected }]"
          :src="interest.icons.svg"
          min-width="40"
          max-height="40"
        />
      </v-layout>
      <h3 class="interest-name">{{ interest.label }}</h3>
    </div>
    <v-layout v-if="editable" class="add-interest" align-center justify-center>
      <blw-button color="white" round icon @click="goToInterests">
        <v-icon class="" color="charcoal">add</v-icon>
      </blw-button>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'InterestsGrid',
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    interests: {
      type: Array,
      required: true,
    },
    onInterestClick: {
      type: Function,
      default: () => {},
    },
    onRemoveInterest: {
      type: Function,
      default: () => {},
    },
    list: {
      type: Boolean,
      default: false,
    },
    interestsRoute: {
      type: String,
    },
  },
  methods: {
    getIconColor(isSelected) {
      return !this.readOnly && isSelected ? 'blue' : 'charcoal'
    },
    goToInterests() {
      this.$trackEvent({
        action: 'Edit Interests',
        properties: {
          location: 'Interests',
          color: 'Blue',
          CTA: '+',
          category: 'Profile',
          type: 'Button',
        },
      })

      const interestsRoute = this.interestsRoute || 'profileInterests'
      this.$routerPush({ name: interestsRoute })
    },
  },
}
</script>

<style lang="scss">
.interest-grid {
  display: grid;
  justify-content: center;
  width: 100%;
  grid-template-columns: repeat(4, 125px);
  user-select: none;

  &.as-list {
    justify-content: flex-start;

    @media (min-width: 501px) {
      grid-template-columns: repeat(auto-fit, 125px);
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .interest-name {
    font-size: 15px;
  }

  .interest,
  .v-icon {
    @include vuetifyTransition();
  }

  .add-interest {
    width: 100%;
    height: 144px;

    .blw-button {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .remove-interest {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0.5rem;
    top: 0.5rem;
    border: 1px solid $salmon-60;
    border-radius: 100%;

    &:hover {
      border-color: $salmon-80;
      .v-icon {
        color: $salmon-80 !important;
      }
    }
  }

  .interest {
    width: 100%;
    height: 144px;
    padding: 1.5rem 0.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-template-rows: 1fr 0.8fr;
    position: relative;
    border: 1px solid $charcoal-10;

    .interest-icon {
      &.selected {
        // HACK: Use CSS trickery to change charcoal image to blue
        // https://codepen.io/sosuke/pen/Pjoqqp
        filter: invert(37%) sepia(17%) saturate(6971%) hue-rotate(216deg) brightness(97%)
          contrast(91%);
      }
    }

    .checked {
      opacity: 0;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }

    &:not(.read-only) {
      cursor: pointer;

      &.selected {
        color: $blue-60;

        .interest-name {
          font-weight: bold;
        }

        .checked {
          opacity: 1;
        }
      }
    }
  }
}
</style>
