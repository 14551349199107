<template>
  <blw-hero
    header="No cosigner application found"
    text="Check your email for a cosigner invitation and click the link!"
  />
</template>
<script>
export default {
  name: 'NoCosignSource',
}
</script>
