<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height">
      <div class="d-flex column align-center justify-center mb-5">
        <h1 class="header-md mb-5">We’ve emailed you an update</h1>
        <blw-illustration name="email" size="175" class="mb-4" />
        <p class="text-xs-center" v-max-width="300">
          Our team has reviewed your application. Please check your email for an update.
        </p>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ReviewEmailedUpdate',
}
</script>
