<template>
  <div class="property-card-details shadow">
    <PropertyBedroomSelection
      v-if="showRoomSelection && !showLeaseLengthSelection"
      :class="{ 'hide-selection': showLeaseLengthSelection }"
      :roomId="currentPropertySelectionState.roomId"
      :showRoomSelection="showRoomSelection"
      :cancelRoomSelectionClickHandler="cancelRoomSelectionClickHandler"
      :saveRoomSelectionHandler="saveRoomSelectionHandler"
      :availableRooms="selectedProperty.availableRooms"
      :minPrice="selectedProperty.minPrice"
      :maxPrice="selectedProperty.maxPrice"
    />
    <PropertyRoomPricingSlider
      v-if="showLeaseLengthSelection"
      :saveSelectionHandler="saveLeaseLengthSliderHandler"
      :cancelClickHandler="cancelLeaseLengthSliderHandler"
      :selectedRoom="selectedRoom"
      v-bind="currentPropertySelectionState"
    />
  </div>
</template>

<script>
import { get as _get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import PropertyBedroomSelection from './PropertyBedroomSelection'
import PropertyRoomPricingSlider from './PropertyRoomPricingSlider'

export default {
  name: 'SelectedPropertyCardDetails',
  props: {
    selectedProperty: {
      type: Object,
    },
    hidePropertyDetails: {
      type: Function,
    },
    reCalculateHeight: {
      type: Function,
    },
  },
  components: {
    PropertyRoomPricingSlider,
    PropertyBedroomSelection,
  },
  data() {
    return {
      currentPropertySelectionState: null,
      showRoomSelection: false,
      showLeaseLengthSelection: false,
    }
  },
  computed: {
    ...mapGetters('application', ['homeSelectionPropertyDetails']),
    selectedRoom() {
      const selectedPropertyRoomId = _get(this.currentPropertySelectionState, 'roomId')
      return this.selectedProperty.availableRooms.find(({ id }) => id === selectedPropertyRoomId)
    },
  },
  mounted() {
    this.initiateData()
  },
  methods: {
    ...mapActions('application', ['updateApplication', 'updatePropertyDisplayState']),
    initiateData() {
      const currentPropertySelectionState = this.homeSelectionPropertyDetails(
        this.selectedProperty.id
      )
      this.currentPropertySelectionState = currentPropertySelectionState
      this.showRoomSelection = true
    },
    async savePropertyDisplayState() {
      const propertyId = this.selectedProperty.id
      const data = { ...this.currentPropertySelectionState }
      this.updatePropertyDisplayState({ propertyId, data })
      await this.updateApplication()
    },
    cancelRoomSelectionClickHandler() {
      this.reset()
    },
    saveRoomSelectionHandler(roomId) {
      this.currentPropertySelectionState.roomId = roomId
      this.showLeaseLengthSelection = true
      window.scrollTo({
        top: 285,
        behavior: 'smooth',
      })
      this.reCalculateHeight()
    },
    async saveLeaseLengthSliderHandler({
      move_in_date,
      lease_end_date,
      monthlyRoomPrice,
      leaseLength,
    }) {
      this.currentPropertySelectionState.move_in_date = move_in_date
      this.currentPropertySelectionState.lease_end_date = lease_end_date
      this.currentPropertySelectionState.monthlyRoomPrice = monthlyRoomPrice
      this.currentPropertySelectionState.leaseLength = leaseLength
      await this.savePropertyDisplayState()
      this.reset()
    },
    cancelLeaseLengthSliderHandler() {
      this.showLeaseLengthSelection = false
      this.reCalculateHeight()
    },
    reset() {
      this.hidePropertyDetails()
      this.initiateData()
      setTimeout(() => {
        this.showRoomSelection = true
        this.showLeaseLengthSelection = false
      }, 800)
    },
  },
}
</script>

<style lang="scss">
.property-card-details {
  background: white;
  z-index: 122;
  transition: transform 0.5s, opacity 0.5s, height 0.5s;
  height: max-content;
}
</style>
