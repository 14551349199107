<template>
  <div class="mg-modal">
    <v-layout column align-center justify-space-around>
      <div>
        <blw-illustration class="mb-4 mx-auto" type="spot" name="thumbs-up" size="80" />
        <h2 class="header-md text-xs-center mb-4">Thanks.</h2>
        <p class="mb-4">Let’s find you a home you’ll love and people you’ll love to live with.</p>
      </div>
      <blw-button class="mt-3 mb-4" color="charcoal" @click="chooseNewHome">
        Choose a different home
      </blw-button>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'MeetGreetChangedMind',
  methods: {
    chooseNewHome() {
      this.$root.$emit('closeModal')
    },
  },
}
</script>
