<template>
  <v-container>
    <v-layout column align-center justify-center class="full-height">
      <div class="d-flex column align-center justify-center">
        <blw-avatar size="160" :avatar="avatar" class="mb-4" />
        <h1 class="header-md mb-3 mt-2">Create Your Profile</h1>
        <p v-max-width="280" class="text-xs-center">
          A great home starts with great people. Answer the next few questions to help us match you
          with your future roommates.
        </p>
      </div>
      <p class="font-small helper-text pt-5 pb-2">
        This will be shared with prospective roommates and the Bungalow community.
      </p>
      <blw-button label="Continue" @click="goToPreferences" />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileStart',
  data() {
    return {
      avatar: 'https://assets.bungalow.com/images/roommates_small.png',
    }
  },
  methods: {
    goToPreferences() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Profile Start',
          color: 'Salmon',
          text: 'Create your profile',
          CTA: 'Continue',
          category: 'Profile',
          type: 'Button',
        },
      })

      this.$routerPush({ name: 'profilePreferences' })
    },
  },
}
</script>
<style lang="scss">
.helper-text {
  color: $text-lighter;
  line-height: 18px;
}
</style>
