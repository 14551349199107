<template>
  <div class="blw-upload-v2 pb-3">
    <div v-if="!previewImageUrl">
      <div class="upload-box">
        <blw-icon class="mr-3" icon="add_a_photo" size="lg" />
        <b v-max-width="200" class="font-base">
          Take a photo of your document or choose an image to upload
        </b>
        <label style="cursor: pointer" :for="inputId" />
        <input
          @change="fileSelectChangeHandler"
          :id="inputId"
          type="file"
          accept="image/jpeg, image/jpg, image/png, application/pdf"
          class="d-none"
        />
      </div>
      <blw-text-field
        v-if="required"
        hint="Valid file types are PDF, PNG and JPEG, should be less than 25MB and have file names less than 100 characters long."
        :persistentHint="fileError"
        :rules="[fileRequired]"
      />
    </div>
    <div v-else class="document-preview mb-3">
      <iframe v-if="imageIsPdf" :src="previewImageUrl" frameBorder="0" scrolling="no" />
      <img v-else :src="previewImageUrl" />
      <div @click="deleteDocument" class="delete-document">x</div>
    </div>
  </div>
</template>

<script>
const MAX_FILE_SIZE_IN_BYTES = 25000000
const MAX_FILE_NAME_LENGTH = 100

export default {
  name: 'BlwUploadV2',
  data() {
    return {
      imageUrl: null,
      fileError: false,
    }
  },
  props: {
    documentUrl: {
      type: String,
      default: null,
    },
    fileOnChangeHandler: {
      type: Function,
      required: true,
    },
    deleteFileHandler: {
      type: Function,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  computed: {
    previewImageUrl() {
      return this.documentUrl || this.imageUrl
    },
    imageIsPdf() {
      return !!this.previewImageUrl.includes('.pdf')
    },
  },
  methods: {
    fileRequired(val) {
      return val ? true : 'This document is required'
    },
    fileSelectChangeHandler(event) {
      this.fileError = false
      const [file] = event.target.files
      if (file.size <= MAX_FILE_SIZE_IN_BYTES && file.name.length <= MAX_FILE_NAME_LENGTH) {
        this.imageUrl = window.URL.createObjectURL(file)
        this.fileOnChangeHandler(file)
      } else {
        this.fileError = true
        this.$segment.track('Failed to upload image', {
          fileSize: file.size,
          fileNameLength: file.name.length,
          isMaxFileSizeError: file.size > MAX_FILE_SIZE_IN_BYTES,
          isMaxFileNameLengthError: file.name.length > MAX_FILE_NAME_LENGTH,
        })
      }
    },
    deleteDocument() {
      this.imageUrl = null
      this.deleteFileHandler()
    },
  },
}
</script>

<style lang="scss">
.blw-upload-v2 {
  .upload-box {
    position: relative;
    padding: 20px;
    cursor: pointer;
    border: 1px dashed #e6e3e3;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      cursor: pointer;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .blw-icon * {
    stroke: none;
  }

  .v-input__slot {
    display: none !important;
  }

  .document-preview {
    position: relative;
    min-width: 144px;

    iframe,
    img {
      height: 100%;
      width: 100%;
      overflow: hidden;
      border: 1px solid $charcoal-10;
      border-radius: 4px;
    }
    .delete-document {
      position: absolute;
      cursor: pointer;
      top: -8px;
      right: -8px;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      border: 1px solid $charcoal-10;

      &:hover {
        background: $charcoal-5;
      }
      &:active {
        background: $charcoal-10;
      }
    }
  }
  .v-text-field__details {
    overflow: inherit;
    .v-messages {
      height: auto;
    }
  }
}
</style>
