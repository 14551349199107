<template>
  <main class="grid full-height">
    <v-layout mt-5 column>
      <blw-hero
        :iconComponent="SubmitIntroSvg"
        header="Your application is ready <br/> to be submitted"
        :text="heroText"
      >
      </blw-hero>
    </v-layout>

    <footer>
      <v-layout justify-center>
        <blw-button
          @click="onClick"
          :label="entityFeeHasBeenWaived ? 'Continue' : 'Finalize and pay'"
        />
      </v-layout>
    </footer>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import SubmitIntroSvg from '@/assets/icons/screening.svg'

export default {
  name: 'SubmitIntro',
  data() {
    return {
      SubmitIntroSvg,
    }
  },
  props: {
    nextRoute: {
      type: String,
      required: true,
    },
    entityMarketCodeGetter: {
      type: String,
      required: true,
    },
    entityApplicationFeeHasBeenWaivedGetter: {
      type: String,
      required: true,
    },
    entityUSAddressCheckGetter: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      entityCountryCode(state, getters) {
        return getters[this.entityMarketCodeGetter]
      },
      entityFeeHasBeenWaived(state, getters) {
        return getters[this.entityApplicationFeeHasBeenWaivedGetter]
      },
      entityHasUSAddress(state, getters) {
        return getters[this.entityUSAddressCheckGetter]
      },
    }),
    /**
     * Conditional hero text based off of the market country code,
     * if the entity has entered a US address and if the fee has been waived
     */
    heroText() {
      if (this.entityHasUSAddress) {
        return `Before you finish, we'll need your social ${
          this.entityCountryCode === 'US' ? 'security' : 'insurance'
        } number${
          this.entityFeeHasBeenWaived ? '.' : ' and a credit card for your application fee.'
        }`
      } else {
        return this.entityFeeHasBeenWaived
          ? ''
          : `Before you finish, we'll need a credit card for your application fee.`
      }
    },
  },
  methods: {
    onClick() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          text: 'Splash screen',
          CTA: 'Continue',
          category: 'Finalize and Pay',
          type: 'Button',
        },
      })

      this.$routerReplace({ name: this.nextRoute })
    },
  },
}
</script>
