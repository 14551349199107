import CosignerIncomeRoutes from '@/routes/cosignerIncome'
import CosignerSubmitRoutes from '@/routes/cosignerSubmit'

import CosignContainer from './CosignContainer'
import CosignSourceHandler from './CosignSourceHandler'
import CosignSteps from './views/ApplicationSteps'
import CosignAboutApplication from './views/CosignAboutApplication'
import CosignAboutYourself from './views/CosignAboutYourself'
import CosignAddress from './views/CosignAddress'
import CosignIdentity from './views/CosignIdentity'

export default [
  {
    path: 'cosign',
    component: CosignSourceHandler,
    name: 'cosignSourceHandler',
    meta: {
      requiresAuth: true,
      basicNav: true,
    },
  },
  {
    path: 'cosign/:cosignerSource',
    component: CosignContainer,
    meta: {
      requiresAuth: true,
      basicNav: true,
    },
    children: [
      {
        path: '',
        name: 'cosignAboutApplication',
        component: CosignAboutApplication,
        meta: { disableBack: true },
      },
      CosignerIncomeRoutes,
      {
        path: 'steps',
        name: 'cosignSteps',
        component: CosignSteps,
        meta: { disableBack: true },
        props: {
          entityNamespace: 'cosigner',
          fetchValidationAction: 'fetchCosignerValidationById',
          nextRoute: 'cosigner-submit',
          entityIdGetter: 'cosignerId',
          sectionsData: [
            {
              header: 'Personal details',
              linkName: 'cosignAboutYourself',
              validationField: 'cosigner',
            },
            {
              header: 'Income information',
              linkName: 'cosignIncome',
              validationField: 'income',
            },
          ],
          updateEntityByKeyAction: 'updateCosignerByKey',
          validationNamespace: 'cosignerValidation',
        },
      },
      {
        path: 'about-yourself',
        name: 'cosignAboutYourself',
        component: CosignAboutYourself,
      },
      {
        path: 'address',
        name: 'cosignAddress',
        component: CosignAddress,
      },
      {
        path: 'identity',
        name: 'cosignIdentity',
        component: CosignIdentity,
      },
      CosignerSubmitRoutes,
    ],
  },
]
