/* Common getters/mutations required for generic tasks in store modules */
import _ from 'lodash'

const stateHelpers = {
  loading: false,
  updating: false,
  error: false,
}

const getterHelpers = {
  loading: (state) => state.loading,
  updating: (state) => state.updating,
  error: (state) => state.error,
  apiFieldError: (state) => (field) => _.get(state, `error.field_errors.${field}`),
}

const mutationHelpers = {
  setLoading: (state, status) => (state.loading = status),
  setUpdating: (state, status) => (state.updating = status),
  setError: (state, status) => (state.error = status),
}

export { stateHelpers, getterHelpers, mutationHelpers }
