<template>
  <div class="profile-interests">
    <h1 class="text-xs-center header-md pa-4">What activities do you enjoy?</h1>
    <p class="text-xs-center pb-4 font-grey">
      <span v-if="selectedInterests.length">{{ selectedInterests.length }} out of 9 selected</span>
      <span v-else>Select up to 9</span>
    </p>
    <interests-grid :interests="interests" :onInterestClick="toggleInterestHandler" />
    <footer class="sticky-bottom-footer">
      <v-layout justify-center>
        <blw-button :label="buttonText" :disabled="tooManyInterests" @click="onContinue" />
      </v-layout>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InterestsGrid from '../components/InterestsGrid'

export default {
  name: 'ProfilePage',
  components: { InterestsGrid },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('interests', ['interests']),
    selectedInterests() {
      return this.interests.filter((interest) => {
        if (interest.selected === true) return interest
      })
    },
    tooManyInterests() {
      // Limit the max selected to 9
      return this.selectedInterests.length >= 10
    },
    buttonText() {
      return this.tooManyInterests ? 'Too many selected' : 'Continue'
    },
  },
  methods: {
    ...mapActions('interests', ['toggleInterest']),
    toggleInterestHandler(interest) {
      this.trackInterestChange(interest)
      this.toggleInterest(interest.id)
    },
    trackInterestChange(interest) {
      const verb = !interest.selected ? 'Add' : 'Remove'

      this.$trackEvent({
        action: `${verb} Interest`,
        properties: {
          location: 'Interests',
          color: 'Blue',
          text: interest.label,
          category: 'Profile',
          type: 'Card',
        },
      })
    },
    onContinue() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Interests',
          color: 'Salmon',
          CTA: 'Continue ',
          category: 'Profile',
          type: 'Button',
        },
      })

      this.editMode ? this.$router.back() : this.$routerPush({ name: 'profileEdit' })
    },
  },
}
</script>

<style lang="scss">
.profile-interests {
  margin: 3.75rem auto;

  @include mobile {
    margin: 2rem auto;
  }
}
</style>
