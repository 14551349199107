<template>
  <div class="bg-white">
    <v-container class="group-invite" v-max-width="478">
      <v-layout column>
        <h1 :class="['header-lg', isMobile ? 'mt-4' : 'mt-5 font-black']">
          Next, invite each adult in your household to join the application
        </h1>
        <p :class="['text-large', isMobile ? 'my-4' : 'mt-4 mb-5']">
          <span class="pr-1">⚠️</span> <b>Each adult (18+)</b> will need to submit an application
          before we can send you a lease.
        </p>
      </v-layout>

      <!-- Group Owner -->
      <div class="mb-2">
        <p class="text-large font-bold mb-2">Applicant 1 (you)</p>
        <v-layout row wrap>
          <v-flex sm6>
            <blw-text-field
              box
              readonly
              tabindex="-1"
              label="First name"
              :class="!isMobile && 'pr-2'"
              :value="userProfile.first_name"
            />
          </v-flex>
          <v-flex sm6>
            <blw-text-field
              box
              readonly
              tabindex="-1"
              label="Last name"
              :class="!isMobile && 'pl-2'"
              :value="userProfile.last_name"
            />
          </v-flex>
          <v-flex>
            <blw-text-field
              box
              readonly
              tabindex="-1"
              label="Email"
              :value="userProfile.email"
              hint="hey"
            />
          </v-flex>
        </v-layout>
      </div>

      <!-- Group Members -->
      <v-form v-model="isValid" class="pb-5">
        <div class="mb-2" v-for="number in numberOfInvites" :key="number">
          <p class="text-large font-bold mb-2">Applicant {{ number + 1 }}</p>
          <v-layout row wrap>
            <v-flex sm6>
              <blw-text-field
                box
                label="First name"
                :class="!isMobile && 'pr-2'"
                :rules="[maxLength(50), required]"
                v-model="groupMembers[number].first_name"
              />
            </v-flex>
            <v-flex sm6>
              <blw-text-field
                box
                label="Last name"
                :class="!isMobile && 'pl-2'"
                :rules="[maxLength(50), required]"
                v-model="groupMembers[number].last_name"
              />
            </v-flex>
            <v-flex>
              <blw-text-field
                box
                label="Email"
                :rules="[maxLength(100), emailField, required]"
                v-model="groupMembers[number].email"
              />
            </v-flex>
          </v-layout>
        </div>
        <v-layout column justify-center class="pb-5">
          <div class="mx-auto mb-1 charcoal-40--txt font-small">
            <span v-if="!isLoading" :class="isValid && 'invisible'">
              Please fill out all fields to continue
            </span>
            <span v-else> Hang tight, we're inviting your group! </span>
          </div>
          <blw-button
            color="charcoal"
            class="mx-auto"
            @click="onSubmit"
            :disabled="!isValid || isLoading"
          >
            <span v-if="!isLoading">Invite & Continue</span>
            <loading-spinner color="white" size="20" v-else />
          </blw-button>
          <p v-if="error" class="mt-3 text-xs-center red-60--txt">
            Oops! Something went wrong. Refresh and try again, if the issue persists, please contact
            support (homes@bungalow.com)
          </p>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { emailField, maxLength, required, minAge } from '@/utils/formValidations'
import BlwButton from '@/shared/components/BlwButton.vue'
import LoadingSpinner from '@/shared/LoadingSpinner.vue'

export default {
  name: 'GroupInvite',
  components: {
    BlwButton,
    LoadingSpinner,
  },
  data() {
    return {
      groupMembers: {},
      groupMember: {
        first_name: '',
        last_name: '',
        email: '',
      },
      isValid: false,
      isLoading: false,
    }
  },
  beforeMount() {
    this.disableIntercom()
    this.prepareGroupInviteeList()
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('groupApplicationSource', ['error', 'groupApplicationSource']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    numberOfInvites() {
      // Lower the total number of roommates (you can't invite yourself)
      const groupSize = this.groupApplicationSource.number_of_potential_roommates
      return groupSize > 1 ? groupSize - 1 : groupSize
    },
  },
  methods: {
    ...{ minAge, required, emailField, maxLength }, // Validation helpers
    ...mapActions('groupApplicationSource', [
      'fetchGroupApplicationSource',
      'addGroupMemberToGroupSource',
    ]),
    disableIntercom() {
      window.Intercom && window.Intercom('shutdown')
    },
    prepareGroupInviteeList() {
      // Dynamically stub out form data structure to capture group invitee details:
      _.times(this.numberOfInvites, (number) => {
        this.groupMembers[number + 1] = { ...this.groupMember }
      })
    },
    async onSubmit() {
      try {
        this.$trackEvent({
          action: 'CTA Clicked',
          properties: {
            category: 'group_applications',
            CTA: 'group_invite_cta',
            text: 'Invite & Continue',
          },
        })

        this.isLoading = true

        // Prepare invites for each group member...
        const { slug: group_source_slug } = this.groupApplicationSource

        const invitesToSend = _.map(this.groupMembers, (groupMember) => {
          return this.addGroupMemberToGroupSource({
            group_source_slug,
            ...groupMember,
          })
        })

        // Send the invites...
        await Promise.all(invitesToSend)

        // Refresh the group source to ensure invites have been sent successfully
        await this.fetchGroupApplicationSource(this.groupApplicationSource.slug)

        // Check to ensure group members match the house capacity:
        const { number_of_potential_roommates, members } = this.groupApplicationSource

        // Looks good, head to next route
        if (number_of_potential_roommates === members.length) {
          this.$trackEvent({
            action: 'Group Invites Sent',
            properties: {
              category: 'group_applications',
              type: 'group_app_invite',
            },
          })

          return this.proceedToNextScreen()
        }

        // Something went wrong, log to Sentry and proceed:
        this.$sentry.captureMessage('Group Invites Check Failed!', {
          extra: { ...this.groupApplicationSource },
        })

        this.proceedToNextScreen()
      } catch (errorAddingGroupMembers) {
        console.error(errorAddingGroupMembers)

        // Something went wrong, log to Sentry and proceed:
        this.$sentry.captureMessage('Group Add Members Failed!', {
          extra: { ...this.groupApplicationSource, error: errorAddingGroupMembers },
        })

        this.isLoading = false
      }
    },
    proceedToNextScreen() {
      this.$router.replace({ name: 'groupsGetStarted' })
    },
  },
}
</script>

<style lang="scss">
.group-invite {
  @include fullHeight();

  .v-input--is-readonly {
    opacity: 0.75;
    pointer-events: none;
  }
}
</style>
