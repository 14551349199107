<template>
  <loading-spinner v-if="!meetGreetReady || loading" full-page />
  <router-view v-else />
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import LoadingSpinner from '@/shared/LoadingSpinner'
import { delay } from '@/utils/helpers'

export default {
  name: 'MeetGreetContainer',
  components: { LoadingSpinner },
  data() {
    return {
      meetGreetReady: false,
      loading: false,
      timeRemainingInterval: null,
      meetingIdInterval: null,
      meetingFetchAttempts: 0,
    }
  },
  watch: {
    error({ code, type }) {
      const meetingNotOpen = code == 404 && type === 'NotFound'
      if (meetingNotOpen && this.$route.name !== 'meetGreetReview')
        this.$routerReplace({ name: 'meetGreetReview' })
    },
    $route(route) {
      // No meeting has expired, head to review stage
      if (this.timeExpired && route.name !== 'meetGreetReview')
        return this.$routerReplace({ name: 'meetGreetReview' })
    },
    timeExpired(timeHasExpired) {
      if (timeHasExpired) this.whenTimeHasExpired()
    },
    meeting(meeting) {
      if (meeting) {
        this.meetGreetReady = true
        clearInterval(this.meetingIdInterval)
      }
    },
    meetingId(meetingId) {
      if (meetingId) {
        const { meetingId: meetingIdQueryParam } = this.$route.query
        this.fetchMeeting(meetingIdQueryParam || meetingId)
        clearInterval(this.meetingIdInterval)
      }
    },
    meetingFetchAttempts(numberOfAttempts) {
      if (numberOfAttempts >= 2) {
        this.meetGreetReady = true
        clearInterval(this.meetingIdInterval)
      }
    },
  },
  computed: {
    ...mapState('meetGreet', ['timeExpired']),
    ...mapGetters('application', ['meetingId', 'applicationStatus']),
    ...mapGetters('meetGreet', ['meeting', 'chatUser', 'error', 'timeLeft', 'meetingChatChannel']),
  },
  async beforeMount() {
    await this.ensureMeetGreetData()
  },
  mounted() {
    this.applicationStatus !== 'meet_and_greet' && this.goToRoute()
    if (this.meeting) this.meetGreetReady = true
  },
  destroyed() {
    clearInterval(this.meetingIdInterval)
    this.clearMeetingData()
  },
  methods: {
    ...mapActions('application', ['fetchApplicationBySource']),
    ...mapActions('meetGreet', ['fetchMeeting', 'fetchChatUser', 'checkTimeLeft']),
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('interests', ['fetchInterests']),
    ...mapMutations('meetGreed', {
      clearMeetingData: 'resetState',
    }),
    watchForMeetingId() {
      const source = this.$route.params.applicationSource

      this.fetchApplicationBySource(source)
      this.meetingIdInterval = setInterval(() => {
        this.fetchApplicationBySource(source)
        this.meetingFetchAttempts++
      }, 1000)
    },
    whenTimeHasExpired() {
      // Send to review page:
      if (this.$route.name !== 'meetGreetReview') this.$routerPush({ name: 'meetGreetReview' })
      // Show expiry modal:
      this.$root.$emit('openModal', { modalComponent: 'MeetGreetChatEnded' })
    },
    goToRoute() {
      // Poll for meeting to begin...
      if (!this.meeting) {
        this.watchForMeetingId()
        // Go to the main MG screen while you wait (or in case of a failure)
        this.$routerPush({ name: 'meetGreet' }).catch(() => {})
      }

      // Meeting closed or expired:
      if (this.error && this.$route.name !== 'meetGreetReview')
        return this.$routerPush({ name: 'meetGreetReview' })
    },
    async ensureMeetGreetData() {
      this.loading = true
      const { meetingId } = this.$route.query
      await this.fetchMeeting(meetingId)
      this.meetingId && (await this.ensureMeetingChannelCreated(meetingId))
      await this.ensureChatUserProviderToken()
      await this.fetchProfile()
      await this.fetchInterests()
      this.loading = false
    },
    async ensureMeetingChannelCreated(meetingId) {
      if (this.meetingChatChannel) return
      await this.fetchMeeting(meetingId)
      await delay(1000)
      await this.ensureMeetingChannelCreated(meetingId)
    },
    async ensureChatUserProviderToken() {
      await this.fetchChatUser()
      if (this.chatUser && this.chatUser.provider_token) return
      await delay(1000)
      return this.ensureChatUserProviderToken()
    },
  },
}
</script>

<style lang="scss">
.mg-modal {
  display: flex;
  align-items: center;
  min-height: 435px;
  max-width: 264px;
  margin: auto;
  text-align: center;
  min-height: 500px;

  @include mobile {
    min-height: initial;
  }
}

.mg-interstitial {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;

  @include mobile {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
}
</style>
