<template>
  <div id="double-deposit-prompt-modal">
    <v-layout column align-center class="text-xs-center">
      <blw-illustration type="spot" name="double-deposit" size="80" />
      <h2 class="header-md my-4">{{ doubleDepositTextConditionalByRegion }}</h2>
      <p v-max-width="250" class="mb-5">
        By clicking the button below, you agree to
        {{ doubleDepositTextConditionalByRegion.toLowerCase() }} prior to move-in.
      </p>
      <blw-button
        size="lg"
        color="charcoal"
        @click="onContinue"
        :loading="pending"
        label="I agree"
      />
      <blw-button class="mt-2" flat darkText @click="$root.$emit('closeModal')" label="Cancel" />
      <v-snackbar :value="error || onContinueError" :timeout="6000">
        {{ error || onContinueError }}
      </v-snackbar>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DoubleDepositPromptModal',
  props: {
    applicationRequestId: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      onContinueError: null,
      pending: false,
    }
  },
  computed: {
    ...mapGetters('application', [
      'applicationId',
      'loading',
      'error',
      'applicationRequest',
      'doubleDepositTextConditionalByRegion',
    ]),
  },
  methods: {
    ...mapActions('application', [
      'updateApplicationRequest',
      'acceptDoubleDepositForApplication',
      'fetchApplication',
    ]),
    async onContinue() {
      try {
        this.pending = true
        this.onContinueError = null

        this.$trackEvent({
          action: 'CTA Clicked: Double Deposit Info',
          properties: {
            location: 'Double Deposit Info Page',
            CTA: 'Submit',
            event: 'CTA Clicked',
          },
        })

        await this.acceptDoubleDepositForApplication(this.applicationId)
        await this.fetchApplication(this.applicationId)

        !this.error &&
          (await this.updateApplicationRequest({
            requestId: this.applicationRequest.id,
            payload: {
              application: this.applicationId,
              status: 'completed',
            },
          }))

        if (!this.error) {
          this.$root.$emit('closeModal')
          this.$routerPush({
            name: 'applicationInReview',
          })
        }

        this.pending = false
      } catch (e) {
        this.onContinueError = e
      }
    },
  },
  mounted() {
    this.$trackEvent({
      action: 'Viewed Page: Double Deposit Info',
      properties: {
        location: 'Bottom',
        CTA: 'I agree',
        event: 'Double Deposit Info Page',
      },
    })
  },
}
</script>
