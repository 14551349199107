<template>
  <v-container
    id="home-shopping"
    :class="['px-0 bg-cream', { 'py-5': $vuetify.breakpoint.mdAndUp }]"
    fluid
  >
    <v-layout column align-center justify-center mt-2>
      <p class="lead mt-4 mb-3 mx-auto grey--text">meet the roommates</p>
      <h1 class="text-xs-center header-md pb-1">
        You're currently<br />
        chatting with {{ openMeetingsCount }} home{{
          !openMeetingsCount || openMeetingsCount > 1 ? 's' : ''
        }}
      </h1>
      <div class="mt-3 mx-auto text-xs-center">
        Chat with as many homes as you like!<br />
        <span v-html="approvedRangeText" />
      </div>
      <div v-if="hiddenProperties.length" class="mt-3">
        <blw-button
          color="primary"
          block
          :flat="!showHiddenProperties"
          @click="showHiddenProperties = !showHiddenProperties"
        >
          {{ showHiddenProperties ? 'Back to my homes' : 'View hidden properties' }}
        </blw-button>
      </div>
    </v-layout>

    <v-layout class="selection-container relative w-100">
      <swiper ref="propertySwiper" :options="swiperOptions" class="mobile-options w-100">
        <template v-if="showHiddenProperties">
          <swiper-slide
            v-for="(availableProperty, index) in visibleHiddenProperties"
            :key="`hidden-property-${index}`"
          >
            <PropertyCard :property="availableProperty" hidden />
          </swiper-slide>
        </template>
        <template v-else>
          <swiper-slide
            v-for="(availableProperty, index) in visibleApplicationProperties"
            :key="`non-hidden-property-${index}`"
          >
            <PropertyCard :property="availableProperty" />
          </swiper-slide>
          <swiper-slide>
            <FindRoommatesCard />
          </swiper-slide>
          <swiper-slide>
            <FindHomesCard />
          </swiper-slide>
        </template>
        <div
          v-ripple
          slot="button-prev"
          class="swiper-button swiper-button-round swiper-button-prev"
        >
          <v-icon color="charcoal">arrow_back</v-icon>
        </div>
        <div
          v-ripple
          slot="button-next"
          class="swiper-button swiper-button-round swiper-button-next"
        >
          <v-icon color="charcoal">arrow_forward</v-icon>
        </div>
      </swiper>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PropertyCard from '../components/PropertyCard'
import FindHomesCard from '@/routes/homeSelection/components/FindHomesCard'
import FindRoommatesCard from '@/routes/homeSelection/components/FindRoommatesCard'

export default {
  name: 'HomeShopping',
  components: {
    PropertyCard,
    FindHomesCard,
    FindRoommatesCard,
  },
  data() {
    return {
      showHiddenProperties: false,
      swiperOptions: {
        noSwipingClass: 'blw-slider',
        slidesPerView: 'auto',
        spaceBetween: this.$vuetify.breakpoint.smAndDown ? 10 : 20,
        centeredSlides: true,
        simulateTouch: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      options: [],
    }
  },
  mounted() {
    this.$root.$on(
      'toggleHiddenProperties',
      (showHiddenProperties) => (this.showHiddenProperties = showHiddenProperties)
    )
  },
  computed: {
    ...mapGetters('application', [
      'applicationPreApprovedForAll',
      'applicationPreApprovedMaxRent',
      'hiddenProperties',
    ]),
    ...mapGetters('homeShopping', ['availableProperties']),
    ...mapGetters('leasePreference', ['leasePreference']),
    ...mapGetters('meetings', ['openMeetingsCount']),
    visibleApplicationProperties() {
      return this.availableProperties.filter(({ slug }) => !this.hiddenProperties.includes(slug))
    },
    visibleHiddenProperties() {
      return this.availableProperties.filter(({ slug }) => this.hiddenProperties.includes(slug))
    },
    approvedRangeText() {
      return this.applicationPreApprovedForAll
        ? 'You’re approved for all rooms.'
        : `You’re approved for up to <span class="green-60--txt font-weight-bold">$${this.applicationPreApprovedMaxRent}/mo</span>`
    },
  },
  methods: {
    scrollToProperty(index) {
      this.$refs.propertySwiper.swiper.slideTo(index)
    },
  },
}
</script>

<style lang="scss">
#home-shopping {
  .blw-hero {
    padding-top: 10px;
  }
  .swiper-slide {
    width: 311px;
    padding: 1.75rem 0;

    @include mobile {
      max-width: calc(100% - 3rem);
    }
  }
  .swiper-button-round {
    border: 1px solid black;
  }
  .swiper-button-prev {
    left: 60px;
  }
  .swiper-button-next {
    right: 60px;
  }
  #find-homes-card {
    width: 100%;
    margin: 0 !important;
    border-radius: 8px;
  }
}
</style>
