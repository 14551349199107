<template>
  <div class="blw-control blw-upload">
    <v-card :class="[{ 'mb-3': file }, { file }]">
      <template v-if="file">
        <v-layout align-baseline wrap class="text-xs-left">
          <span class="file-name font-small pr-2">{{ file.name }}</span>
          <span class="grey--text">({{ getFileSize(file.size) }})</span>
        </v-layout>
        <v-layout align-center justify-end @click="removeFile">
          <v-icon color="charcoal">close</v-icon>
        </v-layout>
      </template>
    </v-card>
    <vuetify-upload-button
      v-if="!hideUploadButton"
      block
      outline
      :key="file && file.lastModified"
      :fileChangedCallback="onFileChanged"
      v-bind="{ ...$props, ...$attrs }"
    >
      <template slot="icon">
        <v-icon color="charcoal">add</v-icon>
      </template>
    </vuetify-upload-button>
    <v-flex class="pt-2">
      <blw-text-field v-if="required" v-model="file" :rules="[fileRequired]" />
    </v-flex>
    <p
      v-max-width="350"
      v-if="showFileHint"
      :class="[
        'pb-3 pt-1 text-xs-center lighter--txt font-small mx-auto',
        { 'error-message-text': fileError },
      ]"
    >
      Valid file types are PDF, PNG and JPEG, should be less than 25MB and have file names less than
      100 characters long.
    </p>
  </div>
</template>

<script>
import getFileSize from 'filesize'
import VuetifyUploadButton from 'vuetify-upload-button'

export default {
  name: 'BlwUpload',
  components: {
    VuetifyUploadButton,
  },
  props: {
    title: {
      type: String,
      default: 'Upload your document',
    },
    showFileHint: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideUploadButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: null,
      file: null,
      fileError: false,
    }
  },
  methods: {
    getFileSize,
    fileRequired(val) {
      return val ? true : 'This document is required'
    },
    removeFile() {
      this.file = null
      this.$emit('input', null)
    },
    onFileChanged(file) {
      const maxFilenameLength = 100
      const maxFileSizeInBytes = 25000000
      const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png']

      if (!file) return

      const fileIsCorrectType = acceptedFileTypes.includes(file.type)
      const fileNameIsShortEnough = file.name.length <= maxFilenameLength
      const fileSizeIsSmallEnough = file.size <= maxFileSizeInBytes

      if (fileIsCorrectType && fileNameIsShortEnough && fileSizeIsSmallEnough) {
        this.fileError = null
        this.file = file
        this.$emit('input', file)
      } else {
        this.fileError = true
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes shakeX {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(-5px);
  }
  66% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.blw-upload {
  .error-message-text {
    color: $red-60 !important;
    animation: shakeX 0.5s;
  }
  .file-name {
    font-weight: bold;
    word-break: break-word;
  }
  div.upload-btn {
    padding: 0 !important;
  }
  label.upload-btn {
    min-height: 5rem;
    height: auto;
  }
  .blw-text-field {
    margin: 0;
    padding: 0;
  }
  .v-input__slot {
    display: none;
  }
  &.hasError {
    & .v-btn {
      border-color: $red-60 !important;
    }
    & .v-messages {
      opacity: 1;
    }
  }
  .v-btn,
  .v-card {
    display: flex;
    justify-content: space-between;
  }
  .v-btn {
    padding: 1.75rem $spacer-lg !important;
    border: 1px $charcoal-40 dashed 1px !important;
    @include mobile {
      padding: 1.75rem $spacer;
    }
  }
  .v-card {
    box-shadow: none;
    .v-icon {
      user-select: none;
      &:hover {
        cursor: pointer;
        color: $red-60;
      }
      &:active {
        cursor: pointer;
        color: $charcoal-60;
      }
    }
    &.file {
      opacity: 1;
      height: auto;
      border-radius: 0;
      padding: 1.75rem $spacer-lg !important;
      border: 1px solid $charcoal-60;
    }
  }
}
</style>
