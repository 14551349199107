<template>
  <div class="blw-progress-bar">
    <v-layout v-if="currentStep" justify-center align-center>
      <v-layout row justify-space-between class="relative">
        <div
          v-for="(step, i) in steps"
          :key="i"
          :class="[
            'progress-step',
            { complete: i + 1 < currentStep, current: i + 1 === currentStep },
          ]"
        >
          <v-icon v-if="i + 1 < currentStep" color="white">check</v-icon>
          <v-icon v-if="i + 1 === currentStep" color="black">arrow_forward</v-icon>
        </div>
        <div class="progress-line" />
        <div class="progress-line-todo" :style="calculateTodoWidth" />
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    steps: {
      type: Number,
    },
    currentStep: {
      type: Number,
    },
  },
  computed: {
    calculateTodoWidth() {
      const percentComplete = (this.currentStep - 1) / (this.steps - 1)
      const percentRemaining = 100 - percentComplete * 100
      return `width: ${percentRemaining}%;`
    },
  },
}
</script>

<style lang="scss">
.blw-progress-bar {
  width: 100%;
  background: white;
  pointer-events: none;
  max-width: 316px;
  margin: 0 auto;

  @include mobile {
    max-width: 240px;
  }

  > .layout {
    height: 100%;
  }

  .v-icon {
    display: block;
    font-size: 14px;
  }

  .progress-step {
    transition: all 1s;
    z-index: 2;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background: white;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $charcoal-40;

    &.complete,
    &.current {
      border: 2px solid $charcoal-60;
    }

    @include mobile {
      border: 1px solid $charcoal-40;
      height: 16px;
      width: 16px;

      &.complete,
      &.current {
        border: 1px solid $charcoal-60;
      }

      & .v-icon {
        font-size: 10px !important;
      }
    }

    svg {
      height: 100%;
      width: 100%;
    }

    &.complete {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $charcoal-60;
    }
  }

  .progress-line {
    z-index: 0;
    position: absolute;
    left: 0;
    width: 100%;
    background: $charcoal-60;
    height: 2px;
    top: calc(50% - 2px);

    @include mobile {
      height: 1px;
      top: calc(50% - 1px);
    }
  }

  .progress-line-todo {
    z-index: 1;
    position: absolute;
    width: 100%;
    background: $charcoal-40;
    height: 2px;
    top: calc(50% - 2px);
    right: 0;

    @include mobile {
      height: 1px;
      top: calc(50% - 1px);
    }
  }
}
</style>
