<template>
  <v-text-field
    :class="['blw-control blw-text-field', { embedded }]"
    @input="$emit('input', $event)"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BlwTextField',
  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
/* NOTE: TextField styles are not scoped because they're shared across multiple components */
.v-text-field {
  input {
    font-size: 15px;
    caret-color: $blue-60 !important;

    &:read-only {
      pointer-events: none;
    }
  }
  // Label
  .v-label {
    font-size: 15px;
    color: $text-lighter !important;
  }
  .v-label--active {
    color: $text-lighter !important;
    transform: translateY(-1.5rem) scale(0.75);
  }

  // Messages (hints, errors)
  .v-messages {
    height: 1rem;
  }

  &.v-input--is-focused {
    .v-icon {
      color: $text-lighter !important;
      &:hover {
        color: $blue-60 !important;
      }
      &:active {
        color: darken($blue-60, 20%) !important;
      }
    }
  }

  // Icons
  .v-icon {
    &:hover {
      color: $blue-60 !important;
    }
    &:active {
      color: darken($blue-60, 20%);
    }
  }

  // Error State
  &.error--text .v-input__slot {
    &::before {
      border-color: $red-60 !important;
    }
  }

  .v-input__slot {
    // Underline
    &::before {
      border-width: 1px !important;
      border-color: $charcoal-20 !important;
      bottom: 0 !important;
    }

    // Focus
    &::after {
      border-width: 1px !important;
      border-color: $blue-60 !important;
      bottom: 0 !important;
    }
  }

  // Disabled / Read-Only
  &.v-input--is-disabled .v-input__slot {
    label {
      color: $text-lighter !important;
    }

    &:before {
      border: 1px solid $charcoal-40 !important;
    }
  }

  &.embedded {
    .v-input__slot {
      margin-bottom: 1rem;
    }

    .v-messages,
    .v-input__slot::before,
    .v-input__slot::after {
      display: none;
    }
  }
}
</style>
