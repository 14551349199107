<template>
  <div id="confirm-address-modal" class="grid-enabled">
    <div class="modalLead mb-3 d-flex justify-center">
      <v-icon color="charcoal" size="70">error_outline</v-icon>
    </div>
    <span class="modal-grid-header header-1 text-xs-center mb-4">Address not found</span>
    <div class="modal-grid-content">
      <v-layout justify-center row pb-3 px-3 class="text-xs-center">
        The address appears to be invalid, or we couldn't find an address that matches.
      </v-layout>
      <v-layout justify-center row pb-3 px-3 class="text-xs-center">
        You can proceed with current address you provided, or review the information and update it
        to try again.
      </v-layout>
      <v-layout column align-center>
        <blw-button
          size="lg"
          class="my-2"
          color="charcoal"
          @click="closeModal"
          label="Review address"
        />
        <blw-button size="lg" class="my-2" @click="proceed" label="Confirm" />
      </v-layout>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InvalidAddressPromptModal',
  props: {
    onContinue: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    proceed() {
      this.onContinue()
      this.closeModal()
    },
    closeModal() {
      this.$root.$emit('closeModal')
    },
  },
}
</script>
