import SendBirdSDK from 'sendbird'

const MESSAGES_LIMIT = 50
const REVERSE_MSG_ORDER = false

const SENDBIRD_ERRORS = {
  // A SendBird error occurred...
  connect: 'attempting to connect to the instance',
  disconnect: 'attempting to disconnect from the current instance',
  channel: 'attempting to fetch the specified group channel',
  user: 'attempting to update the user profile',
  history: 'attempting to fetch the chat history',
  message: 'attempting to send the message',
  file: 'attempting to send the file',
  clear: 'attempting to clear the chat history',
  handler: 'Required paramaters missing for handler activation!',
}

const THUMBNAIL_SIZES = [
  { maxWidth: 100, maxHeight: 100 },
  { maxWidth: 200, maxHeight: 200 },
]

export default class SendBird {
  constructor(appId) {
    if (!appId) throw new Error('SendBird App ID Required!')
    new SendBirdSDK({ appId })
  }

  getInstance() {
    return SendBirdSDK.getInstance()
  }

  onError(context, error) {
    const errorContext = SENDBIRD_ERRORS[context] || ''
    const errorMsg = `Sendbird error occurred ${errorContext}`

    return Error(`${errorMsg} -> ${error.code} - ${error.message}`)
  }

  connect(userId, accessToken) {
    return new Promise((resolve, reject) => {
      const SendBird = this.getInstance()

      SendBird.connect(userId, accessToken, (user, error) => {
        error ? reject(this.onError('connect', error)) : resolve()
      })
    })
  }

  disconnect(channelUrl) {
    return new Promise((resolve, reject) => {
      const SendBird = this.getInstance()
      // Cleanup listener(s)
      SendBird.removeChannelHandler(channelUrl)
      // Disconnect
      SendBird.disconnect((response, error) => {
        error ? reject(this.onError('disconnect', error)) : resolve()
      })
    })
  }

  addChannelHandler(channelUrl, callback) {
    return new Promise((resolve, reject) => {
      if (!channelUrl && !callback) return reject(this.onError('handler'))

      const SendBird = this.getInstance()
      const ChannelHandler = new SendBird.ChannelHandler()

      ChannelHandler.onMessageReceived = (channel, message) => {
        channel.markAsRead()
        if (typeof callback === 'function') callback(channel, message)
      }

      SendBird.addChannelHandler(channelUrl, ChannelHandler)
      resolve()
    })
  }

  updateUser(name, email) {
    return new Promise((resolve, reject) => {
      const nickname = `${name} (${email})`
      const SendBird = this.getInstance()

      SendBird.updateCurrentUserInfo(nickname, '', (response, error) => {
        error ? reject(this.onError('update', error)) : resolve()
      })
    })
  }

  getGroupChannel(channelUrl) {
    return new Promise((resolve, reject) => {
      const SendBird = this.getInstance()

      SendBird.GroupChannel.getChannel(channelUrl, (channel, error) => {
        channel.markAsRead()
        error ? reject(this.onError('channel', error)) : resolve(channel)
      })
    })
  }

  formatMessages(messages) {
    return messages.map(this.formatMessage)
  }

  formatMessage(message) {
    if (!message._sender) return message

    const { userId, profileUrl } = message._sender

    return {
      ...message,
      userId,
      profileUrl,
    }
  }

  getMessages(channel) {
    return new Promise((resolve, reject) => {
      const msgQuery = channel.createPreviousMessageListQuery()

      channel.markAsRead()

      msgQuery.load(MESSAGES_LIMIT, REVERSE_MSG_ORDER, (messages, error) => {
        error ? reject(this.onError('history', error)) : resolve(this.formatMessages(messages))
      })
    })
  }

  sendUserMessage(channel, text) {
    return new Promise((resolve, reject) => {
      channel.sendUserMessage(text, (message, error) => {
        error ? reject(this.onError('message', error)) : resolve(this.formatMessage(message))
      })
    })
  }

  sendFileMessage(channel, file) {
    return new Promise((resolve, reject) => {
      const SendBird = this.getInstance()
      const fileParams = new SendBird.FileMessageParams()

      if (file instanceof File) {
        fileParams.file = file
        fileParams.fileName = file.name
        fileParams.fileSize = file.size
        fileParams.type = file.type
      } else {
        fileParams.fileUrl = file
      }

      fileParams.thumbnailSizes = THUMBNAIL_SIZES

      channel.sendFileMessage(fileParams, (message, error) => {
        error ? reject(this.onError('file', error)) : resolve(this.formatMessage(message))
      })
    })
  }

  clearChatHistory(channel) {
    return new Promise((resolve, reject) => {
      channel.resetMyHistory(function (response, error) {
        error ? reject(this.onError('clear', error)) : resolve()
      })
    })
  }
}
