<template>
  <div class="support-button">
    <v-btn fab dark color="#3047ec" @click="openSupportModal">
      <v-icon dark medium>live_help</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SupportButton',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    openSupportModal() {
      this.$root.$emit('openModal', {
        modalComponent: 'SupportModal',
        modalSize: 'small',
      })
    },
  },
}
</script>

<style lang="scss">
.support-button {
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  right: 20px;

  .v-btn {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
}
</style>
