<template>
  <v-container id="property-selection" class="py-5 px-0 bg-cream" fluid>
    <v-layout column align-center justify-center mt-2>
      <p class="lead mt-4 mb-3 mx-auto">Choose your home</p>
      <h1 class="text-xs-center header-md pb-1">
        Select a room and<br />
        chat with the roommates
      </h1>
      <p class="lead mt-4 mx-auto" v-html="homeSelectionHeaderText" />
    </v-layout>
    <v-layout justify-center pt-4 v-if="leasePreference">
      <v-flex text-xs-center>
        <v-btn @click="openLeasePreferencesModal" flat class="lease-preferences font-thin">
          Lease Preferences
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout class="selection-container mt-3 relative w-100">
      <swiper ref="propertySwiper" :options="swiperOptions" class="mobile-options w-100">
        <div class="swiper-pagination" slot="pagination" />
        <swiper-slide :key="index" v-for="(availableProperty, index) in orderedAvailableProperties">
          <PropertyCard
            v-bind="availableProperty.property"
            :scrollToProperty="() => scrollToProperty(index)"
            :showPropertyRoomDetails="() => showPropertyRoomDetails(availableProperty.property)"
            :property="availableProperty.property"
          />
        </swiper-slide>
        <swiper-slide>
          <FindHomesCard
            :scrollToProperty="() => scrollToProperty(orderedAvailableProperties.length + 1)"
          />
        </swiper-slide>
        <div
          v-ripple
          slot="button-prev"
          class="swiper-button swiper-button-round swiper-button-prev"
        >
          <v-icon color="charcoal">arrow_back</v-icon>
        </div>
        <div
          v-ripple
          slot="button-next"
          class="swiper-button swiper-button-round swiper-button-next"
        >
          <v-icon color="charcoal">arrow_forward</v-icon>
        </div>
      </swiper>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderBy as _orderBy } from 'lodash'
import PropertyCard from '../components/PropertyCard'
import FindHomesCard from '../components/FindHomesCard'

export default {
  name: 'HomeSelection',
  components: {
    PropertyCard,
    FindHomesCard,
  },
  data() {
    return {
      swiperOptions: {
        noSwipingClass: 'blw-slider',
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      options: [],
    }
  },
  computed: {
    ...mapGetters('application', [
      'availableProperties',
      'displayState',
      'applicationPreApprovedForAll',
      'applicationPreApprovedMaxRent',
    ]),
    ...mapGetters('leasePreference', ['leasePreference']),
    orderedAvailableProperties() {
      return _orderBy(this.availableProperties, ['property.availableRooms.length'], ['desc'])
    },
    homeSelectionHeaderText() {
      return this.applicationPreApprovedForAll
        ? 'You’re approved for all rooms'
        : `You’re approved for rooms up to <span class="highlighted-text">$${this.applicationPreApprovedMaxRent}/mo</span>`
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
  methods: {
    openLeasePreferencesModal() {
      this.$trackEvent({
        action: 'CTA Clicked',
        properties: {
          location: 'Center',
          color: 'Outline',
          CTA: 'Lease preferences',
          category: 'Home Selection',
          type: 'Button',
        },
      })

      this.$root.$emit('openModal', { modalComponent: 'LeasePreferenceModal', modalSize: 'sm' })
    },
    scrollToProperty(index) {
      this.$refs.propertySwiper.swiper.slideTo(index)
    },
  },
}
</script>

<style lang="scss">
#property-selection {
  .blw-hero {
    padding-top: 10px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: inherit;
    top: -7px;
  }
  .swiper-slide {
    width: 375px;
    padding: 1.75rem 0;

    @include mobile {
      width: calc(100% - 2rem);
    }
  }

  .swiper-button-prev {
    left: 60px;
  }
  .swiper-button-next {
    right: 60px;
  }

  .selection-card {
    width: 375px;
    overflow: hidden;
  }

  .lease-preferences {
    height: 2.5rem;
    font-size: 14px;
    border: 1px solid $cream;
  }

  .highlighted-text {
    background-color: $cream;
  }
}
</style>
