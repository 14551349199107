export default [
  {
    path: '/lease/:applicationSource',
    redirect: '/application/:applicationSource',
  },
  {
    path: '/apply/cosign',
    redirect: '/cosign',
  },
]
