<template>
  <blw-form
    :onSubmit="updateCosignerAndContinue"
    :updating="updating"
    :error="error.message"
    header="Tell us about yourself"
    text="We'd love you to get to know you a bit."
    formName="cosigner-info"
  >
    <v-layout row wrap>
      <v-flex xs12 md6>
        <blw-text-field
          :rules="[maxLength(50), required]"
          :error-messages="apiFieldError('first_name')"
          label="First name"
          hint="This should be your legal name"
          v-model="first_name"
        />
      </v-flex>

      <v-flex xs12 md6>
        <blw-text-field
          :rules="[maxLength(50), required]"
          :error-messages="apiFieldError('last_name')"
          label="Last name"
          hint="This should be your legal name"
          v-model="last_name"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex>
        <blw-text-field
          :rules="[emailField, maxLength(100), required]"
          :error-messages="apiFieldError('email')"
          label="Email"
          v-model="email"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <blw-calendar
          :age-restriction="18"
          :rules="[required, minAge(18, 'Bungalow cosigners must be at least 18 years of age.')]"
          :error-messages="apiFieldError('birthdate')"
          label="Date of birth"
          :birthday-mode="!birthdate"
          v-model="birthdate"
        />
      </v-flex>
      <v-flex xs12 md6>
        <blw-phone
          :rules="[required]"
          :error-messages="apiFieldError('phonenumber')"
          label="Phone number"
          v-model="phonenumber"
        />
      </v-flex>
    </v-layout>
  </blw-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { emailField, maxLength, required, minAge } from '@/utils/formValidations'

export default {
  name: 'CosignAboutYourself',
  computed: {
    ...mapFields('cosigner', [
      'cosigner.first_name',
      'cosigner.last_name',
      'cosigner.birthdate',
      'cosigner.email',
      'cosigner.phonenumber',
    ]),
    ...mapGetters('cosigner', ['updating', 'error', 'apiFieldError']),
  },
  methods: {
    minAge,
    required,
    emailField,
    maxLength,
    ...mapActions('cosigner', ['updateCosigner']),
    async updateCosignerAndContinue() {
      await this.updateCosigner()
      !this.error && this.$routerPush({ name: 'cosignAddress' })
    },
  },
}
</script>
