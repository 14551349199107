<template>
  <div>
    <v-select
      class="blw-control blw-select"
      @input="$emit('input', $event)"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-select>
    <!-- Hiden input which handles browser auto fill -->
    <input tabindex="-1" class="select-auto-fill-input" v-model="autoCompleteValue" :name="name" />
  </div>
</template>

<script>
import { find as _find } from 'lodash'

export default {
  name: 'BlwSelect',
  props: ['items', 'name'],
  data() {
    return {
      autoCompleteValue: '',
    }
  },
  watch: {
    // this input can only be changed by auto fill so when it does we respond
    autoCompleteValue(value) {
      value && this.autoCompleteHandler(value)
    },
  },
  methods: {
    // This function looks through the provided dropdown options and checks if the auto fill value matches any of those items
    // If a match is found, we use the same $emit('input') event to update the field value !!
    autoCompleteHandler(value) {
      const autocompletedMatchedItem =
        _find(this.items, { text: value }) || _find(this.items, { value })
      autocompletedMatchedItem && this.$emit('input', autocompletedMatchedItem.value)
    },
  },
}
</script>

<style lang="scss">
.blw-select {
  .v-select__slot {
    cursor: pointer;
  }
}
// can't use display none for the desired functionality, hide the input off the screen
.select-auto-fill-input {
  position: fixed;
  top: 150%;
  left: 150%;
}

// Item Hover (global overlay, CSS can't be nested)
.theme--light.v-list .v-list__tile--link {
  &:hover,
  &.v-list__tile--highlighted {
    color: white !important;
    background: $blue-60 !important;
  }
}
</style>
