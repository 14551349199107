<template>
  <v-progress-linear
    v-if="currentProgress"
    v-model="currentProgress"
    id="flow-progress-bar"
    color="blue"
  />
</template>

<script>
export default {
  name: 'BlwFlowProgressBar',
  computed: {
    currentProgress() {
      if (!this.$route.meta.step) return
      return (
        (this.$route.meta.step /
          this.$route.matched.find((route) => route.meta.totalSteps).meta.totalSteps) *
        100
      )
    },
  },
}
</script>

<style lang="scss" scoped>
#flow-progress-bar {
  margin: 0;
  position: sticky;
  top: 80px;
  @include mobile {
    top: 56px;
  }
}
</style>
